import React from "react";


import './style.css';

const LabeledTextarea = ({ labelText, inputSize, disabled = false, extraClass='', ...props }) => {
    return (
      <div className={"ss-group "+ extraClass}>
        <label className="ss-label">{labelText}</label>
        <textarea className="ss-input" type="textarea"  size={inputSize} {...props}>
            {props.value}
        </textarea>
      </div>
    );
  
}

export {LabeledTextarea};