import {
  SHOW_SUBSCRIPTIONS_LOADING,
  ON_GET_USER_SUBSCRIPTIONS_SUCCESS,
  ON_GET_USER_SUBSCRIPTIONS_FAIL,
  ON_CANCEL_USER_SUBSCRIPTION_SUCCESS,
  ON_CANCEL_USER_SUBSCRIPTION_FAIL,
  CLEAN_USER_SUBSCRIPTIONS,
  UNAUTHORIZED,
} from "../actions/types"

const INIT_STATE = {
  userSubscriptionsList: [],
  isLoadingSubscriptions: false,
  isGetSubscriptionsError: false,
  isCancelSubscriptionError: false,
  isCancelSubscriptionSuccessed: false,
}

const UserSubscriptionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_SUBSCRIPTIONS_LOADING:
      return {
        ...state,
        isLoadingSubscriptions: true,
        isGetSubscriptionsError: false,
        isCancelSubscriptionError: false,
        isCancelSubscriptionSuccessed: false,
      }
    case ON_GET_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        userSubscriptionsList: action.payload,
        isLoadingSubscriptions: false,
        isGetSubscriptionsError: false,
      }
    case ON_GET_USER_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        userSubscriptionsList: [],
        isLoadingSubscriptions: false,
        isGetSubscriptionsError: true,
      }
    case ON_CANCEL_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoadingSubscriptions: false,
        isCancelSubscriptionError: false,
        isCancelSubscriptionSuccessed: true,
      }
    case ON_CANCEL_USER_SUBSCRIPTION_FAIL:
      return {
        ...state,
        isLoadingSubscriptions: false,
        isCancelSubscriptionError: true,
        isCancelSubscriptionSuccessed: false,
      }
    case CLEAN_USER_SUBSCRIPTIONS:
      return {
        ...state,
        userSubscriptionsList: [],
        isLoadingSubscriptions: false,
        isCancelSubscriptionError: false,
        isGetSubscriptionsError: false,
        isCancelSubscriptionSuccessed: false,
      }
    case UNAUTHORIZED:
      return {
        userSubscriptionsList: [],
        isLoadingSubscriptions: false,
        isCancelSubscriptionError: false,
        isGetSubscriptionsError: false,
      }
    default:
      return state
  }
}

export default UserSubscriptionsReducer
