import React from 'react';
import { clif } from '../../../utils';

import './style.css';

const InputWithLabel = ({ displayErr, extraClass, title, wrapperExtraClass, titleExtraClass, mandatory, disabled, ...props }) => {
    title = title ? title : props.placeholder;

    const classNamesFromProps = `${clif(displayErr, 'inwl-err')} ${clif(mandatory, 'inwl-mand')} ${clif(disabled, 'inwl-disabled')}`;
    const inputClassName = `inwl-wrapper ${classNamesFromProps} ${extraClass}`;

    return (
        <div className={`inwl-outer-wrapper ${wrapperExtraClass}`}>
            <div className={`inwl-title ${titleExtraClass}`}>
                {title}
            </div>
            <input
                className={inputClassName}
                {...props}
            />
        </div>
    );
}

export { InputWithLabel };