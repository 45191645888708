import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Route } from "react-router-dom";

import PriceLists from "./PriceLists";
import Services from "./Services";
import Processes from "./Processes";
import Items from "./Items";

import {
  fetchPriceListSelectOptions,
  getServices,
  getProcesses,
  getItems,
} from "../../../actions";

import "./style.css";

class PriceListsScreen extends Component {
  componentDidMount() {
    this.props.fetchPriceListSelectOptions();
    this.props.getServices();
    this.props.getProcesses();
    this.props.getItems();
  }

  componentWillUnmount() {
    // Reset the section active price list
  }

  render() {
    return (
      <section id="price-lists-section-container">
        <nav id="price-lists-section-menu">
          <ul>
            <li className="price-lists-section-menu-item">
              <Link to={`${this.props.match.url}/price-lists`}>מחירונים</Link>
            </li>
            {/* <li className="price-lists-section-menu-item"><Link to={`${this.props.match.url}/services`}>Services</Link></li> */}
            <li className="price-lists-section-menu-item">
              <Link to={`${this.props.match.url}/processes`}>תהליכים</Link>
            </li>
            <li className="price-lists-section-menu-item">
              <Link to={`${this.props.match.url}/items`}>פריטים ומוצרים</Link>
            </li>
          </ul>
        </nav>
        <main id="price-lists-section-body">
          <Route
            path={`${this.props.match.url}/price-lists`}
            component={PriceLists}
          />
          <Route
            path={`${this.props.match.url}/services`}
            component={Services}
          />
          <Route
            path={`${this.props.match.url}/processes`}
            component={Processes}
          />
          <Route path={`${this.props.match.url}/items`} component={Items} />
        </main>
      </section>
    );
  }
}

export default connect(null, {
  fetchPriceListSelectOptions,
  getServices,
  getProcesses,
  getItems,
})(PriceListsScreen);
