import React, { Component } from 'react';

import WidthAdjuster from './WidthAdjuster';
import './style.css';

class Slider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSliderOpened: true
        }
    }

    renderChildren() {
        const { isSliderOpened } = this.state;
        const { children } = this.props;

        if (!isSliderOpened) {
            return;
        }

        return (
            <div className='slider-inner-wrapper'>
                {children}
            </div>
        );
    }

    render() {
        const { isSliderOpened } = this.state;

        return (
            <div className='slider-wrapper'>
                {this.renderChildren()}
                <WidthAdjuster
                    isOpened={isSliderOpened}
                    onChangeToggeled={() => this.setState({ isSliderOpened: !isSliderOpened })}
                />
            </div>
        );
    }
};

export default Slider;
