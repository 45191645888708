import axios from "axios"
import api from "../api/AzureApi"
import {
  ON_GET_ROUTES_SUCCESS,
  ON_GET_ROUTES_FAIL,
  ON_ROUTE_SHOW_LOADING,
  ON_SET_ROUTE,
  ON_ROUTE_STATIONS_SHOW_LOADING,
  GET_STATIONS_BY_ROUTE_SUCCESS,
  GET_STATIONS_BY_ROUTE_FAIL,
  ON_CREATE_ROUTE_SUCCESS,
  ON_CREATE_ROUTE_FAIL,
  ON_UPDATE_ROUTE_SUCCESS,
  ON_UPDATE_ROUTE_FAIL,
  ON_CHECK_EXIST_ROUTE_NAME_SUCCESS,
  ON_CHECK_EXIST_ROUTE_NAME_FAIL,
  ON_CHECK_EXIST_ROUTE_NAME_LOADING,
  ON_DELETE_ROUTE_SUCCESS,
  ON_DELETE_ROUTE_FAIL,
  ON_CLOSE_ROUTE_NOTIFICATION,
} from "./types"
import errorManager from "../utils/ErrorManager"

export const setCurrentRoute = (route) => {
  console.log("setCurrentRoute>route:")
  console.log(route)
  return (dispatch) => {
    dispatch({
      type: ON_SET_ROUTE,
      payload: route,
    })
  }
}

export const onLoadingRoutes = () => {
  return (dispatch) => {
    dispatch({
      type: ON_ROUTE_SHOW_LOADING,
    })
  }
}

export const onLoadingRouteStations = () => {
  return (dispatch) => {
    dispatch({
      type: ON_ROUTE_STATIONS_SHOW_LOADING,
    })
  }
}

export const onLoadingCheckExistRouteName = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CHECK_EXIST_ROUTE_NAME_LOADING,
    })
  }
}

export const fetchRoutes = () => {
  console.log("fetchRoutes.")
  return (dispatch) => {
    axios
      .get(api.fetchRoutes)
      .then((res) => {
        console.log(res.data)
        dispatch({
          type: ON_GET_ROUTES_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_ROUTES_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchRouteStations = (routeId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .get(api.getRouteStations(routeId), options)
      .then((res) => {
        dispatch({
          type: GET_STATIONS_BY_ROUTE_SUCCESS,
          payload: res.data,
        })
        //errorManager.throwDispatchErrorReset(dispatch,res);
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: GET_STATIONS_BY_ROUTE_FAIL,
          payload: err,
        })
        //errorManager.throwDispatch(dispatch, err);
      })
  }
}

export const isExistRouteName = (routeName) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    axios
      .post(api.isExistRouteName, routeName, options)
      .then((res) => {
        dispatch({
          type: ON_CHECK_EXIST_ROUTE_NAME_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_CHECK_EXIST_ROUTE_NAME_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const createRoute = (route) => {
  console.log("createRoute: " + route ? route.routeName : "")
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    let data = {
      routeId: "000",
      routeName: route.routeName,
      serviceSupplierId: route.serviceSupplierId,
      color: route.color,
      routeCost: route.routeCost,
    }

    axios
      .post(api.createRoute, data, options)
      .then((res) => {
        dispatch({
          type: ON_CREATE_ROUTE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)

        onLoadingRoutes()
        fetchRoutes()
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_CREATE_ROUTE_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const updateRoute = (route) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    const data = {
      routeName: route.routeName,
      id: route.id,
      serviceSupplierId: route.serviceSupplierId,
      color: route.color,
      routeCost: route.routeCost,
    }
    axios
      .put(api.updateRoute(route.id), data, options)
      .then((res) => {
        dispatch({
          type: ON_UPDATE_ROUTE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)

        onLoadingRoutes()
        fetchRoutes()
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_UPDATE_ROUTE_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const deleteRoute = (routeId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    axios
      .delete(api.deleteRoute(routeId), options)
      .then((res) => {
        dispatch({
          type: ON_DELETE_ROUTE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
        onLoadingRoutes()
        fetchRoutes()
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_DELETE_ROUTE_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const closeRouteNotification = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CLOSE_ROUTE_NOTIFICATION,
    })
  }
}
