import React from "react"

import { BlueButton } from "../../../../common"

import "./style.css"

const UserItem = ({ item, itemMap, gravity, isLast, onEditBtnClick }) => {
  const itemClassName = `ul-item-wrapper ${isLast ? "ul-item-last" : ""}`

  return (
    <div className={itemClassName}>
      {renderItemContent(item, itemMap, gravity)}

      <div>
        <BlueButton
          extraClass="ul-edit-btn"
          onClick={(e) => onEditBtnClick(item)}
        >
          עריכה
        </BlueButton>
      </div>
    </div>
  )
}

const isDeletedUser = (isDelete) => {
  if (isDelete) {
    return (
      <div className="fa fa-times-circle user-deleted" aria-hidden="true"></div>
    )
  }
  return (
    <div className="fa fa-check-circle user-active" aria-hidden="true"></div>
  )
}

const renderItemContent = (item, itemMap, gravity) => {
  return itemMap.map((itmMap, index) => {
    const flex = gravity[`${index}`] || 1
    if (item["stationNumber"] == "0" || item["stationNumber"] == undefined) {
      item["stationNumber"] = ""
    }
    return (
      <div style={{ flex }} key={index}>
        {itmMap == "isDelete" ? isDeletedUser(item[itmMap]) : item[itmMap]}
      </div>
    )
  })
}

export default UserItem
