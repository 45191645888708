import React, { useEffect, useMemo, useState  } from 'react';
import { connect } from 'react-redux';
import  TableContainer  from '../../../common/TableContainer'; 
import { getFormatedDate } from '../../../../utils/FormatedDate';
import { SelectColumnFilter } from '../../../filters/SelectColumnFilter';

import { fetchStationNotifications } from '../../../../actions';


import {
    Container,
    //Card,CardText,CardBody,CardTitle,
  } from "reactstrap"
  
import './style.css';

const StationsNotifications = (props) => {
    let [data, setData] = useState([]);
    const { history, unauthorized, notifications } = props;
    useEffect(() => {
        
        props.fetchStationNotifications();
        
        
    }, [])

    if(unauthorized){
        history.push(`/`);
    }

    const setEmtyIfNull = obj => { 
        if(obj == null){ return '';}
        return obj;
    }

    const formatedNotification =(n)  => {

        const { branchName, stationNumber, orderNumber, 
            mobilePhone,firstName, lastName, 
            orderDate, body, priority, 
            createdDate, stationId, orderId} = n;

        return {
            
            branchName: branchName,
            stationNumber,
            orderNumber: setEmtyIfNull(orderNumber),
            mobilePhone:setEmtyIfNull(mobilePhone),
            firstName: setEmtyIfNull(firstName),
            lastName: setEmtyIfNull(lastName),
            orderDate: getFormatedDate(orderDate),
            note: setEmtyIfNull(n.name),
            body: setEmtyIfNull(body),
            priority,
            createdDate: getFormatedDate(createdDate),
            stationId,
            orderId: setEmtyIfNull(orderId)
        }
    };
    
    const notificationsFormated = notifications.map( notif => formatedNotification(notif));
    data = useMemo(() => notificationsFormated, [notifications]);
    
    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'key', 
                Cell: ({ row }) => (
                 <span >
                    {row.isExpanded ? '👇' : '👈'}
                  </span>
                )
              },
            {
                Header: 'מספר עמדה',
                accessor: 'stationNumber',
            },
            {
                Header: 'שם עמדה',
                accessor: 'branchName',
            },
            {
                Header: 'תאריך',
                accessor: 'createdDate',
                defaultSortDesc: true,
            },
            {
                Header: 'כותרת',
                accessor: 'note',
            },
            {
                Header: 'תוכן',
                accessor: 'body',
            },
            {
                Header: 'עדיפות',
                accessor: 'priority',
                Filter: SelectColumnFilter,
                filter: 'equals'
            },
            {
                Header: 'מספר הזמנה',
                accessor: 'orderNumber',
            }
           /* 
            {
                Header: 'Order Date',
                accessor: 'orderDate',
            },
            {
                Header: 'Mobile Phone',
                accessor: 'mobilePhone',
            },
            {
                Header: 'First Name',
                accessor: 'firstName',
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
            },
            
              {
                Header: 'stationId',
                accessor: 'stationId',
            }, 
            {
                Header: 'orderId',
                accessor: 'orderId',
            },
           */
        ],
        []
    );

    /*const renderRowSubComponent = row => {
        
        const {
            stationNumber,
            branchName,
            createdDate,
            mobilePhone,
            firstName,
            lastName,
            stationId,
            orderId,
            orderDate
        } = row.original
        return (
          <Card style={{ width: "18rem", margin: "0 auto" }}>
            
            <CardBody>
              <CardTitle>
                <strong>{`${stationNumber} ${branchName} at ${createdDate}`} </strong>
              </CardTitle>
              <CardText>
                <strong>Mobile Phone</strong>: {mobilePhone} <br />
                <strong>First Name</strong>: {firstName} <br />
                <strong>Last Name</strong>: {lastName} <br />
                <strong>Order Date</strong>: {orderDate} <br />
                <strong>OrderId</strong>: {orderId} <br />
                <strong>StationId</strong>: {stationId} <br />
              </CardText>
            </CardBody>
          </Card>
        )
      }*/
    
    return (
    <Container>
        <TableContainer columns={columns} data={data} />
    </Container>
        
    );

    
}

const mapStateToProps = ({ stationsList, errors}) => {
    const { notifications } = stationsList;
    const { unauthorized } = errors;
   
    return {
       
        notifications,
        unauthorized
    };
}

export default connect(mapStateToProps, {
    
    fetchStationNotifications
})(StationsNotifications);