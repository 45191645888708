import React from 'react';
import './style.css';


const ValidationMessage = (props) => {
    
    
    if(props.isShow && props.isShowError){
       
         return (
            <div className={props.className}>
                <div className='validation-message-wrapper'>
                    <div className="fa fa-times-circle" aria-hidden="true"></div>
                    <div className='error-text'>{props.errorMessage}</div>
                </div>
            </div>
        );
    }else if(props.isShow && props.isShowOk){
       
            return (
               <div className={props.className}>
                   <div className='validation-message-wrapper'>
                       <div className="fa fa-check-circle" aria-hidden="true"></div>
                   </div>
               </div>
           );
    }else{
        return (
            <div className={props.className}></div>
        );
    }
   
}

export { ValidationMessage };