import axios from "axios"
import api from "../api"

import {
  ON_BALANCE_FETCH_START,
  ON_BALANCE_FETCH_SUCCESS,
  ON_401_UNAOUTHORIZED_REQUEST_ERROR,
  ON_NO_RESPONSE_RECEIVED_ERROR,
  ON_UNHANDLED_REQUEST_ERROR,
  ON_UNHANDLED_INTERNAL_SERVER_ERROR,
} from "./types"

export const downloadDetailedLockersReport = (stations, lockerStatuses) => {
  return (dispatch) => {
    const options = {
      method: "GET",
      responseType: "blob",
      params: {
        stations,
        lockerStatuses,
      },
      paramsSerializer: ({ stations, lockerStatuses }) => {
        let modifiedStations = []
        if (stations.length !== 0) {
          modifiedStations = stations.map((stationId) => {
            return `stationIds=${stationId}`
          })
        } else {
          modifiedStations.push("stationIds=")
        }

        let modifiedlockerStatuses = []
        if (lockerStatuses.length !== 0) {
          modifiedlockerStatuses = lockerStatuses.map((lockerStatus) => {
            return `lockersStatuses=${lockerStatus}`
          })
        } else {
          modifiedlockerStatuses.push("lockersStatuses=")
        }

        return (
          modifiedStations.join("&") + "&" + modifiedlockerStatuses.join("&")
        )
      },
    }

    axios(api.getDetailedLockersReport, options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "DetailedLockersReport.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) =>
        handleError(error, dispatch, "downloadDetailedLockersReport")
      )
  }
}

export const downloadStationsStatusReport = (stations) => {
  return (dispatch) => {
    const options = {
      method: "GET",
      responseType: "blob",
      params: {
        stations,
      },
      paramsSerializer: ({ stations }) => {
        let modifiedStations = []
        if (stations.length !== 0) {
          modifiedStations = stations.map((stationId) => {
            return `stationIds=${stationId}`
          })
        } else {
          modifiedStations.push("stationIds=")
        }

        return modifiedStations.join("&")
      },
    }

    axios(api.getStationsStatusReport, options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "StationsStatusReport.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) =>
        handleError(error, dispatch, "downloadStationsStatusReport")
      )
  }
}

export const downloadOrderItemReport = (orderItemNumber) => {
  return (dispatch) => {
    const options = {
      method: "GET",
      responseType: "blob",
      params: {
        orderItemNumber,
      },
    }

    axios(api.getOrderItemReport, options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "OrderItemReport.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => handleError(error, dispatch, "downloadOrderItemReport"))
  }
}

export const downloadCustomerOrdersReport = (
  stations,
  customerName,
  customerPhone
) => {
  return (dispatch) => {
    const options = {
      method: "GET",
      responseType: "blob",
      params: {
        stations,
        customerName,
        customerPhone,
      },
      paramsSerializer: ({ stations, customerName, customerPhone }) => {
        let modifiedStations = []
        if (stations.length !== 0) {
          modifiedStations = stations.map((stationId) => {
            return `stationIds=${stationId}`
          })
        } else {
          modifiedStations.push("stationIds=")
        }

        return `${modifiedStations.join(
          "&"
        )}&customerName=${customerName}&customerPhone=${customerPhone}`
      },
    }

    axios(api.getCustomerOrdersReport, options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "CustomerOrdersReport.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) =>
        handleError(error, dispatch, "downloadCustomerOrdersReport")
      )
  }
}

export const downloadOutdatedOrdersReport = (stations, numberOfDays) => {
  return (dispatch) => {
    const options = {
      method: "GET",
      responseType: "blob",
      params: {
        stations,
        numberOfDays,
      },
      paramsSerializer: ({ stations, numberOfDays }) => {
        let modifiedStations = []
        if (stations.length !== 0) {
          modifiedStations = stations.map((stationId) => {
            return `stationIds=${stationId}`
          })
        } else {
          modifiedStations.push("stationIds=")
        }
        return `${modifiedStations.join("&")}&numberOfDays=${numberOfDays}`
      },
    }

    axios(api.getOutdatedOrdersReport, options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "OutdatedOrdersReport.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) =>
        handleError(error, dispatch, "downloadOutdatedOrdersReport")
      )
  }
}

function fetchBalanceReport() {
  return {
    type: ON_BALANCE_FETCH_START,
  }
}

export const downloadBalanceReport = ({ fromDate, toDate }) => {
  return (dispatch) => {
    const options = {
      method: "GET",
      responseType: "blob",
      onDownloadProgress: function (progressEvent) {},
    }

    dispatch(fetchBalanceReport())

    axios(
      `${api.getBalanceReport}?fromPaymentDate=${fromDate}&toPaymentDate=${toDate}`,
      options
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "CB_GI_Balance_Report.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
        dispatch({
          type: ON_BALANCE_FETCH_SUCCESS,
        })
      })
      .catch((error) => handleError(error, dispatch, "downloadBalanceReport"))
  }
}

export const downloadDriverRouteReport = () => {
  return (dispatch) => {
    const options = {
      method: "get",
      responseType: "blob",
      params: {},
    }

    axios(api.getDriverRouteReport, options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "DriverRouteReport.pdf")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) =>
        handleError(error, dispatch, "downloadDriverRouteReport")
      )
  }
}

export const downloadDriverVisitsReport = () => {
  return (dispatch) => {
    const options = {
      method: "get",
      responseType: "blob",
      params: {},
    }

    axios(api.getDriverVisitsReport, options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "DriverVisitsReport.pdf")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) =>
        handleError(error, dispatch, "downloadDriverVisitsReport")
      )
  }
}

export const downloadStationLoadingReport = () => {
  return (dispatch) => {
    const options = {
      method: "get",
      responseType: "blob",
      params: {},
    }

    axios(api.getStationLoadingReport, options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "StationLoadingReport.pdf")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) =>
        handleError(error, dispatch, "downloadStationLoadingReport")
      )
  }
}

export const downloadOrdersMarkAsCloseReport = () => {
  return (dispatch) => {
    const options = {
      method: "get",
      responseType: "blob",
      params: {},
    }

    axios(api.getOrdersMarkAsClosedReport, options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "orders_marked_as_close.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) =>
        handleError(error, dispatch, "downloadStationLoadingReport")
      )
  }
}

export const downloadPackageDebitReport = (
  selectedMonth,
  selectedYear,
  selectedOrderType
) => {
  return (dispatch) => {
    const options = {
      method: "GET",
      responseType: "blob",
      params: {
        month: selectedMonth,
        year: selectedYear,
        orderType: selectedOrderType,
      },
    }

    axios(api.getPackageDebitReport, options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "PackageDebitReport.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) =>
        handleError(error, dispatch, "downloadPackageDebitReport")
      )
  }
}

export const downloadPackageDebitReportByAccount = (
  selectedMonth,
  selectedYear,
  selectedOrderType,
  selectedAccountType
) => {
  return (dispatch) => {
    const options = {
      method: "GET",
      responseType: "blob",
      params: {
        month: selectedMonth,
        year: selectedYear,
        orderType: selectedOrderType,
        accountType: selectedAccountType,
      },
    }

    axios(api.getPackageDebitReportAccount, options)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "PackageDebitReport.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) =>
        handleError(error, dispatch, "downloadPackageDebitReportByAccount")
      )
  }
}

function handleError(error, dispatch, method) {
  if (error.response) {
    if (error.response.status === 500) {
      dispatch({
        type: ON_UNHANDLED_INTERNAL_SERVER_ERROR,
        error: error.response,
        message: `An internal server error occurred. Please report the error.`,
      })
    } else if (error.response.status === 401) {
      dispatch({
        type: ON_401_UNAOUTHORIZED_REQUEST_ERROR,
        error: error.response,
        message: "You are unauthorized. Please login.",
      })
    } else {
      dispatch({
        type: ON_UNHANDLED_REQUEST_ERROR,
        error: error.response,
        message: `Error ${error.response.status} occurred. Please report the error.`,
      })
    }
    console.log(`${method} >>> response error`, error.response)
  } else if (error.request) {
    dispatch({
      type: ON_NO_RESPONSE_RECEIVED_ERROR,
      error: error.request,
      message:
        "Cannot get response from the server. Please check your internet connection.",
    })
    console.log(`${method} >>> request error`, error.request)
  } else {
    console.log(`${method} >>> internal error`, error)
  }
}

export const getOrdersWithStatus = (packageNumbers) => {
  return (dispatch) => {
    axios
      .post(api.getOrdersWithStatus, packageNumbers, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "OrdersStatus.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => {
        handleError(error, dispatch, "getOrdersWithStatus")
        console.error("Error occurred while downloading orders:", error)
      })
  }
}

export const exportLaundryPrices = (serviceSupplierId, fromDate, toDate) => {
  return (dispatch) => {
    axios
      .post(
        api.exportLaundryPrices(serviceSupplierId, fromDate, toDate),
        null,
        { responseType: "blob" }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "LaundryPrices.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => {
        handleError(error, dispatch, "exportLaundryPrices")
        console.error(
          "Error occurred while downloading laundry prices report:",
          error
        )
      })
  }
}

export const downloadReservedLockerReport = () => {
  return (dispatch) => {
    axios
      .post(api.downloadReservedLockerReport, null, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "ReservedLockers.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => {
        handleError(error, dispatch, "exportLaundryPrices")
        console.error(
          "Error occurred while downloading reserved lockers report:",
          error
        )
      })
  }
}
