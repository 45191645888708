import {
  ON_GET_ORDER_IMAGES_SUCCESS,
  ON_GET_ORDER_IMAGES_FAIL,
  ON_CLEAR_ORDER_IMAGES,
} from "../actions/types"

const INIT_STATE = {
  isOrderImagesExist: false,
  laundryImages: [],
}

const OrderImagesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_ORDER_IMAGES_SUCCESS:
      return {
        ...state,
        laundryImages: action.payload,
      }
    case ON_GET_ORDER_IMAGES_FAIL:
      return {
        ...state,
        laundryImages: [],
      }
    case ON_CLEAR_ORDER_IMAGES:
      return {
        ...state,
        isOrderImagesExist: false,
        laundryImages: [],
      }
    default:
      return state
  }
}

export default OrderImagesReducer
