import axios from "axios"
import api from "../api"
import {
  ON_GET_ROUTE_AREA_CODE_SUCCESS,
  ON_GET_ROUTE_AREA_CODE_FAIL,
  ON_CREATE_ROUTE_AREA_CODE_SUCCESS,
  ON_CREATE_ROUTE_AREA_CODE_FAIL,
  ON_EDIT_ROUTE_AREA_CODE_SUCCESS,
  ON_EDIT_ROUTE_AREA_CODE_FAIL,
  ON_SELECT_EDIT_ROUTE_AREA_CODE,
  CLEAR_AREA_ROUTE_CODE,
} from "./types"
import errorManager from "../utils/ErrorManager"

export const getAllRouteAreaCodes = () => (dispatch) => {
  axios
    .get(api.getAllRouteAreaCodes)
    .then((res) => {
      dispatch({
        type: ON_GET_ROUTE_AREA_CODE_SUCCESS,
        payload: res.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: ON_GET_ROUTE_AREA_CODE_FAIL,
        payload: err,
      })
      errorManager.dispatchError(err, dispatch)
    })
}

export const createRouteAreaCode = (routeAreaCode) => (dispatch) => {
  return axios
    .post(api.createRouteAreaCode, routeAreaCode)
    .then((res) => {
      dispatch({
        type: ON_CREATE_ROUTE_AREA_CODE_SUCCESS,
        payload: res.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: ON_CREATE_ROUTE_AREA_CODE_FAIL,
        payload: err,
      })
      errorManager.dispatchError(err, dispatch)
    })
}

export const updateRouteAreaCode = (routeAreaCode) => (dispatch) => {
  return axios
    .put(api.updateRouteAreaCode, routeAreaCode)
    .then((res) => {
      dispatch({
        type: ON_EDIT_ROUTE_AREA_CODE_SUCCESS,
        payload: res.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: ON_EDIT_ROUTE_AREA_CODE_FAIL,
        payload: err,
      })
      errorManager.dispatchError(err, dispatch)
    })
}

export const selectEditRouteAreaCode = (routeAreaCode) => (dispatch) => {
  dispatch({
    type: ON_SELECT_EDIT_ROUTE_AREA_CODE,
    payload: routeAreaCode,
  })
}

export const clearRouteAreaCode = () => (dispatch) => {
  dispatch({
    type: CLEAR_AREA_ROUTE_CODE,
  })
}
