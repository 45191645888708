import React from 'react';
import api from '../../../../api';
import './style.css';
import axios from 'axios';

const EXPORT_ORDERS_ITEMS = api.exportOrdersItems;

const exportOrderItemsTableHeaderText = 'יצוא פריטי הזמנות';
const exportButtonText = 'לייצא';

const exportOrderItems = (props) => {
    
    const handleDownload = () => {
        
        props.isLoading(true);
        props.hideError();
        const options = {
            method: "GET",
            headers: {
                "Authorization":  axios.defaults.headers.common["Authorization"],
            }
        };
        const  status = (response) => {
            
            if (response.status >= 200 && response.status < 300) {
              return Promise.resolve(response)
            } else {
              return Promise.reject(new Error('status:'+response.status+'   '+response.statusText))
            }
          }
        fetch(EXPORT_ORDERS_ITEMS, options)
            .then(status)
            .then(response => {
                return response.blob();
            })
            .then(blob => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.style.display = "none";
                a.setAttribute("download", "ordersitems.xlsx");
                a.click();
                a.remove();
            })
            .then( () => { 
                props.isLoading(false);
            })
            .catch(error => {
                console.error('Error on exportOrderItems:', error);
                props.setError('ExportOrderItems', error.toString());
                props.isLoading(false);
            });
    }

    return (
        <div className={props.className}>
            <h1>{exportOrderItemsTableHeaderText}</h1>
            <button
                className="download-file-button"
                onClick={handleDownload} >{exportButtonText}</button>
           
        </div>
    );
}

export default exportOrderItems;