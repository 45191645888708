import React from "react"
import { BlueButton } from "../../../../../common"
import "./style.css"

const CityItem = ({ item, itemMap, gravity, isLast, onEditBtnClick }) => {
  const itemClassName = `item-wrapper ${isLast ? "item-last" : ""}`

  return (
    <div className={itemClassName}>
      {itemMap.map((itmMap, index) => {
        const flex = gravity[`${index}`] || 1
        if (itmMap === "edit") {
          return (
            <div style={{ flex }} key={index}>
              <BlueButton
                extraClass="edit-btn"
                onClick={(e) => onEditBtnClick(item)}
              >
                עריכה
              </BlueButton>
            </div>
          )
        }

        let itemContent = item[itmMap]
        return (
          <div style={{ flex }} key={index}>
            {itemContent}
          </div>
        )
      })}
    </div>
  )
}

export default CityItem
