import React from 'react';

import StyleClasses from './LabeledDatePicker.module.css';

const LabeledDatePicker = ({labelText, name, date, onChange, disabled=false}) => {
    return (
        <div className={StyleClasses.FormGroup +" ss-group"}>
            <label className={StyleClasses.Label +" ss-label"}>{labelText}</label>
            <input
                type="date"
                disabled={disabled} 
                className={StyleClasses.DatePicker +" ss-input"}
                name={name}
                value={date}
                onChange={(e) => onChange(name, e)} />
        </div>
    );
}

export { LabeledDatePicker };