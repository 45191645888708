import React, { Component } from 'react';

import './style.css';

class SelectVal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showItems: false,
            selectedItemIndex: -1
        };
    }

    componentDidMount() {
        const { items, preSelectedIndex = 0, placeholder } = this.props;

        if (!placeholder && items && items.length > 0) {
            this.setState({ selectedItemIndex: preSelectedIndex });
        }
    }

    componentWillReceiveProps(newProps) {
        const { items, preSelectedIndex, placeholder } = newProps;

        const { items: oldItems } = this.props;

        const { selectedItemIndex } = this.state;

        if (items !== oldItems || (!placeholder && selectedItemIndex === -1)) {
            this.setState({ selectedItemIndex: preSelectedIndex });
        }
    }

    renderItems(show) {
        if (!show) {
            return;
        }

        const { items, displayedProp } = this.props;
        const { selectedItemIndex } = this.state;

        return items.map((itm, index) => {

            const itemClassName = `blsel-itm blsel-itm-extra ${index === selectedItemIndex ? 'blsel-picker' : ''}`

            return (
                <div
                    className={itemClassName}
                    onClick={e => this.onItemClick(index)}
                    key={index}
                >
                    {displayedProp ? itm[displayedProp] : itm}
                </div>
            );
        })
    }

    onItemClick(index) {
        const { items } = this.props;

        this.setState({
            selectedItemIndex: index,
            showItems: false
        });

        this.props.onItemSelected(items[index], index);
    }

    getDisplayedItem() {
        const { selectedItemIndex } = this.state;
        const { items, displayedProp, placeholder } = this.props;

        if (placeholder && selectedItemIndex === -1) {
            return placeholder;
        }

        if (!items || !items[selectedItemIndex]) {
            return '   ';
        }

        if (displayedProp) {
            return items[selectedItemIndex][displayedProp];
        }

        return items[selectedItemIndex];
    }

    render() {
        const { showItems } = this.state;
        const { extraClass } = this.props;

        const arrowDownSrc = showItems ? require('../../../assets/images/arrow-up.png') :
            require('../../../assets/images/arrow-down.png');

        return (
            <div className={`blsel-wrapper ${extraClass ? extraClass : ''}`}>
                <div className='blsel-displayed-itm'
                    onClick={e => { this.setState({ showItems: !showItems }) }}
                >
                    <div className='blsel-itm'>
                        {this.getDisplayedItem()}
                    </div>
                    <div className='blsel-arrow-down'>
                        <img src={arrowDownSrc} className='blsel-arrow-img' alt='' />
                    </div>
                </div>
                <div className='blsel-itms-list-wrapper'>
                    <div className='blsel-itms-list'>
                        {this.renderItems(showItems)}
                    </div>
                </div>
            </div>
        );
    }
}

export { SelectVal };
