import React, { Component } from "react"
import { connect } from "react-redux"

import { InputFiled, Pagination } from "../../common"
import { getValEnumFromIndex, getStatusNameFromObj } from "../../../utils"
import OrderList from "./OrderList"

import CreateEditOrderModal from "../../Modals/CreateEditOrderModal"
import { Filterer } from "../../reduxified"

import {
  fetchAllOrders,
  onOrdersFilterByChange,
  updateOrderData,
  setExternalOrderToEdit,
  onStartOrdersLoading,
} from "../../../actions"

import "./style.css"

class OrdersListScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      pagedList: [],
      pageNum: 1,
      showOrderEditModal: false,
      orderToEdit: null,
      fetchOpenOrdersInterval: null,
      searchingOrderNum: this.props.location.state
        ? this.props.location.state.searchingOrderNum
        : null,
    }

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }

    this.props.onStartOrdersLoading()
    console.log("OrdersListScreen --> componentDidMount (call fetchAllOrders)")

    this.props.fetchAllOrders()
    if (this.props.filterBy != "") {
      this.props.onOrdersFilterByChange(this.props.filterBy)
    }
    console.log("set fetchOpenOrdersInterval.")
    const fetchOpenOrdersInterval = setInterval(() => {
      console.log(
        "OrdersListScreen --> fetchOpenOrdersInterval (call fetchAllOrders)"
      )
      this.props.fetchAllOrders()
    }, 60 * 1000)
    this.setState({ fetchOpenOrdersInterval })

    if (this.state.searchingOrderNum) {
      this.props.onOrdersFilterByChange(this.state.searchingOrderNum)
      this.setState({ searchingOrderNum: null })
    }
  }

  componentWillUnmount() {
    const { fetchOpenOrdersInterval } = this.state
    if (fetchOpenOrdersInterval) {
      console.log("clear fetchOpenOrdersInterval.")
      clearInterval(fetchOpenOrdersInterval)
    }
  }

  // USED WHEN OPENING THE EDIT MODAL HERE..
  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }
    const isOrdersListChanged =
      this.props.ordersListArr !== newProps.ordersListArr

    if (isOrdersListChanged) {
      const { ordersStatusesArr, orderTypes, orderStatusesObj } = newProps
      const isExistOrdersStatuses = ordersStatusesArr.length > 0
      const isExistOrdersTypes = orderTypes.length > 0
      newProps.ordersListArr.forEach((element) => {
        element.orderFormatStatus = isExistOrdersStatuses
          ? getStatusNameFromObj(orderStatusesObj, element.orderStatus)
          : element.orderFormatStatus
        element.orderFromatType = isExistOrdersTypes
          ? getValEnumFromIndex(newProps.orderTypes, element.orderType)
          : element.orderFromatType
      })
    }
  }

  onOrderItemEditBtnClick(order) {
    console.log("onOrderItemEditBtnClick", order)
    const { fetchOpenOrdersInterval } = this.state
    if (fetchOpenOrdersInterval) {
      console.log("clear fetchOpenOrdersInterval.")
      clearInterval(fetchOpenOrdersInterval)
    }
    this.props.setExternalOrderToEdit(order)
    this.props.history.push("/OrdersEditingScreen")
  }

  onUpdatedOrderSubmitted({ status, totalPrice, orderItems }) {
    const { orderDetails } = this.props

    const updatedOrder = {
      ...orderDetails,
      orderStatus: status,
      orderTotalPrice: totalPrice,
      orderItems,
    }

    this.props.updateOrderData(updatedOrder)
    this.setState({ showOrderEditModal: false, orderToEdit: null })
  }

  renderOrdersList() {
    const { isLoading } = this.props
    const { pagedList } = this.state

    return (
      <div>
        <OrderList
          extraClass="ols-wrapper-list"
          headers={[
            "מזהה",
            "מס חבילה",
            "תאריך",
            "סטטוס",
            "סוג",
            "מס עמדה",
            "שם סניף",
            "מיקום ההזמנה",
            "שם לקוח",
            "נייד",
            "סכום (₪)",
            "שולם",
            "",
          ]}
          itemMap={[
            "orderNumber",
            "packageNumber",
            "orderFormatDate",
            "orderFormatStatus",
            "orderFromatType",
            "stationNumber",
            "branchName",
            "currentLocation",
            "userFormatFullName",
            "mobilePhone",
            "orderTotalPrice",
            "orderFormatIspayed",
          ]}
          gravity={{ 2: 2, 8: 2, 9: 2 }}
          items={pagedList}
          onItemEditBtnClick={(itm) => this.onOrderItemEditBtnClick(itm)}
          isLoading={isLoading}
        />
      </div>
    )
  }

  render() {
    const { showOrderEditModal, orderToEdit, pageNum } = this.state
    const { filteredListArr, filterBy } = this.props

    return (
      <div className="ols-wrapper">
        <InputFiled
          extraClass="ols-slct-input"
          onChange={(e) => this.props.onOrdersFilterByChange(e.target.value)}
          icon="magnifying_glass"
          placeholder="חפש..."
          value={filterBy}
        />

        <Filterer
          extraClass="ols-slct-filterer"
          mulSelectsExtraClass="ols-slct-filterer-muls"
          showIsPaidFilter
        />
        <div className="user-list-counter">
          {" "}
          נמצאו {filteredListArr.length} רשומות{" "}
        </div>
        {this.renderOrdersList()}

        <Pagination
          fullList={filteredListArr}
          totalPerPage={50}
          onSubListReady={(pagedList, pageNum) =>
            this.setState({ pagedList, pageNum })
          }
          pageNum={pageNum}
        />

        <CreateEditOrderModal
          show={showOrderEditModal}
          orderToEdit={orderToEdit}
          onSubmitOrderResultClick={(order) =>
            this.onUpdatedOrderSubmitted(order)
          }
          onCancelClick={() =>
            this.setState({ showOrderEditModal: false, orderToEdit: null })
          }
        />
      </div>
    )
  }
}

const mapStateToProp = ({
  ordersList,
  ordersStatuses,
  errors,
  selectOptions,
}) => {
  const { ordersListArr, orderDetails, filteredListArr, filterBy, isLoading } =
    ordersList
  const { ordersStatusesArr, orderStatusesObj } = ordersStatuses
  const { orderTypes } = selectOptions
  const { unauthorized } = errors
  return {
    ordersListArr,
    ordersStatusesArr,
    orderStatusesObj,
    orderTypes,
    filteredListArr,
    orderDetails,
    filterBy,
    unauthorized,
    isLoading,
  }
}

export default connect(mapStateToProp, {
  fetchAllOrders,
  onOrdersFilterByChange,
  updateOrderData,
  setExternalOrderToEdit,
  onStartOrdersLoading,
  getStatusNameFromObj,
})(OrdersListScreen)
