import React from "react"

import { BlueButton } from "../../../../common/BlueButton"
import "./style.css"

const OrderItem = ({ item, itemMap, gravity, isLast, onEditBtnClick }) => {
  const itemClassName = `ol-item-wrapper ${isLast ? "ol-item-last" : ""} ${
    item.optionalWrongDoor ? "attention" : ""
  }`

  return (
    <div className={itemClassName}>
      {renderItemContent(item, itemMap, gravity)}
      <div>
        <BlueButton
          extraClass="ol-edit-btn"
          onClick={(e) => onEditBtnClick(item)}
        >
          עריכה
        </BlueButton>
      </div>
    </div>
  )
}

const renderItemContent = (item, itemMap, gravity) => {
  return itemMap.map((itmMap, index) => {
    const flex = gravity[`${index}`] || 1

    let itemContent = item[itmMap]

    if (itmMap === "orderTotalPrice" && item && item[itmMap]) {
      itemContent = item[itmMap].toFixed(2)
    }

    if (itmMap === "isBLE") {
      if (itemContent) {
        return (
          <div
            style={{ flex }}
            key={index}
            className={"fa fa-bluetooth-b is-ble"}
            aria-hidden={true}
          ></div>
        )
      } else {
        return <div style={{ flex }} key={index}></div>
      }
    }
    if (itmMap === "couponId") {
      const couponText = item.couponName ? item.couponName : "יש"
      if (itemContent) {
        return (
          <div style={{ flex }} key={index}>
            {couponText}
          </div>
        )
      } else {
        return <div style={{ flex }} key={index}></div>
      }
    }

    if (itmMap === "isMonthlyPayment") {
      if (itemContent) {
        return (
          <div style={{ flex }} key={index} className="monthly-payment">
            תשלום חודשי
          </div>
        )
      } else {
        return <div style={{ flex }} key={index}></div>
      }
    }
    if (itmMap === "stationPaymentVersion") {
      return (
        <div style={{ flex }} key={index}>
          {itemContent}
        </div>
      )
    }
    return (
      <div style={{ flex }} key={index}>
        {itemContent}
      </div>
    )
  })
}

export default OrderItem
