import React, { Component } from "react"
import { connect } from "react-redux"
import { H1, LabeledSelect, SubmitButton } from "../../../common"
import {
  downloadPackageDebitReport,
  downloadPackageDebitReportByAccount,
} from "../../../../actions"
//import { fetchOrderTypes } from '../../../../actions';
import "./style.scss"

const reportHeader = 'דו"ח חיובי חבילות'
const monthLabel = "בחר חודש"
const yearLabel = "בחר שנה"
const orderTypeLabel = "בחר סוג הזמנה"

const reportHeader_accountType = 'דו"ח חיובי חבילות חיצוניות '
const accountTypeLabel = "בחר סוג חשבון"

class PackageDebitReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formByOrderType: {
        selectedMonth: initMonth(),
        selectedYear: initYear(),
        selectedOrderType: initOrderType(),

        monthOptions: setMonthList(),
        yearOptions: setYearList(),
        orderTypeOptions: setOrderTypeList(),
      },
      formByAccountType: {
        selectedMonth: initMonth(),
        selectedYear: initYear(),
        selectedAccountType: initAccountType(),

        monthOptions: setMonthList(),
        yearOptions: setYearList(),
        accountTypeOptions: setAccountType(),
      },
    }

    this.onDataChange = this.onDataChange.bind(this)
  }

  onDataChange(form, property, value) {
    let formObj = this.state[form]
    formObj[property] = value
    this.setState({ [form]: formObj })
  }

  onDownloadClick(event) {
    event.preventDefault()
    const { formByOrderType } = this.state
    const { selectedMonth, selectedYear, selectedOrderType } = formByOrderType
    this.props.downloadPackageDebitReport(
      selectedMonth,
      selectedYear,
      selectedOrderType
    )
  }

  onDownloadFormByAccountTypeClick(event) {
    event.preventDefault()
    const { formByAccountType } = this.state
    const { selectedMonth, selectedYear, selectedAccountType } =
      formByAccountType
    const orderType = 8 //MailExternal
    this.props.downloadPackageDebitReportByAccount(
      selectedMonth,
      selectedYear,
      orderType,
      selectedAccountType
    )
  }

  render() {
    //const {  orderTypes } = this.props;

    return (
      <div className="forms-wrapper">
        <div className="form-wrapper billingByOrderType">
          <H1 className="reports-section-header" title={reportHeader} />
          <form onSubmit={(e) => this.onDownloadClick(e)}>
            <div className="form-group">
              <div></div>

              <LabeledSelect
                labelText={monthLabel}
                id="month"
                options={this.state.formByOrderType.monthOptions}
                value={this.state.formByOrderType.selectedMonth}
                emptyOption={false}
                onChange={(e) =>
                  this.onDataChange(
                    "formByOrderType",
                    "selectedMonth",
                    e.target.value
                  )
                }
              />

              <LabeledSelect
                labelText={yearLabel}
                id="year"
                options={this.state.formByOrderType.yearOptions}
                value={this.state.formByOrderType.selectedYear}
                emptyOption={false}
                onChange={(e) =>
                  this.onDataChange(
                    "formByOrderType",
                    "selectedYear",
                    e.target.value
                  )
                }
              />
              <LabeledSelect
                labelText={orderTypeLabel}
                id="orderType"
                //options = {orderTypes}
                options={this.state.formByOrderType.orderTypeOptions}
                value={this.state.formByOrderType.selectedOrderType}
                emptyOption={false}
                onChange={(e) =>
                  this.onDataChange(
                    "formByOrderType",
                    "selectedOrderType",
                    e.target.value
                  )
                }
              />
            </div>
            <SubmitButton className="download-file-button" title="Download" />
          </form>
        </div>
        <div className="form-wrapper billingByOrderTypeAndAccountType">
          <H1
            className="reports-section-header"
            title={reportHeader_accountType}
          />
          <form onSubmit={(e) => this.onDownloadFormByAccountTypeClick(e)}>
            <div className="form-group">
              <div></div>

              <LabeledSelect
                labelText={monthLabel}
                id="month"
                options={this.state.formByAccountType.monthOptions}
                value={this.state.formByAccountType.selectedMonth}
                emptyOption={false}
                onChange={(e) =>
                  this.onDataChange(
                    "formByAccountType",
                    "selectedMonth",
                    e.target.value
                  )
                }
              />

              <LabeledSelect
                labelText={yearLabel}
                id="year"
                options={this.state.formByAccountType.yearOptions}
                value={this.state.formByAccountType.selectedYear}
                emptyOption={false}
                onChange={(e) =>
                  this.onDataChange(
                    "formByAccountType",
                    "selectedYear",
                    e.target.value
                  )
                }
              />
              <LabeledSelect
                labelText={accountTypeLabel}
                id="orderType"
                //options = {orderTypes}
                options={this.state.formByAccountType.accountTypeOptions}
                value={this.state.formByAccountType.selectedAccountType}
                emptyOption={false}
                onChange={(e) =>
                  this.onDataChange(
                    "formByAccountType",
                    "selectedAccountType",
                    e.target.value
                  )
                }
              />
            </div>
            <SubmitButton className="download-file-button" title="Download" />
          </form>
        </div>
      </div>
    )
  }
}

const startYear = 2021
const setYearList = () => {
  let years = []
  let now = new Date()
  now = now.getFullYear()
  for (var i = startYear; i <= now; i++) {
    years[i] = i
  }
  return years
}

const setMonthList = () => {
  let monthNames = [
    "ינואר",
    "פברואר",
    "מרץ",
    "אפריל",
    "מאי",
    "יוני",
    "יולי",
    "אוגוסט",
    "ספטמבר",
    "אוקטובר",
    "נובמבר",
    "דצמבר",
  ]
  let month = []
  for (var i = 0; i < monthNames.length; i++) {
    month[i + 1] = monthNames[i]
  }
  return month
}

const setOrderTypeList = () => {
  let orderType = []
  orderType[13] = "חבילות DHL"
  orderType[14] = "חבילות BAR"
  orderType[15] = "חבילות UPS"
  orderType[6] = "החזרות DHL"
  orderType[12] = "חבילות אקסלוט"
  orderType[7] = "החזרות אקסלוט"
  orderType[17] = "חבילות יד מרדכי"
  orderType[18] = "חבילות גפן מדיקל"
  orderType[19] = "חבילות שדה משה"
  orderType[20] = "חבילות אמירים"
  orderType[21] = "חבילות OneProject"
  orderType[22] = "חבילות ZigZag"
  orderType[23] = "חבילות HFD"
  orderType[24] = "החזרות HFD"
  orderType[25] = "חבילות YDM"
  orderType[26] = "החזרות YDM"
  orderType[30] = "חבילות ברור חיל"

  return orderType
}

const setAccountType = () => {
  let accountType = []

  accountType[12] = "חבילות NegroCoffee"
  accountType[14] = "חבילות CheapSim"
  accountType[22] = "חבילות GameStorm"
  return accountType
}

const initMonth = () => {
  let now = new Date()
  let month = now.getMonth()
  if (month === 0) {
    month = 12
  }
  return month
}
const initYear = () => {
  let now = new Date()
  let year = now.getFullYear()
  if (now.getMonth() === 0) {
    year--
  }
  return year
}
const initOrderType = () => {
  return 13 //DHL
}

const initAccountType = () => {
  return 12 //NegroCoffee
}

const mapStateToProps = ({ selectOptions }) => {
  const { orderTypes } = selectOptions
  return { orderTypes }
}

export default connect(mapStateToProps, {
  downloadPackageDebitReport,
  downloadPackageDebitReportByAccount,
})(PackageDebitReport)
