import React, { Component } from 'react';
import { connect } from 'react-redux';

import { H1, LabeledTextInput, SubmitButton } from '../../../common';
import { downloadOrderItemReport } from '../../../../actions';

import './style.css';

class OrderItemReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderItemNumber: '',
        }
    }

    onInputChange(event) {
        const orderItemNumber = event.target.value;
        this.setState({ orderItemNumber });
    }

    onDownloadClick(event) {
        event.preventDefault();
        const { orderItemNumber } = this.state;
        this.props.downloadOrderItemReport(orderItemNumber);
    }

    render() {
        const { orderItemNumber } = this.state;
        const orderItemReportHeader = "דוח שאילתת פריט";
        const orderItemNumberPlaceholder = "מס' סיריאלי של פריט";

        return (
            <>
                <H1 className="reports-section-header" title={orderItemReportHeader} />
                <form onSubmit={(e) => this.onDownloadClick(e)}>
                    <div className="form-group">
                    <LabeledTextInput
                        className="report-section-labeled-text-input"
                        labelText={orderItemNumberPlaceholder}
                        value={orderItemNumber}
                        onChange={(e) => this.onInputChange(e)}
                    />
                    </div>                    
                    <SubmitButton className="download-file-button" title="Download" />
                </form>
            </>
        );
    }
}

export default connect(null, { downloadOrderItemReport })(OrderItemReport);