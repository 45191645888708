import React, { usestate, useEffect, useState } from "react"
import { connect } from "react-redux"
import { WhiteFrame, Title, BlueButton } from "../../../../common"
import OrderItemsTable from "./OrderItemsTable"
import OrderSummary from "./OrderSummary"
import ChangePackageNumberModal from "../../../../Modals/ChangePackageNumberModal"
import "./style.css"

const OrderItemsFrame = (props) => {
  const {
    order,
    userCompensation,
    showOrderItemsOriginalModal,
    sendDelaySMS,
    showOrderImagesModal,
    sendInLockerSMS,
    InLockerDateExtend,
    sendInLockerReturnSMS,
    sendSMSForChangeStation,
    confirmResetDeliveryLoginCode,
    onOrderChanged,
  } = props

  const [showChangePackageNumberModal, setShowChangePackageNumberModal] =
    useState(false)

  if (!order) {
    return <div>LOADING..</div>
  }

  const isOrderTypeForSMS = () => {
    const { orderType, orderStatus } = order
    const typesAllowed = [2, 5, 8, 12, 13, 14, 16, 17, 18, 19, 20, 22, 25, 32]
    const statusAllowed = [4, 13]
    const isTypeMatch = typesAllowed.includes(orderType)
    const isStatusMatch = statusAllowed.includes(orderStatus)

    if (!isTypeMatch || !isStatusMatch) {
      return false
    }
    return true
  }

  const isOrderTypeForReturnSMS = () => {
    const { orderType, orderStatus } = order
    if (orderStatus === 18 && (orderType === 21 || orderType === 31)) {
      return true
    }
    return false
  }

  const isCanChangeStationSMS = () => {
    const { orderType, orderStatus } = order

    if (
      (orderStatus === 18 || orderStatus === 19) &&
      (orderType === 8 || orderType === 12 || orderType === 18)
    ) {
      return true
    }
    return false
  }

  return (
    <WhiteFrame extraClass="order-itms-table-frame">
      <div className="order-itms-table-wrapper">
        <div className="title-wrapper">
          <Title bold extraClass="qwe-blue-title">
            <span className="order-data-caption"> מס’ הזמנה:</span>
            <span className="">{order.orderNumber}</span>
            <span className="order-data-caption"> עמדה: </span>
            <div className="order-data-value">
              {" " + order.stationNumber + " - " + order.branchName + "  "}
            </div>

            <span className="order-data-caption">
              {order.packageNumber && order.packageNumber.length
                ? "   מס' חבילה: "
                : ""}
            </span>
            <span className="">{order.packageNumber}</span>
            {order.orderStatus == 1 && (
              <BlueButton
                extraClass="btn-tracking"
                onClick={() => setShowChangePackageNumberModal(true)}
              >
                שנה ברקוד נהג
              </BlueButton>
            )}
            <span className="order-data-caption">
              {order.externalOrderNumber && order.externalOrderNumber.length
                ? "מס' חיצוני:"
                : ""}
            </span>
            {order.externalOrderNumber && order.externalOrderNumber.length ? (
              <span
                className="order-data-external"
                title={order.externalOrderNumber}
              >
                {order.externalOrderNumber}
              </span>
            ) : null}

            <div className="delivery-code-wrapper">
              <span className="order-data-caption">
                {order.deliveryLoginCode ? "  קוד בהודעת סמס:   " : ""}
              </span>
              <span className="">{order.deliveryLoginCode}</span>
              <BlueButton
                extraClass="btn-reset-locker-code"
                onClick={() => confirmResetDeliveryLoginCode()}
              >
                איפוס
              </BlueButton>
            </div>
          </Title>
          {order.orderType === 1 ? (
            <div className="laundry-buttons">
              <BlueButton
                extraClass="btn-items-original"
                onClick={(e) => showOrderItemsOriginalModal(true)}
              >
                פריטי הזמנה מקוריים
              </BlueButton>
              <BlueButton
                extraClass="order-editor-bottom-left-green-btn"
                onClick={(e) => sendDelaySMS(order.id)}
              >
                שלח SMS: עיכוב
              </BlueButton>
              {order.orderImage && order.orderImage.length > 0 ? (
                <BlueButton
                  extraClass="btn-items-original"
                  onClick={(e) => showOrderImagesModal(true)}
                >
                  הצג תמונות
                </BlueButton>
              ) : null}
            </div>
          ) : null}

          {isOrderTypeForSMS() ? (
            <div className="btn-send-sms-holder">
              <BlueButton
                extraClass="order-editor-bottom-left-green-btn"
                onClick={(e) => sendInLockerSMS(order.id)}
              >
                שלח SMS: מוכן בלוקר
              </BlueButton>
            </div>
          ) : null}

          <div className="btn-send-sms-holder">
            <BlueButton
              extraClass="order-editor-bottom-left-green-btn"
              onClick={(e) => InLockerDateExtend()}
            >
              הארכת חבילה בלוקר
            </BlueButton>
          </div>

          {isOrderTypeForReturnSMS() ? (
            <div className="btn-send-sms-holder">
              <BlueButton
                extraClass="order-editor-bottom-left-green-btn"
                onClick={(e) => sendInLockerReturnSMS(order.id)}
              >
                שליחת SMS עם קוד
              </BlueButton>
            </div>
          ) : null}
          {isCanChangeStationSMS() ? (
            <div className="btn-send-sms-holder">
              <BlueButton
                extraClass="order-editor-bottom-left-green-btn"
                onClick={(e) => sendSMSForChangeStation(order.id)}
              >
                שלח SMS לשינויי עמדת יעד
              </BlueButton>
            </div>
          ) : null}
        </div>
        <OrderItemsTable
          order={order}
          onOrderChanged={(o) => onOrderChanged(o)}
          userCompensation={userCompensation}
        />

        <OrderSummary order={order} />
      </div>
      <ChangePackageNumberModal
        show={showChangePackageNumberModal}
        order={order}
        onCancelClick={() => setShowChangePackageNumberModal(false)}
      />
    </WhiteFrame>
  )
}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps)(OrderItemsFrame)
