import React, { Component } from "react"
import Modal from "../Modal"
import { SmallHeader, InputFiled, PendingSuccessFailBtn } from "../../common"
import "./style.scss"

class LoginModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobile: "",
      pwd: "",
    }
  }

  onInputChange(inputName, val) {
    this.setState({ [inputName]: val })
  }

  onSubmitBtnClick() {
    const { mobile, pwd } = this.state
    this.props.onSubmitClick(mobile, pwd)
  }

  renderSubmitBtnOrLoadingSpinner(isLoading, showError) {
    const btnStatus = isLoading ? 0 : showError ? -1 : 2

    return (
      <PendingSuccessFailBtn
        btnText="כניסה"
        status={btnStatus}
        btnExtraClass="login-modal-button"
        onBtnClick={() => this.onSubmitBtnClick()}
        onSuccessFailClick={(success) => this.props.onSuccessFailClick(success)}
      />
    )
  }

  filterEnterAndSubmit(e) {
    if (e.key === "Enter") {
      const { isSubmitBtnDisabled } = this.state

      if (!isSubmitBtnDisabled) {
        this.onSubmitBtnClick()
      }
    }
  }

  render() {
    const { show, loading, isLoginFailed, errorMsg } = this.props

    if (!show) {
      return <noscript />
    }
    const { mobile, pwd } = this.state

    return (
      <Modal extraClass="login-modal-wrap">
        <div className="login-modal-container">
          <SmallHeader />
          <div className="login-modal-inputs-wrapper">
            ברוכים הבאים אל מערכת הניהול של DONE
            <InputFiled
              value={mobile}
              onChange={(e) => this.onInputChange("mobile", e.target.value)}
              extraClass="login-modal-input"
              onKeyPress={(e) => this.filterEnterAndSubmit(e)}
            />
            <InputFiled
              value={pwd}
              type="password"
              onChange={(e) => this.onInputChange("pwd", e.target.value)}
              extraClass="login-modal-input"
              onKeyPress={(e) => this.filterEnterAndSubmit(e)}
            />
            <div className="auth-error">{errorMsg}</div>
            {this.renderSubmitBtnOrLoadingSpinner(loading, isLoginFailed)}
            {isLoginFailed}
          </div>
        </div>
      </Modal>
    )
  }
}

export default LoginModal
