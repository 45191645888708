import axios from "axios"
import api from "../api"

import {
  ON_CREATE_USER_SUCCESS,
  ON_USER_DATA_FIELD_UPDATE,
  FETCH_EXISTING_USER_DATA,
  CLEAR_CURRENT_CREATE_EDIT_USER_DATA,
  ON_CREATE_UPDATE_USER_REQUEST,
  ON_CREATE_UPDATE_USER_ERROR,
  ON_UPDATE_USER_SUCCESS,
  ON_GET_COUNTRIES_SUCCESS,
  ON_GET_LANGUAGE_SUCCESS,
  ON_USER_CCTOKEN_REMOVED_SUCCESS,
  ON_USER_CCTOKEN_REMOVED_FAIL,
  ON_SET_USER_ID,
  ON_CREATE_UPDATE_USER_EXISTING_ERROR,
  CLEAR_USER_EXISTING_ERROR,
} from "./types"

export const createNewUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: ON_CREATE_UPDATE_USER_REQUEST,
    })

    axios
      .post(api.createNewUserUrl, JSON.stringify(api.createNewUserBody(user)), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("createNewUser", res.data)
        dispatch({
          type: ON_CREATE_USER_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log("ERROR AT: createNewUser", err)
        const errormsg = err.response ? err.response.data : ""
        dispatch({
          type: ON_CREATE_UPDATE_USER_ERROR,
          payload: errormsg,
        })
      })
  }
}

export const updateUserData = (updatedUser) => {
  return (dispatch) => {
    dispatch({
      type: ON_CREATE_UPDATE_USER_REQUEST,
    })

    axios
      .put(api.updateUser(updatedUser.id), JSON.stringify(updatedUser), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("updateUserData", res)

        dispatch({
          type: ON_UPDATE_USER_SUCCESS,
        })
      })
      .catch((err) => {
        if (err.response.data == "mobile phone already taken") {
          dispatch({
            type: ON_CREATE_UPDATE_USER_EXISTING_ERROR,
          })
        } else {
          console.log("ERROR AT: updateUserData", err)
          dispatch({
            type: ON_CREATE_UPDATE_USER_ERROR,
          })
        }
      })
  }
}

export const clearUserExistError = (filedName, val) => {
  return {
    type: CLEAR_USER_EXISTING_ERROR,
  }
}

export const onUserDataFieldUpdate = (filedName, val) => {
  return {
    type: ON_USER_DATA_FIELD_UPDATE,
    payload: {
      filedName,
      val,
    },
  }
}

export const fetchExistingUserDataForUpdate = (userId) => {
  return (dispatch) => {
    axios
      .get(api.getUserById(userId))
      .then((res) => {
        dispatch({
          type: FETCH_EXISTING_USER_DATA,
          payload: res.data,
        })
      })
      .catch((err) => console.log(err))
  }
}

export const setUserId = (userId) => {
  return {
    type: ON_SET_USER_ID,
    payload: userId,
  }
}

export const clearCurrentCreateEditUserData = () => {
  return {
    type: CLEAR_CURRENT_CREATE_EDIT_USER_DATA,
  }
}

export const updateUserCompensation = (
  userId,
  newCompValue,
  customerDiscount
) => {
  return (dispatch) => {
    axios
      .put(
        api.updateUser(userId),
        {
          compensation: newCompValue,
          customerDiscount,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("updateUserCompensation", res)
      })
      .catch((err) => {
        console.log("ERROR AT: updateUserCompensation", err)
      })
  }
}

export const getAllCountries = () => {
  return (dispatch) => {
    axios
      .get(api.getCountries)
      .then((res) => {
        dispatch({
          type: ON_GET_COUNTRIES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log("Failed to fetch countries", err)
      })
  }
}

export const getLanguages = () => {
  return (dispatch) => {
    axios
      .get(api.getLanguages)
      .then((res) => {
        dispatch({
          type: ON_GET_LANGUAGE_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log("Failed to fetch languages", err)
      })
  }
}

export const removeCCToken = (userId) => {
  return (dispatch) => {
    axios
      .put(api.removeUserCCToken(userId))
      .then((res) => {
        console.log("removeCCToken", res)
        dispatch({
          type: ON_USER_CCTOKEN_REMOVED_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log("removeCCToken", err)
        dispatch({ type: ON_USER_CCTOKEN_REMOVED_FAIL })
      })
  }
}
