import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { BlueButton } from "../../../common"
import NotificationModal from "../../../Modals/NotificationModal"


import {
  startLockersLoading,
  fetchStationLockers,
  setLockersStation,
  fetchLockerStatusSelectOptions,
  fetchLockerTypeSelectOptions,
  fetchAllStationData,
  resetUpdateLockerStatusError,
  setLockersHistoryStation,
  fetchReadyToLoadOrders,
  onLoadingReadyToLoadOrders,
  fetchAllOrderStatuses,
  fetchOrderTypes,
  fetchWaitingForPickupOrders,
  onLoadingWaitingForPickupOrders,
  fetchLockerSizes,
  updateLockerSize,
} from "../../../../actions"

import { fetchIfEmpty } from "../../../../utils"
import LockersList from "./LockersList"
import LoadOrderToLockerModal from "../../../Modals/LoadOrderToLockerModal"
import LoadOrderWaitingForPickupToLockerModal from "../../../Modals/LoadOrderWaitingForPickupToLockerModal"
import LoadDHLReturnOrderToLockerModal from "../../../Modals/LoadDHLReturnOrderToLockerModal"
import "./style.css"

class LockersPage extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      updatedLocker: null,
      lockerToLoadOrder: { lockerName: "תא לא נבחר" },
      lockerToLoadWaitingForPickupOrder: { lockerName: "תא לא נבחר" },
      lockerToLoadOrderDHLReturns: { lockerName: "תא לא נבחר" },
      showOrderLoadingModal: false,
      showOrderLoadingModal2: false,
      showDHLOrderLoadingModal: false,
      showSizeUpdated: false,
      showSizeUpdatedError: false,
      hasFetchedLockers: false,
    }
    this.state = this.INIT_STATE

    this.handleStationsFilterChange = this.handleStationsFilterChange.bind(this)
    this.fetchLockers = this.fetchLockers.bind(this)
  }

  componentDidMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }

    fetchIfEmpty(
      this.props.lockerStatuses,
      this.props.fetchLockerStatusSelectOptions
    )
    fetchIfEmpty(this.props.stationsOptions, this.props.fetchAllStationData)
    fetchIfEmpty(
      this.props.lockerTypes,
      this.props.fetchLockerTypeSelectOptions
    )

    fetchIfEmpty(this.props.orderTypes, this.props.fetchOrderTypes)
    fetchIfEmpty(this.props.lockersSizes, this.props.fetchLockerSizes)
    const { currentLockersStationId } = this.props

    if (currentLockersStationId && currentLockersStationId.length > 0) {
      this.fetchLockers(currentLockersStationId)
    }
  }

  componenWillUnmount() {
    this.setState({
      updatedLocker: null,
      lockerToLoadOrder: { lockerName: "תא לא נבחר" },
      lockerToLoadWaitingForPickupOrder: { lockerName: "תא לא נבחר" },
      lockerToLoadOrderDHLReturns: { lockerName: "תא לא נבחר" },
      showOrderLoadingModal: false,
      showOrderLoadingModal2: false,
      showDHLOrderLoadingModal: false,
    })
  }


  componentDidUpdate(prevProps) {
    const { 
      unauthorized, 
      history, 
      returnsOrderSuccess, 
      isLockerSizeUpdated, 
      isError, 
      currentLockersStationId 
    } = this.props;

    if (!prevProps.unauthorized && unauthorized) {
      history.push("/");
    }
  
    if (isLockerSizeUpdated !== prevProps.isLockerSizeUpdated || 
        isError !== prevProps.isError) {
      if (isLockerSizeUpdated && !isError && isError !== undefined) {
        this.setState({
          showSizeUpdated: true,
          showSizeUpdatedError: false,
        });
      } else if (isLockerSizeUpdated && isError) {
        this.setState({ showSizeUpdatedError: true });
      }
  
      if (currentLockersStationId && currentLockersStationId.length > 0) {
        this.fetchLockers(currentLockersStationId);
      }

    }
    if (this.props.returnsOrderSuccess && !prevProps.returnsOrderSuccess) {
      this.setState({
        updatedLocker: null,
      }, () => {
        this.fetchLockers(this.props.currentLockersStationId);
      });
    }
  }


  fetchLockers(stationId) {
    this.props.startLockersLoading()
    this.props.fetchStationLockers(stationId)
  }

  handleStationsFilterChange(item) {
    this.props.setLockersStation(item.stationId)
    this.fetchLockers(item.stationId)
  }
  onLockersHistoryBtnClick() {
    const { currentLockersStationId, history } = this.props
    if (!currentLockersStationId || currentLockersStationId.length <= 0) {
      return
    }
    this.props.setLockersHistoryStation(currentLockersStationId)
    history.push("/LockersScreen/history")
  }

  onLoadOrderToLockerClick(locker) {
    this.setState({ showOrderLoadingModal: true, lockerToLoadOrder: locker })
    const { currentLockersStationId } = this.props
    if (!currentLockersStationId || currentLockersStationId.length <= 0) {
      return
    }
    this.props.onLoadingReadyToLoadOrders()
    this.props.fetchReadyToLoadOrders(currentLockersStationId)
  }

  onLoadOrderToLockerClick2(locker) {
    this.setState({
      showOrderLoadingModal2: true,
      lockerToLoadWaitingForPickupOrder: locker,
    })
    const { currentLockersStationId } = this.props
    if (!currentLockersStationId || currentLockersStationId.length <= 0) {
      return
    }
    this.props.onLoadingWaitingForPickupOrders()
    this.props.fetchWaitingForPickupOrders(currentLockersStationId)
  }

  onLoadOrderDHLReturnsClick(locker) {
    this.setState({
      showDHLOrderLoadingModal: true,
      lockerToLoadOrderDHLReturns: locker,
    })
  }

  onSelectLockerSize(lockerId, lockerSizeId) {
    this.props.updateLockerSize(lockerId, lockerSizeId)
  }

  render() {
    const {
      isLoading,
      stationsOptions,
      currentStationItem,
      stationLockers,
      currentLockersStationId,
    } = this.props

    const {
      lockerToLoadOrder,
      lockerToLoadWaitingForPickupOrder,
      lockerToLoadOrderDHLReturns,
      showOrderLoadingModal,
      showOrderLoadingModal2,
      showDHLOrderLoadingModal,
      showSizeUpdated,
      showSizeUpdatedError,
    } = this.state
    return (
      <div className="station-lockers-wrapper">
        <div className="lockers-filterer">
          <div className="select-autocomplete-wrapper sations-list">
            <Select
              placeholder="עמדה"
              value={currentStationItem}
              options={stationsOptions}
              onChange={(itm) => this.handleStationsFilterChange(itm)}
              classNamePrefix="base-select"
            />
          </div>
          <div className="history-wrapper">
            <BlueButton
              extraClass="history-btn"
              onClick={(e) => this.onLockersHistoryBtnClick()}
            >
              <div className="fas fa-history"></div>
            </BlueButton>
          </div>
        </div>
        <div className="list-counter"> תאים {stationLockers.length} </div>

        <LockersList
          items={stationLockers}
          isLoading={isLoading}
          history={this.props.history}
          onLoadOrderToLockerClick={(e) => this.onLoadOrderToLockerClick(e)}
          onLoadOrderToLockerClick2={(e) => this.onLoadOrderToLockerClick2(e)}
          onLoadOrderDHLReturnsClick={(e) => this.onLoadOrderDHLReturnsClick(e)}
          lockerSizeOptions={this.props.lockerSizeOptions}
          onSelectLockerSize={(lockerId, lockerSizeId) =>
            this.onSelectLockerSize(lockerId, lockerSizeId)
          }
        />

        <NotificationModal
          show={this.props.showErrorUpdateLockerStatus}
          title={"עידכון סטטוס תא"}
          text={"שים לב! שגיא בביצוע עדכון סטטוס"}
          onOkClick={(e) => this.props.resetUpdateLockerStatusError()}
        />

        <NotificationModal
          show={showSizeUpdated}
          title={"עידכון גודל התא"}
          text={"גודל תא עודכן בהצלחה"}
          onOkClick={(e) => this.setState({ showSizeUpdated: false })}
        />

        <NotificationModal
          show={showSizeUpdatedError}
          title={"עידכון גודל התא"}
          text={"שים לב! שגיא בביצוע עדכון גודל התא"}
          onOkClick={(e) => this.setState({ showSizeUpdatedError: false })}
        />

        <LoadOrderToLockerModal
          show={showOrderLoadingModal}
          locker={lockerToLoadOrder}
          onClose={() => {
            this.setState({ showOrderLoadingModal: false })
            this.fetchLockers(currentLockersStationId)
          }}
          currentStationItem={currentStationItem}
        />
        <LoadOrderWaitingForPickupToLockerModal
          show={showOrderLoadingModal2}
          locker={lockerToLoadWaitingForPickupOrder}
          onClose={() => {
            this.setState({ showOrderLoadingModal2: false })
            this.fetchLockers(currentLockersStationId)
          }}
          currentStationItem={currentStationItem}
        />
        
        <LoadDHLReturnOrderToLockerModal
          show={showDHLOrderLoadingModal}
          locker={lockerToLoadOrderDHLReturns}
          stationId={currentLockersStationId}
          onClose={() => 
            this.setState({ showDHLOrderLoadingModal: false })
            
          }
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  errors,
  lockersList,
  selectOptions,
  stationsList,
  orderToLocker,
}) => {
  const { unauthorized } = errors
  const { lockerStatuses, lockerTypes, orderTypes, lockersSizes } =
    selectOptions
  const { stationsItemSelectList } = stationsList
  const stationsOptions = stationsItemSelectList
  const {
    currentLockersStationId,
    stationLockers,
    isLoading,
    isError,
    isLockerSizeUpdated,
    showErrorUpdateLockerStatus,
  } = lockersList
  const { returnsOrderSuccess } = orderToLocker
  const currentStationItem = stationsItemSelectList.filter(
    (item) => item.stationId === currentLockersStationId
  )

  const lockerSizeOptions = lockersSizes
    ? lockersSizes.map((o) => {
        return {
          label: o.name,
          value: o,
          rawSize: `${o.width}x${o.height}x${o.depth}`,
        }
      })
    : []

  return {
    unauthorized,
    currentLockersStationId,
    stationLockers,
    isLoading,
    isError,
    stationsOptions,
    lockerStatuses,
    lockerTypes,
    currentStationItem:
      currentStationItem.length > 0 ? currentStationItem[0] : "",
    showErrorUpdateLockerStatus,
    orderTypes,
    lockersSizes,
    lockerSizeOptions,
    isLockerSizeUpdated,
    returnsOrderSuccess,
  }
}

export default connect(mapStateToProps, {
  startLockersLoading,
  fetchStationLockers,
  setLockersStation,
  fetchLockerStatusSelectOptions,
  fetchLockerTypeSelectOptions,
  fetchAllStationData,
  resetUpdateLockerStatusError,
  setLockersHistoryStation,
  fetchReadyToLoadOrders,
  onLoadingReadyToLoadOrders,
  fetchAllOrderStatuses,
  fetchOrderTypes,
  fetchWaitingForPickupOrders,
  onLoadingWaitingForPickupOrders,
  fetchLockerSizes,
  updateLockerSize,
})(LockersPage)
