import React, { Component } from "react"
import { connect } from "react-redux"
import { SmallHeader, BlueButton } from "../../common"
import Modal from "../Modal"
import UserSubscriptionsList from "../UserSubscriptionsModal/UserSubscriptionsList"
//import getFormatedDate from "../../../utils/FormatedDate"
import { fetchIfEmpty } from "../../../utils"
import {
  fetchExistingUserDataForUpdate,
  fetchUserSubscriptions,
  cancelUserSubscription,
  cleanUserSubscriptions,
  fetchOrderTypes,
} from "../../../actions"

import "./style.scss"

class UserSubscriptionsModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showResultCancelSubscription: false,
      showShureCancelSubscription: false,
      activeSubscription: null,
    }
  }

  componentDidMount() {
    this.setState({
      showShureCancelSubscription: false,
      activeSubscription: null,
    })

    fetchIfEmpty(this.props.orderTypes, this.props.fetchOrderTypes)
  }

  componentWillUnmount() {
    this.props.cleanUserSubscriptions()
  }

  removeSubscription() {
    const { activeSubscription } = this.state

    this.props.cancelUserSubscription(activeSubscription.userSubscribtionId)
  }

  render() {
    if (!this.props.show) {
      return <noscript />
    }

    const {
      isLoadingSubscriptions,
      isGetSubscriptionsError,
      isCancelSubscriptionSuccessed,
      isCancelSubscriptionError,
      userSubscriptionsList,
      orderTypes,
    } = this.props

    if (isLoadingSubscriptions) {
      return (
        <Modal extraClass="cror-modal-wrap">
          <div className="cror-modal-container user-subscriptions">
            <SmallHeader title={" מנוים "} />
            <div className="cror-modal-inputs-wrapper">
              <div className="cror-modal-title">
                <div className="title"> מנוים </div>
                <div className="order-number"> </div>
              </div>
              {isLoadingSubscriptions && <div>טוען...</div>}

              <div className="cror-modal-status">
                <BlueButton
                  extraClass="cror-modal-button"
                  onClick={(e) => this.props.onClose()}
                >
                  סגור
                </BlueButton>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    const {
      showShureCancelSubscription,
      activeSubscription,
      showResultCancelSubscription,
    } = this.state

    if (showShureCancelSubscription && activeSubscription !== null) {
      return (
        <Modal extraClass="cror-modal-wrap">
          <div className="cror-modal-container user-subscriptions">
            <SmallHeader title={" מנוים - ביטול "} />
            <div className="cror-modal-inputs-wrapper">
              <div className="cror-modal-title">
                <div className="title">
                  האם בטוח רוצים לבטל הרשמה למנוי חודשי?
                </div>
              </div>
              <div className="subscribe-param">
                <div className="label">שם מנוי</div>
                <div className="value">
                  {activeSubscription.subscribtionName}
                </div>
              </div>
              <div className="subscribe-param">
                <div className="label">סוג מנוי</div>
                <div className="value">
                  {activeSubscription.subscribtionOrderType}
                </div>
              </div>
              <div className="subscribe-param">
                <div className="label"> תאריך התחלה</div>
                <div className="value">{activeSubscription.startDate}</div>
              </div>
              <div className="subscribe-param">
                <div className="label">יום תשלום בחודש</div>
                <div className="value">{activeSubscription.dayInMonth}</div>
              </div>
              <div className="subscribe-param">
                <div className="label"> סכום חודשי</div>
                <div className="value">
                  {activeSubscription.subscribtionAmount}₪
                </div>
              </div>
              <div className="subscribe-param">
                <div className="label">שם בהרשמה </div>
                <div className="value">{activeSubscription.fullName}</div>
              </div>
              <div className="subscribe-param">
                <div className="label">עמדה בהרשמה </div>
                <div className="value">{activeSubscription.stationInfo}</div>
              </div>
              <div>
                <div></div>
                <div className="action-btns">
                  <BlueButton
                    extraClass="cror-modal-button"
                    onClick={() => {
                      this.setState({
                        showResultCancelSubscription: true,
                        showShureCancelSubscription: false,
                      })
                      this.removeSubscription()
                    }}
                  >
                    בטל הרשמה
                  </BlueButton>
                  <BlueButton
                    extraClass="cror-modal-button"
                    onClick={(e) =>
                      this.setState({
                        showShureCancelSubscription: false,
                        activeSubscription: null,
                      })
                    }
                  >
                    סגור
                  </BlueButton>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    if (showResultCancelSubscription) {
      //isCancelSubscriptionSuccessed ||  && !isLoadingSubscriptions
      return (
        <Modal extraClass="cror-modal-wrap">
          <div className="cror-modal-container user-subscriptions">
            <SmallHeader title={" מנוים - ביטול "} />
            <div className="cror-modal-inputs-wrapper">
              <div className="cror-modal-title">
                {isLoadingSubscriptions && (
                  <div className="title">אנא המתן...</div>
                )}
                {isCancelSubscriptionSuccessed && (
                  <div className="title">מנוי בוטל בהצלחה</div>
                )}
                {isCancelSubscriptionError && (
                  <div className="title">שגיא בביטול מנוי</div>
                )}
              </div>
              <div>
                <div className="action-btns">
                  <BlueButton
                    extraClass="cror-modal-button"
                    onClick={(e) => {
                      this.setState({
                        showResultCancelSubscription: false,
                        activeSubscription: null,
                      })
                      this.props.fetchExistingUserDataForUpdate(
                        this.props.userData.id
                      )
                      this.props.fetchUserSubscriptions(this.props.userData.id)
                    }}
                  >
                    סגור
                  </BlueButton>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    return (
      <Modal extraClass="cror-modal-wrap">
        <div className="cror-modal-container user-subscriptions">
          <SmallHeader title={" מנוים "} />
          <div className="cror-modal-inputs-wrapper">
            <div className="cror-modal-title">
              <div className="title"> מנוים </div>
              <div className="order-number"> </div>
            </div>

            {isGetSubscriptionsError && <div>תקלה בטעינת מנויים</div>}
            {!isGetSubscriptionsError && (
              <UserSubscriptionsList
                itemsList={userSubscriptionsList}
                orderTypes={orderTypes}
                cancelUserSubscription={(userSubscription) => {
                  this.setState({
                    showShureCancelSubscription: true,
                    activeSubscription: userSubscription,
                  })
                }}
              />
            )}

            <div className="cror-modal-status">
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => this.props.onClose()}
              >
                סגור
              </BlueButton>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
const mapStateToProps = ({ selectOptions, userSubscriptions }) => {
  const { orderTypes } = selectOptions
  const {
    userSubscriptionsList,
    isLoadingSubscriptions,
    isGetSubscriptionsError,
    isCancelSubscriptionError,
    isCancelSubscriptionSuccessed,
  } = userSubscriptions
  console.log("isGetSubscriptionsError" + isGetSubscriptionsError)
  return {
    userSubscriptionsList,
    isLoadingSubscriptions,
    isGetSubscriptionsError,
    isCancelSubscriptionError,
    isCancelSubscriptionSuccessed,
    orderTypes,
  }
}

export default connect(mapStateToProps, {
  fetchExistingUserDataForUpdate,
  fetchUserSubscriptions,
  cancelUserSubscription,
  cleanUserSubscriptions,
  fetchOrderTypes,
})(UserSubscriptionsModal)
