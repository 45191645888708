import {
    ON_BALANCE_FETCH_START,
    ON_BALANCE_FETCH_SUCCESS,
} from '../actions/types';

const INIT_STATE = {
    balanceIsFetching: false,
}

const ReportsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ON_BALANCE_FETCH_START:
            console.log("ON_BALANCE_FETCH_START");
            return {
                ...state,
                balanceIsFetching: true,
            }
        case ON_BALANCE_FETCH_SUCCESS:
            console.log("ON_BALANCE_FETCH_SUCCESS");
            return {
                ...state,
                balanceIsFetching: false,
            }
        default:
            return state;
    }
}

export default ReportsReducer;