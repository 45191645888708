import React, { Component } from "react"
import { connect } from "react-redux"

import Slider from "./Slider"
import OrderSelectMenu from "./OrderSelectMenu"
import OrderEditor from "./OrderEditor"

import {
  fetchAllOrders,
  getOrderDetails,
  getUserDetails,
  clearUpdateSuccessFailStatus,
  clearReadyInLockerSMS,
  createNewOrder,
  onOrdersFilterByChange,
  fetchStationPriceList,
  onCreateNewOrder,
  onCreateNewMailOrder,
  createNewMailOrder,
  clearNewMailOrder,
  fetchCouponById,
  setEmptyCoupon,
  clearUsersSearch,
} from "../../../actions"

import NotificationModal from "../../Modals/NotificationModal"
import CreateEditOrderModal from "../../Modals/CreateEditOrderModal"
import CreateEditMailOrderModal from "../../Modals/CreateEditMailOrderModal"
import BlurFilterWithMessage from "./BlurFilterWithMessage"
import "./style.css"

class OrdersEditingScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedIndex: null,
      selectedOrder: null,
      showEditOrderModal: false,
      showEditMailOrderModal: false,
      filterByValue: "",
      filterByDebounceTimeOut: null,
      showBlurryMessage: null,
    }
  }

  componentWillMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }
    const { externalOrderToEdit, filterBy } = this.props

    if (externalOrderToEdit) {
      this.onOrderItemClick(externalOrderToEdit)
    } else {
      console.log(
        "OrdersEditingScreen --> componentWillMount (call fetchAllOrders)"
      )
      this.props.fetchAllOrders()
    }

    this.setState({ filterByValue: filterBy })
  }

  componentWillReceiveProps(newProps) {
    const {
      unauthorized,
      history,
      updateOrderSuccess,
      orderDetails,
      specificUserDetails,
    } = newProps
    const { selectedOrder } = this.state
    if (unauthorized) {
      history.push("/")
    }
    if (selectedOrder && updateOrderSuccess === selectedOrder.orderId) {
      this.setState({ showBlurryMessage: true })
    }

    if (orderDetails && specificUserDetails == null) {
      const payingCustomerMobile =
        orderDetails.orderType === 5
          ? orderDetails.senderMobilePhone
          : orderDetails.mobilePhone
      this.props.getUserDetails(payingCustomerMobile)
    }
  }

  onOrderItemClick(order) {
    console.log("onOrderItemClick", "order", order)
    this.props.clearUsersSearch()
    const { filteredListArr } = this.props
    this.props.setEmptyCoupon()
    this.props.clearUpdateSuccessFailStatus()
    this.props.getOrderDetails(order.orderId)
    this.props.fetchStationPriceList(order.stationId)
    this.props.fetchCouponById(order.couponId)
    this.props.clearReadyInLockerSMS()

    this.setState({
      selectedIndex: filteredListArr.indexOf(order),
      selectedOrder: order,
    })
  }

  onNewOrderSubmitted(order) {
    this.props.onCreateNewOrder()
    this.props.createNewOrder(order)
    this.setState({ showEditOrderModal: false })
  }

  onNewMailOrderSubmitted(order) {
    this.props.createNewMailOrder(order)
  }

  onNewOrderModalClose() {
    this.setState({ showEditOrderModal: false })
    this.props.onCreateNewOrder()
  }

  onNewMailOrderModalClose() {
    this.setState({ showEditMailOrderModal: false })
    this.props.clearNewMailOrder()
  }

  onOrdersFilterByChange(keyword) {
    let { filterByDebounceTimeOut } = this.state

    if (filterByDebounceTimeOut) {
      clearTimeout(filterByDebounceTimeOut)
    }
    console.log("filterByDebounceTimeOut -> setTimeout set")
    filterByDebounceTimeOut = setTimeout(() => {
      console.log("filterByDebounceTimeOut -> setTimeout call")
      this.props.onOrdersFilterByChange(keyword)
    }, 800)

    this.setState({
      filterByDebounceTimeOut,
      filterByValue: keyword,
    })
  }

  onCloseBlurryMessage() {
    this.onOrderItemClick(this.state.selectedOrder)
    this.setState({ showBlurryMessage: false })
  }

  render() {
    const {
      selectedIndex,
      selectedOrder,
      showEditOrderModal,
      showEditMailOrderModal,
      filterByValue,
      showBlurryMessage,
    } = this.state
    const {
      filteredListArr,
      updateOrderSuccess,
      orderDetails,
      specificUserDetails,
      updateOrderError,
      orderCoupon,
      isOrderCreated,
      createOrderError,
      sendSmsSuccess,
      sendSmsFail,
      isMailOrderCreated,
      createMailOrderError,
    } = this.props

    return (
      <div className="oes-wrapper">
        <Slider>
          <OrderSelectMenu
            ordersList={filteredListArr}
            onItemClick={(order) => this.onOrderItemClick(order)}
            selectedItemIndex={selectedIndex}
            onNewOrderBtnClick={() =>
              this.setState({ showEditOrderModal: true })
            }
            onNewMailOrderBtnClick={() =>
              this.setState({ showEditMailOrderModal: true })
            }
            searchVal={filterByValue}
            onSearchChange={(txt) => this.onOrdersFilterByChange(txt)}
          />
        </Slider>
        <div className="oes-editor-and-blur-filter-container">
          <div
            className={`${
              showBlurryMessage ? "oes-editor-container-blur-with-filter" : ""
            }
                    `}
          >
            {selectedOrder ? (
              <OrderEditor
                order={selectedOrder}
                orderDetails={orderDetails}
                userDetails={specificUserDetails}
                coupon={orderCoupon}
              />
            ) : (
              <h1 className="choose-for-edit">אנא בחר הזמנה לעדכון</h1>
            )}
          </div>

          <BlurFilterWithMessage
            show={showBlurryMessage}
            msg="הפעולה בוצעה בהצלחה, בכדי לערוך את ההזמנה הבאה, בחר אותה מהרשימה שמימין"
            onClose={() => this.onCloseBlurryMessage()}
          />

          <NotificationModal
            show={sendSmsSuccess}
            title="הצלחה"
            text="הודעה נשלחה בהצלחה."
            onOkClick={() => this.props.clearReadyInLockerSMS()}
          />

          <NotificationModal
            show={sendSmsFail}
            title="שגיא"
            text="כישלון בשליחת הודעה"
            onOkClick={() => this.props.clearReadyInLockerSMS()}
          />

          <BlurFilterWithMessage
            show={updateOrderError}
            msg="תקלה בעת נסיון עדכון ואישור ההזמנה."
            onClose={() => this.props.clearUpdateSuccessFailStatus()}
          />
        </div>
        <CreateEditOrderModal
          show={showEditOrderModal}
          onSubmitOrderResultClick={(order) => this.onNewOrderSubmitted(order)}
          onCancelClick={() => this.onNewOrderModalClose()}
        />
        <CreateEditMailOrderModal
          show={showEditMailOrderModal}
          onSubmitOrderResultClick={(order) =>
            this.onNewMailOrderSubmitted(order)
          }
          onCancelClick={() => this.onNewMailOrderModalClose()}
        />

        <NotificationModal
          show={isOrderCreated}
          text="ההזמנה חדשה הוספה בהצלחה."
          onOkClick={(e) => this.props.onCreateNewOrder()}
        />
        <NotificationModal
          show={createOrderError}
          text="תקלה בעת הוספת ההזמנה חדשה."
          onOkClick={(e) => this.props.onCreateNewOrder()}
        />
        <NotificationModal
          show={isMailOrderCreated}
          text="הזמנת דואר חדשה נוספה בהצלחה."
          onOkClick={(e) => this.props.clearNewMailOrder()}
        />
        <NotificationModal
          show={createMailOrderError}
          text="תקלה בעת הוספת ההזמנת דואר חדשה."
          onOkClick={(e) => this.props.clearNewMailOrder()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  ordersList,
  usersList,
  stationsList,
  errors,
  coupons,
}) => {
  const { specificUserDetails } = usersList
  const {
    ordersListArr,
    filteredListArr,
    orderDetails,
    filterBy,
    externalOrderToEdit,
    updateOrderSuccess,
    updateOrderError,
    isOrderCreated,
    createOrderError,
    sendSmsSuccess,
    sendSmsFail,
    isMailOrderCreated,
    createMailOrderError,
  } = ordersList
  const { stationPriceList } = stationsList
  const { unauthorized } = errors

  const { coupon } = coupons
  const orderCoupon = coupon

  return {
    ordersListArr,
    filteredListArr,
    orderDetails,
    specificUserDetails,
    filterBy,
    externalOrderToEdit,
    updateOrderSuccess,
    updateOrderError,
    unauthorized,
    orderCoupon,
    isOrderCreated,
    createOrderError,
    sendSmsSuccess,
    sendSmsFail,
    isMailOrderCreated,
    createMailOrderError,
  }
}

export default connect(mapStateToProps, {
  fetchAllOrders,
  getOrderDetails,
  clearUpdateSuccessFailStatus,
  clearReadyInLockerSMS,
  getUserDetails,
  createNewOrder,
  onCreateNewOrder,
  onCreateNewMailOrder,
  createNewMailOrder,
  onOrdersFilterByChange,
  fetchStationPriceList,
  fetchCouponById,
  clearNewMailOrder,
  setEmptyCoupon,
  clearUsersSearch,
})(OrdersEditingScreen)
