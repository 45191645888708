import React from "react";

import DesignedListItem from "./DesignedListItem";

import "./style.css";

const DesignedList = ({ items, onItemClick, selectedItemIndex }) => {
  return (
    <div className="designed-list-wrapper">
      {renderItems(items, selectedItemIndex, onItemClick)}
    </div>
  );
};

const renderItems = (items, selectedItemIndex, onItemClick) => {
  if (!items) {
    return;
  }

  return items.map((itm, index) => {
    return (
      <DesignedListItem
        isSelected={index === selectedItemIndex}
        item={itm}
        key={index}
        onItemClick={onItemClick}
      />
    );
  });
};

export default DesignedList;
