import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  fetchAllOrders,
  getOrderDetails,
  setExternalOrderToEdit,
  fetchAllOrderStatuses,
  setAuthorizationManual,
} from "../../../actions"
import axios from "axios"
import Cookies from "js-cookie"

const OrderEditExternalScreen = (props) => {
  useEffect(() => {
    const token = Cookies.get("token")
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
    }

    const urlParams = new URLSearchParams(window.location.search)
    const orderId = urlParams.get("orderId")
    props.setAuthorizationManual()
    props.fetchAllOrderStatuses()
    props.fetchAllOrders()
    props.getOrderDetails(orderId)
  }, [])

  useEffect(() => {
    if (props.orderDetails) {
      props.orderDetails.orderId = props.orderDetails.id
      props.setExternalOrderToEdit(props.orderDetails)
    }
  }, [props.orderDetails])

  useEffect(() => {
    if (
      props.ordersListArr.length > 0 &&
      props.orderDetails &&
      props.externalOrderToEdit
    ) {
      //this.setState({ orderForEdit: null })

      props.history.push("/OrdersEditingScreen")
    }
  }, [
    props.ordersListArr.length,
    props.orderDetails,
    props.externalOrderToEdit,
  ])

  return <section></section>
}

const mapStateToProps = ({ ordersList, errors }) => {
  const { ordersListArr, orderDetails, externalOrderToEdit } = ordersList
  const { unauthorized } = errors
  return {
    ordersListArr,
    orderDetails,
    externalOrderToEdit,
    unauthorized,
  }
}

export default connect(mapStateToProps, {
  setAuthorizationManual,
  fetchAllOrders,
  getOrderDetails,
  setExternalOrderToEdit,
  fetchAllOrderStatuses,
})(OrderEditExternalScreen)
