import React from 'react';
import { connect } from 'react-redux';

const Editor = (props) => {
    const { editMessage } = props;
    return (
        <textarea>{editMessage}</textarea>
    );
}

const MapStateToProps = ({ editorSection }) => {
    const { editMessage } = editorSection;
    return {
        editMessage,
    };
}

export default connect(MapStateToProps, null)(Editor);