import React, { Component } from "react";
import { connect } from "react-redux";

import Select from 'react-select';
import RouteStationsList from './RouteStationsList';
import {Pagination} from '../../../common';
import  {fetchIfEmpty} from '../../../../utils'

import {
  setCurrentRoute,
  onLoadingRoutes,
  fetchRoutes,
  fetchRouteStations,
  selectStation,
  
} from "../../../../actions";

import './style.css';

class RoutesStations extends Component {
  constructor(props) {
    super(props);

    this.INIT_STATE = {
        pagedList: [],
        pageNum:1,
        reloadRoutes:false,
    };
    this.state = this.INIT_STATE;
  }

  componentWillMount() {
    const { unauthorized, history } = this.props;
    if (unauthorized) {
      history.push("/");
    }
    fetchIfEmpty( this.props.routesList, this.props.fetchRoutes );
    const { currentRouteItem } = this.props;
    if(currentRouteItem){
      this.handleRouteFilterChange(currentRouteItem);
    }

  }

  componentWillUnmount(){
    //this.props.clearLocalSearchStations();
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps;
    if (unauthorized) {
      history.push("/");
    }
  }

  handleRouteFilterChange(itm){
    
    this.props.onLoadingRoutes();
    if(itm === null){
      this.props.setCurrentRoute(null);
      return;
    }

    if(itm && itm.value && itm.label){
      
      this.props.setCurrentRoute({
        routeName: itm.label,
        id: itm.value,
      });
    }
    this.props.fetchRouteStations(itm.value);
  }


  onEditStationBtnClick(itm){
    const { history } = this.props;
    history.push(`/StationsScreen/edit`);
    this.props.selectStation(itm);
  }

  render(){
    const {isLoading, routesList, currentRoute , isError,
      currentRouteItem, routeStations,
    } = this.props;
    const { pagedList, pageNum } = this.state;

    return (
      <div className="stations-page-wrapper">
        <div className="select-autocomplete-wrapper route-select">
           <Select
              placeholder="מסלול הפצה"
              value={currentRouteItem}
              options={routesList}
              onChange={(itm) => this.handleRouteFilterChange(itm)}
              classNamePrefix='base-select'
            />
        </div>
       
        <RouteStationsList
            items={pagedList}
            isLoading={isLoading}
            isError={isError}
            onEditStationBtnClick={itm => this.onEditStationBtnClick(itm) }
        />

        <Pagination
          fullList={routeStations}
          totalPerPage={50}
          onSubListReady={(pagedList,pageNum) => this.setState({ pagedList, pageNum })}
          pageNum={pageNum}
        />
        
      </div>
    );
  }
}

const mapStateToProps = ({  errors, routes }) => {
  const { routeStations, isLoading, currentRoute, isError } = routes;
  const { unauthorized } = errors;
  //const { stationsFilteredListArr } = stationsLocalSearch;
  const routesList = routes.routes;

  const currentRouteItem = currentRoute ? {value: currentRoute.id, label: currentRoute.routeName} : null;
  return {
    unauthorized,
    isLoading,
    routeStations,
    routesList,
    currentRoute,
    currentRouteItem,
    isError,
  };
};

export default connect(mapStateToProps, {
  setCurrentRoute,
  onLoadingRoutes,
  fetchRoutes,
  fetchRouteStations,
  selectStation,
})(RoutesStations);
