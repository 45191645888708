import React, { Component } from 'react';
import { connect } from 'react-redux';

import { H1, Multiselect, LabeledTextInput, SubmitButton } from '../../../common';
import { downloadOutdatedOrdersReport } from '../../../../actions';

import './style.css';

const stationsSelectLabelText = "בחר את עמדות";
const outdatedOrdersReportHeader = "דוח מלאי מת";
const numberOfDays = "מס' ימים";

class OutdatedOrdersReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedStations: [],
            numberOfDays: 0,
        }
    }

    onDataChange(property, value) {
        this.setState({ [property]: value });
    }

    onDownloadClick(event) {
        event.preventDefault();
        const { selectedStations, numberOfDays } = this.state;
        this.props.downloadOutdatedOrdersReport(selectedStations, numberOfDays);
    }

    render() {
        const { stations } = this.props;

        return (
            <>
                <H1 className="reports-section-header" title={outdatedOrdersReportHeader} />
                <form onSubmit={(e) => this.onDownloadClick(e)}>
                    <div className="form-group">
                        <Multiselect
                            labelText={stationsSelectLabelText}
                            allValue
                            options={stations}
                            onChange={selectedOptions => this.onDataChange('selectedStations', selectedOptions)}
                        />
                        <LabeledTextInput
                            className="report-section-labeled-text-input"
                            labelText={numberOfDays}
                            onChange={(e) => this.onDataChange('numberOfDays', e.target.value)} />
                    </div>
                    <SubmitButton className="download-file-button" title="Download" />
                </form>
            </>
        );
    }
}

const mapStateToProps = ({ selectOptions }) => {
    const { stations } = selectOptions;

    return {
        stations,
    };
}

export default connect(mapStateToProps, { downloadOutdatedOrdersReport })(OutdatedOrdersReport);