import {
  ON_GET_CITIES_SUCCESS,
  ON_GET_CITIES_FAIL,
  ON_CITIES_LOADING,
  SET_CURRENT_CITY,
  CLOSE_CITY_NOTIFICATION,
  ON_ADD_CITY_SUCCESS,
  ON_ADD_CITY_FAIL,
  ON_CITIES_FILTER_BY_TEXT,
  ON_CITIES_SET_PAGE,
  CLEAR_LOCAL_CITIES_FILTER_SEARCH,
  ON_GET_ALL_CITIES_SUCCESS,
  EDIT_CITY_FORM,
  ON_CREATE_CITY_SUCCESS,
  ON_CREATE_CITY_FAIL,
  ON_EXTERNAL_CITIES_SUCCESS,
  CLEAN_CITY_FORM,
  ON_GET_DISTRICTS_SUCCESS,
  ON_SELECT_CITY,
  ON_UPDATE_CITY,
  CLEAR_CITIES_LIST,
  ON_UPDATE_CITY_FAILED,
} from "../actions/types"

import {
  cities_RecalcFilteredListOutOfLatestState,
  districtsConvertToSelectList,
} from "../utils"

const emptyCity = {
  cityName: "",
  cityName_en: "",
  cityName_ru: "",
  district: "",
}

const INIT_STATE = {
  citiesList: [],
  citiesListTable: [],
  externalCitiesList: [],
  districts: [],
  isLoading: false,
  isError: false,
  isUpdated: false,
  isUpdateFailed: false,
  notUsedCitiesList: [],
  currentCity: null,
  city: emptyCity,
  showCreateSuccess: false,
  showCreateFail: false,
  showCreateConflict: false,
  showDeleteSuccess: false,
  showDeleteFail: false,
  showDeleteConflict: false,
  showUpdateSuccess: false,
  showUpdateFail: false,
  showUpdateConflict: false,
  reloadCities: false,
  isNewCityCreated: false,
  isShowError: false,
  errorCode: null,
  citiesLocalSearch: {
    searchText: "",
    citiesFilteredListArr: [],
    filtererActiveCities: [],
    page: 1,
  },
}

const CitiesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_CITIES_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case ON_GET_CITIES_SUCCESS:
      return {
        ...state,
        citiesList: action.payload,
        isLoading: false,
        reloadCities: false,
      }
    case ON_GET_ALL_CITIES_SUCCESS:
      return cities_RecalcFilteredListOutOfLatestState({
        ...state,
        citiesListTable: action.payload,
        reloadCities: false,
      })

    case ON_GET_CITIES_FAIL:
      return {
        ...state,
        citiesList: [],
        citiesListTable: [],
        error: action.payload,
        isLoading: false,
        reloadCities: false,
      }
    case SET_CURRENT_CITY:
      return {
        ...state,
        citiecurrentCitysList: action.payload,
        error: false,
        isLoading: false,
      }
    case CLOSE_CITY_NOTIFICATION:
      return {
        ...state,
        showCreateSuccess: false,
        showCreateFail: false,
        showCreateConflict: false,
        showDeleteSuccess: false,
        showDeleteFail: false,
        showDeleteConflict: false,
        showUpdateSuccess: false,
        showUpdateFail: false,
        showUpdateConflict: false,
      }
    case ON_ADD_CITY_SUCCESS:
      return {
        ...state,
        showCreateSuccess: true,
        error: false,
        isLoading: false,
        reloadCities: true,
      }
    case ON_ADD_CITY_FAIL:
      return {
        ...state,
        showCreateFail: true,
        error: false,
        isLoading: false,
        reloadCities: true,
      }

    case ON_CITIES_FILTER_BY_TEXT:
      return cities_RecalcFilteredListOutOfLatestState({
        ...state,
        citiesLocalSearch: {
          ...state.citiesLocalSearch,
          searchText: action.payload,
          page: 1,
        },
        isLoading: false,
      })

    case CLEAR_LOCAL_CITIES_FILTER_SEARCH:
      return cities_RecalcFilteredListOutOfLatestState({
        ...state,
        citiesLocalSearch: {
          searchText: "",
          citiesFilteredListArr: [],
          filtererActiveCities: [],
          page: 1,
        },
        isLoading: false,
      })

    case ON_CITIES_SET_PAGE:
      return cities_RecalcFilteredListOutOfLatestState({
        ...state,
        citiesLocalSearch: {
          ...state.citiesLocalSearch,
          page: action.payload,
        },
        isLoading: false,
      })
    case EDIT_CITY_FORM:
      return {
        ...state,
        city: action.payload,
      }
    case ON_CREATE_CITY_SUCCESS:
      return {
        ...state,
        city: action.payload,
        isNewCityCreated: true,
        isLoading: false,
      }
    case ON_CREATE_CITY_FAIL:
      return {
        ...state,
        isShowError: true,
        errorCode: action.payload,
      }
    case ON_EXTERNAL_CITIES_SUCCESS:
      return {
        ...state,
        externalCitiesList: action.payload,
      }
    case CLEAN_CITY_FORM:
      return {
        ...state,
        city: emptyCity,
        isShowError: false,
        isNewCityCreated: false,
        isLoading: false,
        isUpdated: false,
        isUpdateFailed: false,
      }
    case CLEAR_CITIES_LIST:
      return {
        ...state,
        citiesList: [],
      }
    case ON_GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: districtsConvertToSelectList(action.payload),
      }
    case ON_SELECT_CITY:
      return {
        ...state,
        city: action.payload,
      }
    case ON_UPDATE_CITY:
      return {
        ...state,
        city: action.payload,
        isNewCityCreated: false,
        isUpdated: true,
        isShowError: false,
        isLoading: false,
      }
    case ON_UPDATE_CITY_FAILED:
      return {
        ...state,
        isUpdateFailed: true,
      }

    default:
      return state
  }
}

export default CitiesReducer
