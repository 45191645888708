import React, { Component } from "react"
import { connect } from "react-redux"

import { H1 } from "../../../common"
import Locker from "./Locker"

const lockersListHeaderText = "תאים"

// TODO Convert to a functional component
class LockersList extends Component {
  renderLockers() {
    const { lockers, onLockersChange } = this.props
    return lockers.map((locker, index) => (
      <Locker
        key={locker.lockerName}
        locker={locker}
        lockerIndex={index}
        onLockersChange={onLockersChange}
      />
    ))
  }

  render() {
    const { lockers } = this.props

    if (!lockers || lockers.length === 0) return null

    return (
      <>
        <H1 title={lockersListHeaderText} />
        <div id="station-section-lockers-list">{this.renderLockers()}</div>
      </>
    )
  }
}

export default connect(null, null)(LockersList)
