import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { 
    fetchStationSelectOptions, 
    fetchLockerStatusSelectOptions 
} from '../../../actions';
import { Spinner,ErrorMessageSmall } from '../../../components/common';

import ExportOrderItems from './ExportOrderItems';
import ExportOrders from './ExportOrders';
import ExportUsers from './ExportUsers';

import './style.css';

class ExportScreen extends Component {

    constructor(props) {
        super(props);

        this.INIT_STATE = {
            isLoading: false,
            errorMessage:{
                ExportUsers : {isShow: false, text:'אופס, משהו לא הסתדר. נסה שוב.'},
                ExportOrders:{isShow: false, text:'אופס, משהו לא הסתדר. נסה שוב.'},
                ExportOrderItems:{isShow: false, text:'אופס, משהו לא הסתדר. נסה שוב.'},
                
            }
        };

        this.state = this.INIT_STATE;
    }


    componentWillMount() {
        
        const { unauthorized, history } = this.props;
        if( unauthorized){
            history.push('/');
        }
        this.props.fetchStationSelectOptions();
        this.props.fetchLockerStatusSelectOptions();
    }

    //shouldComponentUpdate() {  return false; }

    render() {

        let updateLoading = (status) => {  
            this.setState((state) => {
                return {
                    isLoading: status
                }
            });
        }
        let hideError = () => {
            this.setState((state) => {
                return {
                    errorMessage:{
                        ExportUsers : {isShow: false},
                        ExportOrders:{isShow: false},
                        ExportOrderItems:{isShow: false},
                        ExportOrdersMarkAsClose:{isShow: false}
                    }
                }
            });
        } 
        let setError = (param, mess) => {
            let tempStateErrorMessage =this.state.errorMessage;
            tempStateErrorMessage[param] = {isShow:true, text:mess};
            this.setState((state) => {
                return {
                    errorMessage: tempStateErrorMessage
                }
            });
        }
        return (
            <section id="export-section-container">
                <div style={{ display: this.state.isLoading ?'flex':'none' }}  className='order-editor-spinner-wrapper'>
                    <div className='loading-text'>אנא המתן</div>
                    <Spinner  zoom={2} />
                </div>
                <main className="export-section-body">
                    <Route path='/ExportScreen' component={() => <ExportUsers  className='export-content' isLoading={updateLoading} hideError={hideError} setError={setError} />} />
                    <ErrorMessageSmall 
                        isShow={this.state.errorMessage.ExportUsers.isShow} 
                        hideError={hideError} 
                        className='err-export' 
                        errorMessage={this.state.errorMessage.ExportUsers.text}/>
                </main>
                <main className="export-section-body">
                    <Route path='/ExportScreen' component={() => <ExportOrders  className='export-content' isLoading={updateLoading} hideError={hideError} setError={setError}/>} />
                    <ErrorMessageSmall 
                        isShow={this.state.errorMessage.ExportOrders.isShow} 
                        hideError={hideError}  
                        className='err-export' 
                        errorMessage={this.state.errorMessage.ExportOrders.text} />
                </main>
                <main className="export-section-body">
                    <Route path='/ExportScreen' component={() => <ExportOrderItems  className='export-content' isLoading={updateLoading} hideError={hideError} setError={setError} />}  />
                    <ErrorMessageSmall 
                        isShow={this.state.errorMessage.ExportOrderItems.isShow} 
                        hideError={hideError}  
                        className='err-export' 
                        errorMessage={this.state.errorMessage.ExportOrderItems.text}/>
                </main>
               
            </section>
        );
    }

}


const mapStateToProps = ({ errors }) => {
    const {unauthorized,} = errors;
    return {
        unauthorized,
    }
}

export default connect(mapStateToProps, { fetchStationSelectOptions, fetchLockerStatusSelectOptions })(ExportScreen);