import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import DrawerList from "./DrawerList"
import "./style.scss"

const Drawer = (props) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isRelative, setIsRelative] = useState(true)

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    if (isRelative && !isOpen) {
      setIsRelative(false)
    } else {
      setIsRelative(true)
    }
  }, [isOpen])

  // if (!props?.logedUserData) {
  //   return null
  // }

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={`menu no-print ${isRelative ? "relative" : "fixed"}`}>
      <button className="drawer-toggle-btn" onClick={toggleDrawer}>
        <i className={`fas fa-arrow-circle-${isOpen ? "right" : "left"}`}></i>
        {isOpen ? "סגור" : "פתח"}
      </button>
      <div
        className={`drawer ${isOpen ? "drawer-open" : "drawer-closed"} ${
          isRelative ? "relative" : "fixed"
        }`}
      >
        <DrawerList userRole={props?.logedUserData?.role} />
      </div>
    </div>
  )
}

const mapStateToProps = ({ login }) => {
  const { logedUserData } = login
  return {
    logedUserData,
  }
}

export default connect(mapStateToProps, {})(Drawer)
