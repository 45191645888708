import React from 'react';

import Modal from '../Modal';

import { SmallHeader, InputFiled, BlueButton } from '../../common';

import './style.css';

const YesNoModal = ({ show, title, text, onYesClick, onNoClick }) => {

    if (!show) {
        return (
            <noscript />
        );
    }

    return (
        <Modal extraClass='yesno-modal-wrap' >
            <div className='yesno-modal-container'>
                <SmallHeader
                    title={title}
                />
                <div className='yesno-modal-content-wrapper'>
                    {text}

                    <div className='yesno-modal-btns-wrapper'>
                        <BlueButton
                            extraClass='yesno-modal-button'
                            onClick={e => onYesClick()}
                        >
                            אישור
                        </BlueButton>
                        <BlueButton
                            extraClass='yesno-modal-button'
                            onClick={e => onNoClick()}
                        >
                            ביטול
                        </BlueButton>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default YesNoModal;
