import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import {
  fetchStationSelectOptions,
  fetchLockerStatusSelectOptions,
} from "../../../../actions";
import { Spinner, ErrorMessageSmall } from "../../../../components/common";
import ExportOrders from "./ExportOrders";

import "./style.css";

class OrdersReport extends Component {
  constructor(props) {
    super(props);

    this.INIT_STATE = {
      isLoading: false,
      errorMessage: {
        ExportOrders11: {
          isShow: false,
          text: "אופס, משהו לא הסתדר. נסה שוב.",
        },
      },
    };

    this.state = this.INIT_STATE;
  }

  componentWillMount() {
    const { unauthorized, history } = this.props;
    if (unauthorized) {
      history.push("/");
    }
    this.props.fetchStationSelectOptions();
    this.props.fetchLockerStatusSelectOptions();
  }

  render() {
    let updateLoading = (status) => {
      this.setState((state) => {
        return {
          isLoading: status,
        };
      });
    };
    let hideError = () => {
      this.setState((state) => {
        return {
          errorMessage: {
            ExportOrders11: { isShow: false },
          },
        };
      });
    };
    let setError = (param, mess) => {
      let tempStateErrorMessage = this.state.errorMessage;
      tempStateErrorMessage[param] = { isShow: true, text: mess };
      this.setState((state) => {
        return {
          errorMessage: tempStateErrorMessage,
        };
      });
    };
    return (
      <section id="export-section-container">
        <div
          style={{ display: this.state.isLoading ? "flex" : "none" }}
          className="order-editor-spinner-wrapper"
        >
          <div className="loading-text">אנא המתן</div>
          <Spinner zoom={2} />
        </div>
        <main className="export-section-body">
          <Route
            path="/ReportsScreen"
            component={() => (
              <ExportOrders
                className="export-content"
                isLoading={updateLoading}
                hideError={hideError}
                setError={setError}
              />
            )}
          />
          <ErrorMessageSmall
            isShow={this.state.errorMessage.ExportOrders11.isShow}
            hideError={hideError}
            className="err-export"
            errorMessage={this.state.errorMessage.ExportOrders11.text}
          />
        </main>
      </section>
    );
  }
}

const mapStateToProps = ({ errors }) => {
  const { unauthorized } = errors;
  return {
    unauthorized,
  };
};

export default connect(mapStateToProps, {
  fetchStationSelectOptions,
  fetchLockerStatusSelectOptions,
})(OrdersReport);
