import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"

import {
  ON_GET_STATION_SELECT_OPTIONS_SUCCESS,
  ON_GET_STATION_SELECT_OPTIONS_FAILURE,
  ON_GET_LOCKER_STATUS_SELECT_OPTIONS_SUCCESS,
  ON_GET_LOCKER_STATUS_SELECT_OPTIONS_FAILURE,
  ON_GET_LOCKER_TYPE_SELECT_OPTIONS_SUCCESS,
  ON_GET_LOCKER_TYPE_SELECT_OPTIONS_FAILURE,
  ON_GET_PRICE_LIST_SELECT_OPTIONS_SUCCESS,
  ON_GET_PRICE_LIST_SELECT_OPTIONS_FAILURE,
  ON_GET_USER_ROLES_SUCCESS,
  ON_GET_USER_ROLES_FAILURE,
  ON_GET_ORDER_TYPES_SUCCESS,
  ON_GET_ORDER_TYPES_FAILURE,
  ON_GET_USER_SOURCES_SUCCESS,
  ON_GET_USER_SOURCES_FAILURE,
  ON_GET_ACCOUNT_TYPES_SUCCESS,
  ON_GET_ACCOUNT_TYPES_FAILURE,
  ON_GET_LOCKERS_SIZES_SUCCESS,
  ON_GET_LOCKERS_SIZES_FAILURE,
  ON_GET_DONE_ORDER_TYPES_SUCCESS,
  ON_GET_DONE_ORDER_TYPES_FAILURE,
} from "./types"

export const fetchStationSelectOptions = () => {
  return (dispatch) => {
    axios
      .get(api.getStationSelectOptions)
      .then((res) => {
        dispatch({
          type: ON_GET_STATION_SELECT_OPTIONS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_STATION_SELECT_OPTIONS_FAILURE,
          payload: err,
        })
      })
  }
}

export const fetchPriceListSelectOptions = () => {
  return (dispatch) => {
    axios
      .get(api.getPriceListSelectOptions)
      .then((res) => {
        dispatch({
          type: ON_GET_PRICE_LIST_SELECT_OPTIONS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_PRICE_LIST_SELECT_OPTIONS_FAILURE,
          payload: err,
        })
      })
  }
}

export const fetchLockerTypeSelectOptions = () => {
  return (dispatch) => {
    axios
      .get(api.getLockerTypeOtions)
      .then((res) => {
        dispatch({
          type: ON_GET_LOCKER_TYPE_SELECT_OPTIONS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_LOCKER_TYPE_SELECT_OPTIONS_FAILURE,
          payload: err,
        })
      })
  }
}

export const fetchLockerStatusSelectOptions = () => {
  return (dispatch) => {
    axios
      .get(api.getLockerStatusSelectOptions)
      .then((res) => {
        dispatch({
          type: ON_GET_LOCKER_STATUS_SELECT_OPTIONS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_LOCKER_STATUS_SELECT_OPTIONS_FAILURE,
          payload: err,
        })
      })
  }
}

export const fetchUserRoles = () => {
  return (dispatch) => {
    axios
      .get(api.getUserRoles)
      .then((res) => {
        dispatch({
          type: ON_GET_USER_ROLES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_USER_ROLES_FAILURE,
          payload: err,
        })
      })
  }
}

export const fetchOrderTypes = () => {
  return (dispatch) => {
    axios
      .get(api.getOrderTypes)
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_TYPES_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDER_TYPES_FAILURE,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchDoneOrderTypes = () => {
  return (dispatch) => {
    axios
      .get(api.getDoneOrderTypes)
      .then((res) => {
        dispatch({
          type: ON_GET_DONE_ORDER_TYPES_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_DONE_ORDER_TYPES_FAILURE,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchAccountTypes = () => {
  return (dispatch) => {
    axios
      .get(api.getAccountTypes)
      .then((res) => {
        dispatch({
          type: ON_GET_ACCOUNT_TYPES_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ACCOUNT_TYPES_FAILURE,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchUserSources = () => {
  return (dispatch) => {
    axios
      .get(api.getUserSources)
      .then((res) => {
        dispatch({
          type: ON_GET_USER_SOURCES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_USER_SOURCES_FAILURE,
          payload: err,
        })
      })
  }
}

export const fetchLockerSizes = () => {
  return (dispatch) => {
    axios
      .get(api.getLockerSizes)
      .then((res) => {
        dispatch({
          type: ON_GET_LOCKERS_SIZES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_LOCKERS_SIZES_FAILURE,
          payload: err,
        })
      })
  }
}
