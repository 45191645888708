import React, { Component } from "react"
import { connect } from "react-redux"
import {
  onLoadingOrdersSacks,
  createOrderSack,
  fetchOrderSacksList,
  fetchOrdersForSack,
  putOrderToSack,
  fetchSackOrders,
  clearOrdersSacks,
  printSackSticker,
} from "../../../../../actions"
import OrderTable from "./OrderTable/OrderTable.js"
import NotificationPopup from "../../../../Modals/NotificationPopup/notificationPopup.js"
import YesNoPopup from "../../../../Modals/YesNoPopup/yesNoPopup.js"
import SelectSackModal from "../../../../Modals/SelectSackModal"
import { BlueButton } from "../../../../common"
import beep from "../../../../../assets/audio/beep.mp3"
import error from "../../../../../assets/audio/error.wav"
import { providerManager } from "../../../../../deliveryProviders/providerManager"
import "./style.scss"

class OneSackPage extends Component {
  constructor(props) {
    super(props)
    this.orderNumInput = React.createRef()
    this.state = {
      orderNumber: null,
      scanedPackageNumber: null,
      isScaned: false,
      showPackageNotFoundModal: false,
      showConfirmFinishModal: false,
      orderForEdit: null,
      serviceName: this.props.history.location.state,
      currentOrderType: 0,
      currrentSack: null,
      orderType: 0,
      showSelectSackModal: true,
    }
  }

  clearState() {
    const orderType = providerManager.orderType(
      this.props.history.location.state
    )
    this.setState({
      orderNumber: null,
      scanedPackageNumber: null,
      isScaned: false,
      showPackageNotFoundModal: false,
      scanedOshowConfirmFinishModalrderNumber: false,
      orderForEdit: null,
      serviceName: this.props.history.location.state,
      orderType: orderType,
    })
    this.props.clearOrdersSacks()
  }

  componentWillMount() {
    const { unauthorized, history } = this.props

    if (unauthorized) {
      history.push("/")
    }
    this.clearState()
    this.props.onLoadingOrdersSacks()
    const orderType = providerManager.orderType(
      this.props.history.location.state
    )
    this.setState({ orderType: orderType })
    this.props.onLoadingOrdersSacks()
    this.props.fetchOrdersForSack(orderType)
    this.props.fetchOrderSacksList(orderType, 16)
  }

  componentDidUpdate(prevProps, prevState) {
    const { unauthorized, history, puttedToSackOrder, createdSack } = this.props

    if (unauthorized) {
      history.push("/")
    }

    if (puttedToSackOrder !== null) {
      const { currrentSack } = this.state
      this.props.onLoadingOrdersSacks()
      this.props.fetchOrdersForSack(currrentSack.orderType)
      this.props.fetchSackOrders(currrentSack.id)
      this.orderNumInput.current.value = ""
      this.orderNumInput.current.disabled = false
      this.orderNumInput.current.focus()
    }

    if (createdSack !== null) {
      this.onSelectSack(createdSack)
    }
  }

  onChangeOrderNumber = (orderNum) => {
    this.setState({ orderNumber: orderNum, scanedPackageNumber: orderNum })
    if (orderNum.length === 12) {
      const order = this.props.ordersForSack.find(
        (elm) => elm.orderNumber === orderNum || elm.packageNumber === orderNum
      )
      const { currrentSack } = this.state
      if (!currrentSack) {
        this.setState({ showSelectSackModal: true })
        return
      } else if (order && currrentSack) {
        this.props.onLoadingOrdersSacks()
        this.props.putOrderToSack(
          order.id,
          currrentSack.id,
          currrentSack.orderType
        )
        this.setState({ showPackageNotFoundModal: false, orderNumber: null })
        this.orderNumInput.current.disabled = true
      } else {
        new Audio(error).play()
        this.setState({ showPackageNotFoundModal: true })
      }
    }
  }

  closeNotPackageFoundModal() {
    this.setState({ showPackageNotFoundModal: false, orderNumber: null })
    this.orderNumInput.current.value = ""
    this.orderNumInput.current.disabled = false
    this.orderNumInput.current.focus()
  }

  onFinishScan() {
    if (this.props.ordersForSack.length > 0) {
      this.setState({ showConfirmFinishModal: true })
    }
    if (this.props.ordersForSack.length === 0) {
      this.goToHomePage()
    }
  }

  goToHomePage() {
    this.props.history.push("/HomeScreen")
  }

  onCreateNewOrderSack() {
    const { serviceName } = this.state
    const orderType = providerManager.orderType(serviceName)
    this.props.onLoadingOrdersSacks()
    this.props.createOrderSack(orderType)
  }

  onSelectSack(sack) {
    this.setState({ currrentSack: sack, showSelectSackModal: false })
    this.props.onLoadingOrdersSacks()
    this.props.fetchOrdersForSack(sack.orderType)
    this.props.fetchSackOrders(sack.id)

    this.orderNumInput.current.value = ""
    this.orderNumInput.current.disabled = false
    this.orderNumInput.current.focus()
  }

  onPrintClick = () => {
    const { currrentSack } = this.state
    this.props.printSackSticker(currrentSack.barcode)
  }

  render() {
    const { ordersForSack, sackOrders, isLoading, isError } = this.props
    const {
      serviceName,
      currrentSack,
      scanedPackageNumber,
      isScaned,
      showPackageNotFoundModal,
      orderNumber,
      showConfirmFinishModal,
      showSelectSackModal,
    } = this.state
    return (
      <section className="delivery-sack">
        <h1 className="title">קליטת הזמנות לשק</h1>
        <div className={"logo " + serviceName}>
          {providerManager.renderLogo(serviceName)}
        </div>

        <input
          autoFocus
          ref={this.orderNumInput}
          placeholder="חפש הזמנה"
          value={orderNumber}
          onChange={(evt) => this.onChangeOrderNumber(evt.target.value)}
        ></input>
        {ordersForSack ? (
          <div className="list-count">
            {" " + ordersForSack.length + " "}
            הזמנות מוכנות לקליטה
          </div>
        ) : null}
        {ordersForSack && (
          <OrderTable
            ordersList={ordersForSack}
            scanedPackageNumber={scanedPackageNumber}
            isScaned={isScaned}
            statusDateTitle={"תאריך איסוף"}
            isError={isError}
            isLoading={false}
            dateToShow="dirtyOrderDate"
          />
        )}

        {ordersForSack ? (
          <div className="btn_finish_holder">
            <BlueButton
              extraClass="btn-finish"
              onClick={() => this.onFinishScan()}
            >
              סיום סריקה
            </BlueButton>
          </div>
        ) : null}
        <div>
          <div className="list-count">
            <div className="orders-count">
              {sackOrders ? " " + sackOrders.length : 0}
            </div>
            הזמנות של שק
            <div className="list-title">
              {currrentSack ? currrentSack.barcode : null}
            </div>
            <div className="select-sack-btn-wrapper">
              <BlueButton
                extraClass={"btn-table"}
                onClick={() => {
                  this.props.onLoadingOrdersSacks()
                  this.props.fetchOrderSacksList(this.state.orderType, 16)
                  this.setState({
                    currrentSack: null,
                    showSelectSackModal: true,
                  })
                }}
              >
                בחר שק
              </BlueButton>
            </div>
            {currrentSack ? (
              <div className="select-sack-btn-wrapper">
                <BlueButton
                  extraClass={"btn-table"}
                  onClick={() => this.onPrintClick()}
                >
                  <i className="fa fa-print" aria-hidden="true"></i>
                  {" הדפס ברקוד של שק "}
                </BlueButton>
              </div>
            ) : null}
          </div>
        </div>
        {sackOrders ? (
          <OrderTable
            ordersList={sackOrders}
            scanedPackageNumber={scanedPackageNumber}
            isScaned={isScaned}
            statusDateTitle={"תאריך קליטה למחסן"}
            isLoading={isLoading}
            isError={isError}
            dateToShow="inWarehouseDate"
          />
        ) : null}
        <NotificationPopup
          btnText="סגור"
          image={require("../../../../../assets/images/warning.png")}
          isOpen={showPackageNotFoundModal}
          infoMsg="החבילה לא נמצאת ברשימה  זו"
          searchForOrder={() => {}}
          toggle={() => this.closeNotPackageFoundModal()}
        />
        <YesNoPopup
          isOpen={showConfirmFinishModal}
          infoMsg="ישנן חבילות שלא נסרקו, האם אתה בטוח שאתה רוצה לצאת?"
          close={() => this.setState({ showConfirmFinishModal: false })}
          confirm={() => this.goToHomePage()}
        />
        <SelectSackModal
          show={showSelectSackModal}
          onSelect={(sack) => this.onSelectSack(sack)}
          onCreateNewOrderSack={() => this.onCreateNewOrderSack()}
          onClose={() => {
            this.setState({ showSelectSackModal: false })
            this.orderNumInput.current.focus()
          }}
        />
      </section>
    )
  }
}

const mapStateToProps = ({ orderSack, errors }) => {
  const { unauthorized } = errors
  const {
    activeSacksList,
    sacksList,
    ordersForSack,
    sackOrders,
    createdSack,
    puttedToSackOrder,
    deliveredOrdersSack,
    isLoading,
    isError,
  } = orderSack

  return {
    activeSacksList,
    sacksList,
    ordersForSack,
    sackOrders,
    createdSack,
    puttedToSackOrder,
    deliveredOrdersSack,
    isLoading,
    isError,
    unauthorized,
  }
}

export default connect(mapStateToProps, {
  onLoadingOrdersSacks,
  createOrderSack,
  fetchOrderSacksList,
  fetchOrdersForSack,
  putOrderToSack,
  fetchSackOrders,
  clearOrdersSacks,
  printSackSticker,
})(OneSackPage)
