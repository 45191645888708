import React from "react"

import Header from "./../../../common/List/Header"
import UserItem from "./UserItem"
import { H1 } from "../../../common/H1"
import { Spinner } from "../../../common/Spinner"

import "./style.css"

const UserList = ({
  extraClass,
  headers,
  items,
  gravity,
  itemMap,
  onItemEditBtnClick,
  isLoading,
  isError,
}) => {
  return (
    <div className={`ul-list-wrapper ${extraClass}`}>
      <Header titles={headers} gravity={gravity} />
      <div className="ul-list-items-wrapper">
        {renderItems(
          items,
          gravity,
          itemMap,
          onItemEditBtnClick,
          isLoading,
          isError
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  gravity,
  itemMap,
  onItemEditBtnClick,
  isLoading,
  isError
) => {
  if (isError) {
    return (
      <H1 className="not-valid-search" title="אנא בדוק את הערך של שדה חיפוש" />
    )
  }

  if (isLoading) {
    return (
      <div className="users-list">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }
  return items.map((itm, index) => {
    return (
      <UserItem
        key={index}
        item={itm}
        itemMap={itemMap}
        gravity={gravity}
        isLast={index === items.length - 1}
        onEditBtnClick={onItemEditBtnClick}
      />
    )
  })
}

export default UserList
