import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Multiselect, SubmitButton, H1 } from '../../../common';
import { downloadStationsStatusReport } from '../../../../actions';

import './style.css';

const stationsSelectLabelText = "בחר את עמדות";

class StationsStatusReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedStations: [],
        }
    }

    onMultiselectChange(multiSelectName, selectedOptions) {
        this.setState({ [multiSelectName]: selectedOptions });
    }

    onDownloadClick(event) {
        event.preventDefault();
        const { selectedStations } = this.state;
        this.props.downloadStationsStatusReport(selectedStations);
    }

    render() {
        const { stations } = this.props;

        const stationStatusReportHeader = "דוח ארונות"

        return (
            <>
                <H1 className="reports-section-header" title={stationStatusReportHeader} />
                <form onSubmit={(e) => this.onDownloadClick(e)}>
                    <div className="form-group">
                    <Multiselect
                        labelText={stationsSelectLabelText}
                        allValue
                        options={stations}
                        onChange={selectedOptions => this.onMultiselectChange('selectedStations', selectedOptions)}
                    />
                    </div>                   
                    <SubmitButton className="download-file-button" title="Download" />
                </form>
            </>
        );
    }
}

const mapStateToProps = ({ selectOptions }) => {
    const { stations } = selectOptions;

    return {
        stations,
    };
}

export default connect(mapStateToProps, { downloadStationsStatusReport })(StationsStatusReport);