import React from 'react';
import { clif } from '../../../utils';

import './style.css';

const Title = ({ children, activable, extraClass, bold, ...props }) => {

    const whiteLabelClassName = `whlb-wrapper \
                                    ${clif(activable, 'whlb-active-effect')} \
                                    ${clif(bold, 'whlb-bold')} \
                                    ${extraClass}`.replace(/ +(?= )/g, '');

    return (
        <div
            className={whiteLabelClassName}
            {...props}
        >
            {children}
        </div>
    );
}

export { Title };
