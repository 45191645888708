import React from 'react';

import { BlueButton } from '../BlueButton';
import SuccessFailBtn from './SuccessFailBtn';
import PendingBtn from './PendingBtn';

import './style.css';

// STATUS -> -1 => ERROR, 0 => PENDING, 1 => OK

const PendingSuccessFailBtn = ({ status, onBtnClick, btnText, btnExtraClass, onSuccessFailClick }) => {
    switch (status) {
        case -1:
            return <SuccessFailBtn onSuccessFailClick={onSuccessFailClick} />;
        case 0:
            return <PendingBtn />;
        case 1:
            return <SuccessFailBtn onSuccessFailClick={onSuccessFailClick} success />;
        case 2:
            return (
                <BlueButton
                    extraClass={`pensuccfail-btn-extra ${btnExtraClass ? btnExtraClass : ''}`}
                    onClick={() => onBtnClick()}
                >
                    {btnText}
                </BlueButton>
            );
        default:
            return <PendingBtn />;
    }
}

export { PendingSuccessFailBtn };
