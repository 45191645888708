import React, { Component } from "react"
import Modal from "../Modal"

import { SmallHeader, InputFiled, PendingSuccessFailBtn } from "../../common"
import { validation } from "../../../utils/FieldsValidation"

import "./style.scss"

class UpdateTemporaryPasswordModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pwd: "",
      pwdVerify: "",
      inputPasswordType: "password",
      inputPasswordVerifyType: "password",
      pwdErr: false,
      pwdVerifyErr: false,
      isSubmitBtnDisabled: true,
      pwdErrText: "סיסמא אמורה להכיל לא פחת מ-5 אותיות באנגלית ומספרים",
      pwdVerifyErrText: "נא להקליד סיסמא שנית בצורה תקינה",
    }
  }

  onInputChange(inputName, val) {
    this.setState({ [inputName]: val })

    let isPwdInputValid,
      isPwdVerifyInputValid,
      isSubmitBtnDisabled,
      pwdErr,
      pwdVerifyErr

    switch (inputName) {
      case "pwd":
        isPwdInputValid = validation.PwdStrong(val)
        isPwdVerifyInputValid = validation.PwdVerify(val, this.state.pwdVerify)
        isSubmitBtnDisabled = !isPwdInputValid || !isPwdVerifyInputValid
        pwdErr = !isPwdInputValid
        pwdVerifyErr = !isPwdVerifyInputValid

        this.setState({
          isSubmitBtnDisabled,
          pwdErr,
          pwdVerifyErr,
        })
        break
      case "pwdVerify":
        isPwdInputValid = validation.PwdStrong(this.state.pwd)
        isPwdVerifyInputValid = validation.PwdVerify(this.state.pwd, val)
        isSubmitBtnDisabled = !isPwdInputValid || !isPwdVerifyInputValid
        pwdErr = !isPwdInputValid
        pwdVerifyErr = !isPwdVerifyInputValid

        this.setState({
          isSubmitBtnDisabled,
          pwdErr,
          pwdVerifyErr,
        })
        break
      default:
        break
    }
  }

  onSubmitBtnClick() {
    this.validateInput()
    const { isSubmitBtnDisabled, pwd, pwdVerify } = this.state
    if (!isSubmitBtnDisabled) {
      this.props.onSubmitClick(pwd, pwdVerify)
    }
  }

  filterEnterAndSubmit(e) {
    if (e.key === "Enter") {
      this.validateInput()

      const { isSubmitBtnDisabled } = this.state
      if (!isSubmitBtnDisabled) {
        this.onSubmitBtnClick()
      }
    }
  }

  validateInput() {
    const { pwd, pwdVerify } = this.state

    const isPwdInputValid = validation.PwdStrong(pwd)
    const isPwdVerifyInputValid = validation.PwdVerify(pwd, pwdVerify)

    const isSubmitBtnDisabled = !isPwdInputValid || !isPwdVerifyInputValid
    const pwdErr = !isPwdInputValid
    const pwdVerifyErr = !isPwdVerifyInputValid

    this.setState({
      isSubmitBtnDisabled,
      pwdErr,
      pwdVerifyErr,
    })

    return !isSubmitBtnDisabled
  }

  onViewPasswordClick() {
    const { inputPasswordType } = this.state
    if (inputPasswordType === "password") {
      this.setState({ inputPasswordType: "text" })
    } else {
      this.setState({ inputPasswordType: "password" })
    }
  }

  onViewPasswordVerifyClick() {
    const { inputPasswordVerifyType } = this.state
    if (inputPasswordVerifyType === "password") {
      this.setState({ inputPasswordVerifyType: "text" })
    } else {
      this.setState({ inputPasswordVerifyType: "password" })
    }
  }

  renderSubmitBtnOrLoadingSpinner(isLoading, showError) {
    const btnStatus = isLoading ? 0 : showError ? -1 : 2

    return (
      <PendingSuccessFailBtn
        btnText="עדכן"
        status={btnStatus}
        btnExtraClass="modal-button"
        onBtnClick={() => this.onSubmitBtnClick()}
        onSuccessFailClick={(success) => this.props.onSuccessFailClick(success)}
      />
    )
  }

  render() {
    const { show, loading, isLoginFailed, errorMsg } = this.props

    const {
      pwdErr,
      pwdVerifyErr,
      pwdErrText,
      pwdVerifyErrText,
      inputPasswordType,
      inputPasswordVerifyType,
    } = this.state

    if (!show) {
      return <noscript />
    }
    const { pwd, pwdVerify } = this.state

    return (
      <Modal extraClass="update-temporary-password-modal-wrap">
        <div className="modal-container">
          <SmallHeader title="עדכון סיסמא קבוע" />
          <div className="inputs-wrapper">
            נא להכניס סיסמא קבועה
            <div className="input-wrapper">
              <InputFiled
                value={pwd}
                placeholder="סיסמא"
                type={inputPasswordType}
                onChange={(e) => this.onInputChange("pwd", e.target.value)}
                extraClass="psw-input"
                onKeyPress={(e) => this.filterEnterAndSubmit(e)}
                onViewPasswordClick={() => this.onViewPasswordClick()}
                isHidedPassword={false}
              />
            </div>
            {pwdErr && <div className="auth-error">{pwdErrText}</div>}
            <div className="input-wrapper">
              <InputFiled
                value={pwdVerify}
                placeholder="הקלידו סיסמא שנית"
                type={inputPasswordVerifyType}
                onChange={(e) =>
                  this.onInputChange("pwdVerify", e.target.value)
                }
                extraClass="psw-input"
                onKeyPress={(e) => this.filterEnterAndSubmit(e)}
                onViewPasswordClick={() => this.onViewPasswordVerifyClick()}
                isHidedPassword={false}
              />
            </div>
            {pwdVerifyErr && (
              <div className="auth-error">{pwdVerifyErrText}</div>
            )}
            {this.renderSubmitBtnOrLoadingSpinner(loading, isLoginFailed)}
            {isLoginFailed}
          </div>
        </div>
      </Modal>
    )
  }
}

export default UpdateTemporaryPasswordModal
