import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Spinner } from '../../../common';

import { SubmitButton, H1, LabeledDatePicker } from '../../../common';
import { downloadBalanceReport, removeErrorMessage } from '../../../../actions';

import StyleClasses from './BalanceReport.module.css';

const balanceReportHeader = 'דוח השוואה: חשבונית ירוקה לעומת Clean Box';
const dateFilterHeader = 'תאריך תשלום';
const fromLabelText = 'מתאריך';
const toLabelText = 'עד תאריך';

class BalanceReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fromDate: "",
            toDate: this.htmlTomorrowDate(new Date()),
        }

        this.handleDateChange = this.handleDateChange.bind(this);
    }

    htmlTomorrowDate(date) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1}`;
    }

    handleDateChange(inputName, event) {
        this.setState({
            [inputName]: event.target.value
        })
    }

    onDownloadClick(downloadOptions, event) {
        event.preventDefault();
        this.props.downloadBalanceReport(downloadOptions);        
    }

    componentWillUnmount() {
        this.props.removeErrorMessage();
    }

    render() {
        const { fromDate, toDate } = this.state;
        const { isFetching } = this.props;

        const downloadOptions = {
            fromDate,
            toDate
        }

        return (
            <>
                <H1 className="reports-section-header" title={balanceReportHeader} />
                <h2 className={StyleClasses.H2}>{dateFilterHeader}</h2>
                <form onSubmit={(e) => this.onDownloadClick(downloadOptions, e)}>
                    <LabeledDatePicker
                        name="fromDate"
                        labelText={fromLabelText}
                        date={fromDate}
                        onChange={this.handleDateChange}
                    />
                    <LabeledDatePicker
                        name="toDate"
                        labelText={toLabelText}
                        date={toDate}
                        onChange={this.handleDateChange}
                    />                    
                    {/* isFetching ? <Spinner /> : <SubmitButton className="download-file-button" title="Download" /> */}
                    <SubmitButton className="download-file-button" title="Download" />
                </form>
            </>
        );
    }
}

const mapStateToProps = ({reports}) => {
    console.log("Map state to props", reports);
    return { isFetching: reports.balanceIsFetching };
}

export default connect(mapStateToProps, { downloadBalanceReport, removeErrorMessage })(BalanceReport);