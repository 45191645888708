import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { BlueButton } from "../../../../common"
import "./StationTaskToggle.scss"

function StationTaskToggle(props) {
  const [notes, setNotes] = useState("")

  const handleTextChange = (event) => {
    setNotes(event.target.value)
  }

  return (
    <section className="stationTaskToggle">
      <div className="modal">
        <div className="modal-content">
          <div className="title">
            {props.stationTask.isActive ? "סגירת משימה " : "לפתוח מחדש משימה "}
            {props.stationTask.taskNumber}
          </div>
          <div className="">
            <textarea
              value={notes}
              onChange={handleTextChange}
              rows={8}
              cols={60}
              placeholder="הוסף הערות"
            />
          </div>
          <div className="btn-holder">
            <BlueButton onClick={() => props.toggleTaskClick(notes)}>
              עדכן
            </BlueButton>
            <BlueButton onClick={() => props.close(notes)}>חזור</BlueButton>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = ({ stationTasks }) => {
  const {} = stationTasks

  return {}
}
export default connect(mapStateToProps, {})(StationTaskToggle)
