import React, { useState } from "react";

import "./style.css";

export const EditableNumberInput = ({
  itemId,
  index,
  id,
  extraClassName,
  productId,
  processId,
  priceListId,
  row,
  className,
  initialValue,
  updateData,
  createData,
}) => {
  const [value, setValue] = useState(initialValue);
  const [extraClass, setExtraClass] = useState("");
  const [isSaveBtnDisable, setSaveBtnDisable] = useState(true);

  const onSaveClick = (value) => {
    if (itemId) {
      updateData(itemId, value);
    } else {
      const priceData = {
        ProductId: productId,
        ProcessId: processId,
        Price: value,
        PriceListId: priceListId,
      };
      createData(priceData);
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
    setExtraClass("highlighted-table-number-input");
    setSaveBtnDisable(false);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div>
      <input
        className={`${className} ${extraClass}`}
        type="number"
        min={0}
        step={0.01}
        value={value}
        onChange={onChange}
      />
      <button
        className="btn-save"
        disabled={isSaveBtnDisable}
        onClick={() => onSaveClick(value)}
      >
        <i class="fas fa-save"></i>
      </button>
    </div>
  );
};
