import React, { Component, CSSProperties } from "react"
import { connect } from "react-redux"

import Select from "react-select"
import {
  BlueButton,
  InputFiled,
  ValidationMessage,
} from "../../../../../common"
import { routesColors } from "../../../../../../utils/ColorLists.ts"
import { colourStyles } from "../../../../../../utils/colorStyles.tsx"
import "./style.scss"

class RouteItem extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      currentItem: null,
    }
    this.state = this.INIT_STATE
  }

  componentWillMount() {
    this.setState({
      currentItem: this.props.item,
    })
  }

  selectServiceSupplierBtnClick(event) {
    const { item, currentRoute } = this.props

    const currentItem = {
      ...currentRoute,
      serviceSupplierId: event.value,
      serviceSupplierName: event.label,
    }
    this.setState({ currentItem })
    this.props.setCurrentRoute(currentItem)
  }

  selectRouteColorBtnClick(event) {
    const { item, currentRoute } = this.props

    const currentItem = {
      ...currentRoute,
      color: event.value,
    }
    this.setState({ currentItem })
    this.props.setCurrentRoute(currentItem)
  }

  setRouteCost(event) {
    const { item, currentRoute } = this.props

    const currentItem = {
      ...currentRoute,
      routeCost: event.target.value,
    }
    this.setState({ currentItem })
    this.props.setCurrentRoute(currentItem)
  }

  render() {
    const {
      item,
      itemMap,
      gravity,
      isLast,
      onEditRouteStationsBtnClick,
      onUpdateRouteBtnClick,
      onEditRouteName,
      onRemoveBtnClick,
      onSaveRouteBtnClick,
      currentRoute,
    } = this.props

    const itemClassName = `item-wrapper ${isLast ? "item-last" : ""}`

    return (
      <div className={itemClassName}>
        {itemMap.map((itmMap, index) => {
          const flex = gravity[`${index}`] || 1

          if (itmMap === "editName") {
            return (
              <div style={{ flex }} key={index}>
                <BlueButton
                  extraClass="base-btn edit-name-btn"
                  onClick={(e) => onEditRouteName(item)}
                >
                  <div className="fa fa-pencil" aria-hidden="true"></div>
                </BlueButton>
              </div>
            )
          }
          if (itmMap === "editStations") {
            return (
              <div style={{ flex }} key={index}>
                <BlueButton
                  extraClass="base-btn edit-station-btn"
                  onClick={(e) => onEditRouteStationsBtnClick(item)}
                >
                  <div
                    className="fa far fa-window-restore"
                    aria-hidden="true"
                  ></div>
                </BlueButton>
              </div>
            )
          }
          if (itmMap === "update") {
            return (
              <div style={{ flex }} key={index}>
                <BlueButton
                  extraClass="base-btn update-btn"
                  onClick={(e) => onUpdateRouteBtnClick(item)}
                >
                  שמור
                </BlueButton>
              </div>
            )
          }
          if (itmMap === "remove") {
            return (
              <div style={{ flex }} key={index}>
                <BlueButton
                  extraClass="base-btn delete-btn"
                  onClick={(e) => onRemoveBtnClick(item.value)}
                >
                  הסר
                </BlueButton>
              </div>
            )
          }
          if (itmMap === "save") {
            return (
              <div style={{ flex }} key={index}>
                <BlueButton
                  extraClass="base-btn add-btn"
                  onClick={(e) => onSaveRouteBtnClick(currentRoute)}
                >
                  שמור
                </BlueButton>
              </div>
            )
          }

          if (itmMap === "cancel") {
            return (
              <div style={{ flex }} key={index}>
                <BlueButton
                  extraClass="base-btn cancel-btn"
                  onClick={(e) => onEditRouteName(null)}
                >
                  בטל
                </BlueButton>
              </div>
            )
          }

          if (itmMap === "routeName") {
            const { currentRoute } = this.props

            return (
              <div style={{ flex }} key={index} className="route-name">
                <InputFiled
                  extraClass={"base-input"}
                  onChange={onEditRouteName}
                  value={currentRoute.routeName}
                  placeholder={"הכנס שם של מסלול הפצה חדש..."}
                />
                <ValidationMessage
                  isShow={this.props.isExistName}
                  isShowOk={!this.props.isExistName}
                  isShowError={this.props.isExistName}
                  className="field-valid"
                  errorMessage={"שם מסלול כבר קיים במערכת. אנא שנה את השם."}
                />
              </div>
            )
          }
          if (itmMap === "color") {
            if (item.color) {
              return (
                <div style={{ flex }} key={index} className="color">
                  <div
                    className="color-wrap"
                    style={{ background: item.color }}
                  ></div>
                </div>
              )
            } else {
              return (
                <div style={{ flex }} key={index} className="color">
                  לא נבחר הצבע
                </div>
              )
            }
          }
          if (itmMap === "colorSelect") {
            const { currentRoute } = this.props

            let selectedColor = {
              label: " לא נבחר הצבע",
              value: "",
              color: "#333",
            }
            console.log("routesColors:")
            console.log(routesColors)
            selectedColor = routesColors.find(
              (e) => e.color === currentRoute.color
            )

            return (
              <div style={{ flex }} key={index} className="color">
                <Select
                  options={routesColors}
                  onChange={(e) => this.selectRouteColorBtnClick(e)}
                  value={selectedColor}
                  placeholder="בחר צבע"
                  classNamePrefix="base-select"
                  styles={colourStyles}
                />
              </div>
            )
          }
          if (itmMap === "serviceSupplierSelect") {
            const { currentRoute, suppliersSelectList } = this.props
            const selectedSupplier = {
              label:
                currentRoute && currentRoute.serviceSupplierName
                  ? currentRoute.serviceSupplierName
                  : "",
              value:
                currentRoute && currentRoute.serviceSupplierId
                  ? currentRoute.serviceSupplierId
                  : "",
            }
            return (
              <div style={{ flex }} key={index} className="service-supplier">
                <Select
                  options={suppliersSelectList}
                  onChange={(e) => this.selectServiceSupplierBtnClick(e)}
                  value={selectedSupplier}
                  placeholder="בחר ספק"
                  classNamePrefix="base-select"
                />
              </div>
            )
          }
          if (itmMap === "routeCost") {
            if (item.routeCost) {
              return (
                <div style={{ flex }} key={index}>
                  <div>{item.routeCost}</div>
                </div>
              )
            }
          }
          if (itmMap === "routeCostInput") {
            const { currentRoute } = this.props

            return (
              <div style={{ flex }} key={index} className="route-cost">
                <InputFiled
                  extraClass={"route-cost-input"}
                  onChange={(e) => this.setRouteCost(e)}
                  value={currentRoute.routeCost}
                  placeholder={""}
                />
                {/* <ValidationMessage
                  isShow={this.props.isExistName}
                  isShowOk={!this.props.isExistName}
                  isShowError={this.props.isExistName}
                  className="field-valid"
                  errorMessage={"שם מסלול כבר קיים במערכת. אנא שנה את השם."}
                /> */}
              </div>
            )
          }
          let itemContent = item[itmMap]
          if (itmMap === "label") {
            return (
              <div
                style={{ flex }}
                key={index}
                className="route-name"
                onClick={(e) => onEditRouteName(item)}
              >
                {itemContent}
              </div>
            )
          }

          return (
            <div style={{ flex }} key={index}>
              {itemContent}
            </div>
          )
        })}
      </div>
    )
  }
}

const MapStateToProps = ({ routes, suppliers }) => {
  const { currentRoute } = routes
  const { suppliersSelectList } = suppliers

  return {
    currentRoute,
    suppliersSelectList,
  }
}

export default connect(MapStateToProps, null)(RouteItem)
