import React, { useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  getItems,
  updateItem,
  createItem,
  deleteItem,
  uploadPriceListItemImage,
  clearItemStatus,
} from "../../../../actions";
import { getImage } from "../../../../actions";
import { BlueButton } from "../../../common/BlueButton";
import EditableTextArea from "./../_common/EditableTextarea";
import Table from "../_common/Table";

import "./style.css";

const itemsTableHeader = "פריטים ומוצרים";
const itemIdTableHeader = "מזהה פריט";
const itemNameHebrewTableHeader = "(he) שם פריט";
const itemNameEnglishTableHeader = "(en) שם פריט";
const itemNameRussianTableHeader = "(ru) שם פריט";
const itemNameArabicTableHeader = "(ar) שם פריט";
const deleteItemButtonLabel = "למחוק את הפרית";
const numberOfPriceListsUse = "Used";
const productImageTableHeader = "תמונת מוצר";
const processPrintableStatusTableHeader = "ניתן להדפסה";
const processImageTableHeader = "תמונת תהליך";
const deleteProcessButtonLabel = "למחוק את התהליך";

const Items = (props) => {
  const {
    products,
    getItems,
    updateItem,
    createItem,
    deleteItem,
    isNewItemCreated,
    clearItemStatus,
  } = props;
  const [productsState, setProductsState] = useState(products);
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [newProduct, setNewProduct] = useState({
    nameHe: "",
    nameEn: "",
    nameRu: "",
    nameAr: "",
  });

  useEffect(() => {
    setProductsState(products);
  }, [products]);

  useEffect(() => {
    if (isNewItemCreated) {
      clearItemStatus();
      alert("פריט נשמר בהצלחה");
      setNewProduct({
        ...newProduct,
        nameHe: "",
        nameEn: "",
        nameRu: "",
        nameAr: "",
      });
    }
  }, [isNewItemCreated]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (newProduct.nameHe == "") {
      return alert("אנא הזן שם פריט בעברית");
    }
    if (newProduct.nameEn == "") {
      return alert("אנא הזן שם פריט באנגלית");
    }
    createItem(newProduct, () => getItems());
  };

  const handleChange = (evt) => {
    setNewProduct({ ...newProduct, [evt.target.name]: evt.target.value });
  };

  const updateItemsState = (rowIndex, columnID, value) => {
    setProductsState((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          let editedRow = {};
          editedRow = {
            ...old[rowIndex],
            [columnID]: value,
          };
          updateItem(editedRow);

          return editedRow;
        }
        return row;
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => itemIdTableHeader,
        id: "productId",
        Cell: ({ row }) => String(row.original.productId),
      },
      {
        Header: () => itemNameHebrewTableHeader,
        accessor: "productName_he",
      },
      {
        Header: () => itemNameEnglishTableHeader,
        accessor: "productName_en",
      },
      {
        Header: () => itemNameRussianTableHeader,
        accessor: "productName_ru",
      },
      {
        Header: () => itemNameArabicTableHeader,
        accessor: "productName_ar",
      },
      {
        Header: "",
        id: "delete-button",
        Cell: ({ row }) => (
          <button
            className="delete-button"
            title={deleteItemButtonLabel}
            onClick={() => {
              const shouldDelete = window.confirm(
                `Do you want to delete this item?`
              );
              if (shouldDelete) {
                deleteItem(row.original.productId, () => getItems());
              }
            }}
          >
            <div className="fas fa-trash-alt" />
          </button>
        ),
      },
    ],
    [deleteItem]
  );

  const defaultColumn = {
    Cell: EditableTextArea,
  };

  if (products.length === 0) return null;

  const createProduct = () => {
    return (
      <section className="createProduct">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="nameHe"
            value={newProduct.nameHe}
            onChange={handleChange}
            placeholder="שם בעברית"
          />
          <input
            type="text"
            name="nameEn"
            value={newProduct.nameEn}
            onChange={handleChange}
            placeholder="שם באנגלית"
          ></input>
          <input
            type="text"
            name="nameRu"
            value={newProduct.nameRu}
            onChange={handleChange}
            placeholder="שם ברוסית"
          ></input>
          <input
            type="text"
            name="nameAr"
            value={newProduct.nameAr}
            onChange={handleChange}
            placeholder="שם בערבית"
          ></input>
          <input className="btn-save" type="submit" value="שמור" />
        </form>
      </section>
    );
  };

  return (
    <>
      <h1 id="items-section-header">{itemsTableHeader}</h1>
      <div className="btn-holder">
        <BlueButton onClick={() => setShowCreateProduct(!showCreateProduct)}>
          הוסף פריט
        </BlueButton>
      </div>

      {showCreateProduct ? createProduct() : null}
      <div className="table-holder">
        <Table
          id="items-table"
          columns={columns}
          defaultColumn={defaultColumn}
          data={productsState}
          updateData={updateItemsState}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ priceListsSection }) => {
  const { products, isNewItemCreated } = priceListsSection;

  return {
    products,
    isNewItemCreated,
  };
};

export default connect(mapStateToProps, {
  getItems,
  getImage,
  updateItem,
  createItem,
  uploadPriceListItemImage,
  deleteItem,
  clearItemStatus,
})(Items);
