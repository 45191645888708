import React, { Component } from "react"
import { connect } from "react-redux"

import { BlueButton, PaginationNext } from "../../common"
import { UsersSearchFilterer } from "../../reduxified"
import {
  //fetchAllUsers,
  onSearchFieldChange,
  fetchExistingUserDataForUpdate,
  setUserId,
  //fetchUserByMobile,
  //fetchUsersByName,
  onStartLoadingUsers,
  onUsersSearchNotValidInput,
  removeNotValidInputMessage,
  searchUsers,
  setUsersSearch,
  clearUserHistory,
  setUserHistoryUser,
} from "../../../actions"
import UsersList from "./UserList"
import "./style.css"

import exportFromJSON from "export-from-json"

class UsersListScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      //usersListArr: [],
      //pagedList: [],
      //searchMobile:'',
      //searchName:'',
    }

    this.state = this.INIT_STATE
  }

  componentWillMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }
    //this.props.fetchAllUsers();
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }
  }

  searchUsersClick(_usersSearch) {
    this.props.onStartLoadingUsers()
    if (_usersSearch) {
      _usersSearch.searchText = _usersSearch.searchText.trim()
      this.props.searchUsers(_usersSearch)
    } else {
      const { usersSearch } = this.props
      usersSearch.searchText = usersSearch.searchText.trim()
      this.props.searchUsers(usersSearch)
    }
  }

  onUserItemEditBtnClick(user) {
    this.props.setUserId(user.id)
    //this.props.fetchExistingUserDataForUpdate(user.id)
    //this.props.clearUserHistory()
    //this.props.setUserHistoryUser(user.id)

    this.props.history.push("/UserCreateEditScreen/" + user.id)
  }

  onPageClick(page) {
    let { usersSearch } = this.props
    usersSearch.page = page
    this.props.setUsersSearch(usersSearch)
    this.searchUsersClick(usersSearch)
  }

  filterEnterAndSubmit(e) {
    let { usersSearch } = this.props
    if (e.key === "Enter") {
      this.searchUsersClick(usersSearch)
    }
  }

  exportUsersToExcel() {
    const data = this.props.filteredUsers.map((user) => {
      return {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobilePhone: user.mobilePhone,
        stationNumber: user.stationNumber,
        branchName: user.branchName,
        sourceName: user.sourceName,
        companyName: user.companyName,
        role: user.role,
      }
    })
    const fileName = "users"
    const exportType = exportFromJSON.types.csv

    exportFromJSON({
      data,
      fileName,
      exportType,
      withBOM: "\uFEFF",
    })
  }

  render() {
    //const { pagedList,searchName, searchMobile } = this.state;
    const {
      usersListArr,
      currentSearchedPageStep,
      currentSearchedPage,
      filteredUsers,
      isLoading,
      isError,
    } = this.props

    return (
      <div>
        <UsersSearchFilterer
          onSearchFilterChanged={(searchFilters) =>
            this.props.setUsersSearch(searchFilters)
          }
          filterEnterAndSubmit={(e) => this.filterEnterAndSubmit(e)}
          searchUsersClick={(e) => this.searchUsersClick()}
        />

        <UsersList
          isError={isError}
          isLoading={isLoading}
          extraClass="uls-wrapper-list"
          headers={[
            "משתמש פעיל",
            "שם פרטי",
            "שם משפחה",
            "אמייל",
            "נייד",
            "מספר סניף",
            "שם סניף",
            "מקור",
            "שם חברה",
            "תפקיד",
            "",
          ]}
          itemMap={[
            "isDelete",
            "firstName",
            "lastName",
            "email",
            "mobilePhone",
            "stationNumber",
            "branchName",
            "sourceName",
            "companyName",
            "role",
          ]}
          gravity={{
            0: 1,
            1: 2,
            2: 2,
            3: 3,
            4: 2,
            5: 1,
            6: 2,
            7: 1,
            8: 1,
            9: 1,
            10: 1,
            11: 1,
            12: 1,
          }}
          items={filteredUsers}
          onItemEditBtnClick={(itm) => this.onUserItemEditBtnClick(itm)}
        />
        <PaginationNext
          pageStep={currentSearchedPageStep}
          page={currentSearchedPage}
          onClick={(page) => this.onPageClick(page)}
          currentResponseCount={usersListArr.length}
        />

        <div className="export-wrapper">
          <BlueButton
            className="blbtn-wrapper base-btn"
            onClick={() => this.exportUsersToExcel()}
          >
            יצאו לקובץ Excel
          </BlueButton>
        </div>
      </div>
    )
  }
}

const mapStateToProp = ({ usersList, searchField, errors }) => {
  const {
    usersListArr,
    isLoading,
    isError,
    currentSearchedPageStep,
    currentSearchedPage,
    usersSearch,
  } = usersList
  const { keyword } = searchField
  const { unauthorized } = errors
  const filteredUsers = usersListArr.filter((user) => {
    const {
      firstName,
      lastName,
      role,
      branchName,
      companyName,
      email,
      mobilePhone,
    } = user

    return (
      firstName.toLowerCase().includes(keyword) ||
      lastName.toLowerCase().includes(keyword) ||
      role.toLowerCase().includes(keyword) ||
      email.toLowerCase().includes(keyword) ||
      mobilePhone.toLowerCase().includes(keyword) ||
      (branchName && branchName.toLowerCase().includes(keyword)) ||
      (companyName && companyName.toLowerCase().toString().includes(keyword)) ||
      keyword === ""
    )
  })

  return {
    usersListArr,
    isLoading,
    isError,
    filteredUsers,
    usersSearch,
    currentSearchedPageStep,
    currentSearchedPage,
    unauthorized,
  }
}

export default connect(mapStateToProp, {
  //fetchAllUsers,
  onSearchFieldChange,
  fetchExistingUserDataForUpdate,
  setUserId,
  //fetchUserByMobile,
  //fetchUsersByName,
  onStartLoadingUsers,
  onUsersSearchNotValidInput,
  removeNotValidInputMessage,
  searchUsers,
  setUsersSearch,

  clearUserHistory,
  setUserHistoryUser,
})(UsersListScreen)
