import React from "react"

import {
  InputWithLabel,
  SelectValPassive,
  SelectAutocomplete,
  CheckboxAndLabel,
} from "../../../../common"
import "./style.scss"

const AcountDetailsTab = ({
  onInputChange,
  userData,
  sortedStationsNames,
  userRoles,
}) => {
  const {
    companyName,
    companyNumber,
    stationId,
    temporaryPassword,
    role,
    isMonthlyPayment,
  } = userData

  const defaultStationValue = () => {
    return sortedStationsNames.find((o) => o.stationId == stationId)
  }

  function changeIsMonthlyPayment(changeIsMonthlyPayment) {
    let newIsMonthlyPayment = false
    if (changeIsMonthlyPayment) {
      newIsMonthlyPayment = true
    }
    onInputChange("isMonthlyPayment", newIsMonthlyPayment)
  }

  return (
    <div className="adt-wrapper">
      <div className="adt-inner-wrapper">
        <InputWithLabel
          extraClass="ucs-input"
          placeholder="שם חברה"
          value={companyName || ""}
          onChange={(e) => onInputChange("companyName", e.target.value)}
        />
        <InputWithLabel
          extraClass="ucs-input"
          placeholder="מס ע.מ/ח.פ"
          value={companyNumber || ""}
          onChange={(e) => onInputChange("companyNumber", e.target.value)}
        />
      </div>
      <div className="adt-inner-wrapper">
        <div className="adt-brirta-mihdal">
          <div className="ucs-title default-station-title">עמדת ברירת מחדל</div>
          <div className="station-autocomplete">
            <SelectAutocomplete
              defaultValue={defaultStationValue()}
              selectedVal={stationId}
              placeholder="עמדת ברירת מחדל"
              items={sortedStationsNames}
              onChangeHandler={(e) => onInputChange("stationId", e.stationId)}
            />
          </div>
        </div>
        {role !== "Customer" && (
          <InputWithLabel
            extraClass="ucs-input"
            placeholder="סיסמא זמנית"
            value={temporaryPassword || ""}
            onChange={(e) => onInputChange("temporaryPassword", e.target.value)}
          />
        )}
      </div>
      <div className="adt-inner-wrapper">
        <div className="adt-brirta-mihdal">
          <div className="ucs-title">סוג לקוח</div>
          <SelectValPassive
            extraClass="ucs-input ucs-input-border"
            placeholder="סוג לקוח"
            items={userRoles}
            valueIndex={userRoles.indexOf(role)}
            onItemSelected={(itm) => onInputChange("role", itm)}
          />
        </div>
      </div>
      <div className="adt-inner-wrapper">
        <div className="adt-brirta-mihdal">
          <CheckboxAndLabel
            checked={isMonthlyPayment}
            onChange={(e) => {
              changeIsMonthlyPayment(e.target.checked)
            }}
          >
            <div className="user-is-monthly-payment">לקוח עסקי תשלום חודשי</div>
          </CheckboxAndLabel>
        </div>
      </div>
    </div>
  )
}

export default AcountDetailsTab
