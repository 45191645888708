import axios from "axios"
import api from "../api"

import {
  // Items
  ON_GET_ITEMS_SUCCESS,
  ON_ITEM_UPDATE_SUCCESS,
  ON_ITEM_DELETE_SUCCESS,
  ON_ITEM_DELETE_FAILURE,
  ON_ITEM_CREATE_SUCCESS,
  ON_ITEM_CREATE_FAILURE,
  CLEAR_ITEM_STATUS,
  // Processes
  ON_GET_PROCESSES_SUCCESS,
  ON_PROCESS_IMAGE_UPLOAD_SUCCESS,
  ON_PROCESS_IMAGE_UPLOAD_FAILURE,
  ON_PROCESS_UPDATE_SUCCESS,
  ON_PROCESS_UPDATE_FAILURE,
  ON_PROCESS_DELETE_SUCCESS,
  ON_PROCESS_DELETE_FAILURE,
  // Services
  ON_GET_SERVICES_SUCCESS,

  // Price lists
  SELECT_PRICE_LIST,
  ON_GET_PRICE_LIST_SUCCESS,
  ON_GET_PRICE_LIST_FAILURE,
  ON_UPDATE_PRICE_LIST_ITEM_SUCCESS,
  ON_UPDATE_PRICE_LIST_ITEM_FAILURE,
  ON_CREATE_PRICE_LIST_ITEM_SUCCESS,
  ON_CREATE_PRICE_LIST_ITEM_FAILURE,
  ON_PRICE_LIST_ITEM_IMAGE_UPLOAD_SUCCESS,
  ON_PRICE_LIST_ITEM_IMAGE_UPLOAD_FAILURE,
  ON_PRICE_LIST_ITEM_DELETE_SUCCESS,
  ON_PRICE_LIST_ITEM_DELETE_FAILURE,
  ON_GET_SUPPLIER_PRICES_SUCCESS,
  ON_GET_SUPPLIER_PRICES_FAILURE,
  ON_SUPPLIER_PRICE_UPDATE_SUCCESS,
  ON_SUPPLIER_PRICE_UPDATE_FAILURE,
  CLEAR_SUPPLIER_PRICE,
} from "./types"

export const getImage = (imageId) => {
  return `${api.getImage}/${imageId}`
}

export const createService = () => {}
export const updateService = () => {}
export const deleteService = () => {}

export const createProcess = () => {}

// ITEMS
export const getItems = () => {
  return (dispatch) => {
    axios
      .get(api.getItems)
      .then((response) => {
        //console.log("getItems",response);

        dispatch({
          type: ON_GET_ITEMS_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        if (error.response) {
          if (error.response === 400) {
            window.alert("Client error: bad request. Please tern to support.")
          } else if (error.response === 401) {
            window.alert("Client error: unauthorized. Please relogin.")
          } else if (error.response === 403) {
            window.alert(
              "Client error: forbidden. Please tern to the manager to get access permission."
            )
          } else if (error.response === 500) {
            window.alert(
              "Server error: internal server error. Please tern to support."
            )
          } else if (error.response === 503) {
            window.alert(
              "Server error: server is not available. Please tern to support."
            )
          }
        } else if (error.request) {
          window.alert("No response.")
        } else {
          window.alert("Application error.")
        }
        console.log(error.config)
      })
  }
}

export const createItem = (product, callback) => {
  return (dispatch) => {
    const body = {
      ProductName_en: product.nameEn,
      ProductName_he: product.nameHe,
      ProductName_ru: product.nameRu,
      ProductName_ar: product.nameAr,
    }
    axios
      .post(api.createItem, body)
      .then((response) => {
        dispatch({
          type: ON_ITEM_CREATE_SUCCESS,
        })
        callback()
      })
      .catch((error) => {
        dispatch({
          type: ON_ITEM_CREATE_FAILURE,
          payload: error,
        })
      })
  }
}

export const updateItem = (item) => {
  //console.log(item);
  return (dispatch) => {
    axios
      .put(`${api.updateItem}/${item.productId}`, item)
      .then((res) => {
        //console.log("updateItem1111",res);
        dispatch({
          type: ON_ITEM_UPDATE_SUCCESS,
          payload: res.data,
        })
        dispatch(getItems())
      })
      .catch((error) => {
        if (error.response) {
          if (error.response === 400) {
            window.alert("Client error: bad request. Please tern to support.")
          } else if (error.response === 401) {
            window.alert("Client error: unauthorized. Please relogin.")
          } else if (error.response === 403) {
            window.alert(
              "Client error: forbidden. Please tern to the manager to get access permission."
            )
          } else if (error.response === 500) {
            window.alert(
              "Server error: internal server error. Please tern to support."
            )
          } else if (error.response === 503) {
            window.alert(
              "Server error: server is not available. Please tern to support."
            )
          }
        } else if (error.request) {
          window.alert("No response.")
        } else {
          window.alert("Application error.")
        }
        console.log(error.config)
      })
  }
}

export const deleteItem = (itemId, callback) => {
  return (dispatch) => {
    axios
      .delete(`${api.deleteItem}/${itemId}`)
      .then((response) => {
        dispatch({
          type: ON_ITEM_DELETE_SUCCESS,
        })
        callback()
      })
      .catch((error) => {
        dispatch({
          type: ON_ITEM_DELETE_FAILURE,
          payload: error,
        })
        console.log("Failed to delete item")
        console.log(error)
      })
  }
}

// PROCESSES
export const getProcesses = () => {
  return (dispatch) => {
    axios
      .get(api.getProcesses)
      .then((response) => {
        // console.log(response.data);
        dispatch({
          type: ON_GET_PROCESSES_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const updateProcess = (process, callback) => {
  //console.log(process);
  return (dispatch) => {
    axios
      .put(`${api.updateProcess}/${process.processId}`, process)
      .then((res) => {
        //console.log(res);
        dispatch({
          type: ON_PROCESS_UPDATE_SUCCESS,
          payload: res.data,
        })
        callback()
      })
      .catch((err) => {
        dispatch({
          type: ON_PROCESS_UPDATE_FAILURE,
          payload: err,
        })
        console.log("Failed to fetch price list")
        console.log(err)
      })
  }
}

export const uploadProcessImage = (processId, image, callback) => {
  return (dispatch) => {
    let formData = new FormData()
    formData.append("image", image)
    axios
      .post(`${api.uploadProcessImage}/${processId}`, formData)
      .then((response) => {
        dispatch({
          type: ON_PROCESS_IMAGE_UPLOAD_SUCCESS,
          payload: response.data,
        })
        callback()
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: ON_PROCESS_IMAGE_UPLOAD_FAILURE,
          payload: error,
        })
      })
  }
}

export const deleteProcess = (processId, callback) => {
  return (dispatch) => {
    axios
      .delete(`${api.deleteProcess}/${processId}`)
      .then(() => {
        dispatch({
          type: ON_PROCESS_DELETE_SUCCESS,
        })
        //callback();
      })
      .catch((error) => {
        dispatch({
          type: ON_PROCESS_DELETE_FAILURE,
          payload: error,
        })
        console.log("Failed to delete items")
        console.log(error)
      })
  }
}

//SERVICES
export const getServices = () => {
  return (dispatch) => {
    axios
      .get(api.getServices)
      .then((response) => {
        dispatch({
          type: ON_GET_SERVICES_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// PRICE LISTS
export const selectPriceList = (priceListId) => {
  return {
    type: SELECT_PRICE_LIST,
    payload: priceListId,
  }
}

export const getPriceList = (priceList) => {
  return (dispatch) => {
    axios
      .get(`${api.getPriceList}/${priceList}`)
      .then((res) => {
        dispatch({
          type: ON_GET_PRICE_LIST_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_PRICE_LIST_FAILURE,
          payload: err,
        })
        console.log("Failed to fetch price list")
        console.log(err)
      })
  }
}

export const updatePrice = (priceListItemId, price, callback) => {
  return (dispatch) => {
    //console.log(priceListItemId);
    //console.log(price);
    axios
      .put(`${api.updatePrice}/${priceListItemId}`, { price })
      .then((res) => {
        //console.log(res);
        dispatch({
          type: ON_UPDATE_PRICE_LIST_ITEM_SUCCESS,
          payload: res.data,
        })
        callback()
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_PRICE_LIST_ITEM_FAILURE,
          payload: err,
        })
        console.log("Failed to update price list item")
        console.log(err)
      })
  }
}
export const createPrice = (priceData, callback) => {
  return (dispatch) => {
    axios
      .post(api.createPrice, priceData)
      .then((res) => {
        dispatch({
          type: ON_CREATE_PRICE_LIST_ITEM_SUCCESS,
          payload: res.data,
        })
        callback()
      })
      .catch((err) => {
        dispatch({
          type: ON_CREATE_PRICE_LIST_ITEM_FAILURE,
          payload: err,
        })
        console.log("Failed to create price list item")
        console.log(err)
      })
  }
}

export const uploadPriceListItemImage = (priceListItemId, image, callback) => {
  return (dispatch) => {
    let formData = new FormData()
    formData.append("image", image)
    axios
      .post(`${api.uploadPriceListItemImage}/${priceListItemId}`, formData)
      .then((response) => {
        dispatch({
          type: ON_PRICE_LIST_ITEM_IMAGE_UPLOAD_SUCCESS,
          payload: response.data,
        })
        callback()
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: ON_PRICE_LIST_ITEM_IMAGE_UPLOAD_FAILURE,
          payload: error,
        })
      })
  }
}

export const deletePriceListItem = (priceItemId, callback) => {
  return (dispatch) => {
    axios
      .delete(`${api.deletePriceListItem}/${priceItemId}`)
      .then((res) => {
        dispatch({
          type: ON_PRICE_LIST_ITEM_DELETE_SUCCESS,
          payload: res.data,
        })
        callback()
      })
      .catch((err) => {
        dispatch({
          type: ON_PRICE_LIST_ITEM_DELETE_FAILURE,
          payload: err,
        })
        console.log("Failed to delete the price item")
        console.log(err)
      })
  }
}

export const deletePriceList = (priceListId) => {
  return (dispatch) => {
    axios
      .delete(`${api.deletePriceList}/${priceListId}`)
      .then((response) => {
        //console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const clearItemStatus = () => {
  return {
    type: CLEAR_ITEM_STATUS,
  }
}

export const getSupplierPrices = (supplierId, priceListId) => {
  return (dispatch) => {
    axios
      .get(api.getSupplierPrices(supplierId, priceListId))
      .then((res) => {
        dispatch({
          type: ON_GET_SUPPLIER_PRICES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_SUPPLIER_PRICES_FAILURE,
          payload: err,
        })
      })
  }
}

export const updateSupplierPriceItem = (
  priceItemId,
  servicesupplierId,
  newPrice
) => {
  return (dispatch) => {
    const body = {
      PriceItemId: priceItemId,
      ServicesupplierId: servicesupplierId,
      SupplierPrice: newPrice,
    }
    axios
      .post(api.updateSupplierPriceItem, body)
      .then((response) => {
        dispatch({
          type: ON_SUPPLIER_PRICE_UPDATE_SUCCESS,
          payload: response.data,
        })
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: ON_SUPPLIER_PRICE_UPDATE_FAILURE,
          payload: error,
        })
      })
  }
}

export const clearSupplierPrices = () => {
  return {
    type: CLEAR_SUPPLIER_PRICE,
  }
}
