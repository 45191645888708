import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
    updatePrice,
    uploadPriceListItemImage,
    deletePriceListItem,
    getPriceList,
} from '../../../../../actions';
import {
    getImage,
} from '../../../../../actions';

import { LabeledFileInput } from '../../../../common';

import Table from '../../_common/Table';
import { EditableNumberInput } from '../../_common/EditableNumberInput';

import './style.css';

const productsTableHeader = 'מוצרים';
const productIdTableHeader = 'מזהה מוצר';
const productNameTableHeader = 'שם מוצר';
const productPriceTableHeader = 'מחיר';
const productImageTableHeader = 'תמונת מוצר';
const deleteProductPriceListItemButtonLabel = 'למחוק את הפרית מהמחירון';
const uploadProcessImageButtonLabel = 'להעלות  את התמונה';


const ProductsPriceListTable = (props) => {
    const {
        priceList,
        productsPriceList,
        updatePrice,
        uploadPriceListItemImage,
        deletePriceListItem,
        getPriceList,
    } = props;

    let [productsPriceListState, setProductsPriceListState] = useState(productsPriceList);

    useEffect(() => {
        setProductsPriceListState(productsPriceList);
    }, [productsPriceList]);

    const updateServerData = ((priceListItemId, price) => {
        console.log(priceListItemId, price);
        updatePrice(priceListItemId, price, () => getPriceList(priceList));
    });

    // Reversed table (Alex)
    /* const onProductsPriceListItemChange = (rowIndex, columnID, value) => {
        setProductsPriceListState(old => {
            return old.map((row, index) => {
                if (index === rowIndex) {
                    setIsUpdated(true);
                    const newRow = {
                        ...old[rowIndex],
                        [columnID]: value,
                    };
                    setPriceListItemState(newRow);
                    return newRow;
                }
                return row;
            })
        });
    } */


    /* useEffect(() => {
        if (isUpdated)
        updatePrice(priceListItemState);
    }, [isUpdated, updatePrice, priceListItemState]); */

    const columns = useMemo(
        () => [
            /* {
                Header: () => productIdTableHeader,
                accessor: 'productId',
            }, */
            {
                Header: () => productNameTableHeader,
                accessor: 'productName_he'
            },
            {
                Header: () => productPriceTableHeader,
                id: `price`,
                Cell: ({ row }) =>
                    <EditableNumberInput
                        index={row.index}
                        itemId={row.original.priceItemId}
                        id={'price'}
                        row={row}
                        className="table-editable-number-input"
                        initialValue={row.original.price}
                        updateData={updateServerData}
                    />
            },
            {
                Header: () => productImageTableHeader,
                id: 'price-list-item-image',
                Cell: ({ row }) =>
                    <img
                        className="price-list-item-img"
                        src={getImage(row.original.imageId)}
                        alt={`${row.original.imageId}`}
                    />
            },
            {
                Header: '',
                id: 'price-list-item-image-upload',
                Cell: ({ row }) => {
                    return <LabeledFileInput
                        inputId={`image-input-${row.original.priceItemId}`}
                        labelText={<div title={uploadProcessImageButtonLabel} className='fas fa-file-upload' />}
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => {
                                uploadPriceListItemImage(row.original.priceItemId, e.target.files[0], () => getPriceList(priceList))
                            }} />
                }
            },
            {
                Header: '',
                id: 'delete-button',
                Cell: ({ row }) =>
                    <button
                        className="delete-button"
                        title={deleteProductPriceListItemButtonLabel}
                        onClick={() => {
                            const shouldDelete = window.confirm(`Do you want to delete this price list item?`);
                            if (shouldDelete) {
                                deletePriceListItem(row.original.priceItemId, () => getPriceList(priceList));
                            }
                        }}><div className='fas fa-trash-alt' /></button>
            }
        ],
        [
            priceList,
            getPriceList,
            updateServerData,
            deletePriceListItem,
            uploadPriceListItemImage
        ]
    );

    const textColumn = ({ cell: { value } }) => String(value);

    const defaultColumn = {
        Cell: textColumn
    }

    if (productsPriceList.length === 0) return null;

    return (
        <>
            <h2>{productsTableHeader}</h2>
            <Table
                id="products-price-list-table"
                columns={columns}
                data={productsPriceListState}
                defaultColumn={defaultColumn}
            />
        </>
    );
}

const mapStateToProps = ({ priceListsSection }) => {
    const { productsPriceList, priceList } = priceListsSection;
    return {
        productsPriceList,
        priceList,
    };
}

export default connect(
    mapStateToProps,
    {
        updatePrice,
        deletePriceListItem,
        uploadPriceListItemImage,
        getPriceList,
    }
)(ProductsPriceListTable);