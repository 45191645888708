import {
  ON_GET_ROUTES_SUCCESS,
  ON_GET_ROUTES_FAIL,
  ON_ROUTE_SHOW_LOADING,
  ON_SET_ROUTE,
  ON_UPDATE_ROUTE_SUCCESS,
  ON_UPDATE_ROUTE_FAIL,
  ON_CREATE_ROUTE_SUCCESS,
  ON_CREATE_ROUTE_FAIL,
  ON_ROUTE_STATIONS_SHOW_LOADING,
  GET_STATIONS_BY_ROUTE_SUCCESS,
  GET_STATIONS_BY_ROUTE_FAIL,
  ON_CHECK_EXIST_ROUTE_NAME_SUCCESS,
  ON_CHECK_EXIST_ROUTE_NAME_FAIL,
  ON_CHECK_EXIST_ROUTE_NAME_LOADING,
  ON_DELETE_ROUTE_SUCCESS,
  ON_DELETE_ROUTE_FAIL,
  ON_CLOSE_ROUTE_NOTIFICATION,
} from "../actions/types"

const INIT_STATE = {
  routes: [],
  routeToAdd: null,
  currentRoute: null,
  showNotification_Success: false,
  showNotification_Faiture: false,
  isLoading: false,
  isLoadingRouteStations: false,
  isError: false,
  isExistRouteName: false,
  isCheckedRouteName: false,
  routeStations: [],

  showDeleteSuccess: false,
  showDeleteFail: false,
  showDeleteConflict: false,
  showCreateSuccess: false,
  showCreateFail: false,
  showCreateConflict: false,
  showUpdateSuccess: false,
  showUpdateFail: false,
  showUpdateConflict: false,

  reloadRoutes: false,
}

const RoutesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_ROUTE_SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
        isLoadingRouteStations: false,
        showNotification_Faiture: false,
        showNotification_Success: false,
        isError: false,
      }
    case ON_GET_ROUTES_SUCCESS:
      return {
        ...state,
        routes: action.payload,
        isLoading: false,
        isLoadingRouteStations: false,
        currentRoute: null,
        reloadRoutes: false,
      }
    case ON_GET_ROUTES_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoadingRouteStations: false,
        showNotification_Faiture: true,
        reloadRoutes: false,
      }
    case ON_SET_ROUTE:
      return {
        ...state,
        currentRoute: action.payload,
        isError: false,
      }
    case ON_UPDATE_ROUTE_SUCCESS:
      return {
        ...state,
        currentRoute: null,
        isLoading: false,
        showUpdateSuccess: true,
        reloadRoutes: true,
      }
    case ON_UPDATE_ROUTE_FAIL:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status === 409
      ) {
        return {
          ...state,
          currentRoute: null,
          isLoading: false,
          showUpdateConflict: true,
          isError: action.payload,
          reloadRoutes: true,
        }
      }
      return {
        ...state,
        currentRoute: null,
        isLoading: false,
        showUpdateFail: true,
        isError: action.payload,
        reloadRoutes: true,
      }
    case ON_CREATE_ROUTE_SUCCESS:
      return {
        ...state,
        currentRoute: null,
        showCreateSuccess: true,
        isLoading: false,
        reloadRoutes: true,
      }
    case ON_CREATE_ROUTE_FAIL:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status === 409
      ) {
        return {
          ...state,
          currentRoute: null,
          isLoading: false,
          showCreateConflict: true,
          isError: action.payload,
          reloadRoutes: true,
        }
      }
      return {
        ...state,
        isError: action.payload,
        showCreateFail: true,
        isLoading: false,
        reloadRoutes: true,
      }
    case ON_ROUTE_STATIONS_SHOW_LOADING:
      return {
        ...state,
        isLoadingRouteStations: true,
      }
    case GET_STATIONS_BY_ROUTE_SUCCESS:
      return {
        ...state,
        routeStations: action.payload,
        isLoadingRouteStations: false,
        isLoading: false,
      }
    case GET_STATIONS_BY_ROUTE_FAIL:
      return {
        ...state,
        isError: action.payload,
        showNotification_Faiture: true,
        isLoadingRouteStations: false,
        isLoading: false,
      }

    case ON_CHECK_EXIST_ROUTE_NAME_LOADING:
      return {
        ...state,
        isExistRouteName: false,
        isCheckedRouteName: false,
        isError: false,
      }

    case ON_CHECK_EXIST_ROUTE_NAME_SUCCESS:
      return {
        ...state,
        isExistRouteName: action.payload,
        isCheckedRouteName: true,
      }

    case ON_CHECK_EXIST_ROUTE_NAME_FAIL:
      return {
        ...state,
        isExistRouteName: false,
        isCheckedRouteName: false,
        isError: action.payload,
      }

    case ON_DELETE_ROUTE_SUCCESS:
      return {
        ...state,
        currentRoute: null,
        isLoading: false,
        showDeleteSuccess: true,
        reloadRoutes: true,
      }
    case ON_DELETE_ROUTE_FAIL:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status === 409
      ) {
        return {
          ...state,
          currentRoute: null,
          isLoading: false,
          showDeleteConflict: true,
          isError: action.payload,
          reloadRoutes: true,
        }
      }
      return {
        ...state,
        currentRoute: null,
        isLoading: false,
        showDeleteFail: true,
        isError: action.payload,
        reloadRoutes: true,
      }

    case ON_CLOSE_ROUTE_NOTIFICATION:
      return {
        ...state,
        showDeleteSuccess: false,
        showDeleteFail: false,
        showDeleteConflict: false,
        showCreateSuccess: false,
        showCreateFail: false,
        showCreateConflict: false,
        showUpdateSuccess: false,
        showUpdateFail: false,
        showUpdateConflict: false,
      }
    default:
      return state
  }
}

export default RoutesReducer
