import {
  ON_CREATE_USER_SUCCESS,
  ON_USER_DATA_FIELD_UPDATE,
  FETCH_EXISTING_USER_DATA,
  ON_SET_USER_ID,
  CLEAR_CURRENT_CREATE_EDIT_USER_DATA,
  ON_CREATE_UPDATE_USER_REQUEST,
  ON_UPDATE_USER_SUCCESS,
  ON_CREATE_UPDATE_USER_ERROR,
  ON_GET_COUNTRIES_SUCCESS,
  ON_GET_LANGUAGE_SUCCESS,
  ON_USER_CCTOKEN_REMOVED_SUCCESS,
  ON_USER_CCTOKEN_REMOVED_FAIL,
  ON_CREATE_UPDATE_USER_EXISTING_ERROR,
  CLEAR_USER_EXISTING_ERROR,
} from "../actions/types"

const INIT_STATE = {
  userData: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    mobilePhone: "",
    companyName: "",
    companyNumber: "",
    language: "",
    comment: "",
    role: "",
    temporaryPassword: "",
    stationId: "",
    address: null,
    compensation: 0,
    errorMessage: "",
    ccTokenRemovedSuccess: null,
    ccTokenRemovedFail: null,
    isUserExistError: false,
    discountExpirationDate: null,
  },
  languages: [],
  countries: [],
  isThatNewUser: true,
  loadingCreateUpdateRequest: false,
  CreateUpdateRequestError: null,
  CreateUpdateRequsetSuccess: false,
}

const CreateUpdateUserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_SET_USER_ID:
      const isThatNewUser = action.payload === ""
      console.log(
        "userId: " + action.payload + "  isThatNewUser: " + isThatNewUser
      )
      return {
        ...state,
        isThatNewUser: false,
        userData: {
          id: action.payload,
          firstName: "",
          lastName: "",
          email: "",
          mobilePhone: "",
          companyName: "",
          companyNumber: "",
          language: "",
          comment: "",
          role: "",
          temporaryPassword: "",
          stationId: "",
          address: null,
          compensation: 0,
          errorMessage: "",
          ccTokenRemovedSuccess: null,
          ccTokenRemovedFail: null,
          discountExpirationDate: null,
        },
      }
    case FETCH_EXISTING_USER_DATA:
      return {
        ...state,
        isThatNewUser: false,
        userData: action.payload,
      }
    case ON_CREATE_UPDATE_USER_REQUEST:
      return {
        ...state,
        loadingCreateUpdateRequest: true,
        CreateUpdateRequestError: null,
        CreateUpdateRequsetSuccess: false,
      }
    case ON_CREATE_USER_SUCCESS:
      return {
        ...INIT_STATE,
        loadingCreateUpdateRequest: false,
        CreateUpdateRequsetSuccess: true,
      }
    case ON_UPDATE_USER_SUCCESS:
      return {
        ...state,
        loadingCreateUpdateRequest: false,
        CreateUpdateRequsetSuccess: true,
      }
    case ON_CREATE_UPDATE_USER_ERROR:
      return {
        ...state,
        loadingCreateUpdateRequest: false,
        CreateUpdateRequestError: true,
        errorMessage: action.payload,
      }
    case ON_GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.payload,
      }
    }
    case ON_GET_LANGUAGE_SUCCESS: {
      return {
        ...state,
        languages: action.payload,
      }
    }
    case ON_USER_DATA_FIELD_UPDATE:
      return {
        ...state,
        userData: {
          ...state.userData,
          [action.payload.filedName]: action.payload.val,
        },
      }
    case ON_USER_CCTOKEN_REMOVED_SUCCESS:
      return {
        ...state,
        ccTokenRemovedFail: false,
        ccTokenRemovedSuccess: true,
        userData: action.payload,
      }
    case ON_USER_CCTOKEN_REMOVED_FAIL:
      return {
        ...state,
        ccTokenRemovedSuccess: false,
        ccTokenRemovedFail: true,
      }
    case CLEAR_CURRENT_CREATE_EDIT_USER_DATA:
      return {
        ...INIT_STATE,
      }
    case ON_CREATE_UPDATE_USER_EXISTING_ERROR:
      return {
        ...state,
        isUserExistError: true,
      }
    case CLEAR_USER_EXISTING_ERROR:
      return {
        ...state,
        isUserExistError: false,
      }
    default:
      return state
  }
}

export default CreateUpdateUserReducer
