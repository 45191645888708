import {
  SET_USER_HISTORY_CURRENT_USER,
  ON_LOADING_USER_HISTORY,
  GET_USER_HISTORY_SUCCESS,
  GET_USER_HISTORY_FAIL,
  CLEAR_USER_HISTORY_LIST,
  SET_USER_HISTORY_SEARCH,
  ON_USER_SIGN_IN_RESET_ATTEMPTS,
  CLEAR_CURRENT_CREATE_EDIT_USER_DATA,
  UNAUTHORIZED,
  RESET_ERROR,
} from "../actions/types"

const INIT_SEARCH = {
  searchText: "",
  userId: "",
  fromDate: "",
  toDate: "",
  pageStep: 50,
  page: 1,
}

const INIT_STATE = {
  isLoading: false,
  isError: false,
  currentUserId: "",
  search: INIT_SEARCH,
  userHistoryList: [],
  currentSearchedPage: 1,
  currentSearchedPageStep: 50,
}

const UserHistoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_LOADING_USER_HISTORY:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case SET_USER_HISTORY_CURRENT_USER:
      return {
        ...state,
        currentUserId: action.payload,
        search: {
          searchText: "",
          userId: action.payload,
          fromDate: "",
          toDate: "",
          pageStep: 50,
          page: 1,
        },
        userHistoryList: [],
        isLoading: false,
      }

    case GET_USER_HISTORY_SUCCESS:
      return {
        ...state,
        userHistoryList: action.payload.userHistoryList,
        currentSearchedPage: action.payload.page,
        currentSearchedPageStep: action.payload.pageStep,
        isLoading: false,
      }
    case GET_USER_HISTORY_FAIL:
      return {
        ...state,
        userHistoryList: [],
        isLoading: false,
      }
    case SET_USER_HISTORY_SEARCH:
      return {
        ...state,
        search: action.payload,
        isLoading: false,
      }

    case RESET_ERROR:
      return {
        ...state,
        isError: false,
      }
    case CLEAR_USER_HISTORY_LIST:
    case CLEAR_CURRENT_CREATE_EDIT_USER_DATA:
    case ON_USER_SIGN_IN_RESET_ATTEMPTS:
    case UNAUTHORIZED:
      return {
        isLoading: false,
        isError: false,
        currentUserId: "",

        userHistoryList: [],
        currentSearchedPage: 1,
        currentSearchedPageStep: 50,
        search: {
          searchText: "",
          userId: "",
          fromDate: "",
          toDate: "",
          pageStep: 50,
          page: 1,
        },
      }
    default:
      return state
  }
}

export default UserHistoryReducer
