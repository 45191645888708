import React from 'react';
import { connect } from 'react-redux';

const TextMessagesList = (props) => {
    const { textMessages } = props;
    return (
        <ul>
            {renderMessagesList(textMessages)}
        </ul>
    )
}

const renderMessagesList = (textMessages) => {
    return textMessages.map((message) => <li key={message.messageId}>{message.messageName}</li>);
}

const MapStateToProps = ({ editorSection }) => {
    const { textMessages } = editorSection;
    return {
        textMessages,
    };
}

export default connect(MapStateToProps, null)(TextMessagesList);