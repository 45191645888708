import React from "react";

import OrderItemCreation from "./OrderItemCreation";
import OrderItem from "./OrderItem";

import "./style.css";

const OrderItemsList = ({ items, addNewItem, processes }) => {
  return (
    <div className="oil-lst-wrapper">
      {renderHeader(items)}

      {renderItems(items)}

      <OrderItemCreation
        addNewItem={(itm) => addNewItem(itm)}
        processes={processes}
      />
    </div>
  );
};

const renderItems = (items) => {
  return items.map((itm, index) => {
    return <OrderItem item={itm} key={index} />;
  });
};

const renderHeader = (items) => {
  if (items.length <= 0) {
    return;
  }

  return (
    <div>
      פריטי ההזמנה:
      <OrderItem
        header
        item={{
          processName: "שם התהליך",
          quantity: "כמות",
          itemPrice: "מחיר הפריט",
        }}
      />
    </div>
  );
};

export default OrderItemsList;
