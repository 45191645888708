import React, { Component } from "react"
import { connect } from "react-redux"

//import Select from "react-select";
import { PaginationNext, BlueButton } from "../../common"
import {
  getValEnumFromIndex,
  getStatusNameFromObj,
  paymentVersionList,
} from "../../../utils"

import OrderList from "../OrdersListScreen/OrderList"
import CreateEditOrderModal from "../../Modals/CreateEditOrderModal"
import { OrdersSearchFilterer } from "../../reduxified"
import exportFromJSON from "export-from-json"

import {
  onOrdersFilterByChange,
  updateOrderData,
  setExternalOrderToEdit,
  fetchSearchedOrders,
  onStartLoadingOrders,
  setOrdersSearch,
  //setSearchPageStep,
} from "../../../actions"

import "./style.css"

const orderListHeaders = [
  "מזהה",
  "מס חבילה",
  "תאריך",
  "סטטוס",
  "סוג",
  "מס עמדה",
  "שם סניף",

  "מיקום ההזמנה",
  "שם לקוח",
  "נייד",
  "סכום (₪)",

  "שולם",
  "תשלום חודשי",
  "סוג עמדה",
  "",
]
const orderListItemMap = [
  "orderNumber",
  "packageNumber",
  "orderFormatDate",
  "orderFormatStatus",
  "orderFromatType",
  "stationNumber",
  "branchName",

  "currentLocation",
  "userFormatFullName",
  "mobilePhone",
  "orderTotalPrice",
  "orderFormatIspayed",
  "isMonthlyPayment",
  "stationPaymentVersion",
]
const orderListGravity = { 2: 2, 8: 2, 9: 2 }

class OrdersSearchScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      pagedList: [],
      showOrderEditModal: false,
      orderToEdit: null,
      externalSearchTerms: null,
    }

    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const searchTerm = urlParams.get("searchTerm")

    if (searchTerm) {
      const search = {
        searchText: searchTerm,
        stations: [],
        statuses: [],
        orderTypes: [],
        accountTypes: [],
        routeId: null,
        fromDate: "",
        toDate: "",
        isPayed: null,
        isMonthlyPayment: null,
        stationPaymentVersion: null,
        pageStep: 50,
        page: 1,
      }
      this.setState({ externalSearchTerms: searchTerm })
      this.props.setOrdersSearch(search)
    }
  }

  componentWillMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.externalSearchTerms !== this.state.externalSearchTerms) {
      this.searchOrdersClick()
    }
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }

    const isOrdersListChanged =
      this.props.searchedOrdersArr !== newProps.searchedOrdersArr

    if (isOrdersListChanged) {
      const { ordersStatusesArr, orderTypes } = newProps
      const isExistOrdersStatuses = ordersStatusesArr.length > 0
      const isExistOrdersTypes = orderTypes.length > 0
      newProps.searchedOrdersArr.forEach((element) => {
        element.orderFormatStatus = isExistOrdersStatuses
          ? getStatusNameFromObj(newProps.orderStatusesObj, element.orderStatus)
          : element.orderFormatStatus
        element.orderFromatType = isExistOrdersTypes
          ? getValEnumFromIndex(newProps.orderTypes, element.orderType)
          : element.orderFromatType
        element.stationPaymentVersion = getStatusNameFromObj(
          paymentVersionList,
          element.stationPaymentVersion
        )
      })
    }
  }

  searchOrdersClick(search) {
    this.props.onStartLoadingOrders()
    if (search) {
      this.props.fetchSearchedOrders(search)
    } else {
      this.props.fetchSearchedOrders(this.props.search)
    }
  }

  filterEnterAndSubmit(e) {
    if (e.key === "Enter") {
      this.searchOrdersClick()
    }
  }

  onPageClick(page) {
    let { search } = this.props
    search.page = page
    this.searchOrdersClick(search)
  }

  /*onPageStepChange(pageStep){
        let {search}= this.props;
        search.pageStep=pageStep.value;
        search.page=1;
        this.props.setOrdersSearch(search);
        this.searchOrdersClick(search);
    }*/

  onOrderItemEditBtnClick(order) {
    console.log("onOrderItemEditBtnClick", order)
    this.props.setExternalOrderToEdit(order)
    this.props.history.push("OrdersEditingScreen")
  }

  onUpdatedOrderSubmitted({ status, totalPrice, orderItems }) {
    const { orderDetails } = this.props
    const updatedOrder = {
      ...orderDetails,
      orderStatus: status,
      orderTotalPrice: totalPrice,
      orderItems,
    }

    this.props.updateOrderData(updatedOrder)
    this.setState({ showOrderEditModal: false, orderToEdit: null })
  }

  onSearchFilterChanged(searchFilters) {
    this.props.setOrdersSearch(searchFilters)
  }

  exportOrdersToExcel() {
    const { orderTypes, orderStatusesObj } = this.props

    const data = this.props.searchedOrdersArr.map((order) => {
      return {
        orderNumber: order.orderNumber,
        packageNumber: order.packageNumber,
        orderDate: order.orderDate,
        orderStatus: getStatusNameFromObj(orderStatusesObj, order.orderStatus),
        orderType: getValEnumFromIndex(orderTypes, order.orderType),
        currentLocation: order.currentLocation,
        firstName: order.firstName,
        lastName: order.lastName,
        mobilePhone: order.mobilePhone,
        email: order.email,
        companyName: order.companyName,
        orderTotalPrice: order.orderTotalPrice,
        discount: order.discount,
        discountType: order.discountType,
        compensation: order.compensation,
        couponCode: order.couponCode,
        isPayed: order.isPayed,
        isMonthlyPayment: order.isMonthlyPayment,
        stationPaymentVersion: order.stationPaymentVersion,
        paymentDate: order.paymentDate,
        stationNumber: order.stationNumber,
        branchName: order.branchName,
        customerFullName: order.customerFullName,
        trackingNote: order.trackingNote ?? "",
        //isBLE: order.isBLE,
        //senderFullName:order.senderFullName,
        //senderMobilePhone: order.senderMobilePhone,
        //stationSource: order.stationSource && order.stationSource != null? order.stationSource.stationNumber : "",
        //stationTarget: order.stationTarget && order.stationTarget != null? order.stationTarget.stationNumber : "",
      }
    })
    const fileName = "orders"
    const exportType = exportFromJSON.types.csv

    exportFromJSON({
      data,
      fileName,
      exportType,
      withBOM: "\uFEFF",
    })
  }

  render() {
    const { showOrderEditModal, orderToEdit } = this.state

    //const  defaultPageStep = pageStepList.filter(i => i.value === search.pageStep)[0];

    const { searchedOrdersArr, isError, isLoading, search } = this.props

    return (
      <div className="search-orders-screen-wrapper">
        <div className="line-wrapper">
          <OrdersSearchFilterer
            onSearchFilterChanged={(searchFilters) =>
              this.onSearchFilterChanged(searchFilters)
            }
            filterEnterAndSubmit={(e) => this.filterEnterAndSubmit(e)}
            searchOrdersClick={(e) => this.searchOrdersClick()}
          />
        </div>

        {/*<div className='page-step-select-wrapper'>
                    <Select
                        defaultValue={defaultPageStep}
                        options={pageStepList}
                        onChange={(itm) => this.onPageStepChange(itm)}
                    />
                    </div>*/}
        {/*<div className="user-list-counter">  {searchedOrdersArr.length} רשומות להצגה </div>*/}

        <OrderList
          extraClass="ols-wrapper-list"
          headers={orderListHeaders}
          itemMap={orderListItemMap}
          gravity={orderListGravity}
          items={searchedOrdersArr}
          onItemEditBtnClick={(itm) => this.onOrderItemEditBtnClick(itm)}
          isError={isError}
          isLoading={isLoading}
        />

        <PaginationNext
          pageStep={this.props.currentSearchedPageStep}
          page={this.props.currentSearchedPage}
          onClick={(page) => this.onPageClick(page)}
          currentResponseCount={searchedOrdersArr.length}
        />

        <div className="export-wrapper">
          <BlueButton
            className="blbtn-wrapper base-btn"
            onClick={() => this.exportOrdersToExcel()}
          >
            יצאו לקובץ Excel
          </BlueButton>
        </div>

        <CreateEditOrderModal
          show={showOrderEditModal}
          orderToEdit={orderToEdit}
          onSubmitOrderResultClick={(order) =>
            this.onUpdatedOrderSubmitted(order)
          }
          onCancelClick={() =>
            this.setState({ showOrderEditModal: false, orderToEdit: null })
          }
        />
      </div>
    )
  }
}

const mapStateToProp = ({
  ordersList,
  ordersStatuses,
  errors,
  selectOptions,
  ordersSearch,
}) => {
  const { orderDetails, filteredListArr, filterBy } = ordersList
  const {
    searchedOrdersArr,
    isError,
    isLoading,
    currentSearchedPage,
    currentSearchedPageStep,
    search,
  } = ordersSearch
  const { ordersStatusesArr, orderStatusesObj } = ordersStatuses
  const { orderTypes } = selectOptions
  const { unauthorized } = errors
  return {
    searchedOrdersArr,
    ordersStatusesArr,
    orderStatusesObj,
    orderTypes,
    filteredListArr,
    orderDetails,
    filterBy,
    isError,
    isLoading,
    currentSearchedPage,
    currentSearchedPageStep,
    search,
    unauthorized,
  }
}

export default connect(mapStateToProp, {
  onOrdersFilterByChange,
  updateOrderData,
  setExternalOrderToEdit,
  fetchSearchedOrders,
  onStartLoadingOrders,
  setOrdersSearch,
  getStatusNameFromObj,
})(OrdersSearchScreen)
