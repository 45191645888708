import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchAllStationData,
  localSearchCitiesByText,
  clearLocalSearchCities,
} from "../../../actions"
import { fetchIfEmpty } from "../../../utils"
import { InputFiled } from "../../common"
import "./style.css"

class CitiesFilterer extends Component {
  componentDidMount() {
    this.props.fetchAllStationData()
    //fetchIfEmpty(this.props.statusesArr, this.props.fetchAllOrderStatuses)
  }

  componentWillUnmount() {
    this.props.clearLocalSearchCities()
  }

  render() {
    const { searchText, filtererActiveCities } = this.props
    return (
      <div className="stations-filterer">
        <InputFiled
          extraClass="search-input"
          placeholder="חיפוש לפי שם"
          value={searchText}
          alt="חיפוש"
          icon="magnifying_glass"
          onChange={(e) => {
            this.props.resetCurrentPage()
            this.props.localSearchCitiesByText(e.target.value)
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ cities }) => {
  const { citiesLocalSearch } = cities
  const { searchText, filtererActiveCities } = citiesLocalSearch

  return {
    citiesLocalSearch,
    searchText,
    filtererActiveCities,
  }
}

export default connect(mapStateToProps, {
  fetchAllStationData,
  localSearchCitiesByText,
  clearLocalSearchCities,
})(CitiesFilterer)
