import React from "react"
import { getFormatedDate } from "../../../../../../utils/FormatedDate"
import "./style.css"

const lockersStatusLocal = {
  0: "תקול",
  1: "לאיסוף",
  2: "מוכן",
  3: "ריק",
  4: "שמור",
}

const LockerHistoryItem = ({
  item,
  itemMap,
  gravity,
  isLast,
  lockerTypes,
  lockerStatuses,
}) => {
  const itemClassName = `item-wrapper ${isLast ? "item-last" : ""}`

  return (
    <div className={itemClassName}>
      {itemMap.map((itmMap, index) => {
        const flex = gravity[`${index}`] || 1

        switch (itmMap) {
          case "lockerType":
            let lockerType = lockerTypes[item.lockerType]
              ? lockerTypes[item.lockerType]
              : "NA"
            return (
              <div style={{ flex }} key={index}>
                {lockerType}
              </div>
            )

          case "lockerStatus":
            const lockerStatus = lockersStatusLocal[item[itmMap]]
            return (
              <div style={{ flex }} key={index}>
                {lockerStatus}
              </div>
            )
          case "orderNumber":
            const orderNumber = item[itmMap] === 0 ? "" : item[itmMap]
            return (
              <div style={{ flex }} key={index}>
                {orderNumber}
              </div>
            )
          case "createdDate":
            const formatedDate = getFormatedDate(item[itmMap])
            return (
              <div style={{ flex }} key={index}>
                {formatedDate}
              </div>
            )
          case "lockerName":
            return (
              <div style={{ flex }} key={index}>
                <div className="circle locker-name">{item[itmMap]}</div>
              </div>
            )

          default:
            let itemContent = item[itmMap]
            return (
              <div style={{ flex }} key={index}>
                {itemContent}
              </div>
            )
        }
      })}
    </div>
  )
}

export default LockerHistoryItem
