import React, { Component } from "react"
import { connect } from "react-redux"
import { BlueButton } from "../../../../../common"

import "./style.scss"

class DriverItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updatedDriver: null,
      showLockerStatusNoteModal: false,
      showNotificationLockerWithOrder: false,
      showErrorUpdate: false,
    }
  }

  componentWillMount() {
    this.setState({
      updatedDriver: this.props.item,

      showLockerStatusNoteModal: false,
      showNotificationLockerWithOrder: false,
      showErrorUpdate: false,
    })
  }

  render() {
    const {
      item,
      itemMap,
      gravity,
      isLast,
      onRemoveDriverFromRouteClick,
      counter,
    } = this.props

    const itemClassName = `item-wrapper ${isLast ? "item-last" : ""}`

    return (
      <div className={itemClassName}>
        {itemMap.map((itmMap, index) => {
          const flex = gravity[`${index}`] || 1

          switch (itmMap) {
            case "number":
              return (
                <div style={{ flex }} key={index}>
                  <div className="num circle">{counter}</div>
                </div>
              )
            case "empty":
              return <div style={{ flex }} key={index}></div>
            case "driverFullName":
              return (
                <div style={{ flex }} key={index}>
                  {item.firstName} {item.lastName}
                </div>
              )

            case "removeDriverFromRoute":
              return (
                <div style={{ flex }} key={index}>
                  <BlueButton
                    extraClass="remove-driver-btn"
                    onClick={(e) => onRemoveDriverFromRouteClick(item.id)}
                  >
                    הסר הרשאה למסלול
                  </BlueButton>
                </div>
              )
            case "routes":
              return (
                <div style={{ flex }} key={index}>
                  {item.routes.map((itm, index) => {
                    if (itm == null) {
                      return <div className="circle" key={index}></div>
                    }
                    return (
                      <div
                        className="circle"
                        key={index}
                        style={{ background: itm.color }}
                        title={itm.routeName}
                      ></div>
                    )
                  })}
                </div>
              )
            default:
              let itemContent = item[itmMap]
              return (
                <div style={{ flex }} key={index}>
                  {itemContent}
                </div>
              )
          }
        })}
      </div>
    )
  }
}

const mapStateToProps = ({ routes }) => {
  const routesList = routes.routes

  return {
    routesList,
  }
}
export default connect(mapStateToProps, {})(DriverItem)
