import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Link } from "react-router-dom";
import SuppliersList from "./SuppliersList";
import EditSupplier from "./EditSupplier";
import NewSupplier from "./NewSupplier";
import "./style.scss";

const suppliersList_HeaderText = "רשימת ספקים";
const newSupplier_HeaderText = "יצירת ספק חדש";

class ServiceSuppliersScreen extends Component {
  componentWillUnmount() {}

  render() {
    return (
      <section id="supplier-section-container">
        <nav className="section-menu">
          <ul>
            <li className="section-menu-item">
              <Link to={`${this.props.match.url}`}>
                {suppliersList_HeaderText}
              </Link>
            </li>
            <li className="section-menu-item">
              <Link to={`${this.props.match.url}/create`}>
                {newSupplier_HeaderText}
              </Link>
            </li>
          </ul>
        </nav>
        <main className="section-body">
          <Route
            exact
            path={`${this.props.match.path}`}
            component={SuppliersList}
          />
          <Route
            path={`${this.props.match.path}/edit`}
            component={EditSupplier}
          />
          <Route
            path={`${this.props.match.path}/create`}
            component={NewSupplier}
          />
        </main>
      </section>
    );
  }
}

export default ServiceSuppliersScreen;
