import axios from 'axios';

import api from '../api';

import {
    ON_GET_TEXT_MESSAGES_SUCCESS,
    ON_GET_TEXT_MESSAGES_FAILURE,
    ON_PICK_TEXT_MESSAGE,
} from './types';

export const getTextMessages = () => {
    return (dispatch) => {
        axios({
            url: api.getTextMessages,
            method: 'GET' 
        }).then((response) => {
            dispatch({
                type: ON_GET_TEXT_MESSAGES_SUCCESS,
                payload: response.data,
            })
        })
            .catch((error) => {
                dispatch({
                    type: ON_GET_TEXT_MESSAGES_FAILURE,
                    payload: error
                })
            })

    }
};

export const pickTextMessage = (message) => {
    return (dispatch) => {
        dispatch({
            type: ON_PICK_TEXT_MESSAGE,
            payload: message,
        })
    };
};