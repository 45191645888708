import axios from 'axios';
import api from '../api';

import {
    ON_ORDERS_IMPORT_SUCCESS,
    ON_USERS_IMPORT_SUCCESS,
    ON_PRICE_LIST_IMPORT_SUCCESS,
} from './types';

export const importOrders = (file) => {
    return (dispatch) => {
        const formData = new FormData()
        formData.append('file', file);

        const config = {
            onUploadProgress: function (progressEvent) {
                // let progress = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
                //console.log(`onUploadProgress progressEvent.loaded: ${progressEvent.loaded}, progressEvent.total: ${progressEvent.total}`);
            }
        }

        axios.post(api.importOrders, formData, config)
        .then(response => {
            console.log(response);
            dispatch({
                type: ON_ORDERS_IMPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            console.log(error);
        });
    }
}

export const importUsers = (file) => {
    return (dispatch) => {
        const formData = new FormData()
        formData.append('file', file);

        axios.post(api.importUsers, formData)
        .then(response => {
            console.log(response);
            dispatch({
                type: ON_USERS_IMPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            console.log(error);
        });
    }
}

export const importPrices = (file) => {
    return (dispatch) => {
        const formData = new FormData()
        formData.append('file', file);

        const config = {
            onUploadProgress: function (progressEvent) {
                // let progress = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
                //console.log(`onUploadProgress progressEvent.loaded: ${progressEvent.loaded}, progressEvent.total: ${progressEvent.total}`);
            }
        }

        axios.post(api.importPriceList, formData, config)
        .then(response => {
            console.log(response);
            dispatch({
                type: ON_PRICE_LIST_IMPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            console.log(error);
        });
    }
}