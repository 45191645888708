import React from 'react';

import './style.css';

const LabeledFileInput = ({ labelText, inputId, ...props }) => {
    return (
        <div>
            <input id={inputId} className="ss-file-input" type="file" {...props} />
            <label className="file-input-button" htmlFor={inputId}>{labelText}</label>
        </div>
    );
}

export { LabeledFileInput };