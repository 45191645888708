import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"

import {
  ON_USER_SIGN_IN_SUCCESS,
  ON_USER_SIGN_IN_FAIL,
  ON_USER_SIGN_IN_REQUEST,
  ON_USER_SIGN_IN_RESET_ATTEMPTS,
  ON_UPDATE_SELF_PASSWORD_REQUEST,
  ON_UPDATE_SELF_PASSWORD_SUCCESS,
  ON_UPDATE_SELF_PASSWORD_FAIL,
} from "./types"

export const loginUser = (mobile, pwd) => {
  return (dispatch) => {
    dispatch({
      type: ON_USER_SIGN_IN_REQUEST,
    })

    axios
      .post(api.signIn, api.signInBody(mobile, pwd))
      .then((res) => {
        const {
          id: userId,
          role,
          firstName,
          lastName,
          token,
          isPasswordTemporary,
          email,
          creditCardToken,
        } = res.data

        axios.defaults.headers.common["Authorization"] = `Bearer ${token}` //eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjZmOTE1NDI0LTk0NmMtNGZmMi0xMGNhLTA4ZDZmNTVhNGJlZSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL21vYmlsZXBob25lIjoiMDUyNDI0MDkzNCIsImVtYWlsIjoieXVsaWFnQGVmY29tLmNvLmlsIiwicm9sZSI6Ik1hbmFnZXIiLCJuYmYiOjE1NjEwMjAwOTAsImV4cCI6MTU2MTI3OTI5MCwiaWF0IjoxNTYxMDIwMDkwfQ.x2fDsgYvfhIafL4DnL-U6w7aqxWR4EzsIZ4CVSaL-VM';

        //TODO - USE :
        dispatch({
          type: ON_USER_SIGN_IN_SUCCESS,
          payload: {
            userId,
            role,
            firstName,
            lastName,
            token,
            isPasswordTemporary,
            creditCardToken,
          },
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_USER_SIGN_IN_FAIL,
          payload: "אחד או יותר מהפרטים שהוזנו שגויים. נסה שנית",
        })

        console.log(err)
      })
  }
}

export const updateSelfPassword = (pwd) => {
  return (dispatch) => {
    dispatch({
      type: ON_UPDATE_SELF_PASSWORD_REQUEST,
    })
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
        "Content-Type": "application/json",
      },
    }
    const data = {
      password: pwd,
    }
    axios
      .put(api.updateSelfPassword, data, options)
      .then((res) => {
        dispatch({
          type: ON_UPDATE_SELF_PASSWORD_SUCCESS,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_SELF_PASSWORD_FAIL,
        })

        console.log(err)
      })
  }
}

export const resetLoginAttempt = () => {
  return {
    type: ON_USER_SIGN_IN_RESET_ATTEMPTS,
  }
}
