import React from 'react';

import './style.css';

const SuccessFailBtn = ({ success, onSuccessFailClick }) => {

    const className = success ? 'o-circle__sign--success' : 'o-circle__sign--failure';

    return (
        <section className="c-container" onClick={e => onSuccessFailClick(success)}>
            <div className={`o-circle c-container__circle ${className}`}>
                <div className="o-circle__sign"></div>
            </div>
        </section>
    );
}

export default SuccessFailBtn;