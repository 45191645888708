export const setOrderStatusesEnumByLang = (obj, lang) => {
  if (obj === undefined || obj === null || obj.length <= 0) {
    return []
  }

  const items = []
  for (const [key, value] of Object.entries(obj)) {
    switch (lang) {
      case "en":
        items[value.id] = value.statusName
        break
      case "he":
        items[value.id] = value.statusName_he
        break
      default:
        items[value.id] = value.statusName_he
    }
  }
  return items
}

export const setOrderStatusesArr = (obj, lang, isOpenOrders) => {
  let ordersObj = setOrderStatusesObj(obj, lang, isOpenOrders)
  let sortedArr = []
  for (const [key, value] of Object.entries(ordersObj)) {
    sortedArr.push(value.label)
  }
  return sortedArr
}

export const setOrderStatusesArrSorted = (obj, lang, isOpenOrders) => {
  let ordersObj = setOrderStatusesObj(obj, lang, isOpenOrders)
  let newArr = []
  ordersObj.map((element) => {
    newArr[element.value] = element.value
    newArr[element.value] = element.label
  })
  return newArr
}

export const setOrderStatusesObj = (obj, lang, isOpenOrders) => {
  if (obj === undefined || obj === null || obj.length <= 0) {
    return []
  }
  const closedOrderStatuses = [5, 6, 12] // 5-is picked, 6 - canceled , 12 -returned to sender
  const items = []
  for (const [key, value] of Object.entries(obj)) {
    if (isOpenOrders) {
      if (closedOrderStatuses.includes(value.id)) {
        continue
      }
    }
    switch (lang) {
      case "en":
        items[key] = {
          id: value.id,
          statusName_he: value.statusName_he,
          sortPriority: value.sortPriority,
        }
        break
      case "he":
        items[key] = {
          value: value.id,
          label: value.statusName_he,
          sortPriority: value.sortPriority,
        }
        break
      default:
        items[key] = {
          id: value.id,
          statusName_he: value.statusName_he,
          sortPriority: value.sortPriority,
        }
    }
  }
  const sorterdItems = sortOrders(items)
  return sorterdItems
}

export const setOpenOrderStatusesEnumByLang = (obj, lang) => {
  if (obj === undefined || obj === null || obj.length <= 0) {
    return []
  }

  const closedOrderStatuses = [5, 6, 12] // 5-is picked, 6 - canceled , 12 -returned to sender
  const items = []

  for (const [key, value] of Object.entries(obj)) {
    if (closedOrderStatuses.includes(value.id)) {
      continue
    }
    switch (lang) {
      case "en":
        items[value.id] = value.statusName
        break
      case "he":
        items[value.id] = value.statusName_he
        break
      default:
        items[value.id] = value.statusName_he
    }
  }
  return items
}

export const DISCOUNT_TYPES_ENUM = ["%", "₪"]

export const setOrderTypesEnumByLang = (obj, lang) => {
  const items = []
  for (const [key, value] of Object.entries(obj)) {
    switch (lang) {
      case "en":
        items[value.id] = value.orderTypeName
        break
      case "he":
        items[value.id] = value.orderTypeName_he
        break
      default:
        items[value.id] = value.orderTypeName_he
    }
  }
  return items
}

export const CouponOrderTypes = () => {
  let orderType = []
  orderType[1] = "כביסה"
  orderType[2] = "דואר"
  //orderType[3] = "מוצר"
  orderType[4] = "השכרת לוקר"
  orderType[5] = "משלוח"
  //orderType[13] = "DHL";
  //orderType[14] = 'BAR';
  //orderType[15] = 'UPS';
  return orderType
}

export const SupplierTypes = () => {
  let supplierType = []
  supplierType[1] = "laundromat"
  supplierType[2] = "test"
  return supplierType
}

export const supplierTypesByValue = (typeValue) => {
  switch (typeValue) {
    case 1:
      return "laundromat"
    case 2:
      return "test"
    default:
  }
}

export const getStatusNameFromObj = (orders, status) => {
  let statusName = ""
  orders?.filter((obj) => {
    if (obj.value === status) {
      statusName = obj.label
    }
  })
  return statusName
}

export const CouponOrderTypes_ENUM = CouponOrderTypes()
export const SupplierTypes_ENUM = SupplierTypes()

export const ORDER_ISPAYED_ENUM = {
  false: "לא",
  true: "כן",
}

export const STATUS_CODE_ENUM = {
  401: "Unauthorized",
  400: "Bad request",
}

export const getValEnumFromIndex = (ENUM, index) => ENUM[index]
export const getIndexFromEnum = (ENUM, enumVal) => ENUM.indexOf(enumVal)

export const proccesesTohebrew = (proccess) => {
  switch (proccess) {
    case "ironing":
      return "גיהוץ בלבד"
    case "ironing and package":
      return "גיהוץ ואריזה לנסיעה"
    case "laundry ironing":
      return "כביסה וגיהוץ"
    case "dry cleaning":
      return "ניקוי יבש"
    case "leather cleaning":
      return "ניקוי עור"
    case "laundry":
      return "כביסה משקל"
    case "laundry detergents sale":
      return "מכירת חומרי כביסה"
    case "laundry ironing package":
      return "כביסה וגיהוץ אריזה לנסיעה"
    default:
      return proccess
  }
}

const sortOrders = (orders) => {
  orders.sort(function (a, b) {
    return parseFloat(a.sortPriority) - parseFloat(b.sortPriority)
  })
  return orders
}

export const lockerStatusToHebrew = (status) => {
  switch (status) {
    case 0:
    case "Error":
      return "תא תקול"
    case 1:
    case "FullDirty":
      return "מלא"
    case 2:
    case "FullClean":
      return "מלא"
    case 3:
    case "Empty":
      return "ריק"
    case 4:
    case "Reserved":
      return "תא שמור"
    default:
      return status
  }
}

export const paymentVersionList = [
  { value: 0, label: "כל הגרסאות" },
  { value: 1, label: "דור ראשון" },
  { value: 2, label: "דור שני" },
  { value: 3, label: "Nayax" },
  { value: 4, label: "BLE" },
  { value: 5, label: "Hybrid" },
  { value: 6, label: "Pudo" },
]

export const stationIsAliveSelector = [
  { value: 0, label: "כל עמדות" },
  { value: 1, label: "עמדות פעילות" },
  { value: 2, label: "עמדות לא פעילות" },
]

export const stationIsVisibleSelector = [
  { value: 0, label: "כל עמדות (מושבת)" },
  { value: 1, label: "עמדות לא מושבתות " },
  { value: 2, label: "עמדות מושבתות" },
]

//Broken,//0
//FullDirty, //1
///FullClean,//2
//Empty, //3

/*
let _ORDER_STATUS = [];
_ORDER_STATUS[0] = 'מחכה לאיסוף';             //'WaitingForPickup',
_ORDER_STATUS[1] = 'הזמנה נאספה עי נהג';     //'DirtyPickedUpByDriver',
_ORDER_STATUS[2] = 'בטיפול';                    //'Pending',
_ORDER_STATUS[3] = 'הזמנה מוכנה לטעינה';        //'CleanPickedUpByDriver',
_ORDER_STATUS[4] = 'מוכן בלוקר';               //'ReadyInLocker',
_ORDER_STATUS[5] = 'נאסף עי לקוח';            //'IsPicked',
_ORDER_STATUS[6] = 'מבוטל';                    //'Canceled',
_ORDER_STATUS[7] = 'תשלום חודשי';              //'MonthlyPayment',
//_ORDER_STATUS[8] = 'דואר-1';                   //'Mail1',
//_ORDER_STATUS[9] = 'דואר-2';                   //'Mail2',
//_ORDER_STATUS[10] = 'דואר-3';                   //'Mail3',
//_ORDER_STATUS[11] = 'דואר-4';                   //'Mail4',
_ORDER_STATUS[12] = 'הוחזר לשולח';                //ReturnedToSender
_ORDER_STATUS[13] = 'סומן לחזרה לשולח';        //MarkedAsReturnToSender

export const ORDER_STATUS_ENUM = _ORDER_STATUS;


let _ORDER_TYPE = [];
_ORDER_TYPE[0] = 'לא ידוע';
_ORDER_TYPE[1] = 'כביסה';
_ORDER_TYPE[2] = 'דואר';
_ORDER_TYPE[3] = 'מוצר';
_ORDER_TYPE[4] = 'השכרת לוקר';
_ORDER_TYPE[13] = 'DHL';
_ORDER_TYPE[14] = 'בר הפצה';
_ORDER_TYPE[15] = 'UPS';
_ORDER_TYPE[16] = 'Decathlon';

export const ORDER_TYPE_ENUM = _ORDER_TYPE;
*/
