import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { BlueButton } from "../../../../../common"
import CreateLockerStatusNoteModal from "../../../../../Modals/CreateLockerStatusNoteModal"
import NotificationModal from "../../../../../Modals/NotificationModal"

import {
  updateLockerStatus,
  updateToEmptyLockerStatus,
  setUpdateLockerStatusLoading,
} from "../../../../../../actions"

import "./style.css"

const colorMapping = {
  0: "#ffc107",
  1: "#ec6b6b",
  2: "#7ed592",
  3: "white",
  4: "#302a2c",
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: colorMapping[state.data.value] || "black",
    color: state.data.value === 3 ? "black" : "white",
    margin: "0 0 2px 0",
    border: "1px solid #b1adac",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    backgroundColor: colorMapping[state.data.value] || "black",
    color: state.data.value === 3 ? "black" : "white",
    borderRadius: "6px",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: colorMapping[state.selectProps.value.value] || "black",
  }),
}

class LockerItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updatedLocker: null,
      isRequiredNote: false,
      showLockerStatusNoteModal: false,
      showNotificationLockerWithOrder: false,
      nextAction: null,
      showErrorUpdate: false,
    }
  }

  componentWillMount() {
    this.setState({
      updatedLocker: this.props.item,
      isRequiredNote: false,
      showLockerStatusNoteModal: false,
      showNotificationLockerWithOrder: false,
      nextAction: null,
    })
  }

  selectLockerStatusBtnClick(event) {
    const { item } = this.props
    this.setState({ updatedLocker: { ...item, lockerStatus: event.value } })
  }

  updateLockerStatusNote(note) {
    let { updatedLocker } = this.state
    updatedLocker.note = note
    this.setState({ updatedLocker })
    this.callNextAction()
  }

  updateLockerStatusBtnClick() {
    const { updatedLocker } = this.state
    const isRequiredNote = true
    const isEmptyWithOrder =
      updatedLocker.lockerStatus === 3 && updatedLocker.order !== null

    if (isEmptyWithOrder) {
      this.setState({
        isRequiredNote,
        nextAction: "",
        showNotificationLockerWithOrder: true,
      })

      return
    }
    this.setState({
      isRequiredNote,
      nextAction: "updateLockerStatus",
      showLockerStatusNoteModal: true,
    })
  }
  closeLockerStatusNote() {
    this.setState({ showLockerStatusNoteModal: false })
  }

  closeNotificationLockerWithOrder() {
    this.setState({ showNotificationLockerWithOrder: false })
  }

  callNextAction() {
    const { nextAction, updatedLocker } = this.state
    const { currentLockersStationId } = this.props

    switch (nextAction) {
      case "updateLockerStatus":
        this.props.setUpdateLockerStatusLoading()
        this.props.updateLockerStatus(updatedLocker, currentLockersStationId)
        break
      case "emptyLocker":
        this.props.setUpdateLockerStatusLoading()
        this.props.updateToEmptyLockerStatus(
          updatedLocker,
          currentLockersStationId
        )
        break

      default:
        return
    }
  }

  emptyLockerBtnClick() {
    this.setState({
      isRequiredNote: false,
      nextAction: "emptyLocker",
      showLockerStatusNoteModal: true,
    })
  }

  handleRelocateOrder(orderId) {
    this.props.history.push("/LockersScreen/lockers/relocate-order/" + orderId)
  }

  loadOrderToLockerClick(item) {
    this.props.onLoadOrderClick(item)
  }

  render() {
    const {
      item,
      itemMap,
      gravity,
      isLast,
      lockerTypes,
      lockerStatusSelectOptions,
      onLoadOrderClick,
      onLoadOrderClick2,
      onLoadOrderDHLReturnsClick,
      lockerSizeOptions,
      onSelectLockerSize,
    } = this.props

    const itemClassName = `item-wrapper ${isLast ? "item-last" : ""}`

    return (
      <div className={itemClassName}>
        {itemMap.map((itmMap, index) => {
          const flex = gravity[`${index}`] || 1

          switch (itmMap) {
            case "lockerType":
              let lockerType = lockerTypes[item.lockerType]
                ? lockerTypes[item.lockerType]
                : "NA"
              return (
                <div style={{ flex }} key={index}>
                  {lockerType}
                </div>
              )
            case "isAccessible":
              if (item.isAccessible) {
                return (
                  <div style={{ flex }} key={index}>
                    <div className="fa fa-wheelchair" aria-hidden="true"></div>
                  </div>
                )
              }
              return <div style={{ flex }} key={index}></div>
            case "lockerSize":
              const currentSize = item.lockerSize
                ? {
                    label: item.lockerSize.name,
                    value: item.lockerSize,
                    rawSize: `${item.lockerSize.width}x${item.lockerSize.height}x${item.lockerSize.depth}`,
                  }
                : { label: "NA", value: null, rawSize: "גודל תא עדיין לא נבחר" }

              return (
                <div style={{ flex }} key={index} title={currentSize.rawSize}>
                  <Select
                    options={lockerSizeOptions}
                    onChange={(e) =>
                      onSelectLockerSize(item.lockerId, e.value.id)
                    }
                    value={currentSize}
                    placeholder="גודל תא"
                    classNamePrefix="base-select"
                  />
                </div>
              )
            case "lockerStatus":
              const currentValue = this.state.updatedLocker
                ? lockerStatusSelectOptions.filter(
                    (s) => s.value === this.state.updatedLocker.lockerStatus
                  )[0]
                : lockerStatusSelectOptions.filter(
                    (s) => s.value === item.lockerStatus
                  )[0]
              return (
                <div style={{ flex }} key={index}>
                  <Select
                    options={lockerStatusSelectOptions}
                    onChange={(e) => this.selectLockerStatusBtnClick(e)}
                    value={currentValue}
                    placeholder="סטטוס תא"
                    classNamePrefix="base-select"
                    styles={customStyles}
                  />
                </div>
              )
            case "orderNumber":
              if (item.order == null) {
                return (
                  <div style={{ flex }} key={index}>
                    <div className="order-na">NA</div>
                  </div>
                )
              }
              return (
                <div style={{ flex }} key={index}>
                  {item.order.orderNumber}
                </div>
              )
            case "updateLockerStatus":
              return (
                <div style={{ flex }} key={index}>
                  <BlueButton
                    extraClass="update-locker-status-btn"
                    onClick={(e) => this.updateLockerStatusBtnClick(item)}
                  >
                    עדכן סטטוס
                  </BlueButton>
                </div>
              )
            case "loadOrderToLocker":
              if (item.lockerStatus === 3) {
                return (
                  <div style={{ flex }} key={index}>
                    <BlueButton
                      extraClass="load-order-to-locker-btn"
                      onClick={(e) => onLoadOrderClick(item)}
                    >
                      טען הזמנה שמוכנה לטעינה
                    </BlueButton>
                  </div>
                )
              }
              return <div style={{ flex }} key={index}></div>
            case "loadOrderToLocker2":
              if (item.lockerStatus === 3) {
                return (
                  <div style={{ flex }} key={index}>
                    <BlueButton
                      extraClass="load-order-to-locker-btn"
                      onClick={(e) => onLoadOrderClick2(item)}
                    >
                      טען הזמנה שמחכה לאיסוף
                    </BlueButton>
                  </div>
                )
              }
              return <div style={{ flex }} key={index}></div>
            case "loadOrderToLockerDHLReturns":
              if (item.lockerStatus === 3) {
                return (
                  <div style={{ flex }} key={index}>
                    <BlueButton
                      extraClass="load-order-to-locker-btn"
                      onClick={(e) => onLoadOrderDHLReturnsClick(item)}
                    >
                      טען הזמנה DHL החזרות
                    </BlueButton>
                  </div>
                )
              }
              return <div style={{ flex }} key={index}></div>
            case "relocateOrder":
              if (item.order === null) {
                return <div style={{ flex }} key={index}></div>
              }
              return (
                <div style={{ flex }} key={index}>
                  <BlueButton
                    extraClass="relocate-btn"
                    onClick={(e) =>
                      this.handleRelocateOrder(item.order.orderId)
                    }
                  >
                    <div className="fas fa-truck" />
                  </BlueButton>
                </div>
              )
            case "lockerName":
              return (
                <div style={{ flex }} key={index}>
                  <div className="circle locker-name">{item[itmMap]}</div>
                </div>
              )
            default:
              let itemContent = item[itmMap]
              return (
                <div style={{ flex }} key={index}>
                  {itemContent}
                </div>
              )
          }
        })}

        <CreateLockerStatusNoteModal
          show={this.state.showLockerStatusNoteModal}
          isRequiredNote={this.state.isRequiredNote}
          updateLockerStatusNote={(e) => this.updateLockerStatusNote(e)}
          onClose={(e) => this.closeLockerStatusNote()}
        />
        <NotificationModal
          show={this.state.showNotificationLockerWithOrder}
          type={"warning"}
          title={"עדכון תא עם הזמנה"}
          text={
            "לא ניתן לעדכן סטטוס תא לריק עם הזמנה משוייכת. נא לעדכן קודם הזמנה."
          }
          onOkClick={(e) => this.closeNotificationLockerWithOrder()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ selectOptions, lockersList }) => {
  const {
    lockerStatuses,
    lockerStatusSelectOptions,
    lockerTypes,
    lockerTypesSelectOptions,
  } = selectOptions
  const { currentLockersStationId } = lockersList

  return {
    lockerStatuses,
    lockerStatusSelectOptions,
    lockerTypes,
    lockerTypesSelectOptions,
    currentLockersStationId,
  }
}

export default connect(mapStateToProps, {
  updateLockerStatus,
  updateToEmptyLockerStatus,
  setUpdateLockerStatusLoading,
})(LockerItem)
