import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import {
  LabeledTextInput,
  LabeledSelect,
  Spinner,
  ValidationMessage,
  CheckboxAndLabel,
  LabeledDatePicker,
  LabeledTextarea,
} from "../../../common"
import { getShortDate } from "../../../../utils/FormatedDate"
import {
  isValidField,
  FIELD_TYPE_ENUM,
} from "../../../../utils/FieldsValidation"
import { SupplierTypes_ENUM } from "../../../../utils/EnumsAndConsts"
import NotificationModal from "../../../Modals/NotificationModal"
import YesNoModal from "../../../Modals/YesNoModal"
import {
  cleanCouponForm,
  editSupplierForm,
  updateSupplier,
  removeSupplier,
  cleanSupplierUpdate,
  cleanSupplierRemove,
} from "../../../../actions"
import { suppliersColors } from "../../../../utils/ColorLists.ts"
import { colourStyles } from "../../../../utils/colorStyles.tsx"
import "./style.css"

const EditSupplier_TitleText = "עדכון ספק"
const EditSupplier_IsDelete = "ספק הוסר"
const EditSupplier_SaveButtonText = "עדכן ספק"
const EditSupplier_RemoveButtonText = "הסר ספק"
const EditSupplier_UpdateSuccessed_Text = "ספק עודכן בהצלחה"
const EditSupplier_RemoveTitleText = "הסרת ספק"
const EditSupplier_RemoveSuccessed_Text = "ספק הוסר בהצלחה"

const EditSupplier_Name_InputLabelText = "שם ספק"
const EditSupplier_Type_TitleText = "סוג ספק"
const EditSupplier_Phone_TitleText = "טלפון"
const EditSupplier_Email_TitleText = "אימייל"
const EditSupplier_City_TitleText = "עיר"
const EditSupplier_ContactPersonName_TitleText = "שם איש קשר"
const EditSupplier_CreatedByName_TitleText = "שם יוצר"

class EditSupplier extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      name: "",
      type: "",
      phone: "",
      email: "",
      city: "",
      contactPersonName: "",
      createdByName: "",
      createdDate: "",
      validField: {
        name: {
          type: "text",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        type: {
          type: "requiredSelect",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        phone: {
          type: "mobile",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        email: {
          type: "email",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        city: {
          type: "text",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        contactPersonName: {
          type: "text",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        color: {
          type: "text",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
      },
    }

    this.state = this.INIT_STATE
    this.onRemoveButtonClick = this.onRemoveButtonClick.bind(this)
    this.onRemoveModalClick = this.onRemoveModalClick.bind(this)
    this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this)
  }

  componentWillMount() {
    this.state = this.INIT_STATE
    this.state = {
      ...this.state,
      createdDate: getShortDate(this.props.supplier.createdDate),
    }
  }
  componentWillUnmount() {
    // this.props.cleanCouponForm();
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }

    const { supplier, isRemoved, isUpdated } = newProps

    this.state = {
      ...this.state,
    }

    if (isRemoved || isUpdated) {
      this.state = {
        ...this.state,
        isLoading: false,
      }
    }
  }

  handleFieldChange(inputName, inputValue) {
    const { supplier } = this.props
    let _supplier = {
      ...supplier,
    }

    _supplier = {
      ..._supplier,
      [inputName]: inputValue,
    }
    this.props.editSupplierForm(_supplier)
    this.runValidation(inputName, inputValue)
  }

  isValidSupplier(newSupplier) {
    const fieldsList = [
      "name",
      "type",
      "phone",
      "email",
      "city",
      "contactPersonName",
      "color",
    ]
    this.runValidationList(fieldsList, newSupplier)

    const { validField } = this.state
    let res = fieldsList.map((fieldName, i) => {
      if (!validField[fieldName].isValid) {
        console.log(fieldName + " is not valid")
        return false
      }
      return true
    })

    return res.filter((i) => i === true).length === fieldsList.length
  }

  runValidationList(fieldsList, supplier) {
    fieldsList.map((fieldName, i) =>
      this.runValidation(fieldName, supplier[fieldName])
    )
  }

  runValidation(inputName, inputValue) {
    let { validField } = this.state
    let field = validField[inputName]

    if (!field) return
    field.isChecked = true

    field.isValid = isValidField(
      inputValue,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  onFieldFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return
    field.isChecked = false
    validField[inputName] = field
    this.setState({
      ...this.state,
      validField,
    })
  }

  onRemoveButtonClick() {
    this.setState({
      ...this.state,
      showAreYouSureModal: true,
    })
  }
  onRemoveModalClick() {
    const { supplier, removeSupplier } = this.props
    this.state = {
      ...this.state,
      showAreYouSureModal: false,
    }

    removeSupplier(supplier.id)
  }

  onSubmitButtonClick() {
    const { supplier } = this.props
    if (!this.isValidSupplier(supplier)) {
      return
    }
    this.props.updateSupplier(supplier)
  }

  closeForm() {
    this.state = this.INIT_STATE
    this.props.cleanSupplierRemove()
    this.props.history.push("/ServiceSuppliersScreen")
  }

  renderIsDeleted(isDelete) {
    if (isDelete) {
      return (
        <div className="section-info-row is-deleted" disabled={!isDelete}>
          {EditSupplier_IsDelete}
        </div>
      )
    } else {
      return
    }
  }

  render() {
    const { supplier, cleanSupplierUpdate, isLoading, isUpdated, isRemoved } =
      this.props
    const fieldTypeList = FIELD_TYPE_ENUM
    const {
      name,
      type,
      phone,
      email,
      city,
      contactPersonName,
      color,
      createdByName,
      isActive,
      isDelete,
    } = supplier

    const { showAreYouSureModal, validField, createdDate } = this.state

    if (isLoading) {
      return (
        <div className="edit-supplier spinner-wrapper">
          <Spinner zoom={2} />
        </div>
      )
    }

    const supplierTypesList = SupplierTypes_ENUM

    let selectedColor = {
      label: " לא נבחר הצבע",
      value: "",
      color: "#fff",
    }

    if (color) {
      selectedColor = suppliersColors.find((e) => e.color === color)
    }

    return (
      <>
        <div className="ss-container edit-supplier">
          <h1>{EditSupplier_TitleText}</h1>
          {this.renderIsDeleted(isDelete)}
          <div className="section-info-row">
            <LabeledTextInput
              labelText={EditSupplier_Name_InputLabelText}
              value={name}
              inputSize={40}
              onChange={(e) => {
                this.handleFieldChange("name", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("name")
              }}
              onBlur={(e) => {
                this.runValidation("name", name)
              }}
            />
            <ValidationMessage
              isShow={validField.name.isChecked}
              isShowOk={false}
              isShowError={!validField.name.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.name.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>
          <div className="section-info-row">
            <LabeledSelect
              disabled={isDelete}
              labelText={EditSupplier_Type_TitleText}
              options={supplierTypesList}
              value={type}
              emptyOption={false}
              onChange={(e) => {
                this.handleFieldChange("type", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("type")
              }}
            />
            <ValidationMessage
              isShow={validField.type.isChecked}
              isShowOk={false}
              isShowError={!validField.type.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.type.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>
          <div className="section-info-row">
            <LabeledTextInput
              labelText={EditSupplier_Phone_TitleText}
              value={phone}
              type="number"
              onChange={(e) => {
                this.handleFieldChange("phone", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("phone")
              }}
              onBlur={(e) => {
                this.runValidation("phone", phone)
              }}
            />
            <ValidationMessage
              isShow={validField.phone.isChecked}
              isShowOk={false}
              isShowError={!validField.phone.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.phone.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>
          <div className="section-info-row">
            <LabeledTextInput
              labelText={EditSupplier_Email_TitleText}
              value={email}
              inputSize={40}
              onChange={(e) => {
                this.handleFieldChange("email", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("email")
              }}
              onBlur={(e) => {
                this.runValidation("email", email)
              }}
            />
            <ValidationMessage
              isShow={validField.email.isChecked}
              isShowOk={false}
              isShowError={!validField.email.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.email.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>
          <div className="section-info-row">
            <LabeledTextInput
              labelText={EditSupplier_City_TitleText}
              value={city}
              type="text"
              onChange={(e) => {
                this.handleFieldChange("city", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("city")
              }}
              onBlur={(e) => {
                this.runValidation("city", city)
              }}
            />
            <ValidationMessage
              isShow={validField.city.isChecked}
              isShowOk={false}
              isShowError={!validField.city.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.city.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>
          <div className="section-info-row">
            <LabeledTextInput
              labelText={EditSupplier_ContactPersonName_TitleText}
              value={contactPersonName}
              type="contactPersonName"
              onChange={(e) => {
                this.handleFieldChange("contactPersonName", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("contactPersonName")
              }}
              onBlur={(e) => {
                this.runValidation("contactPersonName", contactPersonName)
              }}
            />
          </div>
          <div className="section-info-row">
            <div className="ss-group">
              <Select
                options={suppliersColors}
                onChange={(e) => {
                  this.handleFieldChange("color", e.value)
                }}
                value={selectedColor}
                placeholder="בחר צבע"
                classNamePrefix="base-select"
                styles={colourStyles}
              />
            </div>
          </div>
          <div className="section-info-row">
            <LabeledTextInput
              disabled
              labelText={EditSupplier_CreatedByName_TitleText}
              value={createdByName}
              type="text"
            />
            <LabeledTextInput
              disabled
              labelText={"תאריך יצירה"}
              value={this.state.createdDate}
              type="text"
            />
          </div>
          <div className="section-info-row">
            <button
              type="submit"
              disabled={isDelete}
              className="ss-button"
              onClick={(e) => {
                this.onSubmitButtonClick(e)
              }}
            >
              {EditSupplier_SaveButtonText}
            </button>
            <button
              type="submit"
              disabled={isDelete}
              className="ss-button remove-button"
              onClick={() => this.onRemoveButtonClick()}
            >
              {EditSupplier_RemoveButtonText}
            </button>
          </div>
        </div>

        <NotificationModal
          show={isUpdated}
          title={EditSupplier_TitleText}
          text={EditSupplier_UpdateSuccessed_Text}
          onOkClick={() => cleanSupplierUpdate()}
        />

        <NotificationModal
          show={isRemoved}
          title={EditSupplier_RemoveTitleText}
          text={EditSupplier_RemoveSuccessed_Text}
          onOkClick={() => this.closeForm()}
        />
        <YesNoModal
          show={showAreYouSureModal}
          title={EditSupplier_RemoveTitleText}
          text={` הספק ${name} יוסר ,האם אתה בטוח?`}
          onYesClick={(e) => this.onRemoveModalClick()}
          onNoClick={() => this.setState({ showAreYouSureModal: false })}
        />
      </>
    )
  }
}

const mapStateToProp = ({ suppliers, errors }) => {
  const { supplier, isLoading, isUpdated, isRemoved } = suppliers
  const { unauthorized } = errors
  return {
    supplier,
    isLoading,
    isUpdated,
    isRemoved,
    unauthorized,
  }
}

export default connect(mapStateToProp, {
  cleanCouponForm,
  editSupplierForm,
  updateSupplier,
  removeSupplier,
  cleanSupplierUpdate,
  cleanSupplierRemove,
})(EditSupplier)
