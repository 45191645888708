import React, { Component } from "react"
import { connect } from "react-redux"

import {
  fetchAllStationData,
  fetchUserRoles,
  fetchUserSources,
  setUsersSearch,
  clearUsersSearch,
} from "../../../actions"

import { fetchIfEmpty } from "../../../utils"

import {
  InputFiled,
  InputDatePicker,
  SelectMultiAutocomplete,
  BlueButton,
} from "../../common"

import "./style.css"

const searchFromDateText = "הרשמה מתאריך"

class UsersSearchFilterer extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      defaultUserRoles: [],
      defaultStations: [],
      defaultUserSources: [],
      usersSearch: {
        searchText: "",
        stations: [],
        userRoles: [],
        userSources: [],
        fromDate: "",
        pageStep: 50,
        page: 1,
      },
    }

    this.state = this.INIT_STATE
    this.handleDateChange = this.handleDateChange.bind(this)
    this.setDefaultsAndSearch = this.setDefaultsAndSearch.bind(this)
    this.cleanSearch = this.cleanSearch.bind(this)
  }

  componentWillMount() {
    let { usersSearch } = this.props

    const isEmptySearch =
      JSON.stringify(usersSearch) ===
      JSON.stringify(this.INIT_STATE.usersSearch)

    if (isEmptySearch) {
      usersSearch["fromDate"] = this.getDefaultFromDate()
    }
    this.setDefaultsAndSearch(usersSearch)

    if (isEmptySearch) {
      this.props.setUsersSearch(usersSearch)
    }
  }
  componentDidMount() {
    fetchIfEmpty(this.props.allStationsArr, this.props.fetchAllStationData)
    fetchIfEmpty(this.props.userRoles, this.props.fetchUserRoles)
    fetchIfEmpty(this.props.userSources, this.props.fetchUserSources)
  }

  componentWillUnmount() {
    this.cleanSearch()
    this.props.clearUsersSearch()
  }

  componentWillReceiveProps(newProps) {
    let { usersSearch } = newProps
    const isEmptySearch =
      JSON.stringify(usersSearch) ===
      JSON.stringify(this.INIT_STATE.usersSearch)
    const isDataChanged =
      JSON.stringify(newProps.usersSearch) !==
      JSON.stringify(this.state.usersSearch)

    if (isEmptySearch) {
      usersSearch["fromDate"] = this.getDefaultFromDate()
    }

    if (isDataChanged) {
      this.setDefaultsAndSearch(usersSearch)
    }

    if (isEmptySearch) {
      this.props.setUsersSearch(usersSearch)
    }
  }

  setDefaultsAndSearch(usersSearch) {
    const {
      stationSelectList,
      userRoles,
      userSources,
      formattedStationsNames,
    } = this.props
    if (!usersSearch) {
      return
    }

    const defaultUserRoles = usersSearch.userRoles.map((element) => {
      //return { label:userRoles[element] , value: element }
      return { label: element, value: userRoles.indexOf(element) }
    })
    const defaultStations = usersSearch.stations.map((element) => {
      const station = stationSelectList.filter((s) => s.value == element)[0]
      const index = formattedStationsNames.indexOf(station.label)
      return { label: station.label, value: index }
    })

    const defaultUserSources = usersSearch.userSources.map((element) => {
      const sourceItems = userSources.filter((s) => s.value == element)
      const sourceItem = sourceItems.length > 0 ? sourceItems[0] : null
      return { label: sourceItem ? sourceItem.label : "", value: element }
    })

    this.setState({
      usersSearch,
      defaultUserRoles,
      defaultStations,
      defaultUserSources,
    })
  }

  getDefaultFromDate() {
    let defaultDate = new Date()
    let newMonth = defaultDate.getMonth() - 1
    if (newMonth <= 0) {
      newMonth = newMonth <= 0 ? newMonth + 12 : newMonth
      defaultDate.setMonth(newMonth)
      defaultDate.setFullYear(defaultDate.getFullYear() - 1)
    }

    return this.formatDateForPicker(defaultDate)
  }

  formatDateForPicker(date) {
    let day = date.getDate()
    if (day < 10) {
      day = "0" + day
    }
    let month = date.getMonth() + 1
    if (month < 10) {
      month = "0" + month
    }
    return date.getFullYear() + "-" + month + "-" + day
  }

  updateUserRoles(item) {
    let { usersSearch, defaultUserRoles } = this.state
    defaultUserRoles = item
    usersSearch.userRoles = item.map((itm) => {
      return itm.label
    })
    usersSearch.page = 1
    this.setState({ usersSearch, defaultUserRoles })
    this.props.onSearchFilterChanged(usersSearch)
  }

  updateUserSources(item) {
    let { usersSearch, defaultUserSources } = this.state
    defaultUserSources = item
    usersSearch.userSources = item.map((itm) => {
      return itm.value
    })
    usersSearch.page = 1
    this.setState({ usersSearch, defaultUserSources })
    this.props.onSearchFilterChanged(usersSearch)
  }

  updateActiveStations(item) {
    let { usersSearch, defaultStations } = this.state
    defaultStations = item
    const { allStationsArr } = this.props
    usersSearch.stations = item.map((i) => allStationsArr[i.value].stationId)
    usersSearch.page = 1
    this.setState({ usersSearch, defaultStations })
    this.props.onSearchFilterChanged(usersSearch)
  }

  updateActiveField(fieldName, value) {
    //TODO VALIDATION
    let { usersSearch } = this.state
    usersSearch[fieldName] = value
    usersSearch.page = 1
    this.setState({ usersSearch })
    this.props.onSearchFilterChanged(usersSearch)
  }

  handleDateChange(inputName, event) {
    let { usersSearch } = this.state
    usersSearch[inputName] = event.target.value
    usersSearch.page = 1
    this.setState({ usersSearch })
    this.props.onSearchFilterChanged(usersSearch)
  }

  cleanSearch() {
    this.props.setUsersSearch({
      searchText: "",
      stations: [],
      userRoles: [],
      fromDate: "",
      pageStep: 50,
      page: 1,
    })

    this.setState({
      defaultUserRoles: [],
      defaultStations: [],

      usersSearch: {
        searchText: "",
        stations: [],
        userRoles: [],
        fromDate: "",
        pageStep: 50,
        page: 1,
      },
    })
  }

  render() {
    const { formattedStationsNames, userRoles, userSources } = this.props

    const { usersSearch } = this.state
    const { searchText, fromDate } = usersSearch

    return (
      <div className="search-user-filterer ">
        <div className="line-wrapper">
          <InputFiled
            onChange={(e) =>
              this.updateActiveField("searchText", e.target.value)
            }
            onKeyPress={(e) => this.props.filterEnterAndSubmit(e)}
            extraClass="search-input"
            placeholder="חיפוש לפי שם/נייד/אמייל/חברה"
            value={searchText}
            alt="ניתן לחפש לקוח לפי שם או מספר טלפון או אמייל."
            icon="magnifying_glass"
          />

          <InputDatePicker
            name="fromDate"
            date={fromDate}
            onChange={this.handleDateChange}
            placeholder={searchFromDateText}
          />

          <SelectMultiAutocomplete
            placeholder="סוג משתמש"
            items={userRoles}
            value={this.state.defaultUserRoles}
            onChangeHandler={(itm) => this.updateUserRoles(itm)}
            extraClassName="user-roles-list"
          />

          <SelectMultiAutocomplete
            placeholder="עמדה"
            items={formattedStationsNames}
            value={this.state.defaultStations}
            onChangeHandler={(itm) => this.updateActiveStations(itm)}
            extraClassName="sations-list"
          />
          <SelectMultiAutocomplete
            placeholder="מקור"
            items={userSources}
            value={this.state.defaultUserSources}
            onChangeHandler={(itm) => this.updateUserSources(itm)}
            extraClassName="sources-list"
          />
          <div className="search-btn-wrapper">
            <BlueButton
              extraClass="search-btn"
              onClick={(e) => this.props.searchUsersClick()}
              tabIndex="0"
            >
              חפש
            </BlueButton>
          </div>

          {/*
                        <div className='label'>
                            <div 
                                className='clear-btn' 
                                tabIndex="0" 
                                onClick={ e => this.cleanSearch()}
                                > נקה 
                            </div>
                        
                        </div>
                    */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ stationsList, selectOptions, ordersSearch }) => {
  const { usersSearch } = ordersSearch

  const { allStationsArr, stationSelectList, formattedStationsNames } =
    stationsList
  const { userRoles, userSources } = selectOptions

  return {
    allStationsArr,
    stationSelectList,
    formattedStationsNames,
    userRoles,
    userSources,
    usersSearch,
  }
}

export default connect(mapStateToProps, {
  fetchAllStationData,
  fetchUserRoles,
  fetchUserSources,
  setUsersSearch,
  clearUsersSearch,
})(UsersSearchFilterer)
