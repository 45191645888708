import axios from "axios"
import api from "../api/AzureApi"
import {
  ON_GET_CITIES_SUCCESS,
  ON_GET_CITIES_FAIL,
  ON_CITIES_LOADING,
  SET_CURRENT_CITY,
  CLOSE_CITY_NOTIFICATION,
  ON_ADD_CITY_SUCCESS,
  ON_ADD_CITY_FAIL,
  ON_CITIES_FILTER_BY_TEXT,
  ON_CITIES_SET_PAGE,
  CLEAR_LOCAL_CITIES_FILTER_SEARCH,
  ON_GET_ALL_CITIES_SUCCESS,
  EDIT_CITY_FORM,
  ON_CREATE_CITY_SUCCESS,
  ON_CREATE_CITY_FAIL,
  ON_EXTERNAL_CITIES_SUCCESS,
  CLEAN_CITY_FORM,
  ON_GET_DISTRICTS_SUCCESS,
  ON_SELECT_CITY,
  ON_UPDATE_CITY,
  CLEAR_CITIES_LIST,
  ON_UPDATE_CITY_FAILED,
} from "./types"
import errorManager from "../utils/ErrorManager"

export const onLoadingCities = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CITIES_LOADING,
    })
  }
}

export const fetchCities = () => {
  console.log("fetchCities.")
  return (dispatch) => {
    axios
      .get(api.fetchCities("he"))
      .then((res) => {
        console.log(res.data)

        let citiesWithEmpty = [{ value: "", label: "---" }, ...res.data]

        dispatch({
          type: ON_GET_CITIES_SUCCESS,
          payload: citiesWithEmpty,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_CITIES_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
    console.log("fetchCities>exit dispatch")
  }
}

export const setCurrentCity = (city) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_CITY,
      payload: city,
    })
  }
}

export const closeCityNotification = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_CITY_NOTIFICATION,
    })
  }
}

export const addCity = (city) => {
  console.log("addCity.")
  return (dispatch) => {
    axios
      .post(api.fetchCities())
      .then((res) => {
        console.log(res.data)

        dispatch({
          type: ON_ADD_CITY_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_ADD_CITY_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const localSearchCitiesByText = (searchText) => {
  return {
    type: ON_CITIES_FILTER_BY_TEXT,
    payload: searchText ? searchText : searchText,
  }
}
export const localSearchCitiesSetPage = (page) => {
  return {
    type: ON_CITIES_SET_PAGE,
    payload: page,
  }
}

export const clearLocalSearchCities = () => {
  return (dispach) => {
    dispach({
      type: CLEAR_LOCAL_CITIES_FILTER_SEARCH,
    })
  }
}

export const getAllCities = () => {
  return (dispatch) => {
    axios
      .get(api.getAllCities)
      .then((res) => {
        dispatch({
          type: ON_GET_ALL_CITIES_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_CITIES_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const editCityForm = (city) => {
  return {
    type: EDIT_CITY_FORM,
    payload: city,
  }
}

export const createCity = (formData) => {
  return (dispatch) => {
    axios
      .post(api.createCity, formData, {
        headers: {
          Authorization: axios.defaults.headers.common["Authorization"],
        },
      })
      .then((res) => {
        dispatch({
          type: ON_CREATE_CITY_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_CREATE_CITY_FAIL,
          payload: err.response.status,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getExternalCitiesList = () => {
  return (dispatch) => {
    axios
      .get(api.getExternalCitiesList)
      .then((res) => {
        dispatch({
          type: ON_EXTERNAL_CITIES_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_CITIES_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const cleanCityForm = () => {
  return {
    type: CLEAN_CITY_FORM,
  }
}

export const getDistricts = () => {
  return (dispatch) => {
    axios
      .get(api.getDistricts)
      .then((res) => {
        dispatch({
          type: ON_GET_DISTRICTS_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const selectCity = (city) => {
  return {
    type: ON_SELECT_CITY,
    payload: city,
  }
}

export const updateCity = (city) => {
  const cityUpdate = {
    cityId: city.cityId,
    cityName_ru: city.cityName_ru,
    district: { districtId: city.districtId },
  }
  return (dispatch) => {
    axios
      .put(api.updateCity(city.cityId), cityUpdate, {
        headers: {
          Authorization: axios.defaults.headers.common["Authorization"],
        },
      })
      .then((res) => {
        dispatch({
          type: ON_UPDATE_CITY,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_CITY_FAILED,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearCitiesList = () => {
  return {
    type: CLEAR_CITIES_LIST,
  }
}
