import axios from "axios"
import api from "../api"

import {
  ON_GET_DELIVERY_ISRAEL_POST_SUCCESS,
  ON_GET_DELIVERY_ISRAEL_POST_FAIL,
  ON_CLEAR_DELIVERY_ISRAEL_POST,
} from "./types"

import errorManager from "../utils/ErrorManager"

export const getDeliveryDetailsFromIsraelPost = (packageNumber) => {
  return (dispatch) => {
    dispatch({
      type: ON_CLEAR_DELIVERY_ISRAEL_POST,
    })

    axios
      .get(api.getDeliveryDetailsFromIsraelPost(packageNumber))
      .then((res) => {
        dispatch({
          type: ON_GET_DELIVERY_ISRAEL_POST_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_DELIVERY_ISRAEL_POST_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearDeliveryDetails = () => {
  return {
    type: ON_CLEAR_DELIVERY_ISRAEL_POST,
  }
}
