import React from "react"
import OrderList from "../../../OrdersListScreen/OrderList"
import { PaginationNext } from "../../../../common"

import "./style.css"

const orderListHeaders = [
  "מזהה",
  "מס חבילה",
  "תאריך",
  "סטטוס",
  "סוג",
  "קופון",
  "מס עמדה",
  "שם סניף",

  "מיקום ההזמנה",
  "שם לקוח",
  "נייד",
  "סכום (₪)",
  "שולם",
  "",
]
const orderListItemMap = [
  "orderNumber",
  "packageNumber",
  "orderFormatDate",
  "orderFormatStatus",
  "orderFromatType",
  "couponId",
  "stationNumber",
  "branchName",

  "currentLocation",
  "userFormatFullName",
  "mobilePhone",
  "orderTotalPrice",
  "orderFormatIspayed",
]
const orderListGravity = { 2: 2, 9: 2, 10: 2 }

const UserOrdersTab = ({
  userOrdersData,
  onPageClick,
  onOrderItemEditBtnClick,
}) => {
  const {
    items,
    isLoading,
    isError,
    currentSearchedPageStep,
    currentSearchedPage,
  } = userOrdersData

  return (
    <div className="">
      <div className="adt-inner-wrapper">
        <div className="ucs-pdt-flex1">
          <div className="ucs-title"></div>
          <OrderList
            extraClass="ols-wrapper-list"
            headers={orderListHeaders}
            itemMap={orderListItemMap}
            gravity={orderListGravity}
            items={items}
            onItemEditBtnClick={(itm) => onOrderItemEditBtnClick(itm)}
            isError={isError}
            isLoading={isLoading}
          />

          <PaginationNext
            pageStep={currentSearchedPageStep}
            page={currentSearchedPage}
            onClick={(page) => onPageClick(page)}
            currentResponseCount={items.length}
          />
        </div>
      </div>
    </div>
  )
}

export default UserOrdersTab
