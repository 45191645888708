import {
  ON_GET_RECEPTION_ORDERS_SUCCESS,
  ON_GET_RECEPTION_ORDERS_FAIL,
  ON_RECEPTION_ORDER_PROCESS_SUCCESS,
  ON_RECEPTION_ORDER_PROCESS_FAIL,
  ON_RECEPTION_ORDERS_LOADING,
  ON_RECEPTION_ORDER_NOT_ALLOWED,
  CLEAR_RECEPTION_ORDER_NOT_ALLOWED,
  ON_PROCESS_ORDER_LOADING,
  CLEAR_RECEPTION_ORDERS,
  ON_SHIPPING_CERTIFICATE_LOADING,
  ON_GET_SHIPPING_CERTIFICATE_SUCCESS,
  ON_GET_SHIPPING_CERTIFICATE_FAIL,
  ON_SUPPLIERS_SHIPPING_CERTIFICATE_LOADING,
  ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_SUCCESS,
  ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_FAIL,
  ON_ROUTE_SHIPPING_CERTIFICATE_LOADING,
  ON_GET_ROUTE_SHIPPING_CERTIFICATE_SUCCESS,
  ON_GET_ROUTE_SHIPPING_CERTIFICATE_FAIL,
  ON_END_OF_DAY_LOADING,
  ON_END_OF_DAY_SUCCESS,
  ON_END_OF_DAY_FAIL,
  CLEAR_END_OF_DAY,
  CLEAR_CERTIFICATES,
} from "../actions/types"

import { getReceptionOrdersResponse } from "../utils"

const INIT_STATE = {
  receptionOrdersList: null,
  currentSearchedPage: 1,
  currentSearchedPageStep: 50,
  receptionOrderProcessSuccess: false,
  receptionOrderProcessFail: false,
  shippingCertificate: null,
  shippingCertificateSuccess: false,
  shippingCertificateFail: false,
  suppliersShippingOrders: null,
  routeShippingOrders: null,
  endOfDayReportSuccess: false,
  endOfDayReportFail: false,
  isNotAllowedToProcess: false,
}

const ReceptionOrdersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_RECEPTION_ORDERS_LOADING:
      return {
        ...state,
        receptionOrdersList: null,
        isLoading: true,
      }
    case CLEAR_RECEPTION_ORDERS:
      return {
        ...state,
        receptionOrdersList: null,
        receptionOrderProcessSuccess: false,
        receptionOrderProcessFail: false,
      }
    case ON_GET_RECEPTION_ORDERS_SUCCESS:
      return {
        ...state,
        receptionOrdersList: getReceptionOrdersResponse(action.payload.orders),
        currentSearchedPage: action.payload.page,
        currentSearchedPageStep: action.payload.pageStep,
        isLoading: false,
      }
    case ON_GET_RECEPTION_ORDERS_FAIL:
      return {
        ...state,
        receptionOrdersList: null,
        isLoading: false,
      }
    case ON_PROCESS_ORDER_LOADING:
      return {
        ...state,
        updatedReceptionOrder: null,
        receptionOrderProcessSuccess: false,
        receptionOrderProcessFail: false,
        isNotAllowedToProcess: false,
        isLoading: true,
      }
    case ON_RECEPTION_ORDER_PROCESS_SUCCESS:
      return {
        ...state,
        updatedReceptionOrder: action.payload,
        receptionOrderProcessSuccess: true,
        receptionOrderProcessFail: false,
        isLoading: false,
      }
    case ON_RECEPTION_ORDER_PROCESS_FAIL:
      return {
        ...state,
        updatedReceptionOrder: action.payload,
        receptionOrderProcessSuccess: false,
        receptionOrderProcessFail: true,
        isNotAllowedToProcess: false,
        isLoading: false,
      }
    case ON_RECEPTION_ORDER_NOT_ALLOWED:
      return {
        ...state,
        isNotAllowedToProcess: true,
        receptionOrderProcessSuccess: false,
        receptionOrderProcessFail: false,
        isLoading: false,
      }
    case CLEAR_RECEPTION_ORDER_NOT_ALLOWED:
      return {
        ...state,
        isNotAllowedToProcess: false,
        receptionOrderProcessSuccess: false,
        receptionOrderProcessFail: false,
        isLoading: false,
      }
    case ON_SHIPPING_CERTIFICATE_LOADING:
      return {
        ...state,
        isLoading: true,
        shippingCertificateSuccess: false,
        shippingCertificateFail: false,
      }
    case ON_GET_SHIPPING_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shippingCertificate: action.payload,
        shippingCertificateSuccess: true,
        shippingCertificateFail: false,
      }
    case ON_GET_SHIPPING_CERTIFICATE_FAIL:
      return {
        ...state,
        isLoading: false,
        shippingCertificateSuccess: false,
        shippingCertificateFail: true,
      }
    case ON_SUPPLIERS_SHIPPING_CERTIFICATE_LOADING:
      return {
        ...state,
        isLoading: true,
        shippingCertificateSuccess: false,
        shippingCertificateFail: false,
      }
    case ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        suppliersShippingOrders: action.payload,
        shippingCertificateSuccess: true,
        shippingCertificateFail: false,
      }
    case ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_FAIL:
      return {
        ...state,
        isLoading: false,
        shippingCertificateSuccess: false,
        shippingCertificateFail: true,
      }

    case ON_ROUTE_SHIPPING_CERTIFICATE_LOADING:
      return {
        ...state,
        isLoading: true,
        shippingCertificateSuccess: false,
        shippingCertificateFail: false,
      }
    case ON_GET_ROUTE_SHIPPING_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        routeShippingOrders: action.payload,
        shippingCertificateSuccess: true,
        shippingCertificateFail: false,
      }
    case ON_GET_ROUTE_SHIPPING_CERTIFICATE_FAIL:
      return {
        ...state,
        isLoading: false,
        shippingCertificateSuccess: false,
        shippingCertificateFail: true,
      }
    case ON_END_OF_DAY_LOADING:
      return {
        ...state,
        isLoading: true,
        endOfDayReportSuccess: false,
        endOfDayReportFail: false,
      }
    case ON_END_OF_DAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        endOfDayReportSuccess: true,
        endOfDayReportFail: false,
      }
    case ON_END_OF_DAY_FAIL:
      return {
        ...state,
        isLoading: false,
        endOfDayReportSuccess: false,
        endOfDayReportFail: true,
      }
    case CLEAR_END_OF_DAY:
      return {
        ...state,
        endOfDayReportSuccess: false,
        endOfDayReportFail: false,
      }
    case CLEAR_CERTIFICATES:
      return {
        ...state,
        shippingCertificate: null,
        suppliersShippingOrders: null,
        routeShippingOrders: null,
      }
    default:
      return state
  }
}

export default ReceptionOrdersReducer
