import React from 'react';
import { Title } from '../../../../../common';

import { clif } from '../../../../../../utils';
import './style.css';

const DesignedListItem = ({ item, onItemClick, isSelected }) => {
    const arrowSrc = isSelected ?
        require('../../../../../../assets/images/blue-left-arrow.png') :
        require('../../../../../../assets/images/grey-left-arrow.png');

    const { firstName, lastName, orderNumber, orderFromatType, branchName, stationNumber } = item;

    const wrapperClassName = `designed-item-wrapper ${clif(isSelected, 'designed-item-selected-wrapper')}`;

    return (
        <div className={wrapperClassName} onClick={e => onItemClick(item)}>
            <div className='designed-item-containter'>
                <Title
                    activable
                    bold
                    extraClass={clif(isSelected, 'designed-item-selected-title')}
                >
                    {`${firstName} ${lastName} - ${orderNumber}`}
                </Title>
                <Title
                    activable
                >
                    {`${stationNumber} ${ifDef(branchName)} ${ifDef(orderFromatType)} `}
                </Title>
            </div>
            <img src={arrowSrc} alt='' className='designed-item-img' />
        </div>

    );
};

const ifDef = str => {
    if (!str) {
        return '';
    }

    return str;
};

export default DesignedListItem;
