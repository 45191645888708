import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "./style.scss"
import SackScanner from "../SackScanner/sackScanner"
import { BlueButton } from "../../../../common"
import { getFormatedDate } from "../../../../../utils/FormatedDate"
import NotificationModal from "../../../../Modals/NotificationModal"

const ScanSackMobilePage = (props) => {
  return (
    <section className="scanSackMobilePage">
      <SackScanner history={props.history} />
    </section>
  )
}

const mapStateToProps = ({ ordersList }) => {
  const {} = ordersList
  return {}
}

export default connect(mapStateToProps, {})(ScanSackMobilePage)
