import React, { Component } from "react"
import { connect } from "react-redux"
import {
  onUpdateMessage,
  onGetStations,
  closeMessage,
  clearLockersStatuses,
} from "../../../../actions"
import LockerCells from "../../../common/LockerCells"
import { BlueButton } from "../../../common/BlueButton"
import "./style.scss"

class StationStatusScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentWillUnmount() {
    this.props.clearLockersStatuses()
  }

  componentDidMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }
  }

  render() {
    let {} = this.state
    const { station } = this.props

    return (
      <div className="station-status-wrapper">
        <div className="btn-back-wrapper">
          <BlueButton
            extraClass="btn-back"
            onClick={() => this.props.history.goBack()}
          >
            חזור
          </BlueButton>
        </div>

        <LockerCells stationId={station?.stationId} />
      </div>
    )
  }
}

const mapStateToProps = ({ errors, stationsSection }) => {
  const { unauthorized } = errors
  const { station } = stationsSection

  return {
    unauthorized,
    station,
  }
}

export default connect(mapStateToProps, {
  onUpdateMessage,
  onGetStations,
  closeMessage,
  clearLockersStatuses,
})(StationStatusScreen)
