import React from 'react';

import './style.css';

const LabeledSelect = ({ labelText, emptyOption = true, defaultOptionKey = undefined, options, value, onChange, id }) => {
    return (
        <div id={id} className="ss-group">
            <label className="ss-label">{labelText}</label>
            <select  className="ss-input" value={value} onChange={onChange}>
                {renderEmptyOption(emptyOption)}
                {renderOptions(options, emptyOption)}
            </select>
        </div>
    );
}


const renderEmptyOption = emptyOption => {
    if (!emptyOption) {
        return;
    }
    return (
        <option key="" value="">not set</option>
    );
}

const renderOptions = (options, defaultOptionKey) => {
    if (!options) {
        return null;
    }

    
    return Object.keys(options).map(key =>
        <option key={key} value={key} name={key} defaultValue={setDefaultText(key,defaultOptionKey)}>{options[key]}</option>
    );
}


const setDefaultText = (key, defaultOptionKey)=>{
    
    if(key === defaultOptionKey) {
       return true
    }
    return false;
}
    
export { LabeledSelect };