import React from 'react';

import './style.css';

const WidthAdjuster = ({ isOpened, onChangeToggeled }) => {

    const arrowSrc = isOpened ?
        require('../../../../../assets/images/right-arrow.png') :
        require('../../../../../assets/images/left-arrow.png');

    return (
        <div className='wdtadj-wrapper' onClick={e => onChangeToggeled()}>
            <img src={arrowSrc} alt='' className='wdtadj-img' />
        </div>
    );
}

export default WidthAdjuster;
