import React from 'react';

import './style.css';

const Editable = ({ extraClass, ...props }) => {
    
    return (
        <input
            className={`edtble-class ${extraClass ? extraClass : ''}`}
            {...props}
        />
    );
};

export { Editable };