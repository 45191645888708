import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"
import {
  ON_BRANCH_CARD_LOADING,
  ON_GET_BRANCH_CARD_SUCCESS,
  ON_GET_GET_BRANCH_CARD_FAIL,
  CLEAR_UPDATE_BRANCH_CARD,
  ON_BRANCH_CARD_UPDATE_SUCCESS,
  ON_BRANCH_CARD_UPDATE_FAIL,
  CLEAR_GET_BRANCH_CARD,
} from "./types"

export const getBranchCardByBranchId = (branchId) => {
  return (dispatch) => {
    dispatch({ type: ON_BRANCH_CARD_LOADING })
    axios
      .get(api.getBranchCardByBranchId(branchId))
      .then((res) => {
        dispatch({
          type: ON_GET_BRANCH_CARD_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_GET_BRANCH_CARD_FAIL,
          payload: err,
        })
        errorManager.dispatchError(err, dispatch)
      })
  }
}

export const clearUpdateBranchCard = () => (dispatch) => {
  dispatch({
    type: CLEAR_UPDATE_BRANCH_CARD,
  })
}

export const editBranchCard = (branchCard) => {
  return (dispatch) => {
    dispatch({ type: ON_BRANCH_CARD_LOADING })

    axios
      .post(api.editBranchCard, branchCard)
      .then((res) => {
        dispatch({
          type: ON_BRANCH_CARD_UPDATE_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_BRANCH_CARD_UPDATE_FAIL,
          payload: err,
        })
        errorManager.dispatchError(err, dispatch)
      })
  }
}

export const clearGetBranchCard = () => (dispatch) => {
  dispatch({
    type: CLEAR_GET_BRANCH_CARD,
  })
}
