import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Pagination } from '../../../common';
import ItemsList from '../ItemsList';

import {
    fetchCoupons, setCouponToEdit,
} from '../../../../actions';

import './style.css';

class CouponsList extends Component {

    constructor(props) {
        super(props);

        this.INIT_STATE = {
            couponsListArr: [],
            ordersStatusesArr: [],
            pagedList: [],
            pageNum: 1,
            showOrderEditModal: false,
            orderToEdit: null
        };

        this.state = this.INIT_STATE;
    }

    componentWillMount() {
        this.props.fetchCoupons();
    }

    componentWillReceiveProps(newProps) {
       
        const { unauthorized, history } = newProps;
        if(unauthorized){
            history.push('/');
        }
     }

    onCouponItemClick(coupon) {
        console.log('onCouponItemClick', coupon);
        this.props.setCouponToEdit(coupon);
        this.props.history.push(`/CouponsScreen/edit`);

    }

    renderCouponsList() {
        const { couponsList } = this.props;

        if (!couponsList) {
            return (
                <div className="content-loading">טעינה...</div>
            );
        }

        if (couponsList.length <= 0) {
            return (
                <div className="content-message">אין קופונים זמינים במערכת.</div>
            );
        }
        
        return (
            <div>
                <ItemsList
                    extraClass='ols-wrapper-list'
                    headers={['שם הקופון', 'האם פעיל', 'תאור', 'הנחה', 'סוג הנחה', 'סוג שרות', 'תאריך תחילה', 'תאריך סיום' ]}
                    itemMap={['couponName','isActive', 'couponDescription', 'discountToShow', 'discountType', 'orderType', 'startDate', 'expiredDate']}
                    gravity={{ "2": 2, "8": 2, "9": 2 }}
                    items={couponsList}
                    onItemEditBtnClick={itm => this.onCouponItemClick(itm)}
                />
            </div>
        );
    }

    

    render() {
        
        const { filteredListArr, filterBy } = this.props;
        

        return (
            <div className='ols-wrapper'>

                {this.renderCouponsList()}

                <Pagination
                    fullList={filteredListArr}
                    totalPerPage={50}
                    onSubListReady={(pagedList, pageNum) => this.setState({ pagedList, pageNum })}
                    pageNum={this.state.pageNum}
                />

                
            </div>
        )
    }
}

const mapStateToProp = ({ coupons, errors }) => {
    const { couponsList, coupon, filteredListArr, filterBy } = coupons;
    const { unauthorized } = errors;
    return {
        couponsList,
        coupon,
        filteredListArr,
        filterBy,
        unauthorized
    };
};

export default connect(mapStateToProp, {
    fetchCoupons, setCouponToEdit,
})(CouponsList);


