import React from "react"
import { exelotProvider } from "./ExelotProvider"
import { oneProjectProvider } from "./OneProjectProvider"

let provider = {}

provider.isProvider = (serviceName) => {
  switch (serviceName) {
    case "exelot":
      return true
    case "oneprojet":
      return true
    default:
      return false
  }
}

provider.GetProvider = (serviceName) => {
  switch (serviceName) {
    case "exelot":
      return exelotProvider
    case "oneprojet":
      return oneProjectProvider
    default:
      return {}
  }
}

provider.isProviderByOrderType = (orderType) => {
  switch (orderType) {
    case 7:
      return true
    default:
      return false
  }
}

provider.GetProviderByOrderType = (orderType) => {
  switch (orderType) {
    case 7:
      return exelotProvider
    default:
      return {}
  }
}

provider.orderType = (serviceName) => {
  if (!provider.isProvider(serviceName)) {
    return 0
  }
  const s = provider.GetProvider(serviceName)
  return s.orderType
}

provider.serviceName = (orderType) => {
  if (!provider.isProviderByOrderType(orderType)) {
    return 0
  }
  const s = provider.GetProviderByOrderType(orderType)
  return s.serviceName
}

provider.accountType = (serviceName) => {
  if (!provider.isProvider(serviceName)) {
    return 0
  }
  const s = provider.GetProvider(serviceName)
  return s.accountType
}

provider.renderLogo = (serviceName) => {
  if (!provider.isProvider(serviceName)) {
    return
  }
  const s = provider.GetProvider(serviceName)
  return s.renderLogo()
}

export const providerManager = provider
