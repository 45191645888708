import React from 'react';

import Header from '../../../common/List/Header';
import CouponItem from './CouponItem';
//import OrderItem from '../../OrdersListScreen/OrderList/OrderItem';

import './style.css';

const ItemsList = ({ extraClass, headers, items, gravity, itemMap, onItemEditBtnClick }) => {
    return (
        <div className={`ol-list-wrapper ${extraClass}`}>
            <Header titles={headers} gravity={gravity}/>
            <div className='ol-list-items-wrapper'>
                {renderItems(items, gravity, itemMap, onItemEditBtnClick)}
            </div>
        </div>
    );
};

const renderItems = (items, gravity, itemMap, onItemEditBtnClick) => {
    
    return items.map((itm, index) => {
        return (
            <CouponItem
                key={index}
                item={itm}
                itemMap={itemMap}
                gravity={gravity}
                isLast={index === items.length - 1}
                onEditBtnClick={onItemEditBtnClick}
            />
        );
    });
};

export default ItemsList;
