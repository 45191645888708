import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchExistingUserDataForUpdate,
  setUserId,
  clearUserHistory,
  setUserHistoryUser,
  setAuthorizationManual,
  fetchUserByMobile,
} from "../../../actions"
import axios from "axios"
import Cookies from "js-cookie"

class UserEditExternalScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentWillMount() {
    const token = Cookies.get("token")
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
    }
    this.getUserByMobilePhone()
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }
    if (newProps.usersListArr.length > 0) {
      const userId = newProps.usersListArr[0].userId
      this.onUserItemEditBtnClick(userId)
    }
  }

  getUserByMobilePhone() {
    const { history } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const mobilePhone = urlParams.get("mobilePhone")
    if (mobilePhone) {
      this.props.fetchUserByMobile(mobilePhone)
    } else {
      history.push("/")
    }
  }

  onUserItemEditBtnClick(userId) {
    const { history } = this.props
    if (userId) {
      this.props.setAuthorizationManual()
      //this.props.setUserId(userId)
      this.props.fetchExistingUserDataForUpdate(userId)
      this.props.clearUserHistory()
      this.props.setUserHistoryUser(userId)
      this.props.history.push("UserCreateEditScreen/" + userId)
    } else {
      history.push("/")
    }
  }

  render() {
    const {} = this.props
    return <div></div>
  }
}

const mapStateToProp = ({ errors, usersList }) => {
  const { unauthorized } = errors
  const { usersListArr } = usersList

  return { unauthorized, usersListArr }
}

export default connect(mapStateToProp, {
  fetchExistingUserDataForUpdate,
  setUserId,
  clearUserHistory,
  setUserHistoryUser,
  setAuthorizationManual,
  fetchUserByMobile,
})(UserEditExternalScreen)
