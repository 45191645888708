import React from "react"
import "./yesNoPopup.scss"

const yesNoPopup = (props) => {
  return (
    <div className="yesNoPopup-wrapper">
      {props.isOpen ? (
        <div className="overlay">
          <div className="yesNoPopup-container">
            <div className="yesNoPopup">
              <img
                className="info-icon"
                src={require("../../../assets/images/warning.png")}
                alt="info icon"
              />
              <div className="message-text">{props.infoMsg}</div>
              <div className="btn-holder">
                <button className="btn-yes" onClick={props.confirm}>
                  כן
                </button>
                <button className="btn-no" onClick={props.close}>
                  לא
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
export default yesNoPopup
