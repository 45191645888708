import {
  ON_GET_ALL_SUPPLIERS_SUCCESS,
  ON_GET_ALL_SUPPLIERS_FAIL,
  SET_SUPPLIER_TO_EDIT,
  EDIT_SUPPLIER_FORM,
  ON_UPDATE_SUPPLIER,
  CLEAN_SUPPLIER_UPDATE,
  ON_REMOVE_SUPPLIER,
  CLEAN_SUPPLIER_REMOVE,
  CLEAN_SUPPLIER_FORM,
  ON_CREATE_SUPPLIER,
  SUPPLIER_SHOW_LOADING,
  ON_GET_SELECT_SUPPLIERS_SUCCESS,
  ON_GET_SELECT_SUPPLIERS_FAIL,
} from "../actions/types"

const emptySupplier = {
  name: "",
  type: "",
  phone: "",
  email: "",
  city: "",
  contactPersonName: "",
  createdDate: "",
  createdBy: "",
  IsDelete: false,
  color: "",
}

const INIT_STATE = {
  suppliersList: null,
  supplier: emptySupplier,
  isCreated: false,
  isUpdated: false,
  isRemoved: false,
  isLoading: false,
  suppliersSelectList: [],
}

const ServiceSuppliersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_ALL_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliersList: action.payload,
        isLoading: false,
        isUpdated: false,
        isRemoved: false,
        isCreated: false,
      }
    case ON_GET_ALL_SUPPLIERS_FAIL:
      return {
        ...state,
      }
    case ON_CREATE_SUPPLIER:
      return {
        ...state,
        supplier: action.payload,
        isCreated: true,
        isUpdated: false,
        isRemoved: false,
        isLoading: false,
      }
    case ON_UPDATE_SUPPLIER:
      return {
        ...state,
        supplier: action.payload,
        isCreated: false,
        isUpdated: true,
        isRemoved: false,
        isLoading: false,
      }
    case ON_REMOVE_SUPPLIER:
      return {
        ...state,
        supplier: emptySupplier,
        isCreated: false,
        isUpdated: false,
        isRemoved: action.payload,
        isLoading: false,
      }
    case SET_SUPPLIER_TO_EDIT:
      return {
        ...state,
        supplier: action.payload,
      }
    case EDIT_SUPPLIER_FORM:
      return {
        ...state,
        supplier: action.payload,
      }

    case CLEAN_SUPPLIER_UPDATE:
      return {
        ...state,
        isUpdated: false,
      }

    case CLEAN_SUPPLIER_REMOVE:
      return {
        ...state,
        isRemoved: false,
      }
    case CLEAN_SUPPLIER_FORM:
      return {
        ...state,
        supplier: emptySupplier,
      }
    case SUPPLIER_SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case ON_GET_SELECT_SUPPLIERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        suppliersSelectList: action.payload,
      }
    case ON_GET_SELECT_SUPPLIERS_FAIL:
      return {
        ...state,
        isLoading: false,
        suppliersSelectList: [],
      }

    default:
      return state
  }
}

export default ServiceSuppliersReducer
