import {
  ON_GET_READY_TO_LOAD_ORDERS_SUCCESS,
  ON_GET_READY_TO_LOAD_ORDERS_FAIL,
  ON_LOADING_READY_ORDERS,
  ON_PUT_ORDER_TO_LOCKER_SUCCESS,
  ON_PUT_ORDER_TO_LOCKER_FAIL,
  ON_LOADING_WAITINGFORPICKUP_ORDERS,
  ON_GET_WAITINGFORPICKUP_ORDERS_SUCCESS,
  ON_GET_WAITINGFORPICKUP_ORDERS_FAIL,
  ON_USER_SIGN_IN_RESET_ATTEMPTS,
  ON_LOADING_CREATE_RETURNS_ORDER_SUCCESS,
  ON_CREATE_RETURNS_ORDER_SUCCESS,
  ON_CREATE_RETURNS_ORDER_FAIL,
  CLEAR_CREATE_RETURNS_ORDER_SUCCESS,
  UNAUTHORIZED,
} from "../actions/types"

//import { isProdEnv, getOrdersResponse } from "../utils"

const INIT_STATE = {
  readyToLoadOrders: [],
  waitingForPickupOrders: [],
  isLoading: false,
  isError: false,
  returnsOrderSuccess: false,
}

const OrderToLockerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_READY_TO_LOAD_ORDERS_SUCCESS:
      return {
        ...state,
        readyToLoadOrders: action.payload,
        isLoading: false,
      }
    case ON_GET_READY_TO_LOAD_ORDERS_FAIL:
      return {
        ...state,
        readyToLoadOrders: [],
        isLoading: false,
      }
    case ON_LOADING_READY_ORDERS:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case ON_PUT_ORDER_TO_LOCKER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      }
    case ON_PUT_ORDER_TO_LOCKER_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }

    case ON_LOADING_WAITINGFORPICKUP_ORDERS:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }

    case ON_GET_WAITINGFORPICKUP_ORDERS_SUCCESS:
      return {
        ...state,
        waitingForPickupOrders: action.payload,
        isLoading: false,
        isError: false,
      }

    case ON_GET_WAITINGFORPICKUP_ORDERS_FAIL:
      return {
        ...state,
        waitingForPickupOrders: [],
        isLoading: false,
        isError: true,
      }

    case ON_USER_SIGN_IN_RESET_ATTEMPTS:
    case UNAUTHORIZED:
      return {
        ...state,
        searchedOrdersArr: [],
        readyToLoadOrders: [],
        isLoading: false,
      }

    case ON_LOADING_CREATE_RETURNS_ORDER_SUCCESS:
      return {
        ...state,
        returnsOrderSuccess: false,
        isLoading: true,
        isError: false,
      }

    case ON_CREATE_RETURNS_ORDER_SUCCESS:
      return {
        ...state,
        returnsOrderSuccess: true,
        isLoading: false,
        isError: false,
      }

    case ON_CREATE_RETURNS_ORDER_FAIL:
      return {
        ...state,
        returnsOrderSuccess: false,
        isLoading: false,
        isError: true,
      }

    case CLEAR_CREATE_RETURNS_ORDER_SUCCESS:
      return {
        ...state,
        returnsOrderSuccess: false,
        isLoading: false,
        isError: false,
      }
    default:
      return state
  }
}

export default OrderToLockerReducer
