import React from "react"
import "./RouteAreaCodeScreen.scss"
import { Route, Link } from "react-router-dom"
import RouteAreaCodeList from "./RouteAreaCodeList/RouteAreaCodeList.js"
import EditRouteAreaCode from "./EditRouteAreaCode/EditRouteAreaCode.js"
import NewRouteAreaCode from "./NewRouteAreaCode/NewRouteAreaCode.js"

const RouteAreaCodeScreen = (props) => {
  return (
    <section className="routeAreCodeScreen">
      <nav>
        <ul>
          <li>
            <Link to={`${props.match.url}`}>רשימת קודים</Link>
          </li>
          <li>
            <Link to={`${props.match.url}/create`}>יצירת קוד חדש</Link>
          </li>
        </ul>
      </nav>
      <main>
        <Route
          exact
          path={`${props.match.path}`}
          component={RouteAreaCodeList}
        />
        <Route
          path={`${props.match.path}/edit`}
          component={EditRouteAreaCode}
        />
        <Route
          path={`${props.match.path}/create`}
          component={NewRouteAreaCode}
        />
      </main>
    </section>
  )
}

export default RouteAreaCodeScreen
