import axios from "axios";
import api from "../api";
import {
  ON_GET_ALL_SUPPLIERS_SUCCESS,
  ON_GET_ALL_SUPPLIERS_FAIL,
  SET_SUPPLIER_TO_EDIT,
  EDIT_SUPPLIER_FORM,
  ON_UPDATE_SUPPLIER,
  CLEAN_SUPPLIER_UPDATE,
  ON_REMOVE_SUPPLIER,
  CLEAN_SUPPLIER_REMOVE,
  CLEAN_SUPPLIER_FORM,
  ON_CREATE_SUPPLIER,
  SUPPLIER_SHOW_LOADING,
  ON_GET_SELECT_SUPPLIERS_SUCCESS,
  ON_GET_SELECT_SUPPLIERS_FAIL,
} from "./types";
import errorManager from "../utils/ErrorManager";

export const getAllSuppliers = () => {
  return (dispatch) => {
    axios
      .get(api.getAllSuppliers)
      .then((res) => {
        dispatch({
          type: ON_GET_ALL_SUPPLIERS_SUCCESS,
          payload: res.data,
        });
        errorManager.throwDispatchErrorReset(dispatch, res);
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ALL_SUPPLIERS_FAIL,
          payload: err,
        });
        errorManager.throwDispatch(dispatch, err);
      });
  };
};

export const updateSupplier = (supplier) => {
  return (dispatch) => {
    showLoading(dispatch);
    axios
      .put(api.updateSupplier(supplier.id), supplier, {
        headers: {
          Authorization: axios.defaults.headers.common["Authorization"],
        },
      })
      .then((res) => {
        dispatch({
          type: ON_UPDATE_SUPPLIER,
          payload: res.data,
        });
        errorManager.throwDispatchErrorReset(dispatch, res);
      })
      .catch((err) => {
        errorManager.throwDispatch(dispatch, err);
      });
  };
};

export const editSupplierForm = (suplier) => {
  return {
    type: EDIT_SUPPLIER_FORM,
    payload: suplier,
  };
};

export const setSupplierToEdit = (suplier) => {
  return {
    type: SET_SUPPLIER_TO_EDIT,
    payload: suplier,
  };
};

export const cleanSupplierUpdate = () => {
  return {
    type: CLEAN_SUPPLIER_UPDATE,
  };
};

export const cleanSupplierRemove = () => {
  return {
    type: CLEAN_SUPPLIER_REMOVE,
  };
};

export const removeSupplier = (supplierId) => {
  return (dispatch) => {
    showLoading(dispatch);
    axios
      .delete(api.removeSupplier(supplierId), {
        headers: {
          Authorization: axios.defaults.headers.common["Authorization"],
        },
      })
      .then((res) => {
        dispatch({
          type: ON_REMOVE_SUPPLIER,
          payload: res.data,
        });
        errorManager.throwDispatchErrorReset(dispatch, res);
      })
      .catch((err) => {
        console.log(err);
        errorManager.throwDispatch(dispatch, err);
      });
  };
};

export const cleanSupplierForm = () => {
  return {
    type: CLEAN_SUPPLIER_FORM,
  };
};

export const createSupplier = (formData) => {
  return (dispatch) => {
    showLoading(dispatch);
    axios
      .post(api.createSupplier, formData, {
        headers: {
          Authorization: axios.defaults.headers.common["Authorization"],
        },
      })
      .then((res) => {
        dispatch({
          type: ON_CREATE_SUPPLIER,
          payload: res.data,
        });
        errorManager.throwDispatchErrorReset(dispatch, res);
      })
      .catch((err) => {
        console.log(err);
        errorManager.throwDispatch(dispatch, err);
      });
  };
};

export const showLoading = () => {
  return {
    type: SUPPLIER_SHOW_LOADING,
  };
};



export const fetchSuppliersSelectList = () => {
    return (dispatch) => {
      axios
        .get(api.getSuppliersSelectList)
        .then((res) => {
          dispatch({
            type: ON_GET_SELECT_SUPPLIERS_SUCCESS,
            payload: res.data,
          });
          errorManager.throwDispatchErrorReset(dispatch, res);
        })
        .catch((err) => {
          dispatch({
            type: ON_GET_SELECT_SUPPLIERS_FAIL,
            payload: err,
          });
          errorManager.throwDispatch(dispatch, err);
        });
    };
};


