import React from "react"

import Header from "../../../../common/List/Header"
import LockerItem from "./LockerItem"
import { H1, Spinner } from "../../../../common"

import "./style.css"

const lockerHeaders = [
  "מס' תא",
  "סוג תא",
  "נגישות",
  "מס' הזמנה",
  "סטטוס תא",
  "עדכן סטטוס",
  "גודל תא",
  "טעינת הזמנה",
  "טעינת הזמנה ",
  "טעינת הזמנה ",
  "עברת מיקום הזמנה",
]
const lockerItemMap = [
  "lockerName",
  "lockerType",
  "isAccessible",
  "orderNumber",
  "lockerStatus",
  "updateLockerStatus",
  "lockerSize",
  "loadOrderToLocker",
  "loadOrderToLocker2",
  "loadOrderToLockerDHLReturns",
  "relocateOrder",
]
const lockerGravity = { 0: 0.5, 4: 1, 5: 1, 6: 1 }

const LockersList = ({
  extraClass,
  items,
  isError,
  isLoading,
  history,
  onLoadOrderToLockerClick,
  onLoadOrderToLockerClick2,
  onLoadOrderDHLReturnsClick,
  lockerSizeOptions,
  onSelectLockerSize,
}) => {
  return (
    <div className={`stations-list list-wrapper ${extraClass}`}>
      <Header titles={lockerHeaders} gravity={lockerGravity} />
      <div className="list-items-wrapper">
        {renderItems(
          items,
          lockerGravity,
          lockerItemMap,
          isError,
          isLoading,
          history,
          onLoadOrderToLockerClick,
          onLoadOrderToLockerClick2,
          onLoadOrderDHLReturnsClick,
          lockerSizeOptions,
          onSelectLockerSize
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  lockerGravity,
  lockerItemMap,
  isError,
  isLoading,
  history,
  onLoadOrderToLockerClick,
  onLoadOrderToLockerClick2,
  onLoadOrderDHLReturnsClick,
  lockerSizeOptions,
  onSelectLockerSize
) => {
  if (isError) {
    return (
      <H1 className="not-valid-search" title="אנא בדוק את הערך של שדה חיפוש" />
    )
  }
  if (isLoading) {
    return (
      <div className="station-editor-spinner-wrapper">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (!items || items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }

  return items.map((itm, index) => {
    return (
      <LockerItem
        key={index}
        item={itm}
        itemMap={lockerItemMap}
        gravity={lockerGravity}
        isLast={index === items.length - 1}
        history={history}
        onLoadOrderClick={onLoadOrderToLockerClick}
        onLoadOrderClick2={onLoadOrderToLockerClick2}
        onLoadOrderDHLReturnsClick={onLoadOrderDHLReturnsClick}
        lockerSizeOptions={lockerSizeOptions}
        onSelectLockerSize={onSelectLockerSize}
      />
    )
  })
}

export default LockersList
