import {
  ON_USER_SIGN_IN_REQUEST,
  ON_USER_SIGN_IN_SUCCESS,
  ON_USER_SIGN_IN_FAIL,
  ON_USER_SIGN_IN_RESET_ATTEMPTS,
  ON_UPDATE_SELF_PASSWORD_REQUEST,
  ON_UPDATE_SELF_PASSWORD_SUCCESS,
  ON_UPDATE_SELF_PASSWORD_FAIL,
} from "../actions/types"
import Cookies from "js-cookie"
const INIT_STATE = {
  logedUserData: null,
  loadingReq: false,
  isLoginFailed: false,
}

const LoginReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    default:
      return state
    case ON_USER_SIGN_IN_REQUEST:
      return {
        ...state,
        loadingReq: true,
        logedUserData: null,
        isLoginFailed: false,
      }
    case ON_USER_SIGN_IN_SUCCESS:
      Cookies.set("token", action.payload.token, { expires: 1 })
      Cookies.set("firstName", action.payload.firstName, { expires: 1 })
      Cookies.set("lastName", action.payload.lastName, { expires: 1 })
      Cookies.set("role", action.payload.role, { expires: 1 })
      return {
        ...state,
        loadingReq: false,
        isLoginFailed: false,
        logedUserData: action.payload,
      }
    case ON_USER_SIGN_IN_FAIL:
      return {
        ...state,
        logedUserData: null,
        loadingReq: false,
        isLoginFailed: action.payload,
      }

    case ON_UPDATE_SELF_PASSWORD_REQUEST:
      return {
        ...state,
        loadingReq: true,
        isLoginFailed: false,
      }

    case ON_UPDATE_SELF_PASSWORD_FAIL:
      return {
        ...state,
        loadingReq: false,
        isLoginFailed: true,
      }
    case ON_UPDATE_SELF_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingReq: false,
        isLoginFailed: false,
      }
    case ON_USER_SIGN_IN_RESET_ATTEMPTS:
      Cookies.remove("token")
      Cookies.remove("firstName")
      Cookies.remove("lastName")
      Cookies.remove("role")
      return {
        ...INIT_STATE,
      }
  }
}

export default LoginReducer
