import React, { useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  updatePrice,
  createPrice,
  uploadPriceListItemImage,
  deletePriceListItem,
  getPriceList,
} from "../../../../../actions";

import Table from "../../_common/Table";
import { EditableNumberInput } from "../../_common/EditableNumberInput";

import "./style.css";

const laundryTableHeader = "כביסה";
const itemNameTableHeader = "שם פריט";
const deleteLaundryPriceListItemButtonLabel = "למחוק את הפרית מהמחירון";

const LaundryPriceList = (props) => {
  const {
    priceList,
    laundryPriceList,
    updatePrice,
    deletePriceListItem,
    getPriceList,
    createPrice,
    products,
  } = props;

  let [laundryPriceListState, setLaundryPriceListState] =
    useState(laundryPriceList);

  useEffect(() => {
    setLaundryPriceListState(laundryPriceList);
  }, [laundryPriceList]);

  const updateServerData = (priceListItemId, price) => {
    updatePrice(priceListItemId, price, () => getPriceList(priceList));
  };

  const createServerData = (priceData, price) => {
    createPrice(priceData, () => getPriceList(priceList));
  };

  const addPricelessRows = () => {
    let emprtyRows = [];
    for (let i = products.length; i--; ) {
      const found = laundryPriceList.some(
        (el) => el.productId == products[i].productId
      );
      if (!found) {
        emprtyRows.push(products[i]);
      }
    }
    return emprtyRows;
  };

  let items = new Array();
  let processes = new Array();

  laundryPriceList.forEach((priceListEntry) => {
    const processIdIndex = processes.findIndex(
      (object) => object.name === priceListEntry.processName_he
    );
    if (processIdIndex === -1) {
      processes.push({
        name: priceListEntry.processName_he,
        processId: priceListEntry.processId,
      });
    }

    const productIdIndex = items.findIndex(
      (object) => object.name === priceListEntry.productName_he
    );
    if (productIdIndex === -1) {
      items.push({
        name: priceListEntry.productName_he,
        displayName: priceListEntry.productName_he,
        productId: priceListEntry.productId,
      });
    }
  });

  processes.sort((a, b) => (a.name > b.name ? 1 : -1));

  let rows = [];
  items.forEach((item) => {
    let row = {};
    row.itemName = item.name;
    row.productId = item.productId;
    processes.forEach((process) => {
      laundryPriceList.forEach((priceListEntry) => {
        if (
          priceListEntry.productName_he === item.name &&
          priceListEntry.processName_he === process.name
        ) {
          row[process.name] = {
            price: priceListEntry.price,
            priceListItemId: priceListEntry.priceItemId,
          };
        }
      });
    });
    rows.push(row);
  });

  const pricelessRows = addPricelessRows();
  pricelessRows.forEach((pricelessrow) => {
    let emptyRow = [];
    emptyRow.itemName = pricelessrow.productName_he;
    emptyRow.productId = pricelessrow.productId;
    rows.push(emptyRow);
    rows.sort((a, b) => (a.itemName > b.itemName ? 1 : -1));
  });

  let headers = [];
  headers.push({
    Header: () => itemNameTableHeader,
    accessor: "itemName",
  });

  processes.forEach((process) => {
    headers.push({
      Header: process.name,
      id: `${process.processId}`,
      Cell: ({ row }) => {
        let priceListItemId = 0;
        let price = "-";
        let _process = row.original[process.name];
        if (_process !== undefined) {
          priceListItemId = row.original[process.name].priceListItemId;
          price = row.original[process.name].price;
          return (
            <div className="priceHolder">
              <EditableNumberInput
                itemId={priceListItemId}
                index={row.index}
                id={"price"}
                row={row}
                className="table-editable-number-input"
                initialValue={price}
                updateData={updateServerData}
              />
              <button
                className="delete-button"
                title={deleteLaundryPriceListItemButtonLabel}
                onClick={() => {
                  const shouldDelete = window.confirm(
                    `Do you want to delete this price list item?`
                  );
                  if (shouldDelete) {
                    deletePriceListItem(priceListItemId, () =>
                      getPriceList(priceList)
                    );
                  }
                }}
              >
                <div className="fas fa-trash-alt" />
              </button>
            </div>
          );
        } else {
          //if there no item
          return (
            <div className="priceHolder">
              <EditableNumberInput
                productId={row.original.productId}
                processId={process.processId}
                priceListId={props.priceList}
                index={row.index}
                id={"price"}
                row={row}
                className="table-editable-number-input"
                initialValue="0"
                updateData={updateServerData}
                createData={createServerData}
              />
            </div>
          );
        }
      },
    });
  });

  const columns = useMemo(() => headers, [headers]);

  if (laundryPriceList.length === 0) return null;

  return (
    <>
      <h2>{laundryTableHeader}</h2>
      <div className="table-holder">
        <Table id="laundry-price-list-table" columns={columns} data={rows} />
      </div>
    </>
  );
};

const mapStateToProps = ({ priceListsSection }) => {
  const { laundryPriceList, priceList, products } = priceListsSection;
  return {
    laundryPriceList,
    priceList,
    products,
  };
};

export default connect(mapStateToProps, {
  updatePrice,
  createPrice,
  uploadPriceListItemImage,
  deletePriceListItem,
  getPriceList,
})(LaundryPriceList);
