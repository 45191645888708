import React, { useRef, useState, useEffect } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { BlueButton } from "../../../common/BlueButton"
import { getAllSuppliers, exportLaundryPrices } from "../../../../actions"
import "./style.scss"

const ExportLaundryPrices = (props) => {
  const { exportLaundryPrices } = props
  const serviceSupplierIdRef = useRef(null)
  const fromDateRef = useRef(null)
  const toDateRef = useRef(null)
  const [error, setError] = useState(null)
  const [suppliers, setSuppliers] = useState(null)
  const [selectedSupplier, setSelectedSupplier] = useState(null)

  useEffect(() => {
    props.getAllSuppliers()
  }, [])

  useEffect(() => {
    const suppliersList = props.suppliersList
    if (suppliersList?.length > 0) {
      const suppliersFiltered = suppliersList.map((supplier, index) => ({
        value: index,
        label: supplier.name,
        id: supplier.id,
      }))
      setSuppliers(suppliersFiltered)
    }
  }, [props.suppliersList])

  const handleSubmit = async () => {
    const serviceSupplierId = selectedSupplier?.id
    const fromDate = new Date(fromDateRef.current.value)
    const toDate = new Date(toDateRef.current.value)

    if (
      !serviceSupplierId ||
      !fromDateRef.current.value ||
      !toDateRef.current.value
    ) {
      setError("אנא מלא את כל השדות.")
      return
    }

    if (fromDate > toDate) {
      setError("תאריך ההתחלה אינו יכול להיות מאוחר מתאריך הסיום.")
      return
    }

    if (toDate < fromDate) {
      setError("תאריך הסיום אינו יכול להיות לפני תאריך ההתחלה.")
      return
    }

    setError(null)
    exportLaundryPrices(
      serviceSupplierId,
      fromDate.toISOString(),
      toDate.toISOString()
    )
  }

  return (
    <div className="export-laundry-prices">
      <h3>דוח מחירי כביסה</h3>
      <div className="laundry-prices-form">
        <Select
          className="supplier-selector"
          options={suppliers || []}
          placeholder="בחר ספק"
          onChange={(sup) => {
            setSelectedSupplier(sup)
          }}
        />
        <div className="input-group">
          <label>מי תאריך </label>
          <input type="date" ref={fromDateRef} placeholder="From Date" />
        </div>
        <div className="input-group">
          <label>עד תאריך</label>
          <input type="date" ref={toDateRef} placeholder="To Date" />
        </div>
        {error && <div className="error-message">{error}</div>}
        <BlueButton onClick={handleSubmit} className="download-file-button">
          לייצא
        </BlueButton>
      </div>
    </div>
  )
}

const mapStateToProps = ({ suppliers }) => {
  const { suppliersList } = suppliers
  return { suppliersList }
}

export default connect(mapStateToProps, {
  getAllSuppliers,
  exportLaundryPrices,
})(ExportLaundryPrices)
