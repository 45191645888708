import axios from "axios"
import api from "../api"
import {
  ON_GET_RECEPTION_ORDERS_SUCCESS,
  ON_GET_RECEPTION_ORDERS_FAIL,
  ON_RECEPTION_ORDER_PROCESS_SUCCESS,
  ON_RECEPTION_ORDER_PROCESS_FAIL,
  ON_RECEPTION_ORDER_NOT_ALLOWED,
  CLEAR_RECEPTION_ORDER_NOT_ALLOWED,
  ON_RECEPTION_ORDERS_LOADING,
  ON_PROCESS_ORDER_LOADING,
  CLEAR_RECEPTION_ORDERS,
  ON_SHIPPING_CERTIFICATE_LOADING,
  ON_GET_SHIPPING_CERTIFICATE_SUCCESS,
  ON_GET_SHIPPING_CERTIFICATE_FAIL,
  ON_SUPPLIERS_SHIPPING_CERTIFICATE_LOADING,
  ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_SUCCESS,
  ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_FAIL,
  ON_ROUTE_SHIPPING_CERTIFICATE_LOADING,
  ON_GET_ROUTE_SHIPPING_CERTIFICATE_SUCCESS,
  ON_GET_ROUTE_SHIPPING_CERTIFICATE_FAIL,
  ON_END_OF_DAY_LOADING,
  ON_END_OF_DAY_SUCCESS,
  ON_END_OF_DAY_FAIL,
  CLEAR_END_OF_DAY,
  CLEAR_CERTIFICATES,
} from "./types"

import errorManager from "../utils/ErrorManager"

export const getReceptionOrders = () => {
  return (dispatch) => {
    dispatch({ type: ON_RECEPTION_ORDERS_LOADING })
    axios
      .get(api.getReceptionOrders())
      .then((res) => {
        dispatch({
          type: ON_GET_RECEPTION_ORDERS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_RECEPTION_ORDERS_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const processReceptionOrder = (orderId) => {
  return (dispatch) => {
    dispatch({ type: ON_PROCESS_ORDER_LOADING })
    axios
      .put(api.processReceptionOrder(orderId))
      .then((res) => {
        dispatch({
          type: ON_RECEPTION_ORDER_PROCESS_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        if (err.response.status === 403) {
          dispatch({
            type: ON_RECEPTION_ORDER_NOT_ALLOWED,
            payload: err,
          })
        } else {
          dispatch({
            type: ON_RECEPTION_ORDER_PROCESS_FAIL,
            payload: err,
          })
        }
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearNotAllowedToProcess = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_RECEPTION_ORDER_NOT_ALLOWED,
    })
  }
}

export const clearReceptionOrders = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_RECEPTION_ORDERS,
    })
  }
}

export const getShippingCertificateByOrderType = (orderType, orderStatus) => {
  return (dispatch) => {
    dispatch({ type: ON_SHIPPING_CERTIFICATE_LOADING })
    axios
      .put(api.getShippingCertificateByOrderType(orderType, orderStatus))
      .then((res) => {
        dispatch({
          type: ON_GET_SHIPPING_CERTIFICATE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_SHIPPING_CERTIFICATE_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getShippingCertificateByAccountType = (
  accountType,
  orderStatus
) => {
  return (dispatch) => {
    dispatch({ type: ON_SHIPPING_CERTIFICATE_LOADING })
    axios
      .put(api.getShippingCertificateByAccountType(accountType, orderStatus))
      .then((res) => {
        dispatch({
          type: ON_GET_SHIPPING_CERTIFICATE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_SHIPPING_CERTIFICATE_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getShippingCertificateByRoute = (routeId, orderStatus) => {
  return (dispatch) => {
    dispatch({ type: ON_ROUTE_SHIPPING_CERTIFICATE_LOADING })
    axios
      .get(api.getShippingCertificateByRoute(routeId, orderStatus))
      .then((res) => {
        dispatch({
          type: ON_GET_ROUTE_SHIPPING_CERTIFICATE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ROUTE_SHIPPING_CERTIFICATE_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getShippingCertificateByRouteOutsource = (
  routeId,
  orderStatus
) => {
  return (dispatch) => {
    dispatch({ type: ON_ROUTE_SHIPPING_CERTIFICATE_LOADING })
    axios
      .get(api.getShippingCertificateByRouteOutsource(routeId, orderStatus))
      .then((res) => {
        dispatch({
          type: ON_GET_ROUTE_SHIPPING_CERTIFICATE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ROUTE_SHIPPING_CERTIFICATE_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getShippingCertificateBySupplier = (suppierId, orderStatus) => {
  return (dispatch) => {
    dispatch({ type: ON_SUPPLIERS_SHIPPING_CERTIFICATE_LOADING })
    axios
      .get(api.getShippingCertificateBySupplier(suppierId, orderStatus))
      .then((res) => {
        dispatch({
          type: ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_SUPPLIERS_SHIPPING_CERTIFICATE_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const sendEndOfDayNotification = (orders) => {
  return (dispatch) => {
    dispatch({ type: ON_END_OF_DAY_LOADING })
    axios
      .post(api.sendEndOfDayNotification, orders)
      .then((res) => {
        dispatch({
          type: ON_END_OF_DAY_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_END_OF_DAY_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearEndOfDay = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_END_OF_DAY,
    })
  }
}

export const clearCertificates = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CERTIFICATES,
    })
  }
}
