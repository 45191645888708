import axios from "axios"
import api from "../api/AzureApi"
import {
  ON_SET_CURRENT_USERS_ROUTE,
  ON_ROUTE_USER_LOADING,
  ON_GET_ROUTE_USERS_SUCCESS,
  ON_GET_ROUTE_USERS_FAIL,
  ON_CREATE_ROUTE_USER_SUCCESS,
  ON_CREATE_ROUTE_USER_FAIL,
  ON_GET_EXTERNAL_DRIVERS_SUCCESS,
  ON_GET_EXTERNAL_DRIVERS_FAIL,
  ON_REMOVE_ROUTE_USER_SUCCESS,
  ON_REMOVE_ROUTE_USER_FAIL,
  ON_CLOSE_ROUTE_USER_NOTIFICATION,
} from "./types"
import errorManager from "../utils/ErrorManager"

export const setCurrentUsersRoute = (route) => {
  console.log("setCurrentRoute>route:")
  console.log(route)
  return (dispatch) => {
    dispatch({
      type: ON_SET_CURRENT_USERS_ROUTE,
      payload: route,
    })
  }
}

export const onLoadingRouteUser = () => {
  return (dispatch) => {
    dispatch({
      type: ON_ROUTE_USER_LOADING,
    })
  }
}

export const fetchRouteDrivers = (routeId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    axios
      .get(api.getRouteDriversByRoute(routeId), options)
      .then((res) => {
        dispatch({
          type: ON_GET_ROUTE_USERS_SUCCESS,
          payload: res.data,
        })

        //errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_ROUTE_USERS_FAIL,
          payload: err,
        })
        // errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchExternalDrivers = () => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .get(api.getExternalDrivers, options)
      .then((res) => {
        dispatch({
          type: ON_GET_EXTERNAL_DRIVERS_SUCCESS,
          payload: res.data,
        })
        //errorManager.throwDispatchErrorReset(dispatch,res);
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_EXTERNAL_DRIVERS_FAIL,
          payload: err,
        })
        //errorManager.throwDispatch(dispatch, err);
      })
  }
}

export const addRouteDriver = (routeId, userId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    const data = { routeId: routeId, userId: userId }

    axios
      .post(api.addUserToRoute, data, options)
      .then((res) => {
        dispatch({
          type: ON_CREATE_ROUTE_USER_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_CREATE_ROUTE_USER_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const removeRouteUser = (routeId, userId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    const data = { routeId: routeId, userId: userId }
    axios
      .put(api.removeRouteUser, data, options)
      .then((res) => {
        dispatch({
          type: ON_REMOVE_ROUTE_USER_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_REMOVE_ROUTE_USER_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const closeRouteUserNotification = () => {
  return (dispatch) => {
    dispatch({
      type: ON_CLOSE_ROUTE_USER_NOTIFICATION,
    })
  }
}
