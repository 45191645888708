export const clif = (cond, className) => {
  if (!cond) {
    return "";
  }

  return className;
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const calcOrdersItemsCountForPrintLabel = (orderItems) => {
  let labelCount = 0;
  let allowedProcess = [
    "כביסה וגיהוץ",
    "ניקוי יבש",
    "כביסה וגיהוץ אריזה לנסיעה",
    "laundry ironing",
    "dry cleaning",
    "laundry ironing package",
  ];
  orderItems.forEach((element) => {
    if (allowedProcess.includes(element.processName)) {
      labelCount += element.quantity;
    }
  });
  return labelCount;
};
