import React, { Component } from "react"
import { connect } from "react-redux"
import { Pagination } from "../../../common"
import ItemsList from "../ItemsList"
import { getAllSuppliers, setSupplierToEdit } from "../../../../actions"
import "./style.css"

class SuppliersList extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      suppliersListArr: [],
      pagedList: [],
    }

    this.state = this.INIT_STATE
  }

  componentWillMount() {
    this.props.getAllSuppliers()
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }
  }

  onSupplierItemClick(supplier) {
    this.props.setSupplierToEdit(supplier)
    this.props.history.push(`/ServiceSuppliersScreen/edit`)
  }

  renderSuppliersList() {
    const { suppliersList } = this.props

    if (!suppliersList) {
      return <div className="content-loading">טעינה...</div>
    }

    if (suppliersList.length <= 0) {
      return <div className="content-message">אין ספקים במערכת.</div>
    }

    return (
      <div>
        <ItemsList
          extraClass="ols-wrapper-list"
          headers={[
            "",
            "שם ספק",
            "סוג",
            "טלפון",
            "אימייל",
            "עיר",
            "שם איש קשר",
            "תאריך יצירה",
            "נוצר על ידי",
            "",
          ]}
          itemMap={[
            "color",
            "name",
            "type",
            "phone",
            "email",
            "city",
            "contactPersonName",
            "createdDate",
            "createdByName",
          ]}
          gravity={{ 3: 2, 4: 2, 6: 2, 7: 2, 8: 2 }}
          items={suppliersList}
          onItemEditBtnClick={(itm) => this.onSupplierItemClick(itm)}
        />
      </div>
    )
  }

  render() {
    const { filteredListArr, filterBy } = this.props

    return (
      <div className="ols-wrapper">
        {this.renderSuppliersList()}

        <Pagination
          fullList={filteredListArr}
          totalPerPage={50}
          onSubListReady={(pagedList) => this.setState({ pagedList })}
        />
      </div>
    )
  }
}

const mapStateToProp = ({ suppliers, errors }) => {
  const { suppliersList, supplier, filteredListArr, filterBy } = suppliers
  const { unauthorized } = errors
  return {
    suppliersList,
    supplier,
    filteredListArr,
    filterBy,
    unauthorized,
  }
}

export default connect(mapStateToProp, { getAllSuppliers, setSupplierToEdit })(
  SuppliersList
)
