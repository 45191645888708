import React from "react"

import { InputWithLabel, InputDatePicker } from "../../../../common"
import "./style.scss"
import { isProdEnv } from "../../../../../utils"
import { getFormatedDate } from "../../../../../utils/FormatedDate"

const OthersTab = ({ onInputChange, userData, removeCCToken }) => {
  const {
    customerDiscount,
    creditCardToken,
    accountName,
    registrationDate,
    compensation,
    discountExpirationDate,
  } = userData

  const handleDateChange = (inputName, event) => {
    onInputChange(inputName, event.target.value)
  }
  const formatedRegistrationDate = registrationDate
    ? getFormatedDate(registrationDate)
    : ""
  return (
    <div className="adt-wrapper user-edit-others-tab">
      <div className="adt-inner-wrapper">
        <InputWithLabel
          extraClass="ucs-input"
          placeholder="הנחה קבועה ללקוח(%)"
          value={customerDiscount || ""}
          onChange={(e) => onInputChange("customerDiscount", e.target.value)}
        />
        <InputWithLabel
          extraClass="ucs-input"
          placeholder="קיים טוקן אשראי"
          value={
            creditCardToken ? "קיים טוקן אשראי" : "לא קיים טוקן אשראי" || ""
          }
          onChange={(e) => e}
          // onChange={(e) => onInputChange('creditCardToken', e.target.value)}
          disabled
        />
        <div className="btn-delete-holder">
          <button
            disabled={creditCardToken ? false : true}
            className={`btn-delete ${creditCardToken ? "" : "disabled"}`}
            onClick={() => removeCCToken()}
          >
            הסר טוקן
          </button>
        </div>
      </div>
      <div className="adt-inner-wrapper">
        <InputDatePicker
          name="discountExpirationDate"
          date={discountExpirationDate}
          onChange={handleDateChange}
          placeholder={" תוקף של הנחה קבועה "}
        />

        <InputWithLabel
          extraClass="ucs-input registration-date"
          placeholder="תאריך יצירת לקוח"
          value={formatedRegistrationDate || ""}
          // onChange={(e) => onInputChange('registrationDate', e.target.value)}
          onChange={(e) => e}
          disabled
        />
      </div>
      <div className="adt-inner-wrapper">
        <InputWithLabel
          extraClass="ucs-input"
          placeholder="יתרת פיצוי לקוח"
          value={compensation}
          onChange={(e) => onInputChange("compensation", e.target.value)}
          disabled={isProdEnv()}
        />
        <InputWithLabel
          extraClass="ucs-input"
          placeholder="שם לחשבונית"
          value={accountName || ""}
          onChange={(e) => onInputChange("accountName", e.target.value)}
        />
      </div>
    </div>
  )
}

export default OthersTab
