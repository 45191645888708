import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "./RouteAreaCodeList.scss"
import {
  getAllRouteAreaCodes,
  selectEditRouteAreaCode,
  fetchRoutes,
} from "../../../../../actions"
import { BlueButton } from "../../../../common"

const RouteAreaCodeList = (props) => {
  useEffect(() => {
    props.getAllRouteAreaCodes()
    props.fetchRoutes()
  }, [])

  const editRouteAreaCode = (routeAreaCode) => {
    props.history.push(`${props.match.path}/edit`)
    props.selectEditRouteAreaCode(routeAreaCode)
  }

  return (
    <section className="routeAreaCodeList">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>שם קידומת אזור</th>
              <th>תיאור קידומת אזור</th>
              <th>שם מסלול</th>
              <th>כמות עמדות מקושרות</th>
              <th>עריכה</th>
            </tr>
          </thead>
          <tbody>
            {props.routeAreaCodes.map((routeAreaCode) => (
              <tr key={routeAreaCode.id}>
                <td>{routeAreaCode.routeAreaCodeName}</td>
                <td>{routeAreaCode.routeAreaCodeDesc}</td>
                <td>{routeAreaCode.routeName}</td>
                <td>{routeAreaCode.stationsCount}</td>
                <td>
                  <BlueButton
                    extraClass="edit-btn"
                    onClick={(e) => editRouteAreaCode(routeAreaCode)}
                  >
                    עריכה
                  </BlueButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  )
}

const mapStateToProps = ({ routeAreaCode }) => {
  const { routeAreaCodes } = routeAreaCode

  return {
    routeAreaCodes,
  }
}
export default connect(mapStateToProps, {
  getAllRouteAreaCodes,
  selectEditRouteAreaCode,
  fetchRoutes,
})(RouteAreaCodeList)
