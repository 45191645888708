import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Multiselect, SubmitButton, H1 } from '../../../common';
import { downloadDetailedLockersReport } from '../../../../actions';

import './style.css';

const detailedLockersReportHeader = "דוח מצב לוקרים מפורט";
const stationsSelectLabelText = "בחר את עמדות";
const lockerStatusesSelectLabelText = "בחר את מצבי תאים";

class DetailedLockersReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStations: [],
            selectedLockerStatuses: []
        }
    }

    onMultiselectChange(multiSelectName, selectedOptions) {
        this.setState({ [multiSelectName]: selectedOptions });
    }

    onDownloadClick(event) {
        event.preventDefault();
        const { selectedStations, selectedLockerStatuses } = this.state;
        this.props.downloadDetailedLockersReport(selectedStations, selectedLockerStatuses);
    }

    render() {
        const { stations, lockerStatuses } = this.props;

        return (
            <>
                <H1 className="reports-section-header" title={detailedLockersReportHeader} />
                <form onSubmit={(e) => this.onDownloadClick(e)}>
                    <div className="form-group">
                        <Multiselect
                            labelText={stationsSelectLabelText}
                            allValue
                            options={stations}
                            onChange={selectedOptions => this.onMultiselectChange('selectedStations', selectedOptions)}
                        />
                        <Multiselect
                            labelText={lockerStatusesSelectLabelText}
                            allValue
                            options={lockerStatuses}
                            onChange={selectedOptions => this.onMultiselectChange('selectedLockerStatuses', selectedOptions)}
                        />
                    </div>
                    <SubmitButton className="download-file-button" title="Download" />
                </form>
            </>
        );
    }
}

const mapStateToProps = ({ selectOptions }) => {
    const { stations, lockerStatuses } = selectOptions;

    return {
        stations,
        lockerStatuses,
    };
}

export default connect(mapStateToProps, { downloadDetailedLockersReport })(DetailedLockersReport);
