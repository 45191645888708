import React, { useEffect, useState } from "react"
import { BlueButton } from "../../../common/BlueButton"
import "./ScanSackMobile.scss"

const ScanSackMobile = (props) => {
  return (
    <section className="scanSackMobile">
      <div className="title">סריקה תעודות מובייל</div>
      <div>
        <BlueButton
          extraClass="btn-scan-mobile"
          onClick={() => props.history.push("/ScanSackMobilePage")}
        >
          <p>סריקת תעודת משלוח להחזרות</p>
        </BlueButton>
      </div>
    </section>
  )
}

export default ScanSackMobile
