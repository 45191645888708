import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"

import {
  fetchLockerStatusSelectOptions,
  fetchStationSelectOptions,
  setLockersStation,
  fetchOrderTypes,
  fetchAccountTypes,
} from "../../../../actions"
import { fetchIfEmpty } from "../../../../utils/GetIfEmpty"

import NotificationModal from "../../../Modals/NotificationModal"

import { H1, BlueButton, Spinner } from "../../../common"
import api from "../../../../api"
import axios from "axios"
import "./style.scss"

const GET_LOCKERS_NUMBERS = api.getLockersSelectOptions
const RELOCATE_ORDER = api.relocateOrder

const orderRelocationText = "שינוי מיקום הזמנה"
const stationText = "עמדה"
const lockerText = "תא"
const relocateButtonText = " העבר את ההזמנה לתא "

class RelocateOrderPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lockersSelectOptions: [],
      stationSelected: "",
      lockerSelected: "",
      isSuccessful: false,
      isFailed: false,
      isLoading: false,
      order: null,
      validationMessage: "",
    }

    this.handleLockerSelectChange = this.handleLockerSelectChange.bind(this)
    this.handleStationLockersRequest =
      this.handleStationLockersRequest.bind(this)
    this.handleRelocateOrderRequest = this.handleRelocateOrderRequest.bind(this)
    this.handleStationsFilterChange = this.handleStationsFilterChange.bind(this)
  }

  componentDidMount() {
    const { unauthorized, history } = this.props

    if (unauthorized) {
      history.push("/")
    }

    fetchIfEmpty(
      this.props.lockerStatuses,
      this.props.fetchLockerStatusSelectOptions
    )
    fetchIfEmpty(this.props.stationsOptions, this.props.fetchAllStationData)
    this.getOrderDetails(this.props.match.params.orderId)
    fetchIfEmpty(this.props.orderTypes, this.props.fetchOrderTypes)
    fetchIfEmpty(this.props.accountTypes, this.props.fetchAccountTypes)
    //this.setDefaultStation(this.state.order)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.order !== this.state.order) {
      this.setDefaultStation(this.state.order)
    }
  }

  componentWillUnmount() {
    this.setState({
      lockersSelectOptions: [],
      stationSelected: "",
      lockerSelected: "",
      isSuccessful: false,
      isFailed: false,
      isLoading: false,
      order: null,
      validationMessage: "",
    })
  }

  setDefaultStation = (order) => {
    const { stationsOptions } = this.props
    if (order && stationsOptions) {
      const defaultStation = stationsOptions.find(
        (option) => option.stationId === order.stationId
      )

      if (defaultStation) {
        this.setState({ stationSelected: defaultStation }, () => {
          this.handleStationLockersRequest()
        })
      }
    }
  }

  getOrderDetails(orderId) {
    this.setState({ isLoading: true })
    axios
      .get(api.getOrderDetails(orderId))
      .then((res) => {
        this.setState({ order: res.data, isLoading: false })
      })
      .catch((err) => {
        console.log(err)

        this.setState({ isLoading: false })
      })
  }

  handleStationLockersRequest() {
    this.setState({ validationMessage: "" })
    const stationId = this.state.stationSelected.stationId

    const options = {
      method: "GET",
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    fetch(`${GET_LOCKERS_NUMBERS}/${stationId}`, options)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
      })
      .then((lockers) => {
        const lockersSelectOptions = Object.keys(lockers).map((key) => ({
          label: lockers[key],
          value: key,
          lockerId: key,
        }))

        this.setState({
          lockersSelectOptions,
        })
      })
      .catch((error) => {
        console.log("Error:")
        console.log(error)
      })
  }

  handleRelocateOrderRequest(event) {
    event.preventDefault()

    const { stationSelected, lockerSelected } = this.state

    if (stationSelected == "") {
      this.setState({ validationMessage: "אנא בחר עמדה" })
      return
    }
    if (lockerSelected == "") {
      this.setState({ validationMessage: "אנא בחר תא" })
      return
    }
    const options = {
      method: "PUT",
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
        "Content-Type": "application/json",
      },
    }

    fetch(
      `${RELOCATE_ORDER}/${this.props.match.params.orderId}?stationId=${stationSelected.stationId}&lockerId=${lockerSelected.lockerId}`,
      options
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            isSuccessful: true,
          })
        } else {
          this.setState({
            isSuccessful: false,
            isFailed: true,
          })
        }
      })
      .catch((error) => {
        console.log(error.message)
        this.setState({
          isFailed: true,
        })
      })
  }

  handleStationsFilterChange(item) {
    this.setState(
      {
        stationSelected: item,
        validationMessage: "",
      },
      this.handleStationLockersRequest
    )
  }

  handleLockerSelectChange(item) {
    this.setState({ lockerSelected: item, validationMessage: "" })
  }

  renderOrder(order) {
    const { isLoading } = this.state
    const { orderTypes, accountTypes } = this.props
    if (isLoading) {
      return (
        <div className="spinner-wrapper">
          <Spinner zoom={0.5} />
        </div>
      )
    }
    const {
      orderNumber,
      orderType,
      senderFullName,
      senderMobilePhone,

      customerFullName,
      mobilePhone,
      orderTotalPrice,
      accountType,
    } = order

    const isTwoCustomers = orderType === 5 || orderType === 8
    const customerName = isTwoCustomers ? " נמען" : "שם לקוח בהזמנה"
    const customerPhone = isTwoCustomers ? "טלפון נמען" : "טלפון לקוח"
    const orderTypeText = orderTypes ? orderTypes[order.orderType] : orderType
    const accountTypeText = isTwoCustomers
      ? accountTypes
        ? accountTypes[accountType]
        : accountType
      : null
    return (
      <>
        <div className="details-block">
          <div className="details-label">מספר הזמנה</div>
          <div className="details-value">{orderNumber}</div>
        </div>
        <div className="details-block">
          <div className="details-label">סוג הזמנה</div>
          <div className="details-value">{orderTypeText}</div>
        </div>
        {isTwoCustomers ? (
          <div className="details-block">
            <div className="details-label">סוג החשבון</div>
            <div className="details-value">{accountTypeText}</div>
          </div>
        ) : null}
        <div className="details-block">
          <div className="details-label">{customerName}</div>
          <div className="details-value">{customerFullName}</div>
        </div>
        <div className="details-block">
          <div className="details-label">{customerPhone}</div>
          <div className="details-value">{mobilePhone}</div>
        </div>

        {isTwoCustomers ? (
          <>
            <div className="details-block">
              <div className="details-label">שולח</div>
              <div className="details-value">{senderFullName}</div>
            </div>
            <div className="details-block">
              <div className="details-label">טלפון שולח</div>
              <div className="details-value">{senderMobilePhone}</div>
            </div>
          </>
        ) : null}

        <div className="details-block">
          <div className="details-label">סכום הזמנה</div>
          <div className="details-value">{orderTotalPrice} ₪</div>
        </div>
      </>
    )
  }

  render() {
    const {
      stationSelected,
      lockerSelected,
      lockersSelectOptions,
      isSuccessful,
      isFailed,
      isLoading,
      order,
      validationMessage,
    } = this.state
    const { stationsOptions } = this.props

    return (
      <div className="order-relocate-wrapper">
        <H1 title={orderRelocationText} />
        <div className="order-details">
          {order || isLoading ? this.renderOrder(order) : "ההזמנה לא נמצאה"}
        </div>
        <form
          className="form-inline"
          id="relocate-order-form"
          onSubmit={this.handleRelocateOrderRequest}
        >
          <Select
            placeholder={stationText}
            value={stationSelected}
            options={stationsOptions}
            onChange={(itm) => this.handleStationsFilterChange(itm)}
            classNamePrefix="base-select"
          />
          <br />
          <Select
            placeholder={lockerText}
            value={lockerSelected}
            options={lockersSelectOptions}
            onChange={(itm) => this.handleLockerSelectChange(itm)}
            classNamePrefix="base-select"
          />
          <div className="validation-message">{validationMessage}</div>

          <BlueButton extraClass="relocate-btn" type="submit">
            {relocateButtonText + (lockerSelected ? lockerSelected.label : "")}
          </BlueButton>
        </form>

        <NotificationModal
          show={isSuccessful}
          title={"עידכון מקום הזמנה"}
          text={"מיקום הזמנה בלוקר עודכן בהצלחה"}
          onOkClick={(e) => this.setState({ isSuccessful: false })}
        />
        <NotificationModal
          show={isFailed}
          title={"עידכון מקום הזמנה"}
          text={"מיקום הזמנה בלוקר נכשל"}
          onOkClick={(e) => this.setState({ isFailed: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ selectOptions, errors, stationsList }) => {
  const { orderTypes, lockerStatuses, accountTypes } = selectOptions
  const { unauthorized } = errors
  const { stationsItemSelectList } = stationsList
  const stationsOptions = stationsItemSelectList

  return {
    unauthorized,
    stationsOptions,
    lockerStatuses,
    orderTypes,
    accountTypes,
  }
}

export default connect(mapStateToProps, {
  fetchLockerStatusSelectOptions,
  fetchStationSelectOptions,
  setLockersStation,
  fetchOrderTypes,
  fetchAccountTypes,
})(RelocateOrderPage)
