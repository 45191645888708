import React from "react"
import "./CertificateOrdersTable.scss"
import { getCurrentDateTime } from "../../../../../utils"

const CertificateOrdersTable = ({ ordersList, accountTypesList }) => {
  const showTitle = (order) => {
    if (!Array.isArray(accountTypesList)) {
      return null
    }
    if (order.orderStatus === 16) {
      const foundObject = accountTypesList?.find(
        (item) => item.value === order.accountType
      )
      if (!foundObject) {
        return null
      }
      if (parseInt(foundObject.value, 10) == 29) {
        return foundObject.label
      }
      return foundObject.label
    }
    if (order.orderStatus === 3) {
      return order?.routeName
    }
    if (order.orderStatus === 17) {
      return order?.serviceSupplier?.name
    }
  }

  return (
    <div className="CertificateOrdersTable">
      <div className="table-name"> {showTitle(ordersList[0])}</div>
      <div className="print-date">
        <div>תאריך הדפסה: </div>
        <div>{getCurrentDateTime()}</div>
      </div>
      <table className="shipping-list">
        <thead>
          <tr>
            <th>מספר הזמנה</th>
            <th>מספר חבילה</th>
            <th>מספר חיצוני</th>
            <th>שם לקוח</th>
            <th>שם עמדה</th>
            <th>כתובת</th>
          </tr>
        </thead>
        <tbody>
          {ordersList.map((element, index) => (
            <tr key={index}>
              <td>{element.orderNumber}</td>
              <td>{element.packageNumber}</td>
              <td>{element.externalOrderNumber}</td>
              <td>
                {element.customerFullName
                  ? element.customerFullName
                  : `${element.customer?.firstName} ${element.customer?.lastName}`}
              </td>
              <td>{element.branchName}</td>
              <td>{element.stationAddress}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CertificateOrdersTable
