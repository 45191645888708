export const getSelectLockerStatusFromArray = (list) => {
  if (list === null || list.length <= 0) {
    return []
  }

  const nameMapping = {
    0: "תקול",
    1: "לאיסוף",
    2: "מוכן",
    3: "ריק",
    4: "שמור",
  }

  const selectOptionsList = list.map((item, index) => {
    if (item.key !== undefined && item.value !== undefined) {
      return {
        value: item.key,
        labelEn: item.value,
        label: nameMapping[item.value],
      }
    } else {
      return { value: index, label: nameMapping[index], labelEn: item }
    }
  })

  return selectOptionsList
}
