import React, { useEffect, useState } from "react"

import {
  BlueButton,
  SelectVal,
  Editable,
  SelectValPassive,
} from "../../../../../../common"
import { proccesesTohebrew } from "../../../../../../../utils/EnumsAndConsts"
import "./style.css"

const OrderItemsListItm = ({
  item,
  itemNamesArr,
  colorsArr,
  patternsArr,
  onItemUpdate,
  onItemDelete,
  optionalPrices,
  orderStatus,
  isPayed,
}) => {
  const {
    id,
    itemName,
    itemNumber,
    itemPrice,
    notes,
    orderId,
    orderNumber,
    processName,
    quantity,
    color,
    pattern,
    isEditable,
  } = item

  const [defaultItemNameIndex, setItemNameDefaulIndex] = useState(0)
  const [defaultQuantity, setDefaultQuantity] = useState(0)

  useEffect(() => {
    if (quantity) {
      setDefaultQuantity(quantity)
    }
  }, [])

  useEffect(() => {
    if (optionalPrices?.length > 1 && isEditable) {
      if (processName == "laundry") {
        setItemNameDefaulIndex(1)
        onItemNameSelected(
          `1 ק""ג (מינימום 5 ק""ג)`,
          1,
          item,
          onItemUpdate,
          optionalPrices,
          quantity
        )
      }
    }
  }, [optionalPrices.length])

  useEffect(() => {
    if (
      processName == "laundry" &&
      orderStatus != 6 &&
      orderStatus != 5 &&
      isPayed == false
    ) {
      const handleTimeout = setTimeout(() => {
        const validatedValue = Math.max(parseInt(defaultQuantity) || 0, 5)
        setDefaultQuantity(validatedValue)
        onItemUpdate({ ...item, quantity: validatedValue })
      }, 500)
      return () => clearTimeout(handleTimeout)
    }
  }, [defaultQuantity])

  const onQuantityChange = (e) => {
    setDefaultQuantity(e.target.value)
    onItemUpdate({ ...item, quantity: e.target.value })
  }

  return (
    <div className="ewq-table-item">
      <div className="ewq-table-itm-elem">{proccesesTohebrew(processName)}</div>
      <div className="ewq-table-itm-elem">
        <SelectValPassive
          show={processName !== "Compensation"}
          items={itemNamesArr}
          onItemSelected={(itemName, index) =>
            onItemNameSelected(
              itemName,
              index,
              item,
              onItemUpdate,
              optionalPrices,
              quantity
            )
          }
          valueIndex={
            defaultItemNameIndex
              ? defaultItemNameIndex
              : itemNamesArr.indexOf(itemName)
          }
        />
      </div>
      <div className="ewq-table-itm-elem">
        <SelectValPassive
          show={processName !== "Compensation"}
          items={colorsArr}
          onItemSelected={(color) => onItemUpdate({ ...item, color })}
          // preSelectedIndex={colorsArr.indexOf(color)}
          valueIndex={colorsArr.indexOf(color)}
        />
      </div>
      {/* <div className="ewq-table-itm-elem">
        <SelectValPassive
          show={processName !== "Compensation"}
          items={patternsArr}
          onItemSelected={(pattern) => onItemUpdate({ ...item, pattern })}
          // preSelectedIndex={patternsArr.indexOf(pattern)}
          valueIndex={patternsArr.indexOf(pattern)}
        />
      </div> */}
      <div className="ewq-table-itm-elem">
        <Editable
          value={defaultQuantity}
          onChange={(e) => onQuantityChange(e)}
          disabled={processName === "Compensation"}
        />
      </div>
      {/* <div className='ewq-table-itm-elem'>
                מס"ד
                </div> */}
      <div className="ewq-table-itm-elem">
        <Editable
          value={itemPrice}
          type="number"
          extraClass="ewq-table-itm-price"
          onChange={(e) =>
            onItemUpdate({
              ...item,
              itemPrice:
                parseFloat(e.target.value) || e.target.value.endsWith(".")
                  ? e.target.value
                  : 0,
            })
          }
          disabled={processName === "Compensation"}
        />
      </div>
      {/* <div className="ewq-table-itm-elem">
        <Editable
          value={weightQuantity}
          type="number"
          onChange={(e) =>
            onItemUpdate({
              ...item,
              weightQuantity: parseFloat(e.target.value) || 0,
            })
          }
          disabled={processName === "Compensation"}
        />
      </div> */}
      <div className="ewq-table-itm-elem ewq-elem-flex-2">
        <Editable
          value={notes || ""}
          title={notes || ""}
          onChange={(e) => onItemUpdate({ ...item, notes: e.target.value })}
        />
      </div>
      <div className="ewq-table-itm-elem">
        <BlueButton
          show={processName !== "Compensation"}
          extraClass="ewq-table-elem-btn"
          onClick={(e) => onItemDelete(item)}
        >
          מחק פריט
        </BlueButton>
      </div>
    </div>
  )
}

const onItemNameSelected = (
  itemName,
  index,
  item,
  onItemUpdate,
  optionalPrices,
  quantity
) => {
  const qnty = 1 //parseInt(quantity) || 1;
  const itemPrice = optionalPrices[index] * qnty
  item.itemPrice = itemPrice
  item.itemName = itemName
  item.itemNumber = index
  onItemUpdate(item)
}

export default OrderItemsListItm
