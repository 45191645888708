import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import {
  fetchAllStationData,
  fetchOrderTypes,
  fetchAllOrderStatuses,
  setOrdersSearch,
  fetchAccountTypes,
  fetchRoutes,
} from "../../../actions"
import { fetchIfEmpty } from "../../../utils"
import { paymentVersionList } from "../../../utils/EnumsAndConsts"
import {
  InputFiled,
  InputDatePicker,
  SelectMultiAutocomplete,
  BlueButton,
} from "../../common"
import NotificationModal from "../../Modals/NotificationModal"
import "./style.css"

const searchFromDateText = "מתאריך"
const searchToDateText = "עד תאריך"
const IsPaidArray = [
  { value: 0, label: "הצג את הכל (שולם ולא שולם)" },
  { value: 1, label: "הצג את ההזמנות ששולמו בלבד" },
  { value: 2, label: "הצג את ההזמנות שלא שולמו בלבד" },
]

const IsBleArray = [
  { value: 0, label: "כל העמדות (BLE ולא BLE)" },
  { value: 1, label: "עמדות לא BLE" },
  { value: 2, label: "עמדות BLE" },
]
const IsMonthlyPaymentArray = [
  { value: 0, label: "כל סוגי תשלום" },
  { value: 1, label: "תשלום רגיל" },
  { value: 2, label: "תשלום חודשי" },
]

const IsTrackingArray = [
  { value: 0, label: "כל ההזמנות (מעקב)" },
  { value: 1, label: "הזמנות במעקב" },
  { value: 2, label: "הזמנות לא במעקב" },
]

class OrdersSearchFilterer extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      defaultStatuses: [],
      defaultOrderTypes: [],
      defaultStations: [],

      defaultIsPayed: IsPaidArray[0],
      //defaultIsBLE: IsBleArray[0],
      defaultPaymentVersion: paymentVersionList[0],
      defaultIsMonthlyPayment: IsMonthlyPaymentArray[0],
      defaultIsTracking: IsTrackingArray[0],
      defaultAccount: [],
      defaultRoute: null,
      defaultSourceRoute: null,
      routesListLocal: [],
      showDateCantBeNull: false,
      search: {
        searchText: "",
        stations: [],
        statuses: [],
        orderTypes: [],
        accountTypes: [],
        routeId: null,
        sourceRouteId: null,
        fromDate: "",
        toDate: "",
        isPayed: null,
        isMonthlyPayment: null,
        stationPaymentVersion: null,
        pageStep: 50,
        page: 1,
      },
    }

    this.state = this.INIT_STATE
    this.handleDateChange = this.handleDateChange.bind(this)
    this.setDefaultsAndSearch = this.setDefaultsAndSearch.bind(this)
    this.cleanSearch = this.cleanSearch.bind(this)
    this.updateIsPaidFilterStatus = this.updateIsPaidFilterStatus.bind(this)
    this.updateIsMonthlyPayment = this.updateIsMonthlyPayment.bind(this)
  }

  componentWillMount() {
    let { search } = this.props
    const isEmptySearch =
      JSON.stringify(search) === JSON.stringify(this.INIT_STATE.search)

    if (isEmptySearch) {
      search["fromDate"] = this.getDefaultFromDate()
    }
    this.setDefaultsAndSearch(search)

    if (isEmptySearch) {
      this.props.setOrdersSearch(search)
    }
  }

  componentDidMount() {
    fetchIfEmpty(this.props.allStationsArr, this.props.fetchAllStationData)
    fetchIfEmpty(this.props.orderTypes, this.props.fetchOrderTypes)
    fetchIfEmpty(this.props.statusesArr, this.props.fetchAllOrderStatuses)
    fetchIfEmpty(this.props.accountTypes, this.props.fetchAccountTypes)
    fetchIfEmpty(this.props.routesList, this.props.fetchRoutes)
  }

  componentWillReceiveProps(newProps) {
    let { search } = newProps

    const isEmptySearch =
      JSON.stringify(search) === JSON.stringify(this.INIT_STATE.search)
    const isDataChanged =
      JSON.stringify(search) !== JSON.stringify(this.state.search)

    if (isEmptySearch) {
      search["fromDate"] = this.getDefaultFromDate()
    }

    if (isDataChanged) {
      this.setDefaultsAndSearch(search)
    }

    if (isEmptySearch) {
      this.props.setOrdersSearch(search)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { routesList } = this.props

    if (this.state.routesListLocal?.length == 0) {
      const combinedOptions = [
        {
          value: null,
          label: "כל המסלולים",
        },
        ...routesList,
      ]
      this.setState({
        routesListLocal: combinedOptions,
      })
    }
  }

  setDefaultsAndSearch(search) {
    const {
      ordersStatusesArrSorted,
      stationSelectList,
      orderTypes,
      formattedStationsNames,
      accountTypes,
      routesList,
    } = this.props

    const routesListLocal = [
      {
        value: null,
        label: "כל המסלולים",
      },
      ...routesList,
    ]

    const defaultStatuses = search.statuses.map((element) => {
      return { label: ordersStatusesArrSorted[element], value: element }
    })

    const defaultOrderTypes = search.orderTypes.map((element) => {
      return { label: orderTypes[element], value: element }
    })
    const defaultAccount = search.accountTypes?.map((element) => {
      return { label: accountTypes[element], value: element }
    })
    const defaultStations = search.stations.map((element) => {
      const station = stationSelectList.filter((s) => s.value == element)[0]
      const index = formattedStationsNames.indexOf(station.label)
      return { label: station.label, value: index }
    })

    const matchingSourceRoute = routesList.find(
      (route) => route.value === search.sourceRouteId
    )
    const defaultSourceRoute = matchingSourceRoute
      ? { label: matchingSourceRoute.label, value: matchingSourceRoute.value }
      : null

    const matchingRoute = routesList.find(
      (route) => route.value === search.routeId
    )
    const defaultRoute = matchingRoute
      ? { label: matchingRoute.label, value: matchingRoute.value }
      : null

    const defaultIsPayed = IsPaidArray[this.getIsPaidValueIndex(search.isPayed)]
    const defaultIsMonthlyPayment =
      IsMonthlyPaymentArray[
        this.getIsMonthlyPymentValueIndex(search.isMonthlyPayment)
      ]
    const defaultPaymentVersion = paymentVersionList[search.paymentVersion]
    const defaultIsTracking =
      IsTrackingArray[this.getIsTrackingValueIndex(search.isTracking)]

    this.setState({
      search,
      defaultOrderTypes,
      defaultStatuses,
      defaultStations,
      defaultIsPayed,
      defaultAccount,
      defaultRoute,
      defaultSourceRoute,
      defaultIsMonthlyPayment,
      defaultPaymentVersion,
      routesListLocal,
      defaultIsTracking,
    })
  }

  getDefaultFromDate() {
    let defaultDate = new Date()
    let newMonth = defaultDate.getMonth() - 3

    if (newMonth <= 0) {
      newMonth = newMonth <= 0 ? newMonth + 12 : newMonth
      defaultDate.setMonth(newMonth)
      defaultDate.setFullYear(defaultDate.getFullYear() - 1)
    } else {
      defaultDate.setMonth(newMonth)
    }

    return this.formatDateForPicker(defaultDate)
  }

  formatDateForPicker(date) {
    let day = date.getDate()
    if (day < 10) {
      day = "0" + day
    }

    let month = date.getMonth() + 1
    if (month < 10) {
      month = "0" + month
    }
    return date.getFullYear() + "-" + month + "-" + day
  }

  updateActiveTypes(item) {
    let { search, defaultOrderTypes } = this.state
    defaultOrderTypes = item
    search.orderTypes = item.map((itm) => {
      return itm.value
    })
    search.page = 1
    this.setState({ search, defaultOrderTypes })
    this.props.onSearchFilterChanged(search)
  }

  updateActiveStauses(item) {
    let { search, defaultStatuses } = this.state
    defaultStatuses = item
    if (
      search == undefined ||
      search.statuses == undefined ||
      item == undefined
    ) {
      return
    }
    search.statuses = item.map((itm) => {
      return itm.value
    })
    search.page = 1

    this.setState({ search, defaultStatuses })
    this.props.onSearchFilterChanged(search)
  }

  updateActiveStations(item) {
    let { search, defaultStations } = this.state
    defaultStations = item
    const { allStationsArr } = this.props
    search.stations = item.map((i) => allStationsArr[i.value].stationId)
    search.page = 1
    this.setState({ search, defaultStations })
    this.props.onSearchFilterChanged(search)
  }

  updateActiveField(fieldName, value) {
    //TODO VALIDATION
    let { search } = this.state
    search[fieldName] = value
    search.page = 1
    this.setState({ search })
    this.props.onSearchFilterChanged(search)
  }

  updateIsPaidFilterStatus(item) {
    let { search, defaultIsPayed } = this.state
    switch (item.value) {
      case 0:
        search.isPayed = null
        break
      case 1:
        search.isPayed = true
        break
      case 2:
        search.isPayed = false
        break
      default:
        search.isPayed = null
        break
    }

    defaultIsPayed = item
    search.page = 1
    this.setState({ search, defaultIsPayed })

    this.props.onSearchFilterChanged(search)
  }

  getIsPaidValueIndex(searchValue) {
    switch (searchValue) {
      case null:
        return 0
      case true:
        return 1
      case false:
        return 2
      default:
        return 0
    }
  }

  updateIsTRackingFilterStatus(item) {
    let { search, defaultIsTracking } = this.state
    switch (item.value) {
      case 0:
        search.isTracking = null
        break
      case 1:
        search.isTracking = true
        break
      case 2:
        search.isTracking = false
        break
      default:
        search.isTracking = null
        break
    }

    defaultIsTracking = item
    search.page = 1
    this.setState({ search, defaultIsTracking })

    this.props.onSearchFilterChanged(search)
  }
  getIsTrackingValueIndex(searchValue) {
    switch (searchValue) {
      case null:
        return 0
      case true:
        return 1
      case false:
        return 2
      default:
        return 0
    }
  }
  updatePaymentVersion(item) {
    let { search, defaultPaymentVersion } = this.state
    if (item.value == 0) {
      search.stationPaymentVersion = null
    } else {
      search.stationPaymentVersion = item.value
    }

    defaultPaymentVersion = item
    search.page = 1
    this.setState({ search, defaultPaymentVersion })

    this.props.onSearchFilterChanged(search)
  }
  updateIsMonthlyPayment(item) {
    let { search, defaultIsMonthlyPayment } = this.state
    switch (item.value) {
      case 0:
        search.isMonthlyPayment = null
        break
      case 1:
        search.isMonthlyPayment = false
        break
      case 2:
        search.isMonthlyPayment = true
        break
      default:
        search.isMonthlyPayment = null
        break
    }

    defaultIsMonthlyPayment = item
    search.page = 1
    this.setState({ search, defaultIsMonthlyPayment })

    this.props.onSearchFilterChanged(search)
  }

  getIsMonthlyPymentValueIndex(searchValue) {
    switch (searchValue) {
      case null:
        return 0
      case false:
        return 1
      case true:
        return 2
      default:
        return 0
    }
  }

  updateAccountTypeFilter(item) {
    let { search, defaultAccount } = this.state
    defaultAccount = item
    search.accountTypes = item.map((itm) => {
      return itm.value
    })
    search.page = 1
    this.setState({ search, defaultAccount })
    this.props.onSearchFilterChanged(search)
  }

  updateRouteFilter(item) {
    let { search, defaultRoute } = this.state
    defaultRoute = item
    search.routeId = item?.value
    search.page = 1
    this.setState({ search, defaultRoute })
    this.props.onSearchFilterChanged(search)
  }

  updateSourceRouteFilter(item) {
    let { search, defaultSourceRoute } = this.state
    defaultSourceRoute = item
    search.sourceRouteId = item?.value
    search.page = 1
    this.setState({ search, defaultSourceRoute })
    this.props.onSearchFilterChanged(search)
  }

  handleDateChange(inputName, event) {
    let { search } = this.state
    if (inputName === "fromDate" && event.target.value === "") {
      this.setState({ showDateCantBeNull: true })
      return
    }
    search[inputName] = event.target.value
    search.page = 1
    this.setState({ search })
    this.props.onSearchFilterChanged(search)
  }

  cleanSearch() {
    this.props.setOrdersSearch({
      searchText: "",
      stations: [],
      sourceRouteId: null,
      routeId: null,
      statuses: [],
      orderTypes: [],
      accountTypes: [],
      routesList: [],
      fromDate: "",
      toDate: "",
      isPayed: null,
      isMonthlyPayment: null,
      stationPaymentVersion: 0,
      pageStep: 50,
      page: 1,
    })
    this.setState({
      defaultStatuses: [],
      defaultOrderTypes: [],
      defaultStations: [],
      defaultSourceRoute: null,
      defaultRoute: null,
      defaultIsPayed: IsPaidArray[0],
      defaultIsMonthlyPayment: IsMonthlyPaymentArray[0],
      defaultPaymentVersion: paymentVersionList[0],
      search: {
        searchText: "",
        stations: [],
        sourceRouteId: null,
        routeId: null,
        statuses: [],
        orderTypes: [],
        fromDate: "",
        toDate: "",
        isPayed: null,
        isMonthlyPayment: null,
        stationPaymentVersion: null,
        pageStep: 50,
        page: 1,
      },
    })
  }

  render() {
    const {
      statusesArr,
      formattedStationsNames,
      orderTypes,
      ordersStatusesArrSorted,
      accountTypes,
      routesList,
      isLoading,
    } = this.props

    const {
      search,
      defaultIsPayed,
      //defaultIsBLE,
      defaultIsMonthlyPayment,
      defaultPaymentVersion,
      defaultRoute,
      defaultSourceRoute,
      defaultAccount,
      routesListLocal,
      defaultIsTracking,
    } = this.state
    const { searchText, fromDate, toDate } = search

    return (
      <>
        <div className="search-order-filterer ">
          <div className="line-wrapper">
            <InputFiled
              onChange={(e) =>
                this.updateActiveField("searchText", e.target.value)
              }
              onKeyPress={(e) => this.props.filterEnterAndSubmit(e)}
              extraClass="search-input"
              placeholder="חיפוש הזמנה/לקוח"
              value={searchText}
              alt="ניתן לחפש הזמנה לפי מספר הזמנה או מספר חבילה. ניתן לחפש לקוח לפי טלפון או שם."
              icon="magnifying_glass"
            />
            {/*<div className='label'>
                        {searchFromDateText}
                    </div> */}

            <InputDatePicker
              name="fromDate"
              date={fromDate}
              onChange={this.handleDateChange}
              placeholder={searchFromDateText}
            />
            {/* <div className='label'>
                        {searchToDateText}
                    </div>*/}
            {/* */}

            <InputDatePicker
              name="toDate"
              date={toDate}
              onChange={this.handleDateChange}
              placeholder={searchToDateText}
            />

            <div className="select-wrapper">
              <Select
                classNamePrefix="autocomplete-select"
                value={defaultIsPayed}
                options={IsPaidArray}
                onChange={(itm) => this.updateIsPaidFilterStatus(itm)}
              />
            </div>
            <div className="select-wrapper">
              <Select
                classNamePrefix="autocomplete-select"
                value={defaultIsTracking}
                options={IsTrackingArray}
                onChange={(itm) => this.updateIsTRackingFilterStatus(itm)}
              />
            </div>
            <div className="select-wrapper">
              <Select
                classNamePrefix="autocomplete-select"
                value={defaultIsMonthlyPayment}
                options={IsMonthlyPaymentArray}
                onChange={(itm) => this.updateIsMonthlyPayment(itm)}
              />
            </div>
            <div className="select-wrapper">
              <Select
                placeholder="גרסת העמדה"
                classNamePrefix="autocomplete-select"
                value={defaultPaymentVersion}
                options={paymentVersionList}
                onChange={(itm) => this.updatePaymentVersion(itm)}
              />
            </div>
          </div>
          <div className="line-wrapper">
            <SelectMultiAutocomplete
              placeholder="סוג הזמנה"
              items={orderTypes}
              value={this.state.defaultOrderTypes}
              onChangeHandler={(itm) => this.updateActiveTypes(itm)}
              extraClassName="type-list"
            />
            <SelectMultiAutocomplete
              placeholder="סטטוס הזמנה"
              items={ordersStatusesArrSorted}
              value={this.state.defaultStatuses}
              onChangeHandler={(itm) => this.updateActiveStauses(itm)}
            />
            <SelectMultiAutocomplete
              placeholder="עמדה"
              items={formattedStationsNames}
              value={this.state.defaultStations}
              onChangeHandler={(itm) => this.updateActiveStations(itm)}
              extraClassName="sations-list"
            />

            <SelectMultiAutocomplete
              placeholder="סוג חשבון"
              items={accountTypes}
              value={defaultAccount}
              onChangeHandler={(itm) => this.updateAccountTypeFilter(itm)}
              extraClassName="type-list"
            />
            <div className="select-wrapper">
              <Select
                classNamePrefix="autocomplete-select"
                value={defaultRoute}
                placeholder="מסלול"
                options={routesListLocal}
                onChange={(itm) => this.updateRouteFilter(itm)}
              />
            </div>
            <div className="select-wrapper">
              <Select
                placeholder="מסלול מקור"
                classNamePrefix="autocomplete-select"
                value={defaultSourceRoute}
                options={routesListLocal}
                onChange={(itm) => this.updateSourceRouteFilter(itm)}
              />
            </div>
            <div className="search-btn-wrapper">
              <BlueButton
                extraClass={`${isLoading ? "disabled" : "search-btn"}`}
                onClick={(e) => this.props.searchOrdersClick()}
                tabIndex="0"
                disabled={isLoading}
              >
                חפש
              </BlueButton>
            </div>
          </div>
        </div>
        <NotificationModal
          type={"warning"}
          title={"התאריך שמנסים לבחור לא תקין"}
          show={this.state.showDateCantBeNull}
          text={" אנא בחר תאריך תקין. לא נתן לרוקן את השדה."}
          onOkClick={() => this.setState({ showDateCantBeNull: false })}
        />
      </>
    )
  }
}

const mapStateToProps = ({
  ordersStatuses,
  ordersList,
  stationsList,
  selectOptions,
  ordersSearch,
  routes,
}) => {
  const { ordersStatusesArr, ordersStatusesArrSorted } = ordersStatuses
  const { FiltererIsPaid } = ordersList
  const { search, isLoading } = ordersSearch
  const { allStationsArr, stationSelectList, formattedStationsNames } =
    stationsList
  const { orderTypes, accountTypes } = selectOptions
  const routesList = routes.routes

  return {
    statusesArr: ordersStatusesArr,
    ordersStatusesArrSorted,
    FiltererIsPaid,
    allStationsArr,
    stationSelectList,
    formattedStationsNames,
    orderTypes,
    search,
    accountTypes,
    routesList,
    isLoading,
  }
}

export default connect(mapStateToProps, {
  fetchAllStationData,
  fetchOrderTypes,
  setOrdersSearch,
  fetchAllOrderStatuses,
  fetchAccountTypes,
  fetchRoutes,
})(OrdersSearchFilterer)
