import React, { Component } from "react"
import { connect } from "react-redux"

import { SmallHeader, BlueButton, SelectCouponValPassive } from "../../common"
import Modal from "../Modal"
import { fetchActiveUserCoupons } from "../../../actions"

import "./style.css"

class SelectCouponModal extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    if (this.props.userId) {
      this.props.fetchActiveUserCoupons(this.props.userId, this.props.orderType)
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userId && this.props.userId != newProps.userId) {
      this.props.fetchActiveUserCoupons(newProps.userId, this.props.orderType)
    }
  }

  onItemSelected(index) {
    const { couponsActiveList } = this.props
    const selectedCoupon = couponsActiveList[index]
    this.props.onSelectCoupon(selectedCoupon)
  }

  render() {
    const { show } = this.props

    if (!show) {
      return <noscript />
    }

    const { couponsActiveList, isLoading } = this.props

    if (!couponsActiveList || isLoading) {
      return (
        <Modal extraClass="cror-modal-wrap">
          <div className="cror-modal-container">
            <SmallHeader title={"הוספת קופון "} />
            <div className="cror-modal-inputs-wrapper">
              <div className="content-loading">טעינה...</div>
              <div className="cror-modal-status">
                <BlueButton
                  extraClass="cror-modal-button"
                  onClick={(e) => {
                    this.props.onClose()
                  }}
                >
                  סגור
                </BlueButton>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    if (couponsActiveList.length <= 0) {
      return (
        <Modal extraClass="cror-modal-wrap">
          <div className="cror-modal-container">
            <SmallHeader title={"הוספת קופון "} />
            <div className="cror-modal-inputs-wrapper">
              <div className="content-message">אין קופונים זמינים במערכת.</div>
              <div className="cror-modal-status">
                <BlueButton
                  extraClass="cror-modal-button"
                  onClick={(e) => {
                    this.props.onClose()
                  }}
                >
                  סגור
                </BlueButton>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    return (
      <Modal extraClass="cror-modal-wrap">
        <div className="cror-modal-container">
          <SmallHeader title={"הוספת קופון "} />
          <div className="cror-modal-inputs-wrapper">
            <div className="cror-modal-title">
              <div className="title"> אנא בחר קופון מהרשימה: </div>
            </div>

            <SelectCouponValPassive
              items={couponsActiveList}
              onItemSelected={(val, index) => this.onItemSelected(index)}
              extraClass="order-editor-status-val"
            />

            <div className="cror-modal-status">
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => {
                  this.props.onClose()
                }}
              >
                סגור
              </BlueButton>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProp = ({ coupons }) => {
  const { couponsActiveList, isLoading } = coupons
  return {
    couponsActiveList,
    isLoading,
  }
}

export default connect(mapStateToProp, {
  fetchActiveUserCoupons,
})(SelectCouponModal)
