import React from "react"

import Header from "../../../../../common/List/Header"
import UserHistoryItem from "./UserHistoryItem"
import { H1 } from "../../../../../common/H1"
import { Spinner } from "../../../../../common/Spinner"

import "./style.css"

const UserHistoryList = ({
  headers,
  items,
  gravity,
  itemMap,
  isLoading,
  isError,
}) => {
  return (
    <div className={`ul-list-wrapper user-history`}>
      <Header titles={headers} gravity={gravity} />
      <div className="ul-list-items-wrapper">
        {renderItems(items, gravity, itemMap, isLoading, isError)}
      </div>
    </div>
  )
}

const renderItems = (items, gravity, itemMap, isLoading, isError) => {
  if (isError) {
    return (
      <H1 className="not-valid-search" title="אנא בדוק את הערך של שדה חיפוש" />
    )
  }

  if (isLoading) {
    return (
      <div className="users-list">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (!items || items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }
  console.log(items)
  return items.map((itm, index) => {
    return (
      <UserHistoryItem
        key={index}
        item={itm}
        itemMap={itemMap}
        gravity={gravity}
        isLast={index === items.length - 1}
      />
    )
  })
}

export default UserHistoryList
