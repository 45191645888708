import React from "react"
import { BlueButton } from "../../../../common"
import "./style.scss"

const DeliverySackMenu = (props) => {
  return (
    <section className="WHOperator delivery-sack-menu ">
      <div>
        <div className="sacks-title">סריקה תעודות </div>
        <div className="delivery_menu_holder">
          <div className="btn_delivery_holder">
            <BlueButton
              extraClass="btn_delivery sacks recive"
              onClick={(e) => props.history.push("/SacksScreen", "exelot")}
            >
              <p className="block-text">סריקת תעודת משלוח להחזרות</p>
            </BlueButton>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DeliverySackMenu
