import React from "react"
import "./StationTasksScreen.scss"
import { Route, Link } from "react-router-dom"
import StationTasksList from "./StationTasksList/StationTasksList.js"
import EditStationTask from "./EditStationTask/EditStationTask.js"
import NewStationTask from "./NewStationTask/NewStationTask.js"

const StationTasksScreen = (props) => {
  return (
    <section className="stationTasksScreen">
      <nav>
        <ul>
          <li>
            <Link to={`${props.match.url}`}>רשימת משימות</Link>
          </li>
          <li>
            <Link to={`${props.match.url}/create`}>יצירת משימה חדשה</Link>
          </li>
        </ul>
      </nav>
      <main>
        <Route
          exact
          path={`${props.match.path}`}
          component={StationTasksList}
        />
        <Route path={`${props.match.path}/edit`} component={EditStationTask} />
        <Route path={`${props.match.path}/create`} component={NewStationTask} />
      </main>
    </section>
  )
}

export default StationTasksScreen
