import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { BlueButton } from "../../../../common"
import {
  getStationTasksHistoryByTaskId,
  clearStationTaskHistory,
  getStationTaskPhoto,
  clearGetStationTaskPhoto,
} from "../../../../../actions"
import { getFormatedDate } from "../../../../../utils"
import NotificationModal from "../../../../Modals/NotificationModal"
import "./StationTaskHistory.scss"

function StationTaskHistory(props) {
  const [isShowHistoryContent, setIsShowHistoryContent] = useState(false)
  useEffect(() => {
    props.getStationTasksHistoryByTaskId(props.stationTask?.id)
    return () => {
      props.clearStationTaskHistory()
    }
  }, [])

  useEffect(() => {
    if (props.stationTaskHistory.length > 0) {
      setIsShowHistoryContent(true)
    }
  }, [props.getStationTaskHistorySuccess])

  useEffect(() => {
    if (props.getStationTaskPhotoSuccess) {
      const newPage = window.open("", "_blank")
      newPage.document.write(`
      <html>
        <head>
          <title>New Page</title>
        </head>
        <body>
          <img src="data:image/jpeg;base64,${
            props.stationTaskPhoto || ""
          }" alt="Image" />
        </body>
      </html>
    `)
    }
    props.clearGetStationTaskPhoto()
  }, [props.getStationTaskPhotoSuccess])

  return (
    <section className="stationTaskHistory">
      <div className="modal">
        {isShowHistoryContent ? (
          <div>
            <div className="title">
              היסטוריה של משימה {props.stationTask.taskNumber}{" "}
            </div>
            <div className="table-container">
              <table class="history-table">
                <thead>
                  <tr>
                    <th>הושלם</th>
                    <th>פרטי המשימה</th>
                    <th>פרטי המשימה (English)</th>
                    <th>תאריך עדכון</th>
                    <th>מבצע</th>
                    <th>הערה</th>
                    <th>תמונה</th>
                  </tr>
                </thead>
                <tbody>
                  {props.stationTaskHistory?.map((history, index) => (
                    <tr key={index}>
                      <td>
                        {history.isComplete ? (
                          <i class="fas fa-check"></i>
                        ) : (
                          <i class="fas fa-times"></i>
                        )}
                      </td>
                      <td>{history.taskDetails}</td>
                      <td>{history.taskDetails_en}</td>
                      <td>{getFormatedDate(history.createdDate)}</td>
                      <td>
                        {history.firstName || history.lastName
                          ? history.firstName + " " + history.lastName
                          : "לא ידוע"}
                      </td>
                      <td>{history.note}</td>
                      <td>
                        {history?.imageFileName ? (
                          <BlueButton
                            extraClass="btn-pic"
                            onClick={() =>
                              props.getStationTaskPhoto(
                                history.stationTaskId,
                                history.imageFileName
                              )
                            }
                          >
                            הצג תמונה
                          </BlueButton>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="no-history">אין היסטוריה למשימה זו</div>
        )}
        <div className="btn-holder">
          <BlueButton onClick={() => props.closeClick()}>סגור</BlueButton>
        </div>
      </div>
      <NotificationModal
        show={props.getStationTaskPhotoFail}
        type="error"
        title="שגיאה"
        text="אירעה שגיאה בקבלת התמונה"
        onOkClick={() => props.clearGetStationTaskPhoto()}
      />
    </section>
  )
}

const mapStateToProps = ({ stationTasks }) => {
  const {
    stationTaskHistory,
    getStationTaskHistorySuccess,
    getStationTaskHistoryFail,
    getStationTaskPhotoSuccess,
    getStationTaskPhotoFail,
    stationTaskPhoto,
  } = stationTasks

  return {
    stationTaskHistory,
    getStationTaskHistorySuccess,
    getStationTaskHistoryFail,
    getStationTaskPhotoSuccess,
    getStationTaskPhotoFail,
    stationTaskPhoto,
  }
}
export default connect(mapStateToProps, {
  getStationTasksHistoryByTaskId,
  clearStationTaskHistory,
  getStationTaskPhoto,
  clearGetStationTaskPhoto,
})(StationTaskHistory)
