export const convertToSelectList = (items) => {
  let options = []

  if (!items || items === []) {
    return options
  }

  if (
    items.length > 0 &&
    items[0].label !== undefined &&
    items[0].value !== undefined
  ) {
    return items
  }

  if (
    items.length > 0 &&
    (items[0].label === undefined || items[0].value === undefined)
  ) {
    options = items.map((itm, index) => {
      return { value: index, label: itm }
    })
    return options
  }

  let itemsObj = Object.assign(items)
  for (var property in itemsObj) {
    options[Number(property)] = {
      value: Number(property),
      label: itemsObj[property],
    }
  }
  return options
}

export const districtsConvertToSelectList = (items) => {
  let options = []

  if (!items || items === []) {
    return options
  }

  if (
    items.length > 0 &&
    items[0].label !== undefined &&
    items[0].value !== undefined
  ) {
    return items
  }

  if (
    items.length > 0 &&
    (items[0].label === undefined || items[0].value === undefined)
  ) {
    options = items.map((itm, index) => {
      return { value: itm.districtId, label: itm.districtName }
    })
    return options
  }
}
