import {
  ON_STATION_TASKS_LOADING,
  ON_GET_STATION_TASKS_FAIL,
  ON_GET_STATION_TASKS_SUCCESS,
  ON_SELECT_EDIT_TASK,
  ON_UPDATE_STATION_TASKS_SUCCESS,
  ON_UPDATE_STATION_TASKS_FAIL,
  ON_GET_STATION_TASK_HISTORY_SUCCESS,
  ON_GET_STATION_TASK_HISTORY_FAIL,
  ON_CREATE_STATION_TASKS_SUCCESS,
  ON_CREATE_STATION_TASKS_FAIL,
  CLEAR_STATION_TASKS,
  CLEAR_STATION_TASKS_HISTORY,
  ON_TOGGLE_IS_ACTIVE_TASKS_SUCCESS,
  ON_TOGGLE_IS_ACTIVE_TASKS_FAIL,
  ON_GET_STATION_TASK_PHOTO_SUCCESS,
  ON_GET_STATION_TASK_PHOTO_FAIL,
  CLEAR_GET_STATION_TASKS_PHOTO,
} from "../actions/types"

import {} from "../utils"

const INIT_STATE = {
  isLoading: false,
  stationTasksList: [],
  selectedStationTask: null,
  updateStationTaskSuccess: false,
  updateStationTaskFail: false,
  createStationTaskSuccess: false,
  createStationTaskFail: false,
  stationTaskHistory: [],
  getStationTaskHistorySuccess: false,
  getStationTaskHistoryFail: false,
  toggleIsActiveTaskSuccess: false,
  toggleIsActiveTaskFail: false,
  stationTaskPhoto: null,
  getStationTaskPhotoSuccess: false,
  getStationTaskPhotoFail: false,
}

const StationTasksReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_STATION_TASKS_LOADING:
      return { ...state, isLoading: true }
    case ON_GET_STATION_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stationTasksList: action.payload,
      }
    case ON_GET_STATION_TASKS_FAIL:
      return { ...state, isLoading: false }
    case ON_SELECT_EDIT_TASK:
      return { ...state, selectedStationTask: action.payload }
    case ON_UPDATE_STATION_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateStationTaskSuccess: true,
        updateStationTaskFail: false,
      }
    case ON_UPDATE_STATION_TASKS_FAIL:
      return {
        ...state,
        isLoading: false,
        updateStationTaskFail: true,
        updateStationTaskSuccess: false,
      }
    case ON_GET_STATION_TASK_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stationTaskHistory: action.payload,
        getStationTaskHistorySuccess: true,
        getStationTaskHistoryFail: false,
      }
    case ON_GET_STATION_TASK_HISTORY_FAIL:
      return {
        ...state,
        isLoading: false,
        getStationTaskHistorySuccess: false,
        getStationTaskHistoryFail: true,
      }
    case ON_CREATE_STATION_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createStationTaskSuccess: true,
        createStationTaskFail: false,
      }
    case ON_CREATE_STATION_TASKS_FAIL:
      return {
        ...state,
        isLoading: false,
        createStationTaskSuccess: false,
        createStationTaskFail: true,
      }
    case CLEAR_STATION_TASKS:
      return {
        ...state,
        isLoading: false,
        selectedStationTask: null,
        stationTaskHistory: [],
        updateStationTaskSuccess: false,
        updateStationTaskFail: false,
        createStationTaskSuccess: false,
        createStationTaskFail: false,
        toggleIsActiveTaskSuccess: false,
        toggleIsActiveTaskFail: false,
      }
    case CLEAR_STATION_TASKS_HISTORY:
      return {
        ...state,
        isLoading: false,
        stationTaskHistory: [],
        getStationTaskHistorySuccess: false,
        getStationTaskHistoryFail: false,
      }
    case ON_TOGGLE_IS_ACTIVE_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        toggleIsActiveTaskSuccess: true,
        toggleIsActiveTaskFail: false,
      }
    case ON_TOGGLE_IS_ACTIVE_TASKS_FAIL:
      return {
        ...state,
        isLoading: false,
        toggleIsActiveTaskSuccess: false,
        toggleIsActiveTaskFail: true,
      }
    case ON_GET_STATION_TASK_PHOTO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stationTaskPhoto: action.payload,
        getStationTaskPhotoSuccess: true,
        getStationTaskPhotoFail: false,
      }
    case ON_GET_STATION_TASK_PHOTO_FAIL:
      return {
        ...state,
        isLoading: false,
        getStationTaskPhotoSuccess: false,
        getStationTaskPhotoFail: true,
      }
    case CLEAR_GET_STATION_TASKS_PHOTO:
      return {
        ...state,
        isLoading: false,
        getStationTaskPhotoSuccess: false,
        getStationTaskPhotoFail: false,
      }
    default:
      return state
  }
}

export default StationTasksReducer
