import React, { Component } from "react"
import { connect } from "react-redux"
import { Route, Link } from "react-router-dom"
import CitiesPage from "./CitiesPage"
import EditCity from "./EditCity"
import NewCity from "./NewCity"
import "./style.scss"

class CitiesScreen extends Component {
  render() {
    return (
      <section className="city-section-container">
        <nav className="city-section-menu">
          <ul>
            <li className="city-section-menu-item">
              <Link to={`${this.props.match.url}`}>ערים</Link>
            </li>
            <li className="city-section-menu-item">
              <Link to={`${this.props.match.url}/create`}>עיר חדשה</Link>
            </li>
          </ul>
        </nav>
        <main className="city-section-body">
          <Route
            exact
            path={`${this.props.match.path}`}
            component={CitiesPage}
          />
          <Route path={`${this.props.match.path}/edit`} component={EditCity} />
          <Route path={`${this.props.match.path}/create`} component={NewCity} />
        </main>
      </section>
    )
  }
}

export default connect(null, {})(CitiesScreen)
