import {
  getValEnumFromIndex,
  ORDER_ISPAYED_ENUM,
  getStatusNameFromObj,
  getUserFullName,
  paymentVersionList,
} from "./"
import { getFormatedDate } from "./FormatedDate"
import { proccesesTohebrew } from "./EnumsAndConsts"

const US_DATE = "en-US"
const EN_DATE = "en-GB"
const IL_DATE = "he-IL"

export const getOrdersResponse = (response) => {
  const outputOrdersArr = response.map(
    ({
      id: orderId,
      stationId,
      orderNumber,
      stationNumber,
      orderType,
      orderStatus,
      orderDate,
      orderConfirmDate,
      customerId,
      firstName,
      lastName,
      email,
      mobilePhone,
      orderTotalPrice,
      branchName,
      isPayed,
      isMonthlyPayment,
      stationPaymentVersion,
      paymentDate,
      locationId,
      currentLocation,
      lockerNumber,
      orderItems: itms,
      packageNumber,
      couponId,
      couponName,
      serviceSupplierId,
      senderMobilePhone,
      isAllowedCompensation,
      isBLE,
      optionalWrongDoor,
      serviceSupplierName,
      accountType,
      bagsQuantity,
      externalOrderNumber2,
      orderItemsOriginal,
      routeColor,
      stationChangeState,
      trackingNote,
    }) => {
      const orderItems = itms.map(
        ({
          itemNumber,
          quantity: amount,
          processName,
          itemPrice,
          weightQuantity,
          notes,
        }) => {
          return {
            itemNumber,
            amount,
            processName,
            itemPrice,
            weightQuantity,
            notes,
          }
        }
      )

      return {
        orderId,
        orderNumber,
        stationId,
        stationNumber,
        orderType,
        orderStatus,
        orderDate,
        orderConfirmDate,
        customerId,
        firstName,
        lastName,
        email,
        mobilePhone,
        orderTotalPrice,
        branchName,
        isPayed,
        paymentDate,
        locationId,
        currentLocation,
        lockerNumber,
        orderItems,
        packageNumber,
        orderFormatDate: getFormatedDate(orderConfirmDate),
        //orderFormatStatus: getValEnumFromIndex(ORDER_STATUS_ENUM, orderStatus),
        //orderFromatType: getValEnumFromIndex(ORDER_TYPE_ENUM, orderType),
        orderFormatStatus: orderStatus,
        orderFromatType: orderType,
        orderFromatAccount: accountType,
        orderFormatIspayed: getValEnumFromIndex(ORDER_ISPAYED_ENUM, isPayed),
        isMonthlyPayment,
        stationPaymentVersion,
        userFormatFullName: getUserFullName(
          firstName ? firstName : "",
          lastName ? lastName : ""
        ),
        couponId,

        couponName,
        serviceSupplierId,
        senderMobilePhone,
        isAllowedCompensation,
        isBLE,
        optionalWrongDoor,
        serviceSupplierName,
        accountType,
        bagsQuantity,
        externalOrderNumber2,
        orderItemsOriginal,
        routeColor,
        stationChangeState,
        trackingNote,
      }
    }
  )

  return outputOrdersArr
}

export const getOrdersWithStatus = (response) => {
  const outputOrdersArr = response.map(
    ({
      id: orderId,
      stationId,
      orderNumber,
      stationNumber,
      orderType,
      orderStatus,
      orderDate,
      orderConfirmDate,
      customerId,
      firstName,
      lastName,
      email,
      mobilePhone,
      orderTotalPrice,
      branchName,
      isPayed,
      isMonthlyPayment,
      stationPaymentVersion,
      paymentDate,
      locationId,
      currentLocation,
      lockerNumber,
      orderItems: itms,
      packageNumber,
      couponId,
      couponName,
      serviceSupplierId,
      senderMobilePhone,
      isAllowedCompensation,
      isBLE,
      optionalWrongDoor,
      serviceSupplierName,
      accountType,
      bagsQuantity,
      externalOrderNumber2,
      orderItemsOriginal,
      routeColor,
      stationChangeState,
    }) => {
      const orderItems = itms.map(
        ({
          itemNumber,
          quantity: amount,
          processName,
          itemPrice,
          weightQuantity,
          notes,
        }) => {
          return {
            itemNumber,
            amount,
            processName,
            itemPrice,
            weightQuantity,
            notes,
          }
        }
      )
      return {
        orderId,
        orderNumber,
        stationId,
        stationNumber,
        orderType,
        orderStatus,
        orderDate,
        orderConfirmDate,
        customerId,
        firstName,
        lastName,
        email,
        mobilePhone,
        orderTotalPrice,
        branchName,
        isPayed,
        paymentDate,
        locationId,
        currentLocation,
        lockerNumber,
        orderItems,
        packageNumber,
        orderFormatDate: getFormatedDate(orderConfirmDate),
        orderFormatStatus: getStatusNameFromObj(orderStatus),
        // orderFromatType: getValEnumFromIndex(ORDER_TYPE_ENUM, orderType),
        orderFormatIspayed: getValEnumFromIndex(ORDER_ISPAYED_ENUM, isPayed),
        isMonthlyPayment,
        stationPaymentVersion,

        userFormatFullName: getUserFullName(
          firstName ? firstName : "",
          lastName ? lastName : ""
        ),
        couponId,

        couponName,
        serviceSupplierId,
        senderMobilePhone,
        isAllowedCompensation,
        isBLE,
        optionalWrongDoor,
        serviceSupplierName,
        accountType,
        bagsQuantity,
        externalOrderNumber2,
        orderItemsOriginal,
        routeColor,
        stationChangeState,
      }
    }
  )

  return outputOrdersArr
}

export const getReceptionOrdersResponse = (response) => {
  const outputOrdersArr = response.map(
    ({
      id: orderId,
      orderNumber,
      stationNumber,
      orderType,
      orderStatus,
      orderConfirmDate,
      firstName,
      lastName,
      mobilePhone,
      branchName,
      packageNumber,
      accountType,
      routeAreaCode,
    }) => {
      return {
        orderId,
        orderNumber,
        stationNumber,
        orderConfirmDate,
        mobilePhone,
        branchName,
        packageNumber,
        orderStatus,
        orderType,
        accountType,
        orderFormatDate: getFormatedDate(orderConfirmDate),
        orderFormatStatus: orderStatus,
        orderFromatAccount: accountType,
        orderFromatType: orderType,
        routeAreaCode: routeAreaCode,
        userFormatFullName: getUserFullName(
          firstName ? firstName : "",
          lastName ? lastName : ""
        ),
      }
    }
  )

  return outputOrdersArr
}

export const getOrderForPrinterService = (order) => {
  const {
    orderNumber,
    stationNumber,
    orderType,
    orderStatus,
    orderConfirmDate,
    firstName,
    lastName,
    mobilePhone,
    branchName,
    packageNumber,
    orderItems: itms,
    orderItemsOriginal,
    orderTotalPrice,
    routeName,
    routeAreaCode,
    isHighPriority,
    historyRecords,
    isFixedLocationBackoffice,
    storageSackId,
    isTracking,
  } = order

  const orderItems = itms.map(({ processName, itemName, quantity, notes }) => {
    const heProccesesName = proccesesTohebrew(processName)
    return {
      processName: heProccesesName,
      itemName,
      quantity,
      notes,
    }
  })

  const readyToLoadDate = getReadyToLoadDate(historyRecords)

  return {
    orderNumber,
    stationNumber,
    orderConfirmDate,
    mobilePhone,
    branchName,
    packageNumber,
    orderStatus,
    orderType,
    firstName,
    lastName,
    orderFormatDate: getFormatedDate(orderConfirmDate),
    orderItems,
    orderItemsOriginal,
    orderTotalPrice,
    routeName,
    routeAreaCode,
    isHighPriority,
    readyToLoadDate: getFormatedDate(readyToLoadDate),
    isFixedLocationBackoffice,
    isAllowchangeStation: checkIfAllowChangeStation(
      isFixedLocationBackoffice,
      orderType
    ),
    storageSackId,
    isTracking,
  }
}

export const getOrderForPrinterServiceReturnToSource = (order) => {
  const {
    orderNumber,
    stationNumber,
    orderType,
    orderStatus,
    orderConfirmDate,
    firstName,
    lastName,
    mobilePhone,
    branchName,
    packageNumber,
    orderItems: itms,
    orderItemsOriginal,
    orderTotalPrice,
    routeName,
    routeAreaCode,
    isHighPriority,
    historyRecords,
    isFixedLocationBackoffice,
    storageSackId,
    isTracking,
    senderFirstName,
    senderLastName,
    senderFullName,
    senderMobilePhone,
    stationSource,
  } = order

  const orderItems = itms.map(({ processName, itemName, quantity, notes }) => {
    const heProccesesName = proccesesTohebrew(processName)
    return {
      processName: heProccesesName,
      itemName,
      quantity,
      notes,
    }
  })

  const readyToLoadDate = getReadyToLoadDate(historyRecords)

  return {
    orderNumber,
    stationNumber: stationSource?.stationNumber
      ? stationSource.stationNumber
      : "",
    orderConfirmDate,
    mobilePhone: senderMobilePhone,
    branchName: stationSource?.branchDisplayAddress
      ? stationSource.branchDisplayAddress
      : "",
    packageNumber,
    orderStatus,
    orderType,
    firstName: senderFirstName || senderFullName.split(" ")[0],
    lastName: senderLastName || senderFullName.split(" ")[1],
    orderFormatDate: getFormatedDate(orderConfirmDate),
    orderItems,
    orderItemsOriginal,
    orderTotalPrice,
    routeName,
    routeAreaCode,
    isHighPriority,
    readyToLoadDate: getFormatedDate(readyToLoadDate),
    isFixedLocationBackoffice,
    isAllowchangeStation: checkIfAllowChangeStation(
      isFixedLocationBackoffice,
      orderType
    ),
    storageSackId,
    isTracking,
  }
}

function getReadyToLoadDate(historyRecords) {
  const filteredRecords = historyRecords.filter(
    (record) => record.orderStatus === 3
  )
  filteredRecords.sort(
    (a, b) => new Date(b.statusDate) - new Date(a.statusDate)
  )
  const newestStatusDate =
    filteredRecords.length > 0 ? filteredRecords[0].statusDate : null
  return newestStatusDate
}

function checkIfAllowChangeStation(isFixedLocationBackoffice, orderType) {
  if (orderType == 12 && isFixedLocationBackoffice == false) {
    return true
  }
  return false
}
