import {
  ON_GET_USERS_LIST_SUCCESS,
  ON_GET_USERS_LIST_FAIL,
  ON_GET_USER_DETAILS_SUCCESS,
  ON_USERS_LOADING,
  ON_USERS_SEARCH_NOT_VALID_INPUT,
  REMOVE_NOT_VALID_INPUT,
  ON_USERS_SEARCH_SUCCESS,
  SET_USERS_SEARCH,
  CLEAR_USER_SEARCH,
} from "../actions/types"

const INIT_USERS_SEARCH = {
  searchText: "",
  stations: [],
  userRoles: [],
  fromDate: "",
  pageStep: 50,
  page: 1,
}

const INIT_STATE = {
  usersListArr: [],
  specificUserDetails: null,
  isLoading: false,
  isError: false,
  usersSearch: INIT_USERS_SEARCH,
  currentSearchedPage: 1,
  currentSearchedPageStep: 50,
}

const UsersListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersListArr: action.payload,
        isLoading: false,
        isError: false,
      }
    case ON_GET_USERS_LIST_FAIL:
      return {
        ...state,
        usersListArr: [],
        isLoading: false,
        isError: false,
      }
    case ON_GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        specificUserDetails: action.payload,
        isLoading: false,
        isError: false,
      }
    case ON_USERS_LOADING:
      return {
        ...state,
        usersListArr: [],
        isLoading: true,
        isError: false,
      }
    case ON_USERS_SEARCH_NOT_VALID_INPUT:
      return {
        ...state,
        usersListArr: [],
        isLoading: false,
        isError: true,
      }
    case REMOVE_NOT_VALID_INPUT:
      return {
        ...state,
        usersListArr: [],
        isError: false,
      }

    case ON_USERS_SEARCH_SUCCESS:
      return {
        ...state,
        usersListArr: action.payload.users,
        currentSearchedPage: action.payload.page,
        currentSearchedPageStep: action.payload.pageStep,
        isLoading: false,
        isError: false,
      }
    case SET_USERS_SEARCH:
      return {
        ...state,
        usersSearch: action.payload,
        isError: false,
      }
    case CLEAR_USER_SEARCH:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}

export default UsersListReducer
