import React, { useMemo, useState, useEffect } from "react"
import { connect } from "react-redux"

import {
  createProcess,
  updateProcess,
  deleteProcess,
  getProcesses,
} from "../../../../actions"
import { getImage, uploadProcessImage } from "../../../../actions"

import { LabeledFileInput } from "../../../common"

import Table from "../_common/Table"
import EditableTextArea from "./../_common/EditableTextarea"

import api from "../../../../api"

import "./style.css"

const processesTableHeader = "תהליכים"
const processIdTableHeader = "מזהה תהליך"
const serviceIdTableHeader = "מזהה שירות"
const processNameHebrewTableHeader = "(he) שם תהליך"
const processNameEnglishTableHeader = "(en) שם תהליך"
const processNameRussianTableHeader = "(ru) שם תהליך"
const processNameArabicTableHeader = "(ar) שם תהליך"
const numberOfPriceListsUse = "Used"
const processPrintableStatusTableHeader = "ניתן להדפסה"
const processImageTableHeader = "תמונת תהליך"
const uploadProcessImageButtonLabel = "להעלות  את התמונה"
const deleteProcessButtonLabel = "למחוק את התהליך"
const yes = "כן"
const no = "לא"

const Processes = (props) => {
  const {
    processes,
    updateProcess,
    uploadProcessImage,
    deleteProcess,
    getProcesses,
  } = props

  const [processesState, setProcessesState] = useState(processes)
  const [process, setProcess] = useState(null)

  useEffect(() => {
    setProcessesState(processes)
  }, [processes])

  useEffect(() => {
    if (process) {
      updateProcess(process, () => getProcesses())
    }
  }, [updateProcess, process, getProcesses])

  const columns = useMemo(
    () => [
      {
        Header: () => processIdTableHeader,
        id: "process-id",
        Cell: ({ row }) => row.original.processId,
      },
      {
        Header: () => serviceIdTableHeader,
        id: "service-id",
        Cell: ({ row }) => row.original.serviceId,
      },
      {
        Header: () => processNameHebrewTableHeader,
        accessor: "processName_he",
      },
      {
        Header: () => processNameEnglishTableHeader,
        accessor: "processName_en",
      },
      {
        Header: () => processNameRussianTableHeader,
        accessor: "processName_ru",
      },
      {
        Header: () => processNameArabicTableHeader,
        accessor: "processName_ar",
      },

      {
        Header: () => processImageTableHeader,
        id: "price-list-process-image",
        Cell: ({ row }) => (
          <img
            className="process-img"
            src={`${api.getImage}/${row.original.imageId}`}
            alt={`${row.original.imageId}`}
          />
        ),
      },
      {
        Header: "",
        id: "process-image-upload",
        Cell: ({ row }) => (
          <LabeledFileInput
            inputId={`image-input-${row.original.processId}`}
            labelText={
              <div
                title={uploadProcessImageButtonLabel}
                className="fas fa-file-upload"
              />
            }
            accept=".jpg, .jpeg, .png"
            onChange={(e) => {
              uploadProcessImage(
                row.original.processId,
                e.target.files[0],
                () => getProcesses()
              )
            }}
          />
        ),
      },
    ],
    [getProcesses, uploadProcessImage, deleteProcess]
  )

  const defaultColumn = {
    Cell: EditableTextArea,
  }

  const updateProcessesTable = (rowIndex, columnID, value, row, event) => {
    setProcessesState((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          let editedRow = {}
          editedRow = {
            ...old[rowIndex],
            [columnID]: value,
          }
          setProcess(editedRow)
          return editedRow
        }
        return row
      })
    )
  }

  if (processes.length === 0) return null

  return (
    <>
      <h1 id="processes-section-header">{processesTableHeader}</h1>
      <Table
        id="processes-table"
        columns={columns}
        data={processesState}
        defaultColumn={defaultColumn}
        updateData={updateProcessesTable}
      />
    </>
  )
}

const mapStateToProps = ({ priceListsSection }) => {
  const { processes } = priceListsSection
  return {
    processes,
  }
}

export default connect(mapStateToProps, {
  createProcess,
  updateProcess,
  deleteProcess,
  getImage,
  uploadProcessImage,
  getProcesses,
})(Processes)
