import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  getCheapSimOrders,
  updateCheapSimOrder,
  clearCheapSimUpdate,
  printWhiteReceipt,
  clearCheapSimList,
} from "../../../../../actions"
import "./style.scss"
import { BlueButton } from "../../../../common"
import { getFormatedDate } from "../../../../../utils/FormatedDate"
import NotificationModal from "../../../../Modals/NotificationModal"
import CheapSimModal from "../../../../Modals/CheapSimModal/CheapSimModal.js"

const CheapSimPage = (props) => {
  const [isCheapSimModal, setCheapSimModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [ordersList, setOrdersList] = useState([])

  useEffect(() => {
    props.getCheapSimOrders()
    return () => {
      props.clearCheapSimList()
    }
  }, [])

  useEffect(() => {
    if (props.cheapOrdersList) {
      setOrdersList(...ordersList, props.cheapOrdersList)
    }
  }, [props.cheapOrdersList])

  useEffect(() => {
    if (props.isUpdateCheapSimOrderSuccess) {
      removeOrderFromTable(selectedOrder.orderNumber)
      props.printWhiteReceipt(selectedOrder)
    }
  }, [props.isUpdateCheapSimOrderSuccess])

  const selectOrderForUpdate = (order) => {
    setSelectedOrder(order)
    setCheapSimModal(true)
  }

  const saveExternalOrderNumber = (externalOrderNumber) => {
    props.updateCheapSimOrder(selectedOrder.id, externalOrderNumber)
    setCheapSimModal(false)
  }

  const clearUpdate = () => {
    props.clearCheapSimUpdate()
    setSelectedOrder(null)
  }

  const removeOrderFromTable = (orderToRemove) => {
    for (let i = 0; i < ordersList.length; i++) {
      if (ordersList[i].orderNumber == orderToRemove) {
        ordersList.splice(i, 1)
        break
      }
    }
  }

  const renderTableData = () => {
    return ordersList.map((order, index) => {
      const {
        id,
        firstName,
        lastName,
        orderNumber,
        packageNumber,
        orderConfirmDate,
        mobilePhone,
        bagsQuantity,
        branchName,
        stationNumber,
      } = order
      return (
        <tr onClick={(e) => selectOrderForUpdate(order)}>
          <td>{firstName + " " + lastName}</td>
          <td>{mobilePhone}</td>
          <td>{orderNumber}</td>
          <td>{packageNumber}</td>
          <td>{bagsQuantity}</td>
          <td>{branchName}</td>
          <td>{stationNumber}</td>
          <td>{getFormatedDate(orderConfirmDate)}</td>
        </tr>
      )
    })
  }

  const ordersTableAfterSimUpdated = () => {
    return props.updatedCheapsimList.map((order, index) => {
      const {
        id,
        externalOrderNumber,
        orderNumber,
        packageNumber,
        orderConfirmDate,
        branchName,
        bagsQuantity,
        stationNumber,
        firstName,
        lastName,
        mobilePhone,
      } = order
      return (
        <tr>
          <td class="external-num">{externalOrderNumber}</td>
          <td>{firstName + " " + lastName}</td>
          <td>{mobilePhone}</td>
          <td>{orderNumber}</td>
          <td>{packageNumber}</td>
          <td>{bagsQuantity}</td>
          <td>{branchName}</td>
          <td>{stationNumber}</td>
          <td>{getFormatedDate(orderConfirmDate)}</td>
          <td>
            <BlueButton
              extraClass="btn-redirect"
              onClick={() => props.printWhiteReceipt(order)}
            >
              הדפס תוית
            </BlueButton>
          </td>
        </tr>
      )
    })
  }

  return (
    <section className="cheapsim">
      <div className="cheapsim-table">
        <div className="table-title">הזמנות CheapSim</div>
        <table>
          <tr>
            <th>שם לקוח</th>
            <th>מספר טלפון</th>
            <th>מספר הזמנה</th>
            <th>מספר חבילה</th>
            <th>כמות</th>
            <th>שם עמדה</th>
            <th>מספר עמדה</th>
            <th>תאריך</th>
          </tr>
          {ordersList && ordersList.length > 0 ? renderTableData() : null}
        </table>
      </div>
      {props.updatedCheapsimList && props.updatedCheapsimList.length > 0 ? (
        <div className="updated-table">
          <div className="table-title">הזמנות CheapSim אחרי עדכון</div>
          <table>
            <tr>
              <th>מספר sim</th>
              <th>שם לקוח</th>
              <th>מספר טלפון</th>
              <th>מספר הזמנה</th>
              <th>מספר חבילה</th>
              <th>כמות</th>
              <th>שם עמדה</th>
              <th>מספר עמדה</th>
              <th>תאריך</th>
              <th></th>
            </tr>
            {props.updatedCheapsimList && props.updatedCheapsimList.length > 0
              ? ordersTableAfterSimUpdated()
              : null}
          </table>
        </div>
      ) : null}

      <CheapSimModal
        show={isCheapSimModal}
        onSubmitClick={(e) => saveExternalOrderNumber(e)}
        onCancelClick={() => setCheapSimModal(false)}
        orderData={selectedOrder}
      />
      <NotificationModal
        title="עדכון הזמנה CheapSim"
        show={props.isUpdateCheapSimOrderSuccess}
        text="הזמנה נשמרה בהצלחה"
        onOkClick={(e) => clearUpdate()}
      />
      <NotificationModal
        title="עדכון הזמנה CheapSim"
        show={props.isUpdateCheapSimOrderFail}
        text="שגיאה! הזמנה לא עודכנה"
        onOkClick={(e) => clearUpdate()}
      />
    </section>
  )
}

const mapStateToProps = ({ ordersList }) => {
  const {
    cheapOrdersList,
    isUpdateCheapSimOrderSuccess,
    isUpdateCheapSimOrderFail,
    updatedCheapsimList,
  } = ordersList
  return {
    cheapOrdersList,
    isUpdateCheapSimOrderSuccess,
    isUpdateCheapSimOrderFail,
    updatedCheapsimList,
  }
}

export default connect(mapStateToProps, {
  getCheapSimOrders,
  updateCheapSimOrder,
  clearCheapSimUpdate,
  printWhiteReceipt,
  clearCheapSimList,
})(CheapSimPage)
