import React from "react"
import { connect } from "react-redux"
import { getTimeSpanFormated } from "../../../../utils"
import "./style.scss"

const NotAliveStations = (props) => {
  return (
    <div className="not-alive-stations-wrapper">
      <h3>
        <div className="down-count">
          {props.downStations ? props.downStations.length : ""}
        </div>
        תחנות לא פעילות
      </h3>
      <div className="not-alive-stations-list">
        <div className="station-item title">
          <div className="column number">מספר</div>
          <div className="column name">שם עמדה</div>
          <div className="column before-time">עדכון אחרון לפני</div>
        </div>
        {props.downStations.map((itm, index) => {
          //lastAliveDate
          const before = getTimeSpanFormated(itm.aliveBeforeMin)
          const beforeText = before === "" ? "יותר משבוע" : before

          return (
            <div className="station-item" key={itm.stationId}>
              <div className="column number">{itm.stationNumber}</div>
              <div className="column name">{itm.branchDisplayName}</div>
              <div className="column before-time">{beforeText}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProp = ({ stationsList }) => {
  const { downStations } = stationsList

  return { downStations }
}

export default connect(mapStateToProp, {
  //getDownStations,
})(NotAliveStations)
