import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { BlueButton } from "../../../common/BlueButton"
import CreateEditMailOrderModal from "../../../Modals/CreateEditMailOrderModal"
import CreateEditOrderModal from "../../../Modals/CreateEditOrderModal"
import CreateMailOrderIsraelPost from "../../../Modals/CreateMailOrderIsraelPost/CreateMailOrderIsraelPost.js"
import CreateLockerToLockerOrderModal from "../../../Modals/CreateLockerToLockerOrderModal"
import {
  clearNewMailOrder,
  createNewMailOrder,
  onCreateNewOrder,
  createNewOrder,
  clearDeliveryDetails,
  clearNewLockerToLockerOrder,
  createNewLockerToLockerOrder,
  getOrderByNumberWithRoute,
  clearOrderByNumberWithRoute,
  printWhiteReceipt,
} from "../../../../actions"
import NotificationModal from "../../../Modals/NotificationModal"
import exelotLogo from "../../../../assets/images/exelot_logo.png"

const Manager = (props) => {
  const [showEditMailOrderModal, setShowEditMailOrderModal] = useState(false)
  const [showLaundryOrderModal, setShowLaundryOrderModal] = useState(false)
  const [showDeliveryIsraelPost, setShowDeliveryIsraelPost] = useState(false)
  const [showLockerToLockerDelivery, setShowLockerToLockerDelivery] =
    useState(false)

  const onNewMailOrderModalClose = () => {
    setShowEditMailOrderModal(false)
    props.clearNewMailOrder()
  }

  const onNewLaundryOrderModalClose = () => {
    setShowLaundryOrderModal(false)
    props.onCreateNewOrder()
  }

  const orderIsraelPostModalClose = () => {
    setShowDeliveryIsraelPost(false)
  }

  const orderLockerToLockerDeliveryModalClose = () => {
    setShowLockerToLockerDelivery(false)
  }

  const createNewLaundryOrder = (order) => {
    props.onCreateNewOrder()
    props.createNewOrder(order)
    setShowLaundryOrderModal(false)
  }

  const createNewLockerToLockerPreOrder = (order) => {
    props.clearNewLockerToLockerOrder()
    props.createNewLockerToLockerOrder(order)
    setShowLockerToLockerDelivery(false)
  }

  useEffect(() => {
    if (props.isMailOrderCreated) {
      props.getOrderByNumberWithRoute(props.mailOrder?.orderNumber)
      //props.clearNewMailOrder()
    }
  }, [props.isMailOrderCreated])

  useEffect(() => {
    if (props.isGetOrderByNumberSuccess) {
      props.printWhiteReceipt(props.orderByNumberDetails)
      props.clearOrderByNumberWithRoute()
    }
  }, [props.isGetOrderByNumberSuccess])

  return (
    <div className="links-holder">
      <BlueButton
        extraClass="link-btn"
        onClick={() => props.history.push("/StationsScreen/notifications")}
      >
        התראות
      </BlueButton>
      <BlueButton
        extraClass="link-btn"
        onClick={() => props.history.push("/OrdersSearchScreen")}
      >
        חיפוש הזמנות
      </BlueButton>
      <BlueButton
        extraClass="link-btn"
        onClick={(e) => setShowEditMailOrderModal(true)}
      >
        הזמנת דואר חדשה
      </BlueButton>
      <BlueButton
        extraClass="link-btn"
        onClick={(e) => setShowLaundryOrderModal(true)}
      >
        הזמנת כביסה חדשה
      </BlueButton>
      <BlueButton
        extraClass="link-btn"
        onClick={(e) => setShowDeliveryIsraelPost(true)}
      >
        הזמנה דואר ישראל
      </BlueButton>
      <BlueButton
        extraClass="link-btn"
        onClick={(e) => setShowLockerToLockerDelivery(true)}
      >
        הזמנה משלוח
      </BlueButton>
      <CreateEditMailOrderModal
        show={showEditMailOrderModal}
        onSubmitOrderResultClick={(order) => props.createNewMailOrder(order)}
        onCancelClick={() => onNewMailOrderModalClose()}
      />

      <CreateEditOrderModal
        show={showLaundryOrderModal}
        onSubmitOrderResultClick={(order) => createNewLaundryOrder(order)}
        onCancelClick={() => onNewLaundryOrderModalClose()}
      />

      <CreateMailOrderIsraelPost
        show={showDeliveryIsraelPost}
        onSubmitOrderResultClick={(order) => props.createNewMailOrder(order)}
        onCancelClick={() => orderIsraelPostModalClose()}
      />

      <CreateLockerToLockerOrderModal
        show={showLockerToLockerDelivery}
        onSubmitOrderResultClick={(order) =>
          createNewLockerToLockerPreOrder(order)
        }
        onCancelClick={() => orderLockerToLockerDeliveryModalClose()}
      />

      <NotificationModal
        show={props.isMailOrderCreated}
        text="הזמנת דואר חדשה נוספה בהצלחה."
        onOkClick={(e) => props.clearNewMailOrder()}
      />
      <NotificationModal
        show={props.createMailOrderError}
        type="error"
        text={
          <>
            <div>תקלה בעת הוספת ההזמנת דואר חדשה.</div>
            {props.errorMsg ? <div>{props.errorMsg}</div> : null}
          </>
        }
        onOkClick={(e) => props.clearNewMailOrder()}
      />

      <NotificationModal
        show={props.isOrderCreated}
        text="הזמנת כביסה חדשה נוספה בהצלחה."
        onOkClick={(e) => props.onCreateNewOrder()}
      />
      <NotificationModal
        show={props.createOrderError}
        text="תקלה בעת הוספת ההזמנת כביסה חדשה."
        onOkClick={(e) => props.onCreateNewOrder()}
      />

      <NotificationModal
        show={props.createLockerToLockerDeliverySuccess}
        text="הזמנת משלוח חדשה נוספה בהצלחה."
        onOkClick={(e) => props.clearNewLockerToLockerOrder()}
      />
      <NotificationModal
        show={props.createLockerToLockerDeliveryFail}
        text="תקלה בעת הוספת ההזמנת משלוח חדשה."
        onOkClick={(e) => props.clearNewLockerToLockerOrder()}
      />
    </div>
  )
}

const mapStateToProp = ({ ordersList, lockerToLocker }) => {
  const {
    isMailOrderCreated,
    createMailOrderError,
    isOrderCreated,
    createOrderError,
    errorMsg,
    mailOrder,
    orderByNumberDetails,
    isGetOrderByNumberSuccess,
  } = ordersList

  const {
    createLockerToLockerDeliverySuccess,
    createLockerToLockerDeliveryFail,
  } = lockerToLocker
  return {
    isMailOrderCreated,
    createMailOrderError,
    isOrderCreated,
    createOrderError,
    createLockerToLockerDeliverySuccess,
    createLockerToLockerDeliveryFail,
    errorMsg,
    mailOrder,
    orderByNumberDetails,
    isGetOrderByNumberSuccess,
  }
}

export default connect(mapStateToProp, {
  clearNewMailOrder,
  createNewMailOrder,
  onCreateNewOrder,
  createNewOrder,
  clearDeliveryDetails,
  clearNewLockerToLockerOrder,
  createNewLockerToLockerOrder,
  getOrderByNumberWithRoute,
  clearOrderByNumberWithRoute,
  printWhiteReceipt,
})(Manager)
