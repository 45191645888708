import React from "react"

import "./style.scss"

const SmallHeader = ({ title = "כניסה למערכת", type }) => {
  const logoSrc = require("../../../assets/images/donelogo.png")

  const setTitleColor = () => {
    switch (type) {
      case "error":
        return "#ff3333"
      case "success":
        return "#29a7df"
      case "warning":
        return "#ffa500"
      default:
        return "#29a7df"
    }
  }

  return (
    <div
      className="sml-header-wrapper"
      style={{ "--header-color": setTitleColor() }}
    >
      <div className="sml-header-title">{title}</div>
      <img src={logoSrc} alt="" className="sml-header-img-logo" />
    </div>
  )
}

export { SmallHeader }
