import React from "react"
import { getFormatedDate } from "../../../../../../../utils/FormatedDate"

import "./style.scss"

const UserHistoryItem = ({ item, itemMap, gravity, isLast }) => {
  const itemClassName = `ul-item-wrapper ${isLast ? "ul-item-last" : ""}`

  return (
    <div className={itemClassName}>
      {renderItemContent(item, itemMap, gravity)}
    </div>
  )
}

const renderItemContent = (item, itemMap, gravity) => {
  return itemMap.map((itmMap, index) => {
    const flex = gravity[`${index}`] || 1
    if (itmMap == "isDelete") {
      const className = item[itmMap]
        ? " fa fa-times-circle user-deleted"
        : " fa fa-check-circle user-active"
      return (
        <div
          style={{ flex }}
          key={index}
          className={className}
          aria-hidden="true"
        ></div>
      )
    }

    if (itmMap == "createdDate") {
      return (
        <div style={{ flex }} key={index} className="date-time">
          {getFormatedDate(item[itmMap])}
        </div>
      )
    }
    if (itmMap == "isNeedAccessibility") {
      if (item[itmMap] === true) {
        return (
          <div
            style={{ flex }}
            key={index}
            //className="fa fa-wheelchair"
            aria-hidden="true"
            className={"fa fa-wheelchair "}
          ></div>
        )
      }

      return (
        <div style={{ flex }} key={index}>
          -
        </div>
      )
    }

    if (itmMap == "compensation") {
      return (
        <div style={{ flex }} key={index}>
          {(item[itmMap] - 0).toFixed(2)}
        </div>
      )
    }

    if (itmMap == "isMailSubscribe") {
      if (item[itmMap] === true) {
        return (
          <div
            style={{ flex }}
            key={index}
            title="יש מנוי דואר"
            className="is-mail-subscribed active"
          >
            <div class="fa fa-circle" aria-hidden="true"></div>
          </div>
        )
      }

      return (
        <div
          style={{ flex }}
          key={index}
          title="אין מנוי דואר"
          className="is-mail-subscribed"
        >
          <div class="fa fa-ban" aria-hidden="true"></div>
        </div>
      )
    }

    if (itmMap == "isMonthlyPayment") {
      if (item[itmMap] === true) {
        return (
          <div
            style={{ flex }}
            key={index}
            title="לקוח עסקי תשלום חודשי"
            className="is-monthly-payment active"
          >
            <div></div>
          </div>
        )
      }

      return (
        <div style={{ flex }} key={index} className="is-monthly-payment">
          <div class="fa fa-ban" aria-hidden="true"></div>
        </div>
      )
    }

    if (itmMap == "discountExpirationDate") {
      if (item[itmMap]) {
        return (
          <div style={{ flex }} key={index} className="date-time">
            {getFormatedDate(item[itmMap])}
          </div>
        )
      }

      return <div style={{ flex }} key={index}></div>
    }

    if (itmMap == "sourceApp") {
      if (item[itmMap]) {
        return (
          <div
            style={{ flex }}
            key={index}
            className="source-app"
            title={item[itmMap]}
          >
            {item[itmMap]}
          </div>
        )
      }

      return <div style={{ flex }} key={index}></div>
    }

    if (itmMap == "note") {
      if (item[itmMap]) {
        return (
          <div
            style={{ flex }}
            key={index}
            className="note"
            title={item[itmMap]}
          >
            <div class="fa fa-sticky-note-o" aria-hidden="true"></div>
          </div>
        )
      }

      return <div style={{ flex }} key={index}></div>
    }

    return (
      <div style={{ flex }} key={index}>
        {item[itmMap]}
      </div>
    )
  })
}

export default UserHistoryItem
