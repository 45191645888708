import React, { useState } from 'react';

import './style.css';

const MultiSelectValPassive = ({ extraClass, items, onItemCheckChange, valueIndexesArr, title }) => {

    const [showItems, setShowItems] = useState(false);
    const [hideItemsTimeout, setHideItemsTimeout] = useState(0);

    const arrowDownSrc = showItems ? require('../../../assets/images/arrow-up.png') :
        require('../../../assets/images/arrow-down.png');

    const pickedDescription = valueIndexesArr.length <= 0 ? 'הכל' : `${valueIndexesArr.length} נבחרו`;

    return (
        <div
            className={`mul-selvalpassive-wrapper ${extraClass ? extraClass : ''}`}
            onMouseEnter={e => handleOnMouseEnterLeave(true, hideItemsTimeout, setHideItemsTimeout, setShowItems)}
            onMouseLeave={e => handleOnMouseEnterLeave(false, hideItemsTimeout, setHideItemsTimeout, setShowItems)}
        >
            <div className='mul-selvalpassive-displayed-itm'
                onClick={e => { setShowItems(!showItems) }}
            >
                <div className='mul-selvalpassive-arrow-down'>
                    <img src={arrowDownSrc} className='mul-selvalpassive-arrow-img' alt='' />
                </div>
                <div className='mul-selvalpassive-itm'>
                    {title} - {pickedDescription}
                </div>
                
            </div>
            <div className='mul-selvalpassive-itms-list-wrapper'>
                <div className='mul-selvalpassive-itms-list'>
                    {renderItems(showItems, items, valueIndexesArr, onItemCheckChange, setShowItems)}
                </div>
            </div>
        </div>
    );
};

const renderItems = (show, items, selectedItemsIndexs, onItemCheckChange, setShowItems) => {
    if (!show) {
        return;
    }

    return items.map((itm, index) => {

        const isItemSelected = selectedItemsIndexs.includes(index);

        const itemClassName = `mul-selvalpassive-itm mul-selvalpassive-itm-extra ${isItemSelected ? 'mul-selvalpassive-picker' : ''}`

        return (
            <div
                className={itemClassName}
                // onClick={e => { onItemClick(itm, index); setShowItems(false); }}
                onClick={e => onItemCheckChange(itm, index)}
                key={index}
            >
                <input
                    type='checkbox'
                    className='mul-selvalpassive-checkbox'
                    checked={isItemSelected}
                    onChange={e => e}
                />

                <div className='mul-selvalpassive-itm-content'>
                    {itm}
                </div>
            </div >
        );
    });
};

const handleOnMouseEnterLeave = (isEnter, hideItemsTimeout, setHideItemsTimeout, setShowItems) => {
    if (isEnter) {
        if (hideItemsTimeout) {
            clearTimeout(hideItemsTimeout);
        }
    } else {
        const newTimeout = setTimeout(() => { setShowItems(false) }, 500)
        setHideItemsTimeout(newTimeout);
    }
}

export { MultiSelectValPassive };
