import { changePackageNumber } from "../actions"
import {
  ON_GET_ORDERS_LIST_SUCCESS,
  ON_GET_ORDERS_LIST_FAIL,
  ON_GET_ORDER_DETAILS_SUCCESS,
  ON_ORDER_FILTER_BY_CHNAGE,
  SET_EXTERNAL_ORDER_TO_EDIT,
  ON_FILTERED_ACTIVE_TYPES_UPDATE,
  ON_FILTERED_ACTIVE_STATUSES_UPDATE,
  ON_FILTERED_ACTIVE_STATIONS_UPDATE,
  ON_FILTERED_IS_PAID_STATUS_UPDATE,
  ON_GET_ORDER_DETAILS_REQUEST,
  ON_ORDER_UPDATE_FAIL,
  ON_ORDER_UPDATE_REQUEST,
  ON_ORDER_UPDATE_SUCCESS,
  ON_ORDER_RECALC_LOADING,
  ON_ORDER_RECALC_NO_SAVE_SUCCESS,
  ON_ORDER_RECALC_NO_SAVE_FAIL,
  ON_CLEAR_RECALCULATED,
  CLEAR_CALCULATE_ERROR,
  CLEAR_ORDER_UPDATE_SUCCESS_FAIL_STATUS,
  ON_ORDER_LOCAL_UPDATE,
  ON_CREATE_ORDER_SUCCESS,
  ON_CREATE_ORDER_FAIL,
  ON_CREATE_ORDER,
  SEND_READY_IN_LOCKER_SMS_REQUEST,
  SEND_READY_IN_LOCKER_SMS_SUCCESS,
  SEND_READY_IN_LOCKER_SMS_FAIL,
  CLEAR_READY_IN_LOCKER_SMS,
  CLEAR_NEW_MAIL_ORDER,
  ON_CREATE_MAIL_ORDER_SUCCESS,
  ON_CREATE_MAIL_ORDER_FAIL,
  ON_ORDER_COUPON_REMOVE_SUCCESS,
  ON_ORDER_COUPON_REMOVE_FAIL,
  ON_ORDER_COUPON_ADD_SUCCESS,
  ON_ORDER_COUPON_ADD_FAIL,
  ON_GET_ORDER_BY_NUMBER_REQUEST,
  ON_GET_ORDER_BY_NUMBER_SUCCESS,
  ON_GET_ORDER_BY_NUMBER_FAIL,
  ON_START_ORDERS_LOADING,
  CLEAR_ORDER_DETAILS,
  CLEAR_ORDERS_LIST,
  ON_GET_ORDERS_BY_SUPPLIER_SUCCESS,
  ON_GET_ORDERS_BY_SUPPLIER_FAIL,
  ON_GET_ORDERS_BY_ROUTE_SUCCESS,
  ON_GET_ORDERS_BY_ROUTE_FAIL,
  CLEAR_ORDERS_BY_ROUTE,
  ON_GET_ORDERS_ITEMS_ORIGINAL_SUCCESS,
  ON_GET_ORDERS_ITEMS_ORIGINAL_FAIL,
  ON_ORDER_UPDATE_AFTER_LAUNDRY_SUCCESS,
  ON_ORDER_UPDATE_AFTER_LAUNDRY_FAIL,
  SEND_DELAY_SMS_SUCCESS,
  SEND_DELAY_SMS_FAIL,
  ON_UPDATE_ORDER_BAGS_SUCCESS,
  ON_UPDATE_ORDER_BAGS_FAIL,
  CLEAR_ORDER_BAGS_STATUS,
  ON_GET_CHEAP_SIM_ORDERS_SUCCESS,
  ON_GET_CHEAP_SIM_ORDERS_FAIL,
  ON_ORDER_CHEAPSIM_UPDATE_SUCCESS,
  ON_ORDER_CHEAPSIM_UPDATE_FAIL,
  CLEAR_CHEAPSIM_UPDATE,
  CLEAR_CHEAPSIM_LIST,
  ON_LOAD_NEW_MAIL_ORDER,
  ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_REQUEST,
  ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_SUCCESS,
  ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_FAIL,
  CLEAR_ORDER_BY_PACKAGE_OR_NUMBER,
  ON_IN_LOCKER_EXTEND_SUCCESS,
  ON_IN_LOCKER_EXTEND_FAIL,
  CLEAR_IN_LOCKER_EXTEND,
  DELIVERY_LOGIN_CODE_RESET_SUCCESS,
  DELIVERY_LOGIN_CODE_RESET_FAIL,
  CLEAR_DELIVERY_LOGIN_CODE_RESET,
  CHANGE_TARGET_CUSTOMER_SUCCESS,
  CHANGE_TARGET_CUSTOMER_FAIL,
  CLEAR_CHANGE_TARGET_CUSTOMER,
  CLEAR_GET_ORDER_BY_NUMBER,
  CHANGE_TARGET_STATION_SUCCESS,
  CHANGE_TARGET_STATION_FAIL,
  CLEAR_CHANGE_TARGET_STATION,
  ON_RETURN_TO_SENDER_SUCCESS,
  ON_RETURN_TO_SENDER_FAIL,
  SET_ORDER_TRACKING_SUCCESS,
  SET_ORDER_TRACKING_FAIL,
  CLEAR_SET_ORDER_TRACKING,
  CHANGE_PACKAGE_NUMBER_REQUEST,
  CHANGE_PACKAGE_NUMBER_SUCCESS,
  CHANGE_PACKAGE_NUMBER_FAIL,
  CLEAR_CHANGE_PACKAGE_NUMBER,
  CHANGE_SOURCE_STATION_SUCCESS,
  CHANGE_SOURCE_STATION_FAIL,
  CLEAR_CHANGE_SOURCE_STATION,
} from "../actions/types"

import {
  isProdEnv,
  getOrdersResponse,
  handleFilteredActiveStatusesUpdate,
  handleFilteredActiveTypesUpdate,
  recalcFilteredListOutOfLatestState,
  handleFilteredActiveStationsUpdate,
  hanldeFilterByIsPaidUpdate,
} from "../utils"

const INIT_STATE = {
  ordersListArr: [],
  orderDetails: null,
  orderByNumberDetails: null,
  filterBy: "",
  filteredListArr: [],
  externalOrderToEdit: null,

  FiltererActiveTypes: [], // No order types filter is selected by default
  FiltererActiveStatuses: isProdEnv() ? [1] : [],
  FiltererActiveStations: [],
  FiltererIsPaid: 0, // 0 - All, 1 - Paid, 2 - Not Paid

  isUpdateOrderLoading: false,
  updateOrderError: null,
  updateOrderSuccess: false,
  isOrderCreated: false,
  createOrderError: false,
  sendSmsSuccess: null,
  sendSmsFail: null,
  isMailOrderCreated: null,
  createMailOrderError: false,
  isLoading: false,
  isGetOrderByNumberFail: false,
  isGetOrderByNumberSuccess: false,
  ordersListBySupplier: null,
  ordersListByRoute: null,
  orderItemsOriginal: null,
  isOrderUpdatedAfterLaundery: null,
  isRecalculated: false,
  calculateError: false,
  orderBagsUpdateSuccess: false,
  isCleared: false,
  cheapOrdersList: null,
  isUpdateCheapSimOrderSuccess: false,
  isUpdateCheapSimOrderFail: false,
  updatedCheapsimList: [],
  isSubmitDisable: false,
  errorMsg: null,
  orderByPackageOrNumber: null,
  isOrderByPackageOrNumberSuccess: false,
  isOrderByPackageOrNumberFail: false,
  orderInLockerExtendSuccess: false,
  orderInLockerExtendFail: false,
  deliveryLoginCodeResetSuccess: false,
  deliveryLoginCodeResetFail: false,
  changeTargetCustomerSuccess: false,
  changeTargetCustomerFail: false,
  mailOrder: null,
  changeTargetStationSuccess: false,
  changeTargetStationFail: false,
  setOrderTrackingSuccess: false,
  setOrderTrackingFail: false,
  changePackageNumberSuccess: false,
  changePackageNumberFail: false,
  changeSourceStationSuccess: false,
  changeSourceStationFail: false,
}

const OrdersListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_ORDERS_LIST_SUCCESS:
      return recalcFilteredListOutOfLatestState({
        ...state,
        ordersListArr: getOrdersResponse(action.payload),
        isLoading: false,
      })
    // case ON_GET_ORDERS_LIST_FAIL:
    //     return {
    //         ...state
    //     }
    case ON_GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        orderDetails: null,
      }
    case ON_GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
      }
    case SET_EXTERNAL_ORDER_TO_EDIT:
      return {
        ...state,
        externalOrderToEdit: action.payload,
      }

    //SEARCH
    case ON_ORDER_FILTER_BY_CHNAGE:
      return recalcFilteredListOutOfLatestState({
        ...state,
        filterBy: action.payload,
        isLoading: false,
      })
    case ON_FILTERED_ACTIVE_TYPES_UPDATE:
      return handleFilteredActiveTypesUpdate(state, action.payload)

    case ON_FILTERED_ACTIVE_STATUSES_UPDATE:
      return handleFilteredActiveStatusesUpdate(state, action.payload)

    case ON_FILTERED_ACTIVE_STATIONS_UPDATE:
      return handleFilteredActiveStationsUpdate(state, action.payload)

    case ON_FILTERED_IS_PAID_STATUS_UPDATE:
      return hanldeFilterByIsPaidUpdate(state, action.payload)

    case ON_ORDER_UPDATE_REQUEST:
      return {
        ...state,
        isUpdateOrderLoading: true,
        updateOrderError: null,
        updateOrderSuccess: false,
        isLoading: false,
      }
    case ON_ORDER_UPDATE_FAIL:
      return {
        ...state,
        isUpdateOrderLoading: false,
        updateOrderError: true,
        updateOrderSuccess: false,
      }

    case ON_ORDER_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdateOrderLoading: false,
        updateOrderError: null,
        updateOrderSuccess: action.payload,
      }

    case CLEAR_ORDER_UPDATE_SUCCESS_FAIL_STATUS:
      return {
        ...state,
        isUpdateOrderLoading: false,
        updateOrderError: null,
        updateOrderSuccess: false,
        isOrderUpdatedAfterLaundery: false,
      }
    case ON_ORDER_LOCAL_UPDATE:
      return handleLocalOrderUpdate(state, action.payload)
    case ON_CREATE_ORDER:
      return {
        ...state,
        isOrderCreated: false,
        createOrderError: false,
      }
    case ON_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isOrderCreated: true,
        createOrderError: false,
      }
    case ON_CREATE_ORDER_FAIL:
      return {
        ...state,
        isOrderCreated: false,
        createOrderError: true,
      }
    case SEND_READY_IN_LOCKER_SMS_REQUEST:
      return {
        ...state,
        sendSmsSuccess: false,
        sendSmsFail: null,
      }
    case SEND_READY_IN_LOCKER_SMS_SUCCESS:
      return {
        ...state,
        sendSmsSuccess: action.payload,
        sendSmsFail: null,
      }
    case SEND_READY_IN_LOCKER_SMS_FAIL:
      return {
        ...state,
        sendSmsFail: true,
        sendSmsSuccess: false,
      }
    case CLEAR_READY_IN_LOCKER_SMS:
      return {
        ...state,
        sendSmsFail: null,
        sendSmsSuccess: null,
      }

    case CLEAR_NEW_MAIL_ORDER:
      return {
        ...state,
        isMailOrderCreated: false,
        createMailOrderError: false,
        isCleared: true,
      }
    case ON_LOAD_NEW_MAIL_ORDER:
      return {
        ...state,
        isSubmitDisable: true,
      }
    case ON_CREATE_MAIL_ORDER_SUCCESS:
      return {
        ...state,
        isMailOrderCreated: true,
        createMailOrderError: false,
        isCleared: false,
        isSubmitDisable: false,
        mailOrder: action.payload[0],
      }
    case ON_CREATE_MAIL_ORDER_FAIL:
      return {
        ...state,
        isMailOrderCreated: false,
        createMailOrderError: true,
        errorMsg: action.payload,
        isCleared: true,
        isSubmitDisable: false,
      }

    case ON_ORDER_COUPON_ADD_SUCCESS:
    case ON_ORDER_COUPON_REMOVE_SUCCESS:
      return {
        ...state,
        isUpdateOrderLoading: false,
        updateOrderError: null,
        updateOrderSuccess: true,
        orderDetails: action.payload,
      }
    case ON_ORDER_COUPON_ADD_FAIL:
    case ON_ORDER_COUPON_REMOVE_FAIL:
      return {
        ...state,
        isUpdateOrderLoading: false,
        updateOrderError: true,
        updateOrderSuccess: false,
      }

    case ON_GET_ORDER_BY_NUMBER_REQUEST:
      return {
        ...state,
        orderByNumberDetails: null,
        isGetOrderByNumberFail: false,
        isGetOrderByNumberSuccess: false,
      }
    case ON_GET_ORDER_BY_NUMBER_SUCCESS:
      return {
        ...state,
        orderByNumberDetails: action.payload,
        isGetOrderByNumberFail: false,
        isGetOrderByNumberSuccess: true,
      }
    case ON_GET_ORDER_BY_NUMBER_FAIL:
      return {
        ...state,
        isGetOrderByNumberFail: true,
        isGetOrderByNumberSuccess: false,
      }
    case CLEAR_GET_ORDER_BY_NUMBER:
      return {
        ...state,
        isGetOrderByNumberFail: false,
        isGetOrderByNumberSuccess: false,
        orderByNumberDetails: null,
      }
    case ON_START_ORDERS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case CLEAR_ORDER_DETAILS:
      return {
        ...state,
        orderByNumberDetails: null,
      }
    case CLEAR_ORDERS_LIST:
      return {
        ...state,
        ordersListArr: null,
      }
    case ON_GET_ORDERS_BY_SUPPLIER_SUCCESS:
      return {
        ...state,
        ordersListBySupplier: action.payload,
      }
    case ON_GET_ORDERS_BY_SUPPLIER_FAIL:
      return {
        ...state,
        ordersListBySupplier: null,
      }
    case ON_GET_ORDERS_BY_ROUTE_SUCCESS:
      return {
        ...state,
        ordersListByRoute: action.payload,
      }
    case CLEAR_ORDERS_BY_ROUTE:
      return {
        ...state,
        ordersListByRoute: null,
      }
    case ON_GET_ORDERS_BY_ROUTE_FAIL:
      return {
        ...state,
        ordersListByRoute: null,
      }
    case ON_GET_ORDERS_ITEMS_ORIGINAL_SUCCESS:
      return {
        ...state,
        orderItemsOriginal: action.payload,
      }
    case ON_GET_ORDERS_ITEMS_ORIGINAL_FAIL:
      return {
        ...state,
        orderItemsOriginal: null,
      }
    case ON_ORDER_UPDATE_AFTER_LAUNDRY_SUCCESS:
      return {
        ...state,
        isOrderUpdatedAfterLaundery: true,
      }
    case ON_ORDER_UPDATE_AFTER_LAUNDRY_FAIL:
      return {
        ...state,
        isOrderUpdatedAfterLaundery: false,
      }
    case ON_ORDER_RECALC_LOADING:
      return {
        ...state,
        isLoading: true,
        isRecalculated: false,
        calculateError: false,
      }
    case ON_ORDER_RECALC_NO_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRecalculated: true,
        orderDetails: action.payload,
      }
    case ON_ORDER_RECALC_NO_SAVE_FAIL:
      return {
        ...state,
        isLoading: false,
        isRecalculated: false,
        calculateError: true,
      }
    case CLEAR_CALCULATE_ERROR:
      return {
        ...state,
        calculateError: false,
      }
    case ON_CLEAR_RECALCULATED:
      return {
        ...state,
        isRecalculated: false,
      }
    case SEND_DELAY_SMS_SUCCESS:
      return {
        ...state,
        sendSmsSuccess: true,
        sendSmsFail: false,
      }
    case SEND_DELAY_SMS_FAIL:
      return {
        ...state,
        sendSmsSuccess: false,
        sendSmsFail: true,
      }
    case ON_UPDATE_ORDER_BAGS_SUCCESS:
      return {
        ...state,
        orderBagsUpdateSuccess: true,
      }
    case ON_UPDATE_ORDER_BAGS_FAIL:
      return {
        ...state,
        orderBagsUpdateSuccess: false,
      }
    case CLEAR_ORDER_BAGS_STATUS:
      return {
        ...state,
        orderBagsUpdateSuccess: false,
        ordersListBySupplier: null,
      }
    case ON_GET_CHEAP_SIM_ORDERS_SUCCESS:
      return {
        ...state,
        cheapOrdersList: action.payload,
      }
    case ON_GET_CHEAP_SIM_ORDERS_FAIL:
      return {
        ...state,
        cheapOrdersList: null,
      }
    case ON_ORDER_CHEAPSIM_UPDATE_SUCCESS:
      return {
        ...state,
        updatedCheapsimList: [...state.updatedCheapsimList, action.payload],
        isUpdateCheapSimOrderSuccess: true,
        isUpdateCheapSimOrderFail: false,
      }
    case ON_ORDER_CHEAPSIM_UPDATE_FAIL:
      return {
        ...state,
        isUpdateCheapSimOrderFail: true,
        isUpdateCheapSimOrderSuccess: false,
      }
    case CLEAR_CHEAPSIM_UPDATE:
      return {
        ...state,
        isUpdateCheapSimOrderFail: false,
        isUpdateCheapSimOrderSuccess: false,
      }
    case CLEAR_CHEAPSIM_LIST:
      return {
        ...state,
        cheapOrdersList: null,
        updatedCheapsimList: [],
      }
    case ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isOrderByPackageOrNumberSuccess: false,
        isOrderByPackageOrNumberFail: false,
        orderByPackageOrNumber: null,
      }
    case ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isOrderByPackageOrNumberSuccess: true,
        isOrderByPackageOrNumberFail: false,
        orderByPackageOrNumber: action.payload,
      }
    case ON_GET_ORDER_BY_PACKAGE_OR_NUMBER_FAIL:
      return {
        ...state,
        isLoading: false,
        isOrderByPackageOrNumberSuccess: false,
        isOrderByPackageOrNumberFail: true,
        orderByPackageOrNumber: null,
      }
    case CLEAR_ORDER_BY_PACKAGE_OR_NUMBER:
      return {
        ...state,
        isLoading: false,
        isOrderByPackageOrNumberSuccess: false,
        isOrderByPackageOrNumberFail: false,
        orderByPackageOrNumber: null,
      }
    case ON_IN_LOCKER_EXTEND_SUCCESS:
      return {
        ...state,
        orderInLockerExtendSuccess: true,
        orderInLockerExtendFail: false,
      }
    case ON_IN_LOCKER_EXTEND_FAIL:
      return {
        ...state,
        orderInLockerExtendSuccess: false,
        orderInLockerExtendFail: true,
      }
    case CLEAR_IN_LOCKER_EXTEND:
      return {
        ...state,
        orderInLockerExtendSuccess: false,
        orderInLockerExtendFail: false,
      }
    case DELIVERY_LOGIN_CODE_RESET_SUCCESS:
      return {
        ...state,
        deliveryLoginCodeResetSuccess: true,
        deliveryLoginCodeResetFail: false,
      }
    case DELIVERY_LOGIN_CODE_RESET_FAIL:
      return {
        ...state,
        deliveryLoginCodeResetSucces: false,
        deliveryLoginCodeResetFail: true,
      }
    case CLEAR_DELIVERY_LOGIN_CODE_RESET:
      return {
        ...state,
        deliveryLoginCodeResetSuccess: false,
        deliveryLoginCodeResetFail: false,
      }
    case CHANGE_TARGET_CUSTOMER_SUCCESS:
      return {
        ...state,
        changeTargetCustomerSuccess: true,
        changeTargetCustomerFail: false,
      }
    case CHANGE_TARGET_CUSTOMER_FAIL:
      return {
        ...state,
        changeTargetCustomerSuccess: false,
        changeTargetCustomerFail: true,
      }
    case CLEAR_CHANGE_TARGET_CUSTOMER:
      return {
        ...state,
        changeTargetCustomerSuccess: false,
        changeTargetCustomerFail: false,
      }

    case CHANGE_TARGET_STATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        changeTargetStationSuccess: true,
        changeTargetStationFail: false,
      }
    case CLEAR_CHANGE_TARGET_STATION:
      return {
        ...state,
        changeTargetStationSuccess: false,
        changeTargetStationFail: false,
      }
    case CHANGE_TARGET_STATION_FAIL:
      return {
        ...state,
        isLoading: false,
        changeTargetStationSuccess: false,
        changeTargetStationFail: true,
      }
    case ON_RETURN_TO_SENDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case ON_RETURN_TO_SENDER_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case SET_ORDER_TRACKING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        setOrderTrackingSuccess: true,
        setOrderTrackingFail: false,
      }
    case SET_ORDER_TRACKING_FAIL:
      return {
        ...state,
        isLoading: false,
        setOrderTrackingSuccess: false,
        setOrderTrackingFail: true,
      }
    case CLEAR_SET_ORDER_TRACKING:
      return {
        ...state,
        setOrderTrackingSuccess: false,
        setOrderTrackingFail: false,
      }

    case CHANGE_PACKAGE_NUMBER_REQUEST:
      return {
        ...state,
        isLoading: true,
        changePackageNumberSuccess: false,
        changePackageNumberFail: false,
      }
    case CHANGE_PACKAGE_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        changePackageNumberSuccess: true,
        changePackageNumberFail: false,
      }
    case CHANGE_PACKAGE_NUMBER_FAIL:
      return {
        ...state,
        isLoading: false,
        changePackageNumberSuccess: false,
        changePackageNumberFail: true,
      }
    case CLEAR_CHANGE_PACKAGE_NUMBER:
      return {
        ...state,
        isLoading: false,
        changePackageNumberSuccess: false,
        changePackageNumberFail: false,
      }
    case CHANGE_SOURCE_STATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        changeSourceStationSuccess: true,
        changeSourceStationFail: false,
      }
    case CHANGE_SOURCE_STATION_FAIL:
      return {
        ...state,
        isLoading: false,
        changeSourceStationSuccess: false,
        changeSourceStationFail: true,
      }
    case CLEAR_CHANGE_SOURCE_STATION:
      return {
        ...state,
        changeSourceStationSuccess: false,
        changeSourceStationFail: false,
      }
    default:
      return state
  }
}
const handleLocalOrderUpdate = (state, updatedOrder) => {
  const { ordersListArr } = state
  if (ordersListArr) {
    // if (updatedOrder.orderStatus != 3) {
    //   ordersListArr.find(
    //     ({ orderId }) => orderId === updatedOrder.id
    //   ).orderStatus = updatedOrder.orderStatus;
    // }
    return recalcFilteredListOutOfLatestState({
      ...state,
      ordersListArr,
    })
  }
}

export default OrdersListReducer
