import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { SmallHeader, BlueButton } from "../../common"
import { clearDeliveryDetails, fetchUserByMobile } from "../../../actions"
import Modal from "../Modal"
import Select from "react-select"
import "./style.scss"

const CreateLockerToLockerOrderModal = (props) => {
  const [sourceUserId, setSourceUserId] = useState("")
  const [sourceFirstName, setSourceFirstName] = useState("")
  const [sourceLastName, setSourceLastName] = useState("")
  const [sourcePhoneNumber, setSourcePhoneNumber] = useState("")

  const [targetUserId, setTargetUserId] = useState("")
  const [targetFirstName, setTargetFirstName] = useState("")
  const [targetLastName, setTargetLastName] = useState("")
  const [targetPhoneNumber, setTargetPhoneNumber] = useState("")

  const [validationMsg, setValidationMsg] = useState("")
  const [sourceStationNum, setSourceStationNumber] = useState("")
  const [targetStationNum, setTargetStationNumber] = useState("")
  const [sourceStationId, setSourceStationId] = useState("")
  const [targetStationId, setTargetStationId] = useState("")

  useEffect(() => {
    if (targetPhoneNumber && targetPhoneNumber.trim().length === 10) {
      props.fetchUserByMobile(targetPhoneNumber)
    }
  }, [targetPhoneNumber])

  useEffect(() => {
    if (sourcePhoneNumber && sourcePhoneNumber.trim().length === 10) {
      props.fetchUserByMobile(sourcePhoneNumber)
    }
  }, [sourcePhoneNumber])

  useEffect(() => {
    if (
      props.usersListArr.length <= 0 ||
      (targetPhoneNumber.length !== 10 && sourcePhoneNumber.length !== 10)
    ) {
      return
    }
    const userData = props.usersListArr[0]
    if (!userData) {
      return
    }

    const { userId, firstName, lastName, mobilePhone, stationNumber } = userData

    if (mobilePhone === sourcePhoneNumber && sourcePhoneNumber.length === 10) {
      setSourceUserId(userId)
      setSourceFirstName(firstName)
      setSourceLastName(lastName)
      setSourcePhoneNumber(mobilePhone)
      setSourceStationNumber(stationNumber)
      let _station = props.sortedStationsNames.find(
        (option) => option.value === stationNumber
      )
      setSourceStationId(_station?.stationId ? _station.stationId : "")
    }

    if (mobilePhone === targetPhoneNumber && targetPhoneNumber.length === 10) {
      setTargetUserId(userId)
      setTargetFirstName(firstName)
      setTargetLastName(lastName)
      setTargetPhoneNumber(mobilePhone)
      setTargetStationNumber(stationNumber)
      let _station = props.sortedStationsNames.find(
        (option) => option.value === stationNumber
      )
      setTargetStationId(_station?.stationId ? _station.stationId : "")
    }
  }, [props.usersListArr])

  const onCancelClick = () => {
    props.onCancelClick()
    clearState()
  }

  const onSubmitClick = () => {
    let isValid = validateInput()
    if (isValid) {
      const order = {
        stationId: sourceStationId,
        locationId: sourceUserId,
        customerId: targetUserId || "00000000-0000-0000-0000-000000000000",
        firstName: targetFirstName,
        lastName: targetLastName,
        mobilePhone: targetPhoneNumber,
        couponId: "",
        orderType: 5,
        orderStatus: 18,
        orderItems: [
          {
            quantity: 1,
            processName: "Shipment",
          },
        ],
        sourceCustomerId: sourceUserId,
        sourceStationId: sourceStationId,
        targetStationId: targetStationId,
        orderId: "",
        senderMobilePhone: sourcePhoneNumber,
        senderFirstName: sourceFirstName,
        senderLastName: sourceLastName,
      }

      props.onSubmitOrderResultClick(order)
      clearState()
    }
  }

  const clearState = () => {
    setSourceUserId("")
    setSourceFirstName("")
    setSourceLastName("")
    setSourcePhoneNumber("")
    setTargetUserId("")
    setTargetFirstName("")
    setTargetLastName("")
    setTargetPhoneNumber("")
    setSourceStationNumber("")
    setTargetStationNumber("")
    setSourceStationId("")
    setTargetStationId("")
    setValidationMsg("")

    props.clearDeliveryDetails()
  }

  const validateInput = () => {
    if (
      !sourcePhoneNumber ||
      !sourcePhoneNumber.match(/^05\d([-]{0,1})\d{7}$/)
    ) {
      setValidationMsg("מספר טלפון של שולח לא תקין")
      return false
    }
    if (!sourceFirstName || sourceFirstName.length < 2) {
      setValidationMsg("הזן שם של שולח")
      return false
    }

    if (!sourceLastName || sourceLastName.length < 2) {
      setValidationMsg("הזן את שם משפחה של שולח")
      return false
    }
    if (!targetFirstName || targetFirstName.length < 2) {
      setValidationMsg("הזן שם של נמען")
      return false
    }

    if (!targetLastName || targetFirstName.length < 2) {
      setValidationMsg("הזן את שם משפחה של נמען")
      return false
    }
    if (
      !targetPhoneNumber ||
      !targetPhoneNumber.match(/^05\d([-]{0,1})\d{7}$/)
    ) {
      setValidationMsg("מספר טלפון של נמען לא תקין")
      return false
    }

    if (
      targetPhoneNumber &&
      sourcePhoneNumber &&
      sourcePhoneNumber === targetPhoneNumber
    ) {
      setValidationMsg("לא ניתן לשלוח לאותו לקוח")
      return false
    }
    if (!sourceStationNum) {
      setValidationMsg("אנא בחר עמדת מקור")
      return false
    }

    if (!targetStationNum) {
      setValidationMsg("אנא בחר עמדת יעד")
      return false
    }
    setValidationMsg("")
    return true
  }

  if (!props.show) {
    return null
  }

  const enableSourceInput = sourceUserId && sourceUserId.length > 6

  return (
    <Modal extraClass="cror-modal-wrap">
      <div className="cror-modal-container">
        <div className="locker-to-locker-delivery-modal">
          <SmallHeader title="הזמנת משלוח" />
          <div className="modal-body">
            <div className="person-ditails-wrapper">
              <div className="sub-title">פרטי שולח</div>
              <div className="input-wrapper">
                <span className="input-title"> טלפון שולח </span>
                <input
                  className="input-text"
                  value={sourcePhoneNumber}
                  type="number"
                  onChange={(e) => {
                    setSourcePhoneNumber(e.target.value)
                  }}
                ></input>
              </div>
              <div className="input-wrapper">
                <span className="input-title">שם פרטי </span>
                <input
                  className="input-text"
                  value={sourceFirstName}
                  maxLength="30"
                  onChange={(e) => {
                    setValidationMsg("")
                    setSourceFirstName(e.target.value)
                  }}
                  disabled={enableSourceInput}
                ></input>
              </div>
              <div className="input-wrapper">
                <span className="input-title">שם משפחה </span>
                <input
                  className="input-text"
                  maxLength="30"
                  value={sourceLastName}
                  onChange={(e) => {
                    setValidationMsg("")
                    setSourceLastName(e.target.value)
                  }}
                  disabled={enableSourceInput}
                ></input>
              </div>
            </div>
            <div className="person-ditails-wrapper">
              <div className="sub-title">פרטי נמען</div>
              <div className="input-wrapper">
                <span className="input-title"> טלפון נמען </span>
                <input
                  className="input-text"
                  value={targetPhoneNumber}
                  type="number"
                  onChange={(e) => {
                    setValidationMsg("")
                    setTargetPhoneNumber(e.target.value)
                  }}
                ></input>
              </div>
              <div className="input-wrapper">
                <span className="input-title">שם פרטי </span>
                <input
                  className="input-text"
                  value={targetFirstName}
                  maxLength="30"
                  onChange={(e) => {
                    setValidationMsg("")
                    setTargetFirstName(e.target.value)
                  }}
                ></input>
              </div>
              <div className="input-wrapper">
                <span className="input-title">שם משפחה </span>
                <input
                  className="input-text"
                  maxLength="30"
                  value={targetLastName}
                  onChange={(e) => {
                    setValidationMsg("")
                    setTargetLastName(e.target.value)
                  }}
                ></input>
              </div>
            </div>
            <div className="person-ditails-wrapper">
              <div className="input-wrapper">
                <span className="input-title sub-title">עמדת מקור </span>{" "}
                <Select
                  className="station-selector"
                  key={sourceStationNum}
                  placeholder="בחר..."
                  defaultValue={props.sortedStationsNames.find(
                    (option) => option.value === sourceStationNum
                  )}
                  options={props.sortedStationsNames}
                  onChange={(e) => {
                    setValidationMsg("")
                    setSourceStationNumber(e.value)
                    setSourceStationId(e.stationId)
                  }}
                />
              </div>
            </div>
            <div className="person-ditails-wrapper">
              <div className="input-wrapper">
                <span className="input-title sub-title">עמדת יעד </span>{" "}
                <Select
                  className="station-selector"
                  key={targetStationNum}
                  placeholder="בחר..."
                  defaultValue={props.sortedStationsNames.find(
                    (option) => option.value === targetStationNum
                  )}
                  options={props.sortedStationsNames}
                  onChange={(e) => {
                    setValidationMsg("")
                    setTargetStationNumber(e.value)
                    setTargetStationId(e.stationId)
                  }}
                />
              </div>
            </div>
            <div className="validation-msg">
              {validationMsg ? validationMsg : null}
            </div>
            <div className="cror-modal-status">
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => onSubmitClick()}
              >
                אישור
              </BlueButton>
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => onCancelClick()}
              >
                ביטול
              </BlueButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProp = ({
  lockerToLocker,
  stationsList,
  ordersList,
  usersList,
}) => {
  const { lockerToLockerDelivery } = lockerToLocker
  const { sortedStationsNames } = stationsList
  const { isMailOrderCreated, isCleared } = ordersList
  const { usersListArr } = usersList

  return {
    lockerToLockerDelivery,
    sortedStationsNames,
    isMailOrderCreated,
    isCleared,
    usersListArr,
  }
}

export default connect(mapStateToProp, {
  clearDeliveryDetails,
  fetchUserByMobile,
})(CreateLockerToLockerOrderModal)
