import { ON_SEARCH_DATA_CHANGE } from '../actions/types'

const INIT_STATE = {
    keyword: '',
}

const SearchFieldReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ON_SEARCH_DATA_CHANGE:
            return {
                ...state,
                keyword: action.payload
            }
        default:
            return state;
    }
}

export default SearchFieldReducer;