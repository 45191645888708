import {
    ON_REMOVE_COUPON, 
    ON_UPDATE_COUPON, 
    ON_CREATE_COUPON, 
    FETCH_COUPON, 
    FETCH_COUPONS,
    FETCH_ACTIVE_COUPONS,
    EDIT_COUPON_FORM, 
    CLEAN_COUPON_FORM,
    CHECK_EXIST_COUPON_NAME,
    BEFORE_CHECK_EXIST_COUPON_NAME,
    COUPON_SHOW_LOADING,
    SET_COUPON_TO_EDIT,
    CLEAN_COUPON_UPDATE, 
    CLEAN_COUPON_REMOVE,
    SET_EMPTY_COUPON,
    ON_ORDER_COUPON_REMOVE_SUCCESS,
    FETCH_ORDER_COUPON,
    SET_EMPTY_ORDER_COUPON,

} from '../actions/types';
const emptyCoupon = {
    couponId:null,
    couponName:'',
    couponDescription:'',
    discount:0,
    discountType:'',
    orderType:'',
    startDate:'',
    expiredDate:'',
    createdDate:'',
    updateDate: '',
    createdBy:'',
    isDelete:false,
    isActive:false,
};
const emptyCouponNameCheck = {
    couponName:'',
    isCheckedCouponName:false,
    isExistCouponName:false,
}
const INIT_STATE = {
    couponsList: null,
    couponsActiveList: null,
    coupon: emptyCoupon,
    originalCoupon: emptyCoupon,
    isCreated: false,
    isUpdated: false,
    isRemoved: false,
    isLoading: false,
    couponNameCheck:emptyCouponNameCheck,
   
}

const CouponsReducer = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case FETCH_COUPONS:
            return {
                ...state,
                couponsList: action.payload,
                isLoading: false,
                isUpdated: false,
                isRemoved: false,
                isCreated: false,
                couponNameCheck: emptyCouponNameCheck,
            } 
        case FETCH_ACTIVE_COUPONS:
            return {
                ...state,
                couponsActiveList: action.payload,
                isLoading: false,
            }
        case FETCH_COUPON:
            return {
                ...state,
                coupon: action.payload,
                isLoading: false,
            }
        case ON_ORDER_COUPON_REMOVE_SUCCESS:
        case SET_EMPTY_COUPON:
            return {
                ...state,
                coupon: emptyCoupon,
            }    
        case ON_CREATE_COUPON:
            return {
                ...state,
                coupon: action.payload,
                isCreated: true,
                isUpdated: false,
                isRemoved: false,
                couponNameCheck: emptyCouponNameCheck,
                isLoading: false,
            }
        case ON_UPDATE_COUPON:
            return {
                ...state,
                coupon: action.payload,
                isCreated: false,
                isUpdated: true,
                isRemoved: false,
                couponNameCheck: emptyCouponNameCheck,
                isLoading: false,
            }
        case ON_REMOVE_COUPON:
            
            return {
                ...state,
                coupon: emptyCoupon,
                isCreated: false,
                isUpdated: false,
                isRemoved: action.payload,
                couponNameCheck: emptyCouponNameCheck,
                isLoading: false,
            };
        case EDIT_COUPON_FORM:
            return {
                ...state,
                coupon: action.payload,
            };
        case CLEAN_COUPON_FORM:
            return {
                ...state,
                coupon: emptyCoupon,
                couponNameCheck:{
                    couponName: '',
                    isExistCouponName:false,
                    isCheckedCouponName:false,
                }
            };
        case BEFORE_CHECK_EXIST_COUPON_NAME:
            
            return {
                ...state,
                couponNameCheck:{
                couponName: action.payload,
                isExistCouponName:false,
                isCheckedCouponName:false,
                }
            }
        case CHECK_EXIST_COUPON_NAME:
            return {
                ...state,
                couponNameCheck:{
                    couponName: state.couponNameCheck.couponName,
                    isExistCouponName:action.payload,
                    isCheckedCouponName:true,
                    },
                isLoading: false,
            }
        case COUPON_SHOW_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case SET_COUPON_TO_EDIT:
            return {
                ...state,
                originalCoupon: action.payload,
                coupon: action.payload,
            }
        case CLEAN_COUPON_UPDATE:
            return {
                ...state,
                isUpdated: false,
            }
        case CLEAN_COUPON_REMOVE:
            return {
                ...state,
                isRemoved: false,
            }  
        //case RESET_ALL:return INIT_STATE;
        default:
            return state;
    }
}

export default CouponsReducer;