import axios from "axios"
import api from "../api"

import {
  GET_STATION_LOCKERS_SUCCESS,
  GET_STATION_LOCKERS_FAIL,
  ON_START_LOCKERS_LOADING,
  SET_LOCKERS_STATION,
  UPDATE_LOCKER_STATUS_SUCCESS,
  UPDATE_LOCKER_STATUS_FAIL,
  UPDATE_LOCKER_STATUS_LOADING,
  RESET_UPDATE_LOCKER_STATUS_ERROR,
  UPDATE_LOCKER_SIZE_SUCCESS,
  UPDATE_LOCKER_SIZE_FAIL,
} from "./types"

export const startLockersLoading = () => {
  return (dispatch) => {
    dispatch({
      type: ON_START_LOCKERS_LOADING,
    })
  }
}

export const fetchStationLockers = (stationId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }

    axios
      .get(`${api.getLockersList}/${stationId}`, options)
      .then((res) => {
        dispatch({
          type: GET_STATION_LOCKERS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: GET_STATION_LOCKERS_FAIL,
          payload: err,
        })
        console.log("Failed to fetch station lockers")
        console.log(err)
      })
  }
}

export const setLockersStation = (stationId) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCKERS_STATION,
      payload: stationId,
    })
  }
}

export const setUpdateLockerStatusLoading = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOCKER_STATUS_LOADING,
    })
  }
}

export const resetUpdateLockerStatusError = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_UPDATE_LOCKER_STATUS_ERROR,
    })
  }
}

export const updateLockerStatus = (locker, stationId) => {
  return (dispatch) => {
    const data = {
      id: locker.lockerId,
      lockerStatus: locker.lockerStatus,
      lockerType: locker.lockerType,
      name: locker.lockerName,
      orderId: locker.order ? locker.order.orderId : null,
      note: locker.note,
    }

    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
        "Content-Type": "application/json",
      },
    }
    //axios.put(`${api.updateLockerStatus}/${this.state.locker.lockerId}?status=${data}`, options)
    axios
      .put(api.updateLockerStatus(locker.lockerId), data, options)
      .then((res) => {
        dispatch({
          type: UPDATE_LOCKER_STATUS_SUCCESS,
          payload: res.data,
        })
      })
      .then(() => {
        if (stationId) {
          fetchStationLockers(stationId)
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_LOCKER_STATUS_FAIL,
          payload: err,
        })
        console.log("Failed to update locker status")
        console.log(err)
      })
  }
}

export const updateToEmptyLockerStatus = (locker, stationId) => {
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
        "Content-Type": "application/json",
      },
    }
    const data = {
      id: locker.lockerId,
      lockerStatus: locker.lockerStatus,
      lockerType: locker.lockerType,
      name: locker.lockerName,
      orderId: locker.order ? locker.order.orderId : null,
      note: locker.note,
    }
    axios
      .put(api.clearLocker(locker.lockerId), data, options)
      .then((res) => {
        dispatch({
          type: UPDATE_LOCKER_STATUS_SUCCESS,
          payload: res.data,
        })
      })
      .then(() => {
        if (stationId) {
          fetchStationLockers(stationId)
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_LOCKER_STATUS_FAIL,
          payload: err,
        })
        console.log("Failed to update locker status")
        console.log(err)
      })
  }
}

export const updateLockerSize = (lockerId, lockerSizeId) => {
  console.log("lockerId:" + lockerId + ", lockerSizeId:" + lockerSizeId)
  return (dispatch) => {
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
        "Content-Type": "application/json",
      },
    }

    axios
      .put(api.updateLockerSize(lockerId, lockerSizeId), null, options)
      .then((res) => {
        dispatch({
          type: UPDATE_LOCKER_SIZE_SUCCESS,
          payload: res.data,
        })
      })

      .catch((err) => {
        dispatch({
          type: UPDATE_LOCKER_SIZE_FAIL,
          payload: err,
        })
        console.log("Failed to update locker size")
        console.log(err)
      })
  }
}
