import React, { useEffect, useRef } from "react"
import Modal from "../Modal"
import { SmallHeader, BlueButton } from "../../common"
import "./style.scss"

const NotificationModal = ({ show, type, title, text, onOkClick }) => {
  const modalContainerRef = useRef(null)

  if (!show) {
    return <noscript />
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onOkClick()
    }
  }

  return (
    <Modal extraClass="noti-modal-wrap">
      <div
        className="noti-modal-container"
        onKeyDown={handleKeyDown}
        ref={modalContainerRef}
      >
        <SmallHeader title={title} type={type} />
        <div className="noti-modal-content-wrapper">{text}</div>
        <div className="noti-btn-wrapper">
          <BlueButton
            autoFocus={true}
            tabIndex={0}
            extraClass="noti-modal-button"
            onClick={(e) => onOkClick()}
          >
            אישור
          </BlueButton>
        </div>
      </div>
    </Modal>
  )
}

export default NotificationModal
