import React from 'react';

import './style.css';

const OrderItem = ({ item, header }) => {
    const { processName, quantity, itemPrice } = item;

    return (
        <div className='or-itm-wrapper'>
            <div className={`or-itm-data ${header ? 'or-itm-header' : ''}`}>
                {processName}
            </div>
            <div className={`or-itm-data ${header ? 'or-itm-header' : ''}`}>
                {quantity}
            </div>
            <div className={`or-itm-data ${header ? 'or-itm-header' : ''}`}>
                {itemPrice}
            </div>
        </div>
    );
}

export default OrderItem;
