import React, { Component } from "react"
import { Route } from "react-router-dom"
import StationStatusScreen from "./StationStatusScreen"
import LockersPage from "./LockersPage"
import LockersHistoryPage from "./LockersHistoryPage"
import RelocateOrderPage from "./RelocateOrder"
import LockersSensorInfraPage from "./LockersSensorInfraPage"

class LockersScreen extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <>
        <Route
          exact
          path="/LockersScreen/lockers"
          render={(props) => <LockersPage {...props} />}
        />
        <Route
          path="/LockersScreen/lockers/relocate-order/:orderId"
          render={(props) => <RelocateOrderPage {...props} />}
        />
        <Route
          exact
          path="/LockersScreen/history"
          render={(props) => <LockersHistoryPage {...props} />}
        />
        <Route
          exact
          path="/LockersScreen/lockersSensorInfra"
          render={(props) => <LockersSensorInfraPage {...props} />}
        />
        <Route
          exact
          path="/LockersScreen/status"
          render={(props) => <StationStatusScreen {...props} />}
        />
      </>
    )
  }
}

export default LockersScreen
