import axios from "axios"
import api from "../api"

import {
  ON_GET_ORDER_IMAGES_SUCCESS,
  ON_GET_ORDER_IMAGES_FAIL,
  ON_CLEAR_ORDER_IMAGES,
} from "./types"

import errorManager from "../utils/ErrorManager"

export const getOrderImages = (orderId) => {
  return (dispatch) => {
    axios
      .get(api.getOrderImages(orderId))
      .then((res) => {
        dispatch({
          type: ON_GET_ORDER_IMAGES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_ORDER_IMAGES_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearOrderImages = () => {
  return {
    type: ON_CLEAR_ORDER_IMAGES,
  }
}
