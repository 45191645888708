import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { SmallHeader, BlueButton } from "../../common"
import {
  setSourceStation,
  clearSetSourceStation,
  getOrderDetails,
} from "../../../actions"
import Modal from "../Modal"
import Select from "react-select"
import NotificationModal from "../NotificationModal"
import "./style.scss"

const SelectStationSourceChangeModal = (props) => {
  const [validationMsg, setValidationMsg] = useState("")
  const [sourceStationNum, setSourceStationNumber] = useState("")
  const [sourceStationId, setSourceStationId] = useState("")

  const onCancelClick = () => {
    clearState()
    props.onCloseClick()
    props.clearSetSourceStation()
  }

  const onSubmitClick = () => {
    let isValid = validateInput()
    if (isValid) {
      props.setSourceStation(props.order.id, sourceStationId)
      clearState()
    }
  }

  const clearState = () => {
    setSourceStationNumber("")
    setSourceStationId("")
    setValidationMsg("")
  }

  const validateInput = () => {
    if (!sourceStationNum) {
      setValidationMsg("אנא בחר עמדת יעד")
      return false
    }
    setValidationMsg("")
    return true
  }

  const onCloseModal = () => {
    props.getOrderDetails(props.order.id)
    onCancelClick()
    props.clearSetSourceStation()
  }

  if (!props.show) {
    return null
  }

  return (
    <Modal extraClass="cror-modal-wrap">
      <div className="cror-modal-container">
        <div className="locker-to-locker-delivery-modal">
          <SmallHeader title="שינוי עמדת מקור" />
          <div className="modal-body">
            <div className="person-ditails-wrapper">
              {props.order && props.order.stationSource && (
                <div>
                  <div className="order-data-caption"> עמדת מקור נוכחית:</div>
                  <div
                    className="order-data-value"
                    title={props.order.stationSource.branchDisplayAddress}
                  >
                    {props.order.stationSource.stationNumber} -{" "}
                    {props.order.stationSource.branchDisplayName} <br />
                    <br />
                  </div>
                </div>
              )}
              <div className="input-wrapper">
                <span className="input-title sub-title">עמדת מקור </span>{" "}
                <Select
                  className="station-selector"
                  key={sourceStationNum}
                  placeholder="בחר..."
                  defaultValue={props.sortedStationsNames.find(
                    (option) => option.value === sourceStationNum
                  )}
                  options={props.sortedStationsNames}
                  onChange={(e) => {
                    setValidationMsg("")
                    setSourceStationNumber(e.value)
                    setSourceStationId(e.stationId)
                  }}
                />
              </div>
            </div>
            <div className="validation-msg">
              {validationMsg ? validationMsg : null}
            </div>
            <div className="cror-modal-status">
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => onSubmitClick()}
              >
                אישור
              </BlueButton>
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => onCancelClick()}
              >
                ביטול
              </BlueButton>
            </div>
          </div>
        </div>
      </div>

      <NotificationModal
        show={props.changeSourceStationSuccess}
        title={" שינוי עמדת מקור"}
        text={" עמדת מקור שונתה בהצלחה"}
        onOkClick={() => onCloseModal()}
      />
      <NotificationModal
        show={props.changeSourceStationFail}
        title={" שינוי עמדת מקור"}
        text={"תקלה בשינוי עמדת מקור"}
        onOkClick={() => props.onCloseClick()}
      />
    </Modal>
  )
}

const mapStateToProp = ({ stationsList, ordersList }) => {
  const { sortedStationsNames } = stationsList
  const { changeSourceStationSuccess, changeSourceStationFail } = ordersList
  return {
    sortedStationsNames,
    changeSourceStationSuccess,
    changeSourceStationFail,
  }
}

export default connect(mapStateToProp, {
  setSourceStation,
  clearSetSourceStation,
  getOrderDetails,
})(SelectStationSourceChangeModal)
