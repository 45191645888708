import React, { Component } from "react"
import { connect } from "react-redux"

import Modal from "../Modal"
import UserDetails from "./UserDetails"
import { fetchIfEmpty } from "../../../utils"
import {
  //fetchAllOrderStatuses,
  //fetchAllUsers,
  //getUserDetails,
  onUsersSearchNotValidInput,
  onStartLoadingUsers,
  fetchUserByMobile,
  getProcesses,
} from "../../../actions"
import {
  SmallHeader,
  BlueButton,
  InputFiled,
  SelectVal,
  Editable,
} from "../../common"
import Select from "react-select"
import "./style.css"
import OrderItemsList from "./OrderItemsList"

class CreateEditOrderModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobile: "",
      status: 1,
      totalPrice: 0,
      location: "Sorting center",
      orderItems: [],
      userData: null,
      errMsg: null,
      stationNumber: null,
      proccessesObject: {},
    }
  }

  initState() {
    this.setState({
      mobile: "",
      status: 1,
      totalPrice: 0,
      location: "Sorting center",
      orderItems: [],
      userData: null,
      errMsg: null,
      stationNumber: 0,
    })
  }

  componentWillMount() {
    this.initState()
    //this.props.fetchAllOrderStatuses();
    //this.props.fetchAllUsers();
    this.props.getProcesses()
  }

  componentDidUpdate(nextProps, nextState) {
    const { userData, stationNumber } = this.state
    if (userData && userData.stationNumber != 0 && stationNumber == 0) {
      this.setState({ stationNumber: userData.stationNumber })
    }
  }

  componentWillReceiveProps(newProps) {
    const { orderToEdit, processes } = newProps

    if (orderToEdit) {
      const {
        mobilePhone: mobile,
        orderStatus: status,
        orderTotalPrice: totalPrice,
        locationId: location,
        orderItems,
      } = orderToEdit

      this.setState({ mobile, status, totalPrice, location, orderItems })
      this.onMobileChange(mobile)
    }

    if (
      newProps.usersListArr !== this.props.usersListArr &&
      newProps.usersListArr.filter(
        (itm) => itm.mobilePhone === this.state.mobile
      ).length > 0
    ) {
      this.setState({
        userData: newProps.usersListArr.filter(
          (itm) => itm.mobilePhone === this.state.mobile
        )[0],
      })
    }

    if (newProps.show !== this.props.show && newProps.show) {
      this.initState()
    }

    if (processes) {
      const filteredProccesses = this.crateProccessesObject(processes)
      this.setState({ proccessesObject: filteredProccesses })
    }
  }

  crateProccessesObject(processes) {
    const filteredProccesses = processes
      .filter((object) => object.isPrintable)
      .map((itm) => {
        return {
          label: itm.processName_he,
          value: itm.processName_en,
          processId: itm.processId,
        }
      })
    return filteredProccesses
  }

  onInputChange(inputName, val) {
    this.setState({ [inputName]: val })
  }

  onSubmitClick() {
    const {
      mobile,
      status,
      totalPrice,
      location,
      orderItems,
      userData,
      stationNumber,
    } = this.state

    if (!userData) {
      this.setState({ errMsg: "לקוח לא מזוהה, אנא הזן מספר טלפון קיים" })
      return
    }

    if (orderItems.length < 1) {
      this.setState({ errMsg: "אין פריטים בהזמנה זו, אנא הזן פריטים" })
      return
    }
    if (!stationNumber || stationNumber == 0) {
      this.setState({ errMsg: "אנא בחר עמדה" })
      return
    }
    this.setState({ errMsg: "" })

    this.props.onSubmitOrderResultClick({
      mobile,
      status,
      totalPrice,
      location,
      orderItems,
      stationNumber,
    })
  }

  onCancelClick() {
    this.initState()
    this.props.onCancelClick()
  }

  onOrderChanged(order) {
    const { orderItems } = order

    this.setState({ orderItems })
  }

  onNewItemAdded(item) {
    const { userData, orderItems, totalPrice } = this.state

    let calcPrice = item.quantity * item.itemPrice

    if (userData && userData.customerDiscount) {
      calcPrice =
        item.quantity * item.itemPrice * (1 - userData.customerDiscount / 100)
    }

    this.setState({
      orderItems: [...orderItems, item],
      totalPrice: (parseFloat(totalPrice) + calcPrice).toFixed(2),
    })
  }

  onMobileChange(mobile) {
    const { usersListArr } = this.props

    const userData = usersListArr.filter((itm) => itm.mobilePhone === mobile)[0]
    if (userData !== undefined && userData.length > 0) {
      this.setState({
        mobile,
        userData: userData[0],
      })
      return
    }
    this.setState({
      mobile,
      userData: "",
    })
    this.searchByMobile(mobile)
  }

  searchByMobile(value) {
    if (!value || value === undefined || value.length !== 10) {
      this.props.onUsersSearchNotValidInput()
      return
    }
    this.props.onStartLoadingUsers()
    this.props.fetchUserByMobile(value)
  }

  renderErrIfAny(err) {
    if (!err) {
      return <div>&nbsp;</div>
    }

    return <div className="cror-modal-error-msg">{err} ונסה שוב</div>
  }

  render() {
    const { statusesObj, show, sortedStationsNames } = this.props

    if (!show) {
      return <noscript />
    }

    const statusesObjNewForNewLaundry = statusesObj.filter(
      (obj) => obj.value === 1 || obj.value === 7
    )

    const {
      mobile,
      totalPrice,
      orderItems,
      status,
      userData,
      errMsg,
      proccessesObject,
    } = this.state

    return (
      <Modal extraClass="cror-modal-wrap">
        <div className="cror-modal-container">
          <SmallHeader title="הזמנה כביסה" />
          <div className="cror-modal-inputs-wrapper">
            <UserDetails
              mobile={mobile}
              onMobileChange={(mobile) => this.onMobileChange(mobile.trim())}
              userData={userData}
            />

            <div className="cror-modal-status">
              סטטוס ההזמנה:
              <Select
                defaultValue={statusesObjNewForNewLaundry.filter(
                  (option) => option.value === 1
                )}
                className="cror-modal-sel-val"
                options={statusesObjNewForNewLaundry}
                onChange={(status) => this.setState({ status: status.value })}
              />
              <div />
            </div>
            <div className="cror-modal-status">
              <div className="input-title"> עמדה:</div>
              {userData && sortedStationsNames ? (
                <div className="station-selector">
                  <Select
                    placeholder="בחר..."
                    defaultValue={sortedStationsNames.find(
                      (option) => option.value === userData.stationNumber
                    )}
                    options={sortedStationsNames}
                    onChange={(e) => this.setState({ stationNumber: e.value })}
                  />
                </div>
              ) : null}

              <div />
            </div>
            <div className="cror-modal-status">
              סכום ההזמנה:
              <div className="cror-modal-sum">₪ {totalPrice}</div>
              <div />
            </div>

            <OrderItemsList
              items={orderItems}
              addNewItem={(itm) => {
                this.onNewItemAdded(itm)
              }}
              processes={proccessesObject}
            />

            {this.renderErrIfAny(errMsg)}

            <div className="cror-modal-status">
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => this.onSubmitClick()}
              >
                אישור
              </BlueButton>
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => this.onCancelClick()}
              >
                ביטול
              </BlueButton>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = ({
  ordersStatuses,
  usersList,
  stationsList,
  priceListsSection,
}) => {
  const { orderStatusesObj } = ordersStatuses
  const { usersListArr } = usersList
  const { sortedStationsNames } = stationsList
  const { processes } = priceListsSection

  return {
    statusesObj: orderStatusesObj,
    usersListArr,
    sortedStationsNames,
    processes,
  }
}

export default connect(mapStateToProps, {
  //getUserDetails,
  onUsersSearchNotValidInput,
  onStartLoadingUsers,
  fetchUserByMobile,
  getProcesses,
})(CreateEditOrderModal)
