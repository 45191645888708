import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { BlueButton } from "../../../common/BlueButton"
import {
  fetchRoutes,
  fetchRouteStations,
  getOrdersByRoute,
  clearOrdersByRoute,
} from "../../../../actions"
import Select from "react-select"
import "./Driver.scss"
import { useReactToPrint } from "react-to-print"

const Driver = (props) => {
  const [ordersByStation, setOrdersByStation] = useState(null)
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  useEffect(() => {
    props.clearOrdersByRoute()
    props.fetchRoutes()
  }, [])

  useEffect(() => {
    const orders = groupOrdersByStation(props.ordersListByRoute)
    setOrdersByStation(orders)
  }, [props.ordersListByRoute])

  const getRoutStations = (routeId) => {
    props.getOrdersByRoute(routeId)
  }

  const groupOrdersByStation = (ordersListByRoute) => {
    if (ordersListByRoute) {
      var obj = ordersListByRoute.reduce(function (r, e) {
        if (!r[e.stationId]) r[e.stationId] = [e]
        else
          r[e.stationId] = Array.isArray(r[e.stationId])
            ? r[e.stationId].concat(e)
            : [r[e.stationId]].concat(e)
        return r
      }, {})

      var result = Object.keys(obj).map((e) => obj[e])
      return result
    }
  }

  const getOrdersCount = (stations) => {
    let ordersReturnCount = []
    let ordersPickupCount = []
    const allowedOrderTypes = [1, 2, 5, 7, 8, 12, 18, 21, 31, 32]
    stations.map((item) => {
      if (!allowedOrderTypes.includes(item.orderType)) {
        return null
      }
      if (item.orderStatus === 3) {
        ordersReturnCount.push(item.orderType == 1 ? "כ " : "ח ")
      }
      if (item.orderStatus === 0 || item.orderStatus === 13) {
        ordersPickupCount.push(item.orderType == 1 ? "כ " : "ח ")
      }
    })
    return (
      <>
        <td className="orders-count">{ordersPickupCount}</td>
        <td className="orders-count">{ordersReturnCount}</td>
      </>
    )
  }

  const renderOrders = () => {
    return ordersByStation.map((station, index) => {
      return (
        <tr key={index}>
          <td>{station[0].branchName}</td>
          <td>{station[0].stationAddress}</td>
          {getOrdersCount(station)}
        </tr>
      )
    })
  }

  return (
    <div className="driver-page-wrapper">
      <section ref={componentRef} className="driver-page">
        <div className="title">דוח משימות נהג</div>{" "}
        <div className="inputs-wrapper">
          <Select
            className="select-routs"
            options={props.routesList}
            onChange={(e) => {
              getRoutStations(e.value)
            }}
          />
          {ordersByStation ? (
            <BlueButton
              type="button"
              extraClass="btn-print"
              onClick={handlePrint}
            >
              הדפס{" "}
            </BlueButton>
          ) : null}
        </div>
        {ordersByStation ? (
          <table>
            <tr>
              <th>שם עמדה</th>
              <th>כתובת</th>
              <th>איסוף</th>
              <th>החזרה</th>
            </tr>
            {renderOrders(ordersByStation)}
          </table>
        ) : null}
      </section>
    </div>
  )
}

const mapStateToProp = ({ routes, ordersList }) => {
  const routesList = routes.routes
  const routeStations = routes.routeStations
  const { ordersListByRoute } = ordersList
  return { routesList, routeStations, ordersListByRoute }
}

export default connect(mapStateToProp, {
  fetchRoutes,
  fetchRouteStations,
  getOrdersByRoute,
  clearOrdersByRoute,
})(Driver)
