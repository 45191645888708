import React, { Component } from "react"
import { connect } from "react-redux"
import {
  getOrdersBySupplier,
  UpdateOrderAfterReceivingLaundry,
  clearUpdateSuccessFailStatus,
  fetchAllOrders,
  setExternalOrderToEdit,
  updateOrderBags,
  clearOrderBagsStatus,
  notificateEndOfScanning,
} from "../../../../../actions"
import OrderTable from "./OrderTable/OrderTable.js"
import NotificationPopup from "../../../../Modals/NotificationPopup/notificationPopup.js"
import YesNoPopup from "../../../../Modals/YesNoPopup/yesNoPopup.js"
import { BlueButton } from "../../../../common"
import BagsCount from "./BagsCount/BagsCount.js"
import beep from "../../../../../assets/audio/beep.mp3"
import error from "../../../../../assets/audio/error.wav"
import "./style.scss"

class ScanOrders extends Component {
  constructor(props) {
    super(props)
    this.orderNumInput = React.createRef()
    this.state = {
      laundryOrdersList: [],
      supplierId: this.props.location.state.supplierId,
      supplierName: this.props.location.state.supplierName,
      operatorAction: this.props.location.state.operatorAction,
      orderNumber: null,
      scanedOrderNumber: null,
      isScaned: false,
      showPackageNotFoundModal: false,
      showConfirmFinishModal: false,
      orderForEdit: null,
      isMount: false,
      showBugsPopup: false,
      estimatedBagsQuantity: 0,
      selectedOrderId: null,
      scannedOrdersList: [],
    }
    this.timeout = null
  }

  clearState() {
    this.setState({
      laundryOrdersList: [],
      orderNumber: null,
      scanedOrderNumber: null,
      isScaned: false,
      showPackageNotFoundModal: false,
      orderForEdit: null,
      scannedOrdersList: [],
    })
    this.props.clearUpdateSuccessFailStatus()
  }

  componentWillMount() {
    const { supplierId, operatorAction } = this.state
    this.props.getOrdersBySupplier(
      supplierId,
      operatorAction === "send" ? 16 : 17
    )
    this.clearState()
  }

  componentWillReceiveProps(newProps) {
    const {
      unauthorized,
      history,
      ordersListBySupplier,
      isOrderUpdatedAfterLaundery,
      orderBagsUpdateSuccess,
    } = newProps
    const { laundryOrdersList, orderForEdit, isMount, supplierId } = this.state
    if (unauthorized) {
      history.push("/")
    }
    if (!isMount) {
      if (ordersListBySupplier && laundryOrdersList.length === 0) {
        this.reciveOrSendOrders(ordersListBySupplier)
        this.setState({ isMount: true })
      }
    }

    if (isOrderUpdatedAfterLaundery) {
      this.removeOrdeFromList()
      this.props.clearUpdateSuccessFailStatus()
    }
    if (newProps.ordersListArr && orderForEdit) {
      if (newProps.ordersListArr.length > 0) {
        this.setState({ orderForEdit: null })
        orderForEdit.orderId = orderForEdit.id
        this.props.setExternalOrderToEdit(orderForEdit)
        this.props.history.push("/OrdersEditingScreen")
      }
    }
    if (orderBagsUpdateSuccess) {
      this.props.getOrdersBySupplier(supplierId, 17)
      this.props.clearOrderBagsStatus()
      this.setState({ isMount: false, laundryOrdersList: [] })
    }
  }

  removeOrdeFromList() {
    const { scannedOrdersList, scanedOrderNumber, laundryOrdersList } =
      this.state
    new Audio(beep).play()
    this.orderNumInput.current.disabled = true
    this.setState({ isScaned: true })
    const timer = setTimeout(() => {
      // this.setState((prevState) => ({
      //   isScaned: false,
      //   laundryOrdersList: prevState.laundryOrdersList.filter(
      //     (el) => el.orderNumber != scanedOrderNumber
      //   ),
      //   scannedOrdersList: [...scannedOrdersList, scanedOrderNumber],
      //   orderNumber: null,
      // }))

      for (let i = 0; i < laundryOrdersList.length; i++) {
        if (laundryOrdersList[i].orderNumber == scanedOrderNumber) {
          const scanedOrder = laundryOrdersList.splice(i, 1) // removes the item
          this.setState(() => ({
            isScaned: false,
            scannedOrdersList: [...scannedOrdersList, scanedOrder[0]],
            orderNumber: null,
          }))
          break
        }
      }

      this.orderNumInput.current.value = ""
      this.orderNumInput.current.disabled = false
      this.orderNumInput.current.focus()
    }, 2000)
  }

  filterOrderForSending(ordersList) {
    const { supplierId } = this.state
    if (ordersList) {
      var filteredOrders = ordersList.filter(function (el) {
        return el.serviceSupplierId === supplierId && el.orderStatus === 16
      })
      this.setState({ laundryOrdersList: filteredOrders })
    }
  }

  filterOrderForReciving(ordersList) {
    const { supplierId } = this.state
    if (ordersList) {
      var filteredOrders = ordersList.filter(function (order) {
        if (order.serviceSupplierId === supplierId)
          if (order.orderStatus === 17) {
            // const lastRecord = order.historyRecords[order.historyRecords.length - 1]
            const firstRecordOutForService = order.historyRecords.find(
              (element) => {
                return element.orderStatus == 17
              }
            )

            const today = new Date()
            let localStatusDate = new Date(firstRecordOutForService.statusDate)
            if (
              localStatusDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)
            ) {
              return order
            }
          }
      })
      this.setState({ laundryOrdersList: filteredOrders })
    }
  }

  debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => func.apply(this, args), delay)
    }
  }

  debouncedFindOrderInList = this.debounce((orderNum) => {
    this.findOrderInList(orderNum)
  }, 400)

  onChangeOrderNumber = (orderNum) => {
    this.setState({ orderNumber: orderNum, scanedOrderNumber: orderNum })
    this.debouncedFindOrderInList(orderNum)
  }

  findOrderInList = (orderNum) => {
    const order = this.state.laundryOrdersList.find(
      (elm) => elm.orderNumber == orderNum
    )
    if (order) {
      this.updateOrderStatus(order)
    } else {
      new Audio(error).play()
      this.setState({ showPackageNotFoundModal: true })
    }
  }

  updateOrderStatus = (order) => {
    let newstatus
    if (this.state.operatorAction === "send") {
      newstatus = 17
    }
    if (this.state.operatorAction === "recive") {
      newstatus = 3
    }
    if (newstatus) {
      order.orderStatus = newstatus
      this.props.UpdateOrderAfterReceivingLaundry(order)
    }
  }

  onEditClick = (order) => {
    this.setState({ orderForEdit: order })
    this.props.fetchAllOrders()
  }

  onBagsQuantityClick = (order) => {
    this.setState({
      estimatedBagsQuantity: order.bagsQuantity,
      selectedOrderId: order.id,
      showBugsPopup: true,
    })
  }

  confirmBagsCount = (bags) => {
    this.setState({ showBugsPopup: false })
    this.props.updateOrderBags(this.state.selectedOrderId, bags)
  }

  closeBagsCountPopup = () => {
    this.setState({ showBugsPopup: false })
  }

  closeNotPackageFoundModal() {
    this.setState({ showPackageNotFoundModal: false, orderNumber: null })
    this.orderNumInput.current.value = ""
    this.orderNumInput.current.focus()
  }

  reciveOrSendOrders(ordersList) {
    switch (this.state.operatorAction) {
      case "recive":
        this.filterOrderForReciving(ordersList)
        break
      case "send":
        this.filterOrderForSending(ordersList)
        break
    }
  }

  onFinishScan() {
    if (this.state.laundryOrdersList.length > 0) {
      this.setState({ showConfirmFinishModal: true })
    }
    if (this.state.laundryOrdersList.length === 0) {
      this.props.history.push("/HomeScreen")
    }
  }

  confirmFinishScanning() {
    if (this.state.laundryOrdersList) {
      let orderNumbersArr = this.state.laundryOrdersList.map(
        (order) => order.orderNumber
      )
      this.props.notificateEndOfScanning(orderNumbersArr)
      this.setState({ showConfirmFinishModal: false })
      this.props.history.push("/HomeScreen")
    }
  }

  searchForOrder() {
    this.props.history.push({
      pathname: "/OrdersListScreen",
      state: {
        searchingOrderNum: this.state.scanedOrderNumber,
      },
    })
  }

  render() {
    const {} = this.props
    const {
      supplierName,
      laundryOrdersList,
      scanedOrderNumber,
      isScaned,
      showPackageNotFoundModal,
      orderNumber,
      showConfirmFinishModal,
      operatorAction,
      showBugsPopup,
      estimatedBagsQuantity,
      scannedOrdersList,
    } = this.state
    return (
      <section className="scan_orders">
        <h1 className="title">
          {operatorAction == "send"
            ? "חבילות יוצאות למכבסה "
            : "חבילות נכנסות ממכבסה "}
          {supplierName}
        </h1>
        <input
          className="input-orders"
          autoFocus
          ref={this.orderNumInput}
          placeholder="חפש"
          value={orderNumber}
          onChange={(evt) => this.onChangeOrderNumber(evt.target.value)}
        ></input>
        {laundryOrdersList ? (
          <div className="laundrys_count">
            נמצאו {laundryOrdersList.length} רשומות
          </div>
        ) : null}
        {laundryOrdersList && (
          <OrderTable
            ordersList={laundryOrdersList}
            scanedOrderNumber={scanedOrderNumber}
            isScaned={isScaned}
            statusDateTitle={
              operatorAction == "send"
                ? "תאריך מוכן לשרות"
                : "תאריך יציאה לשרות"
            }
            onEditClick={(order) => this.onEditClick(order)}
            onBagsQuantityClick={(order) => this.onBagsQuantityClick(order)}
          />
        )}
        {laundryOrdersList ? (
          <div className="btn_finish_holder">
            <BlueButton
              extraClass="btn-finish"
              onClick={() => this.onFinishScan()}
            >
              סיום סריקה
            </BlueButton>
          </div>
        ) : null}

        {scannedOrdersList && scannedOrdersList.length > 0 ? (
          <div>
            <div className="scaned-orders-title">הזמנות שנסרקו</div>
            <OrderTable
              ordersList={scannedOrdersList}
              scanedOrderNumber={scanedOrderNumber}
              isScaned={isScaned}
              statusDateTitle={
                operatorAction == "send"
                  ? "תאריך מוכן לשרות"
                  : "תאריך יציאה לשרות"
              }
              onEditClick={(order) => this.onEditClick(order)}
              onBagsQuantityClick={(order) => this.onBagsQuantityClick(order)}
            />
          </div>
        ) : null}
        <YesNoPopup
          isOpen={showConfirmFinishModal}
          infoMsg="ישנן חבילות שלא נסרקו, האם אתה בטוח שאתה רוצה לצאת?"
          close={() => this.setState({ showConfirmFinishModal: false })}
          confirm={() => this.confirmFinishScanning()}
        />
        <BagsCount
          show={showBugsPopup}
          estimatedBagsQuantity={estimatedBagsQuantity}
          close={() => this.closeBagsCountPopup()}
          confirmBagsCount={(bagsCount) => this.confirmBagsCount(bagsCount)}
        />
      </section>
    )
  }
}

const mapStateToProps = ({ ordersList }) => {
  const {
    ordersListBySupplier,
    isOrderUpdatedAfterLaundery,
    ordersListArr,
    orderBagsUpdateSuccess,
  } = ordersList
  return {
    ordersListBySupplier,
    isOrderUpdatedAfterLaundery,
    ordersListArr,
    orderBagsUpdateSuccess,
  }
}

export default connect(mapStateToProps, {
  getOrdersBySupplier,
  UpdateOrderAfterReceivingLaundry,
  clearUpdateSuccessFailStatus,
  fetchAllOrders,
  setExternalOrderToEdit,
  updateOrderBags,
  clearOrderBagsStatus,
  notificateEndOfScanning,
})(ScanOrders)
