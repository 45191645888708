import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"

import {
  ON_GET_SEARCHED_ORDERS_SUCCESS,
  ON_GET_SEARCHED_ORDERS_FAIL,
  ON_ORDERS_LOADING,
  SET_SEARCH_PAGE_STEP,
  SET_ORDERS_SEARCH,
  CLEAR_ORDERS_SEARCH,
  ON_GET_SEARCHED_ORDERS_LOADING,
} from "./types"

let abortSearchedOrdersController
export const fetchSearchedOrders = (searchData) => {
  if (abortSearchedOrdersController) {
    abortSearchedOrdersController.abort()
  }

  abortSearchedOrdersController = new AbortController()
  const signal = abortSearchedOrdersController.signal

  const options = {
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
    },
    signal,
  }

  return (dispatch) => {
    dispatch({
      type: ON_GET_SEARCHED_ORDERS_LOADING,
    })
    axios
      .post(api.searchOrders, searchData, options)

      .then((res) => {
        dispatch({
          type: ON_GET_SEARCHED_ORDERS_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_SEARCHED_ORDERS_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const onStartLoadingOrders = () => {
  return (dispatch) => {
    dispatch({
      type: ON_ORDERS_LOADING,
    })
  }
}

export const setSearchPageStep = (pageStep) => {
  return (dispatch) => {
    dispatch({
      type: SET_SEARCH_PAGE_STEP,
      payload: pageStep,
    })
  }
}

export const setOrdersSearch = (search) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDERS_SEARCH,
      payload: search,
    })
  }
}

export const clearOrdersSearch = (search) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDERS_SEARCH,
    })
  }
}
