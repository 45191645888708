import React, { Component } from "react"
import { connect } from "react-redux"
import CitiesFilterer from "../../../reduxified/CitiesFilterer"
import CitiesList from "./CitiesList"
import { Pagination } from "../../../common"

import {
  getAllCities,
  selectCity,
  clearLocalSearchCities,
  localSearchCitiesSetPage,
} from "../../../../actions"

import "./style.css"

class CitiesPage extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      pagedList: [],
    }

    this.state = this.INIT_STATE
  }

  componentWillMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }
    this.props.getAllCities()
  }

  componentWillUnmount() {
    this.props.clearLocalSearchCities()
  }

  resetCurrentPage() {
    this.props.localSearchCitiesSetPage(1)
    this.setState({ pagedList: [] })
  }

  onEditBtnClick(itm) {
    const { history } = this.props
    history.push(`${this.props.match.path}/edit`)
    this.props.selectCity(itm)
  }

  render() {
    const { isLoading, citiesFilteredListArr, pageNum } = this.props
    const { pagedList } = this.state
    return (
      <div className="cities-page-wrapper">
        <CitiesFilterer resetCurrentPage={() => this.resetCurrentPage()} />

        <div key={pagedList.length}>
          <CitiesList
            items={pagedList}
            onItemEditBtnClick={(itm) => this.onEditBtnClick(itm)}
            isLoading={isLoading}
          />
          <Pagination
            fullList={citiesFilteredListArr}
            totalPerPage={50}
            onSubListReady={(pagedList, pageNum) => {
              this.props.localSearchCitiesSetPage(pageNum)
              this.setState({ pagedList })
            }}
            pageNum={pageNum}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ cities, errors }) => {
  const { isLoading, citiesLocalSearch } = cities
  const { unauthorized } = errors
  const { citiesFilteredListArr, page } = citiesLocalSearch

  return {
    unauthorized,
    isLoading,
    citiesFilteredListArr,
    pageNum: page,
  }
}

export default connect(mapStateToProps, {
  getAllCities,
  selectCity,
  clearLocalSearchCities,
  localSearchCitiesSetPage,
})(CitiesPage)
