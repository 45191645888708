import React from "react"

import UserHistoryList from "./UserHistoryList"
import { PaginationNext } from "../../../../common"

import "./style.css"

const UserHistoryTab = ({ userHistoryData, onPageClick }) => {
  const {
    items,
    isLoading,
    isError,
    currentSearchedPageStep,
    currentSearchedPage,
  } = userHistoryData

  return (
    <div className="">
      <div className="adt-inner-wrapper">
        <div className="ucs-pdt-flex1">
          <div className="ucs-title"></div>
          <UserHistoryList
            headers={[
              "תאריך",
              "פעיל",
              "שם פרטי",
              "שם משפחה",
              "אמייל",
              "שם חשבון",
              "שם חברה",
              "תשלום חודשי",
              "הנחה",
              "תוקף הנחה",
              "פיצוי",
              "תפקיד",
              "מנוי דואר",
              "נגישות",
              "הערה",
              "מבצע פעולה",
              "טלפון",
              "מקור",
              "",
            ]}
            itemMap={[
              "createdDate",
              "isDelete",
              "firstName",
              "lastName",
              "email",
              "accountName",
              "companyName",
              "isMonthlyPayment",
              "customerDiscount",
              "discountExpirationDate",
              "compensation",
              "role",
              "isMailSubscribe",
              "isNeedAccessibility",
              "comment",
              "createdByName",
              "mobilePhone",
              "sourceApp",
              "note",
            ]}
            gravity={{
              0: 2,
              1: 1,
              2: 3,
              3: 3,
              4: 4,
              5: 2,
              6: 2,
              7: 2,
              8: 2,
              9: 2,
              10: 2,
              11: 2,
              12: 1,
              13: 2,
              14: 2,
              15: 3,
              16: 2,
              17: 2,
              18: 1,
            }}
            items={items}
            isLoading={isLoading}
            isError={isError}
          />

          <PaginationNext
            pageStep={currentSearchedPageStep}
            page={currentSearchedPage}
            onClick={(page) => onPageClick(page)}
            currentResponseCount={items.length}
          />
        </div>
      </div>
    </div>
  )
}

export default UserHistoryTab
