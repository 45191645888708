import React from "react"
import { useState, useEffect } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import {
  createRouteAreaCode,
  clearRouteAreaCode,
} from "../../../../../actions/RouteAreaCodeAction"
import { BlueButton } from "../../../../common"
import NotificationModal from "../../../../Modals/NotificationModal"

const NewRouteAreaCode = (props) => {
  const [formData, setFormData] = useState({
    routeId: "",
    routeAreaCodeName: "",
    routeAreaCodeDesc: "",
  })
  const [selectedRoute, setSelectedRoute] = useState(null)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const [showFailPopup, setShowFailPopup] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  useEffect(() => {
    return () => {
      props.clearRouteAreaCode()
    }
  }, [])

  useEffect(() => {
    if (props.createAreaCodeSuccess) {
      setShowSuccessPopup(true)
      setShowFailPopup(false)
    }
    if (props.createAreaCodeFail) {
      setShowFailPopup(true)
      setShowSuccessPopup(false)
    }
  }, [props.createAreaCodeSuccess, props.createAreaCodeFail])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSelectChange = (selectedOption) => {
    setSelectedRoute(selectedOption)
    setFormData({
      ...formData,
      routeId: selectedOption.value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (
      !formData.routeId ||
      !formData.routeAreaCodeName ||
      !formData.routeAreaCodeDesc
    ) {
      setFormSubmitted(true)
      return
    }
    props.createRouteAreaCode(formData)
    setFormData({
      routeId: "",
      routeAreaCodeName: "",
      routeAreaCodeDesc: "",
    })
    setSelectedRoute(null)
  }

  return (
    <section className="newRouteAreaCode">
      <h2>יצירת קידומת אזור</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="input-holder">
            <label>צרף למסלול:</label>
            <Select
              className="select-route"
              name="routeId"
              key={props.routesList?.length}
              placeholder={"בחר מסלול"}
              options={props.routesList}
              value={selectedRoute}
              onChange={handleSelectChange}
            />
          </div>

          {formSubmitted && !formData.routeId && (
            <div className="error-message">שדה חובה</div>
          )}
        </div>

        <div className="form-group">
          <div className="input-holder">
            <label>שם קידומת אזור:</label>
            <input
              type="text"
              name="routeAreaCodeName"
              value={formData.routeAreaCodeName}
              onChange={handleInputChange}
            />
          </div>

          {formSubmitted && !formData.routeAreaCodeName && (
            <div className="error-message">שדה חובה</div>
          )}
        </div>
        <div className="form-group">
          <div className="input-holder">
            <label>תיאור קידומת אזור:</label>
            <textarea
              name="routeAreaCodeDesc"
              value={formData.routeAreaCodeDesc}
              onChange={handleInputChange}
            />
          </div>
          {formSubmitted && !formData.routeAreaCodeDesc && (
            <div className="error-message">שדה חובה</div>
          )}
        </div>
        {props.routeAreaCodes.length >= 19 ? (
          <div className="limit">לא ניתן ליצר יותר מי 20 איזורים</div>
        ) : (
          <div className="btn-holder">
            <BlueButton type="submit">שמור</BlueButton>
          </div>
        )}
      </form>
      <NotificationModal
        show={showSuccessPopup}
        type="success"
        title="הצלחה"
        text="קידומת אזור נוצרה בהצלחה"
        onOkClick={() => setShowSuccessPopup(false)}
      />
      <NotificationModal
        show={showFailPopup}
        type="error"
        title="כישלון"
        text="יצירת קידומת אזור נכשלה"
        onOkClick={() => setShowFailPopup(false)}
      />
    </section>
  )
}

const mapStateToProps = ({ routeAreaCode, routes }) => {
  const { createAreaCodeSuccess, createAreaCodeFail, routeAreaCodes } =
    routeAreaCode
  const routesList = routes.routes

  return {
    createAreaCodeSuccess,
    createAreaCodeFail,
    routesList,
    routeAreaCodes,
  }
}
export default connect(mapStateToProps, {
  createRouteAreaCode,
  clearRouteAreaCode,
})(NewRouteAreaCode)
