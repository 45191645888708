import React from "react"

import {
  InputWithLabel,
  SelectValPassive,
  CheckboxAndLabel,
  BlueButton,
} from "../../../../common"
import { isMobileNumInvalid, isEmailInvalid } from "../../../../../utils"

import "./style.scss"

const PersonalDetailsTab = ({
  onInputChange,
  onOpenCancelSubscription,
  userData,
  countries,
  languages,
}) => {
  const {
    firstName,
    lastName,
    mobilePhone,
    email,
    address,
    language,
    isDelete,
    isMailSubscribe,
    isNeedAccessibility,
  } = userData

  const countriesNames = countries.map((x) => x.countryName)
  const languagesNames = languages.map((x) => {
    return `${x.languageName} - ${x.languageNameLocal}`
  })

  return (
    <div className="adt-wrapper">
      <div className="adt-inner-wrapper">
        <InputWithLabel
          extraClass="ucs-input"
          placeholder="שם פרטי"
          maxLength="30"
          value={firstName}
          onChange={(e) => onInputChange("firstName", e.target.value)}
          mandatory
        />
        <InputWithLabel
          extraClass="ucs-input"
          placeholder="שם משפחה"
          maxLength="30"
          value={lastName}
          onChange={(e) => onInputChange("lastName", e.target.value)}
          mandatory
        />
      </div>
      <div className="adt-inner-wrapper">
        <InputWithLabel
          extraClass="ucs-input ucs-ltr"
          placeholder="נייד"
          value={mobilePhone}
          type="number"
          maxsize="12"
          pattern="[0-9]{1-12}"
          onChange={(e) => onInputChange("mobilePhone", e.target.value)}
          mandatory
          displayErr={isMobileNumInvalid(mobilePhone)}
        />

        <InputWithLabel
          extraClass="ucs-input ucs-ltr"
          placeholder="אמייל"
          value={email}
          type="email"
          onChange={(e) => onInputChange("email", e.target.value)}
          displayErr={isEmailInvalid(email)}
          mandatory
        />
      </div>
      <div className="adt-inner-wrapper">
        <div className="ucs-pdt-flex1">
          <div className="ucs-title">מדינה</div>
          <SelectValPassive
            extraClass="ucs-input ucs-input-border"
            valueIndex={
              countriesNames != null && address != null
                ? countriesNames.indexOf(address.countryName)
                : -1
            }
            items={countriesNames}
            placeholder="מדינה"
            onItemSelected={(val) =>
              onInputChange("address", countries[countriesNames.indexOf(val)])
            }
          />
        </div>
        <div className="ucs-pdt-flex1">
          <div className="ucs-title">שפה</div>
          <SelectValPassive
            valueIndex={
              languages != null
                ? languages.map((x) => x.languageCode).indexOf(language)
                : -1
            }
            items={languagesNames}
            placeholder="שפה"
            extraClass="ucs-input ucs-input-border"
            onItemSelected={(val) =>
              onInputChange(
                "language",
                languages[languagesNames.indexOf(val)].languageCode
              )
            }
          />
        </div>
      </div>

      <div className="adt-inner-wrapper">
        <div className="ucs-pdt-flex1">
          <div className="adt-inner-wrapper">
            <InputWithLabel
              extraClass="ucs-input"
              placeholder="סטטוס משתמש"
              value={isDelete || isDelete === undefined ? "לא פעיל" : "פעיל"}
              onChange={(e) => e}
              disabled
            />
          </div>
        </div>
        <div className="ucs-pdt-flex1">
          <div className="adt-inner-wrapper">
            <CheckboxAndLabel checked={isMailSubscribe} disabled>
              לקוח עם מנוי דואר
            </CheckboxAndLabel>
            <BlueButton
              show={isMailSubscribe || true}
              extraClass="ol-edit-btn mail-subscribtion"
              onClick={() => {
                onOpenCancelSubscription()
              }}
            >
              ניהול מנויים
            </BlueButton>
            <CheckboxAndLabel
              checked={isNeedAccessibility}
              onChange={(e) => {
                onInputChange(
                  "isNeedAccessibility",
                  e.target.checked ? true : false
                )
              }}
            >
              <div className="fa fa-wheelchair" aria-hidden="true"></div>
              <div className="label"> נדרש נגישות</div>
            </CheckboxAndLabel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalDetailsTab
