import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"

import {
  ON_GET_READY_TO_LOAD_ORDERS_SUCCESS,
  ON_GET_READY_TO_LOAD_ORDERS_FAIL,
  ON_LOADING_READY_ORDERS,
  ON_PUT_ORDER_TO_LOCKER_SUCCESS,
  ON_PUT_ORDER_TO_LOCKER_FAIL,
  ON_LOADING_WAITINGFORPICKUP_ORDERS,
  ON_GET_WAITINGFORPICKUP_ORDERS_SUCCESS,
  ON_GET_WAITINGFORPICKUP_ORDERS_FAIL,
  ON_LOADING_CREATE_RETURNS_ORDER_SUCCESS,
  ON_CREATE_RETURNS_ORDER_SUCCESS,
  ON_CREATE_RETURNS_ORDER_FAIL,
  CLEAR_CREATE_RETURNS_ORDER_SUCCESS,
} from "./types"

export const fetchReadyToLoadOrders = (stationId) => {
  return (dispatch) => {
    axios
      .get(api.getReadyToLoadOrders(stationId))
      .then((res) => {
        dispatch({
          type: ON_GET_READY_TO_LOAD_ORDERS_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
        console.log("fetchReadyToLoadOrders", res.data)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_READY_TO_LOAD_ORDERS_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const onLoadingReadyToLoadOrders = () => {
  return {
    type: ON_LOADING_READY_ORDERS,
  }
}

export const putOrderToLocker = (orderId, lockerId) => {
  return (dispatch) => {
    axios
      .put(api.putOrderToLocker(orderId, lockerId))
      .then((res) => {
        dispatch({
          type: ON_PUT_ORDER_TO_LOCKER_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
        console.log("putOrderToLocker", res.data)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_PUT_ORDER_TO_LOCKER_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const onLoadingWaitingForPickupOrders = () => {
  return {
    type: ON_LOADING_WAITINGFORPICKUP_ORDERS,
  }
}

export const fetchWaitingForPickupOrders = (stationId) => {
  return (dispatch) => {
    axios
      .get(api.getWaitingForPickupOrders(stationId))
      .then((res) => {
        dispatch({
          type: ON_GET_WAITINGFORPICKUP_ORDERS_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
        console.log("fetchWaitingForPickupOrders", res.data)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_WAITINGFORPICKUP_ORDERS_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const confirmOrderToLocker = (orderId, lockerId) => {
  return (dispatch) => {
    axios
      .put(api.confirmOrder(orderId, lockerId))
      .then((res) => {
        dispatch({
          type: ON_PUT_ORDER_TO_LOCKER_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
        console.log("confirmOrderToLocker", res.data)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_PUT_ORDER_TO_LOCKER_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const createReturnsOrder = (order) => {
  return (dispatch) => {
    dispatch({ type: ON_LOADING_CREATE_RETURNS_ORDER_SUCCESS })
    axios
      .post(api.createReturnsOrder, order)
      .then((res) => {
        dispatch({
          type: ON_CREATE_RETURNS_ORDER_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_CREATE_RETURNS_ORDER_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearCreateReturnsOrder = () => {
  return {
    type: CLEAR_CREATE_RETURNS_ORDER_SUCCESS,
  }
}
