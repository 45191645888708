import api from "../api"
import {
  FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
  FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
  CLEAR_LOCKERS_STATUSES,
} from "./types"
import { axiosGetRetry } from "../utils/ReqsRetry"

export const fetchAllLockersStatuses = (stationId) => {
  return (dispatch) => {
    axiosGetRetry(api.getLockersStatusesByStationId(stationId))
      .then((res) => {
        dispatch({
          type: FETCH_ALL_LOCKERS_STATUSES_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const fetchAllLockersLayout = (stationId) => {
  return (dispatch) => {
    axiosGetRetry(api.getLockersLayoutByStationId(stationId))
      .then((res) => {
        const { layout } = res.data
        dispatch({
          type: FETCH_ALL_LOCKERS_LAYOUT_SUCCESS,
          payload: JSON.parse(layout),
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const clearLockersStatuses = () => {
  return (dispach) => {
    dispach({
      type: CLEAR_LOCKERS_STATUSES,
    })
  }
}
