import React, { Component } from "react"
import { connect } from "react-redux"
import { Route, Link } from "react-router-dom"

import {
  fetchStationSelectOptions,
  fetchLockerStatusSelectOptions,
  removeErrorMessage,
} from "../../../actions"

import DetailedLockersReport from "./DetailedLockersReport"
import StationsStatusReport from "./StationsStatusReport"
import OrderItemReport from "./OrderItemReport"
import CustomerOrdersReport from "./CustomerOrdersReport"
import OutdatedOrdersReport from "./OutdatedOrdersReport"
import BalanceReport from "./BalanceReport/BalanceReport"
import PackageDebitReport from "./PackageDebitReport"
import OrdersMarkAsCloseExport from "./OrdersMarkAsCloseExport"
import OrdersReport from "./OrdersReport"
import ExportOrdersStatus from "./ExportOrdersStatus"
import ExportLaundryPrices from "./ExportLaundryPrices"
import ReservedLockerReport from "./ReservedLockerReport"
import { ErrorMessageSmall } from "../../common"

import "./style.css"

const detailedLockersReportMenuLinkText = "דוח מצב לוקרים מפורט"
const orderItemReportMenuLinkText = "דוח שאילתת פריט"
const stationStatusReportMenuLinkText = "דוח ארונות"
const customerOrdersReportMenuLinkText = "דוח מצב לקוח"
const outdatedOrdersReportMenuLinkText = "דוח מלאי מת"
const balanceReportMenuLinkText = "דוח  השוואה"
const packageDebitReportMenuLinkText = "דוח  חיובי חבילות"
const markAsCloseOrdersExportMenuLinkText = " דוח הזמנות חשודות כסגורות"
const ordersExportMenuLinkText = " דוח הזמנות"
const exportOrdersStatusText = "סטטוס הזמנות"
const laundryPricesReport = "דוח מחירי כביסה"
const reservedLockersReport = "דוח לוקרים שמורים"

class ReportsScreen extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      isLoading: false,
      errorMessage: {
        ExportOrdersMarkAsClose: {
          isShow: false,
          text: "אופס, משהו לא הסתדר. נסה שוב.",
        },
      },
    }

    this.state = this.INIT_STATE
  }
  componentDidMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }
    this.props.fetchStationSelectOptions()
    this.props.fetchLockerStatusSelectOptions()
  }

  componentWillUnmount() {
    this.props.removeErrorMessage()
  }

  renderErrorMessage(error) {
    return (
      <div>
        <p>{error}</p>
        <button onClick={this.props.removeErrorMessage}>Close</button>
      </div>
    )
  }

  updateLoading(status) {
    this.setState((state) => {
      return {
        isLoading: status,
      }
    })
  }
  hideError() {
    this.setState((state) => {
      return {
        errorMessage: {
          ExportUsers: { isShow: false },
          ExportOrders: { isShow: false },
          ExportOrderItems: { isShow: false },
          ExportOrdersMarkAsClose: { isShow: false },
        },
      }
    })
  }
  setError(param, mess) {
    let tempStateErrorMessage = this.state.errorMessage
    tempStateErrorMessage[param] = { isShow: true, text: mess }
    this.setState((state) => {
      return {
        errorMessage: tempStateErrorMessage,
      }
    })
  }

  render() {
    const { error } = this.props

    return (
      <section id="reports-section-container">
        <nav id="reports-section-menu">
          <ul>
            <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/detailed-lockers-report`}>
                {detailedLockersReportMenuLinkText}
              </Link>
            </li>
            <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/order-item-report`}>
                {orderItemReportMenuLinkText}
              </Link>
            </li>
            <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/stations-status-report`}>
                {stationStatusReportMenuLinkText}
              </Link>
            </li>
            <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/customer-orders-report`}>
                {customerOrdersReportMenuLinkText}
              </Link>
            </li>
            <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/outdated-orders-report`}>
                {outdatedOrdersReportMenuLinkText}
              </Link>
            </li>
            <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/cleanbox-balance-report`}>
                {balanceReportMenuLinkText}
              </Link>
            </li>
            <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/package-debit-report`}>
                {packageDebitReportMenuLinkText}
              </Link>
            </li>
            <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/mark-as-close-orders-export`}>
                {markAsCloseOrdersExportMenuLinkText}
              </Link>
            </li>
            <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/orders-status-export`}>
                {exportOrdersStatusText}
              </Link>
            </li>
            <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/laundry-prices-export`}>
                {laundryPricesReport}
              </Link>
            </li>
            <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/reserved-lockers-report`}>
                {reservedLockersReport}
              </Link>
            </li>

            {/* <li className="reports-section-menu-item">
              <Link to={`${this.props.match.url}/orders-report`}>
                {ordersExportMenuLinkText}
              </Link>
            </li> */}
          </ul>
        </nav>
        <main className="reports-section-body">
          <Route
            path={`${this.props.match.url}/detailed-lockers-report`}
            component={DetailedLockersReport}
          />
          <Route
            path={`${this.props.match.url}/order-item-report`}
            component={OrderItemReport}
          />
          <Route
            path={`${this.props.match.url}/stations-status-report`}
            component={StationsStatusReport}
          />
          <Route
            path={`${this.props.match.url}/customer-orders-report`}
            component={CustomerOrdersReport}
          />
          <Route
            path={`${this.props.match.url}/outdated-orders-report`}
            component={OutdatedOrdersReport}
          />
          <Route
            path={`${this.props.match.url}/cleanbox-balance-report`}
            component={BalanceReport}
          />
          <Route
            path={`${this.props.match.url}/package-debit-report`}
            component={PackageDebitReport}
          />
          <Route
            path={`${this.props.match.url}/mark-as-close-orders-export`}
            component={() => (
              <OrdersMarkAsCloseExport
                className="export-content"
                isLoading={() => this.updateLoading()}
                hideError={() => this.hideError()}
                setError={() => this.setError()}
              />
            )}
          />
          <Route
            path={`${this.props.match.url}/orders-report`}
            component={OrdersReport}
          />
          <Route
            path={`${this.props.match.url}/orders-status-export`}
            component={ExportOrdersStatus}
          />
          <Route
            path={`${this.props.match.url}/laundry-prices-export`}
            component={ExportLaundryPrices}
          />
          <Route
            path={`${this.props.match.url}/reserved-lockers-report`}
            component={ReservedLockerReport}
          />
          {/**/}
          <ErrorMessageSmall
            isShow={this.state.errorMessage.ExportOrdersMarkAsClose.isShow}
            hideError={() => this.hideError()}
            className="err-export"
            errorMessage={this.state.errorMessage.ExportOrdersMarkAsClose.text}
          />
        </main>
        {error && this.renderErrorMessage(error)}
      </section>
    )
  }
}

const mapStateToProps = ({ errors }) => {
  const { unauthorized, message } = errors
  return {
    error: message,
    unauthorized,
  }
}

export default connect(mapStateToProps, {
  fetchStationSelectOptions,
  fetchLockerStatusSelectOptions,
  removeErrorMessage,
})(ReportsScreen)
