import React, { useEffect, useState } from "react"
import { BlueButton } from "../../../common/BlueButton"
import cheapsimLogo from "../../../../assets/images/cheapsim-logo.png"
import "./CheapSim.scss"

const CheapSim = (props) => {
  return (
    <section className="CheapSim">
      <div className="title">סריקה CheapSim</div>
      <div>
        <BlueButton
          extraClass="btn-cheapsim"
          onClick={() => props.history.push("/CheapSimPage")}
        >
          <img src={cheapsimLogo} />
        </BlueButton>
      </div>
    </section>
  )
}

export default CheapSim
