import axios from "axios"
import api from "../api"
import {
  SET_LOCKERS_INFRA_SENSOR_STATION,
  ON_LOADING_LOCKERS_INFRA_SENSOR,
  GET_SEARCHED_LOCKERS_INFRA_SENSOR_SUCCESS,
  GET_SEARCHED_LOCKERS_INFRA_SENSOR_FAIL,
  SET_LOCKERS_INFRA_SENSOR_SEARCH,
} from "./types"
import errorManager from "../utils/ErrorManager"
//LockerInfraSensorActions   InfraSensor
export const setLockersInfraSensorStation = (stationId) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCKERS_INFRA_SENSOR_STATION,
      payload: stationId,
    })
  }
}

export const onLoadingLockersInfraSensor = () => {
  return (dispatch) => {
    dispatch({
      type: ON_LOADING_LOCKERS_INFRA_SENSOR,
    })
  }
}

export const setLockersInfraSensorSearch = (search) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCKERS_INFRA_SENSOR_SEARCH,
      payload: search,
    })
  }
}

export const fetchSearchedLockersInfraSensor = (searchData) => {
  const options = {
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
    },
  }

  const data = {
    ...searchData,
    fromDate: searchData.fromDate + " 00:00:00",
    toDate: searchData.toDate + " 23:59:59",
  }

  searchData.fromDate = searchData.fromDate
  searchData.toDate = searchData.toDate

  return (dispatch) => {
    axios
      .post(api.searchLockersInfraSensor, data, options)
      .then((res) => {
        dispatch({
          type: GET_SEARCHED_LOCKERS_INFRA_SENSOR_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: GET_SEARCHED_LOCKERS_INFRA_SENSOR_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}
