import React from "react"
import { Spinner, H1 } from "../../../../../common"
import { getFormatedDate } from "../../../../../../utils/FormatedDate"
import "./OrderTable.scss"

const OrderTable = (props) => {
  const setDateToShow = (order) => {
    switch (props.dateToShow) {
      case "dirtyOrderDate":
        return getFormatedDate(order.dirtyOrderDate)
      case "inWarehouseDate":
        return getFormatedDate(order.inWarehouseDate)
      default:
        return "-"
    }
  }
  const renderTableData = () => {
    return props.ordersList.map((order, index) => {
      const {
        id,
        orderNumber,
        packageNumber,
        stationNumber,
        branchName,
        currentLocation,
        customerFullName,
      } = order

      return (
        <tr
          key={index}
          className={
            order.packageNumber == props.scanedPackageNumber && props.isScaned
              ? "scaned"
              : ""
          }
        >
          <td>{orderNumber}</td>
          <td>{packageNumber}</td>
          <td>{setDateToShow(order)}</td>
          <td>{stationNumber}</td>
          <td>{branchName}</td>
          <td>{currentLocation}</td>
          <td>{customerFullName}</td>
        </tr>
      )
    })
  }

  if (props.isError) {
    return (
      <H1 className="not-valid-search" title="אנא בדוק את הערך של שדה חיפוש" />
    )
  }
  if (props.isLoading) {
    return (
      <div className="list-spinner-wrapper">
        <Spinner zoom={1} />
      </div>
    )
  }

  return (
    <section className="order_table">
      <table>
        <tr>
          <th>מזהה</th>
          <th>מס' חבילה</th>
          <th>{props.statusDateTitle}</th>
          <th>מס' עמדה</th>
          <th>שם סניף</th>
          <th>מיקום הזמנה</th>
          <th>שם לקוח</th>
        </tr>
        {renderTableData()}
      </table>
    </section>
  )
}

export default OrderTable
