export const routesColors: readonly ColourOption[] = [
  { label: "וָרוֹד", label_en: "Pink", value: "#e91e63", color: "#e91e63" },
  { label: "חום", label_en: "Brown", value: "#795548", color: "#795548" },
  { label: "צהוב", label_en: "Yellow", value: "#ffeb3d", color: "#ffeb3d" },
  {
    label: "ירוק בהיר",
    label_en: "Light Green",
    value: "#8bc34a",
    color: "#8bc34a",
  },
  {
    label: "כחול בהיר",
    label_en: "Light Blue",
    value: "#03a9f4",
    color: "#03a9f4",
  },
  {
    label: "אִינדִיגוֹ",
    label_en: "Indigo",
    value: "#3f51b6",
    color: "#3f51b6",
  },
  { label: "סָגוֹל", label_en: "Purpule", value: "#9c27b0", color: "#9c27b0" },
  { label: "אָדוֹם", label_en: "Red", value: "#e51c23", color: "#e51c23" },
  { label: "כָּתוֹם", label_en: "Orange", value: "#ff9800", color: "#ff9800" },
  { label: "ליים", label_en: "Lime", value: "#cddc39", color: "#cddc39" },
]

export const suppliersColors = [
  { label: "ירוק", label_en: "Green", value: "#259b24", color: "#259b24" },
  {
    label: "כחול אפור",
    label_en: "Blue Grey",
    value: "#607d8b",
    color: "#607d8b",
  },
  { label: "אפור", label_en: "Grey", value: "#9e9e9e", color: "#9e9e9e" },
  { label: "טורקיז", label_en: "Cyan", value: "#00bcd4", color: "#00bcd4" },
  {
    label: "כתום-עמוק",
    label_en: "Deep Orange",
    value: "#ff5722",
    color: "#ff5722",
  },
  { label: "עַנבָּר", label_en: "Amber", value: "#ffc107", color: "#ffc107" },
  {
    label: "טקסט-שחור",
    label_en: "Text Black",
    value: "#212121",
    color: "#212121",
  },

  { label: "ירוק כחלחל", label_en: "Teal", value: "#009688", color: "#009688" },
  { label: "כְּחוֹל", label_en: "Blue", value: "#5677fc", color: "#5677fc" },
  {
    label: "סגול עמוק",
    label_en: "Deep Purple",
    value: "#673ab7",
    color: "#673ab7",
  },
]

export interface ColourOption {
  readonly value: string
  readonly label: string
  readonly label_en: string
  readonly color: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
}
