import React from "react"

import { Title, BlueButton } from "../../../../../../common"

import "./style.css"

const OrderItemsHeader = ({}) => {
  return (
    <div className="ewq-table-headers">
      <div className="ewq-table-header-elem">תהליך</div>
      <div className="ewq-table-header-elem">פריט</div>
      <div className="ewq-table-header-elem">צבע</div>
      {/* <div className="ewq-table-header-elem">פטרן</div> */}
      <div className="ewq-table-header-elem">כמות</div>
      {/* <div className='ewq-table-header-elem'>
                מס"ד
                </div> */}
      <div className="ewq-table-header-elem">מחיר</div>
      {/* <div className="ewq-table-header-elem">משקל מקורי</div> */}
      <div className="ewq-table-header-elem ewq-header-elem-flex-2">הערות</div>
      <div className="ewq-table-header-elem">
        <BlueButton extraClass="ewq-table-elem-btn ewq-table-header-elem-btn">
          מחק פריט
        </BlueButton>
      </div>
    </div>
  )
}

export default OrderItemsHeader
