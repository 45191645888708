import {
  ON_GET_SEARCHED_ORDERS_SUCCESS,
  ON_GET_SEARCHED_ORDERS_FAIL,
  ON_GET_SEARCHED_ORDERS_LOADING,
  ON_ORDERS_LOADING,
  SET_SEARCH_PAGE_STEP,
  SET_ORDERS_SEARCH,
  CLEAR_ORDERS_SEARCH,
  CLEAR_CURRENT_CREATE_EDIT_USER_DATA,
  ON_USER_SIGN_IN_RESET_ATTEMPTS,
  UNAUTHORIZED,
} from "../actions/types"

import { isProdEnv, getOrdersResponse } from "../utils"

const INIT_SEARCH = {
  searchText: "",
  stations: [],
  statuses: [],
  orderTypes: [],
  accountTypes: [],
  routeId: null,
  sourceRouteId: null,
  fromDate: "",
  toDate: "",
  isPayed: null,
  isMonthlyPayment: null,
  stationPaymentVersion: null,
  pageStep: 50,
  page: 1,
}

const INIT_STATE = {
  searchedOrdersArr: [],
  orderDetails: null,
  filterBy: "",
  filteredListArr: [],
  externalOrderToEdit: null,

  FiltererActiveTypes: [], // No order types filter is selected by default
  FiltererActiveStatuses: isProdEnv() ? [1] : [],
  FiltererActiveStations: [],
  FiltererIsPaid: 0, // 0 - All, 1 - Paid, 2 - Not Paid

  currentSearchedPage: 1,
  currentSearchedPageStep: 50,

  isLoading: false,
  isError: false,
  search: INIT_SEARCH,
}

const OrdersSearchReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_SEARCHED_ORDERS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case ON_GET_SEARCHED_ORDERS_SUCCESS:
      return {
        ...state,
        searchedOrdersArr: getOrdersResponse(action.payload.orders),
        currentSearchedPage: action.payload.page,
        currentSearchedPageStep: action.payload.pageStep,
        isLoading: false,
        isError: false,
      }
    case ON_GET_SEARCHED_ORDERS_FAIL:
      return {
        ...state,
        searchedOrdersArr: [],
        isLoading: false,
        isError: true,
      }
    case ON_ORDERS_LOADING:
      return {
        ...state,
        searchedOrdersArr: [],
        isLoading: true,
        isError: false,
      }
    case SET_ORDERS_SEARCH:
      return {
        ...state,
        search: action.payload,
      }

    case SET_SEARCH_PAGE_STEP:
      return {
        ...state,
        currentSearchedPageStep: action.payload,
      }
    case CLEAR_ORDERS_SEARCH:
    case CLEAR_CURRENT_CREATE_EDIT_USER_DATA:
    case ON_USER_SIGN_IN_RESET_ATTEMPTS:
    case UNAUTHORIZED:
      return {
        ...state,
        searchedOrdersArr: [],

        isLoading: false,
        search: {
          searchText: "",
          stations: [],
          statuses: [],
          orderTypes: [],
          accountTypes: [],
          routeId: null,
          sourceRouteId: null,
          fromDate: "",
          toDate: "",
          isPayed: null,
          isMonthlyPayment: null,
          stationPaymentVersion: null,
          pageStep: 50,
          page: 1,
        },
      }
    default:
      return state
  }
}

export default OrdersSearchReducer
