import React, { useState } from 'react';

import './style.css';

const SelectCouponValPassive = ({ extraClass, items, onItemSelected, valueIndex }) => {

    const [showItems, setShowItems] = useState(false);

    const arrowDownSrc = showItems ? require('../../../assets/images/arrow-up.png') :
        require('../../../assets/images/arrow-down.png');

    return (
        <div className={`selcouponvalpassive-wrapper ${extraClass ? extraClass : ''}`}>
            <div className='selcouponvalpassive-displayed-itm'
                onClick={e => { setShowItems(!showItems) }}
            >
                <div className='selcouponvalpassive-arrow-down'>
                    <img src={arrowDownSrc} className='selcouponvalpassive-arrow-img' alt='' />
                </div>
                <div className='selcouponvalpassive-itm'>
                    {items[valueIndex]}
                </div>
                
            </div>
            <div className='selcouponvalpassive-itms-list-wrapper'>
                <div className='selcouponvalpassive-itms-list'>
                    {renderItems(showItems, items, valueIndex, onItemSelected, setShowItems)}
                </div>
            </div>
        </div>
    );
};

const renderItems = (show, items, selectedItemIndex, onItemClick, setShowItems) => {
    if (!show) {
        return;
    }

    return items.map((itm, index) => {
        const itemClassName = `selcouponvalpassive-itm selcouponvalpassive-itm-extra ${index === selectedItemIndex ? 'selcouponvalpassive-picker' : ''}`;

        return (
            <div
                className={itemClassName}
                onClick={e => { onItemClick(itm.couponName, index); setShowItems(false); }}
                key={index}
            >
                <div className='coupon-name'>{itm.couponName}</div>
                <div className='coupon-value'>{itm.discountToShow}{itm.discountType===0?'%'  :'₪' }</div>
                 
            </div>
        );
    });
};

export { SelectCouponValPassive };
