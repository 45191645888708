import React from "react"

import { BlueButton } from "../../../../common/BlueButton"
import "./style.css"

const OrderItem = ({ item, itemMap, gravity, isLast, onSelectBtnClick }) => {
  const itemClassName = `ol-item-wrapper ${isLast ? "ol-item-last" : ""}`

  return (
    <div className={itemClassName}>
      {renderItemContent(item, itemMap, gravity)}
      <div>
        <BlueButton
          extraClass="ol-edit-btn"
          onClick={(e) => onClick(item, onSelectBtnClick)}
        >
          טען לתא
        </BlueButton>
      </div>
    </div>
  )
}

const onClick = (item, onSelectBtnClick) => {
  onSelectBtnClick(item)
}

const renderItemContent = (item, itemMap, gravity) => {
  return itemMap.map((itmMap, index) => {
    const flex = gravity[`${index}`] || 1

    let itemContent = item[itmMap]

    if (itmMap === "orderTotalPrice" && item && item[itmMap]) {
      itemContent = item[itmMap].toFixed(2)
    }

    return (
      <div style={{ flex }} key={index}>
        {itemContent}
      </div>
    )
  })
}

export default OrderItem
