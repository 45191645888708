import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { BlueButton } from "../../../common/BlueButton"
import { getAllSuppliers } from "../../../../actions"

import "./WHOperator.scss"

const WHOperator = (props) => {
  const [suppliers, setSuppliers] = useState(null)

  useEffect(() => {
    props.getAllSuppliers()
  }, [])

  useEffect(() => {
    setSuppliers(props.suppliersList)
  }, [props.suppliersList])

  const createSuppliersBlocks = () => {
    return suppliers.map(function (supplier, index) {
      return (
        <div className="supplier_holder" key={index}>
          <p className="supplier_name">{supplier.name}</p>
          <div className="btn_supplier_holder">
            {/* <BlueButton
              extraClass="btn_supplier send"
              onClick={() =>
                props.history.push({
                  pathname: "/ScanOrders",
                  state: {
                    supplierName: supplier.name,
                    supplierId: supplier.id,
                    operatorAction: "send",
                  },
                })
              }
            >
              <p className="block-text">יציאה&nbsp; למכבסה</p>
            </BlueButton> */}
            <BlueButton
              extraClass="btn_supplier recive"
              onClick={() =>
                props.history.push({
                  pathname: "/ScanOrders",
                  state: {
                    supplierName: supplier.name,
                    supplierId: supplier.id,
                    operatorAction: "recive",
                  },
                })
              }
            >
              <p className="block-text">קבלה&nbsp; ממכבסה</p>
            </BlueButton>
          </div>
        </div>
      )
    })
  }

  return (
    <section className="WHOperator">
      <h3>סריקה למכבסה: </h3>
      <div className="laundry-list">
        {suppliers ? createSuppliersBlocks() : null}
      </div>
    </section>
  )
}

const mapStateToProp = ({ suppliers }) => {
  const { suppliersList } = suppliers

  return { suppliersList }
}

export default connect(mapStateToProp, {
  getAllSuppliers,
})(WHOperator)
