import {
  UNAUTHORIZED,
  ON_CREATE_ORDER,
  ON_CREATE_ORDER_SUCCESS,
  ON_CREATE_ORDER_FAIL,
  ON_CREATE_LOCKER_TO_LOCKER_ORDER_SUCCESS,
  ON_CREATE_LOCKER_TO_LOCKER_ORDER_FAIL,
  CLEAR_NEW_LOCKER_TO_LOCKER_ORDER,
} from "../actions/types"

const INIT_STATE = {
  lockerToLockerDelivery: null,
  createLockerToLockerDeliverySuccess: false,
  createLockerToLockerDeliveryFail: false,
}

const LockerToLockerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_CREATE_LOCKER_TO_LOCKER_ORDER_SUCCESS:
      //case ON_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        lockerToLockerDelivery: action.payload,
        createLockerToLockerDeliverySuccess: true,
        createLockerToLockerDeliveryFail: false,
      }
    case ON_CREATE_LOCKER_TO_LOCKER_ORDER_FAIL:
      //case ON_CREATE_ORDER_FAIL:
      return {
        ...state,
        lockerToLockerDelivery: null,
        createLockerToLockerDeliverySuccess: false,
        createLockerToLockerDeliveryFail: true,
      }

    case CLEAR_NEW_LOCKER_TO_LOCKER_ORDER:
      return {
        ...state,
        lockerToLockerDelivery: null,
        createLockerToLockerDeliverySuccess: false,
        createLockerToLockerDeliveryFail: false,
      }

    case UNAUTHORIZED:
      return {
        ...state,
        lockerToLockerDelivery: null,
        createLockerToLockerDeliverySuccess: false,
        createLockerToLockerDeliveryFail: false,
      }
    default:
      return state
  }
}

export default LockerToLockerReducer
