import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTable } from 'react-table';

import { createService, updateService, deleteService } from '../../../../actions';

import './style.css';

const ServicesTable = (props) => {
    const { services, createService, updateService, deleteService } = props;

    const columns = useMemo(
        () => [
            {
                Header: 'Service ID',
                accessor: 'serviceId'
            },
            {
                Header: 'Service name (HE)',
                accessor: 'serviceName_he'
            },
            {
                Header: 'Service name (EN)',
                accessor: 'serviceName_en',
            },
            {
                Header: 'Service name (RU)',
                accessor: 'serviceName_ru',
            },
            {
                Header: 'Service name (AR)',
                accessor: 'serviceName_ar',
            },
            /* {
                Header: '',
                id: 'delete-button',
                Cell: ({ row }) => (<button onClick={
                    () => {
                        console.log(row.original);
                        deleteService(row.original.id);
                    }}>Delete</button>)
            } */
        ],
        []
    );

    const data = useMemo(() => services, [services]);

    const instance = useTable({
        data,
        columns,
    });

    return (
        <>
            {renderTable(instance)}
        </>
    );
}

const renderTable = (instance) => {
    const { getTableProps, headers, rows, prepareRow } = instance;
    return (
        <table id="services-table" {...getTableProps()}>
            <thead>
                <tr className="pls-table-row">{headers.map((header) => <th {...header.getHeaderProps()}>{header.render('Header')}</th>)}</tr>
            </thead>
            <tbody>
                {rows.map(
                    (row, i) =>
                        prepareRow(row) || (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                )}
            </tbody>
        </table>
    );
}

const mapStateToProps = ({ priceListsSection }) => {
    const { services } = priceListsSection;
    return {
        services,
    };
}

export default connect(mapStateToProps, { createService, updateService, deleteService })(ServicesTable);