import React, { Component } from 'react';
import { connect } from 'react-redux';

import { SmallHeader, BlueButton, LabeledTextarea, ValidationMessage } from '../../common';
import { isValidField , FIELD_TYPE_ENUM} from '../../../utils/FieldsValidation';
import Modal from '../Modal';

import './style.css';

class CreateLockerStatusNoteModal extends Component {

    constructor(props) {
        super(props);
        this.INIT_STATE = {
            note:'',
            validField:{
                'note': { type:'note', isRequired:false, isChecked:false, isValid:false, compareMinValue:3, compareMaxValue:500 }
            }
        }
          
        this.state = this.INIT_STATE;
    }

    cleanState(){
        this.setState({
            note:'',
            validField:{
                'note': { type:'note', isRequired:false, isChecked:false, isValid:false, compareMinValue:3, compareMaxValue:500 }
            }
        });
    }

    onEditNote(event){
        this.setState({note: event.target.value});
    }

    onFieldFocus(inputName){
        let { validField } = this.state;
        let field = validField[inputName];
        if(!field) return;
        field.isChecked = false;
        validField[inputName] = field;
        this.setState({
            ...this.state,
            validField:{'note':field}
        });
    }

    onSaveLockerStatusNote(){
        const { isRequiredNote } = this.props;
        let {note, validField} = this.state;

        note = note ? note.trim() : '';
        
        let field = validField.note;
        field.isRequired=isRequiredNote;
        field.isChecked = true;
        field.isValid = isValidField( note, field.type,  field.isRequired , field.compareMinValue, field.compareMaxValue);
        this.setState({validField:{'note':field}});

        
        if(!field.isValid){
            return;
        }

        this.props.updateLockerStatusNote(note);
        this.props.onClose();
        this.cleanState();
    }

    onClose() { 
        this.props.onClose(); 
        this.cleanState();
    } 

    render() {
        const { show, isRequiredNote } = this.props;
        
        if (!show) {
            return (
                <noscript />
            );
        }
        const {note, validField} = this.state;
        const fieldTypeList = FIELD_TYPE_ENUM;
       const errorMessage = isRequiredNote  
            ?fieldTypeList[validField['note'].type].errMessage + " " + fieldTypeList['required'].errMessage
            :fieldTypeList[validField['note'].type].errMessage;
        return (
            <Modal extraClass='cror-modal-wrap'>
                <div className='cror-modal-container'>
                    <SmallHeader title={'הוספת הערה'} />
                    <div className='cror-modal-inputs-wrapper'>
                        <div className='cror-modal-title'>
                          <div className='title'> נא להוסיף הערה עבור שינוי סטטוס תא </div>
                        </div>

                        <LabeledTextarea
                            value={note}
                            onChange={(e) => this.onEditNote(e)}
                            extraClass='locker-status-note-val'
                            onFocus={() => this.onFieldFocus('note')}
                        />
                        <ValidationMessage 
                            isShow={validField['note'].isChecked} 
                            isShowOk={false} 
                            isShowError={!validField['note'].isValid} 
                            className='field-valid' 
                            errorMessage={errorMessage}/>
                    
                       <div className='cror-modal-status'>
                            <BlueButton
                                extraClass='cror-modal-button'
                                onClick={e => this.onSaveLockerStatusNote()}
                            >
                                עדכן
                            </BlueButton>
                            
                            <BlueButton
                                show={true}
                                extraClass='cror-modal-button'
                                onClick={e => this.onClose()}
                            >
                                בטל פעולה
                            </BlueButton>
                        </div>
                       
                    </div>
                </div>
            </Modal>
        );

    }

}


const mapStateToProp = ({ coupons }) => {
    const { couponsActiveList, isLoading } = coupons;
    return {
        couponsActiveList,
        isLoading
    };
};

export default connect(mapStateToProp, {
    
})(CreateLockerStatusNoteModal);