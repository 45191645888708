import React from 'react';

import './style.css';

const Spinner = ({ zoom }) => {
    const width = 150, height = 150;
    const radius = 70;

    return (
        <svg className='spinner-wrapper' style={{ width, height, zoom }}>
            <circle className='spinner-circle' cx={radius} cy={radius} r={radius} />
        </svg>
    );
}

export { Spinner };
