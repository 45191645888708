import axios from 'axios'
import api from '../api';
import errorManager from '../utils/ErrorManager';


import {
    
    FETCH_STATION_NOTIFICATIONS,
} from './types'



export const fetchStationNotifications = (stationId) => {
    return dispatch => {
        axios.get(api.getStationNotifications(stationId))
            .then(res => {
                dispatch({
                    type: FETCH_STATION_NOTIFICATIONS,
                    payload: res.data
                });
                errorManager.throwDispatchErrorReset(dispatch,res);
            })
            .catch(err => {
                console.log(err);
                errorManager.throwDispatch(dispatch, err);
            });
    };
}