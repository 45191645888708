import React from "react"

import Drawer from "./Drawer"
import AppBar from "./AppBar"

import "./style.css"

const AppDecorator = ({ children }) => {
  return (
    <div className="app-wrapper">
      <Drawer />
      <div className="appbar-content-wrapper">
        <AppBar />
        {children}
      </div>
    </div>
  )
}

export default AppDecorator
