import React from 'react';

import './style.css';

const Multiselect = ({ name, labelText, options, allValue, onChange }) => {
    return (
        <div className="select-multiple-group">
            <label className="select-multiple-label">{labelText}</label>
            <select className="select-multiple" name={name} multiple onChange={e => onMultiChange(onChange, e)}>
                {renderAllValue(allValue)}

                {renderOptions(options)}
            </select>
        </div>
    );
}

const renderAllValue = allValue => {
    if (!allValue) {
        return;
    }

    return (
        <option key="all" value="">All</option>
    );
}

const renderOptions = (options) => {
    if (!options) {
        return null;
    }
    return Object.keys(options).map(key =>
        <option key={key} value={key} name={key}>{options[key]}</option>
    );
}

const onMultiChange = (onChange, e) => {
    e.persist();
    const options = e.target.options;
    let selectedOptions = [...options].filter(({ selected }) => selected);
    let stations = selectedOptions.map((option) => option.value);
    onChange(stations);
}

export { Multiselect };
