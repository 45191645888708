import React from 'react';

import './style.css';
import { Title } from '../Title';

const CheckboxAndLabel = ({ children, ...props }) => {
    return (
        <div className='chkbx-and-label-wrapper'>
            <input type='checkbox' className='chkbx-and-label-checkbox' {...props} />
            <Title extraClass='chkbx-and-label-title'>
                {children}
            </Title>
        </div>
    );
}

export { CheckboxAndLabel };
