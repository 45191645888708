import axios from "axios"
import api from "../api"
import {
  SET_LOCKERS_HISTORY_STATION,
  ON_LOADING_LOCKERS_HISTORY,
  GET_SEARCHED_LOCKERS_HISTORY_SUCCESS,
  GET_SEARCHED_LOCKERS_HISTORY_FAIL,
  SET_LOCKERS_HISTORY_SEARCH,
  GET_SEARCHED_LOCKERS_BY_STATION_SUCCESS,
  GET_SEARCHED_LOCKERS_BY_STATION_FAIL,
} from "./types"
import errorManager from "../utils/ErrorManager"

export const setLockersHistoryStation = (stationId) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCKERS_HISTORY_STATION,
      payload: stationId,
    })
  }
}

export const onLoadingLockersHistory = () => {
  return (dispatch) => {
    dispatch({
      type: ON_LOADING_LOCKERS_HISTORY,
    })
  }
}

export const setLockersHistorySearch = (search) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOCKERS_HISTORY_SEARCH,
      payload: search,
    })
  }
}

export const fetchSearchedLockersHistory = (searchData) => {
  const options = {
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
    },
  }

  const data = {
    ...searchData,
    fromDate: searchData.fromDate + " 00:00:00",
    toDate: searchData.toDate + " 23:59:59",
  }

  searchData.fromDate = searchData.fromDate
  searchData.toDate = searchData.toDate

  return (dispatch) => {
    axios
      .post(api.searchLockersHistory, data, options)
      .then((res) => {
        dispatch({
          type: GET_SEARCHED_LOCKERS_HISTORY_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: GET_SEARCHED_LOCKERS_HISTORY_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchLockersSelectListByStationId = (stationId) => {
  const options = {
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
    },
  }
  return (dispatch) => {
    axios
      .get(api.getLockersSelectListByStationId(stationId), options)
      .then((res) => {
        dispatch({
          type: GET_SEARCHED_LOCKERS_BY_STATION_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: GET_SEARCHED_LOCKERS_BY_STATION_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}
