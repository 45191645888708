import React from "react"
import { useTable } from "react-table"

export default function Table({
  id,
  columns,
  defaultColumn,
  data,
  updateData,
  initialState,
}) {
  const { getTableProps, headers, rows, headerGroups, prepareRow } = useTable({
    columns,
    defaultColumn,
    data,
    updateData,
    initialState,
  })

  return (
    <>
      <table id={id} {...getTableProps()}>
        <thead>
          <tr>
            {headerGroups[0].headers.map((header) => (
              <th {...header.getHeaderProps()}>{header.render("Header")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(
            (row, i) =>
              prepareRow(row) || (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    )
                  })}
                </tr>
              )
          )}
        </tbody>
      </table>
    </>
  )
}
