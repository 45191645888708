import {
    ON_ORDERS_IMPORT_SUCCESS,
    ON_USERS_IMPORT_SUCCESS,
    ON_PRICE_LIST_IMPORT_SUCCESS,
} from '../actions/types';

const INIT_STATE = {
    importedOrders: [],
    importedUsers: [],
    importedPrices: [],
};

const ImportReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ON_ORDERS_IMPORT_SUCCESS:
            return {
                ...state,
                importedOrders: action.payload,
            };
        case ON_USERS_IMPORT_SUCCESS:
            return {
                ...state,
                importedUsers: action.payload,
            };
        case ON_PRICE_LIST_IMPORT_SUCCESS:
            return {
                ...state,
                importedPrices: ['done'],
            };
        default:
            return state;
    }
}

export default ImportReducer;