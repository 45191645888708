import React from 'react';

import { InputFiled, WhiteFrame } from '../../../common';

import './style.css';

const UserDetails = ({ onMobileChange, mobile, userData }) => {

    const { companyName, email, firstName, lastName, mobilePhone } = userData || {};

    const userName = firstName ? `שם הלקוח - ${firstName} ${lastName}` : '  ';
    const emailTitle = email ? `דוא"ל לקוח - ${email}` : '  ';

    const showUserData = mobile.length !== 0 && !userData;

    return (
        <div className='usr-details-wrapper'>
            פרטי הלקוח
            <InputFiled
                value={mobile}
                extraClass='usr-details-txt usr-details-modal-input'
                placeholder='הזן מספר טלפון'
                onChange={e => onMobileChange(e.target.value)}
            />
            <div className='usr-details-txt'>
                {showUserData ? 'מחפש לקוחות...' : userName}
            </div>
            <div className='usr-details-txt'>
                {showUserData ? '...' : emailTitle}
            </div>
        </div>
    );
}

export default UserDetails;
