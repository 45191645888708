import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sendStationSMSAction, clearStationSms } from "../../../../actions";
import YesNoModal from "../../../Modals/YesNoModal";
import NotificationModal from "../../../Modals/NotificationModal";
import { BlueButton } from "../../../common";
import "./style.scss";

const SendSMS = (props) => {
  const [smsText, setSMSText] = useState("");
  const [isShowValidationError, setIsShowValidationError] = useState(false);
  const [isShowConfirmPopup, setShowConfirmPopup] = useState(false);

  const templateFail = `לקוחות יקרים, עמדתנו ב ${
    props.station.branchDisplayName
      ? props.station.branchDisplayName
      : props.station.branchName
  } תקולה ולא ניתן לאסוף חבילות. נעדכן כשתתוקן בהקדם. מתנצלים על חוסר הנוחות, צוות Done`;
  const templateSuccess = `לקוחות יקרים, עמדתנו ב ${
    props.station.branchDisplayName
      ? props.station.branchDisplayName
      : props.station.branchName
  } חזרה לפעילות ניתן לאסוף חבילות.  צוות Done`;

  const onTextChanged = (val) => {
    setSMSText(val);
  };

  const validateSmsText = () => {
    if (!smsText) {
      setIsShowValidationError(true);
      return;
    }
    setShowConfirmPopup(true);
  };

  const onSendSmsClick = () => {
    const stationId = props.station.stationId;
    props.sendStationSMSAction(stationId, smsText);
    setShowConfirmPopup(false);
  };

  return (
    <div className="station-sms-body">
      <div className="station-sms-container">
        <h1>עמדה: {props.station.branchName}</h1>
        <h1>שלח SMS</h1>
        <textarea
          className="textarea"
          inputSize={300}
          rows="4"
          value={smsText}
          onChange={(e) => onTextChanged(e.target.value)}
        ></textarea>
        <div className="template-container">
          <button
            className="btn-template"
            onClick={() => setSMSText(templateFail)}
          >
            תבנית כשלון
          </button>
          <button
            className="btn-template"
            onClick={() => setSMSText(templateSuccess)}
          >
            תבנית הצלחה
          </button>
        </div>
        <div className="btn-holder">
          <BlueButton
            extraClass="yesno-modal-button"
            onClick={(e) => validateSmsText()}
          >
            שלח SMS
          </BlueButton>
        </div>

        {isShowValidationError ? (
          <p className="error-msg">אנא הזן טקסט הודעה שתשלח ללקוחות</p>
        ) : null}
      </div>
      <YesNoModal
        show={isShowConfirmPopup}
        title={"שליחת הודעה"}
        text={"אנא אשר את שליחת SMS"}
        onYesClick={() => onSendSmsClick()}
        onNoClick={() => setShowConfirmPopup(false)}
      />
      <NotificationModal
        show={props.isStationSmsSuccess}
        title="הצלחה"
        text="הודעה נשלחה בהצלחה."
        onOkClick={() => props.clearStationSms()}
      />
      <NotificationModal
        show={props.isStationSmsFail}
        title="שגיא"
        text="כישלון בשליחת הודעה"
        onOkClick={() => props.clearStationSms()}
      />
    </div>
  );
};

const mapStateToProps = ({ stationsSection }) => {
  const { station, isStationSmsSuccess, isStationSmsFail } = stationsSection;
  return {
    station,
    isStationSmsSuccess,
    isStationSmsFail,
  };
};

export default connect(mapStateToProps, {
  sendStationSMSAction,
  clearStationSms,
})(SendSMS);
