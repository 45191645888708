import axios from "axios"
import api from "../api"
import {
  SET_USER_HISTORY_CURRENT_USER,
  ON_LOADING_USER_HISTORY,
  GET_USER_HISTORY_SUCCESS,
  GET_USER_HISTORY_FAIL,
  CLEAR_USER_HISTORY_LIST,
  SET_USER_HISTORY_SEARCH,
} from "./types"
import errorManager from "../utils/ErrorManager"

export const setUserHistoryUser = (userId) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_HISTORY_CURRENT_USER,
      payload: userId,
    })
  }
}

export const onLoadingUserHistory = () => {
  return (dispatch) => {
    dispatch({
      type: ON_LOADING_USER_HISTORY,
    })
  }
}

export const clearUserHistory = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USER_HISTORY_LIST,
    })
  }
}

export const setUserHistorySearch = (search) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_HISTORY_SEARCH,
      payload: search,
    })
  }
}

export const fetchSearchedUserHistory = (searchData) => {
  const options = {
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
    },
  }
  const fromDate =
    searchData.fromDate && searchData.fromDate.length > 0
      ? searchData.fromDate + " 00:00:00"
      : null
  const toDate =
    searchData.toDate && searchData.toDate.length > 0
      ? searchData.toDate + " 23:59:59"
      : null

  const data = {
    ...searchData,
    fromDate: fromDate,
    toDate: toDate,
  }

  searchData.fromDate = searchData.fromDate
  searchData.toDate = searchData.toDate

  return (dispatch) => {
    axios
      .post(api.searchUserHistory, data, options)
      .then((res) => {
        dispatch({
          type: GET_USER_HISTORY_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: GET_USER_HISTORY_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}
