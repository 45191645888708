import {
  GET_STATION_LOCKERS_SUCCESS,
  GET_STATION_LOCKERS_FAIL,
  ON_START_LOCKERS_LOADING,
  SET_LOCKERS_STATION,
  UPDATE_LOCKER_STATUS_SUCCESS,
  UPDATE_LOCKER_STATUS_FAIL,
  UPDATE_LOCKER_STATUS_LOADING,
  RESET_UPDATE_LOCKER_STATUS_ERROR,
  UPDATE_LOCKER_SIZE_SUCCESS,
  UPDATE_LOCKER_SIZE_FAIL,
} from "./../actions/types"

const INIT_STATE = {
  stationLockers: [],
  isLoading: false,
  isError: false,
  currentLockersStationId: "",
  isLockerStatusUpdated: false,
  isLockerStatusUpdatedLoading: false,
  showErrorUpdateLockerStatus: false,
  isLockerSizeUpdated: false,
}

const LockersListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_START_LOCKERS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isLockerSizeUpdated: false,
      }
    case SET_LOCKERS_STATION:
      return {
        ...state,
        currentLockersStationId: action.payload,
        stationLockers: [],
        isLoading: false,
      }
    case GET_STATION_LOCKERS_SUCCESS:
      return {
        ...state,
        stationLockers: action.payload,
        isLoading: false,
      }
    case GET_STATION_LOCKERS_FAIL:
      return {
        ...state,
        stationLockers: [],
        isLoading: false,
      }
    case UPDATE_LOCKER_STATUS_LOADING:
      return {
        ...state,
        isLockerStatusUpdated: false,
        isLockerStatusUpdatedLoading: true,
        showErrorUpdateLockerStatus: false,
      }

    case UPDATE_LOCKER_STATUS_SUCCESS:
      return {
        ...state,
        isLockerStatusUpdated: true,
        isLockerStatusUpdatedLoading: false,
      }
    case UPDATE_LOCKER_STATUS_FAIL:
      return {
        ...state,
        isLockerStatusUpdated: false,
        isLockerStatusUpdatedLoading: false,
        showErrorUpdateLockerStatus: true,
      }
    case RESET_UPDATE_LOCKER_STATUS_ERROR:
      return {
        ...state,
        showErrorUpdateLockerStatus: false,
      }
    case UPDATE_LOCKER_SIZE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isLockerSizeUpdated: true,
      }
    case UPDATE_LOCKER_SIZE_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isLockerSizeUpdated: true,
      }
    default:
      return state
  }
}

export default LockersListReducer
