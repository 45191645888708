import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { SmallHeader, BlueButton, CheckboxAndLabel } from "../../common"
import { clearDeliveryDetails } from "../../../actions"
import Modal from "../Modal"
import Select from "react-select"
import "./style.scss"

const SelectStationChangeModal = (props) => {
  const [validationMsg, setValidationMsg] = useState("")
  const [targetStationNum, setTargetStationNumber] = useState("")
  const [targetStationId, setTargetStationId] = useState("")
  const [isStationChangeBilling, setIsStationChangeBilling] = useState(
    props.order?.isStationChangeBilling
  )

  const onCancelClick = () => {
    props.onCancelClick()
    clearState()
  }

  const onSubmitClick = () => {
    let isValid = validateInput()
    if (isValid) {
      props.onSubmitClick(
        props.order.id,
        targetStationId,
        isStationChangeBilling
      )
      clearState()
    }
  }

  const clearState = () => {
    setTargetStationNumber("")
    setTargetStationId("")
    setValidationMsg("")

    props.clearDeliveryDetails()
  }

  const validateInput = () => {
    if (!targetStationNum) {
      setValidationMsg("אנא בחר עמדת יעד")
      return false
    }
    setValidationMsg("")
    return true
  }

  if (!props.show) {
    return null
  }

  return (
    <Modal extraClass="cror-modal-wrap">
      <div className="cror-modal-container">
        <div className="locker-to-locker-delivery-modal">
          <SmallHeader title="שינוי עמדת יעד" />
          <div className="modal-body">
            <div className="person-ditails-wrapper">
              {props.order && props.order.stationTarget && (
                <div>
                  <div className="order-data-caption"> עמדת יעד נוכחית:</div>
                  <div
                    className="order-data-value"
                    title={props.order.stationTarget.branchDisplayAddress}
                  >
                    {props.order.stationTarget.stationNumber} -{" "}
                    {props.order.stationTarget.branchDisplayName} <br />
                    <br />
                  </div>
                </div>
              )}
              <div className="input-wrapper">
                <span className="input-title sub-title">עמדת יעד </span>{" "}
                <Select
                  className="station-selector"
                  key={targetStationNum}
                  placeholder="בחר..."
                  defaultValue={props.sortedStationsNames.find(
                    (option) => option.value === targetStationNum
                  )}
                  options={props.sortedStationsNames}
                  onChange={(e) => {
                    setValidationMsg("")
                    setTargetStationNumber(e.value)
                    setTargetStationId(e.stationId)
                  }}
                />
              </div>
              <div className="is-billing">
                <CheckboxAndLabel
                  checked={isStationChangeBilling}
                  onChange={(e) =>
                    setIsStationChangeBilling(!isStationChangeBilling)
                  }
                >
                  לחייב עבור החלפת עמדה
                </CheckboxAndLabel>
              </div>
            </div>
            <div className="validation-msg">
              {validationMsg ? validationMsg : null}
            </div>
            <div className="cror-modal-status">
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => onSubmitClick()}
              >
                אישור
              </BlueButton>
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => onCancelClick()}
              >
                ביטול
              </BlueButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProp = ({
  lockerToLocker,
  stationsList,
  ordersList,
  usersList,
}) => {
  const { lockerToLockerDelivery } = lockerToLocker
  const { sortedStationsNames } = stationsList
  const { isMailOrderCreated, isCleared } = ordersList
  const { usersListArr } = usersList

  return {
    lockerToLockerDelivery,
    sortedStationsNames,
    isMailOrderCreated,
    isCleared,
    usersListArr,
  }
}

export default connect(mapStateToProp, {
  clearDeliveryDetails,
})(SelectStationChangeModal)
