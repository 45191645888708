import React from "react"
import { BlueButton } from "../../../common/BlueButton"
import "./ShippingCertificate.scss"

const ShippingCertificate = (props) => {
  return (
    <section className="shippingCertificate">
      <div className="title">תעודת משלוח</div>
      <div>
        <BlueButton
          extraClass="btn-shippingCertificate"
          onClick={() => props.history.push("/ShippingCertificatePage")}
        >
          תעודת משלוח
        </BlueButton>
      </div>
    </section>
  )
}

export default ShippingCertificate
