import React from "react"
import { useState, useEffect } from "react"
import { connect } from "react-redux"
import { updateRouteAreaCode, clearRouteAreaCode } from "../../../../../actions"
import { BlueButton } from "../../../../common"
import Select from "react-select"
import NotificationModal from "../../../../Modals/NotificationModal"

const EditRouteAreaCode = (props) => {
  const [selectedRoute, setSelectedRoute] = useState(null)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const [showFailPopup, setShowFailPopup] = useState(false)
  const [formData, setFormData] = useState({
    id: "",
    routeId: "",
    routeAreaCodeName: "",
    routeAreaCodeDesc: "",
  })

  useEffect(() => {
    if (props.editAreaCodeSuccess) {
      setShowSuccessPopup(true)
      setShowFailPopup(false)
      props.clearRouteAreaCode()
    }
    if (props.editAreaCodeFail) {
      setShowFailPopup(true)
      setShowSuccessPopup(false)
      props.clearRouteAreaCode()
    }
  }, [props.editAreaCodeSuccess, props.editAreaCodeFail])

  useEffect(() => {
    if (props.selectedRouteAreaCode) {
      setFormData(props.selectedRouteAreaCode)
      const deffaultRoute = props.routesList.find(
        (r) => r.value === props.selectedRouteAreaCode.routeId
      )
      setSelectedRoute(deffaultRoute)
    }
  }, [props.selectedRouteAreaCode])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const areaCodeData = {
      id: formData.id,
      routeId: formData.routeId,
      routeAreaCodeName: formData.routeAreaCodeName,
      routeAreaCodeDesc: formData.routeAreaCodeDesc,
    }
    props.updateRouteAreaCode(areaCodeData)
  }

  const handleSelectChange = (selectedOption) => {
    setSelectedRoute(selectedOption)
    setFormData({
      ...formData,
      routeId: selectedOption.value,
    })
  }

  return (
    <section className="editRouteAreaCode">
      <h2>ערוך קידומת אזור</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="input-holder">
            <label>מסלול:</label>
            <Select
              className="select-route"
              name="routeId"
              key={props.routesList?.length}
              placeholder={"בחר מסלול"}
              options={props.routesList}
              value={selectedRoute}
              onChange={handleSelectChange}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="input-holder">
            <label>שם קידומת אזור:</label>
            <input
              type="text"
              name="routeAreaCodeName"
              value={formData.routeAreaCodeName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="input-holder">
            <label>תיאור קידומת אזור:</label>
            <textarea
              name="routeAreaCodeDesc"
              value={formData.routeAreaCodeDesc}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="btn-holder">
          <BlueButton type="submit">עדכן</BlueButton>
        </div>
      </form>
      <NotificationModal
        show={showSuccessPopup}
        type="success"
        title="הצלחה"
        text="קידומת אזור עודכנה בהצלחה"
        onOkClick={() => setShowSuccessPopup(false)}
      />
      <NotificationModal
        show={showFailPopup}
        type="error"
        title="כישלון"
        text="יצירת קידומת אזור נכשלה"
        onOkClick={() => setShowFailPopup(false)}
      />
    </section>
  )
}

const mapStateToProps = ({ routeAreaCode, routes }) => {
  const { selectedRouteAreaCode, editAreaCodeSuccess, editAreaCodeFail } =
    routeAreaCode
  const routesList = routes.routes

  return {
    selectedRouteAreaCode,
    routesList,
    editAreaCodeSuccess,
    editAreaCodeFail,
  }
}
export default connect(mapStateToProps, {
  updateRouteAreaCode,
  clearRouteAreaCode,
})(EditRouteAreaCode)
