import React, { Component } from "react"
import { connect } from "react-redux"

import { loginUser, resetLoginAttempt } from "../../../actions"
import LoginModal from "../../Modals/LoginModal"

import "./style.css"

class LoginScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: true,
      errorMsg: "",
    }
  }
  componentWillMount() {
    this.props.resetLoginAttempt()
  }
  // componentWillReceiveProps(newProps) {
  //   const { logedUserData } = newProps
  //   if (logedUserData) {
  //     if (
  //       logedUserData.role == "Manager" ||
  //       logedUserData.role == "WHOperator"
  //     ) {
  //       this.setState({ showModal: false })
  //       if (logedUserData.isPasswordTemporary) {
  //         this.props.history.push("UpdatePasswordScreen")
  //       } else {
  //         this.props.history.push("HomeScreen")
  //       }
  //     } else {
  //       this.setState({ errorMsg: "אינך מורשה להיכנס למערכת" })
  //     }
  //   }
  // }

  componentDidUpdate(prevProps) {
    const { logedUserData } = this.props
    if (logedUserData && logedUserData !== prevProps.logedUserData) {
      if (
        logedUserData.role === "Manager" ||
        logedUserData.role === "WHOperator"
      ) {
        this.setState({ showModal: false })
        if (logedUserData.isPasswordTemporary) {
          this.props.history.push("UpdatePasswordScreen")
        } else if (logedUserData.role === "Manager") {
          this.props.history.push("HomeScreen")
        } else if (logedUserData.role === "WHOperator") {
          this.props.history.push("GeneralReceptionPage")
        }
      } else {
        this.setState({ errorMsg: "אינך מורשה להיכנס למערכת" })
      }
    }
  }

  onUserLoginAttempt(mobile, pwd) {
    this.props.loginUser(mobile, pwd)
  }

  onSuccessFailClick(success) {
    this.props.resetLoginAttempt()
  }

  render() {
    const { showModal, errorMsg } = this.state
    const { loadingReq, isLoginFailed } = this.props

    return (
      <div>
        Login SCREEN
        <LoginModal
          show={showModal}
          loading={loadingReq}
          isLoginFailed={isLoginFailed}
          errorMsg={errorMsg}
          onSubmitClick={(mobile, pwd) => this.onUserLoginAttempt(mobile, pwd)}
          onSuccessFailClick={(success) => this.onSuccessFailClick(success)}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ login }) => {
  const { logedUserData, loadingReq, isLoginFailed } = login

  return {
    logedUserData,
    loadingReq,
    isLoginFailed,
  }
}

export default connect(mapStateToProps, { loginUser, resetLoginAttempt })(
  LoginScreen
)
