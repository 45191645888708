import React, { Component } from "react"
import { connect } from "react-redux"

import { SmallHeader, BlueButton, Spinner } from "../../common"
import OrderList from "./OrderList"
import Modal from "../Modal"
import {
  confirmOrderToLocker,
  onLoadingWaitingForPickupOrders,
} from "../../../actions"

import { getFormatedDate, getValEnumFromIndex } from "../../../utils"

import "./style.css"

const orderListHeaders = [
  "מזהה",
  "מס חבילה",
  "תאריך",
  "סוג",
  "סוג מנוי",
  "שם לקוח",
  "נייד",
  "סכום (₪)",
  "",
]
const orderListItemMap = [
  "orderNumber",
  "packageNumber",
  "orderFormatDate",
  "orderFromatType",
  "accountType",
  "customerName",
  "customerMobilePhone",
  "orderTotalPrice",
]
const orderListGravity = { 2: 2, 5: 2, 6: 2 }

class LoadOrderWaitingForPickupToLockerModal extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      showConfirmPutToLocker: false,
      order: null,
      showResultMessage: false,
    }
    this.state = this.INIT_STATE
  }

  componentWillUnmount() {
    this.cleanState()
  }

  cleanState() {
    this.setState({
      showConfirmPutToLocker: false,
      order: null,
      showResultMessage: false,
    })
  }

  onPutToLockerBtnClick(order) {
    this.setState({
      showConfirmPutToLocker: true,
      order: order,
      showResultMessage: false,
    })
  }

  onPutToLockerConfirmClick() {
    const { order } = this.state
    const { locker } = this.props
    this.props.onLoadingWaitingForPickupOrders()
    this.props.confirmOrderToLocker(order.orderId, locker.lockerId)

    this.setState({
      showConfirmPutToLocker: false,
      showResultMessage: true,
    })
  }

  onClose() {
    this.cleanState()
    this.props.onClose()
  }

  render() {
    const {
      show,
      locker,
      waitingForPickupOrders,
      isLoading,
      isError,
      currentStationItem,
    } = this.props
    console.log(currentStationItem)
    if (!show) {
      return <noscript />
    }
    const { order, showConfirmPutToLocker, showResultMessage } = this.state

    if (showResultMessage) {
      return (
        <Modal extraClass="cror-modal-wrap  put-order-to-locker">
          <div className="cror-modal-container">
            <SmallHeader
              title={
                " טעינת הזמנה " +
                order.orderNumber +
                " לתא " +
                locker.lockerName
              }
            />

            {!isLoading && !isError && (
              <div className="cror-modal-inputs-wrapper">
                <div className="cror-modal-title">
                  <div className="title success">הזמנה הוטענה בהצלחה</div>
                </div>
                <div className="cror-modal-status">
                  <BlueButton
                    show={true}
                    extraClass="cror-modal-button"
                    onClick={(e) => this.onClose()}
                  >
                    סגור
                  </BlueButton>
                </div>
              </div>
            )}
            {!isLoading && isError && (
              <div className="cror-modal-inputs-wrapper">
                <div className="cror-modal-title">
                  <div className="title error error-text">הפעולה נכשלה</div>
                </div>
                <div className="cror-modal-status">
                  <BlueButton
                    show={true}
                    extraClass="cror-modal-button"
                    onClick={(e) => this.onClose()}
                  >
                    סגור
                  </BlueButton>
                </div>
              </div>
            )}
            {isLoading && (
              <div className="cror-modal-inputs-wrapper">
                <div className="cror-modal-title">
                  <div className="users-list">
                    <Spinner zoom={1} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )
    }
    if (showConfirmPutToLocker && order && locker) {
      return (
        <Modal extraClass="cror-modal-wrap  put-order-to-locker">
          <div className="cror-modal-container">
            <SmallHeader
              title={
                " טעינת הזמנה " +
                order.orderNumber +
                " לתא " +
                locker.lockerName
              }
            />
            <div className="cror-modal-inputs-wrapper">
              <div className="cror-modal-title">
                <div className="title">
                  <div>
                    האם ברצונך להטעין הזמנת {order.orderFromatType} מספר
                    {" " + order.orderNumber + " "}
                  </div>
                  <div>של לקוח {order.customerName}</div>
                  <div>
                    {" "}
                    לעמדה {currentStationItem.label} לתא {locker.lockerName} ?
                  </div>
                </div>
                <div className="line-wrapper">
                  <div className="line">
                    <div className="label">מספר הזמנה</div>
                    <div className="value">{order.orderNumber}</div>
                  </div>
                  <div className="line">
                    <div className="label">סוג הזמנה</div>
                    <div className="value">{order.orderFromatType}</div>
                  </div>
                  <div className="line">
                    <div className="label">לקוח</div>
                    <div className="value">{order.customerName}</div>
                  </div>
                  <div className="line">
                    <div className="label">עמדה</div>
                    <div className="value">{currentStationItem.label}</div>
                  </div>
                  <div className="line">
                    <div className="label">תא</div>
                    <div className="value"> {locker.lockerName}</div>
                  </div>
                </div>
              </div>

              <div className="cror-modal-status">
                <BlueButton
                  show={true}
                  extraClass="cror-modal-button"
                  onClick={(e) => this.onPutToLockerConfirmClick()}
                >
                  אישור
                </BlueButton>
                <BlueButton
                  show={true}
                  extraClass="cror-modal-button"
                  onClick={(e) => this.onClose()}
                >
                  ביטול
                </BlueButton>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    return (
      <Modal extraClass="cror-modal-wrap put-order-to-locker">
        <div className="cror-modal-container">
          <SmallHeader
            title={"טעינת הזמנה לתא " + " " + locker.lockerName + " "}
          />
          <div className="cror-modal-inputs-wrapper">
            <div className="cror-modal-title">
              <div className="title">
                רשימת הזמנות בסטטוס מחכה לאיסוף עבור עמדה
                {" " + currentStationItem.label + " "}
              </div>
            </div>
            <div className="order-list-counter">
              נמצאו {waitingForPickupOrders.length} רשומות
            </div>

            <OrderList
              items={waitingForPickupOrders}
              extraClass="ols-wrapper-list select-order"
              headers={orderListHeaders}
              itemMap={orderListItemMap}
              gravity={orderListGravity}
              onSelectBtnClick={(itm) => this.onPutToLockerBtnClick(itm)}
              isError={isError}
              isLoading={isLoading}
            />
            <div className="cror-modal-status">
              <BlueButton
                show={true}
                extraClass="cror-modal-button"
                onClick={(e) => this.onClose()}
              >
                ביטול
              </BlueButton>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProp = ({ orderToLocker, selectOptions }) => {
  const { waitingForPickupOrders, isLoading, isError } = orderToLocker
  const { orderTypes } = selectOptions
  const isExistOrdersTypes = orderTypes.length > 0

  waitingForPickupOrders.forEach((element) => {
    element.orderFormatDate = getFormatedDate(element.orderConfirmDate)

    element.orderFromatType = isExistOrdersTypes
      ? getValEnumFromIndex(orderTypes, element.orderType)
      : element.orderFromatType
  })

  return {
    waitingForPickupOrders,
    isLoading,
    isError,
  }
}

export default connect(mapStateToProp, {
  confirmOrderToLocker,
  onLoadingWaitingForPickupOrders,
})(LoadOrderWaitingForPickupToLockerModal)
