import React from 'react';
import './style.css';



const ErrorMessageSmall = (props) => {
    
    const handleClose = () => {
        
        props.hideError();
    }

    if(props.isShow){
       
         return (
            <div className={props.className}>
                <div className='error-wrapper'>
                    <div className='error-img' onClick={handleClose}><div>+</div></div>
                    <div className='error-text'>{props.errorMessage}</div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={props.className}></div>
        );
    }
   
}

export { ErrorMessageSmall };