import React, { useState } from "react"
import SearchUserModal from "../../../../Modals/SearchUserModal/searchUserModal.js"
import { WhiteFrame, BlueButton } from "../../../../common"

const OrderDetails = ({ order, orderTypes, accountTypes }) => {
  const [isShowSerachUser, setShowSearchUser] = useState(false)
  if (!order) {
    return <div>LOADING..</div>
  }

  let {
    senderFullName,
    senderMobilePhone,
    stationSource,
    customerFullName,
    mobilePhone,
    stationTarget,
    orderType,
    accountType,
    orderStatus,
    isMonthlyPayment,
    externalOrderNumber2,
    routeName,
    isReturnToSource,
    isReturnToWarehouse,
    targetMobilePhone,
    currentLocation,
  } = order

  if (!stationSource) {
    stationSource = {}
  }
  if (!senderFullName) {
    senderFullName = ""
  }
  if (!senderMobilePhone) {
    senderMobilePhone = ""
  }
  if (!stationTarget) {
    stationTarget = {}
  }
  const orderTypeText = orderTypes[orderType]

  const defaultView = () => {
    return (
      <WhiteFrame extraClass="order-editor-frame-wrapper">
        <div className="white-frame-child-wrapper">
          <div className="order-data-wrapper">
            <div className="order-data-caption">סוג הזמנה:</div>
            <div className="order-data-value order-type"> {orderTypeText}</div>
            {isMonthlyPayment && (
              <div className="order-data-value is-monthly-payment">
                תשלום
                <br />
                חודשי
              </div>
            )}
          </div>
        </div>
        <div className="white-frame-child-wrapper">
          <div className="order-data-wrapper">
            <div className="order-data-caption">מסלול נוכחי:</div>
            <div className="order-data-value route-name"> {routeName}</div>
          </div>
        </div>
        <div className="white-frame-child-wrapper">
          <div className="order-data-wrapper">
            <div>
              <div className="order-data-caption"> שם לקוח בהזמנה:</div>
              <div className="order-data-value"> {customerFullName} </div>
              {orderStatus != 5 ? (
                <BlueButton
                  extraClass="change-user-btn"
                  onClick={(e) => setShowSearchUser(true)}
                >
                  החלף
                </BlueButton>
              ) : null}
            </div>
          </div>
        </div>
      </WhiteFrame>
    )
  }

  const laundryView = () => {
    return (
      <WhiteFrame extraClass="order-editor-frame-wrapper">
        <div className="white-frame-child-wrapper">
          <div className="order-data-wrapper">
            <div className="order-data-caption">סוג הזמנה:</div>
            <div className="order-data-value order-type"> {orderTypeText}</div>
            {isMonthlyPayment && (
              <div className="order-data-value is-monthly-payment">
                תשלום
                <br />
                חודשי
              </div>
            )}
          </div>
          {orderStatus != 5 ? (
            <div>
              <div className="order-data-caption">החלף משתמש :</div>
              <div className="btn-change-customer-holder">
                <BlueButton
                  extraClass="change-user-btn"
                  onClick={(e) => setShowSearchUser(true)}
                >
                  החלף
                </BlueButton>
              </div>
            </div>
          ) : null}
          <div className="order-data-wrapper">
            <div className="order-data-caption">מיקום :</div>
            <div className="order-data-value current-location">
              {currentLocation}
            </div>
          </div>
        </div>
        <div className="white-frame-child-wrapper">
          <div className="order-data-wrapper">
            <div className="order-data-caption">מסלול נוכחי:</div>
            <div className="order-data-value route-name"> {routeName}</div>
            {isReturnToWarehouse && (
              <div className="order-data-value is-return-to-warehouse">
                חוזר
                <br />
                למחסן
              </div>
            )}
          </div>
        </div>
      </WhiteFrame>
    )
  }

  const externalReturnsView = () => {
    return (
      <WhiteFrame extraClass="order-editor-frame-wrapper">
        <div className="white-frame-child-wrapper">
          <div className="order-data-wrapper">
            <div className="order-data-caption">סוג הזמנה:</div>
            <div className="order-data-value order-type"> {orderTypeText}</div>
          </div>
          <div className="order-data-wrapper">
            <div className="order-data-caption">סוג החשבון:</div>
            <div className="order-data-value order-type">
              {accountTypes ? accountTypes[accountType] : ""}
              {accountType === 29 && externalOrderNumber2 ? (
                <div className="azrieli-business-title">
                  - {externalOrderNumber2}
                </div>
              ) : null}
            </div>
            {isMonthlyPayment && (
              <div className="order-data-value is-monthly-payment">
                תשלום
                <br />
                חודשי
              </div>
            )}
          </div>
          {orderStatus != 5 ? (
            <div>
              <div className="order-data-caption">החלף משתמש :</div>
              <div className="btn-change-customer-holder">
                <BlueButton
                  extraClass="change-user-btn"
                  onClick={(e) => setShowSearchUser(true)}
                >
                  החלף
                </BlueButton>
              </div>
            </div>
          ) : null}
        </div>
        <div className="white-frame-child-wrapper">
          <div className="order-data-wrapper">
            <div className="order-data-caption">מסלול נוכחי:</div>
            <div className="order-data-value order-type"> {routeName}</div>
          </div>
        </div>
      </WhiteFrame>
    )
  }

  const externalAndShipmentView = () => {
    return (
      <WhiteFrame extraClass="order-editor-frame-wrapper">
        <div className="white-frame-child-wrapper">
          <div className="order-data-wrapper">
            <div className="order-data-caption">סוג הזמנה:</div>
            <div className="order-data-value order-type"> {orderTypeText}</div>
            {isMonthlyPayment && (
              <div className="order-data-value is-monthly-payment">
                תשלום
                <br />
                חודשי
              </div>
            )}
          </div>

          <div className="order-data-wrapper">
            <div className="order-data-caption">סוג החשבון:</div>
            <div className="order-data-value order-type">
              {accountTypes ? accountTypes[accountType] : ""}
              {accountType === 29 && externalOrderNumber2 ? (
                <div className="azrieli-business-title">
                  - {externalOrderNumber2}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="white-frame-child-wrapper">
          <div className="order-data-wrapper">
            <div className="order-data-caption">מסלול נוכחי:</div>
            <div className="order-data-value order-type"> {routeName}</div>
          </div>
        </div>
        <div className="white-frame-child-wrapper">
          {isReturnToSource && (
            <div className="order-data-value is-return-to-source">
              חוזר
              <br />
              לשולח
            </div>
          )}

          {orderType === 5 ? (
            <div className="order-data-wrapper">
              <div>
                <div className="order-data-caption"> שולח:</div>
                <div className="order-data-value"> {senderFullName}</div>
              </div>
              <div>
                <div className="order-data-caption"> טלפון שולח:</div>
                <div className="order-data-value"> {senderMobilePhone}</div>
              </div>
              <div>
                <div className="order-data-caption"> עמדת מקור:</div>
                <div
                  className="order-data-value"
                  title={stationSource.branchDisplayAddress}
                >
                  {" "}
                  {stationSource.stationNumber} -{" "}
                  {stationSource.branchDisplayName}{" "}
                </div>
              </div>
            </div>
          ) : null}

          <div className="order-data-wrapper">
            <div>
              <div className="order-data-caption"> נמען:</div>
              <div className="order-data-value"> {customerFullName} </div>
              {(orderType === 5 || orderType === 8) &&
              orderStatus !== 5 &&
              isReturnToSource === false ? (
                <BlueButton
                  extraClass="change-user-btn"
                  onClick={(e) => setShowSearchUser(true)}
                >
                  החלף
                </BlueButton>
              ) : null}
            </div>
            <div>
              <div className="order-data-caption"> טלפון נמען:</div>
              <div className="order-data-value"> {targetMobilePhone} </div>
            </div>
            <div>
              <div className="order-data-caption"> עמדת יעד:</div>
              <div
                className="order-data-value"
                title={stationTarget.branchDisplayAddress}
              >
                {stationTarget.stationNumber} -{" "}
                {stationTarget.branchDisplayName}{" "}
              </div>
            </div>
          </div>
        </div>
      </WhiteFrame>
    )
  }

  const viewByOrderType = (orderType) => {
    switch (orderType) {
      case 1:
        return laundryView()
      case 31:
        return externalReturnsView()
      case 5:
      case 8:
        return externalAndShipmentView()
      default:
        return defaultView()
    }
  }

  return (
    <div>
      {viewByOrderType(order.orderType)}
      <SearchUserModal
        isOpen={isShowSerachUser}
        close={() => setShowSearchUser(false)}
        customerFullName={customerFullName}
        orderId={order.id}
      />
    </div>
  )
}

export default OrderDetails
