import React from "react"
import exelotLogo from "../assets/images/exelot_logo.png"

let exelot = {}

exelot.orderType = 7
exelot.accountType = 9
exelot.serviceName = "exelot"
exelot.newBagTemp = () => {
  return { orderType: 7, orderStatus: 5 }
}

exelot.renderLogo = () => {
  return (
    <img
      className="logo-service-icon exelot"
      src={exelotLogo}
      alt={"logo exelot"}
    />
  )
}

export const exelotProvider = exelot
