import React from "react"

import { BlueButton } from "../../../../../common"
import "./style.css"

const StationItem = ({
  indexNo,
  item,
  itemMap,
  gravity,
  isLast,
  onEditBtnClick,
  onSendSmsBtnClick,
  onCellsBtnClick,
  onLockersHistoryBtnClick,
  onLockersSensorInfraBtnClick,
  onLockersStatusBtnClick,
}) => {
  const itemClassName = `item-wrapper ${isLast ? "item-last" : ""}`

  return (
    <div className={itemClassName} key={"station-item-" + indexNo}>
      {
        /*renderItemContent(item, itemMap, gravity, onSendSmsBtnClick, onEditBtnClick)*/

        itemMap.map((itmMap, index) => {
          const flex = gravity[`${index}`] || 1

          if (itmMap === "isDecathlon") {
            const itemClassName = !item[itmMap] ? "fa fa-check" : ""
            return (
              <div
                className={"clickable " + itemClassName}
                aria-hidden="true"
                style={{ flex }}
                key={index}
              ></div>
            )
          }

          if (itmMap === "cells") {
            return (
              <div style={{ flex }} key={index}>
                <BlueButton
                  extraClass="lockers-btn"
                  onClick={(e) => onCellsBtnClick(item)}
                >
                  <div className="fa fas fa-th"></div>
                </BlueButton>
              </div>
            )
          }
          if (itmMap === "cellsLog") {
            return (
              <div style={{ flex }} key={index}>
                <BlueButton
                  extraClass="lockers-btn"
                  onClick={(e) => onLockersHistoryBtnClick(item)}
                >
                  <div className="fas fa-history"></div>
                </BlueButton>
              </div>
            )
          }
          if (itmMap === "cellSensorInfra") {
            return (
              <div>
                <BlueButton
                  extraClass="lockers-btn"
                  onClick={(e) => onLockersSensorInfraBtnClick(item)}
                >
                  <div
                    className="fa fa-check-circle-o"
                    aria-hidden="true"
                  ></div>
                </BlueButton>
              </div>
            )
          }
          if (itmMap === "lockersStatus") {
            return (
              <div style={{ flex }} key={index}>
                <BlueButton
                  extraClass="lockers-btn"
                  onClick={(e) => onLockersStatusBtnClick(item)}
                >
                  <div className="fa fas fa-eye"></div>
                </BlueButton>
              </div>
            )
          }
          if (itmMap === "sendSms") {
            return (
              <div style={{ flex }} key={index}>
                <BlueButton
                  extraClass="send-sms-btn"
                  onClick={(e) => onSendSmsBtnClick(item)}
                >
                  שלח SMS
                </BlueButton>
              </div>
            )
          }
          if (itmMap === "edit") {
            return (
              <div style={{ flex }} key={index}>
                <BlueButton
                  extraClass="edit-btn"
                  onClick={(e) => onEditBtnClick(item)}
                >
                  עריכה
                </BlueButton>
              </div>
            )
          }

          let itemContent = item[itmMap]
          if (itmMap === "stationPaymentVersion") {
            return (
              <div style={{ flex }} key={index}>
                <div className={"circle version-" + itemContent}>
                  {itemContent}
                </div>
              </div>
            )
          }
          if (itmMap === "brokenLockersNum") {
            if (!itemContent || itemContent == 0) {
              return <div style={{ flex }} key={index}></div>
            }
            return (
              <div style={{ flex }} key={index}>
                <div className={"circle broken"}>{itemContent}</div>
              </div>
            )
          }
          if (itmMap === "emptyLockersNum") {
            if (!itemContent || itemContent == 0) {
              return <div style={{ flex }} key={index}></div>
            }
            return (
              <div style={{ flex }} key={index}>
                <div className={"circle empty"}>{itemContent}</div>
              </div>
            )
          }
          if (itmMap === "lockersNumber") {
            return (
              <div style={{ flex }} key={index}>
                <div className={"circle lockers"}>{itemContent}</div>
              </div>
            )
          }

          if (itmMap === "isAlive") {
            if (itemContent) {
              return (
                <div style={{ flex }} key={index}>
                  <div
                    className="fa fa-check-circle station-alive"
                    aria-hidden="true"
                  ></div>
                </div>
              )
            }
            return (
              <div style={{ flex }} key={index}>
                <div
                  className="fa fa-times-circle station-down"
                  aria-hidden="true"
                ></div>
              </div>
            )
          }

          return (
            <div style={{ flex }} key={index}>
              {itemContent}
            </div>
          )
        })
      }
    </div>
  )
}

export default StationItem
