import React from "react"

import Header from "../../../../common/List/Header"
import DriverItem from "./DriverItem"
import { H1, Spinner } from "../../../../common"

import "./style.css"

const headers = ["מס' ", "שם נהג", "נייד", "תפקיד", "מסלולים", "הסר מהמסלול"]
const itemMap = [
  "number",
  "driverFullName",
  "mobilePhone",
  "role",
  "routes",
  "removeDriverFromRoute",
]

const itemMapNoAction = [
  "number",
  "driverFullName",
  "mobilePhone",
  "role",
  "routes",
  "empty",
]

const gravity = { 0: 0.5, 1: 2, 2: 1, 5: 2 }

const DriversList = ({
  extraClass,
  items,
  isLoading,
  history,
  onRemoveDriverFromRouteClick,
  hideAction,
}) => {
  const currentItemMap = hideAction ? itemMapNoAction : itemMap
  return (
    <div className={`stations-list list-wrapper ${extraClass}`}>
      <Header titles={headers} gravity={gravity} />
      <div className="list-items-wrapper">
        {renderItems(
          items,
          gravity,
          currentItemMap,
          isLoading,
          history,
          onRemoveDriverFromRouteClick,
          hideAction
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  gravity,
  itemMap,
  isLoading,
  history,
  onRemoveDriverFromRouteClick,
  hideAction
) => {
  if (isLoading) {
    return (
      <div className="station-editor-spinner-wrapper">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (!items || items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }

  return items.map((itm, index) => {
    return (
      <DriverItem
        key={index}
        counter={index + 1}
        item={itm}
        itemMap={itemMap}
        gravity={gravity}
        isLast={index === items.length - 1}
        history={history}
        onRemoveDriverFromRouteClick={onRemoveDriverFromRouteClick}
        hideAction={hideAction}
      />
    )
  })
}

export default DriversList
