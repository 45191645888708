import {
  ON_GET_ORDERS_STATUSES_SUCSESS,
  CLEAR_ORDER_STATUSES,
  //ON_GET_ORDERS_STATUSES_FAIL
} from "../actions/types"

import {
  //setOrderStatusesEnumByLang,
  //setOpenOrderStatusesEnumByLang,
  setOrderStatusesObj,
  setOrderStatusesArr,
  setOrderStatusesArrSorted,
} from "../utils/EnumsAndConsts"

const INIT_STATE = {
  ordersStatusesArr: [],
  openOrdersStatusesArr: [],
}

const OrdersStatusesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_ORDERS_STATUSES_SUCSESS:
      return {
        ...state,
        orderStatusesObj: setOrderStatusesObj(action.payload, "he"),
        ordersStatusesArr: setOrderStatusesArr(action.payload, "he", false),
        openOrdersStatusesArr: setOrderStatusesArr(action.payload, "he", true),
        ordersStatusesArrSorted: setOrderStatusesArrSorted(
          action.payload,
          "he",
          false
        ),
      }
    case CLEAR_ORDER_STATUSES:
      return {
        ...state,
        ordersStatusesArr: [],
      }
    // case ON_GET_ORDERS_STATUSES_FAIL:
    //     return {
    //         ...state
    //     }
    default:
      return state
  }
}

export default OrdersStatusesReducer
