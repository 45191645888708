import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import {
  LabeledTextInput,
  LabeledSelect,
  Spinner,
  ValidationMessage,
} from "../../../common"
import {
  isValidField,
  FIELD_TYPE_ENUM,
} from "../../../../utils/FieldsValidation"
import NotificationModal from "../../../Modals/NotificationModal"
import { SupplierTypes_ENUM } from "../../../../utils/EnumsAndConsts"
import {
  cleanSupplierForm,
  editSupplierForm,
  createSupplier,
  showLoading,
} from "../../../../actions"
import { suppliersColors } from "../../../../utils/ColorLists.ts"
import { colourStyles } from "../../../../utils/colorStyles.tsx"
import "./style.scss"

const NewSupplier_TitleText = "יצירת ספק"
const NewSupplier_Name_InputLabelText = "שם ספק"
const NewSupplier_Type_InputLabelText = "סוג ספק"
const NewSupplier_Phone_InputLabelText = "טלפון"
const NewSupplier_Email_InputLabelText = "אימייל"
const NewSupplier_City_InputLabelText = "עיר"
const NewSupplier_ContactName_InputLabelText = "שם איש קשר"

const NewSupplier_SaveButtonText = "צור ספק"

const NewSupplier_CreateSuccessed_Text = "ספק יוצר בהצלחה"

class NewSupplier extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isCreateSupplierButtonDisabled: false,
      name: "",
      type: "",
      phone: "",
      email: "",
      city: "",
      contactPersonName: "",
      color: "",
      validField: {
        name: {
          type: "text",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
        type: {
          type: "requiredSelect",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        phone: {
          type: "mobile",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        email: {
          type: "email",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        city: {
          type: "text",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        contactPersonName: {
          type: "text",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        color: {
          type: "text",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
      },
    }
  }

  componentWillMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }
    this.props.cleanSupplierForm()
  }
  componentWillUnmount() {
    this.setState({
      isCreateSupplierButtonDisabled: false,
      name: "",
      type: "",
      phone: "",
      email: "",
      city: "",
      contactPersonName: "",
      color: "",
    })
    this.props.cleanSupplierForm()
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }
  }

  handleFieldChange(inputName, inputValue) {
    const { supplier } = this.props
    let _supplier = { ...supplier }

    _supplier = {
      ..._supplier,
      [inputName]: inputValue,
    }

    this.props.editSupplierForm(_supplier)
    this.runValidation(inputName, inputValue)
  }

  onSubmitButtonClick() {
    const { name, type, phone, email, city, contactPersonName, color } =
      this.props.supplier

    let newSupplier = {
      name,
      type,
      phone,
      email,
      city,
      contactPersonName,
      color,
    }
    if (!this.isValidSupplier(newSupplier)) {
      console.log("IS NOT VALID SUPPLIER!!!")
      return
    }

    this.setState({ isCreateSupplierButtonDisabled: true })
    this.props.showLoading()
    this.props.createSupplier(newSupplier)
  }

  isValidSupplier(newSupplier) {
    const fieldsList = [
      "name",
      "type",
      "phone",
      "email",
      "city",
      "contactPersonName",
      "color",
    ]
    this.runValidationList(fieldsList, newSupplier)

    const { validField } = this.state
    let res = fieldsList.map((fieldName, i) => {
      if (!validField[fieldName].isValid) {
        console.log(fieldName + " is not valid")
        return false
      }
      return true
    })

    return res.filter((i) => i === true).length === fieldsList.length
  }

  runValidationList(fieldsList, supplier) {
    fieldsList.map((fieldName, i) =>
      this.runValidation(fieldName, supplier[fieldName])
    )
  }

  runValidation(inputName, inputValue) {
    let { validField } = this.state
    let field = validField[inputName]

    if (!field) return
    field.isChecked = true

    field.isValid = isValidField(
      inputValue,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  onFieldFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return
    field.isChecked = false
    validField[inputName] = field
    this.setState({
      ...this.state,
      validField,
    })
  }

  closeForm() {
    this.props.history.push("/ServiceSuppliersScreen")
  }

  render() {
    const { supplier, isLoading, isCreated } = this.props

    const { name, type, phone, email, city, contactPersonName, color } =
      supplier
    const fieldTypeList = FIELD_TYPE_ENUM
    const { validField, isCreateSupplierButtonDisabled } = this.state
    const supplierTypesList = SupplierTypes_ENUM

    if (supplier.type === "") {
      this.handleFieldChange("type", 1)
    }
    if (isLoading) {
      return (
        <div className="new-supplier spinner-wrapper">
          <Spinner zoom={2} />
        </div>
      )
    }

    let selectedColor = {
      label: " לא נבחר הצבע",
      value: "",
      color: "#fff",
    }

    if (color) {
      selectedColor = suppliersColors.find((e) => e.color === color)
    }

    return (
      <>
        <h1>{NewSupplier_TitleText}</h1>
        <div className="ss-container new-supplier">
          <div className="section-info-row">
            <LabeledTextInput
              labelText={NewSupplier_Name_InputLabelText}
              value={name}
              inputSize={40}
              onChange={(e) => {
                this.handleFieldChange("name", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("name")
              }}
              onBlur={(e) => {
                this.runValidation("name", name)
              }}
            />
            <ValidationMessage
              isShow={validField.name.isChecked}
              isShowOk={false}
              isShowError={!validField.name.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.name.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>
          <div className="section-info-row">
            <LabeledSelect
              labelText={NewSupplier_Type_InputLabelText}
              options={supplierTypesList}
              value={type}
              emptyOption={false}
              onChange={(e) => {
                this.handleFieldChange("type", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("type")
              }}
              onBlur={(e) => {
                this.runValidation("type", type)
              }}
            />

            <ValidationMessage
              isShow={validField.type.isChecked}
              isShowOk={false}
              isShowError={!validField.type.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.type.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <div className="section-info-row">
            <LabeledTextInput
              labelText={NewSupplier_Phone_InputLabelText}
              value={phone}
              type="mobile"
              onChange={(e) => {
                this.handleFieldChange("phone", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("phone")
              }}
              onBlur={(e) => {
                this.runValidation("phone", phone)
              }}
            />
            <ValidationMessage
              isShow={validField.phone.isChecked}
              isShowOk={false}
              isShowError={!validField.phone.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.phone.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <div className="section-info-row">
            <LabeledTextInput
              labelText={NewSupplier_Email_InputLabelText}
              value={email}
              inputSize={40}
              onChange={(e) => {
                this.handleFieldChange("email", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("email")
              }}
              onBlur={(e) => {
                this.runValidation("email", email)
              }}
            />
            <ValidationMessage
              isShow={validField.email.isChecked}
              isShowOk={false}
              isShowError={!validField.email.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.email.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <div className="section-info-row">
            <LabeledTextInput
              labelText={NewSupplier_City_InputLabelText}
              value={city}
              inputSize={40}
              onChange={(e) => {
                this.handleFieldChange("city", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("city")
              }}
              onBlur={(e) => {
                this.runValidation("city", city)
              }}
            />
            <ValidationMessage
              isShow={validField.city.isChecked}
              isShowOk={false}
              isShowError={!validField.city.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.city.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <div className="section-info-row">
            <LabeledTextInput
              labelText={NewSupplier_ContactName_InputLabelText}
              value={contactPersonName}
              inputSize={40}
              onChange={(e) => {
                this.handleFieldChange("contactPersonName", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("contactPersonName")
              }}
              onBlur={(e) => {
                this.runValidation("contactPersonName", contactPersonName)
              }}
            />
            <ValidationMessage
              isShow={validField.contactPersonName.isChecked}
              isShowOk={false}
              isShowError={!validField.contactPersonName.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.contactPersonName.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <div className="section-info-row color">
            <div className="ss-group">
              <Select
                options={suppliersColors}
                onChange={(e) => {
                  this.handleFieldChange("color", e.value)
                }}
                value={selectedColor}
                placeholder="בחר צבע"
                classNamePrefix="base-select"
                styles={colourStyles}
              />
            </div>
          </div>

          <button
            type="submit"
            className="ss-button"
            onClick={(e) => this.onSubmitButtonClick(e)}
            disabled={isCreateSupplierButtonDisabled}
          >
            {NewSupplier_SaveButtonText}
          </button>
        </div>

        <NotificationModal
          show={isCreated}
          title={NewSupplier_TitleText}
          text={NewSupplier_CreateSuccessed_Text}
          onOkClick={() => this.closeForm()}
        />
      </>
    )
  }
}

const mapStateToProp = ({ suppliers, errors }) => {
  const { suppliersList, supplier, isLoading, isCreated } = suppliers
  const { unauthorized } = errors
  return {
    suppliersList,
    supplier,
    isLoading,
    isCreated,
    unauthorized,
  }
}

export default connect(mapStateToProp, {
  cleanSupplierForm,
  editSupplierForm,
  createSupplier,
  showLoading,
})(NewSupplier)
