import React, { Component } from "react"
import { connect } from "react-redux"

import { updateSelfPassword, resetLoginAttempt } from "../../../actions"
import UpdateTemporaryPasswordModal from "../../Modals/UpdateTemporaryPasswordModal"
import NotificationModal from "../../Modals/NotificationModal"
import { validation } from "../../../utils/FieldsValidation"

import "./style.css"

class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: true,
      errorMsg: "",
      isVerifyFailed: true,
      showSuccesedModal: false,
      showFailModal: false,
    }
  }
  componentWillMount() {
    //this.props.resetLoginAttempt()
  }
  componentWillReceiveProps(newProps) {
    const { unauthorized, isLoginFailed } = newProps

    if (unauthorized) {
      this.props.history.push("/")
    }

    if (this.props.loadingReq === true && newProps.loadingReq === false) {
      this.setState({
        showModal: false,
        showSuccesedModal: !isLoginFailed,
        showFailModal: isLoginFailed,
      })
    }
  }

  onUserUpdateSelfPassword(pwd, pwdVerify) {
    const isValid =
      validation.PwdStrong(pwd) && validation.PwdVerify(pwd, pwdVerify)

    if (isValid) {
      this.props.updateSelfPassword(pwd)
    } else {
      this.setState({
        isVerifyFailed: true,
        errorMsg: "נא לבדוק שוב את הפרטים",
      })
    }
  }
  clearInput() {
    this.setState({
      showModal: true,
      errorMsg: "",
      isVerifyFailed: true,
      showSuccesedModal: false,
      showFailModal: false,
    })
  }
  onSuccessFailClick() {
    this.props.resetLoginAttempt()
    this.props.history.push("/")
  }

  render() {
    const { showModal, errorMsg, showSuccesedModal, showFailModal } = this.state
    const { loadingReq, isLoginFailed } = this.props

    return (
      <div>
        Update Password SCREEN
        <UpdateTemporaryPasswordModal
          show={showModal}
          loading={loadingReq}
          isLoginFailed={isLoginFailed}
          errorMsg={errorMsg}
          onSubmitClick={(pwd, pwdVerify) =>
            this.onUserUpdateSelfPassword(pwd, pwdVerify)
          }
          onSuccessFailClick={(success) => this.onSuccessFailClick(success)}
        />
        <NotificationModal
          show={showSuccesedModal}
          type="success"
          title="עדכון סיסמא עבר בהצלתה"
          text="נא לבצע כניסה למערכת עם פרטים מעודכנים"
          onOkClick={() => this.onSuccessFailClick()}
        />
        <NotificationModal
          show={showFailModal}
          type="error"
          title="עדכון סיסמא נכשל"
          text="אנא נסה שוב"
          onOkClick={() => this.clearInput()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ login, errors }) => {
  const { logedUserData, loadingReq, isLoginFailed } = login
  const { unauthorized } = errors
  return {
    logedUserData,
    loadingReq,
    isLoginFailed,
    unauthorized,
  }
}

export default connect(mapStateToProps, {
  updateSelfPassword,
  resetLoginAttempt,
})(ResetPasswordScreen)
