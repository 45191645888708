import React from "react"
import { connect } from "react-redux"
import { Link, Route } from "react-router-dom"
import { BlueButton } from "../../../common/BlueButton"
import PriceListSelect from "./PriceListSelect"
import DeletePriceListButton from "./DeletePriceListButton"
import LaundryPriceList from "./LaundryPriceList"
import ProductsPriceList from "./ProductsPriceList"
import SuppplierPrices from "./SuppplierPrices"

const priceListsSectionHeader = "מחירונים"

const PriceLists = (props) => {
  return (
    <>
      <h1 id="price-lists-section-header">{priceListsSectionHeader}</h1>
      <PriceListSelect />
      <DeletePriceListButton />
      <div className="list-link-holder">
        {renderLaundryPriceListLink(props, props.laundryPriceList)}
        {renderProductsPriceListLink(props, props.productsPriceList)}
        {renderSupplierPricesLink(props, props.laundryPriceList)}
      </div>
      <Route
        path={`${props.match.path}/laundry-price-list`}
        component={LaundryPriceList}
      />
      <Route
        path={`${props.match.path}/products-price-list`}
        component={ProductsPriceList}
      />
      <Route
        path={`${props.match.path}/suppplier-prices`}
        component={SuppplierPrices}
      />
    </>
  )
}

const renderSupplierPricesLink = (props, laundryPriceList) => {
  if (laundryPriceList.length !== 0) {
    return (
      <Link to={`${props.match.url}/suppplier-prices`}>
        <BlueButton extraClass="price-list-section-price-list-link">
          מחירון של ספק
        </BlueButton>
      </Link>
    )
  }
  return null
}

const renderLaundryPriceListLink = (props, laundryPriceList) => {
  if (laundryPriceList.length !== 0) {
    return (
      <Link to={`${props.match.url}/laundry-price-list`}>
        <BlueButton extraClass="price-list-section-price-list-link">
          מחירון כביסה
        </BlueButton>
      </Link>
    )
  }
  return null
}

const renderProductsPriceListLink = (props, productsPriceList) => {
  if (productsPriceList.length !== 0) {
    return (
      <Link to={`${props.match.url}/products-price-list`}>
        <BlueButton extraClass="price-list-section-price-list-link">
          מחירון מוצרים
        </BlueButton>
      </Link>
    )
  }
  return null
}

const mapStateToProps = ({ priceListsSection }) => {
  const { laundryPriceList, productsPriceList } = priceListsSection
  return {
    laundryPriceList,
    productsPriceList,
  }
}

export default connect(mapStateToProps, null)(PriceLists)
