import React from 'react';

import './style.css';

const DrawerItem = ({ icon, text, onClick }) => {

    return (
        <div className='drawer-item-wrapper' onClick={onClick}>
            <div className='drawer-item-icon'>
                <div className={`fa ${icon}`} />
            </div>
            <div className='drawer-item-text'>{text}</div>
        </div>
    );
}

export default DrawerItem;
