import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { SmallHeader, BlueButton } from "../../common"
import {
  getOrderImages,
  getProcesses,
  clearOrderImages,
} from "../../../actions"
import Modal from "../Modal"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import { Navigation } from "swiper"
import "./OrderImagesModal.scss"

const OrderImagesModal = (props) => {
  const { order, laundryImages, processes } = props
  const [swiperData, setSwiperData] = useState(null)

  useEffect(() => {
    props.getOrderImages(order.id)
    props.getProcesses()
  }, [])

  useEffect(() => {
    if (laundryImages && laundryImages.length) {
      fillCarousel()
    }
  }, [laundryImages])

  const fillCarousel = () => {
    const result = laundryImages.map((item) => {
      return (
        <SwiperSlide className="slide">
          <div className="item_img">
            <img
              src={`data:image/jpeg;base64,${item.imageData}`}
              alt="laundry image"
            />
            <div>{getProcessName(item.processId)}</div>
          </div>
        </SwiperSlide>
      )
    })
    setSwiperData(result)
  }

  const getProcessName = (id) => {
    if (processes) {
      let process = processes.find((element) => {
        return element.processId === id
      })
      if (process) {
        return process.processName_he
      }
    }
  }

  const closeModal = () => {
    props.clearOrderImages()
    props.showOrderImagesModal(false)
  }

  if (!props.show) {
    return null
  }
  return (
    <Modal extraClass="cror-modal-wrap">
      <div className="cror-modal-container">
        <div className="laundry-images-modal">
          <SmallHeader title="תמונות" />
          <div className="modal-body">
            <div className="title">תמונות להזמנה {order.orderNumber}</div>
            <div className="carousel-holder">
              {swiperData && swiperData.length > 0 ? (
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  {swiperData}
                </Swiper>
              ) : (
                <div className="loading">Loading...</div>
              )}
            </div>
            <div className="btn-holder">
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => closeModal()}
              >
                סגור
              </BlueButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProp = ({ orderImages, priceListsSection }) => {
  const { laundryImages } = orderImages
  const { processes } = priceListsSection

  return {
    laundryImages,
    processes,
  }
}

export default connect(mapStateToProp, {
  getOrderImages,
  getProcesses,
  clearOrderImages,
})(OrderImagesModal)
