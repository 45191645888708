import React, { Component } from "react"
import { connect } from "react-redux"

import RoutesList from "./RoutesList"
import { Pagination } from "../../../common"
import NotificationModal from "../../../Modals/NotificationModal"

import {
  onLoadingRoutes,
  fetchRoutes,
  closeRouteNotification,
  fetchSuppliersSelectList,
} from "../../../../actions"

import "./style.css"

class RoutesPage extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      pagedList: [],
      pageNum: 1,
    }
    this.state = this.INIT_STATE
  }

  componentWillMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }

    this.props.onLoadingRoutes()
    this.props.fetchRoutes()
    this.props.fetchSuppliersSelectList()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.routesList !== this.props.routesList) {
      this.updatePagedList(this.props.routesList, this.state.pageNum)
    }
  }

  componentWillReceiveProps(newProps) {
    let { reloadRoutes } = newProps
    if (
      (newProps.currentRoute == null && this.props.currentRoute != null) ||
      reloadRoutes
    ) {
      this.props.onLoadingRoutes()
      this.props.fetchRoutes()
    }
  }

  updatePagedList(routesList, pageNum) {
    const totalPerPage = 50
    const startIndex = (pageNum - 1) * totalPerPage
    const endIndex = startIndex + totalPerPage
    const pagedList = routesList.slice(startIndex, endIndex)

    this.setState({ pagedList })
  }

  render() {
    const {
      routesList,
      showCreateSuccess,
      showCreateFail,
      showCreateConflict,
      showDeleteSuccess,
      showDeleteFail,
      showDeleteConflict,
      showUpdateSuccess,
      showUpdateFail,
      showUpdateConflict,
    } = this.props
    const { pagedList, pageNum } = this.state

    return (
      <div className="stations-page-wrapper">
        <RoutesList items={pagedList} history={this.props.history} />

        <Pagination
          fullList={routesList}
          totalPerPage={50}
          onSubListReady={(pagedList, pageNum) =>
            this.setState({ pagedList, pageNum })
          }
          pageNum={pageNum}
        />

        <NotificationModal
          show={showDeleteConflict}
          title={"הסרת מסלול הפצה"}
          text={"לא ניתן להסיר את המסלול הפצה כי שוייך לעמדה."}
          onOkClick={() => this.props.closeRouteNotification()}
        />

        <NotificationModal
          show={showDeleteSuccess}
          title={"הסרת מסלול הפצה"}
          text={"מסלול הפצה הוסר בהצלחה"}
          onOkClick={() => this.props.closeRouteNotification()}
        />

        <NotificationModal
          show={showDeleteFail}
          title={"הסרת מסלול הפצה"}
          text={"תהליך הסרה נכשל"}
          onOkClick={() => this.props.closeRouteNotification()}
        />

        <NotificationModal
          show={showCreateSuccess}
          title={"הוספת מסלול הפצה"}
          text={"מסלול הפצה הוסף בהצלחה"}
          onOkClick={() => this.props.closeRouteNotification()}
        />

        <NotificationModal
          show={showCreateFail}
          title={"הוספת מסלול הפצה"}
          text={"תהליך הוספה נכשל"}
          onOkClick={() => this.props.closeRouteNotification()}
        />

        <NotificationModal
          show={showCreateConflict}
          title={"הוספת מסלול הפצה"}
          text={"שם מסלול הפצה כבר קיים במערכת"}
          onOkClick={() => this.props.closeRouteNotification()}
        />

        <NotificationModal
          show={showUpdateSuccess}
          title={"עדכון מסלול הפצה"}
          text={"מסלול הפצה עודכן בהצלחה"}
          onOkClick={() => this.props.closeRouteNotification()}
        />

        <NotificationModal
          show={showUpdateFail}
          title={"עדכון מסלול הפצה"}
          text={"תהליך עדכון נכשל"}
          onOkClick={() => this.props.closeRouteNotification()}
        />

        <NotificationModal
          show={showUpdateConflict}
          title={"עדכון מסלול הפצה"}
          text={"שם מסלול הפצה כבר קיים במערכת"}
          onOkClick={() => this.props.closeRouteNotification()}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ errors, routes }) => {
  const {
    routeStations,
    showCreateSuccess,
    showCreateFail,
    showCreateConflict,
    showDeleteSuccess,
    showDeleteFail,
    showDeleteConflict,
    showUpdateSuccess,
    showUpdateFail,
    showUpdateConflict,
    reloadRoutes,
  } = routes
  const { unauthorized } = errors
  const routesList = routes.routes
  return {
    unauthorized,
    routeStations,
    routesList,
    showCreateSuccess,
    showCreateFail,
    showCreateConflict,
    showDeleteSuccess,
    showDeleteFail,
    showDeleteConflict,
    showUpdateSuccess,
    showUpdateFail,
    showUpdateConflict,
    reloadRoutes,
  }
}

export default connect(mapStateToProps, {
  onLoadingRoutes,
  fetchRoutes,
  closeRouteNotification,
  fetchSuppliersSelectList,
})(RoutesPage)
