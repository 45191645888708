import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { SmallHeader, BlueButton } from "../../common"
import {} from "../../../actions"
import Modal from "../Modal"
import "./CheapSimModal.scss"

const CheapSimModal = (props) => {
  const [isValidationError, setValidationError] = useState(false)
  const [isDublicateError, setDublicateError] = useState(false)
  const [simNumbersList, setSimNumbersList] = useState([])
  const [isSubmited, setIsSubmited] = useState(false)
  const inputRefs = useRef([])
  const scannerInputRef = useRef(null)

  useEffect(() => {
    const isDuplicate = hasDuplicates()
    if (isDuplicate) {
      setDublicateError(true)
    } else {
      setDublicateError(false)
    }
  }, [simNumbersList])

  const clearState = () => {
    setValidationError(false)
    setDublicateError(false)
    setSimNumbersList([])
  }

  const closeModal = () => {
    clearState()
    props.onCancelClick()
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setIsSubmited(true)
    const isEmptyField = isEmptyFields()
    if (isEmptyField) {
      return
    }
    if (isDublicateError) {
      return
    }
    setValidationError(false)
    const simNumbers = getSimNumbersFromObject()
    props.onSubmitClick(simNumbers)
    clearState()
  }

  const isEmptyFields = () => {
    const form = document.getElementById("form")
    for (let i = 0; i < form.length - 2; i++) {
      if (form[i].value == "" || form[i].value.length < 19) {
        setValidationError(true)
        return true
      }
    }
    setValidationError(false)
    return false
  }

  const getSimNumbersFromObject = () => {
    let numbersOnly = simNumbersList.map((num) => {
      return num.value
    })
    return numbersOnly
  }

  const handleTextChange = (event, index) => {
    const newNumber = event.target.value
    const currentInput = index + 1
    const newItems = [...simNumbersList]
    const simNumIndex = newItems.findIndex((item) => item.id === currentInput)
    if (simNumIndex !== -1) {
      newItems[simNumIndex].value = newNumber
      setSimNumbersList(newItems)
    } else {
      setSimNumbersList([
        ...simNumbersList,
        { id: currentInput, value: newNumber },
      ])
    }
    if (newNumber.length === 19 && currentInput < inputRefs.current.length) {
      inputRefs.current[currentInput].focus()
    }
    if (isSubmited) {
      const isEmptyField = isEmptyFields()
      if (!isEmptyField) {
        setIsSubmited(false)
      }
    }
  }

  const handleScannerInput = (event) => {
    if (event.target.value.length >= 19) {
      if (event.key === "Enter") {
        event.preventDefault()
      }
    }
  }

  const generateModalContent = (order) => {
    const inputs = createInputs(order)
    const form = createForm(inputs)
    return form
  }

  function hasDuplicates() {
    if (simNumbersList && simNumbersList.length > 0) {
      const valuesArr = simNumbersList.map((item) => item.value)
      const uniqueValuesArr = Array.from(new Set(valuesArr))
      return valuesArr.length !== uniqueValuesArr.length
    }
  }

  const createInputs = (order) => {
    var inputs = []
    const totalSimCards =
      order.bagsQuantity === 0 || order.bagsQuantity === null
        ? 1
        : order.bagsQuantity
    for (let i = 0; i < totalSimCards; i++) {
      inputs.push(
        <div className="input-wrapper">
          <div className="input-title">
            <span> מספר סים {i + 1}: </span>
          </div>
          <input
            id={"simNumber" + (i + 1)}
            className="input-text"
            ref={(ddd) => (inputRefs.current[i] = ddd)}
            autoFocus={i == 0 ? true : false}
            onChange={(e) => handleTextChange(e, i)}
            onKeyDown={handleScannerInput}
          ></input>
        </div>
      )
    }
    return inputs
  }

  const createForm = (inputs) => {
    return (
      <form id="form" onSubmit={handleSubmit}>
        {inputs}
        {isValidationError ? (
          <div className="validation-msg">
            אנא מלא את כל השדות עם מספרי סים תקנים
          </div>
        ) : null}
        {isDublicateError ? (
          <div className="validation-msg">יש מספרים כפולים ברשימה</div>
        ) : null}
        <div className="cror-modal-status">
          <BlueButton type="submit" extraClass="cror-modal-button">
            אישור
          </BlueButton>
          <BlueButton
            extraClass="cror-modal-button"
            onClick={(e) => closeModal()}
          >
            ביטול
          </BlueButton>
        </div>
      </form>
    )
  }

  const { orderData } = props
  if (!props.show) {
    return null
  }
  return (
    <Modal extraClass="cror-modal-wrap">
      <div className="cror-modal-container">
        <div className="cheapsim-modal">
          <SmallHeader title="עדכון הזמנות CheapSim" />
          <div className="modal-body">
            <div className="modal-title">
              סרוק את מספר SIM של חבילה {orderData.packageNumber}
            </div>
            {generateModalContent(orderData)}
          </div>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProp = ({}) => {
  return {}
}

export default connect(mapStateToProp, {})(CheapSimModal)
