import React, { Component } from "react"
import { connect } from "react-redux"

import { LabeledTextInput, CheckboxAndUpperLabel } from "../../../../common"
import Select from "react-select"
import "./style.scss"

const Locker_LockerNumberInputLabelText = "מס' תא"
const Locker_LockerType_SelectLabelText = "סוג תא"
const Locker_IsAccessibleLabelText = "נגיש"
const Locker_LockerSize_SelectLabelText = "גודל התא"
class Locker extends Component {
  handleLockerChange = (inputName, inputValue) => {
    const { locker, lockerIndex, onLockersChange, lockerSizeOptions } =
      this.props
    let _locker = {
      ...locker,
      [inputName]:
        inputName === "lockerType" ? parseInt(inputValue) : inputValue,
    }

    if (
      inputName === "lockerType" &&
      parseInt(inputValue) === 0 &&
      lockerSizeOptions.find((o) => o.value.id == 1)
    ) {
      _locker.lockerSize = lockerSizeOptions.find((o) => o.value.id == 1).value
    }

    onLockersChange(_locker, lockerIndex)
  }

  render() {
    const { locker, lockerTypes, lockerTypeSelectOptions, lockerSizeOptions } =
      this.props
    const currentLockerType = lockerTypes
      ? { value: locker.lockerType, label: lockerTypes[locker.lockerType] }
      : { value: locker.lockerType, label: locker.lockerType }

    const currentLockerSize = locker.lockerSize
      ? {
          value: locker.lockerSize,
          label: locker.lockerSize.name,
          rawSize: `${locker.lockerSize.width}x${locker.lockerSize.height}x${locker.lockerSize.depth}`,
        }
      : { value: null, label: "NA" }
    return (
      <div className="stations-section-locker-block">
        <LabeledTextInput
          labelText={Locker_LockerNumberInputLabelText}
          value={locker.lockerName}
          inputSize={4}
          onChange={(e) =>
            this.handleLockerChange("lockerName", e.target.value)
          }
          disabled={true}
        />
        <div className="select-wrapper">
          <div className="select-label">
            {Locker_LockerType_SelectLabelText}
          </div>
          <Select
            labelText={Locker_LockerType_SelectLabelText}
            options={lockerTypeSelectOptions}
            value={currentLockerType}
            //emptyOption={false}
            //onChange={(e) => this.handleLockerChange('lockerType', e.target.value)}
            onChange={(e) => this.handleLockerChange("lockerType", e.value)}
            classNamePrefix="base-select"
          />
        </div>
        <div
          className="select-wrapper locker-size"
          title={currentLockerSize.rawSize}
        >
          <div className="select-label">
            {Locker_LockerSize_SelectLabelText}
          </div>
          <Select
            labelText={Locker_LockerSize_SelectLabelText}
            options={lockerSizeOptions}
            value={currentLockerSize}
            onChange={(e) => this.handleLockerChange("lockerSize", e.value)}
            classNamePrefix="base-select"
          />
        </div>

        <div className="ss-group">
          <CheckboxAndUpperLabel
            checked={locker.isAccessible}
            onChange={(e) => {
              this.handleLockerChange("isAccessible", e.target.checked)
            }}
          >
            <div className="fa fa-wheelchair" aria-hidden="true"></div>
            {Locker_IsAccessibleLabelText}
          </CheckboxAndUpperLabel>
        </div>
      </div>
    )
  }
}

const MapStateToProps = ({ selectOptions }) => {
  const { lockerTypes, lockerTypeSelectOptions, lockersSizes } = selectOptions

  const lockerSizeOptions = lockersSizes
    ? lockersSizes.map((size) => {
        return {
          label: size.name,
          value: size,
          rawSize: `${size.width}x${size.height}x${size.depth}`,
        }
      })
    : []

  return {
    lockerTypes,
    lockerTypeSelectOptions,
    lockerSizeOptions,
  }
}

export default connect(MapStateToProps, null)(Locker)
