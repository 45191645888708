import React, { Component } from "react"
import { Editable, SelectVal, BlueButton } from "../../../../common"
import Select from "react-select"
import "./style.css"

class OrderItemCreation extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      processName: "",
      quantity: "",
      itemPrice: "",
      // notes: ''
    }

    this.state = this.INIT_STATE
  }

  onInputChange(inputName, val) {
    this.setState({ [inputName]: val })
  }

  onAddNewItemClick() {
    const { processName, quantity, itemPrice } = this.state
    this.props.addNewItem({ processName, quantity, itemPrice })
    this.setState({ ...this.INIT_STATE })
  }

  render() {
    const { quantity, itemPrice } = this.state
    const { processes } = this.props
    return (
      <div className="oritcr-wrapper">
        <div className="oritcr-title">יצירת פריט הזמנה חדש:</div>
        <div className="oritcr-container">
          <Select
            placeholder="בחר את שם השירות"
            options={processes}
            onChange={(val) => this.onInputChange("processName", val.value)}
          />
          כמות פריטים:
          <Editable
            value={quantity}
            placeholder="הזן כמות"
            extraClass="oritcr-editable"
            onChange={(e) =>
              this.onInputChange("quantity", parseInt(e.target.value) || "")
            }
          />
          מחיר:
          <Editable
            value={itemPrice}
            placeholder="הזן מחיר"
            extraClass="oritcr-editable"
            onChange={(e) =>
              this.onInputChange(
                "itemPrice",
                parseFloat(e.target.value) || e.target.value.endsWith(".")
                  ? e.target.value
                  : ""
              )
            }
          />
          <BlueButton
            onClick={(e) => this.onAddNewItemClick()}
            extraClass="oritcr-modal-btn"
          >
            הוסף
          </BlueButton>
        </div>
      </div>
    )
  }
}

export default OrderItemCreation
