import React, { useState } from "react";
import { BlueButton } from "../../../common/BlueButton";
import { SmallHeader } from "../../../common";
import "./style.css";

const BlurFilterWithMessage = ({ msg, show, onClose }) => {
  const [isClose, setIsClose] = useState(false);
  if (!show) {
    return <noscript />;
  }

  return (
    <div className="blur-filter-wrapper">
      <div className="blur-filter-msg-container">
        <SmallHeader title={"Clean Box System"} />
        <div className="yesno-modal-content-wrapper">{msg}</div>
        <div className="btn-close-holder">
          <BlueButton extraClass="btn-close" onClick={onClose}>
            סגור
          </BlueButton>
        </div>
      </div>
    </div>
  );
};

export default BlurFilterWithMessage;
