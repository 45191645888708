import React from "react"
import Header from "../../../../common/List/Header"
import CityItem from "./CityItem"
import { H1, Spinner } from "../../../../common"
import "./style.css"

const citiesHeaders = ["שם בעברית", "שם באנגלית", "שם ברוסית", "איזור", ""]
const citiesItemMap = [
  "cityName",
  "cityName_en",
  "cityName_ru",
  "districtName",
  "edit",
]
const citiesGravity = {}

const CitiesList = ({
  extraClass,
  items,
  onItemEditBtnClick,
  isError,
  isLoading,
}) => {
  return (
    <div className={`cities-list list-wrapper ${extraClass}`}>
      <Header titles={citiesHeaders} gravity={citiesGravity} />
      <div className="list-items-wrapper">
        {renderItems(
          items,
          citiesGravity,
          citiesItemMap,
          onItemEditBtnClick,
          isError,
          isLoading
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  citiesGravity,
  citiesItemMap,
  onItemEditBtnClick,
  isError,
  isLoading
) => {
  if (isError) {
    return (
      <H1 className="not-valid-search" title="אנא בדוק את הערך של שדה חיפוש" />
    )
  }
  if (isLoading) {
    return (
      <div className="city-editor-spinner-wrapper">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (!items || items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }

  return items.map((itm, index) => {
    return (
      <CityItem
        key={index}
        item={itm}
        itemMap={citiesItemMap}
        gravity={citiesGravity}
        isLast={index === items.length - 1}
        onEditBtnClick={onItemEditBtnClick}
      />
    )
  })
}

export default CitiesList
