import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import {
  fetchRoutes,
  getAllSuppliers,
  getShippingCertificateByAccountType,
  getShippingCertificateByRoute,
  getShippingCertificateByRouteOutsource,
  getShippingCertificateBySupplier,
  fetchAccountTypes,
  createOrderSackByAccount,
  putOrdersToSack,
  clearOrdersSacks,
  clearCertificates,
} from "../../../../../actions"
import { BlueButton } from "../../../../common/BlueButton"
import Select from "react-select"
import YesNoPopup from "../../../../Modals/YesNoPopup/yesNoPopup.js"
import NotificationModal from "../../../../Modals/NotificationModal"
import Barcode from "react-barcode"
import CertificateOrdersTable from "../CertificateOrdersTable/CertificateOrdersTable.js"
import "./style.scss"

const ShippingCertificatePage = (props) => {
  const [suppliersFiltered, setSuppliersFiltered] = useState({})
  const [selectedRoute, setSelectedRoute] = useState("")
  const [selectedSupplier, setSelectedSupplier] = useState("")
  const [selectedAccountType, setSelectedAccountType] = useState("")
  const [ordersListToDisplay, setOrdersListToDisplay] = useState([])
  const [accountTypesList, setAccountTypesList] = useState([])
  const [showConfirmCreationSack, setShowConfirmCreationSack] = useState(false)
  const [sackBarcode, setSackBarcode] = useState("")
  const [selectedRouteLaundry, setSelectedRouteLaundry] = useState("")
  const [isWithoutLaundry, setIsWithoutLaundry] = useState(true)
  const [isOnlyLaundry, setIsOnlyLaundry] = useState(false)
  const [selectedOption, setSelectedOption] = useState("withoutLaundry")
  const [isNoOrdersToPrintMsg, setIsNoOrdersToPrintMsg] = useState(false)
  const notAllowedAccountTypes = [
    2, 3, 4, 5, 7, 11, 13, 17, 18, 21, 23, 24, 28, 29,
  ]

  useEffect(() => {
    props.fetchRoutes()
    props.fetchAccountTypes()
    if (!props.suppliersList) {
      props.getAllSuppliers()
    }
    return () => {
      props.clearCertificates()
    }
  }, [])

  useEffect(() => {
    if (props.suppliersList) {
      const suppliersFiltered = props.suppliersList.map((supplier, index) => ({
        value: index,
        label: supplier.name,
        id: supplier.id,
      }))
      setSuppliersFiltered(suppliersFiltered)
    }
  }, [props.suppliersList])

  useEffect(() => {
    if (props.routeShippingOrders && props.routeShippingOrders.length > 0) {
      selectOrdersList()
    }
  }, [props.routeShippingOrders])

  useEffect(() => {
    if (
      props.suppliersShippingOrders &&
      props.suppliersShippingOrders.length > 0
    ) {
      selectOrdersList()
    }
  }, [props.suppliersShippingOrders])

  useEffect(() => {
    if (props.shippingCertificate && props.shippingCertificate.length > 0) {
      selectOrdersList()
    }
  }, [props.shippingCertificate])

  useEffect(() => {
    if (props.accountTypes) {
      const listItems = Object.entries(props.accountTypes)
        .filter(
          ([value]) => !notAllowedAccountTypes.includes(parseInt(value, 10))
        )
        .map(([value, label]) => ({
          value: parseInt(value, 10),
          label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
      setAccountTypesList(listItems)
    }
  }, [props.accountTypes])

  useEffect(() => {
    if (props.createdSack) {
      setSackBarcode(props.createdSack.barcode)
      const ordersIds = createArayOfOrdersId()
      props.putOrdersToSack(props.createdSack.id, ordersIds)
    }
  }, [props.createdSack])

  useEffect(() => {
    if (props.putOrdersToSackSuccess && sackBarcode != "") {
      printCertificate()
      setOrdersListToDisplay([])
    }
  }, [props.putOrdersToSackSuccess])

  useEffect(() => {
    if (props.routeShippingOrders?.length > 0) {
      setOrdersListToDisplay([])
      const filteredOrders = filterOrdersByLaundry()
      setOrdersListToDisplay((prevOrdersList) => [
        ...prevOrdersList,
        filteredOrders,
      ])
    }
  }, [isWithoutLaundry, isOnlyLaundry])

  const createArayOfOrdersId = () => {
    const ordersIds = []
    ordersListToDisplay.forEach((orderList) => {
      orderList.forEach((order) => {
        ordersIds.push(order.id)
      })
    })
    return ordersIds
  }

  const handleRouteChange = (route) => {
    props.getShippingCertificateByRoute(route.value, 3)
    setSelectedSupplier("")
    setSelectedAccountType("")
    setSelectedRouteLaundry("")
    setOrdersListToDisplay([])
    setSelectedRoute(route)
  }

  const handleSupplierChange = (supplier) => {
    props.getShippingCertificateBySupplier(supplier.id, 17)
    setSelectedRoute("")
    setSelectedAccountType("")
    setSelectedRouteLaundry("")
    setOrdersListToDisplay([])
    setSelectedSupplier(supplier)
  }

  const handleOrderTypeChange = (accountType) => {
    props.getShippingCertificateByAccountType(accountType.value, 16)
    setSelectedRoute("")
    setSelectedSupplier("")
    setSelectedRouteLaundry("")
    setOrdersListToDisplay([])
    setSelectedAccountType(accountType)
  }

  const handleRouteLaundryChange = (route) => {
    props.getShippingCertificateByRouteOutsource(route.value, 3)
    setSelectedSupplier("")
    setSelectedRoute("")
    setSelectedAccountType("")
    setOrdersListToDisplay([])
    setSelectedRouteLaundry(route)
  }

  const filterOrdersByLaundry = () => {
    const filteredOrders = props.routeShippingOrders.filter((order) => {
      if (isWithoutLaundry) {
        return order.orderType != 1
      }
      if (isOnlyLaundry) {
        return order.orderType == 1
      }
    })

    return filteredOrders
  }

  const selectOrdersList = () => {
    if (selectedRoute) {
      setOrdersListToDisplay((prevOrdersList) => [
        ...prevOrdersList,
        props.routeShippingOrders,
      ])
    }
    if (selectedSupplier) {
      setOrdersListToDisplay((prevOrdersList) => [
        ...prevOrdersList,
        props.suppliersShippingOrders,
      ])
    }
    if (selectedAccountType) {
      setOrdersListToDisplay((prevOrdersList) => [
        ...prevOrdersList,
        props.shippingCertificate,
      ])
    }
    if (selectedRouteLaundry) {
      const filteredOrders = filterOrdersByLaundry()
      setOrdersListToDisplay((prevOrdersList) => [
        ...prevOrdersList,
        filteredOrders,
      ])
    }
  }

  const printAllRouteSertificates = () => {
    setSelectedSupplier("")
    setSelectedAccountType("")
    setSelectedRoute(true)
    setOrdersListToDisplay([])
    for (let route of props.routesList) {
      props.getShippingCertificateByRoute(route.value, 3)
    }
  }

  const printAllSuppliersSertificates = () => {
    setSelectedSupplier(true)
    setSelectedAccountType("")
    setSelectedRoute("")
    setOrdersListToDisplay([])
    for (let supplier of suppliersFiltered) {
      props.getShippingCertificateBySupplier(supplier.id, 17)
    }
  }

  const confirmSackCreation = () => {
    setShowConfirmCreationSack(false)
    const strValue = String(selectedAccountType?.value)
    const match = strValue.match(/^\d+/)
    props.createOrderSackByAccount(match[0])
  }

  const printCertificate = () => {
    window.print()
    clearOrderSackState()
  }

  const clearOrderSackState = () => {
    setShowConfirmCreationSack(false)
    props.clearOrdersSacks()
    setSackBarcode("")
  }

  const createShippingCertificateList = () => {
    return ordersListToDisplay?.map((ordersList, index) => {
      if (ordersList?.length == 0) return

      return (
        <div key={index}>
          {ordersList ? (
            <CertificateOrdersTable
              ordersList={ordersList}
              accountTypesList={accountTypesList}
              title={selectedAccountType?.label || ""}
            />
          ) : (
            <div className="empty-list">בחר הזמנות מהרשימה</div>
          )}
        </div>
      )
    })
  }

  const handleRadioChange = (event) => {
    const { value } = event.target
    if (value === "withoutLaundry") {
      setIsWithoutLaundry(true)
      setIsOnlyLaundry(false)
    } else if (value === "onlyLaundry") {
      setIsWithoutLaundry(false)
      setIsOnlyLaundry(true)
    }
    setSelectedOption(value)
  }

  const checkForOrdersToCreateSack = () => {
    if (ordersListToDisplay.length > 0) {
      setShowConfirmCreationSack(true)
    } else {
      setIsNoOrdersToPrintMsg(true)
    }
  }

  const checkForOrdersToPrint = () => {
    if (ordersListToDisplay.length > 0) {
      window.print()
    } else {
      setIsNoOrdersToPrintMsg(true)
    }
  }

  return (
    <section className="shippingCertificatePage">
      <div className="selects-wrapper">
        <div className="select-routs">
          <h3> כללי</h3>
          <div className="btn-holder">
            <BlueButton
              disabled={selectedRoute == "" && selectedSupplier == ""}
              type="button"
              extraClass="btn-print"
              onClick={() => checkForOrdersToPrint()}
            >
              הדפס
            </BlueButton>
          </div>
          <div className="selects">
            <div>
              <BlueButton
                extraClass="btn-show-all"
                onClick={() => printAllRouteSertificates()}
              >
                הצג כל הקווים
              </BlueButton>
              <Select
                className="select"
                key={props.routesList?.length}
                placeholder={"הזמנות לפי קו"}
                options={props.routesList}
                value={selectedRoute}
                onChange={(route) => handleRouteChange(route)}
              />
            </div>
            <div>
              <BlueButton
                extraClass="btn-show-all"
                onClick={() => printAllSuppliersSertificates()}
              >
                הצג כל מכבסות
              </BlueButton>
              <Select
                className="select"
                key={props.setSuppliersFiltered?.length}
                placeholder={"הזמנות לפי מכבסה"}
                options={suppliersFiltered}
                value={selectedSupplier}
                onChange={(supplier) => handleSupplierChange(supplier)}
              />
            </div>
          </div>
        </div>

        <div className="select-returns">
          <div>
            <h3>החזרות</h3>
            <BlueButton
              disabled={selectedAccountType == ""}
              extraClass="btn-print-returns"
              onClick={() => checkForOrdersToCreateSack()}
            >
              הדפס החזרות
            </BlueButton>
            <Select
              className="select"
              placeholder={"הזמנות לפי חברה"}
              options={accountTypesList}
              value={selectedAccountType}
              onChange={(orderType) => handleOrderTypeChange(orderType)}
            />
          </div>
        </div>
        <div className="select-route-laundry">
          <h3> לנהגים חיצונים</h3>

          <BlueButton
            type="button"
            disabled={selectedRouteLaundry == ""}
            extraClass="btn-print-route-laundry"
            onClick={() => checkForOrdersToPrint()}
          >
            הדפס
          </BlueButton>

          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="laundryOptions"
                value="withoutLaundry"
                checked={selectedOption === "withoutLaundry"}
                onChange={handleRadioChange}
              />
              ללא כביסה
            </label>

            <label>
              <input
                type="radio"
                name="laundryOptions"
                value="onlyLaundry"
                checked={selectedOption === "onlyLaundry"}
                onChange={handleRadioChange}
              />
              רק כביסה
            </label>
          </div>

          <Select
            className="select"
            key={props.routesList?.length}
            placeholder={"הזמנות לפי קו (נהגים חיצונים)"}
            options={props.routesList}
            value={selectedRouteLaundry}
            onChange={(route) => handleRouteLaundryChange(route)}
          />
        </div>
      </div>
      <div className="barcode-wrapper">
        {sackBarcode ? <Barcode value={sackBarcode} /> : null}
      </div>

      <div key={ordersListToDisplay.length}>
        {createShippingCertificateList()}
      </div>
      <YesNoPopup
        isOpen={showConfirmCreationSack}
        infoMsg="האם אתה בטוח?"
        close={() => setShowConfirmCreationSack(false)}
        confirm={() => confirmSackCreation()}
      />
      <NotificationModal
        title={"יצירת תעודת משלוח"}
        type="error"
        show={props.putOrdersToSackFail}
        text={"תקלה ביצירת תעודת משלוח"}
        onOkClick={() => printCertificate()}
      />
      <NotificationModal
        title={"אין הזמנות להדפסה"}
        type="warning"
        show={isNoOrdersToPrintMsg}
        text={"אין הזמנות להדפסה"}
        onOkClick={() => setIsNoOrdersToPrintMsg(false)}
      />
    </section>
  )
}

const mapStateToProps = ({
  receptionOrders,
  routes,
  suppliers,
  selectOptions,
  orderSack,
}) => {
  const {
    shippingCertificate,
    suppliersShippingOrders,
    routeShippingOrders,
    shippingCertificateSuccess,
    shippingCertificateFail,
  } = receptionOrders
  const routesList = routes.routes
  const { suppliersList } = suppliers
  const { accountTypes } = selectOptions
  const { createdSack, putOrdersToSackSuccess, putOrdersToSackFail } = orderSack
  return {
    shippingCertificateSuccess,
    shippingCertificateFail,
    routesList,
    suppliersList,
    shippingCertificate,
    suppliersShippingOrders,
    routeShippingOrders,
    accountTypes,
    createdSack,
    putOrdersToSackSuccess,
    putOrdersToSackFail,
  }
}

export default connect(mapStateToProps, {
  fetchRoutes,
  getAllSuppliers,
  getShippingCertificateByAccountType,
  getShippingCertificateByRoute,
  getShippingCertificateByRouteOutsource,
  getShippingCertificateBySupplier,
  fetchAccountTypes,
  createOrderSackByAccount,
  putOrdersToSack,
  clearOrdersSacks,
  clearCertificates,
})(ShippingCertificatePage)
