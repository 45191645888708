import React from 'react';

import './style.css';

const WhiteFrame = ({ children, extraClass }) => {

    return (
        <div className={`white-frame-wrapper ${extraClass}`} >
            {renderChildren(children)}
        </div >
    );
}

const renderChildren = children => {
    if (!children || !children.length || children.length <= 1) {
        return children;
    }

    return children.map((child, index) => {
        if (index === children.length - 1) {
            return (
                <div className='white-frame-child-wrapper' key={index}>
                    <div className='white-frame-child'>
                        {child}
                    </div>
                </div>
            );
        }

        return (
            <div className='white-frame-child-wrapper' key={index}>
                <div className='white-frame-child'>
                    {child}
                </div>
                <div className='white-frame-child-seperator' />
            </div>
        );
    });
}

export { WhiteFrame };