import {
    ON_SEARCH_DATA_CHANGE
} from './types'

export const onSearchFieldChange = keyword => {
    return {
        type: ON_SEARCH_DATA_CHANGE,
        payload: keyword
    };
};
