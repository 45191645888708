import React from "react"

import Header from "./../../../common/List/Header"
import OrderItem from "./OrderItem"
import { H1, Spinner } from "../../../common"

import "./style.css"

const OrderList = ({
  extraClass,
  headers,
  items,
  gravity,
  itemMap,
  onSelectBtnClick,
  isError,
  isLoading,
}) => {
  return (
    <div className={`ol-list-wrapper ${extraClass}`}>
      <Header titles={headers} gravity={gravity} />
      <div className="ol-list-items-wrapper">
        {renderItems(
          items,
          gravity,
          itemMap,
          onSelectBtnClick,
          isError,
          isLoading
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  gravity,
  itemMap,
  onSelectBtnClick,
  isError,
  isLoading
) => {
  if (isError) {
    return <H1 className="not-valid-search" title="שגיא בביצוע פעולה." />
  }

  if (isLoading) {
    return (
      <div className="users-list">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }

  return items.map((itm, index) => {
    return (
      <OrderItem
        key={index}
        item={itm}
        itemMap={itemMap}
        gravity={gravity}
        isLast={index === items.length - 1}
        onSelectBtnClick={onSelectBtnClick}
      />
    )
  })
}

export default OrderList
