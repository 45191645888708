import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"

import {
  ON_REMOVE_COUPON,
  ON_UPDATE_COUPON,
  ON_CREATE_COUPON,
  FETCH_COUPON,
  FETCH_COUPONS,
  FETCH_ACTIVE_COUPONS,
  EDIT_COUPON_FORM,
  CLEAN_COUPON_FORM,
  CHECK_EXIST_COUPON_NAME,
  BEFORE_CHECK_EXIST_COUPON_NAME,
  COUPON_SHOW_LOADING,
  SET_COUPON_TO_EDIT,
  CLEAN_COUPON_UPDATE,
  CLEAN_COUPON_REMOVE,
  SET_EMPTY_COUPON,
} from "./types"

export const fetchCoupons = (couponId) => {
  return (dispatch) => {
    axios
      .get(api.fetchCoupons(couponId))
      .then((res) => {
        dispatch({
          type: FETCH_COUPONS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchActiveUserCoupons = (userId, orderType) => {
  return (dispatch) => {
    showCouponLoading(dispatch)
    axios
      .get(api.fetchActiveUserCoupons(userId, orderType))
      .then((res) => {
        dispatch({
          type: FETCH_ACTIVE_COUPONS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchActiveOrderUserCoupons = (userId, order) => {
  return (dispatch) => {
    showCouponLoading(dispatch)
    const options = {
      headers: {
        Authorization: axios.defaults.headers.common["Authorization"],
      },
    }
    axios
      .post(api.fetchActiveOrderUserCoupons(userId), order, options)
      .then((res) => {
        dispatch({
          type: FETCH_ACTIVE_COUPONS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const fetchCouponById = (couponId) => {
  if (!couponId) {
    return (dispatch) => {
      dispatch(cleanCouponForm())
    }
  }
  return (dispatch) => {
    showCouponLoading(dispatch)
    axios
      .get(api.fetchCoupons(couponId))
      .then((res) => {
        dispatch({
          type: FETCH_COUPON,
          payload: getOneFromList(res.data),
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch(cleanCouponForm())
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

const getOneFromList = (data) => {
  if (data && data.length > 0) return data[0]
  return data
}

export const fetchCouponByName = (couponName) => {
  return (dispatch) => {
    axios
      .get(api.getCouponByName(couponName))
      .then((res) => {
        dispatch({
          type: FETCH_COUPON,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const setSelectedCoupon = (coupon) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_COUPON,
      payload: coupon,
    })
  }
}
export const setEmptyCoupon = () => {
  return (dispatch) => {
    dispatch({
      type: SET_EMPTY_COUPON,
    })
  }
}
export const createCoupon = (formData) => {
  const options = {
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
    },
  }
  return (dispatch) => {
    showCouponLoading(dispatch)
    axios
      .post(api.createCoupon, formData, options)
      .then((res) => {
        dispatch({
          type: ON_CREATE_COUPON,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const updateCoupon = (coupon) => {
  const options = {
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
    },
  }
  return (dispatch) => {
    showCouponLoading(dispatch)
    axios
      .put(api.updateCoupon(coupon.couponId), coupon, options)
      .then((res) => {
        dispatch({
          type: ON_UPDATE_COUPON,
          payload: res.data,
        })

        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const removeCoupon = (couponId) => {
  const options = {
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
    },
  }
  return (dispatch) => {
    showCouponLoading(dispatch)
    axios
      .delete(api.removeCoupon(couponId), options)
      .then((res) => {
        dispatch({
          type: ON_REMOVE_COUPON,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const cleanCouponUpdate = () => {
  return {
    type: CLEAN_COUPON_UPDATE,
  }
}

export const cleanCouponRemove = () => {
  return {
    type: CLEAN_COUPON_REMOVE,
  }
}

export const editCouponForm = (coupon) => {
  return {
    type: EDIT_COUPON_FORM,
    payload: coupon,
  }
}

export const cleanCouponForm = () => {
  return {
    type: CLEAN_COUPON_FORM,
  }
}

export const showCouponLoading = (dispatch) => {
  dispatch({
    type: COUPON_SHOW_LOADING,
  })
}

export const cleanCouponNameCheck = (couponName) => {
  return {
    type: BEFORE_CHECK_EXIST_COUPON_NAME,
    payload: couponName,
  }
}

export const checkIfCouponNameExist = (couponName) => {
  return (dispatch) => {
    axios
      .get(api.checkExistCouponName(couponName))
      .then((res) => {
        dispatch({
          type: CHECK_EXIST_COUPON_NAME,
          payload: res.data,
        })
        console.log("checkExistCouponName:" + res.data)
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const setCouponToEdit = (coupon) => {
  return {
    type: SET_COUPON_TO_EDIT,
    payload: coupon,
  }
}
