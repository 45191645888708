import React, { useRef, useState, useEffect } from "react"
import { connect } from "react-redux"
import { BlueButton } from "../../../common"
import { downloadReservedLockerReport } from "../../../../actions"
import Select from "react-select"
import "./style.scss"

const ReservedLockerReport = (props) => {
  const handleSubmit = async () => {
    props.downloadReservedLockerReport()
  }

  return (
    <div className="export-reserved-lockers">
      <h3>דוח לוקרים שמורים </h3>
      <div className="reserved-lockers-form">
        <BlueButton onClick={handleSubmit} className="download-file-button">
          לייצא
        </BlueButton>
      </div>
    </div>
  )
}

const mapStateToProps = ({}) => {
  return {}
}

export default connect(mapStateToProps, {
  downloadReservedLockerReport,
})(ReservedLockerReport)
