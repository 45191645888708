import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"

import {
  SHOW_SUBSCRIPTIONS_LOADING,
  ON_GET_USER_SUBSCRIPTIONS_SUCCESS,
  ON_GET_USER_SUBSCRIPTIONS_FAIL,
  ON_CANCEL_USER_SUBSCRIPTION_SUCCESS,
  ON_CANCEL_USER_SUBSCRIPTION_FAIL,
  CLEAN_USER_SUBSCRIPTIONS,
} from "./types"

export const fetchUserSubscriptions = (userId) => {
  showSubscriptionsLoading()
  return (dispatch) => {
    axios
      .get(api.getUserSubscriptions(userId))
      .then((res) => {
        dispatch({
          type: ON_GET_USER_SUBSCRIPTIONS_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_USER_SUBSCRIPTIONS_FAIL,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const cancelUserSubscription = (userSubscriptionId) => {
  showSubscriptionsLoading()
  return (dispatch) => {
    axios
      .get(api.cancelUserSubscription(userSubscriptionId))
      .then((res) => {
        dispatch({
          type: ON_CANCEL_USER_SUBSCRIPTION_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        dispatch({
          type: ON_CANCEL_USER_SUBSCRIPTION_FAIL,
        })
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const cleanUserSubscriptions = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAN_USER_SUBSCRIPTIONS,
    })
  }
}

export const showSubscriptionsLoading = () => {
  return (dispatch) => {
    dispatch({
      type: SHOW_SUBSCRIPTIONS_LOADING,
    })
  }
}
