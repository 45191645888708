import {
    ON_GET_TEXT_MESSAGES_SUCCESS,
    ON_PICK_TEXT_MESSAGE,
} from '../actions/types';

const INIT_STATE = {
    textMessages: [],
    editMessage: '',
};

const EditorScreenReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ON_GET_TEXT_MESSAGES_SUCCESS:
            return {
                ...state,
                textMessages: action.payload,
            };
        case ON_PICK_TEXT_MESSAGE:
            return {
                ...state,
                editMessage: action.payload,
            }
        default:
            return state;
    }
}

export default EditorScreenReducer;