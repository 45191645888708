import React, { Component } from 'react';

import { SmallHeader, BlueButton } from '../../common';
import Modal from '../Modal';

import './style.css';

const OrderNotesModal = ({ show, title, notes, onOkClick }) => {

        if (!show) {
            return (
                <noscript />
            );
        }
        
        return (
            <Modal extraClass='noti-modal-wrap' >
                <div className='noti-modal-container'>
                    <SmallHeader
                        title={title}
                    />
                    <div className='noti-modal-content-wrapper notes'>
                    <div className="record-row title">
                        שים לב יש בהזמנה הערות
                    </div>
                    <div className="notes-wrapper">
                         {renderNotes(notes)}
                    </div>
                       
                    </div>
                    
                    <BlueButton
                        extraClass='noti-modal-button'
                        onClick={e => onOkClick()}
                    >
                        אישור
                    </BlueButton>
                </div>
            </Modal>
        );

}



const renderNotes = items => {
    return items.map((note,index) => {
        return (
            <div className="note-row">
                <div className="note-col-ind">{index+1}</div>
                <div className="note-col"> {note} </div>
            </div>
            
        );
    }); 
}


export default OrderNotesModal;