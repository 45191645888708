import React, { useState } from "react"

import "./style.css"

const SelectValPassive = ({
  extraClass,
  items,
  onItemSelected,
  valueIndex,
  show = true,
}) => {
  const [showItems, setShowItems] = useState(false)

  if (!show) {
    return <></>
  }

  const arrowDownSrc = showItems
    ? require("../../../assets/images/arrow-up.png")
    : require("../../../assets/images/arrow-down.png")

  return (
    <div className={`selvalpassive-wrapper ${extraClass ? extraClass : ""}`}>
      <div
        className="selvalpassive-displayed-itm"
        onClick={(e) => {
          setShowItems(!showItems)
        }}
      >
        <div className="selvalpassive-arrow-down">
          <img src={arrowDownSrc} className="selvalpassive-arrow-img" alt="" />
        </div>
        <div className="selvalpassive-itm">{items[valueIndex]}</div>
      </div>
      <div className="selvalpassive-itms-list-wrapper">
        <div className="selvalpassive-itms-list">
          {renderItems(
            showItems,
            items,
            valueIndex,
            onItemSelected,
            setShowItems
          )}
        </div>
      </div>
    </div>
  )
}

const renderItems = (
  show,
  items,
  selectedItemIndex,
  onItemClick,
  setShowItems
) => {
  if (!show) {
    return
  }

  return items.map((itm, index) => {
    const itemClassName = `selvalpassive-itm selvalpassive-itm-extra ${
      index === selectedItemIndex ? "selvalpassive-picker" : ""
    }`

    return (
      <div
        className={itemClassName}
        onClick={(e) => {
          onItemClick(itm, index)
          setShowItems(false)
        }}
        key={index}
      >
        {itm}
      </div>
    )
  })
}

export { SelectValPassive }
