import React, { Component } from "react"
import { getStatusNameFromObj } from "../../../../utils"
import "./style.scss"

const OrderHistoryList = (historyRecords, statuses, getFormatedDate) => {
  if (historyRecords.length <= 0) {
    return
  }
  const items = historyRecords.map((element, index) => {
    return {
      // orderStatus: statuses[element.orderStatus],

      orderStatus: getStatusNameFromObj(statuses, element.orderStatus),
      statusDate: getFormatedDate(element.statusDate),
      location: element.location,
      stationName: element.stationName,
      userFullname:
        (element.userFirstName == null ? "" : element.userFirstName) +
        " " +
        (element.userLastName == null ? "" : element.userLastName),
      note: element.note,
      orderTotalPrice: element.orderTotalPrice,
      isPayed: element.isPayed,
      stationChangeState: element.stationChangeState,
      sourceApp: element.sourceApp,
    }
  })
  return (
    <div className="record-table">
      <div className="record-row">
        <div className="record-col icon"></div>
        <div className="record-col status">סטטוס הזמנה</div>
        <div className="record-col status-date">תאריך סטטוס</div>
        <div className="record-col location">מיקום</div>
        <div className="record-col station-name">שם עמדה</div>
        <div className="record-col user-fullname">מבצע</div>
        <div className="record-col order-total-price">מחיר</div>
        <div className="record-col is-payed">שולם</div>
        <div className="record-col station-change-state">שינוי עמדה</div>
        <div className="record-col note">הערה</div>
        <div className="record-col source-app">מקור</div>
      </div>
      {renderItems(items)}
    </div>
  )
}

const renderItems = (items) => {
  return items.map((element, index) => {
    const price =
      element.orderTotalPrice !== 0 ? element.orderTotalPrice + "₪" : ""
    const stationChangeStateStyle = element.stationChangeState
      ? " fa fa-check-circle"
      : ""
    const isPayedStyle = element.isPayed ? " fa fa-check-circle" : ""
    console.log("isPayed:" + element.isPayed)
    return (
      <div className="record-row" key={index}>
        <div className="record-col icon">{index + 1}</div>
        <div className="record-col status">{element.orderStatus}</div>
        <div className="record-col status-date">{element.statusDate}</div>
        <div className="record-col location">{element.location}</div>
        <div className="record-col station-name">{element.stationName}</div>
        <div className="record-col user-fullname">{element.userFullname}</div>
        <div className="record-col order-total-price">{price}</div>
        <div className={"record-col is-payed" + isPayedStyle}></div>
        <div
          className={
            "record-col station-change-state" + stationChangeStateStyle
          }
        ></div>
        <div className="record-col note">{element.note}</div>
        <div className="record-col source-app">{element.sourceApp}</div>
      </div>
    )
  })
}

export default OrderHistoryList
