import React from "react"
import oneProjectLogo from "../assets/images/oneProject_logo.png"

let oneProject = {}

oneProject.orderType = 21
oneProject.accountType = 19
oneProject.serviceName = "oneProject"
oneProject.newBagTemp = () => {
  return { orderType: 21, orderStatus: 5 }
}

oneProject.renderLogo = () => {
  return (
    <img
      className="logo-service-icon oneProject"
      src={oneProjectLogo}
      alt={"logo oneProject"}
    />
  )
}

export const oneProjectProvider = oneProject
