import React from "react"

import Header from "../../../../common/List/Header"
import StationItem from "./StationItem"
import { H1, Spinner } from "../../../../common"

import "./style.css"

//const routeHeaders= ['מזהה עמדה','שם עמדה', 'כתובת'];
const routeItemMap = [
  "stationNumber",
  "branchDisplayName",
  "branchDisplayAddress",
  "editStation",
]
const routeGravity = { 0: 0.2, 3: 0.2 }

const routeHeaders = ["מזהה", "עמדות מחוברות למסלול", "כתובת", "עריכת עמדה"]
//const routeGravityHeaders= {"0":0.2};

const RouteStationsList = ({
  extraClass,
  items,
  onEditStationBtnClick,
  isError,
  isLoading,
}) => {
  return (
    <div className={`route-stations-list list-wrapper ${extraClass}`}>
      <Header titles={routeHeaders} gravity={routeGravity} />
      <div className="list-items-wrapper">
        {renderItems(
          items,
          routeGravity,
          routeItemMap,
          onEditStationBtnClick,
          isError,
          isLoading
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  routeGravity,
  routeItemMap,
  onEditStationBtnClick,
  isError,
  isLoading
) => {
  if (isError) {
    return (
      <H1 className="not-valid-search" title="אנא בדוק את הערך של שדה חיפוש" />
    )
  }
  if (isLoading) {
    return (
      <div className="station-editor-spinner-wrapper">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (!items || items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }

  return items.map((itm, index) => {
    return (
      <StationItem
        key={index}
        item={itm}
        itemMap={routeItemMap}
        gravity={routeGravity}
        isLast={index === items.length - 1}
        onEditStationBtnClick={onEditStationBtnClick}
      />
    )
  })
}

export default RouteStationsList
