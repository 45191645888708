import React from "react"

import "./style.css"
import { Title } from "../../../../../common"

const OrderSummary = ({ order }) => {
  if (!order) {
    return <div>LOADING..</div>
  }

  const { orderItems } = order

  // console.log('asdasd', orderItems);

  const ironingAmount = getTotalQuantities(
    orderItems.filter(
      ({ processName }) => processName === "ironing" || processName === "גיהוץ"
    )
  )

  const dryCleaningAmount = getTotalQuantities(
    orderItems.filter(
      ({ processName }) =>
        processName === "dry cleaning" || processName === "ניקוי יבש"
    )
  )

  const laundryAmount = getTotalQuantities(
    orderItems.filter(
      ({ processName }) => processName === "laundry" || processName === "כביסה"
    )
  )

  const laundryIroningAmount = getTotalQuantities(
    orderItems.filter(
      ({ processName }) =>
        processName === "laundry ironing" || processName === "כביסה וגיהוץ"
    )
  )

  const laundryIroningPackageAmount = getTotalQuantities(
    orderItems.filter(({ processName }) =>
      processName
        ? processName === "laundry ironing package" ||
          processName.includes("כביסה וגיהוץ ואריזה")
        : []
    )
  )

  const totalAmountOfItems = getTotalQuantities(
    orderItems.filter(({ processName }) =>
      processName ? processName !== "Compensation" : []
    )
  )

  return (
    <div className="orsum-wrapper">
      <div className="orsum-title-value-wrapper">
        <Title>סה"כ גיהוצים:</Title>
        <Title bold extraClass="cus-det-frame-details-value">
          {ironingAmount}
        </Title>
      </div>
      <div className="orsum-title-value-wrapper">
        <Title>סה"כ כביסה:</Title>
        <Title bold extraClass="cus-det-frame-details-value">
          {laundryAmount}
        </Title>
      </div>
      <div className="orsum-title-value-wrapper">
        <Title>סה"כ כביסה + גיהוצים:</Title>
        <Title bold extraClass="cus-det-frame-details-value">
          {laundryIroningAmount}
        </Title>
      </div>
      <div className="orsum-title-value-wrapper">
        <Title>סה"כ ניקוי יבש:</Title>
        <Title bold extraClass="cus-det-frame-details-value">
          {dryCleaningAmount}
        </Title>
      </div>
      {/* <div className="orsum-title-value-wrapper">
                <Title>
                    סה"כ שונות:
                </Title>
                <Title bold extraClass='cus-det-frame-details-value'>
                    7%
                </Title>
            </div> */}
      <div className="orsum-title-value-wrapper">
        <Title extraClass="orsum-blue orsum-blue2">סה"כ פריטים</Title>
        <Title bold extraClass="cus-det-frame-details-value orsum-blue">
          {totalAmountOfItems}
        </Title>
      </div>
    </div>
  )
}

const getTotalQuantities = (arr) => {
  return arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue.quantity || 0,
    0
  )
}

export default OrderSummary
