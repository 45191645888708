import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getTextMessages } from '../../../actions';

import Editor from './Editor';
import TextMessagesList from './TextMessagesList';

class EditorScreen extends Component {
    componentDidMount() {
        this.props.getTextMessages();
    }

    render() {
        return (
            <>
                <TextMessagesList />
                <Editor />
            </>
        );
    }
}

export default connect(null, { getTextMessages })(EditorScreen);