import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchAllStationData,
  updateFiltererActiveTypes,
  updateFiltererActiveStatuses,
  updateFiltererActiveStations,
  updateFiltererIsPaidStatus,
  fetchOrderTypes,
  fetchAllOrderStatuses,
} from "../../../actions";

import { fetchIfEmpty } from "../../../utils";
import { setOrderStatusesArr } from "../../../utils/EnumsAndConsts";

import { MultiSelectValPassive, SelectValPassive } from "../../common";

import "./style.css";

class Filterer extends Component {
  componentDidMount() {
    fetchIfEmpty(this.props.allStationsArr, this.props.fetchAllStationData);
    fetchIfEmpty(this.props.orderTypes, this.props.fetchOrderTypes);
    fetchIfEmpty(this.props.statusesArr, this.props.fetchAllOrderStatuses);
  }

  updateActiveTypes(item, index) {
    this.props.updateFiltererActiveTypes(index);
  }

  updateActiveStauses(item, index) {
    this.props.updateFiltererActiveStatuses(index);
  }

  updateActiveStations(item, index) {
    const { allStationsArr } = this.props;

    this.props.updateFiltererActiveStations(
      allStationsArr[index].stationNumber
    );
  }

  updateIsPaidFilterStatus(item, index) {
    this.props.updateFiltererIsPaidStatus(index);
  }

  getUnsortedStatuses() {
    const { orderStatusesObj } = this.props;
    let unsortedArr = [];
    const closedOrderStatuses = [5, 6, 12];
    for (const [key, itm] of Object.entries(orderStatusesObj)) {
      if (closedOrderStatuses.includes(itm.value)) {
        continue;
      }
      unsortedArr[itm.value] = itm.label;
    }
    return unsortedArr;
  }

  renderIsPaidFilter(show) {
    if (!show) {
      return;
    }

    const { mulSelectsExtraClass, FiltererIsPaid } = this.props;

    return (
      <SelectValPassive
        items={[
          "הצג את כל ההזמנות",
          "הצג את ההזמנות ששולמו בלבד",
          "הצג את ההזמנות שלא שולמו בלבד",
        ]}
        extraClass={mulSelectsExtraClass}
        valueIndex={FiltererIsPaid}
        onItemSelected={(itm, index) =>
          this.updateIsPaidFilterStatus(itm, index)
        }
      />
    );
  }

  render() {
    const {
      statusesArr,
      orderStatusesObj,
      FiltererActiveTypes,
      FiltererActiveStatuses,
      FiltererActiveStationsIndexes,
      formattedStationsNames,
      extraClass,
      mulSelectsExtraClass,
      showIsPaidFilter,
      orderTypes,
    } = this.props;

    return (
      <div className={extraClass}>
        <MultiSelectValPassive
          title="סוג הזמנה"
          items={orderTypes}
          extraClass={mulSelectsExtraClass}
          valueIndexesArr={FiltererActiveTypes}
          onItemCheckChange={(itm, index) => this.updateActiveTypes(itm, index)}
        />

        <MultiSelectValPassive
          title="סטטוס הזמנה"
          items={
            orderStatusesObj && orderStatusesObj.length > 0
              ? this.getUnsortedStatuses()
              : null
          }
          extraClass={mulSelectsExtraClass}
          valueIndexesArr={FiltererActiveStatuses}
          onItemCheckChange={(itm, index) =>
            this.updateActiveStauses(itm, index)
          }
        />

        <MultiSelectValPassive
          title="עמדה"
          items={formattedStationsNames}
          extraClass={mulSelectsExtraClass}
          valueIndexesArr={FiltererActiveStationsIndexes}
          onItemCheckChange={(itm, index) =>
            this.updateActiveStations(itm, index)
          }
        />

        {this.renderIsPaidFilter(showIsPaidFilter)}
      </div>
    );
  }
}

const mapStateToProps = ({
  ordersStatuses,
  ordersList,
  stationsList,
  selectOptions,
}) => {
  const { openOrdersStatusesArr, orderStatusesObj } = ordersStatuses;
  const {
    FiltererActiveTypes,
    FiltererActiveStatuses,
    FiltererActiveStations,
    FiltererIsPaid,
  } = ordersList;
  const { allStationsArr, formattedStationsNames } = stationsList;
  const { orderTypes } = selectOptions;

  let FiltererActiveStationsIndexes;

  if (allStationsArr) {
    FiltererActiveStationsIndexes = allStationsArr
      .filter(({ stationNumber }) =>
        FiltererActiveStations.includes(stationNumber)
      )
      .map((station) => allStationsArr.indexOf(station));
  }

  return {
    statusesArr: openOrdersStatusesArr,
    orderStatusesObj,
    FiltererActiveTypes,
    FiltererActiveStatuses,
    FiltererActiveStationsIndexes,
    FiltererIsPaid,
    allStationsArr,
    formattedStationsNames,
    orderTypes,
  };
};

export default connect(mapStateToProps, {
  fetchAllStationData,
  updateFiltererActiveTypes,
  updateFiltererActiveStatuses,
  updateFiltererActiveStations,
  updateFiltererIsPaidStatus,
  fetchOrderTypes,
  fetchAllOrderStatuses,
  setOrderStatusesArr,
})(Filterer);
