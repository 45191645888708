import { UNAUTHORIZED, RESET_ERROR } from "../actions/types"

const errorManager = {}

errorManager.getStatusCode = (obj) => {
  if (obj && obj.response && obj.response.status) {
    console.log("StatusCode:" + obj.response.status)
    return obj.response.status
  }
  return "400"
}

errorManager.throwDispatch = (dispatch, obj) => {
  let status = errorManager.getStatusCode(obj)

  switch (status) {
    case 401:
      dispatch({
        type: UNAUTHORIZED,
        payload: obj,
      })
    default:
      return
  }
}

errorManager.throwDispatchErrorReset = (dispatch, obj) => {
  dispatch({
    type: RESET_ERROR,
    payload: obj,
  })
}

export default errorManager
