import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  SmallHeader,
  LabeledTextarea,
  BlueButton,
  CheckboxAndLabel,
} from "../../common"
import Modal from "../Modal"
import NotificationModal from "../NotificationModal"
import {
  setOrderTracking,
  clearOrderTracking,
  getOrderDetails,
} from "../../../actions"

import "./style.scss"

const OrderTrackingModal = (props) => {
  const { order } = props || {}
  const [note, setNote] = useState("")
  const [error, setError] = useState("")
  const [trackingNote, setTrackingNote] = useState(order.trackingNote)
  const [showSucessNotification, setShowSucessNotification] = useState("")
  const [showFailNotification, setShowFailNotification] = useState("")
  const [isTracking, setIsTracking] = useState(order.isTracking)

  useEffect(() => {
    if (props.setOrderTrackingSuccess) {
      setShowSucessNotification(true)
    }
  }, [props.setOrderTrackingSuccess])

  useEffect(() => {
    if (props.setOrderTrackingFail) {
      setShowFailNotification(true)
    }
  }, [props.setOrderTrackingFail])

  if (!props.isShow) {
    return <noscript />
  }

  const onSubmit = () => {
    if (!trackingNote) {
      setError("יש לכתוב הערה")
      return
    }
    setError("")
    props.setOrderTracking(order?.id, isTracking, trackingNote)
  }

  const onTextChengaed = (text) => {
    if (text) {
      setIsTracking(true)
    } else {
      setIsTracking(false)
    }
    setTrackingNote(text)
  }

  const onCloseModal = () => {
    props.clearOrderTracking()
    setShowSucessNotification(false)
    setShowFailNotification(false)
    setError("")
    setNote("")
    props.getOrderDetails(order.id)
    props.close()
  }

  return (
    <div>
      <Modal extraClass="cror-modal-wrap">
        <div className="cror-modal-container">
          <div className="order-tracking-modal">
            <SmallHeader title="מעקב אחר הזמנה" />
            <div className="modal-body">
              <LabeledTextarea
                value={trackingNote}
                onChange={(e) => onTextChengaed(e.target.value)}
              />
              <CheckboxAndLabel
                checked={isTracking}
                onChange={(e) => setIsTracking(!isTracking)}
              >
                הזמנה במעקב
              </CheckboxAndLabel>
              {error && <div className="error-message">{error}</div>}
              <div className="cror-modal-status">
                <BlueButton extraClass="cror-modal-button" onClick={onSubmit}>
                  אישור
                </BlueButton>
                <BlueButton
                  extraClass="cror-modal-button"
                  onClick={() => onCloseModal()}
                >
                  ביטול
                </BlueButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <NotificationModal
        show={showSucessNotification}
        title={"מעקב אחר הזמנה"}
        text={"נתונים עודכנו בהצלחה"}
        onOkClick={() => onCloseModal()}
      />
      <NotificationModal
        show={showFailNotification}
        type="error"
        title={"מעקב אחר הזמנה"}
        text={"נתונים לא עודכנו"}
        onOkClick={() => onCloseModal()}
      />
    </div>
  )
}

const mapStateToProp = ({ ordersList }) => {
  const { setOrderTrackingSuccess, setOrderTrackingFail } = ordersList
  return { setOrderTrackingSuccess, setOrderTrackingFail }
}

export default connect(mapStateToProp, {
  setOrderTracking,
  clearOrderTracking,
  getOrderDetails,
})(OrderTrackingModal)
