import React from "react"

import { getFormatedDateOnly } from "../../../../../utils/FormatedDate"
import { supplierTypesByValue } from "../../../../../utils/EnumsAndConsts"
import { BlueButton } from "../../../../common"
import "./style.css"

const SupplierItem = ({ item, itemMap, gravity, isLast, onEditBtnClick }) => {
  const itemClassName = `ol-item-wrapper ${isLast ? "ol-item-last" : ""}`

  return (
    <div className={itemClassName}>
      {renderItemContent(item, itemMap, gravity)}
      <div>
        <BlueButton
          extraClass="supplier-edit-btn"
          onClick={(e) => onEditBtnClick(item)}
        >
          עריכה
        </BlueButton>
      </div>
    </div>
  )
}

const renderItemContent = (item, itemMap, gravity) => {
  return itemMap.map((itmMap, index) => {
    const flex = gravity[`${index}`] || 1

    let itemContent = item[itmMap]

    if (itmMap === "createdDate") {
      itemContent = getFormatedDateOnly(item[itmMap])
    }

    if (itmMap === "type") {
      itemContent = supplierTypesByValue(item[itmMap])
    }
    if (itmMap === "color") {
      if (item.color) {
        return (
          <div style={{ flex }} key={index} className="color">
            <div
              className="color-wrap"
              style={{ background: item.color }}
            ></div>
          </div>
        )
      } else {
        return <div style={{ flex }} key={index} className="color"></div>
      }
    }
    return (
      <div style={{ flex }} key={index}>
        {itemContent}
      </div>
    )
  })
}

export default SupplierItem
