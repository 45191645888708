import React, { Component } from 'react';
import { connect } from 'react-redux';

import { LabeledSelect } from '../../../../common';

import { getPriceList, selectPriceList } from '../../../../../actions';

import './style.css';

const PriceListSelect_PiceListSelectLabelText = 'Select a price list';

class PriceListSelect extends Component {
    constructor(props) {
        super(props);
        this.onInputChange = this.onInputChange.bind(this);
    }

    onInputChange(priceListId) {
        this.props.selectPriceList(priceListId);
        this.props.getPriceList(priceListId);
    }

    render() {
        const { priceLists, priceList } = this.props;
        return (
            <>
                <LabeledSelect
                    id="price-list-select"
                    labelText={PriceListSelect_PiceListSelectLabelText}
                    options={priceLists}
                    value={priceList}
                    onChange={(e) => { this.onInputChange(e.target.value) }}
                />
            </>
        );
    }
}

const mapStateToProps = ({ selectOptions, priceListsSection }) => {
    const { priceLists } = selectOptions;
    const { priceList } = priceListsSection;
    return { priceLists, priceList };
}

export default connect(mapStateToProps, { getPriceList, selectPriceList })(PriceListSelect);