import {
  ON_GET_ITEMS_SUCCESS,
  ON_GET_PROCESSES_SUCCESS,
  ON_GET_SERVICES_SUCCESS,
  SELECT_PRICE_LIST,
  ON_GET_PRICE_LIST_SUCCESS,
  ON_ITEM_CREATE_SUCCESS,
  ON_ITEM_DELETE_SUCCESS,
  CLEAR_ITEM_STATUS,
  ON_GET_SUPPLIER_PRICES_SUCCESS,
  ON_GET_SUPPLIER_PRICES_FAILURE,
  ON_SUPPLIER_PRICE_UPDATE_SUCCESS,
  ON_SUPPLIER_PRICE_UPDATE_FAILURE,
  CLEAR_SUPPLIER_PRICE,
} from "../actions/types"

const INIT_STATE = {
  products: [],
  product: {},
  services: [],
  service: {},
  processes: [],
  process: {},
  priceList: "",
  laundryPriceList: [],
  productsPriceList: [],
  isNewItemCreated: false,
  isItemDeleted: false,
  supplierPricesList: [],
  isSupplierPriceUpdated: false,
  isSupplierPriceError: false,
}

const PriceListsSectionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_GET_ITEMS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      }
    case ON_GET_PROCESSES_SUCCESS:
      // console.log(action.payload);
      return {
        ...state,
        processes: action.payload,
      }
    case ON_GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
      }
    case ON_GET_PRICE_LIST_SUCCESS:
      return {
        ...state,
        priceList: action.payload.priceListId,
        laundryPriceList: action.payload.laundryPriceList || [],
        productsPriceList: action.payload.productsPriceList || [],
      }
    case SELECT_PRICE_LIST:
      return {
        ...state,
        priceList: action.payload,
      }
    case ON_ITEM_CREATE_SUCCESS:
      return {
        ...state,
        isNewItemCreated: true,
      }
    case ON_ITEM_DELETE_SUCCESS:
      return {
        ...state,
        isItemDeleted: true,
      }
    case CLEAR_ITEM_STATUS:
      return {
        ...state,
        isNewItemCreated: false,
      }
    case ON_GET_SUPPLIER_PRICES_SUCCESS:
      return {
        ...state,
        supplierPricesList: action.payload,
      }
    case ON_GET_SUPPLIER_PRICES_FAILURE:
      return {
        ...state,
        supplierPricesList: null,
      }
    case ON_SUPPLIER_PRICE_UPDATE_SUCCESS:
      return {
        ...state,
        isSupplierPriceUpdated: true,
        isSupplierPriceError: false,
      }
    case ON_SUPPLIER_PRICE_UPDATE_FAILURE:
      return {
        ...state,
        isSupplierPriceUpdated: false,
        isSupplierPriceError: true,
      }
    case CLEAR_SUPPLIER_PRICE:
      return {
        ...state,
        isSupplierPriceUpdated: false,
        isSupplierPriceError: false,
      }

    default:
      return state
  }
}

export default PriceListsSectionReducer
