import {
  SET_LOCKERS_HISTORY_STATION,
  ON_LOADING_LOCKERS_HISTORY,
  GET_SEARCHED_LOCKERS_HISTORY_SUCCESS,
  GET_SEARCHED_LOCKERS_HISTORY_FAIL,
  SET_LOCKERS_HISTORY_SEARCH,
  ON_USER_SIGN_IN_RESET_ATTEMPTS,
  UNAUTHORIZED,
  RESET_ERROR,
  GET_SEARCHED_LOCKERS_BY_STATION_SUCCESS,
  GET_SEARCHED_LOCKERS_BY_STATION_FAIL,
} from "./../actions/types"

const INIT_SEARCH = {
  searchText: "",
  stationId: "",
  lockers: [],
  lockerStatuses: [],
  lockerTypes: [],
  fromDate: "",
  toDate: "",
  pageStep: 50,
  page: 1,
}

const INIT_STATE = {
  lockersHistoryList: [],
  isLoading: false,
  isError: false,
  currentLockersStationId: "",
  currentStationLockers: [],
  search: INIT_SEARCH,
  searchedLockerHistoryList: [],
  currentSearchedPage: 1,
  currentSearchedPageStep: 50,
}

const LockersHistoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_LOADING_LOCKERS_HISTORY:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case SET_LOCKERS_HISTORY_STATION:
      return {
        ...state,
        currentLockersStationId: action.payload,
        lockersHistoryList: [],
        isLoading: false,
      }

    case GET_SEARCHED_LOCKERS_HISTORY_SUCCESS:
      return {
        ...state,
        searchedLockerHistoryList: action.payload.lockersHistory,
        currentSearchedPage: action.payload.page,
        currentSearchedPageStep: action.payload.pageStep,
        isLoading: false,
      }
    case GET_SEARCHED_LOCKERS_HISTORY_FAIL:
      return {
        ...state,
        searchedLockerHistoryList: [],
        isLoading: false,
      }
    case SET_LOCKERS_HISTORY_SEARCH:
      return {
        ...state,
        search: action.payload,
        isLoading: false,
      }

    case GET_SEARCHED_LOCKERS_BY_STATION_SUCCESS:
      return {
        ...state,
        currentStationLockers: action.payload,
        searchedLockerHistoryList: [],
        isLoading: false,
      }
    case GET_SEARCHED_LOCKERS_BY_STATION_FAIL:
      return {
        ...state,
        currentStationLockers: [],
        searchedLockerHistoryList: [],
        isLoading: false,
        isError: true,
      }
    case RESET_ERROR:
      return {
        ...state,
        isError: false,
      }
    case ON_USER_SIGN_IN_RESET_ATTEMPTS:
    case UNAUTHORIZED:
      return {
        lockersHistoryList: [],
        isLoading: false,
        isError: false,
        currentLockersStationId: "",
        currentStationLockers: [],
        searchedLockerHistoryList: [],
        currentSearchedPage: 1,
        currentSearchedPageStep: 50,
        search: {
          searchText: "",
          stationId: "",
          lockers: [],
          lockerStatuses: [],
          lockerTypes: [],
          fromDate: "",
          toDate: "",
          pageStep: 50,
          page: 1,
        },
      }
    default:
      return state
  }
}

export default LockersHistoryReducer
