import {
  ON_SET_CURRENT_USERS_ROUTE,
  ON_ROUTE_USER_LOADING,
  ON_GET_ROUTE_USERS_SUCCESS,
  ON_GET_ROUTE_USERS_FAIL,
  ON_CREATE_ROUTE_USER_SUCCESS,
  ON_CREATE_ROUTE_USER_FAIL,
  ON_GET_EXTERNAL_DRIVERS_SUCCESS,
  ON_GET_EXTERNAL_DRIVERS_FAIL,
  ON_REMOVE_ROUTE_USER_SUCCESS,
  ON_REMOVE_ROUTE_USER_FAIL,
  ON_CLOSE_ROUTE_USER_NOTIFICATION,
} from "../actions/types"

const INIT_STATE = {
  drivers: [],
  routeDrivers: [],
  routeUserToAdd: null,
  currentRoute: null,
  isLoading: false,
  isError: false,

  showDeleteSuccess: false,
  showDeleteFail: false,
  showDeleteConflict: false,
  showCreateSuccess: false,
  showCreateFail: false,
  showCreateConflict: false,
  showUpdateSuccess: false,
  showUpdateFail: false,
  showUpdateConflict: false,
}

const RouteUserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_ROUTE_USER_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case ON_GET_ROUTE_USERS_SUCCESS:
      return {
        ...state,
        routeDrivers: action.payload,
        isLoading: false,
      }
    case ON_GET_ROUTE_USERS_FAIL:
      console.log(action.payload)
      return {
        ...state,
        error: action.payload,
        routeDrivers: [],
        isLoading: false,
      }
    case ON_SET_CURRENT_USERS_ROUTE:
      return {
        ...state,
        currentRoute: action.payload,
        isError: false,
      }

    case ON_CREATE_ROUTE_USER_SUCCESS:
      return {
        ...state,
        showCreateSuccess: action.payload,
        isLoading: false,
      }
    case ON_CREATE_ROUTE_USER_FAIL:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status === 409
      ) {
        return {
          ...state,
          currentRoute: null,
          isLoading: false,
          showCreateConflict: true,
          isError: action.payload,
        }
      }
      return {
        ...state,
        isError: action.payload,
        showCreateFail: true,
        isLoading: false,
        reloadRoutes: true,
      }

    case ON_GET_EXTERNAL_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: action.payload,
        isLoading: false,
      }
    case ON_GET_EXTERNAL_DRIVERS_FAIL:
      return {
        ...state,
        isError: action.payload,
        drivers: [],
        showNotification_Faiture: true,
        isLoading: false,
      }

    case ON_REMOVE_ROUTE_USER_SUCCESS:
      return {
        ...state,
        currentRoute: null,
        isLoading: false,
        showDeleteSuccess: true,
        reloadRoutes: true,
      }
    case ON_REMOVE_ROUTE_USER_FAIL:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.status === 409
      ) {
        return {
          ...state,
          currentRoute: null,
          isLoading: false,
          showDeleteConflict: true,
          isError: action.payload,
          reloadRoutes: true,
        }
      }
      return {
        ...state,
        currentRoute: null,
        isLoading: false,
        showDeleteFail: true,
        isError: action.payload,
        reloadRoutes: true,
      }

    case ON_CLOSE_ROUTE_USER_NOTIFICATION:
      return {
        ...state,
        showDeleteSuccess: false,
        showDeleteFail: false,
        showDeleteConflict: false,
        showCreateSuccess: false,
        showCreateFail: false,
        showCreateConflict: false,
        showUpdateSuccess: false,
        showUpdateFail: false,
        showUpdateConflict: false,
      }
    default:
      return state
  }
}

export default RouteUserReducer
