export const cities_RecalcFilteredListOutOfLatestState = (state) => {
  const { citiesListTable, citiesLocalSearch } = state

  const { searchText, filtererActiveCities } = citiesLocalSearch

  const selectedCityName = filtererActiveCities.map(({ value }) => value)
  let citiesFilteredListArr = citiesListTable

  if (searchText && searchText.length > 0) {
    citiesFilteredListArr = cities_FilterBySearchText(
      citiesFilteredListArr,
      searchText
    )
  }

  return {
    ...state,
    citiesLocalSearch: {
      ...citiesLocalSearch,
      citiesFilteredListArr,
    },
  }
}

export const cities_FilterBySearchText = (list, searchText) => {
  const citiesFilteredListArr = list.filter((city) => {
    const { cityName, cityName_en } = city

    return (
      searchText === "" ||
      cityName.toString().includes(searchText) ||
      (cityName_en && cityName_en.toString().includes(searchText))
    )
  })

  return citiesFilteredListArr
}
