import { isProdEnv } from "../utils"

const api = {}

let BASE_URL

if (isProdEnv()) {
  BASE_URL = "https://bo-api.cleanbox.co.il" //aws
  //BASE_URL = "https://localhost:5001" //aws
} else {
  BASE_URL = "https://bo-api-test.cleanbox.co.il" //test
  //BASE_URL = "https://localhost:5001" //local
}

api.signIn = `${BASE_URL}/api/Users/authenticate`
api.signInBody = (mobile, pwd) => {
  return {
    mobilePhone: mobile,
    password: pwd,
  }
}

api.getAllOrders = `${BASE_URL}/api/orders`

api.getOrdersBySupplier = (supplierId, orderStatus) =>
  `${BASE_URL}/api/orders/get_orders_by_supplier/${supplierId}/${orderStatus}`

api.createNewOrderUrl = `${BASE_URL}/api/office/create_order`
api.createNewOrderBody = ({
  mobile,
  status,
  totalPrice,
  location,
  orderItems,
  stationNumber,
}) => {
  return {
    mobilePhone: mobile,
    orderStatus: status,
    orderTotalPrice: totalPrice,
    currentLocation: location,
    orderItems,
    stationNumber,
  }
}

api.UpdateOrderAfterReceivingLaundry = () =>
  `${BASE_URL}/api/orders/update_order_after_receiving_laundry`

api.updateOrder = (orderId, sendNotification) =>
  `${BASE_URL}/api/office/edit_order/${orderId}${
    sendNotification ? "?sendNotification=true" : ""
  }`

api.updateOrderBagsUrl = (orderId, bagsQuantity) =>
  `${BASE_URL}/api/orders/UpdateOrderBags/${orderId}/${bagsQuantity}`

api.recalcOrderNoSave = (orderId) =>
  `${BASE_URL}/api/office/recalc_order_no_save/${orderId}`

api.removeOrderCoupon = (orderId) =>
  `${BASE_URL}/api/office/remove_order_coupon_no_save/${orderId}`
api.addOrderCoupon = (couponId) =>
  `${BASE_URL}/api/office/add_order_coupon_no_save/${couponId}`

//api.addOrderCoupon = (orderId, couponId) =>  `${BASE_URL}/api/office/add_order_coupon/${orderId}?couponId=${couponId}`

api.getOrdersByRoute = (routeId) =>
  `${BASE_URL}/api/orders/get_order_by_route/${routeId}`

api.createNewUserUrl = `${BASE_URL}/api/users/create_user`
api.createNewUserBody = ({
  firstName,
  lastName,
  email,
  mobilePhone,
  companyName,
  companyNumber,
  language,
  address,
  comment,
  role,
  temporaryPassword,
  accountName,
  stationId,
  customerDiscount,
  discountExpirationDate,
}) => {
  return {
    firstName,
    lastName,
    email,
    mobilePhone,
    comment,
    companyName,
    companyNumber,
    role,
    temporaryPassword,
    accountName,
    stationId,
    country: address,
    language,
    customerDiscount,
    discountExpirationDate,
  }
}

api.updateUser = (userId) => `${BASE_URL}/api/users/update_user/${userId}`

// api.getLanguagesUrl = `${BASE_URL}/api/api/languages/get_languages`;

api.getAllUsers = `${BASE_URL}/api/users/get_users`
api.getUsersByName = `${BASE_URL}/api/Users/get_users_by_name`

api.getAllUsersResponse = (response) => {
  const outputUsersArr = response.map(
    ({
      id: userId,
      firstName,
      lastName,
      email,
      mobilePhone,
      stationNumber,
      branchName,
      companyName,
      role,
      customerDiscount,
    }) => {
      return {
        userId,
        firstName,
        lastName,
        email,
        mobilePhone,
        stationNumber,
        branchName,
        companyName,
        role,
        customerDiscount,
      }
    }
  )

  return outputUsersArr
}

api.getUserByMobile = (mobile) => `${BASE_URL}/api/users/${mobile}`
api.getUserById = (userId) => `${BASE_URL}/api/users/get_user_by_id/${userId}`
api.updateSelfPassword = `${BASE_URL}/api/users/update_self_password`

api.getOrderDetails = (orderId) => `${BASE_URL}/api/orders/get_order/${orderId}`

api.getOrderItemsOriginal = (orderId) =>
  `${BASE_URL}/api/orders/get_order_items_original/${orderId}`

api.getOrderByNumberWithRoute = (orderNumber) =>
  `${BASE_URL}/api/orders/get_order_by_number_with_routs/${orderNumber}`
//api.getOrderStatuses = `${BASE_URL}/api/orders/get_statuses`;
api.getOrderStatuses = `${BASE_URL}/api/orders/get_status_types`

// Select options
api.getAccountTypes = `${BASE_URL}/api/orders/get_account_types`
api.getOrderTypes = `${BASE_URL}/api/orders/get_types`
api.getDoneOrderTypes = `${BASE_URL}/api/orders/get_done_types`
api.getStationSelectOptions = `${BASE_URL}/api/office/get_stations_map`
api.getPriceListSelectOptions = `${BASE_URL}/api/office/get_prices_map`
//api.getLockerStatusSelectOptions = `${BASE_URL}/api/stations/get_lockers_statuses`;
api.getLockerStatusSelectOptions = `${BASE_URL}/api/locker/get_lockers_statuses`
api.getLockersSelectOptions = `${BASE_URL}/api/office/get_lockers_map`
api.getLockerTypeOtions = `${BASE_URL}/api/office/get_lockertypes_map`
api.getCountries = `${BASE_URL}/api/address/get_countries`
api.getLanguages = `${BASE_URL}/api/languages/get_languages`
api.getUserRoles = `${BASE_URL}/api/users/roles`
api.getUserSources = `${BASE_URL}/api/users/get_app_sources`
api.getAllStationsData = `${BASE_URL}/api/branches/get_branches_map`
api.getLockerSizes = `${BASE_URL}/api/locker/get_lockers_sizes`
api.updateLockerSize = (lockerId, lockerSizeId) =>
  `${BASE_URL}/api/locker/update_locker_size/${lockerId}/${lockerSizeId}`

// Stations
//api.getStations = `${BASE_URL}/api/stations/get_stations`;
api.getLightStations = `${BASE_URL}/api/stations/get_stations_light`
api.getStationById = (stationId) =>
  `${BASE_URL}/api/stations/get_station_by_id/${stationId}`
api.getPostalCode = (latitude, longitude) =>
  `${BASE_URL}/api/stations/getPostalCode/${latitude}/${longitude}`
api.isAreaCodeCanBeLinkedToStation = (routeAreaCodeId) =>
  `${BASE_URL}/api/stations/isAreaCodeCanBeLinkedToStation/${routeAreaCodeId}`
api.createStation = `${BASE_URL}/api/stations/create_station`
api.updateStation = `${BASE_URL}/api/stations/update_station`
api.readLockersLayout = `${BASE_URL}/api/stations/read_lockers_layout`
api.readLockersObject = (response) => {
  const outputLockers = response.map(
    ({ name: lockerName, lockerType, lockerStatus, lockerSize }) => {
      return {
        lockerName,
        lockerType,
        lockerStatus,
        lockerSize,
      }
    }
  )
  return outputLockers
}

// Lockers management
api.getLockersList = `${BASE_URL}/api/stations/lockers_status`
api.relocateOrder = `${BASE_URL}/api/orders/relocate_order`

api.updateLockerStatus = (lockerId) =>
  `${BASE_URL}/api/locker/update_locker_status/${lockerId}`
//api.clearLocker = (lockerId, orderId) =>  `${BASE_URL}/api/stations/clear_locker/${lockerId}?&orderId=${orderId}`;
api.clearLocker = (lockerId) =>
  `${BASE_URL}/api/locker/clear_locker/${lockerId}`
api.lockersHistory = (stationId) =>
  `${BASE_URL}/api/locker/get_lockers_history/${stationId}`
api.searchLockersInfraSensor = `${BASE_URL}/api/locker/search_lockers_infra_sensor`

// Export tables
api.exportOrdersItems = `${BASE_URL}/api/reports/get_orders_items_table`
api.exportOrders = `${BASE_URL}/api/reports/get_orders_table`
api.exportUsers = `${BASE_URL}/api/reports/get_users_table`

// Export reports
api.getDetailedLockersReport = `${BASE_URL}/api/reports/get_detailed_lockers_report`
api.getStationsStatusReport = `${BASE_URL}/api/reports/get_stations_status_report`
api.getOrderItemReport = `${BASE_URL}/api/reports/get_order_item_report`
api.getCustomerOrdersReport = `${BASE_URL}/api/reports/get_customer_orders_report`
api.getOutdatedOrdersReport = `${BASE_URL}/api/reports/get_outdated_orders_report`
api.getBalanceReport = `${BASE_URL}/api/reports/get_balance_report`
api.getDriverRouteReport = `${BASE_URL}/api/reports/get_driver_route_report`
api.getDriverVisitsReport = `${BASE_URL}/api/reports/get_driver_visits_report`
api.getStationLoadingReport = `${BASE_URL}/api/reports/get_station_loading_report`
api.getOrdersMarkAsClosedReport = `${BASE_URL}/api/reports/get_orders_marked_as_close`
api.getPackageDebitReport = `${BASE_URL}/api/DeliveryBilling/calcDeliveryBilling`
api.getPackageDebitReportAccount = `${BASE_URL}/api/DeliveryBilling/calcDeliveryBillingAccount`
api.getOrdersWithStatus = `${BASE_URL}/api/reports/get_orders_with_status`
api.exportLaundryPrices = (serviceSupplierId, fromDate, toDate) =>
  `${BASE_URL}/api/reports/get_laundry_prices_report?serviceSupplierId=${serviceSupplierId}&fromDate=${fromDate}&toDate=${toDate}`
api.downloadReservedLockerReport = `${BASE_URL}/api/reports/get_reserved_lockers_report`

// Import
api.importUsers = `${BASE_URL}/api/imports/import_users`
api.importOrders = `${BASE_URL}/api/imports/import_orders`
api.importPriceList = `${BASE_URL}/api/imports/import_price_list`
api.importTickets = `${BASE_URL}/api/imports/import_tickets`

// Editor
api.getTextMessages = `${BASE_URL}/api/messages/get_text_messages`

// Price lists
api.getPriceList = `${BASE_URL}/api/prices/get_price`
api.deletePriceList = `${BASE_URL}/api/prices/delete_price_list`
api.updatePrice = `${BASE_URL}/api/prices/update_price`
api.createPrice = `${BASE_URL}/api/prices/create_price`
api.uploadPriceListItemImage = `${BASE_URL}/api/prices/upload_image`
api.deletePriceListItem = `${BASE_URL}/api/prices/delete_item`

// Services
api.getServices = `${BASE_URL}/api/services/get_services`

// Processes
api.getProcesses = `${BASE_URL}/api/processes/get_processes`
api.uploadProcessImage = `${BASE_URL}/api/processes/upload_image`
api.updateProcess = `${BASE_URL}/api/processes/update_process`
api.deleteProcess = `${BASE_URL}/api/processes/delete_process`

// Items
api.getItems = `${BASE_URL}/api/products/get_products`
api.updateItem = `${BASE_URL}/api/products/update_product`
api.deleteItem = `${BASE_URL}/api/products/delete_product`
api.createItem = `${BASE_URL}/api/products/add_product`

api.getImage = `${BASE_URL}/api/images`

api.getStationPriceList = (stationId, lang) =>
  `${BASE_URL}/api/prices/get_price?stationId=${stationId}&lang=${lang}`

// Stations Notifications
//api.getStationsNotifications = `${BASE_URL}/api/StationNotifications`;
api.getStationNotifications = (stationId) => {
  if (stationId === undefined || stationId === null)
    return `${BASE_URL}/api/StationNotifications`
  return `${BASE_URL}/api/StationNotifications?stationId=${stationId}`
}

//Coupons
api.fetchCoupons = (couponId) => {
  if (couponId === undefined || couponId === null)
    return `${BASE_URL}/api/Coupon`
  return `${BASE_URL}/api/Coupon?couponId=${couponId}`
}

api.fetchActiveUserCoupons = (userId, orderType) =>
  `${BASE_URL}/api/Coupon/active_user_list/${userId}?orderType=${orderType}`

api.fetchActiveOrderUserCoupons = (userId) =>
  `${BASE_URL}/api/Coupon/active_order_user_list/${userId}`

api.createCoupon = `${BASE_URL}/api/Coupon`
api.updateCoupon = (couponId) => `${BASE_URL}/api/Coupon/${couponId}`
api.removeCoupon = (couponId) => `${BASE_URL}/api/Coupon/${couponId}`
api.fetchCoupon = (couponName) =>
  `${BASE_URL}/api/coupon​/GetCouponByName/${couponName}`
api.checkExistCouponName = (couponName) =>
  `${BASE_URL}/api/Coupon/exist_name?couponName=${couponName}`

api.sendInLockerSMS = (orderId) =>
  `${BASE_URL}/api/notification/SendInLockerSMS?orderId=${orderId}`

api.sendDelaySMS = (orderId) =>
  `${BASE_URL}/api/notification/SendOrderDelaySMS?orderId=${orderId}`

api.createNewMailOrderUrl = `${BASE_URL}/api/office/importOrdersWithStatus`

api.sendListDelaySMS = `${BASE_URL}/api/notification/SendOrdersListDelaySMS`

api.c = `${BASE_URL}/api/office/importOrdersWithStatus`

//SEARCH ORDERS
api.searchOrders = `${BASE_URL}/api/Orders/search`

api.sendStationSMS = (stationId, message) =>
  `${BASE_URL}/api/notification/SendStationSMS?stationId=${stationId}&message=${message}`

//SEARCH USERS
api.searchUsers = `${BASE_URL}/api/users/search`

//SEARCH USER HISTORY
api.searchUserHistory = `${BASE_URL}/api/users/history_search`

// SERVICE SUPPLIERS
api.getAllSuppliers = `${BASE_URL}/api/supplier/get_suppliers`
api.getSupplierById = (supplierId) =>
  `${BASE_URL}/api/supplier/get_supplier_by_id?supplierId=${supplierId}`
api.updateSupplier = (supplierId) => `${BASE_URL}/api/supplier/${supplierId}`
api.removeSupplier = (supplierId) => `${BASE_URL}/api/supplier/${supplierId}`
api.createSupplier = `${BASE_URL}/api/Supplier`
api.getSuppliersSelectList = `${BASE_URL}/api/supplier/suppliers_select`

//SEARCH LOCKERS HISTORY
api.searchLockersHistory = `${BASE_URL}/api/locker/search_lockers_history`
api.getLockersSelectListByStationId = (stationId) =>
  `${BASE_URL}/api/locker/lockers_select_by_station/${stationId}`

api.removeUserCCToken = (userId) =>
  `${BASE_URL}/api/users/remove_cctoken/${userId}`
//ROUTES
api.fetchRoutes = `${BASE_URL}/api/route`
api.getRouteStations = (routeId) =>
  `${BASE_URL}/api/route/get_route_stations/${routeId}`
api.createRoute = `${BASE_URL}/api/route/create_route`
api.updateRoute = (routeId) => `${BASE_URL}/api/route/${routeId}`
api.deleteRoute = (routeId) => `${BASE_URL}/api/route/${routeId}`
api.isExistRouteName = `${BASE_URL}/api/route/is_exist_route_name`

//ROUTE USER
api.getRouteDriversByRoute = (routeId) =>
  `${BASE_URL}/api/RouteUser/get_route_drivers/${routeId}`
api.addUserToRoute = `${BASE_URL}/api/RouteUser/add_route_user`
api.getExternalDrivers = `${BASE_URL}/api/RouteUser/get_external_drivers`
api.removeRouteUser = `${BASE_URL}/api/RouteUser/remove_route_user`

//CITY
//api.fetchCities = `${BASE_URL}/api/city`;
api.fetchCities = (lang) => `${BASE_URL}/api/city/cities_select/${lang}`
api.getAllCities = `${BASE_URL}/api/city`
api.getExternalCitiesList = `${BASE_URL}/api/city/GetExternalCitiesList`
api.createCity = `${BASE_URL}/api/city/create`
api.getDistricts = `${BASE_URL}/api/city/get_districts`
api.updateCity = (cityId) => `${BASE_URL}/api/city/update_city/${cityId}`
api.getDownStations = `${BASE_URL}/api/Stations/getDownStations`

//ORDER PUT TO LOCKER
api.putOrderToLocker = (orderId, lockerId) =>
  `${BASE_URL}/api/orders/put_to_locker/${orderId}?lockerId=${lockerId}`
api.getReadyToLoadOrders = (stationId) =>
  `${BASE_URL}/api/orders/get_ready/${stationId}`
api.getWaitingForPickupOrders = (stationId) =>
  `${BASE_URL}/api/orders/get_waiting_for_pickup/${stationId}`
api.confirmOrder = (orderId, lockerId) =>
  `${BASE_URL}/api/orders/confirm_order/${orderId}?lockerId=${lockerId}`
//STATIONS VOLTAGE
api.getStationsVoltage = `${BASE_URL}/api/Stations/getStationsVoltageLast`
api.getStationsVoltagePerStation = (stationId) =>
  `${BASE_URL}/api/Stations/getStationsVoltageLast/${stationId}/20`
api.createReturnsOrder = `${BASE_URL}/api/orders/returns`

//ORDER SACK
api.createOrderSackByAccount = (accountType) =>
  `${BASE_URL}/api/orderSack/create_order_sack_by_account/${accountType}`
api.fetchOrderSacksList = (orderType, orderStatus) =>
  `${BASE_URL}/api/orderSack/get_order_sacks_list`
api.fetchOrderSacksListByAccount = (accountType, orderStatus) =>
  `${BASE_URL}/api/orderSack/get_order_sacks_list_by_account/${accountType}/${orderStatus}`
api.fetchOrdersForSack = (orderType) =>
  `${BASE_URL}/api/orderSack/get_orders_for_sacks/${orderType}`
api.putOrderToSack = (orderId, sackId) =>
  `${BASE_URL}/api/orders/put_order_to_sack/${orderId}/${sackId}`
api.putOrdersToSack = (sackId) =>
  `${BASE_URL}/api/orders/put_orders_to_sack/${sackId}`
api.fetchSackOrders = (sackId) =>
  `${BASE_URL}/api/orderSack/get_sack_orders/${sackId}`
api.setOrderSackDelevered = (sackId) =>
  `${BASE_URL}/api/orderSack/set_order_sack_delivered/${sackId}`
api.setOrderSackDeleveredByBarcode = (barcode) =>
  `${BASE_URL}/api/orderSack/set_order_sack_delivered_by_barcode/${barcode}`
api.searchSackByBarcode = (barcode) =>
  `${BASE_URL}/api/orderSack/search_sack_by_barcode/${barcode}`
api.searchSackByOrderNumber = (orderNumber) =>
  `${BASE_URL}/api/orderSack/search_sack_by_order_number/${orderNumber}`

api.searchSacksByAccount = (accountType) =>
  `${BASE_URL}/api/orderSack/search_sacks_by_account/${accountType}`

api.searchSacksByDeliverPhone = (phoneNumber) =>
  `${BASE_URL}/api/orderSack/search_sacks_by_phone/${phoneNumber}`

api.sendInvoiceForOrders = `${BASE_URL}/api/orders/send_invoice_for_orders`
api.getDeliveryDetailsFromIsraelPost = (packageNumber) =>
  `${BASE_URL}/api/delivery/getDeliveryDetailsFromIsraelPost/${packageNumber}`

api.notificateEndOfScanning = `${BASE_URL}/api/notification/endOfScanningNotification`

api.getSupplierPrices = (supplierId, priceListId) =>
  `${BASE_URL}/api/supplier/getSupplierPrices/${supplierId}/${priceListId}`

api.updateSupplierPriceItem = `${BASE_URL}/api/supplier/updateSupplierPriceItem`

//cheapsim
api.getCheapSimOrders = `${BASE_URL}/api/orders/get_cheap_sim_orders`

api.updateCheapSimOrder = (orderId, externalOrderNumber) =>
  `${BASE_URL}/api/orders/update_cheapsim_order/${orderId}/${externalOrderNumber}`

// order images
api.getOrderImages = (orderId) =>
  `${BASE_URL}/api/images/get_order_images/${orderId}`

api.getReceptionOrders = () => `${BASE_URL}/api/orders/get_reception_orders`

api.processReceptionOrder = (orderId) =>
  `${BASE_URL}/api/orders/process_reception_order/${orderId}`

api.sendEndOfDayNotification = `${BASE_URL}/api/notification/EndOfDayNotification`

api.getShippingCertificateByOrderType = (orderType, orderStatus) =>
  `${BASE_URL}/api/office/shipping_certificate/${orderType}/${orderStatus}`

api.getShippingCertificateByAccountType = (orderType, orderStatus) =>
  `${BASE_URL}/api/office/shipping_certificate_by_account/${orderType}/${orderStatus}`

api.getShippingCertificateByRoute = (routeId, orderStatus) =>
  `${BASE_URL}/api/office/shipping_certificate_by_route/${routeId}/${orderStatus}`

api.getShippingCertificateByRouteOutsource = (routeId, orderStatus) =>
  `${BASE_URL}/api/office/shipping_certificate_by_route_outsource/${routeId}/${orderStatus}`

api.getShippingCertificateBySupplier = (supplierId, orderStatus) =>
  `${BASE_URL}/api/office/shipping_certificate_by_supplier/${supplierId}/${orderStatus}`

api.sendInLockerReturnNotification = (orderId) =>
  `${BASE_URL}/api/office/send_in_locker_return_notification/${orderId}`

api.sendSMSForChangeStation = (orderId) =>
  `${BASE_URL}/api/office/send_sms_for_change_station/${orderId}`

api.getOrderByPackageOrNumber = (searchTerm) =>
  `${BASE_URL}/api/orders/get_order_by_package_or_number/${searchTerm}`

// Route area codes
api.getAllRouteAreaCodes = `${BASE_URL}/api/routeAreaCode/getAllRouteAreaCodes`
api.createRouteAreaCode = `${BASE_URL}/api/routeAreaCode/createRouteAreaCode`
api.updateRouteAreaCode = `${BASE_URL}/api/routeAreaCode/updateRouteAreaCode`

// Station tasks
api.fetchStationTasks = `${BASE_URL}/api/stationTask/getStationTasks`
api.createStationTask = `${BASE_URL}/api/stationTask/createStationTask`
api.updateStationTask = `${BASE_URL}/api/stationTask/editStationTask`
api.getStationTasksHistoryByTaskId = (stationTaskId) =>
  `${BASE_URL}/api/stationTask/getStationTasksHistoryByTaskId/${stationTaskId}`
api.toggleIsActiveTask = (stationTaskId) =>
  `${BASE_URL}/api/stationTask/toggleIsActiveTask/${stationTaskId}`
api.getStationTaskPhoto = (stationTaskId, fileName) =>
  `${BASE_URL}/api/stationTask/getStationTaskPhoto/${stationTaskId}/${fileName}`

api.updateOrderStatusAndExtentionDate = (
  orderId,
  orderStatus,
  inLockerExtentionDate,
  isExtentionBilling
) =>
  `${BASE_URL}/api/orders/updateOrderStatusAndExtentionDate/${orderId}/${orderStatus}/${inLockerExtentionDate}/${isExtentionBilling}`

api.resetDeliveryLoginCode = (orderId) =>
  `${BASE_URL}/api/orders/reset_delivery_login_code/${orderId}`

// Branch Card
api.getBranchCardByBranchId = (branchId) =>
  `${BASE_URL}/api/BranchCard/get_branch_card_by_branchId/${branchId}`
api.editBranchCard = `${BASE_URL}/api/BranchCard/edit_branch_card`
api.changeTargetCustomer = (orderId, userId) =>
  `${BASE_URL}/api/orders/change_target_customer/${orderId}/${userId}`
api.setTargetStationChangeState = (
  orderId,
  targetStationId,
  isStationChangeBilling
) =>
  `${BASE_URL}/api/orders/set_target_station_change_state/${orderId}/${targetStationId}/${isStationChangeBilling}`

api.setSourceStation = (orderId, targetStationId, isStationChangeBilling) =>
  `${BASE_URL}/api/orders/set_source_station/${orderId}/${targetStationId}`

// Locker status
//GET LOCKER STATUSES
api.getLockersStatusesByStationId = (stationId) =>
  `${BASE_URL}/api/stations/lockers_status/${stationId}`

//GET LOCKERS LAYOUT
api.getLockersLayoutByStationId = (stationId) =>
  `${BASE_URL}/api/stations/get_layout/${stationId}`

api.startReturnToSender = (orderId) =>
  `${BASE_URL}/api/orders/set_order_as_return_to_sender/${orderId}`

api.getUserSubscriptions = (userId) =>
  `${BASE_URL}/api/Payment/get_user_subscriptions/${userId}`
api.cancelUserSubscription = (userSubscriptionId) =>
  `${BASE_URL}/api/Payment/delete_user_subscription/${userSubscriptionId}`

api.setOrderTracking = (orderId, isTracking) =>
  `${BASE_URL}/api/orders/set_order_tracking/${orderId}/${isTracking}`

api.changePackageNumber = (orderId, packageNumber) =>
  `${BASE_URL}/api/orders/change_package_number/${orderId}/${packageNumber}`

export default api
