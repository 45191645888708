import React, { Component } from "react";
import { BlueButton } from "../../common/BlueButton";
import axios from "axios";

class OrderTicket extends Component {
    constructor(props) {
        super(props);
        this.tick = React.createRef();
        this.state = {
            actions: "<h1></h1>"
        };
    }
    render() {
        const orderNumber = 100391;
        const lang = "he"
        return (
            <div>
                <BlueButton
                    id="printReceipt"
                    onClick={() => {
                        //  WHITE!
                        axios.post('http://localhost:3001/printReceipt', { "orderNumber": orderNumber, "lang": lang })
                        // this.initOrderPage();
                    }}
                >
                    Click White
                </BlueButton>

                <BlueButton
                    id="printLabel"
                    onClick={() => {
                        //  PINK!
                        axios.post('http://localhost:3001/printLabel', { "orderNumber": orderNumber, "lang": lang })
                        // this.initHydropicPage();
                    }}
                >
                    Click Pink
                </BlueButton>
                <div
                    style={{ opacity: 0, display: "none" }}
                    id="ifmcontentstoprint"
                    dangerouslySetInnerHTML={{ __html: this.state.actions }}
                />
            </div>
        );
    }
}
export default OrderTicket;
