import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import {
  fetchAllStationData,
  fetchAllOrderStatuses,
  setLockersInfraSensorSearch,
  setLockersInfraSensorStation,
  onLoadingLockersInfraSensor,
  fetchSearchedLockersInfraSensor,
  fetchLockersSelectListByStationId,
} from "../../../actions"

import {
  InputDatePicker,
  SelectMultiAutocomplete,
  BlueButton,
} from "../../common"

import "./style.css"

const searchFromDateText = "מתאריך"
const searchToDateText = "עד תאריך"

class LockersInfraSensorFilterer extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {
      defaultStationId: [],
      defaultLockers: [],
      search: {
        stationId: "",
        lockers: [],
        fromDate: "",
        toDate: "",
        pageStep: 50,
        page: 1,
      },
    }

    this.state = this.INIT_STATE
    this.handleDateChange = this.handleDateChange.bind(this)
    this.setDefaultsAndSearch = this.setDefaultsAndSearch.bind(this)
    this.cleanSearch = this.cleanSearch.bind(this)
  }

  componentWillMount() {
    let { search } = this.props

    const isEmptySearch =
      JSON.stringify(search) === JSON.stringify(this.INIT_STATE.search)

    if (isEmptySearch) {
      search["stationId"] = this.props.currentLockersStationId
      search["fromDate"] = this.getDefaultFromDate()
      search["toDate"] = this.formatDateForPicker(new Date())
    }

    this.setDefaultsAndSearch(search)

    if (isEmptySearch) {
      this.props.setLockersInfraSensorSearch(search)
    }
  }

  componentDidMount() {
    this.props.onLoadingLockersInfraSensor()
    this.props.fetchSearchedLockersInfraSensor(this.props.search)
  }

  componentWillUnmount() {
    const search = {
      stationId: "",
      lockers: [],
      fromDate: "",
      toDate: "",
      pageStep: 50,
      page: 1,
    }

    this.props.setLockersInfraSensorSearch(search)
  }

  componentWillReceiveProps(newProps) {
    let { search } = newProps
    const isEmptySearch =
      JSON.stringify(search) === JSON.stringify(this.INIT_STATE.search)
    const isDataChanged =
      JSON.stringify(search) !== JSON.stringify(this.state.search)

    if (isEmptySearch) {
      search["stationId"] = this.props.currentLockersStationId
      search["fromDate"] = this.getDefaultFromDate()
      search["toDate"] = this.formatDateForPicker(new Date()) + " 23:59:59"
    }

    if (isDataChanged) {
      this.setDefaultsAndSearch(search)
    }

    if (isEmptySearch) {
      this.props.setLockersInfraSensorSearch(search)
    }
  }

  setDefaultsAndSearch(search) {
    const { currentStationLockers } = this.props

    const defaultLockers = search.lockers.map((element) => {
      return { label: currentStationLockers[element], value: element }
    })
    this.setState({
      search,
      defaultLockers,
    })
  }

  getDefaultFromDate() {
    let defaultDate = new Date()
    defaultDate.setDate(defaultDate.getDate() - 3)

    return this.formatDateForPicker(defaultDate)
  }

  formatDateForPicker(date) {
    let day = date.getDate()
    if (day < 10) {
      day = "0" + day
    }

    let month = date.getMonth() + 1
    if (month < 10) {
      month = "0" + month
    }
    return date.getFullYear() + "-" + month + "-" + day
  }

  handleStationsFilterChange(item) {
    let { search } = this.state
    this.props.setLockersInfraSensorStation(item.stationId)
    this.props.fetchLockersSelectListByStationId(item.stationId)
    search.stationId = item.stationId
    search.lockers = []
    this.setState({ search, defaultLockers: [] })
    this.props.setLockersInfraSensorSearch(search)
  }

  updateActiveLockers(item) {
    let { search, defaultLockers } = this.state
    defaultLockers = item
    if (search == undefined || item == undefined) {
      return
    }
    search.lockers = item.map((itm) => {
      return itm.value
    })
    search.page = 1

    this.setState({ search, defaultLockers })
    this.props.setLockersInfraSensorSearch(search)
  }

  updateActiveStations(item) {
    let { search, defaultStations } = this.state
    defaultStations = item
    const { allStationsArr } = this.props
    search.stations = item.map((i) => allStationsArr[i.value].stationId)
    search.page = 1
    this.setState({ search, defaultStations })
    this.props.setLockersInfraSensorSearch(search)
  }

  updateActiveField(fieldName, value) {
    let { search } = this.state
    search[fieldName] = value
    search.page = 1
    this.setState({ search })
    this.props.setLockersInfraSensorSearch(search)
  }

  handleDateChange(inputName, event) {
    let { search } = this.state

    search[inputName] = event.target.value
    search.page = 1
    this.setState({ search })
    this.props.setLockersInfraSensorSearch(search)
  }

  cleanSearch() {
    this.props.setLockersInfraSensorSearch({
      stationId: "",
      lockers: [],
      fromDate: "",
      toDate: "",
      pageStep: 50,
      page: 1,
    })
    this.setState({
      defaultStationId: [],
      defaultLockers: [],
      search: {
        stationId: "",
        lockers: [],
        fromDate: "",
        toDate: "",
        pageStep: 50,
        page: 1,
      },
    })
  }

  render() {
    const {
      stationsItemSelectList,
      currentStationItem,
      currentStationLockers,
    } = this.props

    const { search } = this.state
    const { fromDate, toDate } = search

    return (
      <div className="search-order-filterer  lockers-history-filterer">
        <div className="line-wrapper">
          <div className="select-autocomplete-wrapper sations-list">
            <Select
              placeholder="עמדה"
              value={currentStationItem}
              options={stationsItemSelectList}
              onChange={(itm) => this.handleStationsFilterChange(itm)}
              classNamePrefix="base-select"
            />
          </div>

          <InputDatePicker
            name="fromDate"
            date={fromDate}
            onChange={this.handleDateChange}
            placeholder={searchFromDateText}
          />

          <InputDatePicker
            name="toDate"
            date={toDate}
            onChange={this.handleDateChange}
            placeholder={searchToDateText}
          />

          <SelectMultiAutocomplete
            placeholder=" תא"
            items={currentStationLockers}
            value={this.state.defaultLockers}
            onChangeHandler={(itm) => this.updateActiveLockers(itm)}
            extraClassName="locker-list"
          />

          <BlueButton
            extraClass="search-btn"
            onClick={(e) => {
              this.props.onLoadingLockersInfraSensor()
              this.props.fetchSearchedLockersInfraSensor(this.props.search)
            }}
          >
            חפש
          </BlueButton>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ stationsList, lockersInfraSensor }) => {
  const { stationsItemSelectList } = stationsList

  const {
    search,
    lockersList,
    currentLockersStationId,
    currentStationLockers,
  } = lockersInfraSensor

  const currentStationItem = stationsItemSelectList.filter(
    (item) => item.stationId === currentLockersStationId
  )

  return {
    search,
    lockersList,
    stationsItemSelectList,
    currentStationItem,
    currentLockersStationId,
    currentStationLockers,
  }
}

export default connect(mapStateToProps, {
  fetchAllStationData,

  setLockersInfraSensorSearch,
  setLockersInfraSensorStation,
  fetchAllOrderStatuses,
  fetchSearchedLockersInfraSensor,
  onLoadingLockersInfraSensor,
  fetchLockersSelectListByStationId,
})(LockersInfraSensorFilterer)
