import React, { Component } from "react"

import {
  BlueButton,
  SelectVal,
  Editable,
  SelectValPassive,
} from "../../../../../../common"
import Select from "react-select"
import "./style.css"

class CreateNewOrderItem extends Component {
  constructor(porps) {
    super(porps)

    this.INIT_STATE = {
      processName: "",
      itemName: null,
      color: null,
      pattern: null,
      quantity: "",
      itemPrice: "",
      notes: "",
      refreshing: false,
      selectedItemNameIndex: -1,
      // weightQuantity: 0,
    }

    this.state = this.INIT_STATE
  }

  componentWillReceiveProps(newProps) {
    this.setState({ selectedItemNameIndex: -1 })
  }

  onProcessNameSelected(processName) {
    this.updateItemProperty("processName", processName)
    this.setState({ selectedItemNameIndex: -1 })
    this.updateItemProperty("itemPrice", 0)
    if (processName.value == "laundry") {
      this.updateItemProperty("itemName", `1 ק""ג (מינימום 5 ק""ג)`)
      this.setState({ selectedItemNameIndex: 0, quantity: 5 })
      this.updateItemProperty(
        "itemPrice",
        this.props.optionalPricesObj[processName.value][0].price
      )
    }
  }

  onItemNameSelected(itemName, index) {
    const { optionalPricesObj } = this.props
    const { processName, quantity } = this.state
    this.updateItemProperty("itemName", itemName)
    this.updateItemProperty(
      "itemPrice",
      optionalPricesObj[processName.value][index].price
    )

    this.setState({ selectedItemNameIndex: index })
  }

  onQuantityUpdate(val) {
    const quantity = parseInt(val) || 0
    this.updateItemProperty("quantity", quantity)
  }

  updateItemProperty(propName, val) {
    this.setState({ [propName]: val })
  }

  onAddItemClicked() {
    const {
      processName,
      itemName,
      color,
      pattern,
      quantity,
      itemPrice,
      // weightQuantity,
      notes,
    } = this.state

    const quantityOrWeight = this.setDefaultLaundryWeight(
      processName.value,
      quantity
    )
    if (
      processName.value.length > 0 &&
      itemName &&
      quantity > 0 &&
      itemPrice >= 0
    ) {
      this.props.addNewItem({
        processName: processName.value,
        itemName,
        color,
        pattern,
        quantity: quantityOrWeight,
        itemPrice,
        // weightQuantity,
        notes,
      })

      this.setState({ ...this.INIT_STATE, refreshing: true })

      // SETTING REFRESH TIMEOUT TO FORCE RE-CREATION OF THE 'SelectVal' ELEMENTS
      setTimeout(() => this.setState({ refreshing: false }), 100)
    }
  }

  setDefaultLaundryWeight(processName, quantity) {
    if (processName == "laundry" && quantity < 5.5) {
      return 5
    }
    return quantity
  }

  render() {
    const {
      colorsArr,
      patternsArr,
      optionalPricesObj,
      processNamesArr,
      processNamesObj,
    } = this.props
    const {
      processName,
      quantity,
      itemPrice,
      // weightQuantity,
      notes,
      refreshing,
      selectedItemNameIndex,
    } = this.state

    if (refreshing) {
      return <div> refreshing</div>
    }

    let itemNamesArr = optionalPricesObj
      ? optionalPricesObj[processName.value] || []
      : []
    itemNamesArr = itemNamesArr.map(({ productName_he }) => productName_he)
    return (
      <div className="cnoritm-table-item">
        <Select
          options={processNamesObj}
          onChange={(val) => this.onProcessNameSelected(val)}
          menuPlacement="top"
        />
        <SelectValPassive
          items={itemNamesArr}
          onItemSelected={(itemName, index) =>
            this.onItemNameSelected(itemName, index)
          }
          placeholder="פריט"
          valueIndex={selectedItemNameIndex}
        />
        <SelectVal
          // extraClass='cnoritm-table-itm-elem'
          items={colorsArr}
          onItemSelected={(color) => this.updateItemProperty("color", color)}
          placeholder="צבע"
        />
        {/* <SelectVal
          // extraClass='cnoritm-table-itm-elem'
          items={patternsArr}
          onItemSelected={(pattern) =>
            this.updateItemProperty("pattern", pattern)
          }
          placeholder="פטרן"
        /> */}
        <Editable
          value={quantity}
          placeholder="כמות"
          onChange={(e) => this.onQuantityUpdate(e.target.value)}
          extraClass="cnoritm-table-itm-elem"
        />
        {/* <InputFiled
                placeholder='מס"ד'
                extraClass='cnoritm-table-itm-elem'
            /> */}
        <Editable
          value={itemPrice}
          placeholder="מחיר"
          onChange={(e) =>
            this.updateItemProperty(
              "itemPrice",
              parseFloat(e.target.value) || e.target.value.endsWith(".")
                ? e.target.value
                : 0
            )
          }
          extraClass="cnoritm-table-itm-elem"
        />

        {/* <Editable
          value={weightQuantity}
          placeholder="משקל מקורי"
          onChange={(e) =>
            this.updateItemProperty(
              "weightQuantity",
              parseFloat(e.target.value) || e.target.value.endsWith(".")
                ? e.target.value
                : 0
            )
          }
          extraClass="cnoritm-table-itm-elem"
        /> */}
        <Editable
          value={notes}
          placeholder="הערות"
          onChange={(e) => this.updateItemProperty("notes", e.target.value)}
          extraClass="cnoritm-table-itm-elem cnot-table-input-extended"
        />
        <div className="cnoritm-table-itm-elem-btn-wrap">
          <BlueButton
            extraClass="cnoritm-table-elem-btn"
            onClick={(e) => this.onAddItemClicked()}
          >
            אשר הוספת פריט
          </BlueButton>
        </div>
      </div>
    )
  }
}

export default CreateNewOrderItem
