import { StylesConfig } from "react-select"
import { ColourOption } from "./ColorLists"

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",
  border: "solid 1px #fff",
  ":before": {
    backgroundColor: color,
    borderRadius: 2,
    content: '" "',
    display: "block",
    marginRight: 3,
    marginLeft: 6,
    height: 20,
    width: 20,
    boxShadow: "0 0 #8a8a8a",
  },
})

export const colourStyles: StylesConfig<ColourOption> = {
  control: (styles) => ({ ...styles, backgroundColor: "#fff" }),
  option: (styles, { data }) => {
    const textColor = "#333"
    return {
      ...styles,
      ...dot(data.color),
      color: textColor,
      ":active": {
        ...styles[":active"],
        ...dot(data.color),
      },
      ":hover": {
        ...styles[":hover"],
        ...dot(data.color),
      },
    }
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...dot(data.color),
  }),
}
