import React, { Component } from "react"
import { setOrderTypesEnumByLang, getFormatedDate } from "../../../../utils"
import "./style.scss"

const UserSubscriptionsList = (props) => {
  const { itemsList, orderTypes, cancelUserSubscription } = props

  console.log("UserSubscriptionsList props")
  console.log(props)
  console.log("orderTypes")
  console.log(orderTypes)

  if (!itemsList || itemsList.length === 0) {
    return <div>אין רשומות</div>
  }
  const list = itemsList.map((itm, index) => {
    return {
      userId: itm.userId,
      userSubscribtionId: itm.userSubscribtionId,
      subscribtionName: itm.subscribtionName,
      subscribtionOrderType: orderTypes[itm.subscribtionOrderType],
      startDate: getFormatedDate(itm.startDate),
      dayInMonth: itm.dayInMonth,
      subscribtionAmount: itm.subscribtionAmount,
      fullName: itm.fullName,
      stationId: itm.stationId,
      stationInfo: itm.stationInfo,
      isDelete: itm.isDelete,
      lastUpdate: getFormatedDate(itm.updateDate),
    }
  })

  return (
    <div className="record-table user-subscriptions-list">
      <div className="record-row">
        <div className="record-col icon"></div>
        <div className="record-col subscribtion-name"> מנוי</div>
        <div className="record-col order-type">סוג שרות</div>
        <div className="record-col start-date">תאריך התחלה</div>
        <div className="record-col day-in-month">יום תשלום בחודש</div>
        <div className="record-col amount">מחיר</div>
        <div className="record-col user-fullname">שם לקוח במנוי</div>
        <div className="record-col station-info">עמדה</div>
        <div className="record-col delete">עדכון</div>
      </div>
      {renderItems(list, cancelUserSubscription)}
    </div>
  )
}

const renderItems = (items, cancelUserSubscription) => {
  return items.map((element, index) => {
    const subscribtionAmount =
      element.subscribtionAmount !== 0 ? element.subscribtionAmount + "₪" : ""

    const activeStyle = element.isDelete
      ? "fa fa-times-circle"
      : "fa fa-check-circle"

    return (
      <div
        className="record-row"
        key={index}
        data-id={element.userSubscribtionId}
      >
        <div className="record-col icon">
          <div className={activeStyle} aria-hidden="true"></div>
        </div>
        <div className="record-col subscribtion-name">
          {element.subscribtionName}
        </div>
        <div className="record-col order-type">
          {element.subscribtionOrderType}
        </div>
        <div className="record-col start-date">{element.startDate}</div>
        <div className="record-col day-in-month">{element.dayInMonth}</div>
        <div className="record-col amount">{subscribtionAmount}</div>

        <div className="record-col user-fullname">{element.fullName}</div>
        <div className="record-col station-info">{element.stationInfo}</div>
        {element.isDelete === true && (
          <div className={"record-col delete red"}>{element.lastUpdate}</div>
        )}
        {element.isDelete === false && (
          <div
            className={"record-col delete active"}
            onClick={() => cancelUserSubscription(element)}
          >
            <div className="fas fa-trash-alt"></div>
          </div>
        )}
      </div>
    )
  })
}

export default UserSubscriptionsList
