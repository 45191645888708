import React from 'react';

import './style.css';
import DesignedList from './DesignedList';
import { Filterer } from '../../../reduxified';
import { BlueButton, InputFiled } from '../../../common';

const OrderSelectMenu = ({ ordersList, onItemClick, selectedItemIndex, onNewOrderBtnClick, onNewMailOrderBtnClick, searchVal, onSearchChange }) => {
    return (
        <div className='order-slct-menu-wrapper'>
            <div className='order-slct-menu-header'>
                <BlueButton
                    extraClass='order-slct-btn'
                    onClick={e => onNewOrderBtnClick()}
                >
                    הזמנת כביסה חדשה
                </BlueButton>
                <BlueButton
                    extraClass='order-slct-btn'
                    onClick={e => onNewMailOrderBtnClick()}
                >
                    הזמנת דואר חדשה
                </BlueButton>
                <InputFiled
                    extraClass='order-slct-input'
                    icon='magnifying_glass'
                    placeholder='חפש...'
                    value={searchVal}
                    onChange={e => onSearchChange(e.target.value)}
                />
            </div>

            <Filterer />

            <DesignedList
                items={ordersList}
                onItemClick={onItemClick}
                selectedItemIndex={selectedItemIndex}
            />
        </div>
    );
}

export default OrderSelectMenu;
