import React, { useEffect } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { Filter } from '../../filters/Filter';
import { DefaultColumnFilter } from '../../filters/DefaultColumnFilter';
import { Table, Row, Col, Button, Input, CustomInput } from "reactstrap";
import './style.css';

const TableContainer = ({ columns, data  }) => {
  
  const uTable =  useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 50 }
    },
    useFilters,
    useSortBy,
   
    usePagination
  );
    
    let {
       getTableProps,
       getTableBodyProps,
       headerGroups,
       page,
       prepareRow,
       canPreviousPage,
       canNextPage,
       pageOptions,
       pageCount,
       gotoPage,
       nextPage,
       previousPage,
       setPageSize,
       state: { pageIndex, pageSize }
  
  } = uTable;
  
  useEffect(() => {
    uTable.setPageSize(50);
    
}, [])

  console.log('pageIndex:'+ uTable.pageIndex);
  

    const generateSortingIndicator = column => {
      return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }
        
    const onChangeInSelect = event => {
      
      setPageSize(Number(event.target.value))
    }

    const onChangeInInput = event => {
     
      const page = event.target.value ? Number(event.target.value) - 1 : 0
      uTable.pageIndex = page
      gotoPage(page)
    }

    


    return (
     

        <div className='table-container'>
          <table bordered hover {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(h => (
                        <th {...h.getHeaderProps(h.getSortByToggleProps())}>
                          <div>

                            {h.render("Header")}
                          {generateSortingIndicator(h)}
                          </div>
                          <Filter column={h} />
                        </th>
                    ))}
                  </tr>
                ))}
              </thead>
              
              
              <tbody>
                {page.map((row) => {
                  
                  prepareRow(row);
                  
                  return (
                   
                      <tr>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          );
                        })}
                      </tr>
                  );
                })}
              </tbody>
          </table>
        

          <div className='pagination'>
            <div md={3}>
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
           
         
            <div md={2} style={{ marginTop: 7 }}>
              דף{" "}
              <strong>
              {" "} {uTable.pageIndex+1} מ- {pageOptions.length} {" "}
              </strong>
            </div>
             {/*  
             
             <div md={2}>
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={pageOptions.length}
                defaultValue={uTable.pageIndex + 1}
                onChange={e =>onChangeInInput(e)}
              />
              </div>
             */ }
            
          
          

           
              
         
            
           
            <div md={3}>
              <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>

               <div md={2}>
              <CustomInput type="select" value={pageSize} onChange={onChangeInSelect}>
               {">"}
                {[50, 100, 150, 200].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    הצג {pageSize}
                  </option>
                ))}
              </CustomInput>
            </div>
            </div>
          </div>
    </div>
    
    );
  }
  
  export default TableContainer;