import React from "react"
import { connect } from "react-redux"
import { getFormatedDate } from "../../../../utils"
import Select from "react-select"
import "./style.scss"

const StationsVoltage = (props) => {
  return (
    <div className="stations-voltage-wrapper">
      <h3>
        <div className="down-count">
          {props.stationsVoltageSelect
            ? props.stationsVoltageSelect.length
            : ""}
        </div>
        מתח תחנות BLE
        <div className="select-wrapper">
          <Select
            classNamePrefix="autocomplete-select"
            options={props.selectItems}
            value={props.defaultValueVoltageSelect}
            onChange={(itm, index) =>
              props.onSelectStationOnVoltage(itm, index, props.selectItems)
            }
          />
        </div>
      </h3>
      <div className="stations-voltage-list">
        <div className="station-item title">
          <div className="column number">מספר</div>
          <div className="column name">שם עמדה</div>
          <div className="column voltage">מתח</div>
          <div className="column before-time">תאריך</div>
        </div>
        {props.stationsVoltage.map((itm, indx) => {
          const dateTime = getFormatedDate(itm.createdDate)
          return (
            <div className="station-item" key-data={itm.stationId} key={indx}>
              <div className="column number">{itm.stationNumber}</div>
              <div className="column name">{itm.stationName}</div>
              <div className="column voltage">{itm.voltage}</div>
              <div className="column before-time">{dateTime}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProp = ({ stationsList }) => {
  const { stationsVoltage, stationsVoltageSelect } = stationsList
  let selectItems = [
    { value: 0, label: "כול העמדות" },
    ...stationsVoltageSelect.map(
      ({ stationId, stationNumber, stationName }) => ({
        value: stationNumber,
        stationId: stationId,
        label: stationNumber + " - " + stationName,
      })
    ),
    //...stationsVoltage.map(({ stationId, station }) => ({
    //value: station.stationNumber,
    //stationId: stationId,
    //label: station.stationNumber + " - " + station.branch.branchDisplayName,
    //})),
  ]

  return { stationsVoltage, selectItems, stationsVoltageSelect }
}

export default connect(mapStateToProp, {
  //getDownStations,
})(StationsVoltage)
