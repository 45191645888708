import React, { useEffect, useState } from "react"
import { BlueButton } from "../../../../../common/BlueButton"
import "./BagsCount.scss"

const BagsCount = (props) => {
  const { show, estimatedBagsQuantity } = props
  const [bagsCount, setBagsCount] = useState(estimatedBagsQuantity)

  useEffect(() => {
    setBagsCount(estimatedBagsQuantity ? estimatedBagsQuantity : 0)
  }, [estimatedBagsQuantity])

  if (!show) {
    return null
  }

  return (
    <div className="bags-popup-wrapper">
      <div className="bags-popup">
        <p>הזמנה עם יותר מחבילה אחת</p>
        <div>מספר חבילות:</div>
        <div className="bags-counter">
          <span className="plus" onClick={() => setBagsCount(bagsCount + 1)}>
            +
          </span>
          <input
            className="input-bags"
            value={bagsCount}
            type="number"
            min="0"
          />
          <span
            className="minus"
            onClick={() => setBagsCount((c) => Math.max(c - 1, 1))}
          >
            -
          </span>
        </div>
        <div className="btn-confirm">
          <BlueButton
            extraClass=""
            onClick={() => props.confirmBagsCount(bagsCount)}
          >
            אישור
          </BlueButton>
          <BlueButton extraClass="" onClick={() => props.close()}>
            ביטול
          </BlueButton>
        </div>
      </div>
    </div>
  )
}

export default BagsCount
