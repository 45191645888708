import React from "react"
import "./OrderTable.scss"
import { BlueButton } from "../../../../../common"
import { getFormatedDate } from "../../../../../../utils/FormatedDate"

const OrderTable = (props) => {
  const formatDate = (order) => {
    if (order) {
      const lastRecord = getHistoryRecord(order.historyRecords)
      const formatedDate = getFormatedDate(lastRecord.statusDate)
      return formatedDate
    }
  }

  const getHistoryRecord = (orderHistoryItems) => {
    const firstRecordOutForService = orderHistoryItems.find((element) => {
      return element.orderStatus == 17
    })
    return firstRecordOutForService
  }

  const renderTableData = () => {
    return props.ordersList.map((order, index) => {
      const {
        id,
        orderNumber,
        packageNumber,
        orderDate,
        stationNumber,
        branchName,
        currentLocation,
        firstName,
        lastName,
        bagsQuantity,
      } = order
      return (
        <tr
          key={index}
          className={
            order.orderNumber == props.scanedOrderNumber && props.isScaned
              ? "scaned"
              : ""
          }
        >
          <td>{orderNumber}</td>
          <td>{packageNumber}</td>
          <td>{formatDate(order)}</td>
          <td>{stationNumber}</td>
          <td>{branchName}</td>
          <td>{currentLocation}</td>
          <td>{firstName + " " + lastName}</td>
          <td>
            <BlueButton
              extraClass="btn-redirect"
              onClick={() => props.onBagsQuantityClick(order)}
            >
              {bagsQuantity ? bagsQuantity : 1}
            </BlueButton>
          </td>
          <td>
            <BlueButton
              extraClass="btn-redirect"
              onClick={() => props.onEditClick(order)}
            >
              עריכה
            </BlueButton>
          </td>
        </tr>
      )
    })
  }

  return (
    <section className="order_table">
      <table>
        <tr>
          <th>מזהה</th>
          <th>מס' חבילה</th>
          <th>{props.statusDateTitle}</th>
          <th>מס' עמדה</th>
          <th>שם סניף</th>
          <th>מיקום הזמנה</th>
          <th>שם לקוח</th>
          <th>כמות חבילות</th>
          <th></th>
        </tr>
        {renderTableData()}
      </table>
    </section>
  )
}

export default OrderTable
