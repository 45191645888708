import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"
import {
  ON_STATION_TASKS_LOADING,
  ON_GET_STATION_TASKS_FAIL,
  ON_GET_STATION_TASKS_SUCCESS,
  ON_SELECT_EDIT_TASK,
  ON_UPDATE_STATION_TASKS_SUCCESS,
  ON_UPDATE_STATION_TASKS_FAIL,
  ON_GET_STATION_TASK_HISTORY_SUCCESS,
  ON_GET_STATION_TASK_HISTORY_FAIL,
  ON_CREATE_STATION_TASKS_SUCCESS,
  ON_CREATE_STATION_TASKS_FAIL,
  CLEAR_STATION_TASKS,
  CLEAR_STATION_TASKS_HISTORY,
  ON_TOGGLE_IS_ACTIVE_TASKS_SUCCESS,
  ON_TOGGLE_IS_ACTIVE_TASKS_FAIL,
  ON_GET_STATION_TASK_PHOTO_SUCCESS,
  ON_GET_STATION_TASK_PHOTO_FAIL,
  CLEAR_GET_STATION_TASKS_PHOTO,
} from "./types"

export const fetchStationTasks = () => {
  return (dispatch) => {
    dispatch({ type: ON_STATION_TASKS_LOADING })
    axios
      .get(api.fetchStationTasks)
      .then((res) => {
        console.log(res.data)
        dispatch({
          type: ON_GET_STATION_TASKS_SUCCESS,
          payload: res.data,
        })
        errorManager.throwDispatchErrorReset(dispatch, res)
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: ON_GET_STATION_TASKS_FAIL,
          payload: err,
        })
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const updateStationTask = (stationTask) => {
  return (dispatch) => {
    dispatch({ type: ON_STATION_TASKS_LOADING })
    axios
      .put(api.updateStationTask, stationTask)
      .then((res) => {
        dispatch({
          type: ON_UPDATE_STATION_TASKS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_UPDATE_STATION_TASKS_FAIL,
          payload: err,
        })
        errorManager.dispatchError(err, dispatch)
      })
  }
}

export const createStationTask = (stationTask) => {
  return (dispatch) => {
    dispatch({ type: ON_STATION_TASKS_LOADING })
    axios
      .post(api.createStationTask, stationTask)
      .then((res) => {
        dispatch({
          type: ON_CREATE_STATION_TASKS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_CREATE_STATION_TASKS_FAIL,
          payload: err,
        })
        errorManager.dispatchError(err, dispatch)
      })
  }
}

export const getStationTasksHistoryByTaskId = (stationTaskId) => {
  return (dispatch) => {
    axios
      .get(api.getStationTasksHistoryByTaskId(stationTaskId))
      .then((res) => {
        dispatch({
          type: ON_GET_STATION_TASK_HISTORY_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_STATION_TASK_HISTORY_FAIL,
          payload: err,
        })
        errorManager.dispatchError(err, dispatch)
      })
  }
}

export const selectTaskForEdit = (selectedStationTask) => (dispatch) => {
  dispatch({
    type: ON_SELECT_EDIT_TASK,
    payload: selectedStationTask,
  })
}

export const clearStationTasks = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATION_TASKS,
  })
}

export const clearStationTaskHistory = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATION_TASKS_HISTORY,
  })
}

export const toggleIsActiveTask = (params) => {
  return (dispatch) => {
    dispatch({ type: ON_STATION_TASKS_LOADING })
    const requestData = {
      note: params.notes,
      id: params.taskId,
      isActive: params.isActive,
    }
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    axios
      .put(api.toggleIsActiveTask(params.taskId), requestData, options)
      .then((res) => {
        dispatch({
          type: ON_TOGGLE_IS_ACTIVE_TASKS_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_TOGGLE_IS_ACTIVE_TASKS_FAIL,
          payload: err,
        })
        errorManager.dispatchError(err, dispatch)
      })
  }
}

export const getStationTaskPhoto = (stationTaskId, fileName) => {
  return (dispatch) => {
    const encodedFileName = encodeURIComponent(fileName)

    axios
      .get(api.getStationTaskPhoto(stationTaskId, encodedFileName))
      .then((res) => {
        dispatch({
          type: ON_GET_STATION_TASK_PHOTO_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        dispatch({
          type: ON_GET_STATION_TASK_PHOTO_FAIL,
          payload: err,
        })
        errorManager.dispatchError(err, dispatch)
      })
  }
}

export const clearGetStationTaskPhoto = () => (dispatch) => {
  dispatch({
    type: CLEAR_GET_STATION_TASKS_PHOTO,
  })
}
