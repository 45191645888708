import React from 'react';

import { getFormatedDateOnly } from '../../../../../utils/FormatedDate';
import { DISCOUNT_TYPES_ENUM, CouponOrderTypes_ENUM } from '../../../../../utils';
import './style.css';

const CouponItem = ({ item, itemMap, gravity, isLast, onEditBtnClick }) => {
    const itemClassName = `ol-item-wrapper ${isLast ? 'ol-item-last' : ''}`;

    return (
        <div className={itemClassName}  onClick={e => onEditBtnClick(item)}>
            {renderItemContent(item, itemMap, gravity)}
        </div>
    );

};

const renderItemContent = (item, itemMap, gravity) => {
    return itemMap.map((itmMap, index) => {

        const flex = gravity[`${index}`] || 1;

        let itemContent = item[itmMap];
        
        
        if(itmMap === 'discountType'){
            itemContent = DISCOUNT_TYPES_ENUM[item[itmMap]];
        }
        if(itmMap === 'orderType'){
            itemContent = CouponOrderTypes_ENUM[item[itmMap]];
        }
        if(itmMap === 'isActive'){
            const itemClassName = item[itmMap] ? "fa fa-check" : "";
            return(
                <div className={itemClassName}  aria-hidden="true" style={{ flex }} key={index}></div>
            );
            
        }
        if(itmMap === 'startDate' || itmMap === 'expiredDate'){
            itemContent = getFormatedDateOnly(item[itmMap]);
        }

        return (
            <div style={{ flex }} key={index}>
                {itemContent}
            </div>
        );
    });
};

export default CouponItem;
