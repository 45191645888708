import React, { Component } from "react"

import {
  getDateOutOfTimeStamp,
  getTimeOutOfTimeStamp,
} from "../../../../utils/FormatedDate"

import "./style.css"

class Cell extends Component {
  renderImgCell = (num, size) => {
    let iconSrc
    let factor

    if (num === -1) {
      factor = 2
      iconSrc = require("../../../../assets/images/trash_can_sign.png")
    }

    if (num === -2) {
      factor = 6
      iconSrc = require("../../../../assets/images/touch_station.png")
    }

    const cellStyle = {
      paddingTop: size * factor,
      paddingBottom: size * factor,
    }

    return (
      <div className="cell-wrapper cell-type-img" style={cellStyle}>
        <img src={iconSrc} alt="" className="cell-img-icon" />
      </div>
    )
  }

  renderOrderDetails = (order, hideInfo) => {
    if (!order || hideInfo) {
      return
    }

    const {
      orderNumber,
      firstName,
      lastName,
      orderDate,
      orderTypeName,
      packageNumber,
    } = order
    const customerName = `${firstName} ${lastName}`

    const displayOrderDate = getDateOutOfTimeStamp(`${orderDate}Z`)
    const displayOrderTime = getTimeOutOfTimeStamp(`${orderDate}Z`)

    return (
      <div className="cell-order-details-wrapper">
        <div>{orderNumber}</div>
        <div>{customerName}</div>
        <div>{orderTypeName}</div>
        <div>{displayOrderDate}</div>
        <div>{displayOrderTime}</div>
        <div>{packageNumber}</div>
      </div>
    )
  }

  onRefInit(ref) {
    const { num } = this.props

    this.props.onRefReady(num, ref)
  }

  render() {
    const {
      num,
      size,
      order,
      blink,
      red,
      green,
      grey,
      blue,
      yellow,
      black,
      errLocker,
      hideInfo,
      onClick,
      whiteCells,
      accessible,
    } = this.props

    if (num < 0) {
      return this.renderImgCell(num, size)
    }
    const finalSize = !hideInfo && order && size < 3 ? 3 : size

    const cellStyle = { height: finalSize * 80 }

    const cellClassName = `cell-wrapper \
        ${blink && "cell-blink"} \
        ${errLocker && "cell-error-blink"} \
        ${!hideInfo && order && "cell-with-order"} \
        ${!hideInfo && red && "cell-red"} \
        ${!hideInfo && green && "cell-green"} \
        ${!hideInfo && blue && !black && !yellow && "cell-blue"} \
        ${!hideInfo && yellow && "cell-yellow"} \
        ${grey && !whiteCells && "cell-grey"} \
        ${!hideInfo && black && "cell-black"} `

    return (
      <div
        className={cellClassName}
        style={cellStyle}
        onClick={(e) => onClick(num)}
        ref={(ref) => this.onRefInit(ref)}
      >
        {this.renderOrderDetails(order, hideInfo || grey)}
        <div className="cell-number">{num}</div>
      </div>
    )
  }
}

export default Cell
