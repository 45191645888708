import React from "react"
import { useState, useEffect } from "react"
import { connect } from "react-redux"
import {
  fetchExternalDrivers,
  fetchRouteDrivers,
  addRouteDriver,
  onLoadingRouteUser,
  removeRouteUser,
  closeRouteUserNotification,
} from "../../../../actions"
import DriversList from "./DriversList"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { BlueButton } from "../../../common"
import { colourStyles } from "../../../../utils/colorStyles.tsx"
import NotificationModal from "../../../Modals/NotificationModal"
import "./style.scss"

const DriversByRoute = (props) => {
  const [selectedRoute, setSelectedRoute] = useState({
    value: 0,
    label: "כל המסלולים",
    color: "white",
  })
  const [driver, setDriver] = useState(null)

  const [formData, setFormData] = useState({
    routeId: "",
    driverId: "",
  })

  useEffect(() => {
    if (props.unauthorized) {
      props.history.push("/")
    }
    props.onLoadingRouteUser()
    if (selectedRoute.value === 0) {
      props.fetchExternalDrivers()
    } else {
      props.fetchRouteDrivers(selectedRoute.value)
    }
  }, [])

  const animatedComponents = makeAnimated()

  const handleSubmit = (event) => {
    if (!formData.routeId || !formData.userId || props.isLoading) {
      return
    }
    props.onLoadingRouteUser()
    props.addRouteDriver(formData.routeId, formData.userId)
  }
  const handleSearchDriver = (item) => {
    setDriver(item)
    const userId = item.driver.id

    setFormData({
      ...formData,
      userId: userId,
    })
  }

  const handleSelectChange = (selectedOption) => {
    setSelectedRoute(selectedOption)
    setFormData({
      ...formData,
      routeId: selectedOption.value,
    })
    props.onLoadingRouteUser()
    if (selectedOption.value === 0) {
      props.fetchExternalDrivers()
    } else {
      props.fetchRouteDrivers(selectedOption.value)
    }
  }

  const handleRemoveDriverClick = (driverId) => {
    props.onLoadingRouteUser()
    props.removeRouteUser(formData.routeId, driverId)
  }

  return (
    <section className="drivers-by-route">
      <h3>הרשאות נהגים חיצונים לפי מסלול</h3>

      <div className="stations-section-station-info-row">
        <div className="container-select route-select">
          <div className="route-label ss-label">{"מסלול הפצה"}</div>

          <Select
            className="select-route"
            name="routeId"
            options={props.routesList}
            value={selectedRoute}
            classNamePrefix="base-select"
            placeholder={"מסלול הפצה"}
            isClearable={false}
            components={animatedComponents}
            onChange={handleSelectChange}
            styles={colourStyles}
          />
        </div>
      </div>

      <div className="stations-section-station-info-row">
        <div className="driver-label ss-label">נהג חיצוני</div>
        <div className={"multi-autocomplete-wrapper driver-select"}>
          <Select
            classNamePrefix="base-select"
            closeMenuOnSelect={true}
            value={driver}
            placeholder={"חפוש מספר מובייל/שם נהג חיצוני"}
            isClearable={false}
            options={props.driversList}
            components={animatedComponents}
            onChange={(e) => handleSearchDriver(e)}
            isMulti={false}
          />
        </div>
      </div>

      <div className="btn-holder">
        <BlueButton
          type="submit"
          onClick={() => handleSubmit()}
          disabled={
            !selectedRoute ||
            selectedRoute.value === 0 ||
            !driver ||
            driver === null
              ? true
              : false
          }
        >
          הוסף נהג למסלול
        </BlueButton>
      </div>

      {selectedRoute && selectedRoute.value !== 0 && (
        <div className="route-drivers">
          <h3>
            {" "}
            <i
              className="fa fa-motorcycle"
              aria-hidden="true"
              style={{ color: selectedRoute.color }}
            ></i>{" "}
            נהגים חיצונים של מסלול {selectedRoute.label}{" "}
          </h3>
          <DriversList
            extraClass=""
            items={props.routeDrivers}
            isLoading={props.isLoading}
            history={props.history}
            onRemoveDriverFromRouteClick={(driverId) =>
              handleRemoveDriverClick(driverId)
            }
          />
        </div>
      )}
      {selectedRoute && selectedRoute.value === 0 && (
        <div className="external-drivers">
          <h3> כל נהגים חיצונים </h3>
          <DriversList
            extraClass=""
            items={props.drivers}
            isLoading={props.isLoading}
            history={props.history}
            onRemoveDriverFromRouteClick={(driverId) =>
              handleRemoveDriverClick(driverId)
            }
            hideAction={true}
          />
        </div>
      )}

      <NotificationModal
        show={props.showDeleteSuccess}
        type="success"
        title="הצלחה"
        text="הרשאת נהג למסלול הוסרה בהצלחה"
        onOkClick={() => {
          props.closeRouteUserNotification()
          props.onLoadingRouteUser()
          props.fetchRouteDrivers(selectedRoute.value)
        }}
      />
      <NotificationModal
        show={props.showDeleteFail}
        type="error"
        title="הסרה נכשלה"
        text="הסרת נהג מהמסלול נכשלה"
        onOkClick={() => props.closeRouteUserNotification()}
      />
      <NotificationModal
        show={props.showCreateSuccess}
        type="success"
        title="הצלחה"
        text="נהג צורף למסלול בהצלחה"
        onOkClick={() => {
          props.closeRouteUserNotification()
          props.onLoadingRouteUser()
          props.fetchRouteDrivers(selectedRoute.value)
        }}
      />
      <NotificationModal
        show={props.showCreateConflict}
        type="error"
        title="נהג כבר צורף למסלול הנבחר"
        text="צירוף נהג למסלול נכשל"
        onOkClick={() => props.closeRouteUserNotification()}
      />
    </section>
  )
}

const mapStateToProps = ({ routes, routeUser, errors }) => {
  const { unauthorized } = errors
  const { routes: routesOriginal } = routes
  let routesList = [
    {
      value: 0,
      label: "כל המסלולים",
      color: "white",
    },
  ]
  routesList = routesList.concat(routesOriginal)

  const {
    drivers,
    routeDrivers,
    isLoading,
    showDeleteSuccess,
    showDeleteFail,
    showCreateSuccess,
    showCreateConflict,
  } = routeUser

  let driversList = []

  if (!drivers || drivers == []) {
    driversList = []
  } else {
    driversList = drivers.map((driver) => {
      return {
        value: driver.id,
        label: ` ${driver.firstName} ${driver.lastName} ${driver.mobilePhone} `,
        driver: driver,
      }
    })
  }

  return {
    unauthorized,
    routesList,
    drivers,
    routeDrivers,
    driversList,
    isLoading,
    showDeleteSuccess,
    showDeleteFail,
    showCreateSuccess,
    showCreateConflict,
  }
}
export default connect(mapStateToProps, {
  fetchExternalDrivers,
  fetchRouteDrivers,
  addRouteDriver,
  onLoadingRouteUser,
  removeRouteUser,
  closeRouteUserNotification,
})(DriversByRoute)
