import React, { Component } from "react"

import { SmallHeader, BlueButton } from "../../common"
import Modal from "../Modal"
import OrderHistoryList from "../OrderHistoryModal/OrderHistotyList"

import "./style.css"

class OrderHistoryModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      order: null,
      orderId: "",
      historyRecords: [],
    }
  }

  componentDidMount() {
    //const { order, isShow } = this.props;
  }

  render() {
    const {
      isShow,
      order,
      setViewHistory,
      getFormatedDate,
      statuses,
      accountType,
      orderType,
    } = this.props

    if (!isShow) {
      return <noscript />
    }

    const { errMsg } = this.state
    return (
      <Modal extraClass="cror-modal-wrap">
        <div className="cror-modal-container">
          <SmallHeader title={"היסטורית הזמנה מס " + order.orderNumber} />
          <div className="cror-modal-inputs-wrapper">
            <div className="cror-modal-title">
              <div className="title"> היסטורית הזמנה </div>
              <div className="order-number"> מס' {order.orderNumber} </div>
            </div>
            <p className="order-details">
              <div> סוג חשבון: {accountType} </div>
              <div> סוג הזמנה: {orderType} </div>
              <div> מס' חבילה: {order.packageNumber} </div>
              {order?.accountType != 14 ? (
                <div>מס' חיצוני: {order?.externalOrderNumber || "אין"}</div>
              ) : null}
            </p>
            {OrderHistoryList(order.historyRecords, statuses, getFormatedDate)}

            {
              //this.renderErrIfAny(errMsg)
            }

            <div className="cror-modal-status">
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => setViewHistory(false)}
              >
                סגור
              </BlueButton>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default OrderHistoryModal
