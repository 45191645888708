import React, { useRef } from "react"
import { connect } from "react-redux"
import { getOrdersWithStatus } from "../../../../actions"
import "./style.scss"

const ExportOrdersStatus = (props) => {
  const textareaRef = useRef(null)

  const handleSubmit = async () => {
    const inputValue = textareaRef.current.value
    const packageNumbers = inputValue.split("\n").map((number) => number.trim())
    props.getOrdersWithStatus(packageNumbers)
  }

  return (
    <div className="export-orders-status">
      <textarea
        ref={textareaRef}
        rows={5}
        cols={50}
        placeholder="הזן מספרי חבילה כמו בדוגמה:
        XLT123456789
        XLT123456781 
        XLT123456782"
      ></textarea>
      <button onClick={handleSubmit} className="download-file-button">
        לייצא
      </button>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, { getOrdersWithStatus })(
  ExportOrdersStatus
)
