import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';

import OrdersImport from './OrdersImport';
import UsersImport from './UsersImport';
import PricesImport from './PricesImport';

import './style.css';

class ImportScreen extends Component {
    
    componentWillMount() {
        
        const { unauthorized, history } = this.props;
        if( unauthorized){
            history.push('/');
        }
       
    }
    render() {
        const importOrdersLink = `יבוא הזמנות`;
        const importUsersLink = `יבוא משתמשים`;
        const importPricesLink = `יבוא מחירונים`;
        return (
            <section id="imports-section-container">
                <nav id="imports-section-menu">
                    <ul>
                        <li className="imports-section-menu-item"><Link to={`${this.props.match.url}/orders`}>{importOrdersLink}</Link></li>
                        <li className="imports-section-menu-item"><Link to={`${this.props.match.url}/users`}>{importUsersLink}</Link></li>
                        <li className="imports-section-menu-item"><Link to={`${this.props.match.url}/prices`}>{importPricesLink}</Link></li>
                    </ul>
                </nav>
                <main id="imports-section-body">
                    <Route path={`${this.props.match.url}/orders`} render={() => <OrdersImport />} />
                    <Route path={`${this.props.match.url}/users`} render={() => <UsersImport />} />
                    <Route path={`${this.props.match.url}/prices`} render={() => <PricesImport />} />
                </main>
            </section>
        );
    }
}
const mapStateToProps = ({ errors }) => {
    const {unauthorized,} = errors;
    return {
        unauthorized,
    }
}

export default connect(mapStateToProps, {})(ImportScreen);
