import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { SmallHeader, BlueButton } from "../../common"
import {
  getDeliveryDetailsFromIsraelPost,
  clearDeliveryDetails,
  fetchUserByMobile,
} from "../../../actions"
import Modal from "../Modal"
import Select from "react-select"
import "./CreateMailOrderIsraelPost.scss"

const CreateMailOrderIsraelPost = (props) => {
  const [packageNumber, setPackageNumber] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [stationNum, setStationNumber] = useState("")
  const [validationMsg, setValidationMsg] = useState("")
  const packageNumberRef = useRef(null)

  useEffect(() => {
    if (props.israelPostDelivery) {
      const { fName, lName, phone, stationNumber } = props.israelPostDelivery
      if (!fName || !lName) {
        setFirstName("")
        setLastName("")
        setPhoneNumber("")
        setStationNumber("")
      } else {
        setFirstName(fName)
        setLastName(lName)
        setPhoneNumber(phone)
        setStationNumber(stationNumber)
      }
    }
  }, [props.israelPostDelivery])

  useEffect(() => {
    if (props.isCleared) {
      if (packageNumberRef.current) {
        clearState()
        packageNumberRef.current.focus()
      }
    }
  }, [props.isCleared])

  useEffect(() => {
    if (packageNumber && packageNumber.trim().length === 13) {
      props.getDeliveryDetailsFromIsraelPost(packageNumber)
    }
  }, [packageNumber])

  useEffect(() => {
    if (phoneNumber && phoneNumber.trim().length === 10) {
      props.fetchUserByMobile(phoneNumber)
    }
  }, [phoneNumber])

  useEffect(() => {
    if (props.usersListArr.length > 0 && phoneNumber.length === 10) {
      const userData = props.usersListArr[0]
      if (userData) {
        const { firstName, lastName, mobilePhone, stationNumber } = userData
        setFirstName(firstName)
        setLastName(lastName)
        setPhoneNumber(mobilePhone)
        setStationNumber(stationNumber)
      }
    }
  }, [props.usersListArr])

  const onCancelClick = () => {
    props.onCancelClick()
    clearState()
  }
  const onSubmitClick = () => {
    let isValid = validateInput()
    if (isValid) {
      const order = {
        mobilePhone: phoneNumber,
        firstName: firstName,
        lastName: lastName,
        orderType: 2,
        orderStatus: 3,
        stationNumber: stationNum,
        packageNumber: packageNumber,
      }
      props.onSubmitOrderResultClick(order)
    }
  }

  // const onMobileChange = (phoneNumber) => {
  //   props.fetchUserByMobile(phoneNumber)
  // }

  const clearState = () => {
    setPackageNumber("")
    setFirstName("")
    setLastName("")
    setPhoneNumber("")
    setStationNumber("")
    setValidationMsg("")
    props.clearDeliveryDetails()
  }

  const validateInput = () => {
    if (!packageNumber) {
      setValidationMsg("אנא הזן מספר חבילה")
      return false
    }
    if (!firstName) {
      setValidationMsg("הזן שם הלקוח")
      return false
    }

    if (!lastName) {
      setValidationMsg("הזן את שם משפחה")
      return false
    }
    if (!phoneNumber || !phoneNumber.match(/^05\d([-]{0,1})\d{7}$/)) {
      setValidationMsg("מספר טלפון לא תקין")
      return false
    }
    if (!stationNum) {
      setValidationMsg("אנא בחר עמדה")
      return false
    }
    if (!packageNumber.match(/^[a-zA-Z0-9]+$/)) {
      setValidationMsg("מספר חבילה לא תקין")
      return false
    }

    return true
  }

  if (!props.show) {
    return null
  }
  return (
    <Modal extraClass="cror-modal-wrap">
      <div className="cror-modal-container">
        <div className="israel-post-modal">
          <SmallHeader title="הזמנה חבילות" />
          <div className="modal-body">
            <div className="input-wrapper">
              <span className="input-title">מספר חבילה: </span>
              <input
                id="packageNum"
                className="input-text"
                autoFocus
                ref={packageNumberRef}
                value={packageNumber}
                onChange={(e) => {
                  setPackageNumber(e.target.value)
                }}
              ></input>
            </div>
            <div className="input-wrapper">
              <span className="input-title">מספר טלפון: </span>
              <input
                className="input-text"
                value={phoneNumber}
                type="number"
                onChange={(e) => {
                  setPhoneNumber(e.target.value)
                }}
              ></input>
            </div>
            <div className="input-wrapper">
              <span className="input-title">שם פרטי: </span>
              <input
                className="input-text"
                value={firstName}
                maxLength="30"
                onChange={(e) => {
                  setFirstName(e.target.value)
                }}
              ></input>
            </div>
            <div className="input-wrapper">
              <span className="input-title">שם משפחה: </span>
              <input
                className="input-text"
                maxLength="30"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
              ></input>
            </div>
            <div className="input-wrapper">
              <span className="input-title">מספר עמדה: </span>{" "}
              <Select
                className="station-selector"
                key={stationNum}
                placeholder="בחר..."
                defaultValue={props.sortedStationsNames.find(
                  (option) => option.value === stationNum
                )}
                options={props.sortedStationsNames}
                onChange={(e) => {
                  setStationNumber(e.value)
                }}
              />
            </div>
            <div className="validation-msg">
              {validationMsg ? validationMsg : null}
            </div>
            <div className="cror-modal-status">
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => onSubmitClick()}
              >
                אישור
              </BlueButton>
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => onCancelClick()}
              >
                ביטול
              </BlueButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProp = ({
  israelPost,
  stationsList,
  ordersList,
  usersList,
}) => {
  const { israelPostDelivery } = israelPost
  const { sortedStationsNames } = stationsList
  const { isMailOrderCreated, isCleared } = ordersList
  const { usersListArr } = usersList

  return {
    israelPostDelivery,
    sortedStationsNames,
    isMailOrderCreated,
    isCleared,
    usersListArr,
  }
}

export default connect(mapStateToProp, {
  getDeliveryDetailsFromIsraelPost,
  clearDeliveryDetails,
  fetchUserByMobile,
})(CreateMailOrderIsraelPost)
