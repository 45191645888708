import React from 'react';

import './style.css'

const CommentsTab = ({ onInputChange, userData }) => {
    const { comment } = userData;

    return (
        <div>
            <textarea
                className='ucs-input ucs-textarea-classname ucs-input-border'
                placeholder='הערות'
                value={comment}
                onChange={(e) => onInputChange('comment', e.target.value)}
            />
        </div>
    )
}

export default CommentsTab;