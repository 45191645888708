

export const filterFromList = (list, keyword) => {
    const filteredListArr = list.filter(order => {
        const {
            orderNumber, packageNumber, orderFormatDate, orderFromatType, lockerNumber,
            userFormatFullName, mobilePhone, orderTotalPrice, orderFormatIspayed, branchName, email,
            orderFormatStatus, stationNumber,
        } = order;

        return keyword === '' ||
            (email && email.toLowerCase().includes(keyword.toLowerCase())) ||
            orderNumber.toString().includes(keyword) ||
            (packageNumber && packageNumber.includes(keyword)) ||
            orderFormatDate.includes(keyword) ||
            (orderFromatType && orderFromatType.toLowerCase().includes(keyword.toLowerCase())) ||
            lockerNumber.toString().includes(keyword) ||
            (userFormatFullName && userFormatFullName.toLowerCase().includes(keyword.toLowerCase())) ||
            mobilePhone.includes(keyword) ||
            orderTotalPrice.toString().includes(keyword) ||
            (orderFormatIspayed && orderFormatIspayed.toLowerCase().toString().includes(keyword.toLowerCase())) ||
            (branchName && branchName.toLowerCase().includes(keyword.toLowerCase())) ||
            (orderFormatStatus && orderFormatStatus.toLowerCase().toLowerCase().includes(keyword.toLowerCase())) ||
            stationNumber.toString().includes(keyword) ||
            keyword === '';
    });

    return filteredListArr;
};


export const recalcFilteredListOutOfLatestState = state => {
    const {
        ordersListArr, filterBy,
        FiltererActiveTypes, FiltererActiveStatuses, FiltererActiveStations, FiltererIsPaid
    } = state;


    let filteredListArr = ordersListArr.filter(
        ({ orderType, orderStatus, stationNumber, isPayed }) =>
            (FiltererActiveTypes.includes(orderType) || FiltererActiveTypes.length === 0) &&
            (FiltererActiveStatuses.includes(orderStatus) || FiltererActiveStatuses.length === 0) &&
            (FiltererActiveStations.includes(stationNumber) || FiltererActiveStations.length === 0) &&
            (!FiltererIsPaid || (isPayed ? 1 : 2) === FiltererIsPaid)
    );

    if (filterBy && filterBy.length > 0) {
        filteredListArr = filterFromList(filteredListArr, filterBy);
    }
    
    return {
        ...state,
        filteredListArr,
    };

};


export const handleFilteredActiveTypesUpdate = (state, changedStatusIndex) => {
    let { FiltererActiveTypes } = state;

    if (FiltererActiveTypes.includes(changedStatusIndex)) {
        FiltererActiveTypes = FiltererActiveTypes.filter(indx => indx !== changedStatusIndex);
    } else {
        FiltererActiveTypes = [...FiltererActiveTypes, changedStatusIndex];
    }

    return recalcFilteredListOutOfLatestState({ ...state, FiltererActiveTypes });
};

export const handleFilteredActiveStatusesUpdate = (state, changedStatusIndex) => {
    let { FiltererActiveStatuses } = state;

    if (FiltererActiveStatuses.includes(changedStatusIndex)) {
        FiltererActiveStatuses = FiltererActiveStatuses.filter(indx => indx !== changedStatusIndex);
    } else {
        FiltererActiveStatuses = [...FiltererActiveStatuses, changedStatusIndex];
    }

    return recalcFilteredListOutOfLatestState({ ...state, FiltererActiveStatuses });
};

export const handleFilteredActiveStationsUpdate = (state, changedStationNumber) => {
    let { FiltererActiveStations } = state;

    if (FiltererActiveStations.includes(changedStationNumber)) {
        FiltererActiveStations = FiltererActiveStations.filter(stationNumber => stationNumber !== changedStationNumber);
    } else {
        FiltererActiveStations = [...FiltererActiveStations, changedStationNumber];
    }

    return recalcFilteredListOutOfLatestState({ ...state, FiltererActiveStations });
};

export const hanldeFilterByIsPaidUpdate = (state, isPaidState) => {

    const FiltererIsPaid = isPaidState;

    return recalcFilteredListOutOfLatestState({ ...state, FiltererIsPaid });
};



