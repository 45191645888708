import React, { Component } from "react"
import { connect } from "react-redux"

import { SmallHeader, BlueButton, Spinner } from "../../common"
import Modal from "../Modal"

import "./style.scss"

const title = "בחירת שק לקליטת חבילות במחסן"

class SelectSackModal extends Component {
  constructor(props) {
    super(props)
    this.sackBarcodeInput = React.createRef()
    this.state = {
      currentSackBarcode: null,
    }
  }

  onScanSackBarcode = (barcode) => {
    if (barcode && barcode.length >= 8) {
      const sack = this.props.activeSacksList.find(
        (elm) => elm.barcode === barcode
      )
      if (!sack) {
        return
      }
      this.props.onSelect(sack)
    }
  }

  render() {
    const { show } = this.props

    if (!show) {
      return <noscript />
    }

    const { activeSacksList, isLoading } = this.props

    if (isLoading) {
      return (
        <Modal extraClass="cror-modal-wrap">
          <div className="cror-modal-container select-sack-modal ">
            <SmallHeader title={title} />
            <div className="cror-modal-inputs-wrapper">
              <div className="content-loading">
                <Spinner zoom={1} />
              </div>
              <div className="cror-modal-status">
                <BlueButton
                  extraClass="cror-modal-button"
                  onClick={(e) => {
                    this.props.onClose()
                  }}
                >
                  סגור
                </BlueButton>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

    return (
      <Modal extraClass="cror-modal-wrap">
        <div className="cror-modal-container select-sack-modal ">
          <SmallHeader title={title} />
          <div className="cror-modal-inputs-wrapper">
            <div className="cror-modal-title">
              <div className="title"> בחר/סרוק שק קיים או צור שק חדש </div>
            </div>
            <BlueButton
              extraClass={"btn-table"}
              onClick={() => {
                this.props.onCreateNewOrderSack()
              }}
            >
              שק חדש של אקסלות
            </BlueButton>
            <div className="cror-modal-title">
              <div className="title">רשימת שקים:</div>
            </div>
            <input
              autoFocus
              ref={this.sackBarcodeInput}
              placeholder="חפש שק"
              value={this.state.currentSackBarcode}
              onChange={(evt) => this.onScanSackBarcode(evt.target.value)}
            ></input>
            <div className="sack-list-wrapper">
              {activeSacksList &&
                activeSacksList.map((sack) => {
                  return (
                    <BlueButton
                      extraClass={"btn-table"}
                      onClick={() => this.props.onSelect(sack)}
                    >
                      {sack.ordersCount > 0 ? (
                        <div className="orders-count">{sack.ordersCount}</div>
                      ) : null}
                      שק {sack.barcode}
                    </BlueButton>
                  )
                })}
            </div>
            <div className="cror-modal-status">
              <BlueButton
                extraClass="cror-modal-button"
                onClick={() => this.props.onClose()}
              >
                סגור
              </BlueButton>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProp = ({ orderSack }) => {
  const { activeSacksList, sacksList, createdSack, isLoading, isError } =
    orderSack
  return {
    activeSacksList,
    sacksList,
    createdSack,
    isLoading,
    isError,
  }
}

export default connect(mapStateToProp, {})(SelectSackModal)
