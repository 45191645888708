import React from "react"

import "./style.css"
import { Title } from "../Title"

const CheckboxAndUpperLabel = ({ children, ...props }) => {
  return (
    <div className="chkbx-and-upper-label-wrapper">
      <Title extraClass="chkbx-and-upper-label-title">{children}</Title>
      <input
        type="checkbox"
        className="chkbx-and-upper-label-checkbox"
        {...props}
      />
    </div>
  )
}

export { CheckboxAndUpperLabel }
