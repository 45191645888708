import React from "react"

import Header from "../../../../common/List/Header"
import StationItem from "./StationItem"
import { H1, Spinner } from "../../../../common"

import "./style.css"

const stationHeaders = [
  "מחובר",
  "מזהה",
  "שם סניף",
  "כתובת",
  "מספר לוקרים",
  "תאים ריקים",
  "תאים שבורים",
  "גרסה $",
  "מסלול הפצה",
  "תאים",
  "היסטוריה",
  "חישנים",
  "סטטוס",
  "",
  "",
]
const stationItemMap = [
  "isAlive",
  "stationNumber",
  "branchName",
  "branchAddress",
  "lockersNumber",
  "emptyLockersNum",
  "brokenLockersNum",
  "stationPaymentVersion",
  "routeName",
  "cells",
  "cellsLog",
  "cellSensorInfra",
  "lockersStatus",
  "sendSms",
  "edit",
]
const stationGravity = { 0: 1, 7: 1, 9: 0.5, 10: 0.5, 11: 0.5, 12: 0.5 }

const StationsList = ({
  extraClass,
  items,
  onItemEditBtnClick,
  onSendSmsBtnClick,
  onCellsBtnClick,
  onLockersHistoryBtnClick,
  onLockersSensorInfraBtnClick,
  onLockersStatusBtnClick,
  isError,
  isLoading,
}) => {
  return (
    <div className={`stations-list list-wrapper ${extraClass}`}>
      <Header titles={stationHeaders} gravity={stationGravity} />
      <div className="list-items-wrapper">
        {renderItems(
          items,
          stationGravity,
          stationItemMap,
          onItemEditBtnClick,
          onSendSmsBtnClick,
          onCellsBtnClick,
          onLockersHistoryBtnClick,
          onLockersSensorInfraBtnClick,
          onLockersStatusBtnClick,
          isError,
          isLoading
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  stationGravity,
  stationItemMap,
  onItemEditBtnClick,
  onSendSmsBtnClick,
  onCellsBtnClick,
  onLockersHistoryBtnClick,
  onLockersSensorInfraBtnClick,
  onLockersStatusBtnClick,
  isError,
  isLoading
) => {
  if (isError) {
    return (
      <H1 className="not-valid-search" title="אנא בדוק את הערך של שדה חיפוש" />
    )
  }
  if (isLoading) {
    return (
      <div className="station-editor-spinner-wrapper">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (!items || items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }

  return items.map((itm, index) => {
    return (
      <StationItem
        key={index}
        indexNo={index}
        item={itm}
        itemMap={stationItemMap}
        gravity={stationGravity}
        isLast={index === items.length - 1}
        onEditBtnClick={onItemEditBtnClick}
        onSendSmsBtnClick={onSendSmsBtnClick}
        onCellsBtnClick={onCellsBtnClick}
        onLockersHistoryBtnClick={onLockersHistoryBtnClick}
        onLockersSensorInfraBtnClick={onLockersSensorInfraBtnClick}
        onLockersStatusBtnClick={onLockersStatusBtnClick}
      />
    )
  })
}

export default StationsList
