import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { BlueButton, Spinner } from "../../../../common"
import { createStationTask, clearStationTasks } from "../../../../../actions"
import Select from "react-select"
import NotificationModal from "../../../../Modals/NotificationModal"

function NewStationTask(props) {
  const {
    createStationTaskSuccess,
    createStationTaskFail,
    stationsItemSelectList,
    isLoading,
  } = props
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [selectedStation, setSelectedStation] = useState(null)
  const [formData, setFormData] = useState({
    isComplete: false,
    isMustVisit: false,
    taskDetails: "",
    taskDetails_en: "",
    stationId: "",
  })

  useEffect(() => {
    if (createStationTaskSuccess) {
      setFormData({
        isComplete: false,
        isMustVisit: false,
        taskDetails: "",
        taskDetails_en: "",
        stationId: "",
      })
      setSelectedStation(null)
      setFormSubmitted(false)
    }
  }, [createStationTaskSuccess])

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (
      !formData.taskDetails ||
      !formData.taskDetails_en ||
      !formData.stationId
    ) {
      setFormSubmitted(true)
      return
    }
    props.createStationTask(formData)
  }

  const handleSelectChange = (selectedOption) => {
    setSelectedStation(selectedOption)
    setFormData({
      ...formData,
      stationId: selectedOption.stationId,
    })
  }

  return (
    <section className="newStationTask">
      <h2> יצירת משימה</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="input-holder">
            <label>הושלם על ידי נהג:</label>
            <input
              type="checkbox"
              name="isComplete"
              checked={formData.isComplete}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="input-holder">
            <label>האם חייב לבקר:</label>
            <input
              type="checkbox"
              name="isMustVisit"
              checked={formData.isMustVisit}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="input-holder">
            <label>פרטי המשימה:</label>
            <textarea
              name="taskDetails"
              rows={4}
              value={formData.taskDetails}
              onChange={handleInputChange}
            />
          </div>
          {formSubmitted && !formData.taskDetails && (
            <div className="error-message">שדה חובה</div>
          )}
        </div>
        <div className="form-group">
          <div className="input-holder">
            <label>פרטי המשימה (English):</label>
            <textarea
              name="taskDetails_en"
              rows={4}
              value={formData.taskDetails_en}
              onChange={handleInputChange}
            />
          </div>
          {formSubmitted && !formData.taskDetails_en && (
            <div className="error-message">שדה חובה</div>
          )}
        </div>
        <div className="form-group">
          <div className="input-holder">
            <label>עמדה:</label>
            <Select
              className="select-station"
              name="stationId"
              key={stationsItemSelectList?.length}
              placeholder={"בחר עמדה"}
              options={stationsItemSelectList}
              value={selectedStation}
              onChange={handleSelectChange}
            />
          </div>
          {formSubmitted && !formData.stationId && (
            <div className="error-message">שדה חובה</div>
          )}
        </div>

        <div className="btn-holder">
          {isLoading ? (
            <Spinner zoom={0.5} />
          ) : (
            <BlueButton type="submit">שמור</BlueButton>
          )}
        </div>
      </form>
      <NotificationModal
        show={createStationTaskSuccess}
        type="success"
        title="הצלחה"
        text="משימה נוצרה בהצלחה"
        onOkClick={() => props.clearStationTasks()}
      />
      <NotificationModal
        show={createStationTaskFail}
        type="error"
        title="כישלון"
        text="יצירת משימה נכשלה"
        onOkClick={() => props.clearStationTasks()}
      />
    </section>
  )
}

const mapStateToProps = ({ stationTasks, stationsList }) => {
  const { isLoading, createStationTaskSuccess, createStationTaskFail } =
    stationTasks
  const { stationsItemSelectList } = stationsList

  return {
    isLoading,
    stationsItemSelectList,
    createStationTaskSuccess,
    createStationTaskFail,
  }
}
export default connect(mapStateToProps, {
  createStationTask,
  clearStationTasks,
})(NewStationTask)
