import React, { Component } from "react"
import { connect } from "react-redux"
import {
  LabeledTextInput,
  Spinner,
  ValidationMessage,
  LabeledSelect,
  SelectAutocomplete,
} from "../../../common"
import {
  isValidField,
  FIELD_TYPE_ENUM,
} from "../../../../utils/FieldsValidation"
import NotificationModal from "../../../Modals/NotificationModal"
import {
  cleanCityForm,
  editCityForm,
  updateCity,
  showLoading,
  getDistricts,
  clearCitiesList,
} from "../../../../actions"
import "./style.scss"

class EditCity extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditCityButtonDisabled: false,
      cityName: "",
      cityName_en: "",
      cityName_ru: "",
      districtId: "",
      defaultDistrict: "",
      validField: {
        cityName_ru: {
          type: "text",
          isRequired: false,
          isChecked: false,
          isValid: false,
        },
        districtId: {
          type: "number",
          isRequired: true,
          isChecked: false,
          isValid: false,
        },
      },
    }
  }

  componentWillMount() {
    const { unauthorized, history, city } = this.props
    if (unauthorized) {
      history.push("/")
    }
    this.props.getDistricts()
    const cityForm = {
      cityId: city.cityId,
      districtId: city.district.districtId,
      cityName_ru: city.cityName_ru,
    }
    this.props.editCityForm(cityForm)
  }
  componentWillUnmount() {
    this.setState({
      isEditCityButtonDisabled: false,
      cityName: "",
      cityName_en: "",
      cityName_ru: "",
      districtId: "",
    })
    this.props.clearCitiesList()
    this.props.cleanCityForm()
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history, districts, city } = newProps
    if (unauthorized) {
      history.push("/")
    }
    // if (districts.length > 0 && city.districtId) {
    //   const defaultDistrict = this.defaultDistrictValue()
    //   this.setState({ defaultDistrict: defaultDistrict })
    // }
  }

  defaultDistrictValue() {
    let { districts, city } = this.props
    return districts.find((o) => o.value == city.districtId)
  }

  handleFieldChange(inputName, inputValue) {
    const { city } = this.props

    let _city = {
      ...city,
    }

    _city = {
      ..._city,
      [inputName]: inputValue,
    }

    this.props.editCityForm(_city)
    this.runValidation(inputName, inputValue)
  }

  onSubmitButtonClick() {
    const { cityName_ru, districtId } = this.props.city
    const { city } = this.props
    let newCity = {
      cityId: city.cityId,
      cityName_ru,
      districtId,
    }
    if (!this.isValidCity(newCity)) {
      return
    }
    this.setState({ isEditCityButtonDisabled: true })
    this.props.showLoading()
    this.props.updateCity(newCity)
  }

  isValidCity(newCity) {
    const fieldsList = ["cityName_ru", "districtId"]
    this.runValidationList(fieldsList, newCity)

    const { validField } = this.state
    let res = fieldsList.map((fieldName, i) => {
      if (!validField[fieldName].isValid) {
        console.log(fieldName + " is not valid")
        return false
      }
      return true
    })

    return res.filter((i) => i === true).length === fieldsList.length
  }

  runValidationList(fieldsList, supplier) {
    fieldsList.map((fieldName, i) =>
      this.runValidation(fieldName, supplier[fieldName])
    )
  }

  runValidation(inputName, inputValue) {
    let { validField } = this.state
    let field = validField[inputName]

    if (!field) return
    field.isChecked = true

    field.isValid = isValidField(
      inputValue,
      field.type,
      field.isRequired,
      field.compareMinValue,
      field.compareMaxValue
    )
    validField[inputName] = field

    this.setState({
      ...this.state,
      validField,
    })
  }

  onFieldFocus(inputName) {
    let { validField } = this.state
    let field = validField[inputName]
    if (!field) return
    field.isChecked = false
    validField[inputName] = field
    this.setState({
      ...this.state,
      validField,
    })
  }

  closeForm() {
    this.props.history.push("/CitiesScreen")
  }

  render() {
    const {
      city,
      isLoading,
      isNewCityCreated,
      isShowError,
      errorCode,
      districts,
      isUpdated,
      isUpdateFailed,
    } = this.props
    const fieldTypeList = FIELD_TYPE_ENUM
    const { cityName, cityName_en, cityName_ru, districtId } = city
    const { validField, isEditCityButtonDisabled, defaultDistrict } = this.state

    if (isLoading) {
      return (
        <div className="new-city spinner-wrapper">
          <Spinner zoom={2} />
        </div>
      )
    }

    return (
      <div className="new-city">
        <h1>עריכת עיר</h1>
        <div>
          <div className="city-name-selector">
            <LabeledTextInput
              labelText="שם העיר"
              value={cityName}
              inputSize={40}
              disabled
            />
          </div>

          <div className="section-info-row">
            <LabeledTextInput
              labelText="שם העיר באנגלית"
              value={cityName_en}
              inputSize={40}
              disabled
            />
          </div>

          <div className="section-info-row">
            <LabeledTextInput
              labelText="שם העיר ברוסית"
              value={cityName_ru}
              inputSize={40}
              onChange={(e) => {
                this.handleFieldChange("cityName_ru", e.target.value)
              }}
              onFocus={(e) => {
                this.onFieldFocus("cityName_ru")
              }}
              onBlur={(e) => {
                this.runValidation("cityName_ru", cityName_ru)
              }}
            />
            <ValidationMessage
              isShow={validField.cityName_ru.isChecked}
              isShowOk={false}
              isShowError={!validField.cityName_ru.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.cityName_ru.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>
          <div className="district-selector">
            <div>אזור</div>
            <SelectAutocomplete
              defaultValue={this.defaultDistrictValue()}
              selectedVal={districtId}
              placeholder="בחר אזור"
              items={districts}
              onChangeHandler={(dis) => {
                this.handleFieldChange("districtId", dis.value)
              }}
            />
            <ValidationMessage
              isShow={validField.districtId.isChecked}
              isShowOk={false}
              isShowError={!validField.districtId.isValid}
              className="field-valid"
              errorMessage={
                fieldTypeList[validField.districtId.type].errMessage +
                " " +
                fieldTypeList["required"].errMessage
              }
            />
          </div>

          <button
            type="submit"
            className="ss-button"
            onClick={(e) => this.onSubmitButtonClick(e)}
            disabled={isEditCityButtonDisabled}
          >
            צור עיר
          </button>
        </div>

        <NotificationModal
          show={isUpdated}
          title="עדכון עיר"
          text="העיר עודכנה בהצלחה"
          onOkClick={() => this.closeForm()}
        />

        <NotificationModal
          show={isUpdateFailed}
          title="עדכון עיר"
          text={"כשלון בעדכון עיר"}
          onOkClick={() => this.closeForm()}
        />
      </div>
    )
  }
}

const mapStateToProp = ({ cities, errors }) => {
  const {
    city,
    isLoading,
    isNewCityCreated,
    isShowError,
    errorCode,
    districts,
    isUpdated,
    isUpdateFailed,
  } = cities
  const { unauthorized } = errors
  return {
    city,
    isLoading,
    isNewCityCreated,
    unauthorized,
    isShowError,
    errorCode,
    districts,
    isUpdated,
    isUpdateFailed,
  }
}

export default connect(mapStateToProp, {
  cleanCityForm,
  editCityForm,
  showLoading,
  getDistricts,
  updateCity,
  clearCitiesList,
})(EditCity)
