import React from "react"
import { getFormatedDate } from "../../../../../../utils/FormatedDate"
import { lockerStatusToHebrew } from "../../../../../../utils/EnumsAndConsts"
import "./style.css"

const LockerInfraSensorItem = ({
  item,
  itemMap,
  gravity,
  isLast,
  lockerTypes,
  lockerStatuses,
}) => {
  const itemClassName = `item-wrapper ${isLast ? "item-last" : ""}`

  return (
    <div className={itemClassName}>
      {itemMap.map((itmMap, index) => {
        const flex = gravity[`${index}`] || 1

        switch (itmMap) {
          case "lockerStatus":
            const lockerStatus = lockerStatuses[item[itmMap]]
            return (
              <div style={{ flex }} key={index}>
                {lockerStatusToHebrew(lockerStatus)}
              </div>
            )
          case "orderNumber":
            const orderNumber = item[itmMap] === 0 ? "" : item[itmMap]
            return (
              <div style={{ flex }} key={index}>
                {orderNumber}
              </div>
            )
          case "createdDate":
            const formatedDate = getFormatedDate(item[itmMap])
            return (
              <div style={{ flex }} key={index}>
                {formatedDate}
              </div>
            )
          case "lockerName":
            return (
              <div style={{ flex }} key={index}>
                <div className="circle locker-name">{item[itmMap]}</div>
              </div>
            )

          case "createdByName":
            return (
              <div style={{ flex }} key={index}>
                <div className="">{item[itmMap]}</div>
              </div>
            )
          case "createdByRole":
            return (
              <div style={{ flex }} key={index}>
                <div className="">{item[itmMap]}</div>
              </div>
            )
          case "isOccupied":
            if (item[itmMap] === true) {
              return (
                <div style={{ flex }} key={index}>
                  <div className="is-occuppied">מלא</div>
                </div>
              )
            } else {
              return (
                <div style={{ flex }} key={index}>
                  <div className="is-occuppied"> ריק </div>
                </div>
              )
            }

          default:
            let itemContent = item[itmMap]
            return (
              <div style={{ flex }} key={index}>
                {itemContent}
              </div>
            )
        }
      })}
    </div>
  )
}

export default LockerInfraSensorItem
