import axios from "axios";
import api from "../api";

import {
  ON_GET_ORDERS_STATUSES_SUCSESS,
  ON_GET_ORDERS_STATUSES_FAIL,
  CLEAR_ORDER_STATUSES,
} from "./types";

export const fetchAllOrderStatuses = () => {
  console.log("fetchAllOrderStatuses");
  return (dispath) => {
    axios
      .get(api.getOrderStatuses)
      .then((res) => {
        dispath({
          type: ON_GET_ORDERS_STATUSES_SUCSESS,
          payload: res.data,
        });
        // console.log('SUCCESS ON GET ORDER STATUSES');
      })
      .catch((err) => {
        dispath({
          type: ON_GET_ORDERS_STATUSES_FAIL,
          payload: err,
        });
        console.log("FAIL ON GET ORDER STATUSES", err);
      });
  };
};

export const clearOrderStatuses = () => {
  return {
    type: CLEAR_ORDER_STATUSES,
  };
};
