import React, { useState } from "react"

import "./style.css"

const EditableTextarea = (instance) => {
  const {
    cell: { value: initialValue },
    row,
    column: { id },
    updateData,
  } = instance

  const [value, setValue] = useState(initialValue)
  const [extraClass, setExtraClass] = useState("")

  const className = "table-editable-text-area"

  const onChange = (event) => {
    event.persist()
    setValue(event.target.value)
    setExtraClass("highlighted-table-editable-text-area")
  }

  const onBlur = (event) => {
    event.persist()
    updateData(row.index, id, value)
  }

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  // SG: There is a bug with textarea so replaced it with input
  //   return (
  //     <textarea
  //       className={`${className} ${extraClass}`}
  //       rows={2}
  //       wrap="soft"
  //       defaultValue={value}
  //       onChange={onChange}
  //       onBlur={(event) => onBlur(event)}
  //     />
  //   );
  return (
    <input
      className={`${className} ${extraClass}`}
      onChange={(evt) => onChange(evt)}
      value={value}
      onBlur={(event) => onBlur(event)}
    ></input>
  )
}

export default EditableTextarea
