import {
  ON_ERROR_MODAL_CLOSE,
  ON_NO_RESPONSE_RECEIVED_ERROR,
  ON_UNHANDLED_REQUEST_ERROR,
  ON_401_UNAOUTHORIZED_REQUEST_ERROR,
  ON_USER_SIGN_IN_SUCCESS,
  UNAUTHORIZED,
  RESET_ERROR,
  ON_USER_SIGN_IN_FAIL,
  ON_USER_SIGN_IN_RESET_ATTEMPTS,
  SET_AUTHORIZATION_MANUAL,
} from "../actions/types"

const INIT_ERROR_STATE = {
  error: null,
  message: "",
}
const INIT_STATE = {
  error: null,
  message: "",
  unauthorized: true,
}

const ErrorsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_ERROR_MODAL_CLOSE:
      return {
        ...state,
        error: null,
        message: "",
      }
    case ON_NO_RESPONSE_RECEIVED_ERROR:
      return {
        ...state,
        error: action.error,
        message: action.message,
      }
    case ON_UNHANDLED_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        message: action.message,
      }
    case ON_401_UNAOUTHORIZED_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        message: action.message,
      }
    case ON_USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        unauthorized: false,
      }
    case UNAUTHORIZED:
      return {
        ...state,
        unauthorized: true,
      }
    case SET_AUTHORIZATION_MANUAL:
      return {
        ...state,
        unauthorized: false,
      }
    case RESET_ERROR:
      return {
        ...state,
        ...INIT_ERROR_STATE,
      }
    case ON_USER_SIGN_IN_FAIL:
    case ON_USER_SIGN_IN_RESET_ATTEMPTS:
      return {
        ...INIT_STATE,
      }
    default:
      return state
  }
}

export default ErrorsReducer
