import React from "react"
import "./style.scss"
import { Route, Link } from "react-router-dom"

import DriversByRoute from "../DriversByRoute"

const ExternalDriversScreen = (props) => {
  return (
    <section className="stationTasksScreen">
      <nav>
        <ul>
          <li>
            <Link to={`${props.match.url}`}>הרשאות נהגים חיצונים</Link>
          </li>
        </ul>
      </nav>
      <main>
        <Route exact path={`${props.match.path}`} component={DriversByRoute} />
      </main>
    </section>
  )
}

export default ExternalDriversScreen
