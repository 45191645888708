import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "./StationTasksList.scss"
import {
  fetchStationTasks,
  selectTaskForEdit,
  toggleIsActiveTask,
  clearStationTasks,
} from "../../../../../actions"
import ReactPaginate from "react-paginate"
import { Spinner } from "../../../../common"
import { getFormatedDate } from "../../../../../utils"
import StationHistory from "../StationTaskHistory/StationTaskHistory"
import StationTaskToggle from "../StationTaskToggle/StationTaskToggle"
import Select from "react-select"
import NotificationModal from "../../../../Modals/NotificationModal"
import { BlueButton } from "../../../../common/BlueButton"
import exportFromJSON from "export-from-json"

const StationTasksList = (props) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [searchIsComplete, setSearchIsComplete] = useState("")
  const [searchIsActive, setSearchIsActive] = useState("")
  const [selectedTask, setSelectedTask] = useState(null)
  const [isShowHistory, setIsShowHistory] = useState(false)
  const [isShowCloseTask, setIsShowCloseTask] = useState(false)
  const tasksPerPage = 12

  const filterTasks = () => {
    if (searchText === "" && searchIsComplete === "" && searchIsActive === "") {
      return props.stationTasksList
    }

    const searchTextLower = searchText.toLowerCase()

    const filtered = props.stationTasksList.filter(
      (task) =>
        (task.stationNumber &&
          task.stationNumber
            .toString()
            .toLowerCase()
            .includes(searchTextLower)) ||
        (task.branchName &&
          task.branchName.toString().toLowerCase().includes(searchTextLower)) ||
        (task.routeName &&
          task.routeName.toString().includes(searchTextLower)) ||
        (task.createdByName &&
          task.createdByName
            .toString()
            .toLowerCase()
            .includes(searchTextLower)) ||
        (task.createdByPhone &&
          task.createdByPhone.toString().includes(searchTextLower)) ||
        (task.taskNumber &&
          task.taskNumber.toString().includes(searchTextLower))
    )
    const filtereWithStatus = filterCompletionStatus(filtered)
    const filtereWithStatusAndIsActive = filterIsActive(filtereWithStatus)
    return filtereWithStatusAndIsActive
  }

  const filterCompletionStatus = (tasks) => {
    if (searchIsComplete === "") {
      return tasks
    }
    return tasks.filter((task) => task.isComplete == searchIsComplete)
  }

  const filterIsActive = (tasks) => {
    if (searchIsActive === "") {
      return tasks
    }
    return tasks.filter((task) => task.isActive == searchIsActive)
  }

  const getDisplayedTasks = () => {
    const filtered = filterTasks()
    const offset = currentPage * tasksPerPage
    return filtered.slice(offset, offset + tasksPerPage)
  }

  useEffect(() => {
    props.fetchStationTasks()
  }, [])

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected)
  }

  const handleEdit = (task) => {
    props.selectTaskForEdit(task)
    props.history.push(`${props.match.path}/edit/`)
  }

  const handleHistory = (task) => {
    setSelectedTask(task)
    setIsShowHistory(true)
  }

  const handleCloseTask = (task) => {
    setSelectedTask(task)
    setIsShowCloseTask(true)
  }

  const onClickToggleTask = (notes) => {
    setIsShowCloseTask(false)
    props.toggleIsActiveTask({
      isActive: !selectedTask.isActive,
      taskId: selectedTask.id,
      notes: notes,
    })
  }

  const onCloseTaskStatusUpdate = (notes) => {
    props.clearStationTasks()
    props.fetchStationTasks()
  }

  const refreshTable = () => {
    props.fetchStationTasks()
  }

  const currentTasks = getDisplayedTasks()

  const statusList = [
    { value: "", label: "הכל" },
    { value: true, label: "בוצע" },
    { value: false, label: "לא בוצע" },
  ]

  const activeList = [
    { value: "", label: "כל המשימות" },
    { value: true, label: "פתוחות" },
    { value: false, label: "סגורות" },
  ]

  const exportTasksToExcel = () => {
    const filteredTasks = filterTasks()
    const data = filteredTasks.map((task) => {
      console.log(task.taskNumber)
      return {
        "מס' משימה": task.taskNumber,
        "מס' עמדה": task.stationNumber,
        "פרטי המשימה": task.taskDetails,
        "פרטי המשימה (אנגלית)": task.taskDetails_en,
        "תאריך יצירה": getFormatedDate(task.createdDate),
      }
    })
    const fileName = "Stationtasks"
    const exportType = exportFromJSON.types.csv

    exportFromJSON({
      data,
      fileName,
      exportType,
      withBOM: "\uFEFF",
    })
  }

  return (
    <section className="stationTasksList">
      {props.isLoading ? <Spinner zoom={1} /> : null}
      {props.stationTasksList?.length > 0 ? (
        <div className="table-wrapper">
          <div className="title">רשימת משימות</div>
          <div className="search-bar">
            <input
              className="search-input"
              type="text"
              placeholder="חיפוש"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
                setCurrentPage(0)
              }}
            />
            <Select
              className="select-status"
              placeholder={"האם בוצע"}
              isClearable={false}
              options={statusList}
              onChange={(e) => setSearchIsComplete(e.value)}
            />
            <Select
              className="select-status"
              placeholder={"סטטוס משימה"}
              isClearable={false}
              options={activeList}
              onChange={(e) => setSearchIsActive(e.value)}
            />
            <BlueButton
              show={true}
              extraClass="btn-refresh"
              onClick={(e) => refreshTable()}
            >
              רענן טבלה
            </BlueButton>

            <BlueButton
              extraClass="btn-export"
              onClick={() => exportTasksToExcel()}
            >
              יצאו לקובץ Excel
            </BlueButton>
          </div>
          <div className="table-container">
            <table className="task-table">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>מספר משימה</th>
                  <th>משימה סגורה</th>
                  <th>נהג ביצע</th>
                  <th>חייב לבקר</th>
                  <th>עמדה</th>
                  <th>מסלול</th>
                  <th>פרטי המשימה</th>
                  <th>Task details</th>
                  <th>תאריך משימה</th>
                  <th>נוצר על ידי</th>
                </tr>
              </thead>
              <tbody>
                {currentTasks?.map((task) => (
                  <tr key={task.id}>
                    <td onClick={() => handleCloseTask(task)}>
                      <i class="fas fa-check-double"></i>
                    </td>
                    <td onClick={() => handleEdit(task)}>
                      <i class="fas fa-edit"></i>
                    </td>
                    <td onClick={() => handleHistory(task)}>
                      <i class="fas fa-history"></i>
                    </td>
                    <td>{task.taskNumber}</td>
                    <td>
                      {task.isActive ? (
                        <i class="fas fa-times negative"></i>
                      ) : (
                        <i class="fas fa-check positive "></i>
                      )}
                    </td>
                    <td>
                      {task.isComplete ? (
                        <i class="fas fa-check positive"></i>
                      ) : (
                        <i class="fas fa-times negative"></i>
                      )}
                    </td>

                    <td>
                      {task.isMustVisit ? (
                        <i class="fas fa-check positive"></i>
                      ) : (
                        <i class="fas fa-times negative"></i>
                      )}
                    </td>
                    <td>{task.stationNumber + " - " + task.branchName}</td>
                    <td>{task.routeName}</td>
                    <td>{task.taskDetails}</td>
                    <td>{task.taskDetails_en}</td>
                    <td>{getFormatedDate(task.createdDate)}</td>
                    <td>{task.createdByName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={"קודם"}
            nextLabel={"הבא"}
            pageCount={Math.ceil(filterTasks().length / tasksPerPage)}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      ) : (
        <div className="noTasks">אין משימות לתצוגה</div>
      )}
      {isShowHistory && (
        <StationHistory
          stationTask={selectedTask}
          closeClick={() => setIsShowHistory(false)}
        />
      )}
      {isShowCloseTask && (
        <StationTaskToggle
          stationTask={selectedTask}
          toggleTaskClick={(notes) => onClickToggleTask(notes)}
          close={() => setIsShowCloseTask(false)}
        />
      )}

      <NotificationModal
        show={props.toggleIsActiveTaskSuccess}
        type="success"
        title="עדכון משימה"
        text="משימה עודכנה בהצלחה"
        onOkClick={() => onCloseTaskStatusUpdate()}
      />
      <NotificationModal
        show={props.toggleIsActiveTaskFail}
        type="error"
        title="עדכון משימה"
        text="עדכון משימה נכשל"
        onOkClick={() => onCloseTaskStatusUpdate()}
      />
    </section>
  )
}

const mapStateToProps = ({ stationTasks }) => {
  const {
    stationTasksList,
    toggleIsActiveTaskSuccess,
    toggleIsActiveTaskFail,
  } = stationTasks

  return {
    stationTasksList,
    toggleIsActiveTaskSuccess,
    toggleIsActiveTaskFail,
  }
}
export default connect(mapStateToProps, {
  fetchStationTasks,
  selectTaskForEdit,
  toggleIsActiveTask,
  clearStationTasks,
})(StationTasksList)
