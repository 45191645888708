import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { BlueButton } from "../../../../../common"
import {
  searchSackByBarcode,
  searchSackByOrderNumber,
  searchSacksByAccount,
  searchSacksByDeliverPhone,
  clearSearchSack,
  fetchOrderTypes,
  fetchAccountTypes,
  fetchSackOrders,
  clearGetSackOrders,
  clearOrdersSacks,
} from "../../../../../../actions"
import CertificateOrdersTable from "../../../ShippingCertificate/CertificateOrdersTable/CertificateOrdersTable.js"
import { getFormatedDate } from "../../../../../../utils/FormatedDate"
import {
  getValEnumFromIndex,
  convertToSelectList,
} from "../../../../../../utils"
import "./SacksHistory.scss"

const SacksHistory = (props) => {
  const barcodeSearchTermRef = useRef("")
  const orderNumberSearchTermRef = useRef("")
  const phoneSearchTermRef = useRef("")
  const [accountTypesList, setAccountTypesList] = useState({})
  const [selectedAccount, setSelectedAccount] = useState(null)

  useEffect(() => {
    props.fetchOrderTypes()
    props.fetchAccountTypes()
    return () => {
      props.clearOrdersSacks()
      props.clearSearchSack()
    }
  }, [])

  useEffect(() => {
    if (props.accountTypes) {
      const accountTypes = convertToSelectList(props.accountTypes)
      setAccountTypesList(accountTypes)
    }
  }, [props.accountTypes])

  useEffect(() => {
    if (props.searchSackResult) {
      props.fetchSackOrders(props.searchSackResult.id)
    }
  }, [props.searchSackSuccess])

  useEffect(() => {
    if (props.searchSacksListResult) {
    }
  }, [props.searchSacksListSuccess])

  const handleBarcodeSearch = () => {
    props.clearGetSackOrders()
    props.clearSearchSack()
    if (barcodeSearchTermRef.current.value != "") {
      props.searchSackByBarcode(barcodeSearchTermRef.current.value)
    }
  }

  const handleOrderNumberSearch = () => {
    props.clearGetSackOrders()
    props.clearSearchSack()
    if (orderNumberSearchTermRef.current.value != "") {
      props.searchSackByOrderNumber(orderNumberSearchTermRef.current.value)
    }
  }

  const handlePhoneSearch = () => {
    props.clearGetSackOrders()
    props.clearSearchSack()
    if (phoneSearchTermRef.current.value != "") {
      props.searchSacksByDeliverPhone(phoneSearchTermRef.current.value)
    }
  }

  const onAccountChange = (account) => {
    setSelectedAccount(account)
  }

  const showNoDataMsg = () => {
    if (props.searchSackSuccess || props.searchSacksListSuccess) {
      if (
        props.searchSackResult == "" ||
        props.searchSacksListResult?.length == 0
      ) {
        return <div className="no-data">לא נמצאו שקים</div>
      }
    }
  }

  return (
    <div className="SacksHistory">
      <h1>חיפוש שקים</h1>
      <div className="search-sacks no-print">
        <div className="search-input">
          <input
            type="text"
            placeholder="חפש שק לפי ברקוד"
            ref={barcodeSearchTermRef}
            className="input-field"
          />
          <BlueButton extraClass="search-button" onClick={handleBarcodeSearch}>
            חפש
          </BlueButton>
        </div>
        <div className="search-input">
          <input
            placeholder="חפש שק לפי מספר הזמנה"
            type="text"
            ref={orderNumberSearchTermRef}
            className="input-field"
          />
          <BlueButton
            extraClass="search-button"
            disabled={!orderNumberSearchTermRef.current}
            onClick={handleOrderNumberSearch}
          >
            חפש
          </BlueButton>
        </div>
        <div className="search-input">
          <input
            type="text"
            placeholder="חפש שק לפי טלפון"
            ref={phoneSearchTermRef}
            className="input-field"
          />
          <BlueButton extraClass="search-button" onClick={handlePhoneSearch}>
            חפש
          </BlueButton>
        </div>
        <div className="search-select">
          <Select
            className="select-account"
            placeholder="סוג חשבון"
            options={accountTypesList}
            onChange={(e) => onAccountChange(e)}
          />
          <BlueButton
            extraClass="search-button"
            onClick={() => props.searchSacksByAccount(selectedAccount.value)}
          >
            חפש
          </BlueButton>
        </div>
        <div className="btn-print-wrapper">
          <BlueButton extraClass="btn-print" onClick={() => window.print()}>
            הדפס
          </BlueButton>
        </div>
      </div>
      {props.searchSackResult || props.searchSacksListResult?.length > 0 ? (
        <div className="sacks-found">
          <h3>פרטי שק</h3>
          <table className="result-table">
            <tbody>
              <tr>
                <th>ברקוד</th>
                <th>סוג חשבון</th>
                <th>תאריך יצירה</th>
                <th>תאריך הספקה</th>
              </tr>
              {props.searchSackSuccess ? (
                <tr>
                  <td>{props.searchSackResult.barcode}</td>
                  <td>
                    {getValEnumFromIndex(
                      props.accountTypes,
                      props.searchSackResult.accountType
                    )}
                  </td>
                  <td>{getFormatedDate(props.searchSackResult.createdDate)}</td>
                  <td>
                    {getFormatedDate(props.searchSackResult.deliveredDate)}
                  </td>
                </tr>
              ) : null}
              {props.searchSacksListSuccess
                ? props.searchSacksListResult.map((sack, index) => (
                    <tr key={index}>
                      <td>{sack.barcode}</td>
                      <td>
                        {getValEnumFromIndex(
                          props.accountTypes,
                          sack.accountType
                        )}
                      </td>
                      <td>{getFormatedDate(sack.createdDate)}</td>
                      <td>{getFormatedDate(sack.deliveredDate)}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
          {props.getSackOrdersSuccess ? (
            <div className="orders-table">
              <h3>רשימת הזמנות בשק</h3>
              <CertificateOrdersTable
                ordersList={props.sackOrders}
                accountTypesList={props.accountTypes}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {showNoDataMsg()}
    </div>
  )
}

const mapStateToProps = ({ orderSack, selectOptions }) => {
  const {
    searchSackSuccess,
    searchSackFail,
    searchSackResult,
    sackOrders,
    getSackOrdersSuccess,
    putOrdersToSackFail,
    searchSacksListResult,
    searchSacksListFail,
    searchSacksListSuccess,
  } = orderSack
  const { orderTypes, accountTypes } = selectOptions
  return {
    searchSackSuccess,
    searchSackFail,
    searchSackResult,
    orderTypes,
    accountTypes,
    sackOrders,
    getSackOrdersSuccess,
    putOrdersToSackFail,
    searchSacksListResult,
    searchSacksListFail,
    searchSacksListSuccess,
  }
}
export default connect(mapStateToProps, {
  searchSackByBarcode,
  searchSackByOrderNumber,
  searchSacksByAccount,
  searchSacksByDeliverPhone,
  clearSearchSack,
  fetchOrderTypes,
  fetchAccountTypes,
  fetchSackOrders,
  clearGetSackOrders,
  clearOrdersSacks,
})(SacksHistory)
