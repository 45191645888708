import React, { Component } from "react"
import { connect } from "react-redux"
import { PaginationNext } from "../../../common"
import { LockersInfraSensorFilterer } from "../../../reduxified"
import {
  fetchLockerStatusSelectOptions,
  fetchLockerTypeSelectOptions,
  fetchAllStationData,
  onLoadingLockersInfraSensor,
  fetchSearchedLockersInfraSensor,
  fetchLockersSelectListByStationId,
} from "../../../../actions"

import { fetchIfEmpty } from "../../../../utils"
import LockersInfraSensorList from "./LockersInfraSensorList"

import "./style.css"

class LockersSensorInfraPage extends Component {
  constructor(props) {
    super(props)
    this.INIT_STATE = {
      pagedList: [],
    }
    this.state = this.INIT_STATE
  }

  componentDidMount() {
    const { unauthorized, history } = this.props
    if (unauthorized) {
      history.push("/")
    }
    fetchIfEmpty(
      this.props.lockerStatuses,
      this.props.fetchLockerStatusSelectOptions
    )
    fetchIfEmpty(
      this.props.stationsItemSelectList,
      this.props.fetchAllStationData
    )
    fetchIfEmpty(
      this.props.lockerTypes,
      this.props.fetchLockerTypeSelectOptions
    )
    const { currentLockersStationId } = this.props
    if (currentLockersStationId && currentLockersStationId.length > 0) {
      this.props.fetchLockersSelectListByStationId(currentLockersStationId)
    }
  }

  componentWillReceiveProps(newProps) {
    const { unauthorized, history } = newProps
    if (unauthorized) {
      history.push("/")
    }
  }

  onPageClick(page) {
    let { search } = this.props
    search.page = page
    this.props.onLoadingLockersInfraSensor()
    this.props.fetchSearchedLockersInfraSensor(this.props.search)
  }

  render() {
    const {
      lockerStatuses,
      lockerTypes,
      searchedLockersInfraSensorList,
      isLoading,
    } = this.props

    return (
      <div className="station-lockers-history-wrapper">
        <LockersInfraSensorFilterer />

        <LockersInfraSensorList
          items={searchedLockersInfraSensorList}
          lockerStatuses={lockerStatuses}
          lockerTypes={lockerTypes}
          isLoading={isLoading}
        />

        <PaginationNext
          pageStep={this.props.currentSearchedPageStep}
          page={this.props.currentSearchedPage}
          onClick={(page) => this.onPageClick(page)}
          currentResponseCount={searchedLockersInfraSensorList.length}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  errors,
  selectOptions,
  stationsList,
  lockersInfraSensor,
}) => {
  const { unauthorized } = errors
  const { lockerStatuses, lockerTypes } = selectOptions
  const { stationsItemSelectList } = stationsList

  const {
    isLoading,
    search,
    currentLockersStationId,
    searchedLockersInfraSensorList,
    currentSearchedPage,
    currentSearchedPageStep,
  } = lockersInfraSensor

  const currentStationItem = stationsItemSelectList.filter(
    (item) => item.stationId === currentLockersStationId
  )

  return {
    unauthorized,
    currentLockersStationId,
    isLoading,
    search,
    stationsItemSelectList,
    lockerStatuses,
    lockerTypes,
    searchedLockersInfraSensorList,
    currentSearchedPage,
    currentSearchedPageStep,
    currentStationItem:
      currentStationItem.length > 0 ? currentStationItem[0] : "",
  }
}

export default connect(mapStateToProps, {
  fetchLockerStatusSelectOptions,
  fetchLockerTypeSelectOptions,
  fetchAllStationData,
  onLoadingLockersInfraSensor,
  fetchLockersSelectListByStationId,
  fetchSearchedLockersInfraSensor,
})(LockersSensorInfraPage)
