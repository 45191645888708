import React, { useMemo, useState, useEffect } from "react"
import { connect } from "react-redux"
import {
  getSupplierPrices,
  fetchSuppliersSelectList,
  updateSupplierPriceItem,
  clearSupplierPrices,
} from "../../../../../actions"
import Table from "../../_common/Table"
import Select from "react-select"
import EditableTextArea from "../../_common/EditableTextarea"
import NotificationModal from "../../../../Modals/NotificationModal"
import "./style.scss"

const SuppplierPrices = (props) => {
  const [supplierPriceState, setSupplierPriceState] = useState(null)
  const [supplierPrice, setSupplierPrice] = useState(null)
  const [selectedSupplier, setSelectedSupplier] = useState(null)

  useEffect(() => {
    setSupplierPriceState(props.supplierPricesList)
  }, [props.supplierPricesList])

  useEffect(() => {
    props.fetchSuppliersSelectList()
  }, [])

  const selectSupplierClick = (e) => {
    setSelectedSupplier({ name: e.label, id: e.value })
    props.getSupplierPrices(e.value, props.priceList)
  }

  const updateSupplierPricesTable = (rowIndex, columnID, value, row, event) => {
    setSupplierPriceState((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          let editedRow = {}
          editedRow = {
            ...old[rowIndex],
            [columnID]: value,
          }
          setSupplierPrice(editedRow)
          return editedRow
        }
        return row
      })
    )
  }

  const updateSupplierPrice = (priceItemId, newPrice) => {
    props.updateSupplierPriceItem(priceItemId, selectedSupplier.id, newPrice)
  }

  const columns = useMemo(
    () => [
      {
        Header: () => "פריט",
        id: "productNameHe",
        selector: "productName_he",
        Cell: ({ row }) => row.original.productName_he,
      },
      {
        Header: "מזהה",
        accessor: "priceItemId",
      },
      {
        Header: () => "תהליך",
        id: "processNameHe",
        Cell: ({ row }) => row.original.processName_he,
      },
      {
        Header: () => "מחיר ללקוח",
        id: "customerPrice",
        Cell: ({ row }) => row.original.customerPrice,
      },
      {
        Header: () => "עלות",
        accessor: "supplierPrice",
      },
      {
        Header: () => "",
        id: "btnSave",
        Cell: ({ row }) => (
          <button
            className="btn-update-price "
            onClick={(e) =>
              updateSupplierPrice(
                row.original.priceItemId,
                row.original.supplierPrice
              )
            }
          >
            <i className="fas fa-save"></i>
          </button>
        ),
      },
    ],
    [selectedSupplier]
  )

  const initialState = { hiddenColumns: ["priceItemId"] }

  const defaultColumn = {
    Cell: EditableTextArea,
  }

  return (
    <div className="supplierPrices">
      <div className="select-supplier-holder">
        <Select
          options={props.suppliersSelectList}
          onChange={(e) => selectSupplierClick(e)}
          placeholder="בחר ספק"
        />
      </div>

      {selectedSupplier ? (
        <div>
          <h2 className="table-title">{`מחירון של ${selectedSupplier.name}`}</h2>
          <div className="table-holder-supplier-prices">
            <Table
              id="supplier-prices-table"
              columns={columns}
              data={supplierPriceState}
              defaultColumn={defaultColumn}
              initialState={initialState}
              updateData={updateSupplierPricesTable}
            />
          </div>
        </div>
      ) : null}
      <NotificationModal
        show={props.isSupplierPriceUpdated}
        text="מחיר עודכן בהצלחה"
        onOkClick={(e) => props.clearSupplierPrices()}
      />
      <NotificationModal
        show={props.isSupplierPriceError}
        text="שגיאה, מחיר לא עודכן"
        onOkClick={(e) => props.clearSupplierPrices()}
      />
    </div>
  )
}

const mapStateToProps = ({ priceListsSection, suppliers }) => {
  const {
    supplierPricesList,
    isSupplierPriceUpdated,
    isSupplierPriceError,
    priceList,
  } = priceListsSection
  const { suppliersSelectList } = suppliers
  return {
    supplierPricesList,
    suppliersSelectList,
    isSupplierPriceUpdated,
    isSupplierPriceError,
    priceList,
  }
}

export default connect(mapStateToProps, {
  getSupplierPrices,
  fetchSuppliersSelectList,
  updateSupplierPriceItem,
  clearSupplierPrices,
})(SuppplierPrices)
