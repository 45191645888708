import React from "react"
import { BlueButton } from "../../../common/BlueButton"
import "./GeneralReception.scss"

const GeneralReception = (props) => {
  return (
    <section className="generalReception">
      <div className="title">סריקה כללי</div>
      <div>
        <BlueButton
          extraClass="btn-generalReception"
          onClick={() => props.history.push("/GeneralReceptionPage")}
        >
          סריקה כללי
        </BlueButton>
      </div>
    </section>
  )
}

export default GeneralReception
