import React, { Component } from "react"
import { connect } from "react-redux"

import OrderItemsHeader from "./OrderItemsHeader"
import OrderItemsListItm from "./OrderItemsListItm"
import CreateNewOrderItem from "./CreateNewOrderItem"

import { uuidv4 } from "../../../../../../utils"
import "./style.css"

// const itemNamesArr = ['חולצה', 'חולצה ארוזה לנסיעה', 'מכנסיים', 'מכנסיים ארוזים לנסיעה', 'חצאית', 'שמלה', 'ז׳קט', 'סוודר', 'מפת שולחן', 'סדין', 'ציפה', 'ציפית', 'טלית', 'שמלת ערב', 'מעיל', 'ז׳קט עור', 'מכנסי סקי', 'כפפות סקי', 'כותונת', 'חלוק', 'שמיכת פוך', 'שמיכה דקה', 'שמיכת צמר יחיד', 'שמיכת צמר זוגית', 'וילון', 'שטיח'];
const colorsArr = [
  "לבן",
  "שחור",
  "אדום",
  "כחול",
  "תכלת",
  "אפור",
  "צהוב",
  "ורוד",
  "סגול",
  "ירוק",
  "חום",
  "בז'",
]
const patternsArr = [
  "פרחוני",
  "מקושקש",
  "פסים",
  "משובצות קטנות",
  "משבצות גדולות",
]

class OrderItemsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orderToEdit: null,
      processNamesArr: [],
      processNamesObj: [],
    }
  }

  componentDidMount() {
    const { order, allPricesObj } = this.props
    for (const item of order.orderItems) {
      if (item.processName == "laundry") {
        if (item.quantity < 5) {
          item.quantity = 5
        }
      }
    }

    this.setState({
      orderToEdit: order,
    })
    this.renderProcessNameList(allPricesObj)
  }

  componentWillReceiveProps(newProps) {
    const { order, allPricesObj } = newProps
    const { order: oldOrder, allPricesObj: oldAllPricesObj } = this.props

    if (oldOrder !== order) {
      this.setState({ orderToEdit: order })
      console.log("---OrderItemsTable updated")
    }

    if (oldAllPricesObj != allPricesObj) {
      this.renderProcessNameList(allPricesObj)
    }
  }

  renderProcessNameList(allPricesObj) {
    if (!allPricesObj) {
      this.setState({ processNamesArr: [], processNamesObj: [] })
    }
    const processNamesArr = allPricesObj ? Object.keys(allPricesObj) : []

    let processNamesObj = []
    if (allPricesObj) {
      for (var key in allPricesObj) {
        processNamesObj.push({
          value: allPricesObj[key][0].processName_en,
          label: allPricesObj[key][0].processName_he,
        })
      }
    }
    this.setState({ processNamesArr, processNamesObj })
  }

  renderItems(items) {
    if (!items) {
      return <></>
    }
    const { allPricesObj } = this.props
    let { orderToEdit } = this.state

    return items.map((itm, index) => {
      const { processName, itemName } = itm
      if (
        orderToEdit.orderStatus != 6 &&
        orderToEdit.orderStatus != 5 &&
        (itemName == "" || itemName == null || itemName == undefined)
      ) {
        itm.isEditable = true
      }
      const productsData = allPricesObj ? allPricesObj[processName] || [] : []

      const hebProductNames = productsData.map(
        ({ productName_he }) => productName_he
      )
      const prices = productsData.map(({ price }) => price)

      return (
        <OrderItemsListItm
          item={itm}
          key={index}
          orderStatus={orderToEdit.orderStatus}
          itemNamesArr={["", ...hebProductNames]}
          optionalPrices={[0, ...prices]}
          colorsArr={colorsArr}
          patternsArr={patternsArr}
          onItemUpdate={(item) => this.onItemUpdate(item)}
          onItemDelete={(item) => this.onItemDelete(item)}
          isPayed={orderToEdit.isPayed}
        />
      )
    })
  }

  onItemUpdate(item) {
    let { orderToEdit } = this.state
    let { orderItems } = orderToEdit

    const editedItemIndex = orderItems.indexOf(
      orderItems.find((itm) => itm.id === item.id)
    )

    const itemBeforeUpdate = orderItems[editedItemIndex]

    if (
      item.processName != "laundry" &&
      itemBeforeUpdate.quantity > item.quantity
    ) {
      item.id = uuidv4()
      itemBeforeUpdate.quantity -= item.quantity
      // RESETING THE ITEM BEFOR UPDATE TO HOLD 'UNSETTED VALUES'...
      itemBeforeUpdate.color = null
      itemBeforeUpdate.pattern = null
      itemBeforeUpdate.itemName = null
      orderItems.splice(editedItemIndex, 0, item)
    } else {
      orderItems[editedItemIndex] = item
    }

    console.log("OrderItemsTable > onItemUpdate > edited order:")
    console.log(orderToEdit)

    this.setState({ orderToEdit })
    this.props.onOrderChanged(orderToEdit)
  }

  onItemDelete(item) {
    if (item.processName === "Compensation") {
      return
    }
    let { orderToEdit } = this.state
    let { orderItems } = orderToEdit

    orderToEdit.orderItems = orderItems.filter((itm) => itm.id !== item.id)

    this.setState({ orderToEdit })
    this.props.onOrderChanged(orderToEdit)
  }

  addNewItem(item) {
    item.id = uuidv4()

    const { orderToEdit } = this.state
    let { orderItems } = orderToEdit

    orderToEdit.orderItems = [...orderItems, item]

    this.setState({ orderToEdit })
    this.props.onOrderChanged(orderToEdit)
  }

  render() {
    const { orderToEdit } = this.state

    if (!orderToEdit) {
      return <div> LOADING </div>
    }

    const { orderItems } = orderToEdit
    const { processNamesObj, processNamesArr } = this.state
    const { allPricesObj } = this.props

    return (
      <div className="ewq-table-wrap">
        <OrderItemsHeader />

        {this.renderItems(orderItems)}

        {this.props.order.orderType === 1 ? (
          <CreateNewOrderItem
            processNamesArr={processNamesArr}
            processNamesObj={processNamesObj}
            optionalPricesObj={allPricesObj || []}
            colorsArr={colorsArr}
            patternsArr={patternsArr}
            addNewItem={(itm) => this.addNewItem(itm)}
            accountType={this.props.order?.accountType}
          />
        ) : null}
      </div>
    )
  }
}
const mapStateToProps = ({ stationsList }) => {
  const { stationPriceList } = stationsList
  const { allPricesObj } = stationPriceList

  return {
    allPricesObj,
  }
}

export default connect(mapStateToProps)(OrderItemsTable)
