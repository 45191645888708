import React from "react"

import Header from "../../../../common/List/Header"
import LockerHistoryItem from "./LockerHistoryItem"
import { H1, Spinner } from "../../../../common"
import "./style.css"
const lockerHeaders = [
  "תאריך",
  "מס' תא",
  "סטטוס תא",
  "הערות סטטוס",
  "שם מבצע",
  "סוג תא",
  "מס' הזמנה",
]
const lockerItemMap = [
  "createdDate",
  "lockerName",
  "lockerStatus",
  "note",
  "createdByName",
  "lockerType",
  "orderNumber",
]
const lockerGravity = { 0: 2, 3: 2, 4: 2 }

const LockersHistoryList = ({
  extraClass,
  items,
  lockerStatuses,
  lockerTypes,
  isError,
  isLoading,
}) => {
  return (
    <div className={`stations-list list-wrapper ${extraClass}`}>
      <Header titles={lockerHeaders} gravity={lockerGravity} />
      <div className="list-items-wrapper">
        {renderItems(
          items,
          lockerGravity,
          lockerItemMap,
          lockerStatuses,
          lockerTypes,
          isError,
          isLoading
        )}
      </div>
    </div>
  )
}

const renderItems = (
  items,
  lockerGravity,
  lockerItemMap,
  lockerStatuses,
  lockerTypes,
  isError,
  isLoading
) => {
  if (isError) {
    return (
      <H1 className="not-valid-search" title="אנא בדוק את הערך של שדה חיפוש" />
    )
  }
  if (isLoading) {
    return (
      <div className="station-editor-spinner-wrapper">
        <Spinner zoom={1} />
      </div>
    )
  }
  if (!items || items.length === 0) {
    return <H1 className="no-list-items-text" title="אין רשומות" />
  }

  return items.map((itm, index) => {
    return (
      <LockerHistoryItem
        key={index}
        item={itm}
        itemMap={lockerItemMap}
        gravity={lockerGravity}
        isLast={index === items.length - 1}
        lockerStatuses={lockerStatuses}
        lockerTypes={lockerTypes}
      />
    )
  })
}

export default LockersHistoryList
