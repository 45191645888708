import React from 'react';
import { connect } from 'react-redux';

import { deletePriceList } from '../../../../../actions';

const deletePriceListButtonText = 'להסיר את המחירון';
const deletePriceListButtonTitle = '';

const DeletePriceListButton = (props) => {
    const {
        priceList,
        deletePriceList
    } = props;

    let isDisabled = priceList !== '' ? false : true;
    let extraClass = '';
    if (isDisabled) {
        extraClass = "delete-button-disabled";
    }

    return (
        <button
            id="delete-price-list-button"
            className={`delete-button ${extraClass}`}
            disabled={isDisabled}
            onClick={() => {
                const shouldDelete = window.confirm(`Do you want to delete the price list`);
                if (shouldDelete) {
                    deletePriceList(priceList);
                }
            }}
        >
            {deletePriceListButtonText}
        </button>
    );
}

const mapStateToProps = ({ priceListsSection }) => {
    const { priceList } = priceListsSection;
    return {
        priceList,
    };
}

export default connect(mapStateToProps, { deletePriceList })(DeletePriceListButton);