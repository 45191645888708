import React from "react"

import { BlueButton } from "../../../../../common"
import "./style.css"

const StationItem = ({
  key,
  item,
  itemMap,
  gravity,
  isLast,
  onEditStationBtnClick,
}) => {
  const itemClassName = `item-wrapper ${isLast ? "item-last" : ""}`

  console.log("StationItem " + key)
  return (
    <div className={itemClassName} key={"station-item-" + key}>
      {itemMap.map((itmMap, index) => {
        const flex = gravity[`${index}`] || 1

        if (itmMap === "editStation") {
          return (
            <div style={{ flex }} key={index}>
              <BlueButton
                extraClass="base-btn station-edit-btn"
                onClick={(e) => onEditStationBtnClick(item)}
              >
                עריכת עמדה
              </BlueButton>
            </div>
          )
        }

        let itemContent = item[itmMap]

        if (itmMap === "branchDisplayName") {
          return (
            <div style={{ flex }} key={index} className="station-name">
              {itemContent}
            </div>
          )
        }

        return (
          <div style={{ flex }} key={index}>
            {itemContent}
          </div>
        )
      })}
    </div>
  )
}

export default StationItem
