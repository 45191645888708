import React, { Component } from "react"
import { connect } from "react-redux"
import PersonalDetailsTab from "./PersonalDetailsTab"
import AcountDetailsTab from "./AcountDetailsTab"
import CommentsTab from "./CommentsTab"
import OthersTab from "./OthersTab"
import UserHistoryTab from "./UserHistoryTab"
import UserOrdersTab from "./UserOrdersTab"
import UserSubscriptionsModal from "../../../Modals/UserSubscriptionsModal"
import { fetchUserSubscriptions } from "../../../../actions"
import "./style.css"

const tabTitles = [
  "פרטים אישיים",
  "פרטים כלליים לחשבון",
  "שונות",
  "הערות",
  "היסטוריה",
  "הזמנות",
]

class UserTabNavigator extends Component {
  constructor(props) {
    super(props)

    this.state = {
      focused: 0,
      showSubscriptionMessage: false,
    }
  }

  onTabClick(index) {
    this.setState({ focused: index })
    this.props.showUpdateBtn(index !== 4 && index !== 5)
  }

  getTab() {
    const { focused, showSubscriptionMessage } = this.state
    const {
      userData,
      userRoles,
      sortedStationsNames,
      countries,
      languages,
      userHistoryData,
      userOrdersData,
    } = this.props

    switch (focused) {
      case 0:
        return (
          <div className="tn-single-tab-wrapper">
            <PersonalDetailsTab
              languages={languages}
              countries={countries}
              userData={userData}
              onInputChange={(inputName, val) =>
                this.props.onUserDataFieldUpdate(inputName, val)
              }
              onOpenCancelSubscription={() => {
                this.setState({ showSubscriptionMessage: true })
                this.props.fetchUserSubscriptions(userData.id)
              }}
            />
            <UserSubscriptionsModal
              show={showSubscriptionMessage}
              userData={userData}
              onClose={() => this.setState({ showSubscriptionMessage: false })}
            />
          </div>
        )
      case 1:
        return (
          <div className="tn-single-tab-wrapper">
            <AcountDetailsTab
              userRoles={userRoles}
              sortedStationsNames={sortedStationsNames}
              userData={userData}
              onInputChange={(inputName, val) =>
                this.props.onUserDataFieldUpdate(inputName, val)
              }
            />
          </div>
        )
      case 2:
        return (
          <div className="tn-single-tab-wrapper">
            <OthersTab
              userData={userData}
              onInputChange={(inputName, val) =>
                this.props.onUserDataFieldUpdate(inputName, val)
              }
              removeCCToken={() => this.props.removeCCToken()}
            />
          </div>
        )
      case 3:
        return (
          <div className="tn-single-tab-wrapper">
            <CommentsTab
              userData={userData}
              onInputChange={(inputName, val) =>
                this.props.onUserDataFieldUpdate(inputName, val)
              }
            />
          </div>
        )
      case 4:
        return (
          <div className="tn-single-tab-wrapper user-history-tab">
            <UserHistoryTab
              userHistoryData={userHistoryData}
              onPageClick={(page) => this.props.onUserHistoryPageClick(page)}
            />
          </div>
        )
      case 5:
        return (
          <div className="tn-single-tab-wrapper user-orders-tab">
            <UserOrdersTab
              userOrdersData={userOrdersData}
              onPageClick={(page) => this.props.onUserOrdersPageClick(page)}
              onOrderItemEditBtnClick={(itm) =>
                this.props.onOrderItemEditBtnClick(itm)
              }
            />
          </div>
        )
      default:
        return <div />
    }
  }

  renderTabTitles() {
    const { focused } = this.state

    return tabTitles.map((tabTitle, index) => {
      const className = `tn-tabs-wrapper ${
        index === focused ? "tn-active-tab" : ""
      }`

      return (
        <div className={className} key={index}>
          <div className="tn-tab" onClick={(e) => this.onTabClick(index)}>
            {tabTitle}
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <div className="tn-navigation-tab">{this.renderTabTitles()}</div>

        {this.getTab()}
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, {
  fetchUserSubscriptions,
})(UserTabNavigator)
