import React, { Component } from "react"
import { connect } from "react-redux"

import Modal from "../Modal"
import { fetchIfEmpty } from "../../../utils"
import {
  fetchAllOrderStatuses,
  //getUserDetails,
  onUsersSearchNotValidInput,
  onStartLoadingUsers,
  fetchUserByMobile,
  fetchAllStationData,
} from "../../../actions"
import { SmallHeader, BlueButton } from "../../common"
import Select from "react-select"
import "./style.scss"

class CreateEditMailOrderModal extends Component {
  constructor(props) {
    super(props)
    this.phoneNumber = React.createRef()

    this.state = {
      mobile: "",
      orderType: null,
      stationNumber: null,
      packageNumber: null,
      firstName: null,
      lastName: null,
      errMsg: null,
    }
  }

  initState() {
    this.setState({
      mobile: "",
      orderType: 2,
      stationNumber: null,
      packageNumber: null,
      firstName: null,
      lastName: null,
      errMsg: null,
    })
  }

  orderTypes = [
    { value: 2, label: "דואר" },
    { value: 11, label: "Chita" },
    { value: 12, label: "Exelot" },
    { value: 13, label: "DHL" },
    { value: 14, label: "בר הפצה" },
    { value: 16, label: "Decathlon" },
    { value: 22, label: "ZigZag" },
    { value: 23, label: "HFD" },
    { value: 25, label: "YDM" },
    { value: 32, label: "Kexpress" },
  ]

  componentWillMount() {
    this.initState()
    fetchIfEmpty(this.props.ordersStatusesArr, this.props.fetchAllOrderStatuses)
    if (this.props.sortedStationsNames.length == 0) {
      this.props.fetchAllStationData()
    }
  }

  componentWillReceiveProps(newProps) {
    const { orderToEdit } = newProps

    if (this.props.isMailOrderCreated) {
      this.setState({
        mobile: "",
        firstName: "",
        lastName: "",
        packageNumber: "",
      })
      if (this.phoneNumber.current) {
        this.phoneNumber.current.focus()
      }
    }

    if (orderToEdit) {
      const {
        mobilePhone: mobile,
        orderStatus: status,
        locationId: location,
      } = orderToEdit

      this.setState({ mobile, status, location })
      this.onMobileChange(mobile)
    }

    if (
      newProps.usersListArr !== this.props.usersListArr &&
      newProps.usersListArr.filter(
        (itm) => itm.mobilePhone === this.state.mobile
      ).length > 0
    ) {
      const userData = newProps.usersListArr.filter(
        (itm) => itm.mobilePhone === this.state.mobile
      )[0]
      if (userData) {
        this.setState({
          firstName: userData.firstName,
          lastName: userData.lastName,
          stationNumber: userData.stationNumber,
        })
        document.getElementById("packageNum").focus()
      }
    }

    if (newProps.show !== this.props.show && newProps.show) {
      this.initState()
    }
  }

  onSubmitClick() {
    const {
      mobile,
      firstName,
      lastName,
      orderType,
      stationNumber,
      packageNumber,
    } = this.state

    if (!mobile) {
      this.setState({ errMsg: "הזן את מספר טלפון" })
      return
    }
    if (mobile != "0000000002") {
      if (!mobile.match(/^05\d([-]{0,1})\d{7}$/)) {
        this.setState({ errMsg: "מספר טלפון לא תקין" })
        return
      }
    }

    if (!firstName) {
      this.setState({ errMsg: "הזן שם הלקוח" })
      return
    }

    if (!lastName) {
      this.setState({ errMsg: "הזן את שם משפחה" })
      return
    }
    if (!stationNumber) {
      this.setState({ errMsg: "אנא בחר עמדה" })
      return
    }
    if (!packageNumber) {
      this.setState({ errMsg: "אנא הזן מספר חבילה" })
      return
    }
    if (!packageNumber.match(/^[a-zA-Z0-9]+$/)) {
      this.setState({ errMsg: "מספר חבילה לא תקין" })
      return
    }

    this.setState({ errMsg: "" })
    this.props.onSubmitOrderResultClick({
      mobilePhone: mobile,
      orderType,
      stationNumber,
      packageNumber,
      firstName,
      lastName,
      orderStatus: 3,
    })
  }

  onCancelClick() {
    this.initState()
    this.props.onCancelClick()
  }

  renderErrIfAny(err) {
    if (!err) {
      return <div>&nbsp;</div>
    }

    return <div className="cror-modal-error-msg">{err}</div>
  }

  onMobileChange(mobile) {
    const { usersListArr } = this.props

    const userData = usersListArr.filter((itm) => itm.mobilePhone === mobile)[0]
    if (userData !== undefined && userData.length > 0) {
      this.setState({
        mobile,
        userData: userData[0],
      })
      return
    }
    this.setState({
      mobile,
      userData: "",
    })
    this.searchByMobile(mobile)
  }

  searchByMobile(value) {
    if (!value || value === undefined || value.length !== 10) {
      this.props.onUsersSearchNotValidInput()
      return
    }
    this.props.onStartLoadingUsers()
    this.props.fetchUserByMobile(value)
  }

  render() {
    const { show, sortedStationsNames } = this.props

    if (!show) {
      return <noscript />
    }

    const { mobile, packageNumber, errMsg } = this.state

    return (
      <Modal extraClass="cror-mail-modal-wrap">
        <div className="cror-modal-container">
          <SmallHeader title="הזמנה חבילות" />
          <div className="cror-modal-mail-inputs-wrapper">
            <div className="order-mail-form">
              <div className="input-wrapper">
                <span className="input-title">מספר טלפון: </span>
                <input
                  className="input-text"
                  autoFocus
                  ref={this.phoneNumber}
                  value={this.state.mobile}
                  type="number"
                  onChange={(e) => this.onMobileChange(e.target.value)}
                ></input>
              </div>
              <div className="input-wrapper">
                <span className="input-title">שם פרטי: </span>
                <input
                  className="input-text"
                  value={this.state.firstName}
                  maxLength="30"
                  onChange={(e) => {
                    this.setState({ firstName: e.target.value })
                  }}
                ></input>
              </div>
              <div className="input-wrapper">
                <span className="input-title">שם משפחה: </span>
                <input
                  className="input-text"
                  maxLength="30"
                  value={this.state.lastName}
                  onChange={(e) => {
                    this.setState({ lastName: e.target.value })
                  }}
                ></input>
              </div>
              <div className="cror-modal-status">
                <div className="input-title"> סוג ההזמנה:</div>
                <Select
                  defaultValue={this.orderTypes[0]}
                  options={this.orderTypes}
                  onChange={(e) => {
                    this.setState({ orderType: e.value })
                  }}
                />
                <div />
              </div>
              <div className="cror-modal-status">
                <div className="input-title"> עמדה:</div>
                <Select
                  placeholder="בחר..."
                  value={
                    this.state.stationNumber &&
                    sortedStationsNames.find(
                      (o) => o.value === this.state.stationNumber
                    )
                  }
                  options={sortedStationsNames}
                  onChange={(e) => {
                    this.setState({ stationNumber: e.value })
                  }}
                />
                <div />
              </div>
              <div className="input-wrapper">
                <span className="input-title">מספר חבילה: </span>
                <input
                  id="packageNum"
                  className="input-text"
                  value={this.state.packageNumber}
                  onChange={(e) => {
                    this.setState({ packageNumber: e.target.value })
                  }}
                ></input>
              </div>
              {this.renderErrIfAny(errMsg)}
            </div>
            <div className="cror-modal-flex-status">
              <BlueButton
                disabled={this.props.isSubmitDisable}
                extraClass={`cror-modal-button ${
                  this.props.isSubmitDisable ? "disabled" : ""
                }`}
                onClick={(e) => this.onSubmitClick()}
              >
                אישור
              </BlueButton>
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => this.onCancelClick()}
              >
                ביטול
              </BlueButton>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = ({
  ordersStatuses,
  usersList,
  selectOptions,
  stationsList,
  ordersList,
}) => {
  const { ordersStatusesArr } = ordersStatuses
  const { usersListArr } = usersList
  const { orderTypes } = selectOptions
  const { sortedStationsNames } = stationsList
  const { isMailOrderCreated, isSubmitDisable } = ordersList
  return {
    ordersStatusesArr,
    usersListArr,
    orderTypes,
    sortedStationsNames,
    isMailOrderCreated,
    isSubmitDisable,
  }
}

export default connect(mapStateToProps, {
  fetchAllOrderStatuses,
  //getUserDetails,
  onUsersSearchNotValidInput,
  onStartLoadingUsers,
  fetchUserByMobile,
  fetchAllStationData,
})(CreateEditMailOrderModal)
