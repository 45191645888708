import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    H1,
    Multiselect,
    LabeledTextInput,
    SubmitButton
} from '../../../common';
import { downloadCustomerOrdersReport } from '../../../../actions';

import './style.css';

const customerOrdersReportHeader = "דוח מצב לקוח";
const customerName = "שם לקוח";
const customerPhone = "נייד לקוח";
const stationsSelectLabelText = "בחר את עמדות";

class CustomerOrdersReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedStations: [],
            customerName: '',
            customerPhone: ''
        }
    }

    onDataChange(property, value) {
        this.setState({ [property]: value });
    }

    onDownloadClick(event) {
        event.preventDefault();
        const { selectedStations, customerName, customerPhone } = this.state;
        this.props.downloadCustomerOrdersReport(selectedStations, customerName, customerPhone);
    }

    render() {
        const { stations } = this.props;

        return (
            <>
                <H1 className="reports-section-header" title={customerOrdersReportHeader} />
                <form onSubmit={(e) => this.onDownloadClick(e)}>
                    <div className="form-group">
                        <Multiselect
                            labelText={stationsSelectLabelText}
                            allValue
                            options={stations}
                            onChange={selectedOptions => this.onDataChange('selectedStations', selectedOptions)}
                        />
                        <LabeledTextInput
                            className="report-section-labeled-text-input"
                            labelText={customerName}
                            onChange={(e) => this.onDataChange('customerName', e.target.value)} />
                        <LabeledTextInput
                            className="report-section-labeled-text-input"
                            labelText={customerPhone}
                            onChange={(e) => this.onDataChange('customerPhone', e.target.value)} />
                    </div>
                    
                    <SubmitButton className="download-file-button" title="Download" />
                </form>
            </>
        );
    }
}

const mapStateToProps = ({ selectOptions }) => {
    const { stations } = selectOptions;

    return {
        stations,
    };
}

export default connect(mapStateToProps, { downloadCustomerOrdersReport })(CustomerOrdersReport);