import React, { useState, useRef, useEffect } from "react"
import { connect } from "react-redux"
import { SmallHeader, BlueButton, Spinner } from "../../common"
import Modal from "../Modal"
import { createReturnsOrder, clearCreateReturnsOrder } from "../../../actions"
import "./style.scss"

const LoadDHLReturnOrderToLockerModal = (props) => {
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const mobilePhoneRef = useRef()
  const packageNumberRef = useRef()
  const [errors, setErrors] = useState({})
  const [isOrderCreated, setIsOrderCreated] = useState(false)

  const validateForm = () => {
    const newErrors = {}
    const englishRegex = /^[A-Za-z\s]+$/

    if (!firstNameRef.current.value.trim()) {
      newErrors.firstName = "שם פרטי הוא שדה חובה"
    } else if (!englishRegex.test(firstNameRef.current.value)) {
      newErrors.firstName = "שם פרטי חייב להיות באנגלית בלבד"
    }

    if (!lastNameRef.current.value.trim()) {
      newErrors.lastName = "שם משפחה הוא שדה חובה"
    } else if (!englishRegex.test(lastNameRef.current.value)) {
      newErrors.lastName = "שם משפחה חייב להיות באנגלית בלבד"
    }

    if (!mobilePhoneRef.current.value.trim()) {
      newErrors.mobilePhone = "מספר טלפון הוא שדה חובה"
    }

    if (!packageNumberRef.current.value.trim()) {
      newErrors.packageNumber = "מספר חבילה הוא שדה חובה"
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateForm()) {
      props.createReturnsOrder({
        orderType: 6,
        stationId: props.stationId || "",
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        mobilePhone: mobilePhoneRef.current.value,
        packageNumber: packageNumberRef.current.value,
        locationId: props.locker.lockerId || "",
      })
    }
  }

  useEffect(() => {
    if (props.returnsOrderSuccess) {
      setIsOrderCreated(true)
    }
  }, [props.returnsOrderSuccess])

  useEffect(() => {
    if (props.isError) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        form: "אירעה שגיאה ביצירת ההזמנה. נסה שוב.",
      }))
    }
  }, [props.isError])

  const onSuccessClose = () => {
    props.onClose()
    setErrors({})
    setIsOrderCreated(false)
    props.clearCreateReturnsOrder()
  }

  if (!props.show) {
    return null
  }

  return (
    <Modal extraClass="dhl-order-to-locker">
      <div className="cror-modal-container">
        <SmallHeader
          title={`יצירת הזמנה DHL החזרות  לתא ${props.locker.lockerName}`}
        />
        {isOrderCreated ? (
          <div>
            <div className="success">
              <div className="cror-modal-title">ההזמנה נוצרה בהצלחה</div>
              <BlueButton onClick={() => onSuccessClose()}>סגור</BlueButton>
            </div>
          </div>
        ) : (
          <div className="cror-modal-inputs-wrapper">
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="שם פרטי באנגלית"
                  ref={firstNameRef}
                />
                {errors.firstName && (
                  <span className="error">{errors.firstName}</span>
                )}
              </div>
              <div>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="שם משפחה באנגלית"
                  ref={lastNameRef}
                />
                {errors.lastName && (
                  <span className="error">{errors.lastName}</span>
                )}
              </div>
              <div>
                <input
                  type="tel"
                  id="mobilePhone"
                  name="mobilePhone"
                  placeholder="מספר טלפון"
                  ref={mobilePhoneRef}
                />
                {errors.mobilePhone && (
                  <span className="error">{errors.mobilePhone}</span>
                )}
              </div>
              <div>
                <input
                  type="text"
                  id="packageNumber"
                  name="packageNumber"
                  placeholder="מספר חבילה"
                  ref={packageNumberRef}
                />
                {errors.packageNumber && (
                  <span className="error">{errors.packageNumber}</span>
                )}
              </div>
              {props.isLoading ? (
                <div className="spinner-holder">
                  <Spinner zoom={0.4} />
                </div>
              ) : (
                <p className="btn-modal-holder">
                  <BlueButton
                    type="submit"
                    className={props.isLoading ? "loading" : ""}
                  >
                    אישור
                  </BlueButton>
                  <BlueButton onClick={props.onClose}>ביטול</BlueButton>
                </p>
              )}
            </form>
            {errors.form && <div className="form-error">{errors.form}</div>}
          </div>
        )}
      </div>
    </Modal>
  )
}

const mapStateToProp = ({ orderToLocker }) => {
  const { isLoading, isError, returnsOrderSuccess } = orderToLocker
  return {
    isLoading,
    isError,
    returnsOrderSuccess,
  }
}

export default connect(mapStateToProp, {
  createReturnsOrder,
  clearCreateReturnsOrder,
})(LoadDHLReturnOrderToLockerModal)
