import React from "react"
import "./style.scss"

const BlueButton = ({
  children,
  extraClass,
  disabled,
  show = true,
  ...props
}) => {
  if (!show) {
    return <></>
  }
  return (
    <button
      className={`blbtn-wrapper ${extraClass}${disabled ? " disabled" : ""}`}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}

export { BlueButton }
