import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import {
  fetchUserByMobile,
  changeTargetCustomer,
  clearChangeTargetCustomer,
  getOrderDetails,
  clearUsersSearch,
} from "../../../actions"
import { SmallHeader, BlueButton } from "../../common"
import YesNoPopup from "../YesNoPopup/yesNoPopup.js"
import NotificationModal from "../NotificationModal"
import "./searchUserModal.scss"

const SearchUserModal = (props) => {
  const [mobile, setMobile] = useState("")
  const [foundUser, setFoundUser] = useState("")
  const [isShowConfirmSwitchUser, setShowConfirmSwitchUser] = useState(false)
  useEffect(() => {
    if (mobile?.length == 10) {
      props.fetchUserByMobile(mobile)
    }
  }, [mobile])

  useEffect(() => {
    if (props.usersListArr?.length > 0) {
      setFoundUser(props.usersListArr[0])
    }
  }, [props.usersListArr])

  const clearAndCloseModal = () => {
    setMobile("")
    setFoundUser("")
    props.close()
  }

  const onConfirm = () => {
    setShowConfirmSwitchUser(false)
    props.changeTargetCustomer(props.orderId, foundUser.userId)
  }

  const clearOnsuccess = () => {
    props.getOrderDetails(props.orderId)
    props.clearChangeTargetCustomer()
    props.clearUsersSearch()
    clearAndCloseModal()
  }

  return (
    <div className="searchUser-wrapper">
      {props.isOpen ? (
        <div className="overlay">
          <div className="searchUser-container">
            <div className="searchUser">
              <SmallHeader title="החלפת משתמש יעד" />
              <div className="search-user-wrapper">
                <div>
                  <span className="message-text">חפש משתמש:</span>
                  <input
                    className="phone-input"
                    autoFocus
                    placeholder="הכנס מספר טלפון"
                    value={mobile || ""}
                    type="number"
                    onChange={(e) => setMobile(e.target.value)}
                  ></input>
                </div>

                {foundUser ? (
                  <div className="user-data">
                    <div>
                      נמצא משתמש:{" "}
                      {foundUser.firstName + " " + foundUser.lastName}
                    </div>
                  </div>
                ) : null}
                <div className="btn-holder">
                  <button
                    disabled={foundUser ? false : true}
                    className={` ${foundUser ? "btn-yes" : "btn-disabled"}`}
                    onClick={() => setShowConfirmSwitchUser(true)}
                  >
                    אישור
                  </button>
                  <BlueButton
                    extraClass="btn-no"
                    onClick={() => clearAndCloseModal()}
                  >
                    ביטול
                  </BlueButton>
                </div>
              </div>
            </div>
            <YesNoPopup
              isOpen={isShowConfirmSwitchUser}
              infoMsg={`הינך הולך להחליף משתמש יעד ${
                props.customerFullName
              } במשתמש ${
                foundUser?.firstName + " " + foundUser?.lastName
              }. האם אתה בטוח?`}
              close={() => setShowConfirmSwitchUser(false)}
              confirm={() => onConfirm()}
            />
            <NotificationModal
              show={props.changeTargetCustomerSuccess}
              type="success"
              title="עדכון המשתמש יעד "
              text="המשתמש יעד עודכן בהצלחה"
              onOkClick={() => clearOnsuccess()}
            />
            <NotificationModal
              show={props.changeTargetCustomerFail}
              type="error"
              title=" עדכון המשתמש יעד"
              text="עדכון המשתמש נכשל"
              onOkClick={() => props.clearChangeTargetCustomer()}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

const mapStateToProp = ({ usersList, ordersList }) => {
  const { usersListArr } = usersList
  const { changeTargetCustomerSuccess, changeTargetCustomerFail } = ordersList
  return {
    usersListArr,
    changeTargetCustomerSuccess,
    changeTargetCustomerFail,
  }
}

export default connect(mapStateToProp, {
  fetchUserByMobile,
  changeTargetCustomer,
  clearChangeTargetCustomer,
  getOrderDetails,
  clearUsersSearch,
})(SearchUserModal)
