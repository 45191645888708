import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { SmallHeader, BlueButton } from "../../common"
import {
  changePackageNumber,
  clearChangePackageNumber,
  getOrderDetails,
} from "../../../actions"
import NotificationModal from "../NotificationModal"
import Modal from "../Modal"
import "./style.scss"

const ChangePackageNumberModal = (props) => {
  const [validationMsg, setValidationMsg] = useState("")
  const inputRef = useRef(null)

  const onCancelClick = () => {
    props.onCancelClick()
    props.clearChangePackageNumber()
    clearState()
  }

  const onSubmitClick = () => {
    let isValid = validateInput(inputRef.current.value)
    if (isValid) {
      props.changePackageNumber(props.order.id, inputRef.current.value)
      clearState()
    }
  }

  const clearState = () => {
    setValidationMsg("")
  }

  const validateInput = (input) => {
    if (!input) {
      setValidationMsg("אנא הזן מספר חבילה")
      return false
    }
    setValidationMsg("")
    return true
  }

  const onCloseModal = () => {
    props.getOrderDetails(props.order.id)
    onCancelClick()
    props.clearChangePackageNumber()
  }

  if (!props.show) {
    return null
  }

  return (
    <Modal extraClass="cror-modal-wrap">
      <div className="cror-modal-container">
        <div className="change-package-number-modal">
          <SmallHeader title="שינוי מספר חבילה" />
          <div className="modal-body">
            <div>
              מספר ברקוד נוכחי: <b>{props.order.packageNumber}</b>
            </div>
            <div>
              <span> מספר ברקוד חדש:</span>
              <input className="input-text" ref={inputRef}></input>
            </div>
            <div className="validation-msg">
              {validationMsg ? validationMsg : null}
            </div>
            <div className="cror-modal-status">
              <BlueButton
                disabled={props.isLoading}
                extraClass="cror-modal-button"
                onClick={(e) => onSubmitClick()}
              >
                אישור
              </BlueButton>
              <BlueButton
                extraClass="cror-modal-button"
                onClick={(e) => onCancelClick()}
              >
                ביטול
              </BlueButton>
            </div>
          </div>
        </div>
      </div>
      <NotificationModal
        show={props.changePackageNumberSuccess}
        title={" שינוי  מספר חבילה"}
        text={" מספר חבילה שונה בהצלחה"}
        onOkClick={() => onCloseModal()}
      />
      <NotificationModal
        show={props.changePackageNumberFail}
        type={"error"}
        title={" שינוי מספר חבילה"}
        text={" שינוי מספר חבילה נכשל"}
        onOkClick={() => onCancelClick()}
      />
    </Modal>
  )
}

const mapStateToProp = ({ ordersList }) => {
  const { changePackageNumberSuccess, changePackageNumberFail, isLoading } =
    ordersList

  return { changePackageNumberSuccess, changePackageNumberFail, isLoading }
}

export default connect(mapStateToProp, {
  changePackageNumber,
  clearChangePackageNumber,
  getOrderDetails,
})(ChangePackageNumberModal)
