import React from "react"
import eye from "../../../assets/images/eye.svg"
import eye_closed from "../../../assets/images/eye_closed.svg"

import "./style.css"

const InputFiled = ({
  icon,
  displayErr,
  onFocusChanged,
  onRefReady,
  extraClass,
  isHidedPassword,
  onViewPasswordClick,
  type,
  ...props
}) => {
  let miniIcon,
    iconStyle = null
  if (icon) {
    miniIcon = require(`../../../assets/images/${icon}.png`)
    iconStyle = {
      backgroundImage: `url('${miniIcon}')`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "4% 50%",
      // backgroundSize: '10%',
      paddingLeft: 25,
    }
  }
  const renderPasswordView = (isHidedPassword, onViewPasswordClick) => {
    if (onViewPasswordClick === undefined) {
      return <></>
    }

    if (isHidedPassword) {
      return (
        <div className={"password-eye "} onClick={() => onViewPasswordClick()}>
          <img src={eye} alt="eye_img" />
        </div>
      )
    }

    return (
      <div className={"password-eye "} onClick={() => onViewPasswordClick()}>
        <img src={eye_closed} alt="closed-eye" />
      </div>
    )
  }
  return (
    <>
      <input
        className={`infl-wrapper ${displayErr ? "infl-err" : ""} ${extraClass}`}
        style={iconStyle}
        onFocus={onFocusChanged ? (e) => onFocusChanged(true) : null}
        onBlur={onFocusChanged ? (e) => onFocusChanged(false) : null}
        ref={onRefReady ? (ref) => onRefReady(ref) : null}
        type={type ? type : "text"}
        {...props}
      />
      {renderPasswordView(type === "password", onViewPasswordClick)}
    </>
  )
}

export { InputFiled }
