import React, { Component } from "react"
import { connect } from "react-redux"
import { BlueButton } from "../../../../../../common"
import { updateLockerStatus } from "../../../../../../../actions"
import { providerManager } from "../../../../../../../deliveryProviders/providerManager"
import { getFormatedDate } from "../../../../../../../utils/FormatedDate"
import { getStatusNameFromObj } from "../../../../../../../utils"
import "./style.scss"

class SackItem extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    this.setState({})
  }

  componentDidUpdate(prevProps, prevState) {}

  render() {
    const {
      item,
      itemMap,
      gravity,
      isLast,
      accountTypes,
      orderStatusesObj,
      onPrintClick,
      onDeliverSackClick,
    } = this.props

    const itemClassName = `item-wrapper ${isLast ? "item-last" : ""}`

    return (
      <div className="sackItem">
        <div className={itemClassName}>
          {itemMap.map((itmMap, index) => {
            const flex = gravity[`${index}`] || 1

            switch (itmMap) {
              case "accountType":
                let accountType = accountTypes[item.accountType]
                  ? accountTypes[item.accountType]
                  : "NA"
                return (
                  <div style={{ flex }} key={index}>
                    {accountType}
                  </div>
                )

              case "orderStatus":
                let ordersStatus =
                  orderStatusesObj && orderStatusesObj.length > 0
                    ? getStatusNameFromObj(orderStatusesObj, item.orderStatus)
                    : item.orderStatus

                return (
                  <div style={{ flex }} key={index}>
                    {ordersStatus}
                  </div>
                )
              case "createdDate":
                const date = getFormatedDate(item.createdDate)
                return (
                  <div style={{ flex }} key={index}>
                    {date}
                  </div>
                )
              case "updatedDate":
                const dateU = getFormatedDate(item.updatedDate)
                return (
                  <div style={{ flex }} key={index}>
                    {dateU}
                  </div>
                )

              case "setAsDelevered":
                return (
                  <div style={{ flex }} key={index}>
                    <BlueButton
                      extraClass="update-locker-status-btn"
                      onClick={(e) => onDeliverSackClick(item)}
                    >
                      סמן שק כהוחזר לשולח
                    </BlueButton>
                  </div>
                )
              case "ordersCount":
                return (
                  <div style={{ flex }} key={index}>
                    <div className="orders-count">{item.ordersCount}</div>
                  </div>
                )
              case "print":
                return (
                  <div style={{ flex }} key={index}>
                    <BlueButton
                      extraClass="update-locker-status-btn"
                      onClick={(e) => onPrintClick(item)}
                      title="הדפס הזמנה"
                    >
                      <i class="fa fa-print" aria-hidden="true"></i>
                    </BlueButton>
                  </div>
                )

              default:
                let itemContent = item[itmMap]
                return (
                  <div style={{ flex }} key={index}>
                    {itemContent}
                  </div>
                )
            }
          })}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ selectOptions, ordersStatuses }) => {
  const { orderStatusesObj } = ordersStatuses
  const { orderTypes } = selectOptions

  return {
    orderStatusesObj,
    orderTypes,
  }
}

export default connect(mapStateToProps, {
  updateLockerStatus,
})(SackItem)
