import React, { Component } from "react"
import { connect } from "react-redux"

import Header from "../../../../common/List/Header"
import RouteItem from "./RouteItem"
import { H1, Spinner } from "../../../../common"

import {
  setCurrentRoute,
  onLoadingRoutes,
  onLoadingCheckExistRouteName,
  fetchRoutes,
  fetchRouteStations,
  isExistRouteName,
  createRoute,
  updateRoute,
  deleteRoute,
  closeRouteNotification,
} from "../../../../../actions/RoutesActions"

import "./style.css"

const routeHeaders = [
  "שם מסלול הפצה",
  "צבע מסלול",
  "שם ספק",
  "עלות מסלול",
  "עמדות",
  "עריכת שם",
  "הסר",
]
const routeItemMap = [
  "label",
  "color",
  "serviceSupplierName",
  "routeCost",
  "editStations",
  "editName",
  "remove",
]
const routeEditItemMap = [
  "routeName",
  "colorSelect",
  "serviceSupplierSelect",
  "routeCostInput",
  "",
  "update",
  "cancel",
]
const routeAddItemMap = [
  "routeName",
  "colorSelect",
  "serviceSupplierSelect",
  "routeCostInput",
  "",
  "save",
  "cancel",
]
const routeGravity = {}
const addNewRouteBtnText = "הוספת מסלול הפצה חדש"

class RoutesList extends Component {
  constructor(props) {
    super(props)

    this.INIT_STATE = {}
    this.state = this.INIT_STATE
  }

  onEditRouteStationsBtnClick(itm) {
    let route = null
    if (itm && itm.value && itm.label) {
      route = {
        routeName: itm.label,
        id: itm.value,
      }
    }
    this.props.setCurrentRoute(route)
    const { history } = this.props
    history.push(`/StationsScreen/route_stations`)
  }

  onEditRouteName(data) {
    const { setCurrentRoute } = this.props

    if (data === null) {
      setCurrentRoute(null)
      this.setState({ isExistName: false })
      return
    }

    if (data && data.value && data.label) {
      const routeFromList = {
        routeName: data.label,
        id: data.value,
        serviceSupplierId: data.serviceSupplierId,
        serviceSupplierName: data.serviceSupplierName,
        color: data.color,
        routeCost: data.routeCost,
      }
      setCurrentRoute(routeFromList)
      this.setState({ isExistName: false })
      return
    }

    if (data && data.routeName && data.id) {
      setCurrentRoute(data)
      this.setState({ isExistName: false })
      return
    }

    let { currentRoute, routesList } = this.props
    if (data && data.currentTarget && data.currentTarget.value) {
      currentRoute.routeName = data.currentTarget.value

      setCurrentRoute(currentRoute)
      const isExistName =
        routesList.filter((itm) => itm.label === currentRoute.routeName)
          .length > 0
      this.setState({ isExistName })
      return
    }

    setCurrentRoute(data)
    this.setState({ isExistName: false })
  }

  removeRoute(routeId) {
    this.props.onLoadingRoutes()
    this.props.deleteRoute(routeId)
    this.setState({ reloadRoutes: true })
  }

  createCurrentRoute() {
    this.props.onLoadingRoutes()
    this.props.createRoute(this.props.currentRoute)
    this.setState({ reloadRoutes: true })
  }

  updateCurrentRoute() {
    this.props.onLoadingRoutes()
    this.props.updateRoute(this.props.currentRoute)
    this.setState({ reloadRoutes: true })
  }

  renderItems(items, isError) {
    const { isLoading } = this.props
    if (isError) {
      return (
        <H1
          className="not-valid-search"
          title="אנא בדוק את הערך של שדה חיפוש"
        />
      )
    }
    if (isLoading) {
      return (
        <div className="station-editor-spinner-wrapper">
          <Spinner zoom={1} />
        </div>
      )
    }
    if (!items || items.length === 0) {
      return <H1 className="no-list-items-text" title="אין רשומות" />
    }
    const { currentRoute } = this.props
    console.log("Route list > currentRoute:")
    console.log(currentRoute)

    return items.map((itm, index) => {
      const itemMap =
        currentRoute && currentRoute.id === itm.value
          ? routeEditItemMap
          : routeItemMap
      return (
        <RouteItem
          key={index}
          item={itm}
          itemMap={itemMap}
          gravity={routeGravity}
          isLast={index === items.length - 1}
          onEditRouteStationsBtnClick={(e) =>
            this.onEditRouteStationsBtnClick(e)
          }
          onUpdateRouteBtnClick={(e) => this.updateCurrentRoute(e)}
          onEditRouteName={(e) => this.onEditRouteName(e)}
          onRemoveBtnClick={(e) => this.removeRoute(e)}
          onSaveRouteBtnClick={(e) => this.createCurrentRoute(e)}
          isExistName={this.state.isExistName}
          setCurrentRoute={(e) => this.props.setCurrentRoute(e)}
        />
      )
    })
  }

  renderAddItem() {
    const { currentRoute } = this.props
    if (!currentRoute || currentRoute.id != "") {
      return <></>
    }
    return (
      <RouteItem
        key={"add-item"}
        item={currentRoute}
        itemMap={routeAddItemMap}
        gravity={routeGravity}
        isLast={false}
        onEditRouteStationsBtnClick={(e) => this.onEditRouteStationsBtnClick(e)}
        onUpdateRouteBtnClick={(e) => this.updateCurrentRoute(e)}
        onEditRouteName={(e) => this.onEditRouteName(e)}
        onRemoveBtnClick={(e) => this.removeRoute(e)}
        onSaveRouteBtnClick={(e) => this.createCurrentRoute(e)}
        isExistName={this.state.isExistName}
        setCurrentRoute={(e) => this.props.setCurrentRoute(e)}
      />
    )
  }

  render() {
    const { extraClass, items, isError, routesList } = this.props

    return (
      <div className={`stations-list list-wrapper ${extraClass}`}>
        <div className="list-items-wrapper">
          <div
            className={"add-new-route-btn"}
            role="button"
            alt={addNewRouteBtnText}
            tabIndex={0}
            onClick={(e) => this.onEditRouteName({ id: "", routeName: "" })}
          >
            {addNewRouteBtnText}
          </div>
        </div>

        <Header titles={routeHeaders} gravity={routeGravity} />

        <div className="list-items-wrapper">{this.renderAddItem()}</div>
        <div className="list-items-wrapper">
          {this.renderItems(items, isError)}
        </div>
      </div>
    )
  }
}

const MapStateToProps = ({ routes }) => {
  const { currentRoute, isLoading } = routes
  const routesList = routes.routes
  return {
    currentRoute,
    isLoading,
    routesList,
  }
}

export default connect(MapStateToProps, {
  setCurrentRoute,
  onLoadingRoutes,
  onLoadingCheckExistRouteName,
  fetchRoutes,
  fetchRouteStations,
  isExistRouteName,
  createRoute,
  updateRoute,
  deleteRoute,
  closeRouteNotification,
})(RoutesList)
