import React from "react";
import "./notificationPopup.scss";

const notificationPopup = (props) => {
  return (
    <div className="notificationPopup-wrapper">
      {props.isOpen ? (
        <div className="overlay">
          <div className="notificationPopup-container">
            <div className="notificationPopup">
              <img className="info-icon" src={props.image} alt="info icon" />
              <p className="message-text">{props.infoMsg}</p>
              <div className="btn-holder">
                <button className="btn-close" onClick={props.toggle}>
                  {props.btnText}
                </button>
                {props.searchForOrder ? (
                  <button className="btn-close" onClick={props.searchForOrder}>
                    חפש הזמנה
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default notificationPopup;
