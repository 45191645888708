import axios from "axios"
import api from "../api"
import errorManager from "../utils/ErrorManager"

import {
  FETCH_ALL_STATIONS_DATA,
  STATION_PRICE_LIST_CLEAR,
  FETCH_STATION_PRICE_LIST_EN,
  FETCH_STATION_PRICE_LIST_HE,
  FETCH_DOWN_STATIONS,
  FETCH_STATIONS_VOLTAGE,
  FETCH_STATION_VOLTAGE,
  IS_AREA_CODE_CAN_BE_LINKED,
  CLEAR_AREA_CODE_CAN_BE_LINKED,
} from "./types"

export const fetchAllStationData = () => {
  return (dispatch) => {
    axios
      .get(api.getAllStationsData)
      .then((res) => {
        dispatch({
          type: FETCH_ALL_STATIONS_DATA,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const fetchStationPriceList = (stationId) => {
  return (dispatch) => {
    // CLEANING LAST STATION PRICE LIST..
    dispatch({
      type: STATION_PRICE_LIST_CLEAR,
    })

    axios
      .get(api.getStationPriceList(stationId, "en"))
      .then((res) => {
        dispatch({
          type: FETCH_STATION_PRICE_LIST_EN,
          payload: res.data[0],
        })
      })
      .catch((err) => console.log(err))

    axios
      .get(api.getStationPriceList(stationId, "he"))
      .then((res) => {
        dispatch({
          type: FETCH_STATION_PRICE_LIST_HE,
          payload: res.data[0],
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const getDownStations = () => {
  return (dispatch) => {
    axios
      .get(api.getDownStations)
      .then((res) => {
        dispatch({
          type: FETCH_DOWN_STATIONS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getStationsVoltage = () => {
  return (dispatch) => {
    axios
      .get(api.getStationsVoltage)
      .then((res) => {
        dispatch({
          type: FETCH_STATIONS_VOLTAGE,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const getStationsVoltagePerStation = (stationId) => {
  return (dispatch) => {
    axios
      .get(api.getStationsVoltagePerStation(stationId))
      .then((res) => {
        dispatch({
          type: FETCH_STATION_VOLTAGE,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const isAreaCodeCanBeLinkedToStation = (routeAreaCodeId) => {
  return (dispatch) => {
    axios
      .get(api.isAreaCodeCanBeLinkedToStation(routeAreaCodeId))
      .then((res) => {
        dispatch({
          type: IS_AREA_CODE_CAN_BE_LINKED,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        errorManager.throwDispatch(dispatch, err)
      })
  }
}

export const clearAreaCodeCanBeLinked = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_AREA_CODE_CAN_BE_LINKED,
      payload: true,
    })
  }
}
