import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { importOrders } from '../../../../actions';

import './style.css';

function OrdersImport(props) {
    const importTitle = `יבוא הזמנות`;
    const fileInputLabelTitle = `בחרו את הקובץ`;
    const fileInputSelectMessage = `בחרו את קובץ ה-CSV כדי לייבא הזמנות`;
    const fileInputFileMessage = `קובץ נבחר`;
    const importSuccessMessage = `הזמנות יובאו`;
    const importOrdersFormLinkTitle = `קובץ`;
    const importOrdersFormLink = `https://docs.google.com/spreadsheets/d/1_s_QUx0DqvIR9ndrjbKsIYSaZLI5bXhF3ePCPyy9H38/edit?usp=sharing`;

    const fileInput = useRef(null);

    const [fileStatusMessage, setFileStatusMessage] = useState('');
    const [importStatusMessage, setImportStatusMessage] = useState('')

    useEffect(() => {
        let importedOrders = props.importedOrders;
        // let errors = 0;
        if (importedOrders.length !== 0) {
            setImportStatusMessage(`${importedOrders.length} ${importSuccessMessage}`/* , ${errors} errors` */)
        }
        fileInput.current.value = '';
        setFileStatusMessage(`${fileInputSelectMessage}`);
    }, [props.importedOrders, fileInputSelectMessage, importSuccessMessage])

    function handleFileSelect(event) {
        event.preventDefault();
        let files = fileInput.current.files;
        if (files.length !== 0) {
            setFileStatusMessage(`${files[0].name} ${fileInputFileMessage}`);
        }

        props.importOrders(fileInput.current.files[0]);
    }

    return (
        <>
            <header>
                <h1 className="import-section-header">{importTitle}</h1>
                <a href={importOrdersFormLink} target="_blank" rel="noopener noreferrer">{importOrdersFormLinkTitle}</a>
            </header>
            <article>
                <input
                    id="orders-import"
                    className="ss-file-input"
                    type="file"
                    accept=".csv"
                    ref={fileInput}
                    onChange={(event) => { handleFileSelect(event) }}
                />
                <label className="file-input-button" htmlFor="orders-import">{fileInputLabelTitle}</label>
                <span className="file-input-message">{fileStatusMessage}</span>
                <p className="import-status-message">{importStatusMessage}</p>
            </article>
            {/* <article className="import-errors-list">
                <h2>Not correct strings:</h2>
                <ul>
                    <li>hdjsfgs, dshfksjhdf, sdhfhskjdhf</li>
                    <li>hdjsfgs, dshfksjhdf, sdhfhskjdhf</li>
                    <li>hdjsfgs, dshfksjhdf, sdhfhskjdhf</li>
                </ul>
            </article> */}
            {/* <article className="import-success-list">
                <h2>Successfully imported orders:</h2>
                <ul className="import-list">
                    <li>100289</li>
                    <li>100290</li>
                    <li>100291</li>
                    <li>100292</li>
                </ul>
            </article> */}
        </>
    );
};

const mapStateToProps = ({ importSection }) => {
    const { importedOrders } = importSection;
    return {
        importedOrders,
    };
}

export default connect(mapStateToProps, { importOrders })(OrdersImport);