import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { AppVersion } from "../../../utils/Env.js"
import "./style.css"

class AppBar extends Component {
  getPageNameFromPathName(pathname) {
    const pagePathMap = {
      // '/': 'דף הבית',
      "/OrdersListScreen": "הזמנות פתוחות",
      "/OrdersSearchScreen": "חיפוש הזמנות",
      "/UsersListScreen": "משתמשים",
      "/OrdersEditingScreen": "עריכת הזמנות",
      "/UserCreateEditScreen": "משתמש",
      "PriceListsScreen/items": "פריטים",
      "PriceListsScreen/services": "שירותים",
      "PriceListsScreen/processes": "תהליכים",
      "PriceListsScreen/price-lists": "מחירונים",
      "/LockersScreen/lockers": "לוקרים",
      "/LockersScreen/lockers/relocate-order": "שינוי מיקום הזמנה",
      "/CouponsScreen": "קופונים",
    }

    return pagePathMap[pathname]
  }

  render() {
    const { pathname } = this.props.location

    const laundryLogoSrc = require("../../../assets/images/done_long_logo.png")

    return (
      <div className="appbar-wrapper">
        <div className="appbar-flex-1">
          {this.getPageNameFromPathName(pathname)}
        </div>
        <div className="app-version">{AppVersion}</div>
        <img
          onClick={() => this.props.history.push("/HomeScreen")}
          src={laundryLogoSrc}
          alt="cleanBox logo"
          className="appbar-flex-1 appbar-img"
        />
      </div>
    )
  }
}

export default withRouter(AppBar)
