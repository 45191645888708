import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Link } from 'react-router-dom';

import CouponsList from './CouponsList';
//import CouponsTable from './CouponsTable';
import EditCoupon from './EditCoupon';
import NewCoupon from './NewCoupon';

import './style.css';

const couponsList_HeaderText = "רשימת קופונים";
const newCoupon_HeaderText = "יצירת קופון חדש";

class CouponsScreen extends Component {
    componentWillUnmount() {
        
    }

    render() {
        
        return (
            <section id="coupons-section-container">
                <nav className="section-menu">
                    <ul>
                        <li className="section-menu-item"><Link to={`${this.props.match.url}`}>{couponsList_HeaderText}</Link></li>
                        <li className="section-menu-item"><Link to={`${this.props.match.url}/create`}>{newCoupon_HeaderText}</Link></li>
                    </ul>
                </nav>
                <main className="section-body">
                    <Route exact path={`${this.props.match.path}`} component={CouponsList} />
                    <Route path={`${this.props.match.path}/edit`} component={EditCoupon} />
                    <Route path={`${this.props.match.path}/create`} component={NewCoupon} />
                </main>
            </section>
        );
    }
}

export default (CouponsScreen);









