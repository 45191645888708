import {
  ON_BRANCH_CARD_LOADING,
  ON_GET_BRANCH_CARD_SUCCESS,
  ON_GET_GET_BRANCH_CARD_FAIL,
  ON_BRANCH_CARD_UPDATE_SUCCESS,
  ON_BRANCH_CARD_UPDATE_FAIL,
  CLEAR_UPDATE_BRANCH_CARD,
  CLEAR_GET_BRANCH_CARD,
} from "../actions/types"

import {} from "../utils"

const INIT_STATE = {
  isLoading: false,
  branchCardItem: null,
  getBranchCardSuccess: false,
  getBranchCardFail: false,
  branchCardUpdateSuccess: false,
  branchCardUpdateFail: false,
}

const BranchCardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_BRANCH_CARD_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case ON_GET_BRANCH_CARD_SUCCESS:
      return {
        ...state,
        branchCardItem: action.payload,
        getBranchCardSuccess: true,
        getBranchCardFail: false,
        isLoading: false,
      }
    case ON_GET_GET_BRANCH_CARD_FAIL:
      return {
        ...state,
        isLoading: false,
        getBranchCardFail: true,
        getBranchCardSuccess: false,
      }
    case ON_BRANCH_CARD_UPDATE_SUCCESS:
      return {
        ...state,
        branchCardUpdateSuccess: true,
        branchCardUpdateFail: false,
        isLoading: false,
      }
    case ON_BRANCH_CARD_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        branchCardUpdateFail: true,
        branchCardUpdateSuccess: false,
      }
    case CLEAR_UPDATE_BRANCH_CARD:
      return {
        ...state,
        isLoading: false,
        branchCardUpdateSuccess: false,
        branchCardUpdateFail: false,
      }
    case CLEAR_GET_BRANCH_CARD:
      return {
        ...state,
        isLoading: false,
        getBranchCardSuccess: false,
        getBranchCardFail: false,
        branchCardItem: null,
      }

    default:
      return state
  }
}

export default BranchCardReducer
