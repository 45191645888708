import {
  ON_USER_SIGN_IN_RESET_ATTEMPTS,
  UNAUTHORIZED,
  RESET_ERROR,
  GET_SEARCHED_LOCKERS_BY_STATION_SUCCESS,
  GET_SEARCHED_LOCKERS_BY_STATION_FAIL,
  SET_LOCKERS_INFRA_SENSOR_STATION,
  ON_LOADING_LOCKERS_INFRA_SENSOR,
  GET_SEARCHED_LOCKERS_INFRA_SENSOR_SUCCESS,
  GET_SEARCHED_LOCKERS_INFRA_SENSOR_FAIL,
  SET_LOCKERS_INFRA_SENSOR_SEARCH,
} from "../actions/types"

const INIT_SEARCH = {
  stationId: "",
  lockers: [],
  fromDate: "",
  toDate: "",
  pageStep: 50,
  page: 1,
}

const INIT_STATE = {
  lockersHistoryList: [],
  isLoading: false,
  isError: false,
  currentLockersStationId: "",
  currentStationLockers: [],
  search: INIT_SEARCH,
  searchedLockersInfraSensorList: [],
  currentSearchedPage: 1,
  currentSearchedPageStep: 50,
}

const LockersInfraSensorReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_LOADING_LOCKERS_INFRA_SENSOR:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case SET_LOCKERS_INFRA_SENSOR_STATION:
      return {
        ...state,
        currentLockersStationId: action.payload,
        searchedLockersInfraSensorList: [],
        isLoading: false,
      }

    case GET_SEARCHED_LOCKERS_INFRA_SENSOR_SUCCESS:
      return {
        ...state,
        searchedLockersInfraSensorList: action.payload.lockersInfraSensor,
        currentSearchedPage: action.payload.page,
        currentSearchedPageStep: action.payload.pageStep,
        isLoading: false,
      }
    case GET_SEARCHED_LOCKERS_INFRA_SENSOR_FAIL:
      return {
        ...state,
        searchedLockersInfraSensorList: [],
        isLoading: false,
      }
    case SET_LOCKERS_INFRA_SENSOR_SEARCH:
      return {
        ...state,
        search: action.payload,
        isLoading: false,
      }

    case GET_SEARCHED_LOCKERS_BY_STATION_SUCCESS:
      return {
        ...state,
        currentStationLockers: action.payload,
        searchedLockersInfraSensorList: [],
        isLoading: false,
      }
    case GET_SEARCHED_LOCKERS_BY_STATION_FAIL:
      return {
        ...state,
        currentStationLockers: [],
        searchedLockersInfraSensorList: [],
        isLoading: false,
        isError: true,
      }
    case RESET_ERROR:
      return {
        ...state,
        isError: false,
      }
    case ON_USER_SIGN_IN_RESET_ATTEMPTS:
    case UNAUTHORIZED:
      return {
        isLoading: false,
        isError: false,
        currentLockersStationId: "",
        currentStationLockers: [],
        searchedLockersInfraSensorList: [],
        currentSearchedPage: 1,
        currentSearchedPageStep: 50,
        search: {
          stationId: "",
          lockers: [],
          fromDate: "",
          toDate: "",
          pageStep: 50,
          page: 1,
        },
      }
    default:
      return state
  }
}

export default LockersInfraSensorReducer
