import React, { Component, useEffect } from "react"

import { SmallHeader, BlueButton } from "../../common"
import Modal from "../Modal"

import "./style.scss"

const OrderItemsOriginalModal = ({
  isShow,
  orderNumber,
  orderItems,
  setViewOrderItemsOriginal,
}) => {
  if (!isShow) {
    return <noscript />
  }

  const renderItems = (orderItems) => {
    if (orderItems) {
      return orderItems.map((item, index) => {
        const {
          processName,
          itemName,
          color,
          pattern,
          quantity,
          itemPrice,
          notes,
        } = item
        return (
          <tr key={index} className="">
            <td>{processName}</td>
            <td>{itemName}</td>
            <td>{color}</td>
            <td>{pattern}</td>
            <td>{quantity}</td>
            <td>{itemPrice}</td>
            <td>{notes}</td>
          </tr>
        )
      })
    }
  }

  return (
    <Modal extraClass="cror-modal-wrap">
      <div className="cror-modal-container">
        <SmallHeader title={"פריטי הזמנה מקוריים"} />
        <div className="cror-modal-inputs-wrapper">
          <div className="cror-modal-title">
            <div className="title"> פריטי הזמנה מקוריים </div>
            <div className="order-number"> מס' {orderNumber} </div>
          </div>

          <section className="items-original-table">
            <table>
              <tr>
                <th>תהליך</th>
                <th>פריט</th>
                <th>צבע</th>
                <th>פטרן</th>
                <th>כמות</th>
                <th>מחיר</th>
                <th>הערות</th>
              </tr>
              {renderItems(orderItems)}
            </table>
          </section>

          <div className="cror-modal-status">
            <BlueButton
              extraClass="cror-modal-button"
              onClick={(e) => setViewOrderItemsOriginal(false)}
            >
              סגור
            </BlueButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default OrderItemsOriginalModal
